@import "@/styles/mixins/_breakpoint";

.myProductsView_wrapper {
  .kf-react-container.my-products__wrapper {
    @include breakpoint(desktop-small) {
      max-width: 1920px !important;
      padding: 0 2.64% !important;
      margin: 0 !important;
    }
    @media (min-width: 992px) and (max-width: 1023px) {
      padding: 0 2rem !important;
    }
  }
  @include breakpoint(tab-max) {
    padding-bottom: 0;
  }
  .my-products__wrapper {
    .account-tabs {
      &__nav-wrapper {
        @include breakpoint(desktop-large) {
          flex: 0 0 13%;
          max-width: 13%;
        }
        @media (min-width: 992px) and (max-width: 1023px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 0;
        }
      }
      &__nav {
        @include breakpoint(desktop-small) {
          padding: 3.75rem 1.375rem 0 0;
        }
        @include breakpoint(tab-landscape) {
          padding: 2.5rem 2rem;
          margin-right: -2rem;
        }
        @include breakpoint(mobile-potrait) {
          padding: 2.5rem 1.125rem 2.5rem;
          margin: 0 -1.125rem;
        }
        .account-tabs__dropdown {
          .select-dropdown {
            &__placeholder.--label {
              @include breakpoint(tab-max) {
                @include text-styles(
                  $font-regular,
                  0.75rem,
                  1.125rem,
                  -0.0156rem
                );
              }
            }
            &__value {
              @include breakpoint(tab-max) {
                @include text-styles(
                  $font-regular,
                  1.125rem,
                  1.625rem,
                  -0.0313rem
                );
              }
            }
            &__field {
              padding: 8px 15px;
            }
          }
        }
      }
      &__content {
        @include breakpoint(desktop-small) {
          padding: 2.8125rem 0 7.5rem 1.875rem;
        }
        @media (min-width: 992px) and (max-width: 1023px) {
          padding: 1.25rem 0 7.5rem;
        }
        @media (max-width: 992px) {
          padding-top: 1.25rem !important;
        }
        @include breakpoint(mobile-landscape) {
          padding: 1.25rem 0 0 0;
        }
        @include breakpoint(mobile-potrait) {
          padding-top: 1.25rem !important;
        }
        .my-products {
          &__cta {
            .kf-react-button.large {
              @include text-styles(
                $font-medium,
                1.125rem,
                1.625rem,
                -0.0219rem
              );
              min-width: 100%;
              height: 3.125rem;
              a.kf-react-button {
                padding: 0 2.5rem;
              }
            }
            &-mssg {
              @include text-styles($font-medium, 1rem, 1.5rem, -0.025rem);
              margin-bottom: 0.625rem;
              @include breakpoint(tab-landscape) {
                margin-bottom: 1.25rem;
              }
            }
            &--desktop,
            &--mobile-potrait-flex,
            &--mobile-flex {
              flex-direction: column;
              @include breakpoint(tab-landscape) {
                padding: 60px 0;
              }
              @media (min-width: 992px) and (max-width: 1022px) {
                display: flex;
                align-items: flex-start;
              }
            }
            &.my-products__cta--mobile {
              @include breakpoint(tab-landscape) {
                padding: 60px 0;
              }
            }
          }
          &__title {
            @include text-styles($font-light, 2.8125rem, 3.5rem, -0.1994rem);
            padding-bottom: 4.6875rem;
          }
          &__content {
            @include breakpoint(tab-max) {
              margin-bottom: 0;
            }
          }
          &__list-title {
            @include text-styles($font-bold, 1.25rem, normal, 0.1136rem);
            text-transform: uppercase;
            &-section {
              @include breakpoint(mobile-landscape) {
                margin-bottom: 1.875rem;
              }
            }
          }
          &__empty-title {
            @include text-styles($font-light, 1.75rem, 2.25rem, -0.0875rem);
            margin-bottom: 1.25rem;
            color: $slate;
          }
          &__empty-desc {
            @include breakpoint(tab-max) {
              padding: 0;
            }
          }
          &__empty-disabled-info {
            @include breakpoint(tab-max) {
              padding: 0;
            }
            > div:first-child {
              padding: 0;
            }
          }
          &__select-sec {
            @include breakpoint(mobile-potrait) {
              margin-top: 0.9375rem;
            }
            .sort {
              @include breakpoint(mobile-potrait) {
                margin: 0;
              }
              .sort__drop-down {
                @include breakpoint(mobile-potrait) {
                  margin-bottom: 0;
                }
              }
              &-by-text,
              &-options {
                @include text-styles($font-regular, 1rem, 1.5rem, -0.025rem);
                color: $color-dark-gray;
              }
              &__toggle-icon {
                margin-left: 10px;
              }
            }
          }
          &__list-disabled-info {
            border-bottom: 1px solid $color-dark-gray;
            @include breakpoint(mobile-landscape) {
              padding-top: 0;
            }
          }
        }
      }
      &__list {
        margin-top: 2.5rem;
        &-item {
          @include text-styles($font-light, 1rem, 1.5rem, -0.025rem);
          &:first-child {
            padding-top: 0;
          }
          &.--active {
            color: $slate;
          }
        }
      }
      &__sign-out {
        margin-top: 3.125rem;
        @include text-styles($font-medium, 14px, 22px, -0.3px);
        text-underline-offset: 5px;
      }
      &__message {
        @include text-styles($font-light, 1.75rem, 2.25rem, -0.0875rem);
      }
    }
    .my-product {
      &-tile {
        padding: 30px 0;
        column-gap: 20px;
        row-gap: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include breakpoint(mobile-landscape) {
          position: relative;
        }
        @include breakpoint(mobile-potrait) {
          flex-direction: column;
          position: relative;
        }
        &__brand {
          color: $color-dark-gray;
          @include text-styles($font-medium, 1rem, 1.5rem, -0.0219rem);
          margin: 0 0 0.3125rem;
          @include breakpoint(tab-landscape) {
            @include text-styles($font-medium, 0.875rem, 1.375rem, -0.0187rem);
          }
        }
        &__details {
          max-width: 300px;
          @include breakpoint(tab-landscape) {
            max-width: 340px;
          }
        }
        &-container {
          @include breakpoint(desktop-small) {
            display: flex;
            justify-content: space-between;
            flex: 1 1 85%;
          }
          @include breakpoint(tab) {
            flex: 1 1 100%;
          }
          .my-product {
            &__details {
              width: 40%;
              margin-top: 1.25rem;
            }
            &-split {
              display: flex;
              flex-direction: row;
              width: 60%;
              justify-content: space-between;
              @include breakpoint(desktop-mid) {
                width: 70%;
              }
              @include breakpoint(mobile-landscape) {
                width: 100%;
                justify-content: space-between;
                align-items: baseline;
                flex-direction: row;
                margin: 30px 0 3px;
              }
              @include breakpoint(mobile-potrait) {
                width: 100%;
                flex-direction: column;
              }
              .my-product {
                &-cta {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-end;
                  @include breakpoint(mobile-landscape) {
                    flex-direction: row;
                    align-items: baseline;
                  }
                  @include breakpoint(mobile-potrait) {
                    align-items: baseline;
                    flex-direction: row;
                    justify-content: flex-start;
                    margin: 1.875rem 0 2.5rem;
                    padding-bottom: 1.875rem;
                  }
                  &.my-product-without-cta {
                    @include breakpoint(mobile-potrait) {
                      margin: 0;
                      padding: 0;
                    }
                  }
                }
                &-tile {
                  &__links {
                    a {
                      @include text-styles(
                        $font-regular,
                        1rem,
                        1.5rem,
                        -0.025rem
                      );
                      &:last-child {
                        @include breakpoint(mobile-landscape) {
                          padding-bottom: 1.875rem;
                        }
                      }
                    }
                  }
                  &__registered {
                    background-color: transparent;
                    border: 1px solid $light-gray;
                    padding: 5px 8px;
                    color: $charcoal;
                    @include text-styles(
                      $font-medium,
                      0.625rem,
                      0.625rem,
                      0.0313rem
                    );
                  }
                }
              }
              @include breakpoint(mobile-landscape) {
                &:is(.my-product-tile__registered) {
                  .my-product-tile__links a {
                    margin-top: 0;
                  }
                }
              }
              &.my-product-split--show {
                .my-product-cta {
                  &.my-product-without-cta {
                    @include breakpoint(mobile-potrait) {
                      margin: 1.875rem 0 2.5rem;
                      padding-bottom: 1.875rem;
                    }
                  }
                }
              }
            }
            &-tile {
              &__details {
                @include breakpoint(desktop-mid) {
                  max-width: 21.25rem;
                  padding-right: 1.875rem;
                }
              }
              &__name {
                @include text-styles($font-regular, 1rem, 1.5rem, -0.025rem);
                color: $slate;
              }
              &__desc {
                @include text-styles($font-regular, 1rem, 1.5rem, -0.025rem);
                color: $slate;
              }
              &__color {
                @include text-styles(
                  $font-regular,
                  0.875rem,
                  1.375rem,
                  -0.0281rem
                );
                margin-top: 0.9375rem;
                margin-bottom: 0;
              }
              &__sku {
                @include text-styles(
                  $font-regular,
                  0.875rem,
                  1.375rem,
                  -0.0281rem
                );
                margin-top: 0.625rem;
                color: $slate;
              }
            }
          }
        }
        &__cta {
          .kf-react-button {
            @include text-styles($font-medium, 0.875rem, 1.375rem, -0.0187rem);
            @include breakpoint(mobile-landscape) {
              min-width: 30.6875rem;
            }
            @media (min-width: 768px) and (max-width: 790px) {
              min-width: 28rem;
            }
            @include breakpoint(mobile-potrait) {
              width: 100%;
            }
          }
          @include breakpoint(mobile-landscape) {
            position: absolute;
            bottom: 1.9375rem;
            margin-top: 2.3125rem;
            left: 0;
            margin-left: 257px;
          }
          @include breakpoint(mobile-potrait) {
            margin-top: 0;
            position: absolute;
            bottom: 31px;
            width: 100%;
          }
          a.kf-react-button {
            padding: 0.5rem 1.875rem;
            @include breakpoint(mobile-landscape) {
              margin-top: 30px;
            }
          }
        }
        &__remove {
          @include text-styles($font-regular, 1rem, 1.5rem, -0.025rem);
          @include breakpoint(mobile-landscape) {
            padding-left: 2.5rem;
          }
          @include breakpoint(mobile-potrait) {
            order: 2;
            padding-left: 2.5rem;
          }
          span {
            @include text-styles($font-regular, 1rem, 1.5rem, -0.025rem);
            margin: 0 0 20px;
            color: $color-slate;
            @include breakpoint(tab-landscape) {
              margin: 0;
            }
          }
        }
        &__image {
          flex: 1 1 15%;
          @include breakpoint(mobile-landscape) {
            flex: 1 1 14.8125rem;
          }
          img {
            width: 14.8125rem;
            height: 14.8125rem;
          }
        }
        &__links {
          @include breakpoint(desktop-small) {
            margin-top: 70px;
          }
          @include breakpoint(mobile-potrait) {
            margin: 1.875rem 0 0;
          }
          a:last-child {
            @include breakpoint(mobile-landscape) {
              margin-bottom: 1.875rem;
            }
          }
        }
        &__icons {
          @include breakpoint(mobile-potrait) {
            order: 1;
          }
          img {
            color: $color-dark-gray;
          }
          .social-share__box {
            @include breakpoint(mobile-potrait) {
              left: 0;
            }
          }
        }
        &__share {
          margin-left: 1.25rem;
          @include breakpoint(tab-landscape) {
            margin-left: 2.5rem;
          }
        }
        &__header {
          @include breakpoint(mobile-landscape) {
            margin-top: 0;
          }
          @include breakpoint(mobile-potrait) {
            margin-top: 1.25rem;
          }
        }
      }
    }
    .account-tabs__wrapper-nav {
      @include breakpoint(desktop-large) {
        flex: 0 0 87%;
        max-width: 87%;
      }
      @media (min-width: 992px) and (max-width: 1023px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 0;
      }
      .my-products {
        &__disabled-container {
          padding-bottom: 0;
          @include breakpoint(tab-max) {
            padding: 0;
          }
          @include breakpoint(mobile-landscape) {
            display: none;
          }
        }
        &__list-title-section {
          @include breakpoint(mobile-potrait) {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        &__list {
          .modal-popup {
            .modal-popup-content {
              @include breakpoint(mobile-potrait) {
                margin: auto 18px;
              }
              .modal-popup-body {
                @include breakpoint(mobile-landscape) {
                  padding: 50px 66px 60px;
                }
                @include breakpoint(mobile-potrait) {
                  padding: 50px 30px 30px;
                }
              }
              .account-settings__modal-title {
                @include breakpoint(desktop-small) {
                  max-width: 420px;
                }
                @include breakpoint(tab-landscape) {
                  @include text-styles($font-light, 2rem, 2.5rem, -0.1375rem);
                }
                @include breakpoint(mobile-landscape) {
                  white-space: nowrap;
                }
              }
              .close-button {
                @include breakpoint(tab-landscape) {
                  top: 22px;
                  right: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.register-offline-product {
  &__go-back {
    .arrow-back .link {
      letter-spacing: -0.4px !important;
    }
  }
  &__main-header-top {
    .main-title {
      letter-spacing: 34px;
      line-height: -1.5px;
    }
  }
}
.register-online-product {
  &.register-online-product__info-details {
    &__step {
      @include breakpoint(mobile-potrait) {
        padding: 40px 0 70px;
      }
    }
    &__content {
      &.col-lg-9 {
        @media (min-width: 992px) and (max-width: 1023px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .select-products {
        @include breakpoint(tab-landscape) {
          padding-bottom: 0;
        }
      }
    }
    .step-nav__edit-btn--mobile {
      @include breakpoint(tab-landscape) {
        margin-top: 0;
        display: none;
      }
    }
    .step-nav {
      .step-nav__step {
        .step-nav {
          &__title {
            margin-bottom: 40px;
            @include breakpoint(mobile) {
              margin: 0;
            }
            h2 {
              text-transform: capitalize;
              @include text-styles($font-light, 32px, 40px, -1.6px!important);
              @include breakpoint(mobile-potrait) {
                max-width: 14.5rem;
              }
            }
          }
        }
      }

      &__step--active {
        .select-product-details {
          > div.row {
            flex-wrap: nowrap;
            &:first-child {
              @include breakpoint(tab-landscape) {
                margin-top: 20px;
              }
            }
          }
          @include breakpoint(tab-landscape) {
            position: relative;
            margin-top: 20px;
            padding: 24px 0 40px;
          }
          &:first-child {
            @include breakpoint(tab-landscape) {
              border-top: 0 !important;
            }
          }
        }
        .step-nav {
          &__title {
            margin-bottom: 40px;
            h2 {
              text-transform: capitalize;
              letter-spacing: -3.1px !important;
              @include breakpoint(tab-landscape) {
                letter-spacing: -1.6px;
              }
            }
          }
          &__content {
            padding-bottom: 80px;
          }
        }
        .step-nav__content {
          @include breakpoint(tab-landscape) {
            margin-bottom: 30px;
          }
        }
      }
      .step-nav__step {
        @include breakpoint(tab-landscape) {
          padding-top: 30px;
        }
        &:last-child {
          .step-nav__content {
            h2 {
              @include breakpoint(mobile-potrait) {
                white-space: nowrap;
              }
            }
          }
        }
        .step-nav__content {
          @include breakpoint(tab-landscape) {
            padding-bottom: 20px;
            margin: 0;
          }
          .order-summary-mobile-block {
            .step-nav__edit-btn--mobile {
              display: none;
            }
          }
          .step-nav__edit-btn {
            @include text-styles($font-medium, 14px, 22px, -0.3px);
            @include breakpoint(tab-landscape) {
              display: block;
            }
          }
        }
      }
    }
    .select-products__select-all {
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      @include breakpoint(tab-landscape) {
        margin-bottom: 60px;
      }
    }
    .select-product-details {
      &__item-left {
        margin: 0 30px 15px;
        @include breakpoint(mobile-landscape) {
          margin: 0 30px 0 0;
        }
        @include breakpoint(mobile-potrait) {
          margin: 0 30px 0 0;
          min-width: 61px;
        }
      }
      &__brand-name {
        line-height: 24px;
      }
      &__sku-id {
        letter-spacing: -0.3px;
      }
      &__name {
        line-height: 30px;
        letter-spacing: -1.1px;
      }
      &__description {
        letter-spacing: -0.4px;
      }
      &__item-color,
      &__item-color .color-finish {
        color: $color-dark-gray;
        letter-spacing: -0.4px;
      }
      &__purchase span,
      &__purchase-center span {
        @include text-styles($font-regular, 14px, 22px, -0.3px);
      }
      &__register {
        @include breakpoint(tab-landscape) {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
        }
        .checkbox {
          @include breakpoint(tab-landscape) {
            margin-bottom: 0;
          }
        }
      }
      &__register-mobile {
        display: none;
      }
      &__item-right {
        @include breakpoint(tab-landscape) {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0 40px 0 0;
        }
      }
      &__item-center {
        @include breakpoint(mobile-landscape) {
          padding: 0;
        }
        @include breakpoint(mobile-potrait) {
          width: 100%;
          padding: 0;
        }
      }
      &__purchase-center {
        @include breakpoint(mobile-potrait) {
          display: block;
        }
      }
    }
    .checkbox {
      &_default {
        letter-spacing: -0.4px;
      }
    }
    .register-online-product {
      &__back .back {
        font-family: $font-medium;
        line-height: 28px;
        letter-spacing: -0.4px;
      }
      &__title {
        @include text-styles($font-light, 1.625rem, 2.125rem, -0.0938rem);
        padding-bottom: 30px;
      }
      &__info-details {
        .step-nav {
          &__title {
            h2 {
              text-transform: capitalize;
              letter-spacing: -1.6px;
              @include breakpoint(tab-landscape) {
                font-size: 2rem;
                line-height: 2.5rem;
              }
              white-space: nowrap;
            }
          }
        }
        .step-nav__step--done .step-nav__title h2 {
          letter-spacing: -3.1px;
        }
        .registration-information__container {
          padding-top: 10px;
          .input-field__value label {
            letter-spacing: -0.5px;
            line-height: 26px;
          }
          .input-field__border {
            padding: 8px 15px;
          }
          .registration-information__required-fields {
            @include breakpoint(mobile-potrait) {
              margin-top: 0;
            }
          }
          .react-datepicker-wrapper {
            @include breakpoint(mobile-potrait) {
              display: block;
            }
          }
          .next-button {
            @include breakpoint(tab-landscape) {
              padding-bottom: 0;
            }
            a.kf-react-button {
              padding: 0 60px;
              line-height: 26px;
              @include breakpoint(tab-landscape) {
                width: 100%;
              }
            }
          }
        }
      }
      &__information {
        margin-bottom: 80px;
      }
      &__your-account {
        @include breakpoint(mobile-landscape) {
          padding-bottom: 50px;
        }
        @include breakpoint(mobile-potrait) {
          padding-bottom: 60px;
        }
        .kf-react-container {
          div.row {
            margin: 0;
          }
          .submit-button {
            .kf-react-button {
              min-width: auto;
            }
            a.kf-react-button {
              min-width: 100%;
              padding: 0 60px;
            }
          }
          .register-online-product__my-products p {
            letter-spacing: -3.1px;
            @include breakpoint(tab-landscape) {
              letter-spacing: -2.5px;
            }
            @include breakpoint(mobile-potrait) {
              margin-bottom: 20px;
            }
          }
        }
      }
      &__summary {
        .kf-react-container {
          div.row {
            margin: 0;
          }
        }
        &-container {
          padding: 0;
        }
        .select-product-details {
          @include breakpoint(tab-landscape) {
            padding: 30px 0 40px;
          }
          &__item-left {
            @include breakpoint(tab-landscape) {
              margin: 0 30px 15px 0;
            }
          }
        }
        .select-product-details__register,
        .select-product-details__item-right {
          @include breakpoint(tab-landscape) {
            display: none;
          }
        }
        .submit-button {
          .kf-react-button {
            min-width: auto;
            @include breakpoint(mobile-landscape) {
              width: auto;
              max-width: fit-content;
            }
          }
          a.kf-react-button {
            padding: 0 60px;
          }
        }
        .register-online-product__information {
          @include breakpoint(tab-landscape) {
            padding: 25px 0 0;
          }
        }
      }
      &__heading {
        @include breakpoint(mobile-landscape) {
          padding: 50px 0 45px;
        }
        @include breakpoint(mobile-potrait) {
          padding: 50px 0 60px;
        }
      }
      &__my-products {
        padding-top: 12px;
        @include breakpoint(tab-landscape) {
          padding: 30px 0 0;
        }
      }
    }
    .step-nav__number--mobile {
      margin-right: 40px;
      @include breakpoint(tab-landscape) {
        margin-right: 13px;
      }
    }
  }
}
.next-button {
  a.kf-react-button {
    padding: 0 60px;
    line-height: 26px;
    @include breakpoint(tab-landscape) {
      width: auto !important;
    }
  }
}

.review-registration {
  &__container {
    padding-top: 10px;
  }
  &__main span {
    font-family: $font-medium !important;
    letter-spacing: -0.4px !important;
  }
  .recaptcha-links {
    @include text-styles($font-medium!important, 16px, 24px, -0.4px);
    margin-bottom: 0 !important;
  }
  .submit-button {
    @include breakpoint(tab-landscape) {
      padding-top: 60px !important;
    }
    .button {
      padding: 0 60px !important;
      line-height: 26px;
    }
  }
  .step-nav__content {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .recaptcha-links {
    @include breakpoint(mobile-potrait) {
      padding: 0 !important;
    }
    span {
      font-family: $font-medium;
    }
  }
}
.select-products__register-main.confirmation-page {
  .select-product-details {
    .row.ml-0.mr-0 {
      @include breakpoint(mobile-potrait) {
        flex-wrap: nowrap !important;
      }
    }
    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
    &__register {
      @include breakpoint(tab-landscape) {
        display: none !important;
      }
    }
    &__item-right {
      @include breakpoint(tab-landscape) {
        top: 40px;
      }
    }
  }
}
.register-online-product__edit-information {
  @include breakpoint(tab-landscape) {
    padding: 20px 0 0 !important;
  }
  p {
    @include text-styles(
      $font-medium!important,
      .875rem!important,
      1.375rem!important,
      -0.3px!important
    );
  }
}
.register-online-product__summary-main {
  .select-product-details__item-right {
    margin-left: 0 !important;
  }
  .select-product-details {
    .row.ml-0.mr-0 {
      @include breakpoint(mobile-potrait) {
        flex-wrap: nowrap !important;
      }
    }
  }
}

.register-online-product {
  .register-online-product__your-account {
    @include breakpoint(mobile-potrait) {
      padding: 0 18px;
    }
    .container.kf-react-container {
      .register-online-product__back {
        .back {
          font-family: $font-medium;
          line-height: 28px;
          letter-spacing: -0.4px;
        }
      }
    }
  }
  &__summary-container {
    .register-online-product {
      &__heading,
      &__registered,
      &__summary-main {
        @include breakpoint(mobile-potrait) {
          padding: 0 18px;
        }
      }
    }
  }
}
