.my-products {    
    &__cta {
        &--mobile-flex {
            @include breakpoint(tab) {
                display: none;
            }
        }
    }
    &__list {
        .modal-popup {
            .modal-popup-content {
                height: 308px;
                width: 500px;
                @include breakpoint(tab) {
                    height: 332px !important;
                    width: 747px;
                }
                @include breakpoint(mobile) {
                    height: 380px !important;
                    width: 339px;
                }
                .close-button {
                    right: 40.37px;
                    top: 32.37px;
                    @include breakpoint(tab-max) {
                        right: 20.37px;
                        top: 22.37px;
                    }
                }
                .modal-popup-body {
                    @include breakpoint(tab) {
                        padding: 50px 66px 62px;
                    }
                    @include breakpoint(mobile) {
                        padding: 50px 30px 30px;
                    }
                }
                .account-settings {
                    &__modal-title {
                        @include breakpoint(tab-max) {
                            @include text-style (32px, 40px, -2.2px);
                        }
                        @include breakpoint(tab) {
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }
}

.my-product {
    &-tile {
        &__modal-cta {
            .button {
                width: 200px;
                @include text-style (18px, 24px, -0.35px);
                @include breakpoint(tab) {
                    width: 615px;
                    font-weight: 500;
                }
                @include breakpoint(mobile) {
                    width: 279px;
                }
            }
            .button:first-child {
                color: $text-medium-black;
                @include breakpoint(tab-max) {
                    margin: 0 0 20px 0;
                }
            }
        }
        &__cta{
            a.kf-react-button.button{
                border: none;
            }
        }
    }
}
//acount settings fixed 
.accountsettings {
    .account-settings {
        @include breakpoint (desktop-extra-lite-sm) {
            z-index: 0;
            position: relative;
        }
        .account-tabs__content{
            .account-address{
                &__tiles{
                    .modal-popup{
                        .modal-popup-body{
                            @include breakpoint(tab){
                                padding:35px 32px 50px;
                            }
                        }
                    }
                }
            }
        }
    }
    .button.large, .kf-react-button.large{
            margin-top:30px;
        @include breakpoint(tab){
            margin-top: 20px;
        }
        @include breakpoint(mobile){
            margin-top: 30px;
        }
    }
}
.account-tabs{
    &__nav{
        @include breakpoint(tab-max){
            z-index: 1;
        }
    }
}
.scroll-lock.modal-open .cmp-container {
    @include breakpoint (tab-max) {
        z-index: 0;
        position: relative;
    }
}
.account-management{
    &__desc{
        color: $slate;
        @include ann-describtion(20px, $helvetica-medium, 28px);
        letter-spacing: -0.4px;
    }
    .modal-popup{
        .modal-popup-content{
            .modal-popup-body{
                padding: 35px 32px 50px;
            }
        }
    }
    &__cta{
        >div.kf-react-button.button{
            border: none;
            padding: 0;
        }
    }
}
.account-communication{
    .account-communication{
        &__title{
            color: $slate;
        }
    }
    &__cta{
        >div.kf-react-button.button{
            border: none;
            padding: 0;
        }
    }
}
body.scroll-lock{
    &.modal-open{
        .ann-sacks-logobar, .ann-sacks-main-menu.ann-sacks-tab-bar{
            z-index: 0;
        }
        .ann-sacks-main-menu{
            .cmp-container{
                @include breakpoint(mobile){
                    overflow: hidden;
                }
            }
        }
    }
}