.container.sitemap-container {
  float: inherit !important; // Over ride default float: left property  
  background:$cool-light-grey;
  @include breakpoint(desktop-small) {
    padding: 0 2.25rem;
  }
  @include breakpoint(tab) {
    padding: 0 2rem;
  }
  @include breakpoint(mobile) {
    padding: 0 1.125rem;
  }
  .cmp-container{
    margin: 0 auto;
    max-width: 1380px;
    padding: 0 !important;
  }
  .teaser {
    padding: 6.875rem 0;
    text-align: center;
    border-bottom: 1px solid $cool-dark-grey;
    @include breakpoint(tab-max) {
      padding: 5.3125rem 0 3.75rem 0;
    }
    .cmp-teaser {
      &__content{
        flex-direction: column;
        .cmp-teaser__title{
          border:0;
          width: 100%;
          padding: 0;
          h1, h2, h3, h4, h5, h6, p {
            @include ann-describtion(3.375rem, $playfair-regular, 3.875rem);
            letter-spacing: -0.0625rem;
            color: $text-dark-grey;
            width: 28.375rem;
            margin: 0 auto;
            padding-bottom: 1.25rem;
            @include breakpoint(tab) {
              width: 11rem;
              @include ann-describtion(2.625rem, $playfair-regular, 3rem);
              letter-spacing: -0.175rem;
              padding-bottom: 0.9375rem;
            }
            @include breakpoint(mobile) {
              width: 11rem;
              @include ann-describtion(2.625rem, $playfair-regular, 3rem);
              letter-spacing: -0.175rem;
              padding-bottom: 0.9375rem;
            }
          }
        }
        .cmp-teaser__description{
          width: 100%;
          padding: 0;
          h1, h2, h3, h4, h5, h6,p {
            width: 21.75rem;
            margin: 0 auto;
            @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
            letter-spacing: normal;
            color: $text-medium-dark-grey;
            @include breakpoint(mobile) {
              width: 12.875rem;
              @include ann-describtion(0.8125rem, $lato-regular, 1.25rem);
              letter-spacing: -0.0125rem;
            }
          }
        }
        }
    }
  }
}

.container.sitemap-container-list {  
  background:$cool-light-grey;  
  padding: 0;
  .cmp-container {
    margin: 0 auto;
    max-width: 1380px;
    column-count: 3;
    padding: 0;
    @include breakpoint(desktop-mid){
      padding: 0 2.25rem;
    }

    @include breakpoint(desktop-small) {
      padding-bottom: 5.625rem;
    }

    @include breakpoint(tab) {
      column-count: auto;
      padding: 1.25rem 2rem 3.125rem 2rem;
    }

    @include breakpoint(mobile) {
      padding: 1.25rem 1.125rem 3.125rem 1.125rem;
      column-count: auto;
    }    

    .list {      
      border-bottom: 1px solid $cool-dark-grey;
      vertical-align: top;
      margin-right: 7.5rem;
      @include breakpoint(desktop-small) {
        width: 22.5rem;        
        &:first-child {
          .list-inner {
            .cmp-list {
              .cmp-list__subhead {
                padding-bottom: .625rem;
              }
            }
          }
        }
        &:nth-child(3) {
          .list-inner {
            padding-top: 1.875rem;
          }
        }
        &:nth-child(4) {
          .list-inner {
            padding-top: 1.875rem;
          }
        }
        &:nth-child(6) {
          .list-inner {
            padding-top: 1.875rem;
          }
        }
        &:nth-child(7) {
          .list-inner {
            padding-top: 1.875rem;
          }
        }
        &:last-child {
          .list-inner {
            padding-top: 1.875rem;
          }
        }
      }
      &:last-child {
        border: none;        
      }
      @include breakpoint(tab) {
        margin-right: 0;
         padding: 0;
         width: 100%;
      }
      @include breakpoint(mobile) {
        margin-right: 0;
        padding: 0;
         width: 100%;
      }        
      .list-inner {
        break-inside: avoid;
        padding-top: 2.5rem;
        position: relative;
        @include breakpoint(tab) {
          padding: 0;
        }
        @include breakpoint(mobile) {
          padding: 0;
        }       

      h1, h2, h3, h4, h5, h6 {
        a {
          color: $text-dark-grey;
          text-decoration: none;
        }
      }

        .cmp-list {
          list-style: none;
          margin: 0;
          padding: 0 0 2.5rem 0;
            :last-child {
              padding: 0;
            }
            @include breakpoint(desktop-small) {
              display:block;
             }
             ul {
              margin: 0;
              list-style: none;
              padding: 0;            
            }
        
          .cmp-list__subhead {
            color: $text-dark-grey;
            @include ann-describtion(1rem, $lato-bold, 1.5rem);              
            letter-spacing: normal;
            text-decoration: none;
            @include breakpoint(tab-max){
              padding-bottom:10px;
            }            
            a {
              color: $text-dark-grey;
              @include ann-describtion(1rem, $lato-bold, 1.5rem);              
              letter-spacing: normal;
              text-decoration: none;
            }
          }
          .cmp-page-link,
          .cmp-browsertab  {
            display: none;
          }
          .cmp-page-name {
            padding: .0625rem 0;
              color: $text-light-grey;
              @include ann-describtion(1rem, $lato-regular, 1.5rem);
              letter-spacing: normal;
              &:hover {
                text-decoration: underline;
              }
            a {
              color: $text-light-grey;
              @include ann-describtion(1rem, $lato-regular, 1.5rem);
              letter-spacing: normal;
            }
          }
          &.active-block{
            display: block
          }
        }
        .cmp-list__heading {
          @include ann-describtion(2.625rem, $playfair-regular, 3rem);
              letter-spacing: -0.073125rem;
              padding: 0 0 1.25rem 0;
              margin: 0;
              color: $text-dark-grey;
              @include breakpoint(desktop-small){
                pointer-events: none;
              }
              @include breakpoint(tab-max) {
                @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
              }
              @include breakpoint(tab) {
                padding: 1.375rem 0;
              }
            @include breakpoint(mobile) {
              padding: 1.3125rem 0 1.375rem;
            }            
          @include breakpoint(tab-max) {
            &::after {
              content: "\e929";
              font-size: 14.5px;
              color: $text-light-grey;
              font-family: $icon-moon;
              width: 14.5px;
              height: 14.5px;
              float:right;
              font-weight: 600;
              top: 17px;
            }
          }
          @include breakpoint(tab-max) {
            &.active {
              &::after {
                content: "";
                color: $text-light-grey;
                background-color: $text-light-grey;
                position: relative;
                font-size: 14.5px;
                font-family: $icon-moon;
                width: 14.5px;
                height: 1px;
                top: 17px;
                overflow-x: hidden;
              }
            }
          }          
        }
      }
    }
  }
}  