.back-to-top {
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  color: $text-medium-dark-grey;
  font-family: $lato-regular;
  font-size: 1pc;
  letter-spacing: 0.4px;
  line-height: 24px;

  .back-to-top-text {
    margin-right: 15px;   
    @include breakpoint(desktop-mid) {
      margin-right: 15px;
    }
    @include breakpoint(xs) {
      margin-right: 15px;    
    }
  }
  .back-to-top-image {
    height: 18.95px;
    width: 9.9px;
    margin-top: 2px;
    margin-left: 0; 
  }
}
