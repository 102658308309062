.breadcrumb {
  background: transparent;
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 89px;
  @include breakpoint(desktop-extra-sm) {
    min-height: 73px;
  }
  @include breakpoint(tab-max) {
    min-height: 69px;
  }
  .cmp-breadcrumb {
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    padding: 30px 2.6045% 40px;
    z-index: 1;
    position: relative;
    @include breakpoint(desktop-large) {
      padding: 30px 50px 40px;
    }
    @include breakpoint(desktop-extra-sm) {
      padding-bottom: 30px;
    }
    @include breakpoint(desktop-extra-lite-sm) {
      padding-top: 20px;
      padding-bottom: 30px;
    }
    @include breakpoint(tab-max) {
      max-width: 100%;
    }
    @include breakpoint(tab) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    @include breakpoint(mobile) {
      padding-right: 1.125rem;
      padding-left: 1.125rem;
    }
    &__item {
      padding: 0;
      text-transform: capitalize;
      span {
        color: $text-medium-dark-grey !important;
        @include ann-describtion(
          0.8125rem !important,
          $lato-regular !important,
          1.125rem !important
        );
        letter-spacing: 0.0125rem !important;
        text-transform: capitalize !important;
        @include breakpoint(desktop-extra-lite-lg) {
          @include ann-describtion(
            13px !important,
            $lato-regular !important,
            20px !important
          );
        }
        @include breakpoint(tab-max) {
          line-height: 16px !important;
        }
      }
      &:last-child {
        .cmp-breadcrumb__item-link {
          &:after {
            display: none;
          }
        }
      }
    }
    &__item-link {
      color: $text-medium-dark-grey;
      span {
        @include ann-describtion(0.8125rem, $lato-regular, 1.125rem);
        letter-spacing: 0.0125rem;
        text-transform: capitalize;
        color: $text-medium-dark-grey;
        opacity: 1 !important;
        &:hover {
          text-decoration: none !important;
          border: none !important;
        }
        @include breakpoint(desktop-extra-lite-lg) {
          @include ann-describtion(13px, $lato-regular, 20px);
        }
        @include breakpoint(tab-max) {
          line-height: 16px;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &:after {
        margin: 0 0.3125rem;
        content: "/";
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
    &__item--active {
      span {
        color: $text-dark-grey !important;
      }
    }
  }
  &.fc-carbon {
    .cmp-breadcrumb {
      &__item-link {
        span,
        &:after {
          color: $text-medium-dark-grey;
        }
      }
      &__item--active {
        span {
          color: $text-dark-grey;
          &:hover {
            border-bottom: none;
          }
        }
      }
    }
  }
  &.fc-white {
    .cmp-breadcrumb {
      &__item-link {
        span,
        &:after {
          color: $white !important;
        }
      }
      &__item--active {
        span {
          color: $white !important;
          &:hover {
            border-bottom: none;
          }
        }
      }
      &-slash {
        &:after {
          color: $white !important;
        }
      }
    }
  }
  + .global-hero-banner,
  + .ann-sacks-category-landing-banner,
  + .hero-banner-collection,
  + .hero-banner-three-by-four-full-bleed,
  + .services-hero,
  + .page-title,
  + .ann-sacks-plp-hero-banner,
  + .hero-banner-article-top,
  + .hero-banner-membership-landing,
  + .browse-showroom-hero {
    margin-top: -89px;
    @include breakpoint(desktop-extra-sm) {
      margin-top: -73px;
    }
    @include breakpoint(tab-max) {
      margin-top: -69px;
    }
  }
  & + .teaser {
    &.hero-banner-article-top {
      margin-top: -89px;
      @include breakpoint(desktop-extra-sm) {
        margin-top: -73px;
      }
      @include breakpoint(tab-max) {
        margin-top: -69px;
      }
    }
  }
  & + .carousel-header {
    margin-top: 0px;
  }
}
.searchbar-open,
.gbh-of-scroll {
  .breadcrumb {
    .cmp-breadcrumb {
      z-index: auto;
    }
  }
}
// Manual PDP Breadcrumb Overlap
.breadcrumb + .manualPdpSummary {
  @include breakpoint(desktop-small) {
    margin-top: -89px;
  }
  @include breakpoint(tab-max) {
    margin-top: -69px;
  }
  .page-container {
    .product-details {
      @include breakpoint(desktop-small) {
        padding-top: 120px;
      }
      @include breakpoint(tab-max) {
        padding-top: 69px;
      }
    }
  }
}
body:has(.manualPdpSummary) {
  .breadcrumb {
    background: $cool-light-grey;
    z-index: 0;
  }
}
