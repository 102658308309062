.order-summary {
	background: $color-extra-light-gray;
	padding: 50px 40px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border: solid 1px $color-light-gray;

	&__checkout-btn {
		margin-bottom: 40px;
	}

	&__title {
		font-family: $font-light;
		font-size: 32px;
		line-height: 40px;
		letter-spacing: -1.6px;
		color: $color-slate;
		margin-bottom: 25px;
	}

	&__cart-number {
		font-family: $font-regular;
		font-size: 14px;
		line-height: 1.57;
		letter-spacing: -0.65px;
		color: $color-slate;
		margin-top: 10px;
	}

	&__shared-cart-number {
		font-family: $font-regular;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.45px;
		color: $color-slate;
		margin-bottom: 10px;
	}

	&__separator {
		border-top: 1px solid $color-dark-gray;
		margin: 25px auto;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
			&.estimated-total {
				margin-bottom: 0;
				align-items: baseline;
					.order-summary__item--label {
						font-size: 16px;
						line-height: 24px;
						letter-spacing: -0.35px;
						color: $color-slate;
						align-items: baseline;
					}
					.order-summary__item--value-large {
						font-family: $font-light;
						font-size: 32px;
						line-height: 40px;
						letter-spacing: -1.6px;
						color: $color-slate;
						align-items: baseline;
					}
			}

		&--small {
			.order-summary__item--label,
			.order-summary__item--value {
				font-family: $font-regular;
				font-size: 16px;
				line-height: 1.5;
				letter-spacing: -0.55px;
				color: $color-slate;
			}
		}

		&--label {
			font-family: $font-medium;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: -0.35px;
			color: $color-slate;
			display: flex;
			align-items: center;
		}

		&--value {
			font-family: $font-regular;
			font-size: 20px;
			line-height: 1.4;
			letter-spacing: -0.65px;
			color: $color-slate;
			@media screen and (min-width: 1024px) and (max-width: 1440px) {
				font-size: 18px;
			}

			&-large {
				font-family: $font-light;
				font-size: 32px;
				line-height: 1.25;
				letter-spacing: -1.6px;
				color: $color-slate;
				@media screen and (min-width: 1024px) and (max-width: 1440px) {
					font-size: 22px;
				}
			}
		}
	}
	&__price-shimmer {
		p {
			width: 100px;
		}
		&.--large {
			p {
				width: 120px;
			}
		}
	}
	&__close {
		cursor: pointer;
		padding-right: 15px;
	}
	&__input-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__input {
		width: 100%;
		margin-right: 20px;
		padding: 15px;
		border-radius: 4px;
		border: solid 1px $color-medium-gray;
		background-color: $color-white;
		font-family: $font-regular;
		font-size: 18px;
		line-height: 1.44;
		letter-spacing: -0.7px;
		color: $color-slate;
		&.--error {
			border-color: $color-error-red;
		}
	}
	&__promo-error {
		margin-top: 10px;
		font-family: $font-regular;
		font-size: 16px;
		line-height: 1.38;
		letter-spacing: -0.3px;
		color: $color-error-red;
	}
	&__zip-label {
		font-family: $font-regular;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.55px;
		color: $color-slate;
	}
	&__zip-cta {
		cursor: pointer;
		text-decoration: underline;
		font-family: $font-regular;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.55px;
		color: $color-slate;
		padding-left: 10px;
		&:hover {
			text-decoration: underline;
		}
	}
	&__tooltip-wrapper {
		position: absolute;
		z-index: 9;
		top: 10px;
		left: -10px;
	}
	&__tooltip--show {
		display: block;
		position: relative;
	}
	&__tooltip--hide {
		display: none;
	}
	&__tooltip-icon {
		cursor: pointer;
		width: 30px;
		height: 30px;
		margin-left: 10px;
	}

	@media screen and (max-width: 1023px) {
		margin-top: 50px;
		padding: 35px 32px;
		border-radius: 0;
		border: none;
		margin-left: -32px;
    	margin-right: -32px;	
		&__checkout-btn {
			display: none;
		}
		&__title {
			font-size: 26px;
			line-height: 34px;
			letter-spacing: -1.5px;
			margin-bottom: 28px;
		}
		&__item--value-large {
			font-size: 26px;
			line-height: 34px;
			letter-spacing: -1.5px;
		}
	}
	@media screen and (max-width: 480px) {
		padding: 35px 18px 20px;
		border-radius: 0;
		margin-left: -18px;
		margin-right: -18px;
		&__tooltip-wrapper {
			top: 20px;
			left: -90px;
		}
	}
}

.download-specs {
	padding: 50px 40px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: solid 1px $color-light-gray;
	border-top: none;
	background-color: #ffffff;

	&__cta {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}

	p,
	span {
		font-family: $font-regular;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.55px;
		color: $color-dark-gray;
		img {
			margin-left: 10px;
		}
	}
	span {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
	.download-specs {
		padding: 50px 40px 55px 40px;
	}
}