.history-module-with-video{
    max-width:1380px; 
    padding:4.375rem 0;
    margin:0 auto;
    @include breakpoint(desktop-mid){
        padding-right:2.25rem;
        padding-left:2.25rem;
    }
    @include breakpoint(tab-max){
        padding:50px 32px;
    }
    @include breakpoint(mobile){
       padding:50px 0;
    }
    .cmp-teaser{
        display:flex;
        justify-content:space-between;
        align-items: flex-start;
        @include breakpoint(desktop-lg){
            justify-content:flex-start;
        }
        @include breakpoint(tab){
            align-items: center;
        }
        @include breakpoint(mobile){
            flex-direction:column;
        }
        &__image{
            position: relative;
            margin-right:auto;
            display:flex;
            flex-direction:column;
            @include breakpoint(desktop-lg){
                margin-right:7.5rem;
            }
            @include breakpoint(tab){
                margin-right:68px;
            }
            @include breakpoint(mobile){
                width:100%;
                margin:0;
            }
            .hero-video{
                max-width:660px;
                min-height:372px;
                width:100%;
                height:100%;
                position: relative;
                object-fit:cover;
                @include breakpoint(desktop-extra-lite-lg){
                    min-height:260px;
                }
                @include breakpoint(tab-max){
                    min-height:unset;
                }
                @include breakpoint(tab){
                    max-width:340px;
                    min-height:192px;
                }
                @include breakpoint(mobile){
                    min-height:211px;
                    max-width:100%;
                }
            }
            .poster-img{
                width:100%;
                height:100%;
                position:absolute;
                top:0;
                left:0;
                display:block;
                z-index: 1;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .gbh-play-video{
                position: absolute;
                left: 50% !important;
                top: 50% !important;
                transform: translate(-50%, -50%);
                width: 8.75rem;
                height: auto;
                z-index: 2;
                opacity:1 !important;
                .playinside {
                    width: 100%;
                    height: auto;
                    background: transparent;
                    border: none;
                    &::before {
                        display: inline-block;
                        width: 2.875rem;
                        height: 3.1875rem;
                        content: "\e93b";
                        color:$white;
                        font-family:$icon-moon;
                        font-size:2.875rem;
                        line-height:100%;
                        left: 47% !important;
                        @include breakpoint(tab-max){
                            width: 2.0625rem;
                            height: 2.25rem;
                            font-size:2.25rem;
                        }
                    }
                    &::after{
                        content: " ";
                        font-size: .75rem;
                        letter-spacing: .125rem;
                        line-height: 1rem;
                        text-align: center;
                        color: $white;
                        font-family: $lato-regular;
                        display: flex;
                        justify-content: center;
                        padding-top: 30%;
                        @include breakpoint(tab-max){
                            padding-top: 1.21875rem;
                            font-size: .625rem;
                            letter-spacing: .10625rem;
                        }
                    }
                }
                &.active{
                    .playinside{
                        width: 100%;
                        height: auto;
                        background: transparent;
                        border: none;
                        &.desktop {
                            &::before {
                                display: inline-block;
                                width: 1rem;
                                height: 3.1875rem;
                                content: "\e933";
                                color:$white;
                                font-family:$icon-moon;
                                font-size:2.875rem !important;
                                left: 47% !important;
                                @include breakpoint(tab-max){
                                    width: 1rem;
                                    height: 2.25rem;
                                    font-size:2.25rem;
                                }
                            }
                            &::after{
                                content: " ";
                                font-size: .75rem;
                                letter-spacing: .125rem;
                                line-height: 1rem;
                                text-align: center;
                                font-family: $lato-regular;
                                display: flex;
                                justify-content: center;
                                color: $white;
                                left: 47% !important;
                                @include breakpoint(tab-max){
                                    padding-top: 1.21875rem;
                                    font-size: .625rem;
                                    letter-spacing: .10625rem;
                                }
                            } 
                        }
                    }
                    +.poster-img{
                        display:none;
                    }
                }
            }
            .cmp-img-caption{
                *{
                    @include ann-describtion(12px, $lato-regular, 16px);
                    margin-top:20px;
                    letter-spacing:0.22px;
                    @include breakpoint(mobile){
                        padding:0 18px;
                    }
                }
            }
        }
        &__content{
            min-width:33.75rem;
            margin:3.5rem 3.75rem 0 0;
            @include breakpoint(tab){
                max-width:340px;
                min-width:inherit;
                margin:0;
            }
            @include breakpoint(mobile){
                min-width:339px;
                width:100%;
                margin: 50px 0 0;
                padding: 0 18px;
                box-sizing: border-box;
            }
        .cmp-teaser__pretitle{
            h1, h2, h3, h4, h5, h6, p {
            @include ann-describtion(4.375rem, $playfair-regular,4.875rem);
            letter-spacing:-1.5px;
            margin-bottom:4.375rem;
            @include breakpoint(tab-max){
                @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                letter-spacing:-2px;
                margin-bottom:40px;
            }
        }
        }
        .cmp-teaser__title{
            h1, h2, h3, h4, h5, h6, p {
                font-family: $lato-regular;
                font-size: 1.125rem !important;
                line-height: 1.75rem !important;
                letter-spacing:normal !important;
            }
        }
        .cmp-teaser__description{
            max-width:30rem;
            margin-right:auto;
            *{
                font-family: $lato-regular;
                font-size:  1rem !important;
                line-height: 1.5rem !important;
                letter-spacing:normal !important;
                margin-top:20px !important;
                color: auto !important;
            }
        }
    }
    }
    &.video-right{
        .cmp-teaser{
            flex-direction:row-reverse;
            justify-content: space-between;
            @include breakpoint(tab){
                align-items:flex-end;
            }
            @include breakpoint(mobile){
                flex-direction:column-reverse;
            }
            &__image{
                margin:0;
            }
            &__content{
                margin:3.3125rem 7.5rem 0 3.75rem;
                @include breakpoint(tab){
                    margin:0 68px 23px 0;
                }
                @include breakpoint(mobile){
                    margin:0 0 50px;
                }
            }
        }
    }
}
.bg-dark-blue-two {
    .cmp-container {
        .teaser.history-module-with-video.video.video-left {
            .cmp-teaser {
                &__content {
            .cmp-teaser__description p{
                color: $white;
            }
            }
        }
        }
    }
} 