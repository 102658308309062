.my-service {
  &__active-service {
    .sort {
      &__drop-down {
        @include breakpoint(mobile) {
          left: 0px;
        }
      }
    }
  }
}
