.breadcrumb + .curated-hero-banner{
     margin-top:-89px;
     @include breakpoint(desktop-extra-lite-lg){
        margin-top:-73px;
     }
     @include breakpoint(tab-max){
        margin-top:-69px;
     }   
}

.teaser.curated-hero-banner, .plppage .teaser.curated-hero-banner{
    padding:0;
    .cmp-teaser{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content: center;
        min-height:388px;
        position: relative;
        z-index: 0;
        @include breakpoint(desktop-extra-lite-lg){
            min-height:322px;
        }
        @include breakpoint(tab-max){
            min-height:inherit;
        }
        &__image{
            width:100%;
            max-height:388px;
            min-height:388px;
            @include breakpoint(desktop-extra-lite-lg){
                max-height:322px;
            }
            @include breakpoint(tab-max){
                max-height:317px;
            }
            .cmp-image{
                max-height:inherit;
                min-height:inherit;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                    max-height:inherit;
                    min-height:inherit;
                }
            }
        }
        &__content{
            position: absolute;
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            color:$white;
            top: unset;
        }
        &__title, &__description{
            max-width:394px;
            margin:0 auto;
            @include breakpoint(desktop-extra-lite-lg){
                max-width:372px;
            }
            @include breakpoint(tab-max){
                max-width:340px;
            }
            @include breakpoint(mobile){
                max-width:339px;
            }
        }
        &__title{
            *{
                @include ann-describtion(3.375rem, $playfair-regular, 3.875rem);
                letter-spacing:-1px;
                @include breakpoint(desktop-extra-lite-sm){
                    @include ann-describtion(36px, $playfair-regular, 44px);
                    letter-spacing:-2px;
                }
            }
        }
        &__description{
            *{
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                letter-spacing:normal;
                margin-top:20px !important;
                padding-top: 0;
                @include breakpoint(desktop-extra-lite-sm){
                    @include ann-describtion(16px, $lato-regular, 24px);
                    margin-top:10px !important;
                }
            }
        }
        &__action-container{
        padding-top: 0;
        }
        &__action-link{
            @include ann-describtion(11px, $lato-regular, 12px);
            letter-spacing:1.83px;
            margin-top:20px;
            padding-bottom:7px;
            color:$white !important;
            border-color: $cool-light-grey !important;
            @include breakpoint(desktop-lg){
                margin-top:20px;
            }
            @include breakpoint(tab-max){
                margin-top:20px;
            }
        }
    }
    &.content-center{
        .cmp-teaser{
            &__image{
                &:before{
                    content:none;
                }
                .cmp-image{
                    &::before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                        position: absolute;
                        left: 0;
                        opacity:0.4;
                    }
                }
            }
        }
    }
}
