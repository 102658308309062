@mixin  theme-color($bg-color, $title-font-color, $font-color, $border-color) {
  //bg color start here
  background-color:$bg-color;
  position: relative;
  .cmp-container{
    .teaser{
      border-color:$border-color;
      .cmp-teaser{
        &__pretitle{
          color:$title-font-color;
        }
        &__title{
          h1,h2,h3,h4,h5,h6,p{
            color:$title-font-color;
          }
        }
        &__description{
          h1,h2,h3,h4,h5,h6{
            color:$title-font-color;
          }
          p{
            color: $font-color;
            ol,ul{
              li{
                color:$font-color;
                a{
                  color:$font-color;
                  text-decoration:none;
                  &:hover{
                    text-decoration: none;
                  }
                }
              }
            }
            a{
              text-decoration:none;
              color:$font-color;
              &:hover{
                text-decoration: none;
              }
            }
            b, strong, i{
              color:$font-color;
            }
          }
        }
      }
      .cmp-img-caption{
         color:$title-font-color;
         h1,h2,h3,h4,h5,h6{
           color:$title-font-color;
         }
      }
    }
    .carousel{
      background: transparent;
      overflow: hidden;
      .cmp-carousel{
        background: transparent;
        &__text{
          h1, h2, h3, h4, h5, h6{
            color: $title-font-color;
          }
          p{
            color:$font-color;
          }
        }
        &__content{
          background: transparent;
        }
        &__item{
          background: transparent;
        }
        .teaser{
          .cmp-teaser{
            &__description{
              p{
                color: $font-color;
              }
            }
            &__title{
              h1, h2, h3, h4, h5, h6{
                color: $title-font-color;
              }
              p{
                color:$font-color;
              }
            }
            &__action-link{
              border-color: $font-color;
              color: $font-color;
            }
          }
        }
      }
    }
    .text{
      .cmp-text{
          border-color:$border-color;
          h1,h2,h3,h4,h5,h6{
            color:$title-font-color;
          }
          p{
            color:$font-color
          }
      }
    }
  }
 
}

// teaser theme
@mixin teaser-theme($bg-color, $eyebrow-text, $teasertitle-color,  $descp-color, $font-color, $primary-btn-bg, $primary-btn-text, $secondary-btn-border, $secondary-btn-text) {
  .cmp-container{
    .teaser{
      .cmp-teaser {
        &__content{
            background-color:$bg-color;
        }   
        &__pretitle{
            h1,h2,h3,h4,h5,h6,p{
                color:$eyebrow-text;
            }
        }
        &__title {
            h1,
            h2,
            h3,
            h4, 
            h5, 
            h6,p {
                color: $teasertitle-color;
            }
        }
        &__description {
            h1,h2,h3,h4,h5,h6{
                color:$teasertitle-color;
            }
            p {
                color: $descp-color;
                ol,
                ul {
                    li {
                        color: $font-color;
                        a {
                            color: $font-color;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                a {
                    text-decoration: underline;
                    color: $font-color;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                b,
                strong,
                i {
                    color: $font-color;
                }
            }
        }
        &__action-link {
            border-color: $font-color;
            color: $font-color;
            &.gbh-primary-button{
                background: $primary-btn-bg;
                color:$primary-btn-text;
                border:none;
            }
            &.gbh-secondary-button{
                background: transparent;
                border-color:$secondary-btn-border;
                color:$secondary-btn-text;
            }
        }
    }
    .cmp-img-caption{
        color: $teasertitle-color;
    }
    &.full-bleed-image-hover-content{
      .cmp-teaser{
        &__content{
          background-color:$bg-color !important;
        }
        &__action-link {
          border-color: $font-color !important;
          color: $font-color !important;
          &.gbh-primary-button{
              background: $primary-btn-bg;
              color:$primary-btn-text !important;
              border:none !important;
          }
          &.gbh-secondary-button{
              background: transparent;
              border-color:$secondary-btn-border !important;
              color:$secondary-btn-text !important;
          }
        }
      }
    }
    }
  }
 
}
// White Theme
.bg-white, .bg-calcite {
  @include theme-color($white, $text-dark-grey, $text-medium-dark-grey, $cool-dark-grey);
  @include teaser-theme($white, $text-dark-grey, $text-dark-grey, $text-medium-dark-grey, $color-light-blue, $color-medium-blue, $white, $color-medium-blue, $color-medium-blue);
}
// dark theme
.bg-dark{
  @include theme-color($charcoal, $white, $white, $cool-dark-grey);
  @include teaser-theme($charcoal, $white, $white, $white, $white, $color-medium-blue, $white, $white, $white);
}
// Light theme
.bg-light{
  @include theme-color($half-white, $slate, $slate, $cool-dark-grey);
  @include teaser-theme($half-white, $text-dark-grey, $text-dark-grey, $text-medium-dark-grey, $color-light-blue, $color-medium-blue, $white, $color-medium-blue, $color-medium-blue);
}
// BG Warm Gray Medium
.bg-warm-gray-medium{
  @include theme-color($color-medium-grey, $text-dark-grey, $text-medium-dark-grey, $cool-dark-grey);
  @include teaser-theme($color-medium-grey, $text-dark-grey, $text-dark-grey, $text-medium-dark-grey, $color-light-blue, $color-medium-blue, $white, $color-medium-blue, $color-medium-blue);
}
// BG Warm Gray Light
.bg-warm-gray-light, .bg-color-gray-light{
  @include theme-color($color-light-grey, $text-dark-grey, $text-medium-dark-grey, $cool-dark-grey);
  @include teaser-theme($color-light-grey, $text-dark-grey, $text-dark-grey, $text-medium-dark-grey, $color-light-blue, $color-medium-blue, $white, $color-medium-blue, $color-medium-blue);
}
// BG Blue Medium
.bg-dark-blue-two, .bg-color-charcoal{
  @include theme-color($color-medium-blue, $white, $white, $cool-dark-grey);
  @include teaser-theme($color-medium-blue, $white, $white, $white, $white, $color-medium-blue, $white, $white, $white);
}
// BG Blue Dark
.bg-dark-blue-three{
  @include theme-color($color-dark-blue, $white, $white, $text-medium-dark-grey);
  @include teaser-theme($color-dark-blue, $white, $white, $white, $white, $color-medium-blue, $white, $white,$white);
}
// BG Plumb Medium
.bg-medium-plumb {
  @include theme-color($color-medium-plump, $white, $white, $cool-dark-grey);
  @include teaser-theme($color-medium-plump, $text-dark-grey, $text-dark-grey, $text-medium-dark-grey, $color-light-blue, $color-medium-blue, $white, $color-medium-blue, $color-medium-blue);
}
// BG Cool Gray 
.bg-cool-grey-light{
  @include theme-color($cool-light-grey, $text-dark-grey, $text-medium-dark-grey, $cool-dark-grey);
  @include teaser-theme($cool-light-grey, $text-dark-grey, $text-dark-grey, $text-medium-dark-grey, $text-dark-grey, $color-medium-blue, $white, $color-medium-blue, $color-medium-blue);
}
// text-theme
@mixin text-theme($font-color){
  color: $font-color;
  .cmp-teaser{
    &__content{ 
      color: $font-color;
     }
    &__pretitle{
      h1,h2,h3,h4,h5,h6,p{
        color: $font-color;
      }
    }
    &__title{
      h1,h2,h3,h4,h5,h6,p{
        color: $font-color;
      }
    }
    &__description{
      p{
        color: $font-color;
        ol,ul{
          li{
            color:$font-color;
            a{
              color:$font-color;
              text-decoration:none;
              &:hover{
                text-decoration: none;
              }
            }
          }
        }
        a{
          text-decoration:none;
          color:$font-color;
          &:hover{
            text-decoration: none;
          }
        }
        b, strong, i{
          color:$font-color;
        }
      }
    }
    &__action-container{
      .cmp-teaser__action-link{
        border-color: $font-color;
        color: $font-color;
      }
    }
  }
  .cmp-carousel{
    background: transparent;
    &__text{
      h1, h2, h3, h4, h5, h6{
        color: $font-color;
      }
    }
    &__content{
      background: transparent;
    }
    &__item{
      background: transparent;
    }
    .teaser{
      .cmp-teaser{
        &__description{
          p{
            color: $font-color;
          }
        }
        &__title{
          h1,h2,h3,h4,h5,h6,p{
            color: $font-color;
          }
        }
        &__action-link{
          border-color: $font-color;
          color: $font-color;
        }
      }
    }
  }
}
.fc-black{
  @include text-theme($slate);
}
.fc-white{
  @include text-theme($white);
}
.fc-stone{
  @include text-theme($text-dark-grey);
}
.container.responsivegrid.bg-warm-gray-light.aem-GridColumn.aem-GridColumn--default--12 {
    float: left;
    clear: none;
    width: 100%;
} 