.order-summary {
    @include breakpoint (mobile) {
        padding: 35px 18px 39px;
    }
    @include breakpoint (tab) {
        padding: 39px 36px 39px;
    }
    &__input-group > .small  {
        line-height: 24px;
    }
}

.order-summary__title {
    @include breakpoint (mobile) {
        margin-bottom: 23px;
    }
    @include breakpoint (tab) {
        margin-bottom: 23px;
    }
}
.check-out {
.check-out__grid {
    div.check-out__grid-steps {
        @include breakpoint (mobile) {
            margin-bottom: 0 !important;
        }
        @include breakpoint (tab) {
            margin-bottom: 47px !important;
        }
    }
}
.check-out__support {
    @include breakpoint (mobile) {
        margin-left: 0 !important;
    }
}
}
