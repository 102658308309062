.news-letter-sign-up{
    .modal-popup{
        &.show-modal-popup{
            z-index: 1003;
        }
        .modal-popup-content{
            height: 526px;

            @include breakpoint(mobile){
                height: 521px !important;
            }
            @include breakpoint(tab){
                height: 481px !important;
                min-height: unset !important;
            }

            .modal-popup-body{
                padding-top: 75px;
                @include breakpoint(mobile){
                    padding-top: 70px;
                }
                @include breakpoint(tab){
                    padding-top: 65px;
                }
            }

            .close-button{
                right: 32.39px;
                top: 34.5px;
                @include breakpoint(mobile){
                    right: 24.29px;
                    top: 24.5px;
                }
                @include breakpoint(tab){
                    right: 37.82px;
                    top: 32.03px;
                }

                img{
                    height: 21px;
                    width: 21.21px;
                }
                @include breakpoint(mobile){
                    right: 24.5px;
                    top: 24.5px;
                }
            }
        }
    }

    &__header{
        margin-bottom: 15px;
        p{
            font-family: $playfair-display-regular;
            color: $text-dark-grey;
            @include text-style (36px, 0px, -2px);
            height: 48px;
            width: 100%;
        }
    }

    &__subcopy{
        p{
            font-family: $lato-regular;
            color: $text-medium-dark-grey;
            @include text-style (16px, 24px, 0px);
        }
    }

    &__email-input{
        font-family: $lato-regular;
        color: $text-light-grey;
        @include text-style (18px, 26px, 0px);
        border: 1px solid $cool-dark-grey;
        border-radius: 3px;
        background-color: $color-white;
        padding: 16px 0px 18px 15px;
    }

    &__button{
        font-family: $lato-bold;
        color: $color-white;
        @include text-style (12px, 24px, 2.5px);
        text-transform: uppercase;
        padding: 16.8px 0px 19.2px 0px;
        background-color: $color-medium-blue-cap;
        @media screen and (max-width: 992px){
            margin-top: 25px;
        }
        @include breakpoint(desktop-small){
            margin-top: 35px;
        }
    }

    &__content{
        .recaptcha-links{
            font-family: $lato-regular;
            color: $text-medium-dark-grey;
            @include text-style (13px, 20px, .2px);
            margin: 20px 0;
        }
    }

    &__policies{
        display: block;
        a{
            font-family: $lato-regular;
            color: $text-medium-dark-grey;
            @include text-style (13px, 20px, .2px);
            margin-right: 20px;
            @include breakpoint(mobile){
                margin-bottom: 15px;
                display: inline-block;
            }
        }
        a:last-child{
            margin-right: 0;
        }
    }
} 