//Order Confirmation
.container {
  .thank-you-page-container {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: $font-regular;
    flex-direction: column;
    @include breakpoint(desktop-large) {
      padding: 0 50px;
    }
    @include breakpoint(mobile-landscape){
      padding: 0 32px;
    }
    @include breakpoint(mobile-potrait){
      padding: 0 18px;
    }
    .thankyou-container {
      max-width: 872px;
      @include breakpoint(mobile-landscape) {
        max-width: 748px;
      }
      @include breakpoint(mobile-potrait) {
        max-width: 100%;
      }
      .order-details {
        padding: 0;
      }
      .title {
        font-size: 2.625rem;
        line-height: 3.375rem;
        letter-spacing: -0.0731rem;
        color: $slate;
        font-weight: 300;
        font-family: $playfair-regular;
        @include breakpoint(tab-max) {
          font-size: 28px;
          line-height: 34px;
          letter-spacing: -0.78px;
        }
      }
      .order-number {
        font-size: 20px;
        margin: 30px 0 20px 0;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $slate;
        font-weight: 400;
        font-family: $font-regular;
        span {
          font-weight: 500;
          color: $charcoal;
          letter-spacing: -0.4px;
          font-family: $font-medium;
        }
      }
      .order-message,
      p.order-message {
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: -0.35px;
        font-weight: 400;
        line-height: 24px;
        color: $slate;
        font-family: $font-regular;
        span {
          color: $charcoal;
          letter-spacing: -0.35px;
          font-weight: 500;
          font-family: $font-medium;
        }
      }
      .order-info {
        p {
          font-size: 16px;
          letter-spacing: -0.4px;
          font-weight: 400;
          line-height: 24px;
          font-family: $font-regular;
          span {
            color: $charcoal;
            letter-spacing: -0.35px;
            font-weight: 500;
            + span {
              color: $slate;
              font-weight: 400;
              font-family: $font-regular;
            }
          }
          + p {
            margin-top: 10px;
          }
        }
      }
      .btn-group {
        display: flex;
        align-items: center;
        margin-top: 40px;
        justify-content: center;
        gap: 20px;
        @include breakpoint(mobile) {
          width: 100%;
          display: block;
        }
        @include breakpoint(tab-max) {
          button {
            &.button-hide-mobile {
              display: none;
            }
          }
        }
        @media print {
          display: none !important;
        }
        .view-order-button,
        button {
          border: 1px solid $color-medium-blue;
          border-radius: 1px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          text-decoration: none;
          white-space: nowrap;
          padding: 13px 27px;
          height: 50px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          line-height: 24px;
          letter-spacing: 2.5px;
          font-size: 12px;
          color: $color-medium-blue;
          font-weight: 500;
          font-family: $lato-bold;
          text-transform: uppercase;
          &:hover {
            background-color: $cool-light-grey;
            transform: scale(0.95);
          }
        }
      }
    }
  }
}
