.order-confirmation {
	&__container {
		@media screen and (min-width: 767px) {
			margin-top: 60px;
			padding: 0 2.6rem!important;
			max-width: 100%!important;
		}
		@media screen and (max-width: 767px) {
			&.kf-react-container{
				padding: 0 1.125rem!important;
			}
		}
	}
	&__message {
		background-color: $color-off-white;
		padding-top: 80px;
		padding-bottom: 100px;
		@media screen and (min-width: 767px) {
			.container{
				&.kf-react-container{
					padding: 0 2.6rem!important;
					max-width: 100%!important;
				}
			}
		}
	}
	&__thank-you, &__no-order {
		font-family: $font-light;
		font-size: 60px;
		line-height: 1.13;
		letter-spacing: -3.1px;
		color: $color-slate;
		margin-bottom: 25px;
	}
	&__description {
		font-family: $font-regular;
		font-size: 20px;
		line-height: 1.4;
		letter-spacing: -0.65px;
  		color: $color-slate;
	}
	&__wrapper {
		display: grid;
		grid-template-columns: 3fr 1fr;
		column-gap: 60px;
		margin-top: 34px;
		@media screen and (max-width: 1024px) {
			margin-top: 30px;
		}
	}
	&__content {
		order: 1;
		grid-row-start: 1;
		grid-row-end: 2;
    }
	&__order-summary {
		max-width: 420px;
		order: 2;
		grid-row-start: 1;
		grid-row-end: 3;
		@media screen and (min-width: 1380px) {
			min-width: 420px; 
		}
	}
	&__shipment-details {
		order: 3;
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		@media screen and (max-width: 1024px) {
			margin-bottom: 15px;
		}
	}
	&__title {
		font-family: $font-light;
		font-size: 60px;
		line-height: 1.13;
		letter-spacing: -3.1px;
		color: $color-slate;
	}
	&__print {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: end;
		span {
			font-family: $font-regular;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: -0.55px;
			color: $color-dark-gray;
		}
		img {
			margin-right: 10px;
			width: 17px;
			height: 17px;
		}
	}
	&__details {
		margin-bottom: 10px;
		span {
			font-family: $font-light;
			font-size: 26px;
			line-height: 1.31;
			letter-spacing: -1.55px;
			color: $color-slate;
		}
	}
	.userExistAccount{
		.order-confirmation__customer-info {
			h2{  	
				margin: 80px 0 32px 0;
				@media screen and (max-width: 1024px) {
					margin: 50px 0 25px 0;
				}
			}
		}
	}
	&__customer-info {
		h2{  	
			font-family: $font-medium;
			font-size: 20px;
			line-height: 1.4;
			letter-spacing: -0.4px;
			color: $color-charcoal;
			margin: 0px 0 32px 0;
		}
	}
	&__info-row {
		display: grid;
		column-gap: 20px;
		grid-template-columns: repeat(4, 1fr);
	}
	&__info-tile {
		section {
			margin-bottom: 5px;
			font-family: $font-medium;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: -0.35px;
			color: $color-dark-gray;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		li {
			font-family: $font-regular;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: -0.55px;
			color: $color-dark-gray;
		}
	}
	&__buttons {
		margin: 60px 0;
		display: flex;
		column-gap: 60px;
	}
	&__shipment {
		margin-top: 45px;
		&-title {
			display: flex;
			font-family: $font-medium;
			font-size: 20px;
			line-height: 1.4;
			letter-spacing: -0.4px;
			color: $color-charcoal;
			img {
				cursor: pointer;
				margin-left: 20px;
				width: 30px;
				height: 30px;
			}
		}
	}
	&__tooltip-wrapper {
		position: absolute;
		z-index: 9;
		bottom: 10px;
		left: 10px;
	}
	&__oneClick-email-label{
		.order-confirmation__tooltip-wrapper {
			bottom: -8px;
			left: -13px;
		}
	}
	&__oneClick-AccountTypeLable{
		.order-confirmation__tooltip-wrapper {
			bottom: 6px;
    		left: 131px;
			bottom: 6px;
			@media only screen and (min-width: 1025px) and (max-width:1218px) {
				left: 112px;
			}
		}
		.tooltip__container{
			 @media screen and (max-width: 768px) {
				width: 224px;
			 }
			 @media screen and (max-width: 374px) {
				width: 160px;
			 }
		}
		
	}
	&__tooltip--show {
		display: block;
		position: relative;
	}
	&__tooltip--hide {
		display: none;
	}
	&__support {
		border-radius: 0 0 5px 5px;
  		border: solid 1px $color-light-gray;
		padding: 50px 40px;
		&-help {
			padding-bottom: 40px;
			border-bottom: 1px solid $color-dark-gray;
		}
		&-call {
			padding-top: 40px;
			text-align: left;
		}
		&-title {
			margin-bottom: 15px;
			font-family: $font-light;
			font-size: 32px;
			line-height: 1.25;
			letter-spacing: -1.6px;
			color: $color-slate;
		}
		&-subtitle {
			margin-bottom: 10px;
			font-family: $font-light;
			font-size: 26px;
			line-height: 1.31;
			letter-spacing: -1.5px;
			color: $color-slate;
		}
		&-label {
			font-family: $font-regular;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: -0.55px;
			color: $color-dark-gray;
			&.--phone {
				display: inline;
				cursor: pointer;
				font-size: 14px !important;
			}
			a{
				text-decoration: underline;
				color:$color-dark-gray;
			}
		}
	}
	&__breadcrumb {
		margin-top: 30px;
		nav {
			font-family: $font-regular;
			font-size: 14px;
			line-height: 1.71;
			letter-spacing: -0.3px;
			color: $color-dark-gray;
		}
	}
	&__breadcrumb-link {
		cursor: pointer;
		font-family: $font-regular;
		font-size: 14px;
		line-height: 1.71;
		letter-spacing: -0.3px;
		color: $color-dark-gray;
		text-decoration: none;
	}
	&__download-btn {
		padding : 0 60px;
	}
	.order-summary {
		&__item {
			margin-bottom: 20px;
				&.order-total{
					margin-bottom: 0;
					align-items: baseline;
					.order-summary__item--label {
						font-size: 16px;
						line-height: 24px;
						letter-spacing: -0.35px;
						color: $color-slate;
						align-items: baseline;
					}
					.order-summary__item--value-large {
						font-family: $font-light;
						font-size: 32px;
						line-height: 40px;
						letter-spacing: -1.6px;
						color: $color-slate;
						align-items: baseline;
					}
			}
		}
		
	}
	.cart-item {
		.cart-item__label {
			&.--quantity {
				line-height: 28px;
				color: $color-charcoal;
				margin-left: 45px;
			}
		}
	}
	@media screen and (max-width: 767px) {
		&__message {
			padding-top: 50px;
			padding-bottom: 116px;
		}
	}
	@media screen and (max-width: 1024px) {
		&__container {
			margin-top: 20px;
		}
		&__message {
			padding-top: 50px;
			padding-bottom: 88px;
		}
		&__thank-you {
			font-size: 2.5rem;
			line-height: 2.875rem;
			letter-spacing: -2.5px;
			margin-bottom: 15px;
		}
		&__wrapper {
			display: grid;
			grid-template-columns: 1fr;
			column-gap: 0;
		}
		&__order-summary {
			grid-row-start: 2;
			grid-row-end: 3;
			width: 100%;
			max-width: 100%;
			margin: 30px 0 0 0;
		}
		&__print {
			display: none;
		}
		&__title {
			font-size: 40px;
			line-height: 1.15;
			letter-spacing: -2.5px;
		}
		&__info-row {
			row-gap: 30px;
			column-gap: 70px;
			grid-template-columns: 1fr 2fr;
		}
		&__details {
			margin-bottom: 5px;
			span {
				font-family: $font-regular;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.55px;
			}
		}
		&__customer-info {
			margin-top: 0px;
			margin-bottom: 25px;
		}
		&__shipment-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&__tooltip-wrapper {
			bottom: 25px;
			left: unset;
			right: 0;
		}
		&__buttons {
			display: flex;
			.button {
				width: 100%;
				margin-bottom: 0px;
			}
		}
		&__shipment {
			margin-top: 35px;
		}
		&__support {
			border: none;
			border-radius: 0;
			padding: 50px 0 0;
			&-help {
				padding-bottom: 20px;
			}
			&-call {
				padding-top: 20px;
				text-align: left;
			}
			&-title {
				letter-spacing: -1.5px;
			}
		}
	}
	@media screen and (max-width: 767px) {
		&__support {
			&-help {
				padding-bottom: 40px;
			}
			&-call {
				padding-top: 40px;
				padding-bottom: 20px;
			}
		}
		.cart-item {
			.cart-item__label {
				&.--quantity {
					text-align: center;
					margin-bottom: 0;
					line-height: 22px;
				}
			}
			&__qty-price{
				margin-bottom: 25px;
			}
		}
	}
	@media screen and (max-width: 576px) {
		&__info-tile {
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__info-row {
			row-gap: 30px;
			column-gap: 0;
			grid-template-columns: 1fr;
		}
		&__buttons {
			margin-top: 45px;
		}
	}
	.viewOrderButton {
		font-size: 1.25rem;
		font-family: $font-medium;
		line-height: 1.25rem;
		letter-spacing: -0.025rem;
		color: $color-slate;
		margin: 5px 0 0 0;
		text-decoration: underline;
		cursor: pointer;
	}
	&__oneClick-account-section {
		@media screen and (max-width: 1024px) {
			margin: 60px 0 0 0;
		}
		padding: 30px 0 40px 0;
		margin: 50px 0 0 0;
		border-top: 1px solid;
		border-bottom: 1px solid;
		color: $color-dark-gray;
	}
	&__oneClick-title {
		font-size: 1.25rem;
		font-family: $font-medium;
		line-height: 1.75rem;
		letter-spacing: -0.025rem;
		color: $color-slate;
	}
	&__oneClick-description {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.0344rem;
		color: $color-slate;
		margin: 5px 0 0 0;
	}
	&__oneClick-form {
   		 margin: 22px 0 0 0;
		 @media screen and (max-width: 1024px) {
			margin: 27px 0 0 0;
		}
	}
	&__oneClick-email-label {
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.0219rem;
		font-family: $font-medium;
		color: $color-slate;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		-webkit-box-align: center;
    	-webkit-align-items: center;
        -ms-flex-align: center;
		img{
			margin: 0 0 0 10px;
			cursor: pointer;
		}
	}
	&__order_detail_title {
		font-size: 2.5rem;
		font-family: $font-light;
		line-height: 2.875rem;
		letter-spacing: -2.5px;
		color: $color-slate;
	}
	
	&__oneClick-emailid {
		letter-spacing: -0.55px;
		color: $color-dark-gray;
		margin: 0px 0 30px 0;
	}
	&__oneClick-email-form  {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		@media screen and (max-width: 1220px) {
			display: block;
		}
		.order-confirmation__oneClick-checkbox{
			.checkbox_default{
				color: $color-slate;
			}
		}
		.tooltip__container{
			ul{
				margin: 0 0 0 15px;
			}
			.password-tooltip {
				p{
					font-family: $font-regular;
					font-size: .875rem;
					line-height: .0981rem;
					letter-spacing: -0.0406rem;
					color: $color-dark-gray;
					margin: 0 0 8px 0;
					display: initial;
				}
			}
		}
	}
	&__oneClickPassword{
		.input-field__border{
			width: 339px;
			 @media screen and (max-width: 767px) {
				width: 100%;
			 }
		}
		.input-field{
			margin: 0 0 10px 0;
		}
		.password-requirements {
			letter-spacing: -0.0281rem;
			line-height: 1.375rem;
			@media screen and (max-width: 1024px) {
				margin: 0 0 30px 0px;
			}
		}
		.input-field__value {
			input{
				&::placeholder {
					color: $color-dark-gray;
				}
			}
		}
	}
	&__oneClick-AccountType{
		margin: 0 60px;
			@media screen and (max-width: 1220px) {
				margin: 0;
			}
	}
	&__oneClick-checkbox {
		 @media screen and (max-width: 1220px) {
    			margin: 30px 0 40px 0;
		 }
	}
	&__oneClick-AccountTypeLable{
		font-size: 1rem;
		font-family: $font-medium;
		line-height: 1.5rem;
		letter-spacing: -0.0219rem;
		color: $color-slate;
		img{
			margin: 0 0 0 10px;
			cursor: pointer;
		}
	}
	&__oneClick-AccountTypeList{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin: 10px 0 0 0;
		.radio-button__label{
			color: $color-dark-gray;
		}
		@media screen and (max-width: 260px) {
			display: block;
		}
	}
	&__oneClick-account-main {
		width: 79%;
		@media screen and (max-width: 1220px) {
			width: 100%;
		}
		.tooltip__container {
			span{
				line-height: 22px;
				letter-spacing: -0.45px;
			}
		}
	}
	&__oneClick-checkbox  {
		label{
			 color: $color-slate;
			 letter-spacing: -0.55px;
			 margin: 0 0 10px 0px;
		}
	}
	&__oneClick-checkboxInfo {
		font-size: .875rem;
		line-height: 1.375rem;
		color: $color-dark-gray;
		letter-spacing: -0.0281rem;
		@media screen and (max-width: 767px) {
			padding: 0 10px 0 28px;
		}
		a{
			font-size: .875rem;
			line-height: 1.375rem;
			color: $color-dark-gray;
			letter-spacing: -0.0281rem;
			text-decoration: underline;
		}
	}
	&__oneClick-button {
		.button{
			padding: 0 60px;
			@media screen and (max-width: 1220px) {
					width: 100%;
			}
		}
	}
	&__buttons{
		&.custom_button{
			.kf-react-button.button,.order-confirmation__download-btn{
				background: unset!important;
				padding: 0 0 5px 0 !important;
				height: unset!important;
				font-size: 1.25rem;
				line-height: 1.25rem;
				font-family: $font-medium;
				letter-spacing: -0.025rem;
				border-bottom: 1px solid $color-slate;
				border-top: unset;
				border-left: unset;
				border-right: unset;
				@media screen and (max-width: 1024px) {
					width: auto;
					margin-bottom: 0px;
				}
			}
		}
	}
	&__order_title_printsec{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	}    
}

.order-items {
	margin-top: 40px;
	&__details {
		display: flex;
		justify-content: space-between;
		div {
			font-family: $font-regular;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: -0.55px;
			color: $color-slate;
		}
	}
	&__td {
		padding-bottom: 20px;
        margin-top: 15px;
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
    }
	.combo-cart-items{
		.cart-item {
			padding: 0px;
		}
	}
	.cart-item {
		padding: 35px 0px 0px;
		&__item-of {
			padding: 15px 31px 15px 0;
		}
		&__qty-price {
			.cart-item__discount-container{
			  padding-right: 10px;
			  @media only screen and (max-width: 480px){
				padding-right: 0px;
			  }
			}
		  }
	}
	@media screen and (max-width: 480px) {
		.cart-item{
			&__item-of {
				padding: 15px 31px 15px 0;
			  }
		}		
	}	
}

@media print { 
	.order-confirmation {
		&__print, 
		&__buttons,
		&__breadcrumb,
		&__message,
		&__support-help {
			display: none !important;
		}
		&__shipment-title {
			img { 
				display: none !important;
			}
		}
		.cart-item {
			&__link {
				span {
					display: none !important;
				}
			}
			&__sku {
				white-space: nowrap;
				display: block;
			}
			&__image {
				margin-right: 15px;
			}
		}
	}

	.carousel-slick.carousel.carousel-promo-banner.bg-slate {
		display: none !important;
	}
}
@media only screen and (max-width: 480px){
	.cart-item {
	  padding-bottom: 0;
	  .cart-item__label {
		&.--mobile {
			display: block;
		}
		&.--quantity {
			margin-left: 0 !important;
		}
	}
	  &__qty {
		.cart-item__label{
			&.--mobile {
				text-align: left;
				margin-bottom: 10px;
			}
		  }
	  }
		  &__label{
			&.--mobile {
				display: block;
				margin-bottom: 10px;
			}
		  }
	

	.order-confirmation{
		.cart-item{
			.cart-item__label{
				&.--quantity {
					margin-left: 20px;
				}
			}
		}
	}
	.combo-cart-items {
	  padding-bottom: 0;
	  &__note {
		  padding-left: 0px !important;
	  }
	}
	.order-confirmation__shipment {
	  margin-top: 50px;
	}
  }
  .order-items {
	&__td {
		margin-top: 10px !important;		
	}
}
}