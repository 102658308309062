.cart-info {
    padding: 30px 40px;

    @media screen and (max-width: 480px) {
        padding: 30px 0px;
    }

    &__support {
        display: none;
    }

    &__title {
        margin-bottom: 10px;
        font-family: $font-light;
        font-size: 26px;
        line-height: 1.31;
        letter-spacing: -1.55px;
        color: $color-slate;
    }
    &__desc {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        span {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    @media screen and (max-width: 990px) {
        padding: 40px 0;
        &__support {
            display: block;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
        &__link {
            cursor: pointer;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
        }
    }
}