.shipping-alert {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);

    &__modal {
        background: $color-white;
        margin: auto;
        position: relative;
        width: 1020px;
        padding: 50px 60px;
        max-height: 90vh;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        column-gap: 100px;
    }
    &__close-icon {
        cursor: pointer;
        order: 1;
        img {
            width: 16px;
            height: 16px;
        }
    }
    &__title {
        order: 0;
        font-family: $font-light;
        font-size: 36px;
        line-height: 1.22;
        letter-spacing: -1.7px;
        color: $color-slate;
    }
    &__description {
        margin-top: 15px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
    }
    &__button {
        float: right;
        margin-top: 15px;
        width:259px;
        padding: 0 60px;
    }
    .cart-items {
        &__td {
            padding-bottom: 15px;
            margin-top: 50px;
            line-height: 28px;
        }
    }
    .cart-item {
        padding:35px 0px 0px;
         .radio-button__label{
            margin-right: 0px !important;
        } 
        &__radio-group {
            height: 24px;
        }           
        &__label {
            margin-bottom: 0px;
            &.--quantity {
                font-size: 20px;
                margin-left: 0px;
                line-height: 28px;
                letter-spacing: -0.65px;
                font-family: $font-regular;
                color: $color-dark-gray;
                @media screen and (max-width: 991px) {
                    color: $color-charcoal;
                    font-family: $font-regular;
                    font-size: 16px;
                    letter-spacing: -0.8px;
                    line-height: 22px;
                }
                @media screen and (max-width: 768px) {
                    text-align: left;
                    margin-left: 25px;
                }
                @media screen and (max-width: 480px) {
                    text-align: left !important;
                    margin-left: 25px !important;
                }
            }
        }
        &__sku{
           letter-spacing: -0.45px; 
        }
        &__unit-price {
            font-family: $font-regular;
            @media screen and (max-width: 480px) {
            padding-top: 10px;
            }
        }
        &__total-price {
            @media screen and (max-width: 480px) {
           padding-top: 10px;
            }
        }
        &__stock-msg {
            margin-top: 0px;
            display: none;
        }
        &__qty-price {
            margin-bottom: 15px;
            @media screen and (max-width: 480px) {
                margin-bottom: 40px !important;
            }
        }
    }

    &.verify-address {
        .shipping-alert__modal {
            width: 900px;
            padding: 70px 60px;
        }
        .shipping-alert__close-icon {
            position: absolute;
            top: 32px;
            right: 35px;
        }
        .verify-address__ctas {
            display: flex;
            margin-top: 60px;
            column-gap: 30px;
        }
        .verify-address__alert {
            margin-top: 15px;
            color: $color-error-red;
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 22px;
        }
        .address-choice {
            margin-top: 40px;
            &__title {
                padding-bottom: 15px;
                color: $color-charcoal;
                font-family: $font-medium;
                font-size: 20px;
                letter-spacing: -0.4px;
                line-height: 28px;
            }
            .radio-button {
                align-items: unset;
                &__marker {
                    margin-top: 4px;
                }
                &__label {
                    padding-left: 18px;
                    color: $color-dark-gray;
                    font-family: $font-regular;
                    font-size: 16px;
                    letter-spacing: -0.55px;
                    line-height: 24px;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        &__modal {
            margin: 0;
            width: 100%;
            height: 100vh;
            max-height: none;
            padding: 20px 18px 50px;
        }
        &__header {
            flex-direction: column-reverse;
            row-gap: 15px;
            column-gap: 0;
        }
        &__close-icon {
            text-align: right;
        }
        &__title {
            font-size: 32px;
            letter-spacing: -2.2px;
            line-height: 40px;
        }
        &__button {
            width: 100%;
        }
        &.verify-address {
            .shipping-alert__modal {
                width: 100%;
                padding: 70px 32px 60px;
            }
            .shipping-alert__description {
                font-size: 14px;
                letter-spacing: -0.45px;
                line-height: 22px;
            }
            .shipping-alert__close-icon {
                position: absolute;
                top: 32px;
                right: 32px;
            }
            .verify-address__ctas {
                flex-direction: column;
                column-gap: 0;
                row-gap: 20px;
                .button {
                    width: 100%;
                }
            }
            .verify-address__alert {
                margin-top: 20px;
            }
            .address-choice {
                &__title {
                    padding-bottom: 20px;
                    font-size: 16px;
                    letter-spacing: -0.35px;
                    line-height: 24px;
                }
                .radio-button {
                    &__label {
                        padding-left: 15px;
                        font-size: 14px;
                        letter-spacing: -0.55px;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__modal {
            padding: 20px 32px 50px;
        }
        .cart-items {
            padding-top: 40px;
            &__th {
                display: none;
            }
        }
        &.verify-address {
            .shipping-alert__modal {
                padding: 70px 18px 60px;
            }
            .shipping-alert__close-icon {
                right: 18px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        &__modal {
            padding: 20px 18px 45px;
        }
    }
}