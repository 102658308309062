.progressive-loader {
    background-color: $cool-light-grey;
    padding: 0 110px 0 110px;
    @include breakpoint(mobile) {
        padding: 0 18px 0 18px;
    }
    @include breakpoint(tab) {
        padding: 0 32px 0 32px;
    }
    &__header-block {
        height: 392px;
        width: 100%;
        padding: 100px 0 0 0;
        @include breakpoint(mobile) {
            height: 304px;
            padding: 80px 0 0 0;
        }
        @include breakpoint(tab) {
            height: 282px;
            padding: 80px 0 0 0;
        }
    }
    &__row, &__filter-row, &__filter-contents {
        margin-left: 0;
        margin-right: 0;
    }
    &__title, &__description, &__details {
        border-radius: 5px;
        margin: auto;
    }
    &__title, &__description, &__details {
        background-color: $cool-medium-grey;
    }
    &__title, &__description, &__details {
        @include breakpoint(tab) {
            background-color: $cool-dark-grey;
        }
    }
    &__title {
        height: 60px;
        width: 320px;
        @include breakpoint(mobile) {
            height: 48px;
            width: auto;
        }
        @include breakpoint(tab) {
            height: 45px;
            width: 218px;
        }
    }
    &__description {
        height: 60px;
        width: 420px;
        margin-top: 20px;
        @include breakpoint(mobile) {
            width: 214px;
        }
        @include breakpoint(tab) {
            height: 60px;
            width: 304px;
            margin-top: 11px;
        }
    }
    &__details {
        height: 32px;
        width: 214px;
        margin-top: 20px;
        @include breakpoint(mobile) {
            width: 109px;
        }
        @include breakpoint(tab) {
            height: 32px;
            width: 109px;
            margin-top: 29px;
        }
    }
    &__result-view {
        display: none;
        @include breakpoint(mobile) {
            display: block;
        }
        @include breakpoint(tab) {
            display: block;
        }
    }
    &__result-block {
        @include breakpoint(mobile) {
            height: 30px;
            width: auto;
            margin-bottom: 35px;
        }
        @include breakpoint(tab) {
            height: 30px;
            width: auto;
            margin-bottom: 32px;
        }
    }
    &__result-heading, &__result-grid-view, &__result-list-view {
        @include breakpoint(mobile) {
            border-radius: 5px;
            background-color: $extra-light-gray;
        }
        @include breakpoint(tab) {
            border-radius: 5px;
            background-color: $extra-light-gray;
        }
    }
    &__result-heading {
        @include breakpoint(mobile) {
            height: 30px;
            width: 140px;
            float: left;
        }
        @include breakpoint(tab) {
            height: 30px;
            width: 109px;
            float: left;
        }
    }
    &__result-grid-view, &__result-list-view {
        @include breakpoint(mobile) {
            height: 22px;
            width: 22px;
            float: right;
            padding: 4.5px 0 3.5px 0;
        }
        @include breakpoint(tab) {
            height: 22px;
            width: 22px;
            float: right;
            padding: 0.5px 0 7.5px 0;
        }
    }
    &__result-list-view {
        @include breakpoint(mobile) {
            margin-right: 12px;
        }
        @include breakpoint(tab) {
            margin-right: 12px;
        }
    }
    &__filter-block {
        padding: 0;
    }
    &__filter-row {
        height: 53px;
        width: auto;
        @include breakpoint(mobile) {
            height: 52px;
        }
    }
    &__filter-heading {
        height: 30px;
        width: 240px;
        border-radius: 5px;
        background-color: $extra-light-gray;
        float: left;
        margin: 7px 0 16px 0;
        @include breakpoint(mobile) {
            width: 86px;
            margin: 15px 0 7px 0;
        }
        @include breakpoint(tab) {
            width: 101px;
            margin: 13px 0 9px 0;
        }
    }
    &__filter-sort-by {
        height: 53px;
        width: 154px;
        border-radius: 5px;
        background-color: $minor-light-grey;
        float: right;
        @include breakpoint(mobile) {
            height: 52px;
            width: 136px;
            background-color: $extra-light-gray;
        }
        @include breakpoint(tab) {
            height: 52px;
            width: 136px;
            background-color: $extra-light-gray;
        }
    }
    &__filter-contents {
        margin-top: 43px;
        @include breakpoint(mobile) {
            margin-top: 24px;
        }
        @include breakpoint(tab) {
            margin-top: 25px;
        }
    }
    &__filter-contents-heading {
        height: 300px;
        width: 100%;
        background-color: $extra-light-gray;
    }
    &__filter-contents-print {
        height: 40px;
        width: 100%;
        background-color: $extra-light-gray;
        margin-top: 60px;
    }
    &__filter-contents-share {
        height: 20px;
        width: 41.66%;
        background-color: $extra-light-gray;
        margin-top: 20px;
    }
    &__plp-products-image, &__plp-products-image-middle, &__plp-products-image-last {
        height: 466px;
        width: auto;
        @include breakpoint(mobile) {
            height: 219px;
            background-color: $cool-medium-grey;
        }
    }
    &__plp-products-image {
        background-color: $cool-medium-grey;
        @include breakpoint(tab) {
            height: 464px;
            background-color: $cool-dark-grey;
        }
    }
    &__plp-products-name {
        background-color: $cool-grey-medium;
    }
    &__plp-products-name, &__plp-products-name-middle, &__plp-products-name-last {
        height: 39px;
        width: auto;
        border-radius: 5px;
        margin-top: 24px;
        @include breakpoint(mobile) {
            height: 61px;
            border-radius: 5px;
            background-color: $cool-medium-grey;
            margin-top: 14px;
        }
        @include breakpoint(tab) {
            border-radius: 0px;
            background-color: $cool-dark-grey;
        }
    }
    &__plp-products-sub-name {
        background-color: $cool-grey-medium;   
    }
    &__plp-products-sub-name-last {
        background-color: $extra-light-gray;
    }
    &__plp-products-sub-name, &__plp-products-sub-name-middle, &__plp-products-sub-name-last {
        height: 15px;
        width: 54.2%;
        border-radius: 5px;
        margin-top: 10px;  
        @include breakpoint(mobile) {
            height: 35px;
            width: 50.6%;
            border-radius: 5px;
            background-color: $cool-medium-grey;
            margin-top: 14px;  
        }  
        @include breakpoint(tab) {
            width: 71.3%;
            border-radius: 0px;
            background-color: $cool-dark-grey;
        }  
    }
    &__plp-products-color {
        background-color: $cool-grey-medium;
    }
    &__plp-products-color, &__plp-products-color-middle, &__plp-products-color-last {
        height: 32px;
        width: auto;
        border-radius: 5px;
        margin-top: 18px;
        @include breakpoint(mobile) {
            display: none;
        }
        @include breakpoint(tab) {
            display: block;
            border-radius: 0px;
            background-color: $cool-dark-grey;
        }
    }
    &__filter-contents-block {
        padding: 0;
    }
    &__filter-details-block, &__plp-contents-block, &__plp-products, &__plp-products-middle, &__plp-products-last {
        float: left;
    }
    &__filter-details-block {
        padding: 0;
        @include breakpoint(mobile) {
            display: none;
        }
        @include breakpoint(tab) {
            display: none;
        }
    }
    &__plp-contents-block {
        padding: 0;
        width: 69%;
        max-width: 69%;
        margin-left: 59px;
        @include breakpoint(mobile) {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
        }
        @include breakpoint(tab) {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
        }
    }
    &__filter-details-block {
        margin-top: 1px;
        max-width: 24.51%;
    }
    &__plp-products, &__plp-products-middle, &__plp-products-last {
        width: 30.3%;
        margin-bottom: 100px;
        @include breakpoint(mobile) {
            width: 46%;
            margin-bottom: 62px;
        }
        @include breakpoint(tab) {
            width: 45%;
        }
    }
    &__plp-products-last {
        @include breakpoint(mobile) {
            display: none;
        }
        @include breakpoint(tab) {
            display: none;
        }
    }
    &__plp-products-middle, &__plp-products-last {
        margin-left: 30px;
        @include breakpoint(mobile) {
            margin-left: 19px;
        }
        @include breakpoint(tab) {
            margin-left: 68px;
        }
    }
    &__plp-products-image-middle, &__plp-products-name-middle, &__plp-products-sub-name-middle, &__plp-products-color-middle {
        background-color: $extra-light-gray;
        @include breakpoint(tab) {
            background-color: $cool-medium-grey;
        }
    }
    &__plp-image-bottom-row {
        background-color: $cool-grey-medium;
        @include breakpoint(tab) {
            background-color: $cool-dark-grey;
        }
    }
    &__plp-products-image-last, &__plp-products-name-last, &__plp-products-color-last, &__plp-sub-name-bottom-row {
        background-color: $minor-light-grey;      
    }
    &__plp-products-image-middle {
        @include breakpoint(tab) {
            height: 464px;
            background-color: $cool-medium-grey;
        }
    }
}