.featured-collection {
    padding: 70px 0 70px;
    @include breakpoint(tab){
        padding: 50px 0 50px;
    }
    @include breakpoint(mobile){
        padding:0;
    }
    .cmp-teaser {
        display: flex;  
        align-items: center;
        max-width:1600px;
        margin:0 auto;
        justify-content: space-between;
        z-index: 0;
        position: relative;
        @include breakpoint(tab){
            align-items:flex-start;
        }
        @include breakpoint(mobile){
            flex-direction:column;
        }
        &__image{
            .cmp-image{
                @include breakpoint(desktop-small){
                    max-width: 830px;
                    max-height: 791px;
                }
                @include breakpoint(tab-max){
                    max-width:375px;
                    max-height: 400px;
                }
                .cmp-teaser__image{
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        &__content {
            width: 380px;
            margin-left:auto;
            padding-right:2.25rem;
            box-sizing:content-box;
            margin-top: 28px !important;
            @include breakpoint(tab){
                width: 340px;
                margin-left:4.0625rem;
                margin-top: 12.5px !important;
            }
            @include breakpoint(mobile){
                width:100%;
                padding: 50px 1.125rem 50px;
                margin-left:0;
                box-sizing:border-box;
                margin-top: unset !important;
            }
        }
        &__pretitle {
            h1, h2, h3, h4, h5, h6, p {
            @include ann-describtion(.75rem, $lato-regular, 1rem);
            letter-spacing:.125rem;
            text-transform: uppercase;
            margin-bottom:.625rem;
            color:$text-dark-grey;
            }
        }
        &__title {
            h1,h2, h3, h4, h5, h6{
                @include headingtitle_h3;
                margin-bottom:3.125rem;
                color:$text-dark-grey;
                @include breakpoint(tab-max){
                    @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                    margin-bottom:1.875rem;
                    letter-spacing: -2px;
                }
            }
        }
        &__description {
            h1, h2, h3, h4, h5, h6{
                @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                margin-bottom:1.875rem;
                color:$text-dark-grey;
                @include breakpoint(tab-max){
                    margin-bottom:1.25rem;
                    padding-right:1.375rem;
                }
            }
            p{
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                margin-bottom: 1.875rem;
                color:$text-medium-dark-grey;
                letter-spacing: normal;
                @include breakpoint(tab-max){
                    margin-bottom: 1.25rem;
                    padding-right:1.8125rem;
                }
            &:last-child {
                margin-bottom: 3.125rem;
                @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                letter-spacing: normal;
                @include breakpoint (tab-max) {
                    margin-bottom: 1.875rem;
                }
                @include breakpoint (mobile) {
                    margin-bottom: 2.5rem;
                }
            }
            }
        }
        &__action-container {
        .cmp-teaser__action-link.gbh-secondary-button{
            color:$color-medium-blue;
            border-color:$color-medium-blue;
            padding: 1rem 1.6875rem;
            @include ann-describtion (12px, $lato-bold, 16px);
            letter-spacing: 2px;
            @include breakpoint(tab){
                width: 14.125rem;
            }
        }
    }
    }
    &.image-left {
        .cmp-teaser {
            flex-direction: inherit;
            justify-content:flex-start;
            @include breakpoint(mobile){ 
                flex-direction: column;
            }
            &__content{
                margin-right:auto;
                margin-left:7.5rem;
                margin-top: 21px;
                @include breakpoint(desktop-mid){
                    margin-left:2.25rem;
                }
                @include breakpoint(tab){
                    margin-right:0;
                    margin-left:auto;
                    padding-right:2rem;
                }
                @include breakpoint(mobile){
                    margin:0;
                }
            }
        }
    }
    &.image-right {
        .cmp-teaser {
            flex-direction: row-reverse;
            @include breakpoint(mobile){
             flex-direction:column;
            }    
            &__content {
                margin-right:auto;
                @include breakpoint(desktop-xlg){
                    margin-left: auto;
                    padding: 0;
                    margin-right: 7.5rem;
                }
                @include breakpoint(desktop-lg){
                    margin-left:10.625rem;
                    width: 28.125rem;
                    padding: 0;
                    margin-right: 7.5rem;
                }
                @include breakpoint(desktop-mid){
                    margin-left:2.25rem;
                    padding-right: 3.25rem;
                    
                }
                @include breakpoint(tab-max){
                    margin-left:0;
                }
                @include breakpoint(tab){
                    padding-left:2rem;
                }
            }  
        }
    }
}