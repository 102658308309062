.change-password {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: $color-white;
    &__wrapper {
        width: 420px;
        margin: auto;
    }
    &__header {
        margin-bottom: 40px;
        h2 {
            margin-bottom: 15px;
            font-family: $font-light;
            font-size: 36px;
            line-height: 1.22;
            letter-spacing: -1.7px;
            color: $color-slate;
        }
        span {
            font-family: $font-regular;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
        }
    }
    &__reset-button {
        margin-top: 20px;
    }
}

@media screen and (max-width: 990px) {
    .change-password {
       height: 100%;
       overflow-y: scroll;
       padding-bottom: 50px;
       }
 }

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .change-password {
        &__wrapper {
            width: 100%;
            padding: 0 150px;
        }
    }
}

.password-requirements {
    display: inline-block;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Helvetica Now Text W05 Regular';
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;

    &__tooltip--show {
        display: block;
        position: relative;
    }

    &__tooltip--hide {
        display: none;
    }
    
    &__tooltip-wrapper {
        position: absolute;
        z-index: 9;
        top: -40px;
        left: 165px;
    }
}

@media screen and (max-width: 480px) {
    .change-password {
        &__wrapper {
            width: 100%;
            margin-top: 40px;
            padding: 0 20px;
        }
    }
    .password-requirements {
        &__tooltip-wrapper {
            top: -15px;
            left: 130px;
            .tooltip__container {
                width: 200px;
            }
        }
    }
}