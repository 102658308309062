.imagecluster {
    &.gbh-annsacks-left {
        background: $half-white;

        @include breakpoint(tab-max) {
            padding-top: 0;
        }

        .gbh-img-cluster {
            .gbh-img-container {
                width: 100%;
                display: flex;
                position: relative;
                flex-direction: row;

                @include breakpoint(tab-max) {
                    padding-top: 0;
                    margin-top: 0;
                }

                @include breakpoint(mobile-portrait) {
                    display: initial;
                    flex-direction: unset;
                    position: relative;
                }
            }

            .gbh-img-left__full {
                left: 0px;
                top: 0px;
                width: 100%;

                @include breakpoint(tab-max) {
                    width: 100%;
                    position: relative;
                }

                @include breakpoint(mobile-portrait) {
                    top: 0;
                    width: 100%;
                }

                .gbh__full--img {
                    width: 100%;
                }
            }

            .gbh-img-left__half {
                position: absolute;
                right: 28%;
                top: 85px;
                width: 194px;
                background: $cool-light-grey;
                padding: 10px;

                @include breakpoint(tab-max) {
                    left: 55%;
                    top: 35px;
                    padding: 10px;
                    width: 92px;
                }

                @include breakpoint(mobile-portrait) {
                    width: 134px;
                    margin: auto;
                    top: initial;
                    right: 35%;
                    position: unset;
                }

                img {
                    @include breakpoint(tab-max) {
                        width: 100%;
                    }
                }

                .gbh-img-left-half__inner {
                    width: 100% !important;
                }
            }

            .gbh-img-left-half {
                width: 50%;
                padding: 0;
                clear: both;

                @include breakpoint(tab-max) {
                    width: 50%;
                    display: block;
                    margin-bottom: 100px;
                }

                @include breakpoint(mobile-portrait) {
                    margin-bottom: 0;
                    width: 100%;
                }

                .gbh-img-left__half {
                    .gbh__full--img {
                        width: 100%;
                    }
                }
            }

            .gbh-img-right-half {
                padding-bottom: 0;
                margin-top: 275px;
                width: 50%;
                padding-left: 140px;

                @media only screen and (max-width: 1379px) {
                    padding-left: 40px;
                }

                @include breakpoint(tab-max) {
                    width: 50%;
                    padding-left: 45px;
                    padding-bottom: 0;
                    padding-right: 32px;
                    padding-top: 0;
                    margin-top: 140px;
                }

                @include breakpoint(mobile-portrait) {
                    width: 100%;
                    padding-left: 18px;
                    padding-right: 18px;
                    margin-top: 60px;
                }

                h1 {
                    color:$black;
                    font-size: 36px;
                    font-family: "Helvetica Now Text W05 Light", Helvetica, sans-serif;
                }

                h2 {
                    font-size: 54px;
                    letter-spacing: -1px;
                    font-family: PlayfairDisplay;
                    margin-bottom: 20px;
                    color: $text-dark-grey;
                    width: 376px;

                    @include breakpoint(tab-max) {
                        font-size: 36px;
                        padding-bottom: 30px;
                        margin-bottom: 0;
                        width: 300px;
                        letter-spacing: -1px;
                        line-height: 1.22;
                    }

                    @include breakpoint(mobile-portrait) {
                        margin: 0 22px 30px 0;
                        font-family: PlayfairDisplay;
                        font-size: 36px;
                        line-height: 1.22;
                        letter-spacing: -1px;
                        color: $text-dark-grey;
                        padding-bottom: 0;
                    }
                }

                .sub-title {
                    font-size: 13px;
                    font-weight: normal;
                    font-family: "Helvetica Now Text W05 Light", Helvetica, sans-serif;
                    padding: 10px 0;
                    color: $slate;
                }

                .gbh-img-right__descrip {
                    h3 {
                        font-size: 18px;
                        margin-bottom: 30px;
                        color: #424242;
                        line-height: 1.33;
                        letter-spacing: normal;
                        padding-left: 0;
                        width: 376px;

                        @include breakpoint(tab-max) {
                            font-size: 32px;
                            line-height: 40px;
                            letter-spacing: -2.5px;
                            padding-left: 0;
                            width: 300px;
                        }

                        @include breakpoint(mobile-portrait) {
                            margin: 0 0 20px;
                            font-family: Lato;
                            font-size: 18px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            letter-spacing: normal;
                            color:$text-dark-grey;
                            padding-left: 0;
                        }

                    }

                    p {
                        font-size: 14px;
                        color: $slate;
                        font-family: Lato;
                        line-height: 1.43;
                        color: $text-light-grey;
                        letter-spacing: normal;
                        width: 440px;
                        padding-left: 0;

                        @include breakpoint(tab-max) {
                            font-size: 18px;
                            line-height: 28px;
                            letter-spacing: -0.65px;
                            width: 98%;
                            padding-left: 0;
                        }

                        @include breakpoint(tab) {
                            padding-left: 0;
                        }

                        b {
                            font-size: 18px;
                        }
                    }

                    div {
                        font-size: 14px;
                        color: $slate;
                        font-family: Lato;
                        line-height: 1.43;
                        color: $text-light-grey;
                        letter-spacing: normal;
                        padding-left: 140px;
                        letter-spacing: normal;
                        width: 376px;

                        @include breakpoint(tab-max) {
                            font-size: 20px;
                            line-height: 28px;
                            letter-spacing: -0.65px;
                            padding-left: 0px;
                            margin-left: 0;
                            width: 300px;
                        }

                        @include breakpoint(mobile-portrait) {
                            margin: 20px 7px 0 0;
                            font-family: Lato;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.43;
                            letter-spacing: normal;
                            color: $text-light-grey;
                            width: 100%;
                            padding-left: 0;
                        }
                    }
                }

                a {
                    .gbh__btn--link {
                        border-radius: 0;
                        width: 237px;
                        padding: 12px 27px;
                        margin: 50px 0 140px 0;
                        font-family: Lato;
                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: 2.5px;
                        text-align: center;
                        color: $color-light-blue;

                        @include breakpoint(tab-max) {
                            width: 100%;
                            margin: 30px 0 20px 0;
                        }

                        @include breakpoint(mobile-portrait) {
                            margin: 50px 0 50px 0;
                            padding: 12px 27px 14px;
                            border-radius: 1px;
                            border: solid 1px $color-light-blue;
                            width: 100%;
                            margin-left: 0;
                        }
                    }
                }
            }

            .img-link {
                width: 100%;
                margin-right: 5px;
            }

            .img-link-half {
                width: 50%;
                height: 50%;
                margin-bottom: 5px;
                margin-left: 10px;
            }

            .top-width-link {
                width: 100%;
            }

            .gbh__btn--link {
                @include breakpoint(tab-max) {
                    margin: 0 0 50px 0;
                    padding: 10px;
                    width: 90%;
                }

            }

            .img-half-without {
                width: 70%;
                margin-bottom: 5px;
                margin-left: 10px;
            }

            .img-link-share {
                position: absolute;
                right: 0;
                margin: 10px;
                width: 40px;
                cursor: pointer;
            }

            .img-caption-left {
                width: 20px;
                height: 20px;
            }

            .img-caption-right {
                width: 20px;
                height: 20px;
            }

            .img-copyright-right {
                display: flex;
                width: 100%;
                justify-content: flex-end;
            }

            .img-copyright-left {
                display: flex;
                width: 100%;
                justify-content: flex-start;
            }
        }
    }

    &.gbh-annsacks-left {
        .gbh-img-cluster {
            .gbh-img-left-half {
                .gbh-img-left-half__inner {
                    position: inherit;

                    @include breakpoint(mobile-portrait) {
                        position: inherit;
                    }
                }
            }
        }
    }
}