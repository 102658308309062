.modal-popup {
  &.show-modal-popup {
    .modal-popup-content {
      .close-button-holder {
        .close-button {
          @include breakpoint(desktop-lg) {
            top: 19px;
          }
        }
      }

      .modal-popup-body {
        @include breakpoint(desktop-lg) {
          padding-top: unset !important;
        }

        .auth-modal {
          &__header {
            .auth-modal {
              &__tab {
                @include breakpoint(desktop-lg) {
                  height: 64px;
                  margin-top: unset;
                }
              }
            }
          }

          &__content {
            .sign-in {
              @include breakpoint(desktop-lg) {
                padding: 60px 60px 0px;
                height: 481px !important;
              }

              &__footer {
                @include breakpoint(desktop-lg) {
                  margin: unset;
                  padding-top: 0px;
                  justify-content: unset;
                  column-gap: 18px;
                }
              }

              &__sign-in-button {
                @include breakpoint(desktop-lg) {
                  margin-bottom: 36px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sign-in__keep-me-logged {
  .checkbox {
    .checkbox__border {
      .checkbox__marker {
        border-radius: 5px;
        width: 20px;
        height: 20px;
        .checkbox__marker-icon {
          width: 9.13px;
          height: 7.42px;
        }
      }
    }
  }
}

// this one related to favorite button fix while modifing
@media screen and (max-width: 576px) {
  .favorites__modal-cta .kf-react-button button:first-child {
    margin-bottom: 20px;
  }
}
