.carousel-sticky{
    .cmp-container{
        .carousel-swipe-scroll{
            &.sticky-content-center{
                padding:0 0 4.375rem 16.875rem;
                margin:0 auto!important;
                @include breakpoint(desktop-lg){
                    padding-left:6.875rem;
                }
                @include breakpoint(tab-max){
                    padding-bottom:3.125rem;
                }
                @include breakpoint(tab){
                    padding-left:2rem;
                }
                @include breakpoint(mobile){
                    padding:50px 0;
                }
                .cmp-carousel{
                    &.cmp-carousel-slick{
                       max-width: 100%;
                        .carousel-header-info{
                            position: absolute;
                            width: 20.1875rem;
                            top: 14.0625rem;
                            text-align:left;
                            padding:0;
                            margin:0;
                            @include breakpoint(desktop-small){
                                margin-right:6.0625rem;
                            }
                            @include breakpoint(desktop-mid){
                                top:5rem;
                            }
                            @include breakpoint(tab){
                                max-width:12.75rem;
                                padding:0;
                                top:7.125rem;
                            }
                            @include breakpoint(mobile){
                                position: relative;
                                top:0;
                                width:100%;
                                margin-bottom:2.5rem;
                                padding:0 1.125rem;
                            }
                            h1,h2,h3,h4,h5,h6,p{
                                @include ann-describtion(12px,$lato-regular,16px);
                                color:$text-dark-grey;
                                margin-bottom:20px;
                                padding-bottom:0;
                                letter-spacing:2px;
                            }
                            .cmp-carousel__pretitle{
                                text-transform: uppercase;
                            }
                            .cmp-carousel{
                                &__title{
                                    margin:0;
                                    h1,h2,h3,h4,h5,h6{
                                        @include headingtitle_h3;
                                        text-transform:inherit;
                                        margin-bottom:3.125rem;
                                        @include breakpoint(tab-max){
                                            margin-bottom:50px !important;
                                            letter-spacing:-1.17px;
                                        }
                                    }
                                }
                                &__description{
                                    p{
                                        @include ann-describtion(18px,$lato-regular,28px);
                                        letter-spacing:normal;
                                        max-width:16.9375rem;
                                        margin:0;
                                        @include breakpoint(tab-max){
                                            @include ann-describtion(14px,$lato-regular,20px);
                                            padding-right:0;
                                        }
                                        @include breakpoint(mobile){
                                            margin:0;
                                            max-width:100%;
                                        }
                                    }
                                }
                            }
                        }
                        .slick-initialized{
                            .teaser {
                                .cmp-teaser {
                                    &__title{
                                        h1,h2,h3,h4,h5,h6,p{
                                            @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                                            color:$text-dark-grey;
                                            padding:0;
                                            letter-spacing:normal;
                                            @include breakpoint(tab-max){
                                                position: relative;
                                                top: auto;
                                            }
                                        }
                                    }
                                }
                            }
                            .slick-dots{
                                margin: 0 auto;
                                text-align: right;
                                top:unset;
                                bottom:4.375rem;
                                right:20.625rem;
                                position:absolute;
                                width:auto;
                                line-height:0;
                                margin-top:2.5rem;
                                @include breakpoint(desktop-lg){
                                    right:330px;
                                }
                                @include breakpoint(tab-max){
                                    position: relative;
                                    bottom:0;
                                    width:100%;
                                    text-align:center;
                                    right:0;
                                    top:0 !important;
                                }
                                @include breakpoint(tab-max){
                                    margin-top:1.875rem;
                                }
                            }
                            .slick-list{
                                margin-left:26.25rem;
                                padding-right:16.875rem!important;
                                @include breakpoint(desktop-lg){
                                    padding-right:6.875rem !important;
                                }
                                @include breakpoint(tab){
                                    margin-left:17rem;
                                    padding:0 !important;
                                }
                                @include breakpoint(mobile){
                                    margin-left:0;
                                    padding:0 !important;
                                }
                                .slick-track{
                                    display: flex;
                                    .slick-slide{
                                        margin-right:3.75rem;
                                        @include breakpoint(tab-max){
                                            margin-right: 0;                                           
                                        }
                                        @media (min-width: 1920px){
                                            width: 900px !important;
                                        }
                                        &.cmp-carousel-slick__item{
                                            .cmp-teaser{
                                                &__content{
                                                    display:none;
                                                }
                                            }
                                        }
                                        &.slick-active{
                                            .cmp-teaser{
                                                &__content{
                                                    display:block;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .slick-arrow{
                                display:none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}