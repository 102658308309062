.inpage-search{
    .inpage-search__title{
        font-family: $font-regular-base;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
        color: $text-medium-dark-grey;
        &--dark{
            color: $text-medium-dark-grey;
        }
    }
    .inpage-search__search-control{
        font-family: $font-regular-base;
        font-size: 80px;
        letter-spacing: 0;
        line-height: 36px;
        color: $cool-dark-grey;
        &--dark{
            color: $text-dark-grey;
        }
    }
}
@media screen and (max-width: 480px) {
    .inpage-search__search-control{
        font-size: 26px;
    }
}
@media screen and (orientation:landscape) and (max-width: 900px){
    .inpage-search__search-control{
        font-size: 26px;
    }
}

// Page not found
.page-not-found {
    .inpage-search {
      max-width: 1380px;
      margin: 0 auto;
      padding: 19.5rem 30px !important;
      @include breakpoint(tab) {
        padding: 7.3125rem 2rem 4.375rem !important;
        justify-content: center;
        width: 100%;
      }
      @include breakpoint(mobile) {
        padding: 7.3125rem 1.125rem 4.3125rem !important; // Overwirte KF CSS
        width: 100%;
      }
  
      .row.inpage-search-section {
        padding: 0 40px;
        @include breakpoint(tab-max) {
          padding: 0 16px;
        }
      }
      @include breakpoint(desktop-small) {
        .row.inpage-search-section {
          padding: 0;
        }
      }
      &__title {
        &:empty {
          display: none;
        }
        @include headingtitle_h3;
        color: $text-dark-grey;
        padding-bottom: 4.375rem;
        text-align: left;
        @include breakpoint(tab-max) {
          @include headingtitle_h1;
          letter-spacing: -0.175rem;
          padding-bottom: 4.75rem;
          text-align: center;
          width: 21.25rem;
          margin: 0 auto;
        }
        @include breakpoint(mobile) {
          width: 100%;
          text-align: left;
        }
      }
      &__header-section {
        display: unset;
        margin: 0;
        position: relative;
        padding: 0;
        align-items: unset;
        border: none;
        width: 33.75rem;
        @include breakpoint(tab) {
          width: 100%;
        }
        @include breakpoint(mobile) {
          width: 100%;
        }
        .button.large.ghost {
          padding: 0.75rem 1.6875rem 0.875rem;
          @include ann-describtion(0.75rem, $lato-bold, 1.5rem);
          letter-spacing: 0.15625rem;
          width: auto;
          text-transform: uppercase;
          color: $color-medium-blue-cap;
          border: 1px solid $color-medium-blue-cap;
          position: absolute;
          top: 0;
          right: 0;
          @include breakpoint(tab) {
            color: $color-medium-blue-cap;
            border: 1px solid $color-medium-blue-cap;
          }
          @include breakpoint(mobile) {
            color: $color-medium-blue-cap;
            border: 1px solid $color-medium-blue-cap;
          }
          @include breakpoint(tab) {
            margin-top: 40px;
            position: relative;
          }
          &.large {
            height: auto;
          }
        }
      }
      &__search-filed {
        width: 100%;
        height: auto;
        position: relative;
        padding: 1.0625rem 11.25rem 1.375rem 0;
        color: $text-dark-grey;
        border-bottom: 1px solid $text-dark-grey;
        @include breakpoint(tab-max) {
          width: 308px;
          padding: 0 0 16px;
          margin: 0 auto;
          border-bottom: 1px solid $cool-dark-grey;
        }
        ::placeholder {
          color: $cool-dark-grey;
        }
        @include breakpoint(mobile) {
          width: 100%;
        }
        .inpage-search__search-control {
          padding: 0;
          @include ann-describtion(1.625rem, $lato-regular, 2.25rem);
          letter-spacing: normal;
          color: $text-dark-grey;
          height: auto;
          background: none;
          border: none;
          @include breakpoint(tab) {
            text-align: center;
            padding: 0;
          }
          @include breakpoint(mobile) {
            border: none;
            padding: 0;
            text-align: left;
          }
          &::placeholder {
            color: $cool-dark-grey;
            font-size: 1.625rem;

          }
          &:focus::placeholder {
            color: $cool-dark-grey;
            font-size: 1.625rem;
            line-height: 2.25rem;
            letter-spacing: normal;
          }
          &:focus {
            border: 0;
          }
        }
      }
      &__header {
        @include breakpoint(tab){
          padding: 0;
        }
        .button {
          @include breakpoint(tab) {
            height: 50px;
            color: $color-medium-blue-cap;
            font-family: $lato-bold;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 2.5px;
            line-height: 24px;
            text-align: center;
          }
          @include breakpoint(mobile) {
            height: 50px;
            color: $color-medium-blue-cap;
            font-family: $lato-bold;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 2.5px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
    //UI alignment update
    @include breakpoint(desktop-mid){
      padding: 0 6.875rem;
    }
    @include breakpoint(tab){
      padding: 0 2rem;
    }
    @include breakpoint(mobile){
      padding: 0 1.125rem;
    }
    .cmp-container{
      max-width: 1380px;
      margin: 0 auto;
      padding: 19.5rem 0;
      @include breakpoint(tab-max){
        padding: 7.3125rem 0 4.375rem 0;
      }
      .site-maintain-text{
        .cmp-text{
          h2{
            @include ann-describtion(3.375rem, $playfair-regular, 3.875rem);
            letter-spacing: -0.0625rem;
            color: $text-dark-grey;
            padding-bottom: 4.375rem;
            @include breakpoint(tab-max){
              @include ann-describtion(2.625rem, $playfair-regular, 3rem);
              letter-spacing: -0.175rem;
              text-align: center;
              padding-bottom: 4.75rem;
              margin: 0;
            }
            @include breakpoint(tab){
              max-width: 21.25rem;
              margin: 0 auto;
            }
            @include breakpoint(mobile){
              text-align: left;
              max-width: 100%;
              margin: 0;
            }
          }
        }
      }
        .in-page-search{
          .inpage-search{
            padding: 0 !important; 
            .inpage-search__header{
              @include breakpoint(mobile){
                padding: 0;
              }
            }
          }
        }
    }
  }
.in-page-search{
    @include breakpoint(tab){
        .row{
            display: block;
        }
    }
    .inpage-search{
        &__header{
            &-section{
                display: flex;
                @include breakpoint(tab){
                    flex-direction: column;
                    .inpage-search__search-filed{
                        padding-bottom: 16px;
                    }
                }
            }
        }
        &__title{
            @include breakpoint(tab){
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
// organize-container-indent with search only component
.organize-container-indent {
    .in-page-search {
        width: 100%;
        .inpage-search {
            .inpage-search-section{
                padding: 7.5rem 0 9.375rem 0;
                margin: 0;
                @include breakpoint(tab) {
                    padding: 3.1875rem 0 3.875rem;
                }
                @include breakpoint(mobile) {
                    padding: 3.125rem 0 4.375rem;
                }
            }
            &__title {
                padding: 0;
                @include ann-describtion(.75rem, $lato-regular, 1rem);
                letter-spacing:.125rem;
                text-transform:uppercase;
                @include breakpoint(tab-max){
                    width:100%;
                    margin:0;
                    padding:0;
                    text-align:left;
                }
                @include breakpoint(mobile){
                    padding-right:30px;
                }
            }
            &__header {
                padding:0;
                .inpage-search__header-section {
                    border-bottom: 1px solid $cool-dark-grey;
                    padding: 0;
                    @include breakpoint (desktop-small) {
                        margin-top: 4.375rem;                        
                        width:100%;
                    }
                    @include breakpoint (tab) {
                        margin-top: 4.0625rem;
                    }
                    @include breakpoint (mobile) {
                        margin-top:4.25rem;
                        padding-bottom: 0;
                    }
                    div.kf-react-button.button{
                      padding: 0;
                      border: 0;
                      height: 100%;
                      a.kf-react-button.button{
                        @include breakpoint(mobile){
                          all: unset;
                        }
                      }
                    }
                    a {
                        &.button.large.ghost {
                            @include ann-describtion(.75rem, $lato-bold, 1.5rem);
                            letter-spacing: .1563rem;
                            color: $color-medium-blue;
                            border-color: $color-medium-blue;
                            padding: 12px 27px 14px 27px;
                            height: auto;
                            text-transform:uppercase;
                            bottom:0;
                            top:unset;
                            @include breakpoint(tab) {
                                position:absolute;
                                right:0;
                                margin-bottom: 1.5rem;
                            }
                            @include breakpoint(mobile) {
                                display:inline-flex;
                                align-items: center;
                                text-align: center;
                            }
                            &.large {
                                height: 50px;
                                background-color: transparent;
                            }        
                        }
                    }
                }
                div.kf-react-button.button{
                  &.--active{
                    &.large {
                      background-color: transparent;
                      transition: all 0.5s ease, $light-gray 1.5s ease;
                    }
                  }
                  &.--inactive{
                    &.large {
                      background-color: transparent;
                      transition: all 0.5s ease, $light-gray 1.5s ease;
                    }
                  }
                }
            }
            &__search-control {
                padding: .3125rem 0 0 0;
                @include ann-describtion(36px, $lato-regular, 36px);
                letter-spacing: normal;
                color: $text-dark-grey;
                height: auto;
                background: none;
                border: none;
                text-transform:capitalize;
                @include breakpoint(tab-max){
                    text-align:left;
                    font-size: 26px;
                }
                @include breakpoint(mobile){
                    padding:0;
                }
                &::placeholder {
                    color: $cool-dark-grey;
                    @include breakpoint (desktop-small) {
                        font-size: 36px;
                    }
                    @include breakpoint (tab-max) {
                        font-size:26px;
                    }
                }
                &:focus::placeholder {
                    color: $text-dark-grey;                    
                }
            }
            .inpage-search__search-filed {
                padding: 0 0 .9375rem 0;
                border: none;
                @include breakpoint(desktop-small) {
                    padding: .8125rem 7.5rem 1.5625rem .25rem;                
                }                
                @include breakpoint (tab) {
                    padding-bottom: .9375rem;
                }
                @include breakpoint (mobile) {
                    padding-bottom: .9375rem;
                }
                input::placeholder{
                    color: $cool-dark-grey;
                    font-family: $lato-regular;
                }
                label{
                  @include breakpoint(tab-max){
                    margin: 0;
                    width: 100%;
                  }
                }
            }
            &.kf-react-container {
                //React code css !Important overwrite purpose i written below code               
                @include breakpoint(desktop-small) {
                    padding: 0 !important;
                }
                @include breakpoint(tab-max){
                    padding:0 !important;
                }
            }
        }
    }
}
.in-page-search {
    .inpage-search {
        .inpage-search-section {
          padding: 0;
          // @media screen and (max-width: 480px){
          //   .button {
          //     margin-top: 40px;
          //   }
          // }
          div.kf-react-button.button{
            a.kf-react-button.button{
              @include breakpoint(mobile){
                all: unset;
              }
            }
            &--active{
              &.large {
                background-color: $light-gray;
              }
            }
          }
        }
    }
}