.organize-teaser-accordion{
    .teaser.faq-header{
        width:33.75rem;
        padding:0;
        margin-right:auto;
        position:relative;
        @include breakpoint(tab){
            width:100%;
            margin-bottom:30px;
        }
        @include breakpoint(mobile){
            width:100%;
            padding-right:1.6875rem;
        }
        .cmp-teaser{
            &__content{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width:33.75rem;
                @include breakpoint(tab){
                    flex-direction:column;
                    width:100%;
                }
                @include breakpoint(mobile){
                    flex-direction:column;
                    width:100%;
                    margin-bottom:3.75rem;
                }
            }
            &__title {
                h1,h2,h3,h4,h5,h6,p{
                    font-size:3.375rem;
                    line-height:3.875rem;
                    letter-spacing: -1px;
                    font-family:$playfair-regular;
                    margin-bottom:1.875rem;
                    order: -1;
                    flex: 1 0 100%;
                    color:$text-dark-grey;
                    padding-bottom: 0;
                    @include breakpoint(tab){
                        margin-bottom: 3.75rem;
                    }
                    @include breakpoint(tab-max){
                        font-size:1.75rem;
                        line-height:2.125rem;
                        letter-spacing: -0.0731rem;
                        padding-bottom: 0
                    }
                    
                }
            }
            &__description{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                margin-right:0.3125rem;
                color:$text-medium-dark-grey;
                padding: 0;
                p{
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    letter-spacing: normal;
                    color: $text-medium-dark-grey !important;
                    @include breakpoint(tab-max){
                        margin:0;
                    }
                    a{
                        text-decoration:underline;
                        @include breakpoint(tab-max){
                            display:inherit;
                        }
                    }
                }
            }
            &__action-container{
                display:flex;
                @include breakpoint(tab-max){
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            &__action-link{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                color:$text-dark-grey!important;
                text-transform:inherit;
                letter-spacing:0;
                border:none;
                padding:0;
                text-decoration:underline;
                @include breakpoint(tab-max){
                    margin: 0;
                }
                &.gbh-primary-button{
                    text-decoration: none;
                    padding: 12px 27px;
                    font-size: 12px;
                    letter-spacing: 2.5px;
                    line-height: 24px;
                    position:absolute;
                    left:0;
                    margin-top:40px;
                    background: $color-medium-blue;
                    color:$white;
                    @include breakpoint(tab-max){
                        position: relative;
                        margin-top:20px;
                        top:inherit !important;
                    }
                }
            }
        }
    }
    &.container{
        padding: 0;
        .accordion.faq-accordion{
            .cmp-accordion{
                &__item {
                    padding: unset;
                    border: none;
                }
                &__header{
                    .cmp-accordion__icon{
                        width: 15px;
                        height: 15px;
                        .line.line-1{
                            width: 15px;
                        }
                        .line.line-2{
                            height: 15px;
                            transform: translateX(-7px);
                        }
                    }
                    .cmp-accordion__button--expanded .cmp-accordion__icon .line-2 {
                        height: 0;
                    }
                }
            }
        }
    }
}