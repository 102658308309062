.store-appointment {
    .modal-popup .modal-popup-content{
        position: fixed;
        z-index: 1;
        left:0;
        right:0;
        top: 0;
        margin: 30px auto;
        background-color: $color-white;
        max-width: 780px;
        padding: 0px;

        .modal-popup-body{
            @media only screen and (min-width: 990px) {
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 1px grey; 
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $color-dark-gray; 
                    border-radius: 5px;
                }
            }
        }
    }
    .modal-popup {
        &-content, &-body {
            @media only screen and (max-width: 990px) {
                &::-webkit-scrollbar { 
                    display: none;
                }
            }
        }
    }
    .modal-popup .modal-popup-content .close-button{
        z-index: 9;
        right: 3%;
        top: 40px;
        color: $color-charcoal;
        position: relative;
        margin-bottom: -40px;
    }
    .store-appointment{
        &__header{
            background-color: $color-off-white;
            padding: 80px 60px 60px;
            p{
                margin-bottom: 0px;
            }
        }
        &__heading{
            font-family: $font-light;
            font-size: 36px;
            line-height: 44px;
            letter-spacing: -1.7px;
            color: $color-slate;
            padding-bottom: 20px;
        }
        &__desc{
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $color-slate;
            padding-bottom: 40px;
        }
        &__location{
            font-family: $font-medium;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.4px;
            color: $color-charcoal;
            padding-bottom: 20px;
        }
        &__store{
            font-family: $font-light;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -1.6px;
            color: $color-slate;
            padding-bottom: 10px;
        }
        &__address{
            font-family: $font-regular;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.65px;
            color: $color-slate;
        }
        &__select-date{
            padding: 60px 60px 0px;
        }
        &__select-date-time{
            padding: 40px 60px 60px;
        }
    }
    &__available-txt{
        font-family: $font-regular;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        margin-bottom: 0px;
    }
    &__available-container{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    }
    &__available-time{
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
    }
    &__arrow{
        margin-left: 20px;
        cursor: pointer;
    }
    &__available-time-card{
        font-family: $font-regular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        border: 1px solid $color-medium-gray;
        text-align: center;
        border-radius: 5px;
        padding: 14px 10px;
        cursor: pointer;
        &.--disable{
            border: none;
            background-color: $color-extra-light-gray; 
            cursor: not-allowed;
        }
        &.--active{
            color: $color-white;
            border: none;
            background-color: $color-slate;
        }
    }
    .store-appointment{
        &__contact-heading{
            padding: 0px 60px;
        }
        &__contact-details{
            padding: 0px 60px;
            .input-field__prefix{
                display: flex;
            }
        }
    }
    &__step{
        font-family: $font-medium;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: $color-charcoal;
        margin-bottom: 0px;
    }
    &__required{
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-slate;
        margin: 30px 0px 20px;
    }
    &__privacy-policy{
        padding: 20px 15px 30px;
        list-style-type: none;
        margin-left: 0;
        display: inline-flex;
        a{
            font-family: $font-regular;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.45px;
            color: $color-dark-gray;
            padding-right: 18px !important;
            &, &:hover{
                text-decoration: underline;
            }
            &:last-child{
                padding-right: 0px;
            }
        }
    }
    &__confirmation{
        padding-top: 60px;
        padding-bottom: 50px;
    }
    &__thank-you-container{
        padding: 20px 60px 40px;
    }
    &__thank-you{
        font-family: $font-light;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.7px;
        color: $color-slate;
        margin-bottom: 20px;
    }
    &__thank-you-desc{
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin-bottom: 0px;
    }
    &__details-container{
        display: grid;
        grid-template-columns: 1fr 360px;
        padding-left: 60px;
        background-color: $color-charcoal;
    }
    &__details-left{
        padding: 50px 60px 50px 0;
    }
    &__details-right{
        padding: 0px;
        min-height: 492px;
        img{
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: 50% 50%;
            object-position: 50% 50%;
        }
    }
    &__details{
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0px;
        }
        &-title{
            font-family: $font-medium;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
            color: $color-white;
            margin-bottom: 10px;
        }
        &-content{
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $color-light-gray;
            margin-bottom: 0px;
        }
        &-time{
            font-family: $font-regular;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.45px;
            color: $color-light-gray;
            margin-bottom: 0px;
        }
        &-content-main{
            margin-bottom: 10px;
        }
        &-store-link{
            letter-spacing: -0.45px;
            text-decoration: underline;
            &:hover{
                color: $color-light-gray;
            }
        }
    }
    &__close-container{
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
    &__close-btn{
        margin-top: 50px;
    }
    &__left-arrow-disable, &__right-arrow-disable{
        cursor: not-allowed;
        margin-left: 15px;
    }
}

@media only screen and (max-width: 990px) and (orientation: landscape){
    .store-appointment-overlay {
        display: none;
    }

    .store-appointment{
        .modal-popup .modal-popup-content{
            margin: 0px auto;
            max-height: 100%;
            max-width: 100%;
        }
        .modal-popup .modal-popup-content .close-button{
            top: 20px;
            right: 32px;
        }
        .store-appointment{
            &__header{
                padding: 80px 3.94% 60px;
            }
            &__heading{
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -2.2px;
            }
            &__details-container{
                grid-template-columns: 1fr 440px;
                padding-left: 3.94%;
            }
            &__details-right{
                display:grid;
                grid-template-rows: 329px auto;
                min-height: 329px;
            }
            &__store{
                font-size: 26px;
                line-height: 34px;
                letter-spacing: -1.5px;
                padding-bottom: 8px;
            }
            &__select-date{
                padding: 60px 3.94% 0px;
            }
            &__select-date-time{
                padding: 40px 3.94% 60px;
            }
            &__contact-heading{
                padding: 0px 3.94%;
            }
            &__contact-details{
                padding: 0px 3.94%;
            }
        }
        &__thank-you-container{
            padding: 20px 3.94% 40px;
        }
        &__details-left{
            padding: 40px 8.37% 40px 0;
        }
        &__thank-you{
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -2.2px;
        }
        &__close-container{
            padding-left: 3.94% !important;
            padding-right: 3.94% !important;
        }
        &__close-btn {
            margin-top: 35px;
        }
    }
}


@media only screen and (max-width: 767px){
    .store-appointment-overlay {
        display: none;
    }

    .store-appointment{        
        .modal-popup .modal-popup-content{
            margin: 0px auto;
            height: 100vh;
            max-width: 100%;
        }
        .modal-popup .modal-popup-content .close-button{
            top: 20px;
            right: 18px;
        }
        .store-appointment{
            &__header{
                padding: 80px 4.8% 60px;
            }
            &__heading{
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -2.2px;
            }
            &__store{
                font-size: 26px;
                line-height: 34px;
                letter-spacing: -1.5px;
                padding-bottom: 8px;
            }
            &__select-date{
                padding: 60px 4.8% 0px;
            }
            &__select-date-time{
                padding: 40px 4.8% 60px;
            }
            &__contact-heading{
                padding: 0px 4.8%;
            }
            &__contact-details{
                padding: 0px 4.8%;
            }
        }
        &__thank-you-container{
            padding: 20px 4.8% 40px;
        }
        &__confirmation{
            padding-top: 40px;
            padding-bottom: 40px;
        }
        &__thank-you{
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -2.2px;
        }
        &__details-container{
            grid-template-columns: auto;
        }
        &__details-left{
            padding: 40px 4.8%;
        }
        &__close-container{
            padding-left: 18px;
            padding-right: 18px;
        }
        &__close-btn {
            margin-top: 40px;
        }
        &__privacy-policy{
            padding: 20px 10px 30px 15px;
            a{
                display: inline-block;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .store-appointment{   
        &__details-container{
            min-height: 753px;
            grid-auto-rows: 281px auto;
            padding-left: 0;
        }
        &__details-left{
            order: 2;
        }
        &__details-right{
            order: 1;
            min-height: 281px;
        }
        &__close-container{
            padding-left: 4.8% !important;
            padding-right: 4.8% !important;
        }
    }
}