.technical-tile {
  display: grid;

  grid-column-gap: 50px;

  &__grid-showfilter {
    grid-template-columns: 40% 1fr;
  }

  &__grid-hidefilter {
    grid-template-columns: 40% 1fr;
  }

  &__maxwidth {
    margin-left: 0px !important;
    max-width: 1020px;
  }

  &__zeropx {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  &__discontinued {
    font-family: $font-medium;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.65px;
    color: $color-white;
    margin-bottom: 0px;
    padding: 9px 12px;
    background-color: $color-dark-gray;
    text-align: center;
    @media screen and (max-width: 1023px) {
      padding: 6px 8px;
    }
  }
  &__image {
    max-width: 596px;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    // background: #f9f9f9;

    &--product-image {
      width: 100%;
      background: #f9f9f9;
    }

    img {
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      object-fit: cover;
    }
  }
  &__sku {
    font-family: $font-medium;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: $color-slate;
  }
  &__brand-description {
    color: $color-dark-gray;
    @media screen and (min-width: 1140px) {
      padding-right: 50px;
    }
  }
  &__specs-container {
    @media screen and (min-width: 1140px) {
      display: grid;
      grid-template-columns: 80% 1fr;
    }
  }
  &__details {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    h3 {
      font-family: $font-light;
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: -1.5px;
      color: $color-slate;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-bottom: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &__specs {
    padding-top: 0px;
    div {
      margin-top: 15px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  &__specs-subtitle {
    font-family: "Helvetica Now Text W05 Medium";
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.35px;
    color: $color-slate;
    margin-bottom: 0px;
    display: block;
  }
  &__specs-content {
    font-family: "Helvetica Now Text W05 Regular";
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    display: block;
  }
  &__collapsible-fullWidth-grid {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  &__collapsible-grid {
    grid-column-start: 2;
    grid-column-end: 2;
  }
  &__collapsible {
    .accordion-container:first-child {
      .collapsible-accordion {
        .Collapsible {
          border-top: 1px solid $color-dark-gray;
        }
      }
    }
    .collapsible-accordion {
      .Collapsible {
        border-bottom: 1px solid $color-dark-gray;
        &__trigger {
          padding: 20px 0px;
          .collapsible__header {
            align-items: center;
            span {
              font-family: $font-light;
              font-size: 22px;
              line-height: 1.45;
              letter-spacing: -1.2px;
              color: $color-charcoal;
            }
          }
        }
      }
    }
    .Collapsible__contentInner {
      padding-bottom: 41px;
    }
  }

  &__add-share {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;

    span {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-left: 10px;
      cursor: pointer;
      padding: 10px;
      &:first-child {
        display: flex;
        align-items: center;
      }
    }
    img {
      height: fit-content;
      width: auto;
    }
  }
  &__download-section {
    display: grid;
    grid-template-columns: 1fr 240px;
    grid-template-rows: 40px auto;
    padding-top: 0px;
  }
  &__download-btn {
    grid-column-start: 2;
    font-family: $font-medium;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.25px;
    color: $color-white;
    height: 40px;
    padding: 8px 30px;
    background: $color-kohler-blue;
    text-align: center;
  }
  &.disabled {
    color: $color-white;
    background: $color-light-gray;
  }
  &__files {
    section {
      display: flex;
      padding-top: 20px;
      padding-bottom: 5px;

      .technical-tile__file {
        color: $color-dark-gray;
        line-height: 22px;
        margin-bottom: 5px;
        span {
          cursor: pointer;
          padding: 0px;
        }
        img {
          margin-right: 15px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }

      span.technical-tile__file-type.m-right {
        margin-right: 30px;
        display: flex;
        align-items: center;
      }

      .technical-tile__file-type {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.35px;
        color: $color-dark-gray;
        width: 100%;
        max-width: 180px;
        display: flex;
      }
      .input-group {
        display: flex;
        .checkbox-wrapper {
          margin-bottom: 15px;
        }
      }
      .checkbox {
        margin-left: 30px;
        min-width: 68px;
      }
    }
    &.--technical-info {
      max-width: 416px;
      padding-bottom: 10px;

      section {
        display: flex;
        align-items: center;
        .technical-tile__file-type {
          max-width: none;
        }
        .checkbox {
          margin-left: auto;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.technical.product-listing {
  &__tile-col {
    &--full-width {
      margin: 0px;
    }
    .product-listing {
      &__tile {
        padding-right: 0px;
      }
    }
  }
}

@media all and (min-width: 1500px) {
  .technical-tile {
    &__grid-showfilter {
      grid-template-columns: 507px 1fr;
    }
    &__grid-hidefilter {
      grid-template-columns: 596px auto;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
  .technical-tile {
    &.--full-width {
      &__image {
        img {
          height: 100%;
        }
      }
    }
    &__grid-showfilter {
      grid-template-columns: 351px 1fr;
    }
    &__grid-hidefilter {
      grid-template-columns: 478px 1fr;
    }
  }

  .technical-tile {
    &__specs {
      margin-top: 20px;
      margin-bottom: 0px;
    }
    &__grid-showfilter {
      grid-template-columns: 50% 1fr;
    }

    &__collapsible {
      &.wide-collapsible {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row: none;
        margin-top: 0px;
        margin-top: 30px;
      }
    }
    &__download-section {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      padding-top: 10px;
    }
    &__select-all {
      margin-bottom: 30px;
      .checkbox {
        margin: 0px;
      }
    }
    &__download-btn {
      grid-column-start: 1;
      grid-row-start: 3;
      margin-top: 20px;
      margin-bottom: 25px;
    }
    &__files {
      section {
        padding: 15px 0px;
      }
      &.--technical-info {
        section {
          .checkbox {
            margin-left: auto;
            margin-right: 0px;
          }
        }
      }
    }
    &__image {
      img {
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .technical-tile {
    &__image {
      &--product-image {
        width: 100%;
        height: 100%;
        background: $color-off-white;
      }
    }
    &__brand-description {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      font-family: $font-regular;
      color: $color-dark-gray;
    }
    &__sku {
      padding-top: 5px;
    }
    &__specs {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: 992px) {
  .technical-tile {
    &__specs {
      margin-top: 0px;
    }
    grid-template-columns: 180px auto;
    grid-column-gap: 30px;
    &__download-section {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      padding-top: 5px;
    }
    &__select-all {
      margin-bottom: 30px;
      .checkbox {
        margin: 0px;
      }
    }
    &__download-btn {
      grid-column-start: 1;
      grid-row-start: 3;
      margin-top: 20px;
      margin-bottom: 25px;
    }
    &__files {
      section {
        padding: 15px 0px;
      }
      &.--technical-info {
        section {
          .checkbox {
            margin-left: auto;
            margin-right: 0px;
          }
        }
      }
    }
    &__image {
      img {
        height: 100%;
      }
    }

    &__details {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;

      h3 {
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.65px;
        margin: 0 0 5px 0;
      }
      p {
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        margin-bottom: 15px;
      }
    }
    &__specs {
      display: flex;
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 1;

      div {
        margin-top: 0px;
        margin-right: auto;
      }
    }
    &__add-share {
      top: 0px;
      right: 0px;
    }
    &__specs-container {
      display: block;
    }
    &__specs-subtitle {
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.35px;
      display: block;
    }
    &__specs-content {
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      display: block;
    }
    &__collapsible {
      grid-column-start: 3;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 1;

      margin-top: 20px;
      border-top: none;
      .collapsible-accordion {
        .Collapsible__trigger {
          padding: 15px 0px;
        }
      }
      .Collapsible__contentInner {
        padding-bottom: 15px;
      }
    }

    &__image {
      max-width: 123px;
      height: 123px;
    }

    grid-template-columns: 123px 187px auto;
    grid-column-gap: 30px;
    &__specs {
      margin-bottom: 25px;
    }
    &__collapsible {
      margin-top: 0px;
    }
    &__details {
      h3 {
        font-family: $font-regular;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .technical-tile {
    grid-template-columns: 123px 150px auto;
    grid-column-gap: 18px;
  }
}

@media screen and (max-width: 640px) {
  .technical-tile {
    &__specs {
      margin-top: 25px;
    }
    &__maxwidth {
      margin-left: -15px !important;
    }

    grid-template-columns: 123px 2fr;
    &__image {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
      width: 123px;
      height: 123px;
    }
    &__details {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 1;
      h3 {
        font-family: $font-regular;
      }
    }
    &__collapsible {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row: none;
      margin-top: 0px;
      border-top: none;
      max-width: none;
    }
  }

  .technical-tile {
    &__download-btn {
      margin: 10px 0px 0px 0px;
    }
    &__specs {
      margin-bottom: 25px;
    }
    &__select-all {
      margin-bottom: 30px;
      .checkbox {
        margin: 0px;
      }
    }
    &__collapsible {
      .Collapsible__contentInner {
        padding-bottom: 41px;
      }
    }
  }
}
