// Manual Product Slider New Enhancemant 
.carousel-slick{
    &.carousel-header-info{
        &.carousel-swipe-scroll{
            &.manual-product-slider-carousel{
                .cmp-carousel{
                    max-width: 100%;
                    margin: unset;
                    .carousel-header-info{
                        @include breakpoint(desktop-small){
                            position: relative;
                            display: block;
                            margin-bottom: 3.125rem;
                            padding: 0;
                        }
                        @include breakpoint(tab-max){
                            margin-bottom: 2.5rem;
                            padding-bottom: 0;
                        }
                        p{
                            @include ann-describtion(.875rem, $lato-regular, 1rem);
                            letter-spacing: .125rem;
                            color: $text-dark-grey;
                            text-transform: uppercase;
                            margin: 0;
                            padding: 0;
                            @include breakpoint(desktop-small){
                                width: 100%;
                            }
                            @include breakpoint(tab-max){
                                font-size: .75rem;
                            }
                        }
                        .cmp-carousel{
                            &__title{
                                padding: 0;
                                margin: .625rem 0 0 0;
                                @include breakpoint(desktop-small){
                                    width: 100%;
                                }
                                h1,h2,h3,h4,h5,h6,p{
                                    @include ann-describtion(3.375rem, $playfair-regular, 3.875rem);
                                    letter-spacing: -0.0625rem;
                                    color: $text-dark-grey;
                                    margin: 0;
                                    padding: 0;
                                    @include breakpoint(tab-max){
                                        @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                                        letter-spacing: -0.0731rem;
                                    }
                                }
                            }
                            &__description{
                                margin: 1.25rem 0 0 0;
                                padding: 0;
                                @include breakpoint(desktop-small){
                                    width: 49.4375rem;
                                    right: unset;
                                    position: unset;
                                }
                                h1,h2,h3,h4,h5,h6,p{
                                    @include ann-describtion(1.375rem, $lato-regular, 2rem);
                                    letter-spacing: normal;
                                    color: $text-medium-dark-grey;
                                    margin: 0;
                                    padding: 0;
                                    text-transform: none;
                                    @include breakpoint(tab-max){
                                        width: 100%;
                                        @include ann-describtion(1rem, $lato-regular, 1.5rem);
                                    }
                                }
                            }
                            &__action-container{
                                @include breakpoint(desktop-small){
                                    text-align: right;
                                    position: absolute;
                                    right: 0;
                                    bottom: .875rem;
                                }
                            }
                            &__action-link{
                                height: 50px;
                                padding: 12px 27px 14px;
                            }
                        }
                    }
                    &-slick__content{
                        .slick-list{
                            .manual-product-slider{
                                .cmp-teaser{
                                    &__content{
                                        margin-top: .9375rem;
                                        text-align: left;
                                        @include breakpoint(tab-max){
                                            margin-top: .625rem;
                                        }
                                    }
                                    &__title{
                                        h1,h2,h3,h4,h5,h6,p{
                                            @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                                            letter-spacing: normal;
                                            color: $text-dark-grey;
                                            width: 16rem;
                                            @include breakpoint(tab-max){
                                                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                                                width: 100%;
                                            }
                                        }
                                    }
                                    &__description{
                                        h1,h2,h3,h4,h5,h6,p{
                                            @include ann-describtion(1rem, $lato-regular, 1.5rem);
                                            letter-spacing: normal;
                                            color: $text-medium-dark-grey;
                                            margin-top: .3125rem;
                                            width: 16rem;
                                            @include breakpoint(tab-max){
                                                width: 100%;
                                            }
                                        }
                                        @include breakpoint(tab-max){
                                            display: none;
                                        }
                                    }
                                    &__action-container{
                                        margin-top: .9375rem;
                                        @include breakpoint(tab-max){
                                            margin-top: .625rem;
                                        }
                                    }
                                    &__action-link{
                                        @include ann-describtion(.6875rem, $lato-regular, .75rem);
                                        letter-spacing: .1144rem;
                                        padding-bottom: 7px;
                                        color: $color-light-blue;
                                        border-bottom-color: $color-light-blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}