.cart-landing {
  .kf-react-container.cart-landing {
    .cart-item-list-section {
      @include breakpoint(desktop-mid) {
        max-width: 68%;
      }
      .cart-landing {
        &__content {
          .cart-items {
            .cart-item{
              @include breakpoint(mobile){
                padding: 20px 0;
              }
            }
            .cart-item__image {
              @media (min-width: 1025px) and (max-width: 1440px) {
                width: 120px;
                height: 160px;
              }
            }
            .cart-header {
              .cart-items__title {
                color: $text-dark-grey;
                @include breakpoint(desktop-small) {
                  @include ann-describtion(
                    42px,
                    $playfair-display-regular,
                    54px
                  );
                  letter-spacing: -1.17px;
                }
                @include breakpoint(tab-max) {
                  @include ann-describtion(
                    28px,
                    $playfair-display-regular,
                    34px
                  );
                  letter-spacing: -0.78px;
                }
              }
            }
          }
        }
      }
    }
    .order-summary-cart {
      @include breakpoint(desktop-mid) {
        width: 21.6875rem;
      }

      .cart-accordian {
        .cart-accordion-section {
          .order-summary {
            &__input-group {
              .order-summary {
                &__input {
                  .input-field {
                    &__border {
                      @media (min-width: 1025px) and (max-width: 1440px) {
                        width: 14.6875rem !important;
                      }
                      @include breakpoint(desktop-small) {
                        min-width: 12rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .cart-id-share {
    @include breakpoint(mobile) {
      position: relative;
    }
  }
  .social-share {
    @include breakpoint(mobile) {
      position: absolute;
      right: 0;
      z-index: 9;
    }
    &__box {
      @include breakpoint(mobile) {
        position: unset;
      }
    }
  }
  .cart-info__brand-title {
    @include breakpoint(mobile) {
      display: flex;
      gap: 2px;
    }
  }
  button.kf-react-button.button.gbh-data-layer.sing-in-cart.large.black{
    background-color: $color-medium-blue;
  }
}
.v1_authModalWrapper__DV2_i {
  .modal-popup {
    .modal-popup-content {
      .modal-popup-body {
        .auth-modal__tabbed-panel {
          width: auto;
        }
      }
    }
  }
}
.cart-accordian {
  .cart-accordion-section {
    .order-summary__input {
      .input-field__border {
        label {
          font-family: $helvetica-regular;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: normal;
          color: $dark-gray;
          margin-bottom: 0;
        }
      }
    }
  }
}
.cart-accordion-section {
  .order-summary__input-group {
    div {
      .Input_inputFieldWrapper__vj2wJ {
        .input-field__value {
          input:placeholder-shown + label {
            transform: translateY(50%) scale(1);
          }
          input:focus + label {
            font-size: 10px;
            line-height: 10px;
            letter-spacing: -0.25px;
          }
        }
      }
    }
  }
}