#press-room-as {
  .press-room {
    &__description,
    &__tab-button,
    &__contact_title,
    &__print,
    &__share,
    &__contact_name,
    &__contact_mobile,
    &__contact_email,
    &__view_more,
    &__back-to-top {
      font-family: $lato-regular;
    }
    &__main-row {
      display: block;
      padding-bottom: 116px;
      @include breakpoint(mobile) {
        padding-right: 0;
        padding-bottom: 145px;
      }
      @include breakpoint(tab) {
        padding-right: 0;
        padding-bottom: 81px;
      }
    }
    &__header-block {
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
      display: block;
      padding-bottom: 0;
      @include breakpoint(mobile) {
        padding-right: 0;
      }
      @include breakpoint(tab) {
        padding-right: 0;
      }
    }
    &__title-block {
      width: 620px;
      max-width: 900px;
      padding: 0;
      @include breakpoint(mobile) {
        width: auto;
        max-width: 339px;
      }
      @include breakpoint(tab) {
        width: auto;
        max-width: 476px;
      }
    }
    &__description-block {
      display: block;
      max-width: 299px;
      padding: 0;
      @include breakpoint(mobile) {
        max-width: 299px;
      }
      @include breakpoint(tab) {
        max-width: 476px;
      }
    }
    &__title {
      color: $text-dark-grey;
      font-family: $playfair-regular;
      font-size: 90px;
      letter-spacing: -1.75px;
      line-height: 96px;
      padding: 82px 0 0 0;
      border-right: none;
      @include breakpoint(mobile) {
        font-size: 42px;
        letter-spacing: -2.8px;
        line-height: 48px;
        text-align: center;
        padding: 50px 0 0 0;
      }
      @include breakpoint(tab) {
        font-size: 54px;
        letter-spacing: -1px;
        line-height: 62px;
        padding: 69px 0 0 0;
      }
    }
    &__title,
    &__description {
      text-align: center;
      height: auto;
    }
    &__title-block,
    &__description-block,
    &__title,
    &__description {
      margin: auto;
    }
    &__description {
      color: $text-medium-dark-grey;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      padding: 0;
      margin-top: 30px;
      display: block;
      @include breakpoint(mobile) {
        margin-top: 25px;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        max-width: 299px;
      }
    }
    &__tab-header {
      background-color: $color-white;
      border-bottom: 1px solid $cool-dark-grey;
      height: auto;
    }
    &__tab-button {
      color: $text-light-grey;
      font-size: 14px;
      letter-spacing: 2.33px;
      line-height: 16px;
      text-align: center;
      padding: 30px 20px 25px 20px;
      text-transform: uppercase;
      @include breakpoint(desktop-small) {
        padding: 26px 20px 22px 20px;
      }
      &.--active {
        border-bottom: 2px solid $color-light-blue;
        color: $text-dark-grey;
      }
      @include breakpoint(mobile) {
        padding: 26px 20px 22px 20px;
        &:first-child {
          margin-left: 29px;
        }
      }
      @include breakpoint(tab) {
        padding: 27px 25.5px 20px 20px;
      }
    }
    &__side_pannel {
      margin-top: 60px;
      min-width: 240px;
      min-height: 537px;
      @include breakpoint(mobile) {
        min-width: auto;
        min-height: auto;
        margin: 40px 0 0 0;
        padding-bottom: 60px;
      }
      @include breakpoint(tab) {
        margin: 90px 0 0 0;
        min-width: auto;
        min-height: auto;
        padding-bottom: 73px;
      }
    }
    &__contact_item {
      margin-top: 20px;
    }
    &__contact {
      padding-top: 5px;
      border-bottom: 1px solid $cool-dark-grey;
      padding-bottom: 26px;
      @include breakpoint(mobile) {
        padding-top: 40px;
        padding-bottom: 30px;
      }
      @include breakpoint(tab) {
        padding-top: 0;
        padding-bottom: 0;
        min-width: 339px;
        min-height: 216px;
        margin-right: 69px;
        float: left;
        border-bottom: none;
      }
    }
    &__contact_title {
      color: $text-medium-dark-grey;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      margin-bottom: 0;
      @include breakpoint(tab) {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
    &__contact_name,
    &__view_more {
      letter-spacing: 0;
    }
    &__contact_name,
    &__contact_mobile,
    &__contact_email {
      color: $text-medium-dark-grey;
      font-size: 14px;
      line-height: 20px;
      @include breakpoint(tab) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
    &__contact_mobile,
    &__contact_email {
      &:hover {
        color: $text-medium-dark-grey;
        text-decoration: underline;
      }
    }
    &__contact_mobile {
      p {
        color: $text-medium-dark-grey;
        font-size: 14px;
        line-height: 20px;
        font-family: $lato-regular;
        letter-spacing: -0.2px;
        @include breakpoint(tab) {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
    }
    &__view_more {
      color: $text-medium-dark-grey;
      font-size: 14px;
      line-height: 20px;
      @include breakpoint(tab) {
        color: $color-light-blue;
        font-size: 11px;
        letter-spacing: 1.83px;
        line-height: 24px;
        margin-top: 13px;
        text-transform: uppercase;
        text-underline-offset: 5px;
      }
    }
    &__view-more-block {
      @include breakpoint(tab) {
        margin-top: 33px;
      }
    }
    &__contact_mobile,
    &__contact_email {
      letter-spacing: -0.2px;
      text-decoration: underline;
    }
    &__divider {
      display: none;
    }
    &__placement {
      margin-top: 30px;
      @include breakpoint(tab) {
        margin-top: 0;
        min-width: 339px;
        min-height: 216px;
      }
    }
    &__contact-button-block {
      margin-top: 30px;
      visibility: visible;
      display: block;
      @include breakpoint(mobile) {
        margin-top: 0;
        display: none;
      }
      @include breakpoint(tab) {
        margin-top: 0;
        display: none;
      }
    }
    &__main_pannel {
      margin-top: 60px;
      margin-left: 120px;
      @include breakpoint(mobile) {
        margin: 50px 0 0 0;
      }
      @include breakpoint(tab) {
        margin: 73px 0 0 0;
      }
    }
    .product-list__load-more {
      width: 100%;
      height: 40px;
      border: 1px solid $color-medium-blue-cap;
      // font-family: $font-bold-base;
      transition: all 0.3s ease-in-out;
      border-radius: 1px;
      color: $color-medium-blue-cap;
      font-size: 11px;
      font-weight: normal;
      letter-spacing: 2.5px;
      line-height: 10px;
      text-align: center;
      text-transform: uppercase;
      margin: 0 0 33px;
      @include breakpoint(tab) {
        width: 100%;
        font-size: 12px;
        line-height: 24px;
        margin: 0 0 40.38px;
      }
      @include breakpoint(mobile) {
        margin: 0 0 15px;
      }
    }
    &__back-to-top {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      @include breakpoint(mobile) {
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
      }
    }
    &__print-icon {
      height: 17px;
      width: 17px;
    }
    &__share-icon {
      height: 15.88px;
      width: 14px;
    }
    &__print,
    &__share {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 8px;
    }
    &__print {
      margin-right: 20px;
    }
    &__pagination {
      margin-bottom: 90px;
      @include breakpoint(tab) {
        justify-content: end;
      }
    }
  }
  .press-room .press-room__pagination .products-per-page {
    visibility: visible;
    @include breakpoint(mobile) {
      visibility: hidden;
    }
    @include breakpoint(tab) {
      visibility: visible;
    }
  }
  .product-list {
    &__go-back {
      height: 24px;
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      @include breakpoint(mobile) {
        height: 20px;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
      }
      img {
        height: 18.95px;
        width: 9.9px;
        margin-top: 2px;
        margin-left: 0;
      }
    }
    &__go-back-text {
      order: 2;
      margin-left: 15px;
    }
    &__go-back-image {
      order: 1;
    }
  }
  .products-per-page ul li {
    text-transform: uppercase;
    color: $text-medium-dark-grey;
    font-family: $font-regular-base;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    margin-right: 15px;
  }
  .products-per-page ul li.disabled {
    color: $text-dark-grey;
  }
  .products-per-page ul li.disabled {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4.5px;
    text-decoration-color: $cool-dark-grey-cap;
  }
  .products-per-page ul li.disabled:first-child:after {
    content: ":";
    font-family: $font-regular-base;
    font-size: 10px;
    letter-spacing: 1.7px;
    line-height: 16px;
    margin-right: 3px;
    text-decoration: none;
  }
  .products-per-page ul li.disabled:first-child {
    text-decoration: none;
    font-size: 10px;
    letter-spacing: 1.7px;
    color: $text-medium-dark-grey;
  }
  .press-release,
  .press-kit {
    margin-left: 0;
    .sort {
      &__toggle {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        padding-left: 0;
        width: auto;
        height: 100%;
        min-height: 30px;
      }
      &__toggle-icon {
        height: 6.97px;
        width: 12.15px;
        margin-left: 10px;
        margin-right: 0;
        flex: auto;
      }
    }
    &__sort-by-block {
      padding-bottom: 60px;
      @include breakpoint(mobile) {
        padding-bottom: 55px;
      }
      @include breakpoint(tab) {
        padding-bottom: 48px;
      }
      .sort__item {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
      .sort__item.active {
        color: $text-dark-grey;
        background-color: $half-white;
      }
      .sort__item:first-child {
        margin-top: 0;
        border-top: 1px solid $cool-dark-grey-cap;
      }
      .sort__drop-down {
        top: 41px;
      }
      .sort--open .sort__drop-down {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  @include breakpoint(mobile) {
    .press-room {
      &__pagination {
        margin-bottom: 40px;
      }
      &__contact {
        padding-top: 0;
      }
    }
  }
  @include breakpoint(tab) {
    .press-room {
      &__side_pannel {
        margin: 0;
        padding-bottom: 44.67px;
      }
    }
  }

  @media print {
    .sort__toggle,
    .pb-sitewide-messaging.carousel,
    .ann-sacks-main-menu,
    .cmp-breadcrumb__list,
    .button.product-list__load-more.gbh-data-layer,
    .press-room__pagination,
    .press-room__side_pannel {
      display: none;
    }
    .press-room {
      &__placement {
        margin-left: 0px;
      }
    }
    .press-room__side_pannel {
      display: flex;
      flex-direction: column;
    }
  }

  .press-room__inner-container {
    .press-room__main_pannel {
      .press-release {
        .product-list__load-more.gbh-data-layer {
          @include breakpoint(tab) {
            margin-bottom: 58.83px;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
    .press-room__side_pannel {
      @include breakpoint(mobile) {
        padding-bottom: 40px;
      }
      @include breakpoint(tab) {
        padding-bottom: 76.17px;
      }
      .press-room__contact {
        @include breakpoint(desktop-small) {
          padding-bottom: 30px;
          padding-top: 0;
        }
      }
      .press-room__placement {
        @include breakpoint(desktop-small) {
          .press-room__contact-button-block {
            span {
              .press-room__print-icon,
              .press-room__share-icon {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .lazyload-wrapper.kf-lazyload-wrapper {
      &:last-child {
        @include breakpoint(desktop-small) {
          .press-release__row {
            margin: 0 0 90px;
          }
        }
      }
    }
    @include breakpoint(mobile) {
      .press-release__row {
        .col.content-wrapper.px-0 {
          .read-more-section {
            .press-release__download-link.gbh-data-layer {
              .download-article.download-spinner {
                font-size: 11px;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }

  .press-room {
    @media (min-width: 640px) and (max-width: 900px) {
      &__side_pannel {
        display: inherit;
      }
    }
    @include breakpoint(tab) {
      &__inner-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }
  .press-release__row {
    .col.content-wrapper.px-0 {
      .read-more-section {
        a {
          font-family: $lato-regular;
          font-size: 11px;
          line-height: 12px;
          letter-spacing: 1.83px;
          color: $color-light-blue;
          text-transform: uppercase;
          align-items: center;
          display: flex;
          border-bottom: 1px solid $color-light-blue;
          padding-bottom: 7px;
        }
      }
    }
  }
}
