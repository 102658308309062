.radio {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	margin-right: 10px;
}
.radio__input {
	display: none;
	&:checked {
		+ {
			.radio__custom {
				&::after {
					transform: scale(1);
				}
			}
		}
	}
}
.radio__custom {
	width: 1.25em;
	height: 1.25em;
	border: 2px solid $color-light-gray;
	border-radius: 50%;
	margin-right: 10px;
	box-sizing: border-box;
	padding: 2px;
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		display: block;
		background: $color-black;
		border-radius: 50%;
		transform: scale(0);
		transition: transform 0.15s;
	}
}

@media screen and (max-width: 767px) {
    .radio {
        margin-bottom: 0;
      }
}