.shipping-form {
    .select-dropdown {
        @include breakpoint (mobile) {
            padding-bottom: 20px;
        }
    }
}
.check-out{
    .step-nav {
        .step-nav__number{
            line-height: 40px;
            letter-spacing:-1.6px;
        }
        .step-nav__content {
            .step-nav__title{
                h2{
                line-height: 40px;
                letter-spacing: -1.6px;
                }
                .step-nav__edit-btn{
                    color: $charcoal;
                    border: 1px solid $charcoal;
                    line-height: 24px;
                }
            }
        }
    }
    .collapsible-accordion .Collapsible__trigger .collapsible__header .plus .line-2 {
        padding-left: 1px;
    }
}

@include breakpoint (mobile) {
    .button.small, .kf-react-button.small {
        color: $charcoal;
        line-height: 24px;
    }
}
@include breakpoint (tab) {
    .button.small, .kf-react-button.small {
        color: $charcoal;
        line-height: 24px;
    }
}