.article-featured-card {
    margin-top: 0px !important;
    &.teaser {
        .cmp-teaser {
            width: 100%;
            display: flex;
            gap: 90px;
            flex-direction: row-reverse;
            align-items: center;
            @include breakpoint (desktop-small) {
                width: 1380px;
                margin: 0 auto;
            }
            @include breakpoint (desktop-extra-sm) {
                width: 100%;
                padding: 0 32px;
            }
            @include breakpoint (tab) {
                max-width: 46.75rem;
                margin: 0 auto;
                gap: 3.125rem;
            }
            @include breakpoint (mobile) {
                width: 21.1875rem;
                margin: 0 auto;
                flex-direction: column;
                gap: 30px;
                padding: 20px 0 25px;
            }
            .cq-dd-image.cmp-teaser__image {
                @include breakpoint (mobile) {
                    width: 21.1875rem;
                }
            }
            &__content {
                .cmp-teaser__pretitle {
                    h1, h2, h3, h4, h5, h6, p {
                    @include ann-describtion(.75rem, $lato-regular, 1rem);
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    color: $text-dark-grey;
                    }
                }
                .cmp-teaser__title {
                    h1, h2, h3, h4, h5, h6, p {
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                    letter-spacing: -1.17px;
                    color: $text-dark-grey;
                    margin-bottom: 20px;
                    width: 38rem;
                    @include breakpoint (tab) {
                        @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                        width: 23.125rem;
                    }
                    @include breakpoint (mobile) {
                        @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                        width: 18.6875rem;
                    }
                    }
                }
                .cmp-teaser__description {
                    h1, h2, h3, h4, h5, h6, p {
                        @include ann-describtion(1rem, $lato-regular, 1.5rem);
                        color: $text-medium-dark-grey;
                        margin-bottom: 3.125rem;
                        width: 38rem;
                        @include breakpoint (tab) {
                            width: 23.125rem;
                            @include ann-describtion( .875rem, $lato-regular, 1.25rem);

                        }
                        @include breakpoint (mobile) {
                            width: 18.6875rem;
                            @include ann-describtion( .875rem, $lato-regular, 1.25rem);
                        }
                    }
                }
                .cmp-teaser__action-container {
                    .cmp-teaser__action-link  {
                        @include ann-describtion(.6875rem, $lato-regular, 1rem);
                        letter-spacing: 1.8px;
                        text-transform: uppercase;
                        color: $color-light-blue;
                    }
                }
            }
            .cmp-teaser__image {
                .cmp-image {
                    &__image {
                        @include breakpoint (desktop-small) {
                            min-width: 42.625rem;
                        }
                    }
                }
            }
        }
    }
}