.text-title-list-style {
  padding: 4.375rem 4.375rem;
  @include breakpoint(tab) {
    padding: 3.125rem 2rem;
  }
  @include breakpoint(mobile) {
    padding: 3.125rem 1.125rem;
  }
  .cmp-text {
    display: flex;
    flex-direction: row;
    max-width: 1380px;
    margin: 0 auto;
    column-gap: 3.75rem;
    border-top: 1px solid $cool-dark-grey;
    border-bottom: 1px solid $cool-dark-grey;
    padding: 4.375rem 0 3.625rem;
    @include breakpoint(tab) {
      flex-direction: column;
      padding: 3.125rem 0;
      column-gap: 0;
    }
    @include breakpoint(mobile) {
      flex-direction: column;
      padding: 3.125rem 0;
      column-gap: 0;
    }

    h1, h2, h3, h4, h5, h6, p {
      @include headingtitle_h4;
      color: $text-dark-grey;
      font-size: 2.625rem;
      line-height: 3rem;
      letter-spacing: -0.073125rem;
      font-family: $playfair-regular;
      width: 47.82%;
      margin: 0;
      text-align: left;
      @include breakpoint(tab) {
        font-size: 1.75rem;
        line-height: 2.125rem;
        letter-spacing: -0.073125rem;
        width: 100%;
        padding-bottom: 2.5rem;
      }
      @include breakpoint(mobile) {
        font-size: 1.75rem;
        line-height: 2.125rem;
        letter-spacing: -0.073125rem;
        width: 100%;
        padding-bottom: 2.5rem;
      }
    }

    ul, ol {
      width: 39.13%;
      margin: 0;
      padding: 0;
      @include breakpoint(tab) {
        width: 100%;
        padding-left: 1.6875rem;
      }
      @include breakpoint(mobile) {
        width: 100%;
      }

      li {
        font-size: .875rem;
        font-family: $lato-regular;
        line-height: 1.25rem;
        letter-spacing: 0;
        padding-bottom: 18px;
        color: $text-medium-dark-grey !important;
        text-align: left;
        &:last-child {
          padding-bottom: 0;
        }
        @include breakpoint(tab) {
          padding-left: .3125rem;
          margin: 0;
        }
        @include breakpoint(mobile) {
          padding-left: .3125rem;
          margin: 18px;
        }
      }
    }
  }
}
/* GBH-9822 End */
// FAQ text style
.text.faq-text{
  margin-bottom:2.5rem;
  padding-right: 3.875rem;
  @include breakpoint(tab-max){
      padding-right:0;
      margin-bottom:1.875rem;
  }
  .cmp-text{
      p{
          @include ann-describtion(14px, $lato-regular, 20px);
          color: $text-light-grey !important;
          margin:0;
          letter-spacing: normal;
      }
      ul,ol{
          margin:1.875rem 0 0;
          padding:0;
          @include breakpoint(tab){
              max-width:330px;
          }
          @include breakpoint(tab-max){
            margin-top:.9688rem;
          }
          @include breakpoint(mobile){
            padding:0 15px;
          }
          li{
              font-size: 14px;
              line-height: 20px;
              letter-spacing: normal;
              color:$text-medium-dark-grey;
              margin:0 2rem;
              @include breakpoint(tab-max){
                 margin-bottom:0.625rem;
              }
              @include breakpoint(mobile){
                margin:0 10px;
              }
              &:last-child{
                  @include breakpoint(tab-max){
                    margin-bottom:0;
                  }
              }
          }
      }
      a{
        color:$text-medium-dark-grey;
        text-decoration:underline;
        text-underline-position: under;
      }
  }
}
.article-text-display-style-two{
    width:56.25rem;
    margin:0 auto;
    @include breakpoint(tab){
      width:100%;
    }
    @include breakpoint(mobile){
      width:100%;
    }
    .cmp-text{
        h1,h2,h3,h4,h5,h6{
            @include headingtitle_h3;
               margin-bottom:3.75rem;
              text-align: center;
            @include breakpoint(tab){
              font-size:2.25rem;
              line-height:2.125rem;
              letter-spacing:-0.125rem;
              margin-bottom:2.5rem;
            }
            @include breakpoint(mobile){
              font-size:2.25rem;
              line-height:2.125rem;
              letter-spacing:-0.125rem;
              margin-bottom:2.1875rem;
            }
        }
        p{
          @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
            margin-bottom:2.25rem;
            max-width:100%;
          @include breakpoint(tab){
            @include ann-describtion(.875rem, $lato-regular, 1.25rem);
            margin-bottom:1.875rem;
          }
          @include breakpoint(mobile){
            @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
            margin-bottom:1.875rem;
            padding-right: 1.875rem;
            padding-left: 1.875rem;
          }
          &:last-child{
            margin-bottom:0;
          }
        }
     }
}
.article-content-font-light{
    @include ann-describtion(1.375rem, $lato-regular, 2rem);
    letter-spacing: normal;
    color:$text-medium-dark-grey;
    @include breakpoint(tab-max){
        @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
        letter-spacing: normal;
    }
    &.article-text-display-style-two{
      padding:70px 0;
      width:33.75rem;
      @include breakpoint(tab){
        width:34.625rem;
        padding: 50px 0;
      }
      @include breakpoint(mobile){
        width:100%;
        padding:50px;

      }
      .cmp-text{
            p{
              a {
                color: $text-medium-dark-grey;
                &:hover {
                  border-bottom: 1px solid $text-medium-dark-grey;
                }
              }
              @include ann-describtion(1.375rem, $lato-regular, 2rem);
              color:$text-medium-dark-grey;
              @include breakpoint(tab-max){
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
              }
              @include breakpoint(mobile){
                padding:0 3.0625rem;
              }
            }
      }
    }
    
}
// Global Text only Hero V9.
.hero-banner-membership-landing {
  padding: 7.6875rem 0;
  @include breakpoint(tab-max){
    padding: 5rem 0 5.625rem;
  }
  @include breakpoint (mobile) {
    padding: 5rem 18px 5.625rem;
  }
  &.text {
    .cmp-text { 
      margin: 0 auto;
      text-align: center;
      max-width: 41.25rem;
      @include breakpoint (tab) {
        max-width: 21.25rem;
      }
      @include breakpoint (mobile) {
        width: 100%;
      }
      h1, h2, h3, h4, h5, h6 { 
        font-size: 3.375rem;     
        margin: 0; 
        font-family:$playfair-regular;
        line-height: 62px;
        letter-spacing: 1px;
        @include headingtitle_h1;
        color: $text-dark-grey;
        @include breakpoint (tab-max) {        
          letter-spacing: -0.175rem;
        }
      }
      ul {
        color:$text-medium-dark-grey-small;
      }
      p {
        max-width:33.75rem;
        @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
        letter-spacing: normal;
        color: $text-medium-dark-grey;
        margin: 2.8125rem auto 0;
        padding-top: 2.8125rem;
        @include breakpoint (tab-max) {
          @include ann-describtion(1rem, $lato-regular, 1.5rem);
          letter-spacing: inherit;
          width: 17.375rem;
          padding-top: 1.25rem;
          margin: 1.25rem auto 0;
        }
      }
    }
  }
}
// End
.access-denied {
  @extend .hero-banner-membership-landing;
  &.text {
    background-color: $cool-light-grey;
    padding-top: 1px;
	  padding-bottom: 1px;
    .cmp-text {
      width: 41.25rem;
      text-align: left;    
      @include breakpoint (tab) {
        width: 16.5625rem;
      }
      @include breakpoint (mobile) {
        width: 100%;
      }
      h1, h2, h3, h4, h5, h6 {
        width: auto;
        margin: 15rem 0 2.5rem 0; 
        @include ann-describtion(90px, $playfair-display-regular, 96px);
        letter-spacing: -1.75px;
        color: $text-dark-grey;
        @include breakpoint (tab) {
          width: inherit;
          @include ann-describtion(42px, $playfair-display-regular, 48px);
          letter-spacing: -2.8px;
          margin: 7.5rem 0 1.25rem 0;
        }
        @include breakpoint (mobile) {
          @include ann-describtion(42px, $playfair-display-regular, 48px);
          letter-spacing: -2.8px;
          margin: 5.625rem 0 1.25rem 0;
        }
      }
      p {
        width: auto;
        @include ann-describtion(36px, $playfair-display-regular, 44px);
        letter-spacing: -2px;
        color: $text-dark-grey;
        margin: 0 0 25.6875rem 0;
        padding: 0;
        @include breakpoint (tab) {
          @include ann-describtion(18px, $playfair-display-regular, 30px);
          letter-spacing: -0.67px;
          width: inherit;
          margin: 0 0 11.375rem 0; 
        }
        @include breakpoint (mobile) {
          @include ann-describtion(18px, $playfair-display-regular, 30px);
          letter-spacing: -0.67px;
          width: 100%;
          margin: 0 0 12.0625rem 0;
        }
      }
    }
  }
}
// Article Tile Module
.article-center-container{
  .cmp-container{
    .text {
      width: 100%;
      text-align: center;
        .cmp-text {
            h1, h2, h3, h4, h5, h6, p {
                letter-spacing: normal;
                margin: 0;
                color:$text-dark-grey;
                @include breakpoint(desktop-small) {
                    @include ann-describtion (1.625rem, $lato-regular, 2.25rem);
                    padding: 4.375rem 0 3.125rem;
                }
                @include breakpoint(tab-max){
                  @include ann-describtion (1.375rem, $lato-regular, 2rem); 
                  padding:3.125rem 0 2.5rem;
                }
            }
        }
    }
  }
    
}