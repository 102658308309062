.kf-page-title {
    display: flex;
    &__titleBox {
        flex: 8;
        margin: 0;
    }

    &__title {
        color: $color-slate;
        font-family: $font-light;
        font-size: 100px;
        letter-spacing: -7.5px;
        line-height: 110px;
        padding-bottom: 130px;
    }

    &__descBox {
        flex: 4.1;
        border-left: 1px solid $color-dark-gray;
    }

    &__desc {
        margin: 0;
        margin-left: 30px;
        color: $color-dark-gray;
        font-family: $font-light;
        font-size: 32px;
        letter-spacing: -1.6px;
        line-height: 40px;
    }

    &__total {
        color: $color-slate;
        font-family: $font-light;
        font-size: 32px;
        letter-spacing: -1.6px;
        line-height: 40px;
        height: 40px;
    }
}
.product-list__sticky-nav-wrap{
    &.article-sticky{
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 860px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 991px){
    .kf-page-title {
       display: block;
       margin: 0;
       
        &__title {
            font-size: 46px;
            letter-spacing: -3.4px;
            line-height: 50px;
            margin-bottom: 20px;
        }

        &__desc {
            font-size: 26px;
            letter-spacing: -1.5px;
            line-height: 34px;
        }

        &__total {
            font-size: 26px;
            letter-spacing: -1.5px;
            line-height: 34px;
            margin-bottom: 30px;
            height: auto;
        }
    }
}
@media screen and (max-width: 992px){
    .product-list__sticky-nav-wrap{
        &.article-sticky{
            .product-list__controls{
                padding-top: 15px; border-top: 1px solid $color-light-gray;
            }
        }
    }
    .kf-page-title {
        &__title {
            padding-bottom: 0;
        }
        &__descBox {
            border: none;
        }
        &__desc{
            margin: 0;
        }
        &__total {
            margin-top: 24px;
        }
    }
}

@media screen and (max-width: 767px){
    .kf-page-title {
        display: block;
        margin: 0;
       
        &__title {
            font-size: 46px;
            letter-spacing: -3.4px;
            line-height: 50px;
            margin-bottom: 10px;
        }

        &__desc {
            font-size: 26px;
            letter-spacing: -1.5px;
            line-height: 34px;
        }

        &__total {
            font-size: 26px;
            letter-spacing: -1.5px;
            line-height: 34px;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}