.contact-us-main{

  #contact-user-state, #contact-installed-productTypes, #contact-installed-purchase, #contact-form-businessType{
    .select-dropdown__display{
      .select-dropdown__placeholder {
        letter-spacing: normal;
        line-height: 26px;
        color: $text-light-grey-cap;
        font-family: $lato-regular;
      } 
    }
  }

  #contactus-request-message ~ .description{
    font-family: $lato-regular;
  }
  .textare-label-message{
    font-size: 18px;
    font-family: $lato-regular;
    color: $text-light-grey-cap;
  }
  .textarea__wrapper textarea:placeholder-shown+label{
    letter-spacing: normal;
  }
  .textarea__wrapper textarea:focus+label, .textarea__wrapper textarea:not(:empty)+label
  {
    font-size: 13px;
    font-family: $lato-regular;
  }
  .form-wrapper.textarea__wrapper{
    max-height: 212px;
    border-radius: 3px;
    border-color: $cool-dark-grey;
    &:focus-within{
      border: 1px solid $text-dark-grey;
    max-height: 212px;
    border-radius: 3px;
    border-color: $cool-dark-grey;
    }
    @include breakpoint(tab-max){
      min-height: 200px;
      height: 200px;
    }
    textarea{
      color: $text-dark-grey !important;
      @include breakpoint(tab-max){
        height: 100% !important;
      }
    }
    @include breakpoint(tab-max){
      min-height: 200px;
      height: 200px;
    }
    textarea{
      @include breakpoint(tab-max){
        height: 100% !important;
      }
    }
  }
  .optional-form.border-0{
    .description{
      font-family: $lato-regular;
      @include breakpoint(tab-max){
        padding: 0 !important;
      }
    } 
    .upload-notes{
      span:last-child{
        font-family: $lato-bold;
      }
      >span{
        font-family: $lato-regular;
      }
    }
  }

  .optional-form{
    border-color: $cool-dark-grey !important;
    &.professional-group{
      .radio-section{
        margin-bottom: 60px;
        @include breakpoint(tab-max){
          margin-bottom: 40px;
        }
      }
      .radio-wrapper{
        margin: 30px 0 0;
      }
      .form-wrapper{
        margin-top: 30px;
        margin-bottom: 0px;
        #kf-professional-company-wrapper{
          margin-bottom: 0px;
        }
      }
    }
    > div.question{
      margin-bottom: 20px;
    }
    .form-wrapper{
      @include breakpoint(tab-max){
        margin-bottom: 40px !important;
    }
      .kf-react-datepicker{
        .react-datepicker-wrapper{
          width: 100%;
        }
      }
    }
    .upload-image-container{
      background-color: $cool-light-grey;
      @include breakpoint(mobile){
        padding: 30px 0;
        width: 375px;
        margin-left: -18px;
        margin-right: -18px;
        .drag-and-drop-container{
          margin-top: 0;
          .drag-and-drop-upload-btn{
            .kf-react-button.button{
              width: 277px;
              height: 50px;
              padding: 13px 27px;
              align-items: center;
            }
          }
        }
      }
      @include breakpoint(tab){
        padding: 30px 0;
        .drag-and-drop-container{
          margin-top: 0;
          .drag-and-drop-upload-btn{
            .kf-react-button.button{
              width: 157px;
              height: 50px;
              padding: 12px 27px 14px;
              align-items: center;
            }
          }
        }
      }
      &.file-5{
        @include breakpoint(tab-max){
          padding: 20px 30px !important;
          position: relative;
          .contact-us-image-preview{
            margin-top: 5px;
            &:first-child{
              margin-top:0;
            }
          }
        }
        @include breakpoint(mobile){
          width: 375px;
          padding-left: 18px;
          padding-right: 18px;
          left:-18px;
        }
        .contact-us-image-preview + .drag-and-drop-container{
          @include breakpoint(tab-max){
              margin-top:20px;
          }
        }
      }
      &__error-msg{
          font-size: 13px;
          letter-spacing: 0.22px;
          position: relative;
          bottom: 20px;
        @include breakpoint(tab-max){
          @include text-style (13px, 20px, 0.22px);
          padding-top: 20px;
        }
        &:empty{
          padding-top: 0;
        }
      }
      .contact-us-image-preview{
        textarea{
          font-family: $helvetica-regular;
          color: $text-dark-grey;
          @include breakpoint(mobile){
            padding: 15px 37px 14px 15px;
          }
        }
        .image-delete{
          img{
            width: 11.06px;
            height: 10.71px;
            color: $text-medium-dark-grey;
          }
        }
        @include breakpoint(mobile){
          height: 120px;
        }
        textarea::placeholder {
          color: $text-medium-dark-grey;
          font-family: $lato-regular;
        }
        &.active-comment{
          border: 1.5px solid $text-dark-grey;
        }
      }
      .drag-and-drop-container{
        padding: 50px 0 29px;
      }
    }
    .radio-wrapper{
      margin: 30px 0 40px;      
      .radio__custom{
        border: 1px solid $medium-gray;
        width: 20px;
        height: 20px;
        &::after{
          background-color:$color-light-blue;
        }
      }
      .radio{
        span{
          color: $text-medium-dark-grey-small;
        }
      }
    }
  }
  .contact-us-footer{
      .kf-react-button{
        text-transform: uppercase;
        text-align: center;
        font-family: $lato-bold;
        @include text-style (12px, 24px, 2.5px);
        font-weight: normal;
        text-align: center;
        height: 50px;
        background-color: $color-medium-blue-cap;
        &.disabled{
          background-color: $light-gray;
          color: $white;
        }
      }
    
    .drag-and-drop-container{
      @include breakpoint(tab-max){
        margin-top: 0 !important;
      }
    }
    .upload-image-container{ 
      .contact-us-image-preview{
        @include breakpoint(tab-max){
          margin-top: 5px !important;
        }
      }     
      &.file-5{
        @include breakpoint(mobile){
          margin: 40px -18px;
        }
        @include breakpoint(tab-max){
          padding:20px 18px !important;
        }
        .drag-and-drop-container{
          @include breakpoint(tab-max){
            margin-top: 30px !important;
          }
        }
      .contact-us-image-preview:last-child{
          margin-bottom: 30px;
        }
      }
    }
  }

  #contact-image-preview-container{
    .drag-and-drop-container{
      .drag-and-drop-text-large{
        @include text-style (26px, 36px, normal);
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
      }
      .drag-and-drop-text-small{
        margin-top: 20px;
        color: $text-light-grey;
      }
      .drag-and-drop-upload-btn{
        margin-bottom: 20px;
        @include breakpoint(tab-max){
          margin-bottom: 10px;
        }
      }
      .drag-and-drop-upload-btn>a{
        @include text-style (12px, 24px, 2.5px);
        color: $color-medium-blue-cap;
        font-family: $lato-bold;
        text-transform: uppercase;
        border: 1px solid $color-medium-blue;
        padding: 12px 27px 12px;
      }
    }
  }
  #contact-installed-helpTopic{
    .select-dropdown__display{
      .select-dropdown__placeholder {
        font-family: $lato-regular;
        @include text-style (18px, 26px, normal);
        color: $text-light-grey-cap;
      }
    }
  }

  .contact-us-footer{
    &__policies{
      font-family: $lato-regular;
      @include text-style (13px, 20px, 0.25px);
      color: $text-medium-dark-grey;
    }
    .recaptcha-links{
      font-family: $lato-regular;
      @include text-style (13px, 20px, 0.2px);
      color: $text-medium-dark-grey;
      margin: 20px 0 30px 0;
      .gpolicies {
        color: $text-medium-dark-grey;
    }
    }
    .contact-us__policies a {
      font-family: $lato-regular;
      @include text-style (13px, 20px, 0.25px);
      color: $text-medium-dark-grey;
    }
  }
  .container{
    @include breakpoint(mobile){
      border-bottom: 1px solid $cool-dark-grey;
    }
    @include breakpoint(tab){
      border-bottom: 1px solid $cool-dark-grey;
    }
    .row{
      .col-12.pr-10{
      @include breakpoint(tab){
        padding-right: 10px !important;
      }
      }
      .col-12.pl-10{
        @include breakpoint(tab){
          padding-left: 10px !important;
        }
        }
      .col-sm-6.pr-10{ 
        @include breakpoint(tab){
          padding-right: 10px !important;
        }
      }
      .col-sm-6.pl-10{ 
        @include breakpoint(tab){
          padding-left: 10px !important;
        }
      }
      .col-md-6.pr-10{
        @include breakpoint(tab){
          padding-right: 10px !important;
        }
      } 
      .col-md-6.pl-10{
        @include breakpoint(tab){
          padding-left: 10px !important;
        }
      }
      .select-dropdown{
        @include breakpoint(tab-max){
          margin-bottom: 0px;
        }
      } 
    }
  }
  .select-dropdown{
    &__field{      
        border: 1px solid $cool-dark-grey;
        border-radius: 3px;      
      &:focus{        
          border: 1px solid $text-dark-grey;
          border-radius: 3px;
      }
    }
    &__list-value{
      @include text-style (14px, 20px, normal);
      font-family: $lato-regular;
      color: $text-dark-grey;
    }
    &__list-item{
      &.selected-item{
        background-color: $color-light-blue;  
        .select-dropdown{
          &__list-value {            
            color: $color-white; 
          }     
          
        }
              
    }
    &:hover{      
        background-color: $cool-light-grey;      
    }
    }
    &__icon{
      img{
        width: 12.15px;
        height: 6.97px;
        color: $text-medium-dark-grey;
      }
    }
    &__display{
      .select-dropdown {
        &__value{
          font-size: 18px;
        }
      }
    }
  }
  .row{
    .col-md-6{
      &.pr-10{
        padding-right:10px;
        @media only screen and (max-width: 576px) {
          padding-right:0;
        }
        
      }
      &.pl-10{
        padding-left: 10px;
        @media only screen and (max-width: 576px) {
          padding-left: 0;
        }
      }
    }
  }
  .container{
    &.mb-40{
        border-bottom: 1px solid $cool-dark-grey;
        padding-bottom: 40px;
        @include breakpoint(tab-max){
          padding-bottom: 0;
        }
    }
  }
}
.contact-us-count{
  position: absolute;
  bottom: 5px;
  right: 10px;
  top: unset!important;
  padding: 0!important;
  font-family: $lato-regular;
  font-size: 12px;
  line-height: 20px;
  color: $text-light-grey;
}
.contactus {
  .kf-react-container {
    @include breakpoint(mobile) {
      padding: 0px 17.58px 0px 18px !important
    }
    .contact-us__success-message{
      .message-content{
        .title{
          font-family: $playfair-regular;
          @include text-style (3.375rem, 3.875rem, -0.0625rem);
          color: $text-dark-grey;
        }
        .details{
          font-family: $lato-regular;
          @include text-style (1rem, 1.5rem, normal);
          color: $text-medium-dark-grey;
        }
      }
      .kf-react-button.contact-us__continue-shopping-btn{
        font-family: $lato-bold;
        @include text-style (.75rem, 1.5rem, .1563rem);
        color: $white;
        background-color: $color-medium-blue-cap;
        text-transform: uppercase;
        padding: 12px 27px 14px;
        height: 50px;
      }
    }
  }
  .description{
    color: $text-medium-dark-grey;
  }
  .optional-form{
    .upload-notes{
      color: $text-medium-dark-grey;
    }
  }
}
.contact-info-form {
  label {
    font-family: $lato-regular;
    line-height: 155.6%;
  }
  .input-field {
    &__value {
      input {
        @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
        letter-spacing: normal;
        color: $text-dark-grey;
      }
    }
  }
}