// Container styles
.two-up-content-sixty-by-fourty{
    padding: 70px 36px;
    @include breakpoint (tab){
        padding: 50px 32px;
    }
    @include breakpoint (mobile){
        padding: 50px 18px;
    }
    .cmp-container{
        max-width: 1380px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: space-between;
        @include breakpoint(mobile) {
            flex-direction: column-reverse; 
        }
    }
}
.two-up-content-sixty-by-fourty-reverse{
    padding: 70px 36px;
    @include breakpoint (tab){
        padding: 50px 32px;
    }
    @include breakpoint (mobile){
        padding: 50px 18px;
    }
    .cmp-container{
        max-width: 1380px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        @include breakpoint(mobile) {
            flex-direction: column-reverse; 
        }
    }
    .two-up-fourty-by-hundred, .two-up-sixty-by-hundred{
        .cmp-teaser{
            &__action-link{
                color: $color-light-blue;
                border-bottom: 1px solid $color-light-blue;
            }
        }
    }
}
// Teaser width styles
.two-up-sixty-by-hundred{
    width: 60.86%;
    padding: 0;
    margin: 0;
    @include breakpoint (tab){
        width: 62.83% ;
    }
    @include breakpoint (mobile){
        width: 100%;
        padding-top: 40px;
    }
    .cmp-teaser{
        &__content{
            width: 53.70%;
            @include breakpoint (tab){
                width: 57.03%;
            }
            @include breakpoint (mobile){
                width: 100%;
            }
        }
    }
}
.two-up-fourty-by-hundred{
    width: 34.78%;
    padding: 0;
    margin: 0;
    @include breakpoint (tab){
        width: 35.82%;
    }
    @include breakpoint (mobile){
        width: 100%;
    }
    .cmp-teaser{
        &__content{
            width: 93.96%;
            @include breakpoint (tab-max){
                width: 100%;
            }
        }
    }
}
// Common Text Styles
.two-up-sixty-by-hundred, .two-up-fourty-by-hundred{
    .cmp-teaser  {
        &__content{
            padding: 30px 0 0;
            @include breakpoint (tab-max){
                padding: 20px 0 0;
            }
        }
        &__title {
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                letter-spacing: 0;
                margin: 0;
                padding: 0;
                @include breakpoint (tab-max){
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                }
            }
        }
        &__description {                
            h1,h2,h3,h4,h5,h6,p {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0;
                padding: 10px 0 0;
                margin: 0;
                width: 93.13%;
                @include breakpoint (tab-max){
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    width: 100%;
                }
            }
        }
        .cmp-teaser__action-container{
            .cmp-teaser__action-link   {
                @include ann-describtion(.6875rem, $lato-regular, .8125rem);
                letter-spacing: .114375rem;
                padding: 0 0 7px 0;
                margin-top: 20px;
                color: $color-light-blue;
                border-bottom: 1px solid $color-light-blue;
                @include breakpoint (tab-max){
                    letter-spacing: 1.8px;
                    line-height: 16px;
                }
            }
        }
       
    }
}