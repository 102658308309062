

.kf-lazyload-wrapper {
  min-height: auto;
}
.plppage .breadcrumb.fc-slate .cmp-breadcrumb .cmp-breadcrumb__list .cmp-breadcrumb__item .cmp-breadcrumb__item-link { 
  span, span p {
    color: $color-dark-gray !important;
  }
}

.product-listing {
  &__container {
    max-width: 86.25rem;
  }

  &__fillter_sort{
        display: none;
          font-family: $lato-regular;
            @media screen and (max-width: 991px) {
                display: inline-block;
                font-size: 1.125rem;
                line-height: 1.5rem;
                color: $slate;
                letter-spacing: -0.45px;
                margin: 0 10px 0 0px;
            }
  }
  
  &__curated{
    padding-top: 3.75rem !important;
  }

  &__transparent-button{
    background: none;
    border: none;
    outline: none;
    margin-left: 20px;
  }

  &__total {
    font-family: "Helvetica Now Text W05 Light";
    font-size: 26px;
    max-height: 34px;
    line-height: 34px;
    letter-spacing: -1.5px;
    color: $slate;
  }

  &__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    .filter-col {
      position: relative;
    }
    .product-listing__tile {
      padding-right: 16px;
    }
    &.row-cols-3 {
      margin-right: -16px;
      .product-listing__tile, .product-listing__tile.promo-col-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3%;
        flex: 0 0 33.3%;
        max-width: 33.3%;
      }
      .product-listing__tile.promo-col-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6%;
        flex: 0 0 66.6%;
        max-width: 66.6%;
      }
    }
    &.row-cols-2 {
      margin-right: -16px;
      .product-listing__tile, .product-listing__tile.promo-col-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .product-listing__tile.promo-col-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  &__col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  &__filter-toggle {
    display: flex;
    align-items: center;
    font-family: "Helvetica Now Text W05 Regular";
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.45px;
    color: $color-charcoal;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;

    &-text {
      list-style: none;
      padding: 0;
      margin: 0 10px 0 0;
      text-align-last: left;
      height: 24px;
      overflow: hidden;

      li {
        height: 24px;
        transition: all 0.3s ease-in-out;

        &.hide {
          opacity: 0;
          transform: scale(0.9);
        }

        &.show {
          opacity: 1;
        }
      }

      li:last-child {
        transform: translateY(-30%);
      }

      &--show {
        li:last-child {
          transform: translateY(-100%);
        }
      }
    }

    &-icon {
      width: 34px;
      height: 30px;
      padding: 8px 6px 6px;
    }
  }

  &__close-icon {
    width: 16px;
    height: 16px;
  }

  &__sticky-nav-wrap {
    position: relative;
    z-index: 2;   
  }

  .sticky{
    .product-listing__sticky-nav{
      padding: 15px 0;
    @media only screen and (max-width: 991px) {
      padding: 11px 0;
      }
    }
  }

  &__sticky-nav {
    background: $color-white;
    transition: all 0.3s ease;
    padding-top: 40px;
    padding-bottom: 30px;

    &--shadow {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
    }
  }

  .sorting-section {
    margin-right: -6px;
    @media only screen and (max-width: 768px) {
        margin-right: -6px;
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-start;
    height: 30px;
    align-items: center;

    .control-left-section {
      width: 300px;
      position: absolute;
    }

    .control-right-section {
      display: flex;
      align-items: center;
      justify-content:flex-end;
      width: 100%;
      margin-left: 350px;
      position: relative;
    }

    section {
      display: flex;
      min-height: 34px;
      align-items: center;
    }
  }

  &__filter {
    width: 237px;
    height: 100%;
    position: absolute;
    opacity: 1;
    transition: all 0.5s ease-in-out;

    &-curated{
      .filter__print-and-share{
        margin-top: 30px;
      }
    }  
    &--hide {
      opacity: 0;
    }

    &--sticky {
      position: sticky;
      top: 80px;
    }
    .social-share{
      position: relative;
      top: -50px;
      left: -15px;
      transform: scale(0.8);
      width: fit-content;
    }
  }
  &__tiles {
    margin-bottom: 124px;
    @media only screen and (min-width: 1024px) and (max-width:1279px) {
      margin-bottom:40px;
    }
  }

  &__tiles-knowledge-articles {
    margin-bottom: 63px;
  }

  &__tile-col {
    transition: all 0.5s ease-in-out;
    margin-left: 266px;
    min-height: calc(100vh - 100px);
    position: relative;
    &--full-width {
      margin-left: 0px;
      .product-listing__row {
        &.row-cols-3 {
           .product-listing__tile, .product-listing__tile.promo-col-1 {
            -ms-flex: 0 0 33.33%;
            flex: 0 0 33.33%;
            max-width: 33.33%;
          }
          .product-listing__tile {
            &.promo-col-2 {
              -ms-flex: 0 0 66.66%;
              flex: 0 0 66.66%;
              max-width: 66.66%;
            }
            &.promo-col-3 {
              -ms-flex: 0 0 100%;
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
        &.row-cols-2 {
          .product-listing__tile {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            &.promo-col-2,  &.promo-col-3 {
              -ms-flex: 0 0 100%;
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  &__tile {
    margin-bottom: 60px;
    position: relative;
    @media only screen and (max-width:1279px) {
      margin-bottom: 10px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .product-listing__tile.promo-col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .product-listing__load-more {
    width: 100%;
    background: $color-white;
    border: solid 1px $slate;
    font-family: "Helvetica Now Text W05 Medium";
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center;
    color: $slate;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
  }
  &__back-to-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__print-and-share {
    font-family: "Helvetica Now Text W05 Regular";
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    display: flex;
    .social-share__box {
      top: 30px;
      transform: translateX(-50%);
    }

    span {
      margin-right: 30px;
      display: flex;
      align-items: center;
      img {
        height: 16px;
        margin-right: 10px;
      }
    }
  }
  &__go-back {
    display: flex;
    font-family: "Helvetica Now Text W05 Regular";
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.45px;
    color: $color-charcoal;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;

    img {
      height: 20px;
      margin-left: 15px;
    }
  }

  &__no-results {
    font-family: "Helvetica Now Text W05 Regular";
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    p, h1, h2, h3, a {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
    }
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .promo-banner {
    height: 100% !important;
    margin-left: -15px;
    margin-right: -15px;
  
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (min-width: 1921px){
    .kf-react-plp-container {
      max-width: 1820px;
      margin: auto;
    }
  }
  @media only screen and (min-width: 1600px) and (max-width: 1919px){
    .kf-react-plp-container {
      min-width: 1517px;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1599px) {
    .kf-react-plp-container {
      min-width: 1366px;
      .product-listing__row {
        .product-listing {
          &__tile {
            &-col--full-width {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1280px) and (max-width: 1439px){
    .kf-react-plp-container {
      min-width: 1214px;
    }
  }
  @media only screen and (min-width: 480px) {
    .kf-react-plp-container {
      @media (max-width: 1023px) {
        padding: 0px;
        max-width: 92.12%;
        margin-left: 3.94%;
        margin-right: 3.94%;
        .row-cols-2 {
          margin-right: -12px;
          .product-listing__tile {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 12px;
          }
        }
      }
      @media (max-width: 1279px) {
        .product-listing {
          &__tile {
            &.promo-col-1 {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 50%;
              flex: 0 0 50%;
              max-width: 50%;
            }
            &.promo-col-3 {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 100%;
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1024px){
    .kf-react-plp-container {
      padding: 0px;
      @media (max-width: 1920px) {
        max-width: 94.8%;
        margin-left: 2.6%;
        margin-right: 2.6%;
      }
    }
    @media (max-width: 1279px) {
      .kf-react-plp-container {
          min-width: 971px;
      }
      &__filter{
        z-index: 1;
      }
      &__tile-col{
        margin-left: 253px;
      }

      &__tile-col--full-width {
        margin-left: 0px;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    &__sticky-nav {
      padding-top: 13px;
      padding-bottom: 13px;
    }
    &__row.d-block{
      margin-top: 15px;
    }
    &__back-to-top{
      justify-content: flex-end;
    }
  }
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    &__sticky-nav {
      padding-top: 30px;
      padding-bottom: 15px;
    }    
  }
  @media only screen and (max-width: 860px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 991px){
    &__print-and-share {
      display: none;
    }
    &__back-to-top{
      margin-bottom: 80px;
      padding-top: 20px;
    }
    &__tile-col {
      margin: auto;
    }
    &__filter--sticky{
      top: 8px;
    }
    &__total {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -1.5px;
      padding-top: 0;
      bottom: 0;
    }
    &__filter--modal {
      display: block;
    }
    &__container{
      padding: 0 32px;
    }
    &__controls {
      justify-content: space-between;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px){
    &__container.container{
      padding: 0px 32px;
    }
    &__controls {
      .control-right-section {
        margin-left: 150px;
      }
      
      section {
        right: 15px;
        .sort__drop-down {
          right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 991px){
    &__description{
      line-height: 34px;
      font-size: 26px;
      letter-spacing: -1.15px;
      margin-top: 10px;
      margin-bottom: 90px;
    }
    &__filter{
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: 10000000; // To open filter modal on top of chat bubble
      top: 0;
      left: 0;
      background: $color-white;
      display: none;
      padding-left:32px;
      padding-right: 0;
      @media only screen and (max-width:480px) {
        padding-left: 18px;
      }
      &--modal {
        display: block;
        bottom: 0;
        .product-listing__filter-toggle{
          span{
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__curated{
      padding-top: 40px !important;
    }
    &__tiles {
      margin-bottom: 60px;
    }
    &__total {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -1.5px;
      padding-top: 0;
      bottom: 0;
      width: auto !important;
    }
    &__filter--sticky{
      position: relative;
      top: 9px;
    }
    &__container{
      padding-left: 18px;
      padding-right: 18px;
    }
    &__controls {
      .control-right-section {
          margin-left: 50%;
          justify-content: flex-end;
      }
      section {
        right: 15px;
        .sort__drop-down {
          right: 0;
        }
      }
    }
    &__tile-col {
      margin: auto 11px;
    }
  
    &__load-more {
      margin-bottom: 40px;
      padding: 8px 30px;
      font-size: 14px;
    }
    &__tile-col--full-width{
      .product-list-tile__startingat{
        text-align: left;
      }
    }
    &__grid-control {
      &-icon {
        width: 30px;
        height: 30px;
        padding: 6px;
        margin-left: 10px;
      }
    }
    &__tile-col{
      .row-cols-1{
        .product-list-tile {
          &__share{
            opacity: 1;
            top: 15px;
            right: 20px;
          }
          &__share-icon{
            background: transparent;
            margin-left: 20px;
            width: 15px;
            height: 13.3px;
            border: none;
          }
          &__share-icon-plp{
            display: none;
          }
        }
      }
    }
    &__print-and-share {
      display: none;
    }
  }
  @media only screen and (max-width:480px) {
    &__filter-toggle {
      &-icon {
        margin-right: 0px;
        padding: 0;
        width: 21px;
        height: 14px;
      }
    }
    .kf-react-plp-container {
      padding: 0px;
      max-width: 90.4%;
      margin-left: 4.8%;
      margin-right: 4.8%;
      .product-listing {
        &__tile-col {
          &--full-width {
            margin: auto;
          }
          .row-cols-1 {
            margin-right: 0;
            .product-listing__tile {
              padding-right: 0;
              transition: all 0.5s ease-in-out;
            }
          }
          .row-cols-2 {
            margin-right: -7px;
            .product-listing__tile {
              -ms-flex: 0 0 50%;
              flex: 0 0 50%;
              max-width: 50%;
              transition: all 0.5s ease-in;
              padding-right: 7px;
            }
          }
          .row-cols-1, .row-cols-2 {
            .promo-col-3 {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 100%;
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  @media (hover: none) {
    &__load-more {
      &:hover {
        color: #494949 !important;
        text-decoration: none;
      }
    }
  }
  /* Safari only */
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    &__controls {
      justify-content: space-between;
      position: relative;
    }
  }
  @media screen and (min-width: 992px){
    &__print-and-share {
      display: none;
    }
  }
}

@media print and (min-width: 280px) { 
  @page {
    size: A4 portrait;
  }
 
  .breadcrumb {
    display: none;
  }
  .cmp-teaser__content{
    display: none;
  }

  .product-listing {
    &__filter, &__sticky-nav-wrap, &__back-to-top, .button.product-listing__load-more {
      display: none;
    }
    &__tile {
      break-inside: avoid;
      page-break-inside: avoid;
      margin-bottom: auto;
      display: block;
      margin-bottom: 30px;
      max-width: 100% !important;
      padding-right: 0 !important;
      &-info {
        height: auto;
      }
      &-col {
        margin-left: 0px;
        transition: none;
        .product-listing__row {
          .promo-col-1, .promo-col-2, .promo-col-3 {
            display: none;
          }
        }
      }
      &-col > .product-listing__row {
        display: grid;
        margin-right: 0;
        grid-template-columns: 32.6% 32.6% 32.6%;
        column-gap: 16px;
      }
    }
    &__total {
      max-height: 3.5rem;
    }
  }
  .plp-promo-banner{
    &__info-image, &__info-bg{
    display:none;
  }
}
  .kf-promo-banner {
    display: none;
  }
}