.basic-text-wrapper{
    .article-content-font-regular{
        &.article-text-display-style{
            width:33.875rem;
            margin:0 auto;
            padding:70px 0;
            @include breakpoint(tab){
                width: 100%;
                padding: 50px 32px;
                max-width: 100%;
                margin: 0;
            }
            @include breakpoint(mobile){
                width:100%;
                padding:50px 18px;
                max-width: 100%;
                margin: 0;
            }
            .cmp-text{
                h1,h2,h3,h4,h5,h6{
                    @include headingtitle_h3;
                    letter-spacing:-1px;
                    margin-bottom:3.75rem;
                    color: $text-dark-grey;
                    @include breakpoint(tab-max){
                        @include ann-describtion(28px, $playfair-regular, 34px);
                        letter-spacing:-1.17px;
                        margin-bottom:2.5rem;
                    }
                }
                p{
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                    text-align:left;
                    max-width:478px;
                    letter-spacing: normal;
                    color: $text-medium-dark-grey;
                    @include breakpoint(tab-max){
                        @include ann-describtion(14px, $lato-regular, 20px);
                        padding:0;
                        max-width: 100%;
                        margin: 0;
                    }
                    a{
                        color: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
        &.article-text-display-style-two{
            padding:70px 0;
            @include breakpoint(tab){
                width: 100%;
                padding: 50px 32px;
            }
            @include breakpoint(mobile){
                width:100%;
                padding:50px 18px;
                max-width: 100%;
                margin: 0;
            }
            .cmp-text{
                h1, h2, h3, h4, h5, h6{
                    @include headingtitle_h3;
                    letter-spacing:-1px;
                    color: $text-dark-grey;
                    width: 37.5rem;
                    margin: 0 auto 3.75rem;
                    @include breakpoint(tab-max){
                        @include ann-describtion(28px, $playfair-regular, 34px);
                        letter-spacing:-1.17px;
                        margin-bottom:2.5rem;
                        width: 100%;
                    }
                }
                p{
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                    margin:0 auto;
                    text-align:left;
                    margin-bottom:2.25rem;
                    padding:0;
                    max-width:900px;
                    letter-spacing: normal;
                    color: #5d5d5d !important;
                    &:last-child{
                        margin-bottom:0;
                    }
                    @include breakpoint(tab-max){
                        width:100%;
                        @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    }
                    @include breakpoint(tab){
                        margin-bottom:2rem;
                    }
                    @include breakpoint(mobile){
                        margin-bottom:1.5rem;
                    }
                    a{
                        color: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
        &.article-text-display-style-three{
            width: 41.25rem;
            margin:0 auto;
            padding:70px 0;
            @include breakpoint(tab-max){
                width:100%;
                padding:50px 32px;
            }
            @include breakpoint(mobile){
                padding:50px 18px;
            }
            .cmp-text{
                h1,h2,h3,h4,h5,h6{
                    @include headingtitle_h3;
                    letter-spacing:-1px;
                    margin-bottom:3.75rem;
                    color:$text-dark-grey;
                    @include breakpoint(tab-max){
                        @include ann-describtion(28px, $playfair-regular, 34px);
                        letter-spacing:-1.17px;
                        margin-bottom:2.5rem;
                    }
                    @include breakpoint(tab){
                        max-width: 41.25rem;
                        margin: 0 auto 2.5rem;
                    }
                }
                p{
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                    text-align:left;
                    margin-bottom:2.25rem;
                    padding:0;
                    max-width:660px;
                    &:last-child{
                        margin-bottom:0;
                    }
                    @include breakpoint(tab-max){
                        @include ann-describtion(14px, $lato-regular, 20px);
                    }
                    @include breakpoint(tab){
                        margin-bottom:2rem;
                        padding:0;
                    }
                    @include breakpoint(mobile){
                        margin-bottom: 1.125rem;
                    }
                    a{
                        color: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
        &.article-text-display-style-four{
            width: 56.25rem;
            margin:0 auto;
            padding:70px 0;
            @include breakpoint(tab-max){
                width:100%;
                padding:50px 32px;
            }
            @include breakpoint(mobile){
                padding:50px 18px;
            }
            .cmp-text{
                h1,h2,h3,h4,h5,h6{
                    @include ann-describtion(36px, $playfair-regular, 48px);
                    letter-spacing:-1px;
                    margin-bottom: 2.5rem;
                    color:$text-dark-grey;
                    @include breakpoint(tab-max){
                        @include ann-describtion(22px, $playfair-regular, 28px);
                        letter-spacing:-1px;
                        margin-bottom: 1.25rem;
                    }
                    @include breakpoint(tab){
                        max-width: 40.5rem;
                        margin: 0 auto 1.25rem;
                    }
                    @include breakpoint(mobile){
                        max-width: 18.6875rem;
                        margin: 0 auto 1.25rem;
                    }
                }
                p{
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                    text-align:left;
                    margin-bottom:2.25rem;
                    padding:0;
                    max-width: 56.25rem;
                    &:last-child{
                        margin-bottom:0;
                    }
                    @include breakpoint(tab-max){
                        @include ann-describtion(14px, $lato-regular, 20px);
                    }
                    @include breakpoint(tab){
                        margin-bottom:2rem;
                        padding:0;
                    }
                    @include breakpoint(mobile){
                        margin-bottom: 1.125rem;
                    }
                    a{
                        color: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &.bg-warm-gray-light{
        .text .cmp-text{
            ol,ul,dl{
                li a{
                    color: $text-medium-dark-grey;
                }
            }
        }
    }
}
.text{
    .cmp-text{
        ul li,ol li,dl li{
            @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
            text-align:left;
            padding:0 2rem;
            @include breakpoint(tab-max){
                @include ann-describtion(14px, $lato-regular, 20px);
                padding:0;
                max-width: 100%;
                margin: 0;
            }
            a{
                color: inherit;
                text-decoration: underline;
            }
        }
        p{
            padding:0 !important;
        }
        ul,ol,dl{
            padding:0 30px;
            li{
                padding:0;
                margin:0 45px;
                ul,ol,dl{
                    li{
                        margin:0;
                    }
                }
            }
        }
        blockquote{
            margin:16px 40px;
        }
    }
}