.step-nav {
    &__step {
        display: flex;
        padding-top: 30px;
        border-top: 1px solid $color-dark-gray;
        &:last-child {
            border-bottom: 1px solid $color-dark-gray;
        }

        &--active {
            padding-top: 40px;
            .step-nav__number {
                padding-top: 25px;
                color: $color-slate;
            }
            .step-nav__title {
                margin-bottom: 50px;
                h2 {
                    font-size: 60px;
                    color: $color-slate;
                }
            }
            .step-nav__content {
                padding-bottom: 80px;
                max-width: 100%;
            }
        }
        &--done {
            .step-nav__number {
                color: $color-slate;
            }
            .step-nav__title {
                h2 {
                    color: $color-slate;
                }
            }
        }
    }
    &__content {
        flex: 1;
        padding-bottom: 30px;
    }
    &__number {
        width: 20px;
        font-family: $font-light;
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -1.5px;
        color: $color-dark-gray;
        margin-right: 40px;
    }
    &__number--mobile {
        display: none;
    }
    &__title {
        display: flex;
        width: 100%;   
        justify-content: space-between;
        h2 {
            font-family: $font-light;
            font-size: 32px;
            line-height: 1.25;
            letter-spacing: -3.1px;
            color: $color-dark-gray;
        }
    }
    &__next {
        text-align: right;
    }
    &__review {
        margin: 30px 0;
		&-tile {
            section {
                margin-bottom: 5px;
                font-family: $font-medium;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.35px;
                color: $color-dark-gray;
                h2 {
                    &.--unverified {
                        color: $color-error-red;
                    }
                }
            }
        }
        &-tile-parcel {
            section {
                margin-bottom: 5px;
                font-family: $font-medium;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.35px;
                color: $color-dark-gray;
                h2 {
                    &.--unverified {
                        color: $color-error-red;
                    }
                }
            }
        }
        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                font-family: $font-regular;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.55px;
                color: $color-dark-gray;
            }
        }
	}
    &__edit-btn--mobile {
        display: none;
    }
    &__plus {
        display: none;
    }
    &__review-tile-parcel{
        margin-bottom: 30px;
    }

    @media screen and (max-width: 990px) {
        &__number--mobile {
            display: inline-block;
            margin-right: 15px;
        }
        &__title {
            h2 {
                font-size: 26px;
                line-height: 1.23;
                letter-spacing: -1.15px;
            }
        }
        &__content {
            padding-bottom: 25px;
        }
        &__step {
            padding-top: 25px;
            &--active {
                padding-top: 30px;
                .step-nav__title {
                    margin-bottom: 40px;
                    span {
                        font-size: 26px;
                        line-height: 34px;
                        letter-spacing: -1.5px;
                    }
                    h2 {
                        font-size: 32px;
                        line-height: 1.25;
                        letter-spacing: -1.5px;
                    }
                }
                .step-nav__content {
                    padding-bottom: 50px;
                }
            }
        }
        &__edit-btn {
            display: none;
        }
        &__edit-btn--mobile {
            display: inline-flex;
            width: 100%;
            margin-top: 30px;
        }
        &__component {
            height: 0;
            overflow: hidden;
            transition: all 0.5s ease;
            &.--open {
                height: 100%;
                overflow: visible;
            }
        }
        &__plus {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .line {
                background: $color-charcoal;
                transition: all 0.5s ease;
                &.line-1 {
                    width: 100%;
                    height: 1px;
                }
                &.line-2 {
                    width: 1px;
                    height: 100%;
                    transform: translateX(-10px);
                }
            }
            &.--open {
                .line {
                    &.line-2 {
                        height: 0;
                    }
                }
            }
        }
        &__review {
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width:767px){
        .step-nav__edit-btn--mobile{
           margin-bottom: 15px;
        }
        .step-nav {
            &__step {
                &--active {
                    .step-nav__content {
                        padding-bottom: 0px;
                    }
                }
            }
        }
    } 
    
    @media screen and (max-width: 768px) {
        &__review {
            margin-bottom: 0;
        }
        &__review-tile {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__review-tile-parcel{
            margin-bottom: 30px;
        }
      .order-summary-mobile-block{
        overflow: inherit;
    }
    }
}
@media screen and (min-width: 1024px) {
    .step-nav{
        &__step--active{
            .step-nav__title{
                h2{
                    line-height: 68px;
                }
            }
        }
    }
}

