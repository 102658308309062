@import "./bg-theme";
.hero-banner-three-by-four-full-bleed{
    .cmp-teaser{
        display: block;
        position: relative;
        @include breakpoint(tab-max){
            background: $charcoal;
        }
        &__content{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            max-width:33.75rem;
            padding: 0 2.6%;
            background: transparent;
            box-sizing: content-box;
            color: $white;
            flex-direction: column;
            text-align: left;
            z-index: 2;
            @include breakpoint(desktop-large){
                max-width: 1920px;
                padding: 0 50px;
                margin-left: -960px;
                left: 50%;
            }
            @include breakpoint(tab){
                padding: 0 32px;
                width: 100%;
                top: 3.125rem;
                box-sizing: content-box;
                max-width:206px;
            }
            @include breakpoint(mobile){
                padding: 0 18px;
                top: 3.125rem;
                max-width:193px;
            }
        }
        &__title{
            padding: 0;
            margin: 0;
            max-width: 540px;
            @include breakpoint(tab){
                max-width: 144px;
            }
            @include breakpoint(mobile){
                max-width: 270px;
                z-index: 999;
            }
            h1, h2, h3, h4, h5, h6{
                @include headingtitle_h3;
                margin-bottom: 30px;
                margin-top: 0;
                @include breakpoint(tab-max){
                    letter-spacing: -0.073125rem;
                    margin-bottom: 1.25rem;
                    color: $text-dark-grey;
                }
            }
        }
        &__description{
            padding: 30px 0 20px;
            max-width: 540px;
            @include breakpoint(tab){
                padding: 30px 0 3px;
                max-width: 100%;
            }
            @include breakpoint(mobile){
                padding: 30px 0 15px;
                max-width: 100%;
            }
            p{
                @include text-style(1.25rem, 1.75rem, -0.5px);
                font-family: $helvetica-regular;
                margin: 0;
                @include breakpoint(tab-max){
                    @include text-style(16px,24px,-0.5px);
                    color: $very-light-grey;
                }
            }
        }
        &__image{
            @include breakpoint(desktop-small){
                max-height: 767px;
            }
            @include breakpoint(tab){
                max-height: 464px;
            }
            @include breakpoint(mobile){
                min-height: 540px;
            }
            img{
                max-height: inherit;
                @include breakpoint(mobile){
                    min-height:inherit;
                }           
            }
        }
        &__action-container{
            display: block;
            width: 100%;
            @include breakpoint(tab){
                padding-bottom: 50px;
            }
            @include breakpoint(mobile){
                display: flex;
                width: auto;
                flex-direction: column;
                align-items: baseline;
            }
        }
        &__action-link{
            @include ann-describtion(11px, $lato-regular, 12px);
            letter-spacing: 1.83px;
            padding: 0 0 .5rem;
            background: transparent;
            text-transform: uppercase;
            border-bottom: 1px solid;
            color: $white;
            box-shadow: none;
            border-radius: 0;
            &:first-child{
                margin-right: 1.25rem;
                @include breakpoint(mobile){
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
            &:last-child{
                margin: 0;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
    &.fc-black{
        @include breakpoint(tab-max){
            @include text-theme($white);
        }
        .cmp-teaser{
            &__content{
                @include breakpoint(tab-max){
                    color: $black;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    @include breakpoint(tab-max){
                        color: $black;
                    }
                }
            }
            &__description{
                p{
                    @include breakpoint(tab-max){
                        color: $very-light-grey;
                    }
                }
            }
        }
    }
    &.fc-white{
        @include breakpoint(tab-max){
            @include text-theme($white);
        }
        .cmp-teaser{
            &__content{
                @include breakpoint(tab-max){
                    color: $white;
                }
            }
            &__description{
                p{
                    @include breakpoint(tab-max){
                        color: $very-light-grey;
                    }
                }
            }
        }
    }
    &.content-top-left{
        .cmp-teaser{
            &__content{
                justify-content: flex-start;
                @include breakpoint(desktop-small){
                    top: 100px;
                }
            }
            &__title{
                @include breakpoint(tab-max){
                    top: 100px;
                }
            }
        }
    }
    &.content-center{
        .cmp-teaser{
            &__content{
                text-align: center;
                width:33.75rem;
                height: 100%;
                padding: 0;
                align-items: center;
                max-width:100%;
                margin:0 auto;
                left:0;
                @include breakpoint(tab-max){
                    top: 0;
                }
            }
            &__title{
                text-align: center;
                margin: 0 auto;
            }
            &__description{
                @include breakpoint(desktop-small){
                    margin: 0 auto;
                }
            }
        }
    }
    &.content-bottom-left{
        .cmp-teaser{
            &__content{
                justify-content: flex-end;
                bottom: 0;
                @include breakpoint(desktop-small){
                    padding-bottom: 105px;
                }
                @include breakpoint(mobile){
                    bottom: 50px;
                    top: unset;
                }
            }
            &__title{
                @include breakpoint(tab-max){
                    top: unset;
                    bottom: 50px;
                }
            }
        }
    }
    &.fc-light-gray{
        .cmp-teaser{
            &__description{
                p{
                    color: $very-light-grey;
                }
            }
        }
    }
}

.searchbar-open{
    &.modal-open{
        .hero-banner-three-by-four-full-bleed{
            .cmp-teaser{
                &__image{
                    &::after{
                        z-index: auto;
                    }
                }
                &__content{
                    z-index: auto;
                }
            }
        }
    }
}
.gbh-of-scroll{
    .hero-banner-three-by-four-full-bleed{
        .cmp-teaser{
            &__image{
                &::after{
                    z-index: auto;
                }
            }
            &__content{
                z-index: auto;
            }
        }
    }
}