#literatureWrapperId {
    .literature-tile {
        position: relative;
        max-width: 457px;

        &__widgets {
            display: flex;
            position: absolute;
            top: 10px;
            right: 10px;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            gap: 5px;
        }

        &__share-icon {
            width: 36px;
            height: 36px;
            background: #fff;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &:hover {
            .literature-tile__widgets {
                opacity: 1;
            }
        }

        &__title {
            margin-top: 20px;
            margin-bottom: 10px;
            color: $slate;
            font-family: 'Helvetica Now Text W05 Light';
            font-size: 26px;
            font-weight: 300;
            letter-spacing: -1.5px;
            line-height: 34px;
        }

        &__image {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;

            @media print {
                page-break-inside: avoid;
            }
        }

        &__download-cta {
            display: flex;
            gap: 10px;
            align-items: center;
            color: $color-dark-gray;
            font-family: 'Helvetica Now Text W05 Regular';
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;

            img {
                width: 18px;
                height: 20px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .sort {
        &__toggle {
            line-height: 30px;
        }
    }

    .literature-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 70px 5px;
        margin-bottom: 70px;

        &__one-col {
            grid-template-columns: 1fr;
            gap: 40px 0px;

            .literature-tile__title {
                margin-bottom: 15px;
            }
        }

        &__two-col {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 5px;

            .literature-tile__title {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        &__grid-control {
            justify-content: flex-end !important;
            margin-top: -45px;
        }
    }

    .product-list__tiles-literature {
        .filter__print-and-share {
            margin-top: 15px;
        }
    }

    .literature {
        .product-list__filter {
            z-index: 1;
        }

        .product-list__tile-col.product-list__tile-col--full-width.literature-list__tile-col {
            margin: 0;
            position: relative;
            z-index: 3;
            background: $color-white;
        }

        .product-list__sticky-nav-wrap {
            z-index: 4;
            padding-bottom: 40px;
            &.product-list__sticky-nav-container {
                z-index: 9 !important;
            }
            &.false {
                z-index: 1 !important;
            }
        }

        .product-list__controls {
            height: auto;
        }

        .product-list__controls section {
            min-height: auto;
        }

        .product-list__sticky-nav-container {
            .product-list__controls {
                height: 65px;
            }

            .product-list__controls section {
                min-height: 65px;
            }
        }
    }

    main.literature {
        padding-bottom: 120px;

        @include breakpoint(desktop-xxxlg) {
            padding: 0px 50px 120px;
        }

        .kf-page-title__total {
            margin-top: 60px;
        }

        @media only screen and (max-width: 860px) {
            padding-bottom: 0px;

            .kf-page-title__total {
                margin-top: 40px;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .literature {
            .product-list__controls {
                margin-top: 15px;
            }

            .product-list__sticky-nav-container {
                .product-list__controls {
                    margin-top: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 860px) and (orientation: landscape),
    only screen and (min-width: 768px) and (max-width: 991px) {
        .literature-tile {
            &__title {
                color: $slate;
                font-family: 'Helvetica Now Text W05 Regular';
                font-size: 16px;
                letter-spacing: -0.55px;
                line-height: 24px;
            }
        }

        .product-list__tile-col.literature-list__tile-col {
            margin: 0;
        }

        .literature {
            .product-list__filter {
                z-index: 25;

                .filter__inner-container {
                    .filter__mobile-header {
                        display: flex;
                        height: 30px;
                        margin: 9px 0 40px;
                        justify-content: space-between;
                    }
                }
            }

            .kf-page-title__total {
                margin-bottom: 21px;
            }

            .product-list__grid-control {
                padding-bottom: 0;
            }

            .literature-list__grid-control {
                margin-top: 0;
            }
        }
    }

    @media screen and (min-width: 767px) {
        .product-list__tile-col.product-list__tile-col--full-width.literature-list__tile-col {
            margin: 0;
        }
    }

    @media screen and (max-width: 767px) {
        .literature {
            .kf-page-title__total {
                margin-top: 80px;
                height: auto;
                margin-bottom: 18px;
            }

            .product-list__grid-control {
                padding-bottom: 18px;
            }
        }

        .literature-tile {
            &__title {
                font-size: 16px;
                letter-spacing: -0.55px;
                line-height: 24px;
                font-family: 'Helvetica Now Text W05 Regular'
            }
        }

        .product-list__tile-col.literature-list__tile-col {
            margin: 0;
        }

        .literature {
            .product-list__filter {
                z-index: 25;
            }
        }
    }

    .kf-react-container {
        max-width: 1444px !important;
    }


    @media print and (min-width: 280px) {
        @page {
            size: A4;
        }

        .product-list__tile-col {
            margin-left: 0px !important;
            transition: none !important;
        }

        .breadcrumb,
        .kf-page-title__total,
        .cmp-teaser__content,
        .cmp-teaser__image {
            display: none;
        }

        .literature-list {
            page-break-inside: avoid;
            break-before: avoid-page;
            break-after: avoid-page;
            break-inside: avoid-page;

            &:first-child {
                margin-top: 50px;
            }

            .literature-list-wrapper {
                break-before: avoid-page;
                break-after: avoid-page;
                break-inside: avoid-page;
                float: left;
            }

        }

        .literature-tile {
            max-width: 100%;
            page-break-inside: avoid;
            break-before: avoid-page;
            break-after: avoid-page;
            break-inside: avoid-page;

            img {
                display: block;
                page-break-inside: avoid;
                break-before: avoid-page;
                break-after: avoid-page;
                break-inside: avoid-page;
            }
        }
   }

    //gbh code below
.literature {
    background-color: $extra-light-white;
    @include breakpoint(tab) {
        background-color: $extra-light-white;
    }
    @include breakpoint(mobile) {
        background-color: $extra-light-white;
    }
    .filter{
        padding: 0 !important;
    }

    .filter__print-and-share span {
        flex-direction: row-reverse;
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-right: 30px;
    }

    .filter__print-and-share span img {
        height: 1pc;
        margin-right: 10px;
        margin-left: 0;
        order: 1;
    }

    .product-list__sticky-nav {
        padding-left: 0px;
        padding-right: 0px;
        background-color: $extra-light-white;
    }

    .product-list__sticky-nav-wrap {
        z-index: 9;
        position: sticky;
        padding-bottom: 20px;

        .product-list__controls{
            @include breakpoint(tab){
                padding: 5px 0;
                position: absolute;
                top: 0;
                max-width: 400px;
                right: 0;
                margin-top: -65px;
            }
            .product-list__filter-toggle{
                .product-list__filter-toggle-icon{
                    @include breakpoint(tab){
                        color: $slate;                      
                    }
                    @include breakpoint(mobile){
                        color: $slate;
                        margin:8px 10px 6.81px 0px
                    }
                    
                }
            div.span{
                @include breakpoint(tab-max){
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .product-list{
                &__filter-toggle-text{
                    @include breakpoint(tab){
                        font-size: 16px;
                        line-height: 20px;
                    }
                    @include breakpoint(mobile){
                        font-size: 14px;
                        line-height: 20px;
                        display: none;
                    }
                    @include breakpoint (desktop-small) {
                        font-size: 16px;
                        line-height: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .sort{
            @include breakpoint(mobile){
                margin-left: 16px;
                display: none;
            }
            .sort__toggle{
                @include breakpoint(tab){
                    font-size: 16px;
                    line-height: 24px;
                }
                @include breakpoint(mobile){
                    font-size: 14px;
                    line-height: 20px;
                }
                .sort__toggle-icon{
                    @include breakpoint(tab){
                        margin-left: 9.5px;
                        height: 10px;
                    }
                    @include breakpoint(mobile){
                        margin-left: 9.5px;
                        height: 10px;
                    }
                }
            }
        }
        }
    }

    .product-list__filter {
        width: 240px;
    }

    .product-list__tile-col {
        margin-left: 21.74%;
    }

    .product-list__tile-col--full-width {
        background: none !important;

        .literature-tile__image {
            height: 574px;
        }
        
    }

    .literature-list.literature-list__two-col{
        @include breakpoint(mobile){
            .literature-tile{
                width: auto;
                .literature-tile__image{
                    height: 124px;
                    min-width: unset;
                }
            }
        }
    }
   
    .sort__item,
    .product-list__go-back {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        @include breakpoint(tab) 
        { 
            font-size: 16px;
            line-height: 24px;
            color: $text-dark-grey;
        }
        @include breakpoint(mobile) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .sort__toggle,  .product-list__filter-toggle{
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 24px;
        @include breakpoint(tab) { 
            font-size: 14px;
            line-height: 20px;
            color: $text-dark-grey;
        }
        @include breakpoint(mobile) {
            font-size: 14px;
            line-height: 20px;
            position: absolute;
            top: 0;
            margin-top: -75px;
            right: 31px;
        }
    }
    .product-list__filter-toggle.gbh-data-layer{
        .product-list__filter-toggle-icon{
            height: 13.19px;
            color: $text-medium-dark-grey;
            margin-left: 5px;
            order: 2;
            @include breakpoint(tab-max){
                display:block !important;
            }
            @include breakpoint(mobile) { 
                font-size: 14px;
                color: $slate;
            }
            @include breakpoint(tab) {
                width: 21px;
                height: 14px;
                color: $text-medium-dark-grey;
            } 
        }
        span{
            @include breakpoint(tab-max) { 
                font-size: 16px;
                line-height:24px;
                letter-spacing:normal;
                color: $slate;
            }
            @include breakpoint(mobile){
                display: none;
            }
        }
    }

    .filter .Collapsible__trigger {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
        text-transform: uppercase;
        @include breakpoint(desktop-small){
            padding: 20.61px 0 20.61px 1px;
            padding: 0;
            margin-top: 15px;
            margin-bottom: 14px;
        }
        @include breakpoint (desktop-large) {
            padding: 20px 0px;
            margin-top: unset;
            margin-bottom: 20px;
        }
        div{
            align-items: center;
            span{
                @include breakpoint(tab){
                    font-size: 12px;
                }
            }
        }
    }

    .filter .Collapsible__trigger div .plus {
        margin-right: 10px;
        color: $text-medium-dark-grey;
        width: 10.72px;
        height: 10.72px;
        @include breakpoint(desktop-small){
            margin-right: 20.76px;
        }
        .line {
            margin-left: -2px;
            @include breakpoint(desktop-small){
                margin-left: -4px;
            }
        }
    }

    .filter .Collapsible__trigger div .plus .line.line-2 {
        transform: translate(-3.5px, 0.5px);
        @include breakpoint (desktop-large) {
            transform: translateX(-4.4px);
          }
    }

    .kf-page-title {
        flex-direction: column;
        justify-content: center;
        gap: 25px;
        margin-bottom: 50px;
        border-bottom: 1px solid $cool-dark-grey;

    }

    .kf-page-title__title {
        color: $text-dark-grey;
        font-family: $playfair-regular;
        font-size: 54px;
        letter-spacing: -1px;
        line-height: 62px;
        text-align: center;
        flex: 0;
        padding-bottom: 0px;
    }

    .kf-page-title__desc {
        width: 348px;
        margin: 0 auto;
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        padding-bottom: 91.5px;
    }

    .kf-page-title__separator {
        display: none;
    }

    .kf-page-title__total {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        margin: 0;
        padding-bottom: 17px;
        padding-top: 34.5px !important;
    }

    .kf-react-container {
      @media screen and (min-width: 1440px) and (max-width: 1599px){
        width: 1380px;
        padding: 0px !important;
      }
      @media screen and (min-width: 1600px){
        margin: auto !important;
        max-width: 1600px !important;
        padding: 0px !important;
        width: calc(100vw - 13.75%);
        .product-list__controls{
          width: calc(100vw - 13.75%);
          margin: auto !important;
        }
      }
      @media screen and (min-width: 1920px){
        max-width: 1920px !important;
        margin: auto !important;
        width: calc(100vw - 5.75%);
        padding: 0px !important;
        .product-list__controls{
          max-width: 1920px !important;
          margin: auto !important;
          width: calc(100vw - 5.75%);
        }

      }
    }

    .sticky {
        width: 100% !important;
        left: 0;
    }

    .products-tab {
      @media screen and (min-width: 1440px) and (max-width: 1599px){
        max-width: 1380px;
        margin: 0 auto;
      }
    }

    .product-list__filter--sticky {
        margin-top: 0;
    }

    .product-list__controls {
        max-width: 1380px;
        margin: 0 auto;
        justify-content: flex-end;

        section {
            position: relative;
        }
    }


    .literature-tile {
        &__widgets {
            display: none;
        }

        &__title {
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 26px;
            margin-top: 16.73px;
            margin-bottom: 12.55px;
            cursor: pointer;
            @include breakpoint(desktop-small){
                margin-top: 20px;
            }
        }

        &__image {
            height: 465px;
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            cursor: pointer;
        }

        &__download-cta {
            display: flex;
            gap: 10px;
            align-items: center;
            color: $text-medium-dark-grey;
            font-family: $lato-regular;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;

            img {
                width: 16px;
                height: 16.76px;
            }

            &:hover {
                text-decoration: none;
            }

        }
    }

    .literature-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 70px 30px;
        margin-bottom: 50px;
        @include breakpoint(mobile){
            grid-template-columns: repeat(2, 1fr);
        }
    }
    
    .literature-list.literature-list {
        &__two-col {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 18px;
            margin-bottom: 40px;
            @include breakpoint(tab-max){
                gap: 65px 18px;
            }
            .literature-tile__title {
                font-size: 16px;
                line-height: 22px;
                margin-top: 10px;
                margin-bottom: 5px;
            }
        }
        &__one-col{
            margin-bottom: 40px;
            @include breakpoint(mobile){
                .literature-tile__title {
                    margin-top: 15px;
                    margin-bottom: 10px;
                }
            }
        }

    }
    .literature-tile {
        @include breakpoint(mobile){
            max-width: 100%;
        }
        .literature-landing-tile__share{
            display: flex;
            position: absolute;
            z-index: 1;
            right: 25px;
            top: 15px;
            .literature-landing-tile__share-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                background: #fff;
                border-radius: 5px;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
    .sort__toggle-icon{
        width: 12.15px;
        height: 6.97px;
        color: $text-medium-dark-grey;
    }
    .product-list{
        &__load-more.gbh-data-layer {
            color: $color-medium-blue-cap;
            font-family: $lato-bold;
            font-size: 11px;
            letter-spacing: 2.5px;
            line-height: 10px;
            text-transform: uppercase;
            padding: 15px 0px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 1px solid $color-medium-blue;
            border-radius: 1px;
            margin-left: unset;
            margin-right: unset;
            margin-bottom: 30px;


        }
    }
    .filter .collapsible-outer{
        &:first-child{
            .Collapsible{
                border-top: 1px solid $filter-border-line;
            }
        }
        .Collapsible{
            border-color: $filter-border-line;
        }
    }
    .product-list__go-back.gbh-data-layer{
        flex-direction: row-reverse;
        gap: 25px;
        color: $text-medium-dark-grey;
        margin-left: auto !important;
        @include breakpoint(mobile) {
            gap: 14px;
        }
    }
    .product-list__filter{
        &.product-list__filter--modal{
            z-index: 1000;
            @include breakpoint(desktop-small){
                width: 17.318%;
                padding: 0px;
            }
            .filter{                
                @include breakpoint(desktop-small){
                    padding: 0;
                }
            }
        }
    }
    .product-list__filter-toggle{
        @include breakpoint(tab){
            font-size: 16px;
            line-height: 24px;
        }
    }
}
.literature-tile {
    @include breakpoint(desktop-large){
      max-width: inherit!important;
  }
}
.literature{
    .literature-list__tile-col{
        .literature-tile{    
            &__image-landscape {
                width: 290px;
                height: 208px;
                aspect-ratio: 1/1;
                object-fit: cover;
                cursor: pointer;
                @include breakpoint(desktop-large){
                    width: 498px;
                    height: 373px;  
                }
                @include breakpoint(desktop-extra-lite-lg){
                    width: 364px;
                    height: 273px;  
                }
                @include breakpoint(mobile){
                    width: 166px;
                    height: 125px; 
                }
            }
        }
        
    }
    .product-list__tile-col--full-width .literature-tile{
        &__image-landscape {
            width: 364px;
            height: 273px;
            aspect-ratio: 1/1;
            object-fit: cover;
            cursor: pointer;
            @include breakpoint(desktop-large){
                width: 586px;
                height: 440px; 
            }
            @include breakpoint(desktop-extra-lite-lg){
                width: 476px;
                height: 357px;  
            }
            @include breakpoint(mobile){
                width: 166px;
                height: 125px; 
            }
        }

    }
    .literature-list__one-col{
        .literature-tile{ 
        &__image-landscape {
            @include breakpoint(mobile){
                width: 339px;
                height: 254px; 
            }
        }
    }
    }
}
.literature{
    .product-list__tiles-literature-landscape{
        @include breakpoint(desktop-extra-lite-lg){
           padding: 0px 26px !important;
        }
        .col{
            @include breakpoint(desktop-extra-lite-lg){
                padding-left: 10px;
                padding-right: 10px;
             } 
             @include breakpoint(mobile){
                padding-left: 18px;
                padding-right: 18px;
             }
        }
        .product-list__filter--modal{
            @include breakpoint(desktop-large){
                width: 11.8%;
            }
        }
        .product-list__tile-col {
            @include breakpoint(desktop-large){
                margin-left: 14.8%;
            }
            
        }
        .literature-list {
            @include breakpoint(desktop-large){
                gap: 75px 30px;    
            }
        }
        .filter .Collapsible__trigger {
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 14px;
            font-style: normal;
            line-height: 16px;
            text-transform: uppercase;
        }
    }
}
@include breakpoint(desktop-extra-sm ){
  .product-list__controls section {
    right: 0;
  }

}
@include breakpoint(tab) {

    .literature {
        main.products-tab{
            padding-top: 0px;
        }
        .kf-page-title__total {
            border-bottom: 1px solid $cool-dark-grey;
            padding-left: 0px;
            @include breakpoint(tab-max){
                border: none;
            }
        }

        .product-list__grid-control {
            margin-top: -80px;
            border-bottom: none;
            padding-bottom: 10px;
        }

        .kf-page-title {
            border-bottom: none;
        }

        .kf-page-title__title {
            font-size: 42px;
            line-height: 48px;
            letter-spacing: -2.8px;
            padding-top: 93px;
        }

        .kf-page-title__desc {
            width: 379px;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.2px;
            padding-bottom: 91.1px;
        }

        .product-list__tile-col {
            margin-left: 0;
            .product-list__load-more{
                @include breakpoint(desktop-small){
                    margin: 0 0 33px;
                }
            }
        }
        .product-list__tile-col--full-width{
            .literature-list{
                margin-bottom: 40px;
            }
        }

        .kf-react-container,
        .product-list__controls,
        .products-tab {
            width: 100%;
        }
        .product-list__controls section {
            right: 0;
        }
        .kf-react-container {
            padding: 0 30px;
        }

        .sticky {
            top: 0 !important;
        }

        .literature-list {
            grid-template-columns: repeat(2, 1fr);
            gap: 65px 2.84%;
            margin-top: 0;
        }

        .products-tab {
            padding: 30px 0px 0px;
        }

        .product-list__filter--modal {
            width: 100%;
        }
        .product-list__filter--sticky {
            margin: 0 14px;
            @include breakpoint(tab){
                margin: 0;
            }
        }



        .filter .Collapsible__trigger {
            margin: 14.5px 0;
        }

        .filter{
            &__inner-container{
                .product-list{
                    &__controls.filter__mobile-header{
                        margin-top: 18px;
                        margin-bottom: 28px;
                    }
                    @include breakpoint(tab) {
                        margin: 18px 0 28px;
                    }
                }
                .filter__chips{
                    .filter__chips-tag{
                        margin: -8px 0 0 0 ;
                       @include breakpoint(tab) {
                        margin-right: 10px;
                       }
                        font-family: $lato-regular;
                        span{
                            @include breakpoint(tab){
                                font-size: 11px;
                            }
                        }
                       
                    } 
                    &.span  {
                        @include breakpoint (desktop-small) {
                            font-size: 11px;
                            line-height: 16px;
                        }
                    }
                    .filter__chips-clear-all.gbh-data-layer{
                        margin-top: 20px;
                        margin-bottom: 25px;
                        padding-top: 0 !important;
                        font-size: 14px !important;
                        color: #5D5D5D !important;
                        @include breakpoint(tab-max){
                            margin-top: 20px !important;
                            font-size: 13px !important;
                        }
                    }
                    div.filter__chips-clear-all.gbh-data-layer{
                        margin-top: 30px;
                        margin-bottom: 25px;
                        padding-top: 0;
                        font-size: 13px ;
                        color: $text-medium-dark-grey ;
                    }
                }
            }
        }

        .product-list__tile-col--full-width .literature-tile__image {
            height: 388px;
            min-width: 340px;
            @include breakpoint(mobile){
                min-width: 339px;
                height: 254px;
            }
        }

        div.product-list__sticky-nav-container .product-list__controls {
            margin-top: 0;
            padding-left: 31.5px;
            padding-right: 32px;
            padding-top: 45px ;
            padding-bottom: 36px;
            @include breakpoint(tab) {
                padding: 45px 0 36px 0;
                background-color: $white;
                max-width: 100%;
            }
            @include breakpoint(mobile) {
                max-height: 50px;
            }
        }

        .product-list__sticky-nav-wrap {
            padding-bottom: 30px;
            padding-top: 30px;
        }
        .product-list{
            &__load-more.gbh-data-layer {
                padding: 0;
                margin: 0;
                height: 40px;
                line-height: 24px;
                font-size: 12px;
                @include breakpoint(tab){
                    font-size: 11px;
                    line-height: 10px;
                    margin-bottom: 30px;
                    border: 1px solid $color-medium-blue;
                    border-radius: 1px;
                }
            }

            &__back-to-top{
                padding-top: 15px;
                margin-bottom: 50px;
                @include breakpoint(tab-max){
                    padding-top: 0px;
                    margin-bottom: 50px;
                }
            }
        }
    }
}

@include breakpoint(mobile) {
    .literature {
        .kf-page-title__total {
            border-bottom: none;
        }

        .products-tab {
            padding: 0px;
        }

        .product-list__grid-control {
            margin-top: -75px;
            border-bottom: none;
            padding-bottom: 10px;
            .product-list__transparent-button{
                &:first-child{
                    display: none;
                }
            }
        }
        .product-list__go-back {
            font-size: 13px;
        }

        .kf-page-title {
            border-bottom: none;
            font-size: 42px;
            line-height: 20px;
            letter-spacing: -2.8px;
        }

        .kf-page-title__desc {
            width: 325px;
            flex: 0;
            font-size: 13px;
            letter-spacing: 0.2px;
            line-height: 20px;
            padding-bottom: 60px;
        }

        .product-list__controls section {
            right: 0;
        }

        .kf-react-container,
        .product-list__controls,
        .products-tab {
            width: 100%;
        }

        .kf-react-container {
            padding: 0 20px;
        }

        .product-list__tile-col {
            margin: 0;
            background: none;
        }

        .kf-react-container {
            padding: 0 30px;
        }

        .sticky {
            top: 0 !important;
        }

        .literature-list.literature-list__one-col {
            grid-template-columns: repeat(1, 1fr);
            gap: 65px 18px;
        }

        .product-list__filter {
            width: 100%;
        }

        .product-list__filter--modal {
            .filter__mobile-header {
                display: flex;
                margin-top: 53px;
                margin-bottom: 28px;
                margin-right: 281px;
                justify-content:space-between;
            }
        }

        .filter .Collapsible__trigger {
            margin-top: 23.01px;
            margin-bottom: 23.61px;
            @include breakpoint (mobile) {
                margin-top: 14.5px;
                margin-bottom: 14.5px;
            }
        }
        .filter{
            &__chips{
                .filter__chips-tag{
                    margin: 12px 2px 20px 0;
                    &.span {
                        @include breakpoint (desktop-small) {
                            font-size: 11px;
                            line-height: 16px;
                        }
                       
                    }
                }
                
            }
        }

        .product-list__tile-col--full-width {
            background: none !important;
        }

        .product-list__tile-col--full-width .literature-tile__image {
            height: 420px;
            min-width: 340px;
        }

        div.product-list__sticky-nav-container .product-list__controls {
            margin-top: 0;
            @include breakpoint(tab-max) {
                height: 50px;
                background-color: $white;
                position: absolute;
                content: "";
                left: 0;
                padding: 0 18px;
                top: -1px;
            }
            @include breakpoint(tab) {
                padding-top: 30px;
                padding-bottom: 21px;
                height: 81px;
            }
        }

        .product-list__sticky-nav-wrap {
            padding-bottom: 30px;
            padding-top: 30px;
        }
        .filter__chips{
            .filter__chips-clear-all.gbh-data-layer{
                padding-top: 0 !important;
                margin-top: 0;
                font-size: 13px ;
                color: $text-medium-dark-grey ;
            }
        }
    }
    .product-list{
        &__load-more.gbh-data-layer {
            margin-bottom: 0;
            font-size: 11px;
            line-height: 10px;
            width: 340px;
            height: 40px;
            margin-top: 20px;
        }
        &__back-to-top{
            padding-top: 15px;
            margin-bottom: 60px;
        }
    }


}

div.filter {
    .filter__chips {
        .filter__chips-clear-all {
            margin-top: 0;
            padding-top: 20px;
            font-size: 14px ;
            line-height: 20px ;
            letter-spacing: normal ;
            color: $text-medium-dark-grey ;
            @include breakpoint (desktop-small) {
                font-size: 13px;
                font-family: $lato-regular;
            }
        }
    }
}
.basicpage.gbh-of-scroll{
    .literature{
        .products-tab{
            z-index: 0;
            position: relative;
        }
    }
}
.literature{
  .products-tab{
      @media screen and (min-width: 1024px){
        .product-list__filter.product-list__filter--modal{
          z-index: 0;
        }
      }
  }
}


  @media print and (min-width: 280px){
    @page {
        margin-top: 100px;
    }

    .literature{
        .literature-list{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 0;
            gap:50px 30px;
        }
        .product-list__load-more{
            display: none!important;
        }
    }
    .product-list__tile-col {
        min-height:auto;
        }
    main.literature {
        padding-bottom: 0;    
    }

    .breadcrumb, .kf-page-title__total, 
    .image, .container.responsivegrid.ann-sacks-logobar,
     .carousel-slick.carousel,
    .teaser_v2.teaser.curated-hero-banner.content-center,
    .cmp-promo__description, .carousel-slick.carousel.single-carousel.pb-sitewide-messaging {    
        display: none;
    }
    .article-center-container .cmp-container{
        display:grid;
        grid-template-columns:33% 33% 33%;
        grid-gap: 20px;
    }
    .text{
        grid-column-start: 1;
        grid-column-end: 12;
        grid-row-start: 1;
        grid-row-end: 3;
    }
}
.searchbar-open {
    .literature {
        display: none;
    } 
}  
div.product-list {
    &__load-more.gbh-data-layer { 
        height: 40px;
    }
}

div.filter .Collapsible {
    color: $cool-dark-grey;
}

.literature {
    .checkbox__border, .checkbox__marker {
        width: 16px;
        height: 16px;
    }
    div.checkbox .checkbox_default{
        color: $text-dark-grey;
        font-size: 12px;
        @include breakpoint(tab-max){
            font-size: 12px !important;
            letter-spacing: 0.23px !important;
        }
        @include breakpoint(desktop-lg){
            font-size: 12px;
            line-height: 15px;
            height: 15px;
        }
    }
    div.checkbox input:checked~span{
        color: $text-dark-grey;
        font-size: 12px;
        font-family: $lato-regular;
      
    }
} 

main.literature .kf-page-title__total {
    margin-top: 0;
}
.kf-page-title__total {
    height: 74.5px;
}

.product-listing

.literature {
    .filter {
        .Collapsible__trigger {
            font-size: 14px;
            line-height: 15px;
            letter-spacing: .23px;
            width: 10.72px;
            height: 10.72px;
            @include breakpoint(tab){
                font-size: 16px;
            }
            @include breakpoint(desktop-small) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
.literature {
    .checkbox {
        input:checked~span {
            color: $text-dark-grey;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: .23px;
            font-family: $lato-regular;
        }
        .checkbox_default {
            color: $text-medium-dark-grey;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: .23px;
            font-family: $lato-regular;
            @include breakpoint(desktop-lg){
                font-size: 12px;
                line-height: 15px;
                height: 15px;
            }
            @include breakpoint(desktop-xxlg){
                font-size: 12px;
                line-height: 15px;
                height: 15px;
            }
        }
    }
    .filter {
        .Collapsible__trigger {
            color: $text-dark-grey;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 2px;
            width: unset;
            height: unset;
            @include breakpoint (tab) {
                margin-top: 14.5px;
                margin-bottom: 14.5px;
                padding: 0px;
            }
            @include breakpoint (mobile) {
                margin-top: 14.5px;
                margin-bottom: 14.5px;
                padding: 0px;
            }
            @include breakpoint (desktop-small) {
                font-size: 12px;
                line-height: 16px;
                height: 16px;
            }
            .div {
                .plus {
                    margin-right: unset;
                    position: absolute;
                    right: 0;
                    width: 15.6px;
                    height: 15.6px;
                    @include breakpoint (desktop-large) {
                        width: 10px;
                        height: 10px;
                    }
                    .line {
                        margin-left: -5px;
                    }
                }
            }
        }
    }
}
.literature  {
    .filter {
        .Collapsible__trigger  {
            div {
                .plus {
                    margin-right: unset;
                    width: 15.15px;
                    height: 15.15px;
                    .line{
                        margin-left: -5px;
                        @include breakpoint(tab-max){
                            margin-left: -2px;
                        }
                        &.line-2 {
                            transform: translate(-6.5px,0.5px);
                            @include breakpoint(desktop-small) {
                                transform: translate(-3.4px,0.5px);
                            }
                        }
                    }
                }
            }
        }
    }
}

.literature {
    .filter__chips-tag{
        font-size: 14px;
        color: $text-dark-grey;
        @include breakpoint(tab){
            font-size: 11px !important;
        }
        &.span {
            @include breakpoint (desktop-small) {
                font-size: 11px;
                line-height: 16px;
                height: 16px;
            }
        } 
    }   
    .sort-by-text{
        margin-right: 3px;
    } 
    .product-listing{
        &.filter{
            .os-scrollbar{
                &.os-scrollbar-vertical{
                    width: 11px;
                    display: none;
                    .os-scrollbar-track{
                        background-color: transparent;
                        left: 11px;
                        
                    }
                }
            }
            .os-padding{
                .os-content{
                    .filter__inner-container{
                        overflow-y: auto;
                        height: calc(100vh - 100px);
                        scrollbar-width: thin;
                    
                    }
                }
            }
        }
    } 
    .filter{
    &__mobile-sort{
        display: none;
    }
    }
}

@include breakpoint (desktop-large) {
    .literature {
        div.checkbox input:checked~span{
            color: $text-dark-grey ;
            font-size: 14px;
            font-family: $lato-regular;
        }
        div.filter .Collapsible {
            color: $cool-dark-grey ;
        }
        .checkbox .checkbox_default {
            color: $text-medium-dark-grey;
            
        }
        div.filter {
            .filter__chips {
                .filter__chips-clear-all {
                    font-size: 14px;
                    color: $text-medium-dark-grey;
                }
            }
        }
        div.filter__chips-clear-all {
            font-family: $lato-regular ;
            font-size: 14px;
            letter-spacing: 0.23px;
        }
    }
}

    @include breakpoint (desktop-lg) {
        div.literature { 
            div.checkbox .checkbox_default {
                font-size: 12px !important;
                line-height: 15px;
                height: 15px;
                
            } 
        } 
    }

.literature{
    .product-list__filter {
        .filter{
           @include breakpoint(desktop-lg){
                scrollbar-width: thin;
                &::-webkit-scrollbar {
                  display: block;
                }
              }
        }
    }
}
.filter{
    &__chips{
        .filter__chips-tag{
            margin: 12px 2px 20px 0;
            &.span {
                @include breakpoint (desktop-small) {
                    font-size: 11px;
                    line-height: 16px;
                    height: 16px;
                }
               
            }
        }
        
    }
}
.product-list__controls section .sort__drop-down{
    @include breakpoint (desktop-small) {
        right: 0;
    }    
}
.literature .product-list__tile-col.product-list__tile-col--full-width.literature-list__tile-col{
    z-index: 0;
}
.literature{

    .filter__inner-container{
        .product-listing__controls{

            &.filter__mobile-header{
                .product-listing__filter-toggle{
                    @include breakpoint(mobile){
                        font-size: 1pc;
                        line-height: 24px;
                        color: $text-dark-grey;
                        font-family:$lato-regular;
                        letter-spacing: 0;
                    }
                    @include breakpoint(tab){
                        font-size: 1pc;
                        line-height: 24px;
                        color: $text-dark-grey;
                        font-family:$lato-regular;
                        letter-spacing: 0;
                    }
                }
                @include breakpoint(desktop-small){
                    display: none;
                }
                @include breakpoint(mobile){
                    width: 100%;
                    margin-right: unset;
                    img {
                        width: 21.21px;
                        height: 21px;
                    }
                }
                @include breakpoint(tab){
                    margin-top: 18px;
                margin-bottom: 28px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }


.product-list__filter{
    &.product-list__filter--modal{
        .product-listing{
            &.filter{
                @include breakpoint(mobile){
                    height: 100%;
                }
                @include breakpoint(tab){
                    height: 100%;
                    
                }
            }
        } 
    }
} 
.product-list__tile-col{
    &.literature-list__tile-col{
        .product-list__back-to-top{
            @include breakpoint(desktop-small){
                margin-bottom: unset;
            }
        }
    }
} 
}
}

html[page='literature'] {
    .curated-hero-banner {
        margin-top: 0px;
    }
}
//menu verlap issue
.Literature_view_literatureWrapper__7Z8yv {
    .literature {
        .products-tab {
            .product-list__sticky-nav-wrap {
                z-index: 2;
            }
        }
    }
}
.searchbar-open {
    #literatureWrapperId, .Literature_view_literatureWrapper__7Z8yv {
        .literature {
            .products-tab {
                .product-list__sticky-nav-wrap.false {
                    z-index: auto !important;
                }
            }
        }
    }
}