.product-summary__product-summary-block {
  padding: 0;
}

.product-summary {
  background-color: $cool-light-grey;
  padding: 0 0 29.75px 46px;
  @include breakpoint(tab) {
    padding: 0 0 4.61px 56px;
  }
  @include breakpoint(mobile) {
    padding: 0 0 4.88px 0;
  }
  &__title,
  &__price-first,
  &__price-sec,
  &__details {
    font-family: $lato-regular;
  }
  &__title {
    color: $color-charcoal;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    margin-bottom: 15px;
    text-transform: uppercase;
    @include breakpoint(mobile) {
      color: $text-dark-grey;
    }
    @include breakpoint(tab) {
      margin-bottom: 14px;
    }
  }
  &__desc {
    max-width: 100%;
    width: 100%;
    color: $text-dark-grey;
    font-family: $playfair-regular;
    font-size: 36px;
    letter-spacing: -2px;
    line-height: 44px;
    margin-bottom: 30px;
    @include breakpoint(desktop-extra-lite-lg) {
      @include ann-describtion(28px, $playfair-regular, 34px);
      letter-spacing: -1.17px;
    }
    @include breakpoint(desktop-extra-sm) {
      margin-bottom: 25px;
    }
    @include breakpoint(tab-max) {
      font-size: 28px;
      letter-spacing: -1.17px;
      line-height: 34px;
    }
    @include breakpoint(tab) {
      margin-bottom: 20px;
    }
    @include breakpoint(mobile) {
      max-width: 269px;
      margin-bottom: 35px;
    }
  }
  &__price-first {
    color: $text-dark-grey;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
    margin-right: 5px;
  }
  &__price-sec {
    color: $text-medium-dark-grey;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    @include breakpoint(tab) {
      color: $text-light-grey-cap;
    }
  }
  &__details {
    max-width: 480px;
    width: 100%;
    color: $text-medium-dark-grey;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 29px;
    margin-top: 30px;
    @include breakpoint(mobile) {
      width: 339px;
      margin-bottom: 25px;
    }
    @include breakpoint(tab-max) {
      margin-top: 25px;
    }
    @include breakpoint(tab) {
      width: auto;
      margin-bottom: 35px;
    }
    @include breakpoint(ipad-landscape) {
      width: 100%;
    }
  }
  &__button-block {
    margin-bottom: 45px;
    @include breakpoint(mobile) {
      margin-bottom: 45px;
    }
    @include breakpoint(tab) {
      margin-bottom: 40px;
    }
    .product-summary__button {
      @include breakpoint(tab-max) {
        font-size: 12px;
      }
      &:hover {
        background-color: inherit;
        border: 1px solid $color-light-blue;
        color: $color-light-blue;
      }
    }
  }
  &__button {
    height: 40px;
    width: 166px;
    font-family: $lato-bold;
    color: $color-medium-blue;
    background-color: $cool-light-grey;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 2.5px;
    line-height: 10px;
    text-align: center;
    border: 1px solid $color-medium-blue;
    border-radius: 1px;
    text-transform: uppercase;
    @include breakpoint(mobile) {
      width: 100%;
    }
    @include breakpoint(tab) {
      max-width: 166px;
    }
  }
  &__button:hover {
    background-color: $primary-blue;
    color: $white-cap;
  }
  &__icons-block {
    //width: 540px;
    width: auto;
    @include breakpoint(mobile) {
      width: 100%;
      height: auto;
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include breakpoint(tab) {
      height: auto;
      width: auto;
      border-bottom: none;
    }
  }
  &__icons-part-first {
    clear: both;
    @include breakpoint(mobile) {
      width: 250px;
      margin-left: 0;
      margin-right: 0;
      justify-content: center;
    }
  }
  &__icons {
    height: 30px;
    min-width: 30px;
    margin-right: 25px;
    margin-bottom: 31.25px;
    object-fit: cover;
    mix-blend-mode: multiply;
    @include breakpoint(tab) {
      margin-right: 25px;
      margin-bottom: 30px;
    }
    @include breakpoint(mobile) {
      margin-right: 25px;
      margin-bottom: 30px;
    }
  }
  &__icons-wall {
    width: 31px;
    margin-right: 24px;
  }
  &__discount {
    @include breakpoint(mobile) {
      margin-bottom: 5px;
    }
  }
  &__discount-price {
    text-decoration: line-through;
    color: $cool-dark-grey-cap;
    font-family: $lato-regular;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
    text-align: left;
  }
  &__percent-off {
    color: $color-charcoal;
    font-family: $lato-bold;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 2px;
    line-height: 16px;
    margin-left: 10px;
  }
  &__icons-part-first
    .product-details__tooltip-main-block
    .product-details__list-tooltip-wrapper {
    left: 0;
    bottom: -64px;
    position: absolute;
  }
  &__icons-part-first
    .product-details__tooltip-main-block:not(:nth-child(2))
    .product-details__list-tooltip-wrapper {
    @include breakpoint(tab) {
      left: -85px;
    }
    @include breakpoint(mobile) {
      left: -50px;
    }
  }
}
.product-summary__icons-part-first {
  display: flex;
  flex-wrap: wrap;
}
.tooltip__container {
  width: max-content;
  span[role="tooltip"] {
    color: $text-dark-grey;
  }
}
.product-details {
  &__list-tooltip--show {
    display: block;
  }

  &__list-tooltip--hide {
    display: none;
  }
}
.product-summary .product-details__list-text {
  margin-right: 0;
}
.product-summary {
  &__icons-devider--none {
    border-bottom: none;
  }
  &__icons-devider--block {
    border-bottom: 1px solid $text-light-grey;
    @include breakpoint(mobile) {
      border-bottom: none;
    }
    @include breakpoint(tab) {
      border-bottom: none;
    }
  }
}

.product-select-style__color-custom-block {
  position: relative;
}
