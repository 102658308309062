.clear {
  clear: both;
}

.error {
  color: red;
  font-size: 90%;
}

.warning {
  color: #ffa500;
}

.divider {
  box-sizing: content-box;
  height: 0;
}
:focus:not(:focus-visible), button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}

input:focus,
select:focus,
textarea:focus,
*:focus {
  outline-color: transparent;
  outline-style: none;
}

@media not all and (min-resolution:.001dpcm) { 
  *:focus {
    outline: 2px dotted $color-dark-gray !important;
  }
}

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 10000;
  background: #000;
  opacity: 0.5;
  display: none;
  &.show {
    display: block;
  }
}

.modal-open {
  overflow-y: hidden !important;
  touch-action: none;
}
.message-modal{
  .modal-popup-content{
    max-width: 500px;
  }
}

.highlight-text {
  font-family: $font-bold;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%; 
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.search-side-panel__suggestion-list {
  list-style: none;
  margin-left: 0;
}

@media print {
  .hide-from-print,
  .gbh-helpcont,
  .embeddedServiceHelpButton {
    display: none !important;
  }

  .wow {
    animation: none!important;
    visibility: visible !important;
  }
  #ot-sdk-btn-floating {
    display: none;
  }
}

@media screen and (min-width: 980px) and (max-width: 1024px) {
  :root {
      font-size: 16px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1600px) {
  :root {
      font-size: 15px;
  }
}

@media screen and (min-width: 1600px) {
  :root {
      font-size: 16px;
  }
}

.kf-react-container {
  max-width: 1440px !important;
  padding: 0 32px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    padding: 0 36px !important;
  }
  @media only screen and (max-device-width: 1023px) and (orientation: landscape){
    padding: 0 18px !important;
  }
  @media screen and (max-width: 767px) {
    padding: 0 18px !important;
  }
  @media only screen and (max-width: 990px) and (orientation:landscape) {
    padding: 0 32px !important;
  }
}

.grecaptcha-badge { visibility: hidden; }

/* ADA Focus styles */
a:focus:not(:focus-visible), span:focus:not(:focus-visible), img:focus:not(:focus-visible), *:focus:not(:focus-visible), .kf-react-button:focus:not(:focus-visible), input:focus-visible {
  outline: none !important;
}

a:focus-visible, span:focus-visible, button:focus-visible, img:focus-visible, *:focus-visible, .kf-react-button:focus-visible {
    outline: 2px dotted $color-dark-gray ;
}
.findstore-util-nav__submit:focus-visible{
  outline: 2px dotted $color-dark-gray !important;
}
body.scroll-lock, html.scroll-lock {
  position: relative;
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
  touch-action: none;
}

.aria-messages {
  opacity: 0;
  height: 0;
}
.kf-lazyload-wrapper {
  min-height: auto;
}

.animate{
  &__fadeInUp{
    animation-duration: .6s;
  }
}

.product-details__sticky-wrapper .sticky,
.product-detail-page__sticky-wrapper .sticky,
.product-list__sticky-nav-wrap .sticky, 
.product-listing__sticky-nav-wrap .sticky, 
.search-result__sticky-search--mobile .sticky,
.search-result .product-list__sticky-nav-wrap .sticky {
  transition: all 0.8s ease-out;
  &.nav-up{
    top:58px !important;
    transition: all 0.8s ease-out;
    z-index: 999 !important;
    @media only screen and (max-width:990px) {
      top:55px !important;
    }
  }
  &.nav-down{
    top:0 !important;
    transition: all 0.4s ease-out;
    z-index: 999 !important;
  }
}
@media only screen and (max-width:768px) {
  .search-result .product-list__sticky-nav-wrap .sticky{
    &.nav-up{
      top:114px !important;
    }
    &.nav-down{
      top:58px !important;
    }
  } 
}

@media only screen and (min-width: 1921px){
  .kf-react-plp-container {
    max-width: 1820px;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1919px){
  .kf-react-plp-container {
    min-width: 1517px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .kf-react-plp-container {
    min-width: 1366px;
    .product-listing__row {
      .product-listing {
        &__tile {
          &-col--full-width {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1439px){
  .kf-react-plp-container {
    min-width: 1214px;
  }
}
@media only screen and (min-width: 480px) {
  .kf-react-plp-container {
    @media (max-width: 1023px) {
      padding: 0px;
      max-width: 92.12%;
      margin-left: 3.94%;
      margin-right: 3.94%;
    }
    @media (max-width: 1279px) {
      .product-listing {
        &__tile {
          &.promo-col-1 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 48.9%;
            flex: 0 0 48.9%;
            max-width: 48.9%;
          }
          &.promo-col-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px){
  .kf-react-plp-container {
    padding: 0px;
    @media (max-width: 1920px) {
      max-width: 94.8%;
      margin-left: 2.6%;
      margin-right: 2.6%;
    }
  }
  @media (max-width: 1279px) {
    .kf-react-plp-container {
        min-width: 971px;
    }
    // &__filter{
    //   z-index: 1;
    // }
    // &__tile-col{
    //   margin-left: 253px;
    // }

    // &__tile-col--full-width {
    //   margin-left: 0px;
    // }
  }
}