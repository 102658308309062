.contact-us-main {
  max-width: 780px;
  margin: 120px auto 120px;
  color: $slate;
  .textarea__wrapper {
    position: relative;
    padding: 0 15px 15px 15px;
    border: 1px solid $slate;
    border-radius: 4px;
    textarea {
      border: none !important;
    }
    textarea:not(:placeholder-shown) + label,
    textarea:focus + label {
      background: $color-white;
      min-width: 92%;
    }
    label {
      background: $color-white;
      min-width: auto;
    }
  }
  .title {
    .question {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 26px;
      letter-spacing: 0;
      line-height: 36px;
      padding-bottom: 0;
      margin-bottom: 19px;
      @include breakpoint(tab-max) {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
      }
      @include breakpoint(mobile) {
        padding-bottom: 0px;
      }
      @include breakpoint(tab) {
        padding-bottom: 0px;
      }
    }
  }
  .description {
    font-size: 16px;
    line-height: 24px;
    font-family: $lato-regular;
    letter-spacing: -0.55px;
    margin: 20px 0;
    color: $text-medium-dark-grey;
  }
  .required-fields {
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .policies {
    text-decoration: underline;
    font-family: "Helvetica Now Text W05 Regular";
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    margin: 20px 0;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .tab-list {
    margin: 20px 0px 30px 0px;
    border-bottom: 1px solid $color-light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    .tab-list-item {
      font-size: 14px;
      text-transform: uppercase;
      color: $text-light-grey;
      letter-spacing: 2.33px;
      line-height: 16px;
      font-family: $lato-regular;
      margin-right: 20px;
      cursor: pointer;
      padding: 0 20px 22px !important;
      &:last-child {
        margin-right: 0;
      }
      @include breakpoint(mobile) {
        padding: 0 10px 22px !important;
      }
    }
    .tab-list-active {
      color: $text-dark-grey;
      border-bottom: 2px solid $color-light-blue;
      font-weight: normal;
    }
  }
  div#contactus-upload-wrapper {
    border-bottom: 0;
  }
  .optional-form {
    border-bottom: 1px solid $color-dark-gray;
    margin: 60px 0px 0px 0px;

    div {
      &#contactus-request-message {
        margin-top: 60px;
        @include breakpoint(tab-max){
          margin-top: 40px;
        }
      }
      &#contactus-upload-text {
        margin-top: 60px;
        @include breakpoint(tab-max){
          margin-top: 40px;
        }
      }
    }

    .upload-notes {
      color: $color-dark-gray;
      letter-spacing: -0.55px;
      span:last-child {
        font-family: "Helvetica Now Text W05 Medium";
      }
    }

    .upload-image-container {
      background-color: $cool-light-grey;
      padding: 0 30px 30px;
      margin: 40px 0 60px;
      &.file-5 {
        padding: 30px;
      }
      .contact-us-image-preview {
        height: 120px;
        display: flex;
        position: relative;
        margin-top: 15px;

        &:first-child {
          margin-top: 0;
        }

        .preview-wrapper {
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-extra-light-gray;
          .image-box {
            height: 100%;
            width: 120px;
            cursor: pointer;
          }
        }

        .image-delete img {
          position: absolute;
          right: 10px;
          top: 20px;
          cursor: pointer;
        }

        textarea {
          flex: 1;
          border: none;
          resize: none;
          padding: 15px 30px 15px 20px;
          font-family: $lato-regular;
          color: $text-dark-grey;
          @include text-style (16px, 24px, 0.44px);
        }
        textarea:focus {
          outline: none !important;
        }
        &.active-comment {
          border: 1px solid $color-dark-gray;
          padding: 4px;
          border-radius: 5px;
        }
      }
      .text-count {
        position: absolute;
        right: 10px;
        top: 75%;
      }

      .drag-and-drop-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 50px 0px 0px;
        color: $slate;
        .drag-and-drop-text-large {
          @include text-style(26px, 36px, normal);
          color: $text-medium-dark-grey;
          font-family: $lato-regular;
        }
        .drag-and-drop-text-small {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          margin-top: 20px;
          color: $text-light-grey;
          margin-bottom: 25px;
        }
        .drag-and-drop-upload-btn {
          .button {
            @include text-style(12px, 24px, 2.5px);
            color: $color-medium-blue-cap;
            font-family: $lato-bold;
            text-transform: uppercase;
            border: 1px solid $color-medium-blue;
            padding: 12px 27px 12px;
            height: auto;
          }
        }
      }
      &__error-msg {
        color: $color-red-alert;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.2px;
        text-align: center;
        font-family: $lato-regular;
        margin-top: 10px;
      }
    }
    .radio-wrapper {
      margin: 40px 0;
      font-size: 16px;
      .radio {
        margin-right: 40px;
        span {
          color: $color-dark-gray;
        }
        .radio_custom {
          width: 20px;
          height: 20px;
        }
      }
    }
    .form-wrapper {
      margin: 40px 0 60px 0;
      .modal-link {
        display: block;
        margin-bottom: 20px;
        text-decoration: underline;
        cursor: pointer;
        font-family: "Helvetica Now Text W05 Regular";
        letter-spacing: -0.45px;
        line-height: 22px;
        color: $color-dark-gray;
        font-size: 14px;
      }
      &.textarea__wrapper {
        textarea {
          &::placeholder {
            opacity: 0;
          }
        }
      }
    }
    .question {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
      padding-bottom: 0;
      margin-bottom: 19px;
      @include breakpoint(mobile) {
        padding-bottom: 0px;
      }
      @include breakpoint(tab) {
        padding-bottom: 0px;
      }
    }
    .form-wrapper {
      &.textarea__wrapper {
        max-height: 212px;
        border-radius: 3px;
        border-color: $cool-dark-grey;
        &:focus-within {
          border: 1px solid $text-dark-grey;
          max-height: 212px;
          border-radius: 3px;
          border-color: $cool-dark-grey;
        }
        @include breakpoint(tab-max) {
          min-height: 200px;
          height: 200px;
        }
        textarea {
          height: 198px;
          font-family: $lato-regular;
          letter-spacing: normal;
          line-height: 26px;
          color: $text-dark-grey !important;
          @include breakpoint(tab-max) {
            height: 100% !important;
          }
        }
        @include breakpoint(tab-max) {
          min-height: 200px;
          height: 200px;
        }
        textarea {
          @include breakpoint(tab-max) {
            height: 100% !important;
          }
        }
      }
    }
    .upload-notes {
      span:last-child {
        font-family: $lato-bold;
      }
      > span {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
      }
    }
  }
  .contact-us-footer {
    font-size: 14px;
    letter-spacing: -0.45px;
    line-height: 22px;
    #submit-contact-form-button {
      text-transform: uppercase;
      text-align: center;
      font-family: $lato-bold;
      @include text-style(12px, 24px, 2.5px);
      font-weight: normal;
      text-align: center;
      height: 50px;
      background-color: $color-medium-blue-cap;
      &.disabled {
        background-color: $light-gray;
        color: $white;
      }
    }
    &__error-msg {
      color: $color-red-alert;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.55px;
      padding-bottom: 10px;
    }
    .contact-us__policies {
      font-family: "Helvetica Now Text W05 Regular";
      letter-spacing: -0.45px;
      line-height: 24px;
      color: $color-dark-gray;
      a {
        margin-right: 20px;
        text-decoration: underline;
        font-family: "Helvetica Now Text W05 Regular";
        letter-spacing: -0.45px;
        color: $color-dark-gray;
        &:hover {
          color: $text-medium-black;
        }
      }
    }
    .recaptcha-links {
      font-family: $lato-regular;
      @include text-style(13px, 20px, 0.2px);
      color: $text-medium-dark-grey;
      margin: 20px 0 30px 0 !important;
      .gpolicies {
        color: $text-medium-dark-grey;
      }
    }
    .contact-us__policies a {
      font-family: $lato-regular;
      @include text-style(13px, 20px, 0.25px);
      color: $text-medium-dark-grey;
    }
  }

  .radio__custom {
    border: 1px solid $color-dark-gray;
    &::after {
      background: $color-light-blue;
    }
  }

  input[type="date"] {
    position: relative;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  .select-dropdown {
    margin-bottom: 20px;
  }

  .select-dropdown__error-message {
    margin: 10px 0;
  }
  .input-field__prefix {
    img {
      width: 21px;
      display: block;
    }
  }
  #contact-user-phone-wrapper {
    img {
      width: 21px;
      height: 21px;
      display: block;
    }
  }
  #contact-installed-installDate-wrapper {
    img {
      width: 19px;
      display: block;
    }
  }
  .container {
    &.mb-40 {
      border-bottom: 1px solid $cool-dark-grey;
      padding-bottom: 40px;
    }
  }
}

.custom-close-icone {
  width: 10px;
  height: 10px;
}

.contact-us__success-message-wrapper {
  background-color: $cool-light-grey;
  .kf-react-container {
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 2.6% !important;
    @include breakpoint (tab) {
      margin: 0 2rem !important;
    }
    @include breakpoint (mobile) {
      margin: 0 1.125rem !important;
    }
    }
}
.contact-us__success-message {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 103px !important;
  padding-bottom: 145px !important;
  .Button_buttonWrapper__9Vvy_ {
    text-transform: uppercase;
    width: 13.375rem;
    @include breakpoint (desktop-small) {
      position: relative;
      margin-top: 64px;
      right: 23.8%;
    }
      @include breakpoint (desktop-xlg) {
        right: 21.2%;
      }
      @include breakpoint (desktop-mid) {
        right: 26.4%;
      }
    @include breakpoint (mobile) {
      width: 100%;
    }
  }
  .back {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.65px;
    position: absolute;
    top: 30px;
    background: transparent;
    border: none;

    img {
      margin-right: 20px;
    }
  }
  .message-content {
    padding-right: 20px;
    @include breakpoint (desktop-small) {
      display: flex;
      justify-content: space-between;
      width: 1580px;
    }
    .title {
      @include text-style(3.375rem, 3.875rem, -0.0625rem);
      color: $text-dark-grey;
      font-family: $playfair-regular;
      @include breakpoint (desktop-small) {
        width: 37.5rem;
      }
      @include breakpoint (tab) {
        width: 33.75rem
      }
      @include breakpoint (tab-max) {
        @include text-style(1.75rem, 2.125rem, -0.0731rem);
      }
    }
    .details {
      font-family: $lato-regular;
      @include text-style(1rem, 1.5rem, normal);
      color: $text-medium-dark-grey;
    }
  }
}

.contact-us-header {
  background-color: $cool-light-grey;
  &__breadcrumb {
    padding-top: 30px;
    margin-bottom: 40px;
    section {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: $slate;
    }
  }
  &__breadcrumb-link {
    cursor: pointer;
    color: $color-dark-gray;
    text-decoration: none;
    &:hover {
      color: $slate;
    }
  }
  .kf-page-title {
    margin: 40px 0px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-us-main {
    max-width: 512px;
    margin: 40px auto 60px auto;

    .mobile-pd-0 {
      padding: 0 !important;
    }
    .mobile-pl-0 {
      padding-left: 0 !important;
    }
    .mobile-pr-0 {
      padding-right: 0 !important;
    }

    .mobile-pl-10 {
      padding-left: 10px !important;
    }
    .mobile-pr-10 {
      padding-right: 10px !important;
    }

    .optional-form {
      margin: 40px 0 0;
      .upload-image-container {
        padding: 30px;
        margin: 40px 0;
        .drag-and-drop-container {
          margin-top: 20px;
          padding: 0;
          .drag-and-drop-text-large,
          .drag-and-drop-text-small {
            display: none;
          }
        }
      }
    }
    .contact-us-footer {
      margin-top: 0;
    }
  }
}
@media screen and (max-width: 992px) {
  .contact-us__success-message {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    padding-top: 60px !important;
    padding-bottom: 50px !important;
    min-height: auto;
    .back {
      display: none;
    }
    .message-content {
      padding-right: 0px;
      padding-bottom: 40px;
      .details {
        @include breakpoint (tab-max) {
          margin-top: 30px;
        }
      }
    }
  }
  .contact-us-count {
    top: 5px !important;
    padding-right: 15px !important;
  }
  .textarea__wrapper {
    position: relative;
    padding: 0 0 42px 15px !important;
    border: 1px solid $slate;
    border-radius: 4px;
    min-height: 278px;
    height: 278px;
    textarea {
      border: none !important;
    }
    textarea:not(:placeholder-shown) + label,
    textarea:focus + label {
      background: $color-white;
      min-width: -webkit-fill-available;
    }
    label {
      background: $color-white;
      min-width: auto;
    }
  }
}

@media screen and (max-width: 767px) {
  .contact-us-main {
    max-width: 512px;
    margin: 40px auto 60px;
    .mobile-pd-0 {
      padding: 0 !important;
    }
    .mobile-pl-0 {
      padding-left: 0 !important;
    }
    .mobile-pr-0 {
      padding-right: 0 !important;
    }

    .optional-form {
      margin: 40px 0 0;
      .upload-image-container {
        padding: 30px;
        margin: 40px 0;
        .drag-and-drop-container {
          margin-top: 20px;
          padding: 0;
          .drag-and-drop-text-large,
          .drag-and-drop-text-small {
            display: none;
          }
        }
      }
    }
    .contact-us-footer {
      margin-top: 0;
    }
  }
}

@media (max-width: 480px) {
  .contact-us-main {
    .title {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .required-fields {
      margin-top: 15px;
    }
  }
  .contact-us__success-message {
    .button {
      width: 100%;
    }
  }
}

@media screen and (max-device-width: 1023px) {
  .contact-us__success-message {
    min-height: 323px;
  }
}

.textarea-message {
  resize: none !important;
  padding: 26px 15px 0 0 !important;
}
.contact-us-count {
  position: absolute;
  bottom: 5px;
  right: 10px;
  top: unset !important;
  padding: 0 !important;
  @include ann-describtion(12px, $lato-regular, 20px);
  letter-spacing: -0.45px;
  color: $text-light-grey;
}
textarea:focus {
  outline: none !important;
}
.cmp-tabs {
  @media screen and (min-device-width: 767px) {
    .new {
      padding-top: 80px;
    }
  }
}
.cmp-tabs {
  @media screen and (max-device-width: 766px) {
    .new {
      padding-top: 80px;
    }
  }
}
.cmp-tabs {
  @media screen and (min-device-width: 767px) and (orientation: landscape) {
    .new {
      padding-top: 80px;
    }
  }
}
@media screen and (max-device-width: 991px) and (orientation: landscape) {
  .kf-react-container {
    .contact-us__success-message {
      position: relative;
      display: block;
      align-items: center;
      justify-content: space-between;
    }
    .back {
      display: none;
    }
    .kf-react-button.large {
      padding: 0 100px;
    }
  }
  .contact-us-count {
    top: -14px !important;
    padding-right: 15px !important;
  }
  .textarea__wrapper {
    position: relative;
    padding: 0 0 16px 15px !important;
    border: 1px solid $slate;
    border-radius: 4px;
    min-height: 200px !important;
    textarea {
      width: 100%;
      height: 200px;
      padding: 15px;
      border-radius: 4px;
      border: none !important;
      background-color: $color-white;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 18px;
      line-height: 1.44;
      letter-spacing: -0.7px;
      color: $slate;
      &:focus {
        border: 1px solid $slate;
      }
      &::placeholder {
        opacity: 0;
      }
    }
    textarea:focus {
      border: 1px solid $slate;
    }
    textarea:placeholder-shown + label {
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      transform: translate(0, 88%) scale(1.5);
    }
    textarea:not(:placeholder-shown) + label,
    textarea:focus + label {
      transform: translate(0, 0) scale(1);
      background: $color-white;
      min-width: -webkit-fill-available !important;
    }
    label {
      position: absolute;
      left: 15px !important;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: -0.35px;
      color: $color-dark-gray;
      margin-bottom: 0;
      transition: all 0.2s;
      min-width: auto;
      background: $color-white;
    }
  }
  .textare-label-message {
    font-size: 18px;
    font-family: $lato-regular;
    color: $text-light-grey-cap;
    min-width: auto;
  }
  .textarea__wrapper {
    position: relative;
    padding: 0 15px 15px 15px;
    border: 1px solid $slate;
    border-radius: 4px;
    textarea {
      width: 100%;
      height: 200px;
      padding: 15px;
      border-radius: 4px;
      border: none !important;
      background-color: $color-white;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 18px;
      line-height: 1.44;
      letter-spacing: -0.7px;
      color: $slate;
      &:focus {
        border: 1px solid $slate;
      }
      &::placeholder {
        opacity: 0;
      }
    }
    textarea:focus {
      border: 1px solid $slate;
    }
    textarea:placeholder-shown + label {
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      transform: translate(0, 88%) scale(1.5);
    }
    textarea:not(:placeholder-shown) + label,
    textarea:focus + label {
      transform: translate(0, 0) scale(1);
    }
    label {
      position: absolute;
      left: 15px !important;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: -0.35px;
      color: $color-dark-gray;
      margin-bottom: 0;
      transition: all 0.2s;
    }
  }
}
@media screen and (min-width: 1024px) {
  .contact-us__continue-shopping-btn {
    &.kf-react-button {
      padding: 0 60px;
    }
  }
}

.kf-react-datepicker {
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    padding-top: 0;
    margin-top: -15px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index: 999;
  }

  .react-datepicker__header {
    background: #fff;
    border-bottom: none;
    padding: 25px 0 0 0;
  }

  .react-datepicker__navigation {
    top: 25px;
  }

  .react-datepicker__navigation--previous {
    right: 60px;
    left: inherit;
  }

  .react-datepicker__navigation--next {
    right: 25px;
  }

  .react-datepicker__month {
    margin: 20px 25px 25px 25px;
  }

  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__current-month {
    margin-top: 0;
    color: $color-dark-gray;
    font-weight: normal;
    font-size: 20px;
    font-family: "Helvetica Now Text W05 Regular";
    text-align: left;
    margin-left: 25px;
  }

  .react-datepicker__current-month {
    margin-bottom: 26px;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon:before,
  .react-datepicker__year-read-view--down-arrow {
    border-color: $color-dark-gray;
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    top: 11px;
    width: 10px;
  }

  .react-datepicker {
    background-color: #fff;
    color: $color-dark-gray;
    border: 1px solid #fff;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    font-family: "Helvetica Now Text W05 Regular";
  }

  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: $color-dark-gray;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    margin: 3px;
    font-family: "Helvetica Now Text W05 Regular";
    font-size: 14px;
    line-height: 32px;
    letter-spacing: -0.65px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day:hover {
    border-radius: 0;
    background-color: $slate;
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--outside-month {
    background: none;
    color: #757575;
  }

  button.react-datepicker__close-icon {
    height: 60px;
    width: 48px;
  }

  .react-datepicker__close-icon::after {
    background: none;
    color: $color-dark-gray;
    font-size: 24px;
    margin: auto;
    text-align: center;
    width: 32px;
    left: 0;
    right: 0;
    border-radius: 0;
    display: block;
    height: 32px;
    font-family: "Helvetica Now Text W05 Light";
  }

  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    border-radius: 0;
    background-color: $slate;
    color: #fff;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled,
  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    cursor: default;
    background-color: transparent;
    color: #ccc;
  }
}

@media only screen and (max-width: 991px) {
  .kf-react-datepicker {
    .input-field__prefix {
      display: flex;
    }
  }
}

