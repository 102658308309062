.place-order {
  &__btn {
    display: inline-block;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    border: none;
  }
}

@media (min-width: 768px) {
  .place-order {
    &__btn {
      width: 50%;
    }
  }
}

@media (min-width: 980px) {
  .place-order {
    &__btn {
      width: 41.66667%;
    }
  }
}
