.inpage-search{
    padding: 60px;

    &__title{
        font-family: $font-light;;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        &--dark{
            color: $color-light-gray;
        }
    }
    &__header {
        width: 100%;
        z-index: 1;
    }

    &__header-section {
        display: flex;
        margin: auto;
        position: relative;
        padding-top: 40px;
        align-items: center;
        border-bottom: 1px solid $color-dark-gray;

        &--light:focus-within{
            border-bottom: 1px solid $color-slate;
        }
    }

    &__search-filed {
        width: 100%;
        position: relative;
        padding: 0 40px 20px 0;
    }

    &__search-control {
        width: 100%;
        font-size: 100px;
        border: 0;
        letter-spacing: -7.45px;
        padding-bottom: 0;
        font-family: $font-light;
        color: $color-slate;

        &::placeholder {
            font-size: 100px;
            color:rgba(0, 0, 0, 0.2)
        }

        &:focus + i {
            display: none;
        }

        &--dark{
            background: $color-charcoal;
            color: $color-white;

            &::placeholder {
                color: $color-dark-gray;
            }
        }
    }

    &__search-button{
        position: absolute;
        right: 0;
        top: 70px;
        z-index: 99;
        color: $color-charcoal;
        border: 1px solid $color-slate;
        font-family: $font-medium;
        height: 60px;
        margin: auto;
        padding: 17px 60px 19px;
        border-radius: 30px;
        background: $color-white;

        &--dark{
            color: $color-white;
            background: $color-charcoal;
            border: 1px solid $color-white;
        }
    }

    &--dark{
        background: $color-charcoal;
    }
}
.inpage-search-section{
    padding: 70px 60px 70px 60px;
}

@media screen and (max-width: 480px) {
    .inpage-search{
        padding: 40px 0px 0px 20px;

        &__search-control {
            font-size: 32px;
            font-weight: 300;
            line-height: 1.25;
            letter-spacing: -2.5px;
            height: 50px;

            &::placeholder {
                font-size: 32px;
                color:rgba(0, 0, 0, 0.2)
            }

            &--dark{
                &::placeholder {
                    color: $color-dark-gray;
                }
            }
        }

        &__header-section {
            padding-bottom: 20px;
        }

        &__search-filed {
            padding-bottom: 0;
            padding-right: 20px;
        }

        .button {
            font-size: 18px;
            letter-spacing: -0.35px;
            width: 100%;
            height: 46px;
            margin-top: 30px;
        }
    }

    .inpage-search-section{
        padding: 50px 0px 50px 0px;
    }
}

@media screen and (orientation:landscape) and (max-width: 900px){
    .inpage-search{
        padding: 40px 0px 0px 45px;

        &__search-control {
            font-size: 32px;
            line-height: 1.25;
            letter-spacing: -2.5px;
            height: 50px;
    
            &::placeholder {
                font-size: 32px;
                color:rgba(0, 0, 0, 0.2)
            }
        }
    }
    .inpage-search-section{
        padding: 50px 0px 50px 0px;
    }
}