
body.pdp-image-gallery-modal-opened{
    overflow-y: hidden;
    .pdp-pdp-image-reel{
        display: none;
    }
}

/* responsive carousel style */
.pdp-image-gallery {
  position: relative;
  display: flex;

  ul, li {
    list-style: none;
    padding: 0;
  }
}

.pdp-image-reel{
  &__view {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    display: block;
  }
  
  &__image-thumb-view{
    display:flex;
    margin-left: 0;
    align-items: center;
    margin-bottom: 0;
    padding: 0 6.72%;
    margin-left: -2.6%;
    @media only screen and (min-width: 1920px) {
      padding: 0 130px;
      margin-left: -50px;
    }
    .pdp-image-reel__image-thumb{
      img{
        height: 80px;
        object-fit: cover;
        object-position: 50% 50%;
        mix-blend-mode: multiply;
      }
    }
    ul {
      li {
          list-style: none;
      }
    }
  }
  
  &__image-list{
    display: block;
    padding-bottom: 16px;
    img{
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
      cursor:default;
      @media print {
        mix-blend-mode: normal;
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  
  &__image-stack-view{
    overflow: auto;
    transition: all .35s ease-in-out;
    .pdp-image-reel__link-more{
      font-family: $font-medium;
      color: $color-slate;
      font-size: 16px;
      letter-spacing: -0.32px;
      line-height: 16px;
      border-bottom: 1px solid black;
      padding-bottom: 5px;
    }
    @media print {
      transform: translateY(0px) !important;
      overflow: visible !important;
    }
  }

  &__thumb{
    display: flex;
    margin-left: 40px;
    padding-top: 45px;
  }

  &__image-thumb-list{
    display: inline-block;
    padding-right: 16px;
    &:last-child {
      padding-right: 0;
    }
  }

  &__image-thumb{
    width: 80px;
    img {
      width:80px;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__image-thumbSelected{
    border: 1px solid $color-dark-gray;
    width: 80px;
    padding: 2px;
    border-radius: 5px;
    height: 80px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
    }
    &:hover {
      cursor: pointer;
    }
  }
  
  &__image-view{
    display: block;
    margin-top: 9px;
    text-align: right;
    cursor: pointer;
    float: right;
  }
  &__image-stack-list{
    margin-left: 0px;
  }
  
  &__link-more {
    font-size: 14px;
    font-weight: 500;
    color: #757577;
    display: block;
    background: none;
    width: auto;
    border: none;
    .view-more-icon {
      display: block;
      margin: 11px auto;
    }
  }
  &__horizontal-gallery {
    .pdp-image-reel {
      &__image-stack-list, &__thumb {
        display: flex;
        margin-bottom: 0;
      }
      &__image-stack-view, &__thumb {
        &::-webkit-scrollbar {
          display: none;
        }
        &.os-theme-dark {
          &.os-host-overflow {
            padding-bottom: 35px;
            margin-left: 2.6%;
            @media only screen and (max-width: 1280px) {
              padding-bottom: 25px;
              margin-left: 2.54%;
            }
            @media only screen and (min-width: 1920px) {
              margin-left: 50px;
            }
          }
          .os-scrollbar {
            padding: 0px;
            &-handle {
              background-color: $color-medium-gray;
            }
            &-horizontal {
              right: 0;
              height: 5px;
              padding: 0 6.72%;
              margin-left: -2.6%;
              margin-right: 0;
              @media only screen and (min-width: 1920px) {
                padding: 0 130px;
                margin-left: -50px;
              }
            }
          }
          .os-content {
            padding-left: 0px !important;
          }
        }
      }
      &__image-list {
        display: inline-flex;
        padding-right: 16px;
        padding-bottom: 0;
        &:last-child {
          padding-right: 0;
        }
        img, iframe {
            width: 100vw;
            max-width: 960px;
            height: 100%;
        }
        &.pdp-image-reel__modal-image {
          img {
            height: auto;
            width: 700px;
            object-fit: cover;
          }
          iframe {
            height: auto;
            width: 700px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    &__horizontal-gallery {
      .pdp-image-reel {
        &__image-list {
          padding-right: 8px;
          &.pdp-image-reel__modal-image {
            img {
              width: 350px;
            }
            iframe {
              width: 350px;
            }
          }
        }
        &__thumb {
          padding-top: 25px;
        }
        &__image-thumb-list{
          padding-right: 8px;
        }
        &__image-thumb{
          width: 45px;
          img{
            height: 45px;
            width: 45px;
          }
        }
        &__image-thumbSelected {
          height: 45px;
          width: 45px;
        }
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    &__horizontal-gallery {
      .pdp-image-reel__slider-container {
        overflow: hidden;
        position: relative;
      }
      .pdp-image-reel__slider-list {
        overflow: initial;
        white-space: nowrap;
        transition: transform 0.3s ease-in;
        .pdp-image-reel__image-list {
          align-items: center;
          justify-content: center;
          display: inline-flex;
          padding-right: 0;
          .img-play-button {
            position: absolute;
            height: 55px;
            width: 64px;
            cursor: pointer;
            mix-blend-mode: initial;
          }
        }
      }
      .pdp-image-reel__slider-count {
        position: absolute;
        font-family: $font-regular;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: $color-slate;
        margin-left: 32px;
        margin-bottom: 30px;
        bottom: 0;
        @media screen and (max-width: 767px) {
          margin-left: 18px;
          margin-bottom: 18px;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    &__image-stack-view {
      margin-left: 0px;
    }
    &__image-thumb-view{
      padding-left: 5px;
    }
  }
}