.store-team-carousel {
    .carousel-slick {
        .cmp-carousel {
            .carousel-header-info {
                max-width: 1380px;
                margin: 0 auto;
                @include breakpoint(desktop-small) {
                    padding: 0 36px 3.125rem;
                }        
                @include breakpoint(tab) {
                    padding: 0 2rem 2.5rem;
                }
                @include breakpoint(mobile) {
                    padding: 0 1.125rem 2.5rem;
                }
                h1,h2,h3,h4,h5,h6,p{
                    text-align: center !important;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    color: $text-dark-grey!important;
                }
            }
        }                
        @include breakpoint(desktop-small) {
            padding: 4.375rem 0;
        }        
        @include breakpoint(tab-max) {
            padding: 3.125rem 0;
        }
        .cmp-carousel.cmp-carousel-slick {
            .slick-initialized {
                .slick-list {
                    max-width:1600px;
                    margin:0 auto;
                    padding: 0 2.625rem 0 6.875rem;
                    &.slick-single{
                        @include breakpoint(tab) {
                            padding: 0 88px;
                        }
                        @include breakpoint(mobile){
                            padding: 0 34px;
                        }
                        .slick-track{
                            @include breakpoint(tab-max){
                                display:flex;
                                justify-content:center;
                                align-items: center;
                            }
                        }
                    }
                    .slick-track{
                        @include breakpoint(tab-max){
                            display:flex;
                            justify-content:center;
                            align-items: center;
                        }
                    }
                    @include breakpoint(tab) {
                        padding: 0 7.75rem 0 2rem;
                    }
                    @include breakpoint(mobile) {
                        padding: 0 28px 0 19px;
                    }                                        
                }
                .slick-prev, .slick-next {
                    opacity: 0;
                }
                &.slick-slider{
                    .slick-dots{
                        li{
                            margin-top: 3.125rem;
                            @include breakpoint(tab-max){
                                margin-top: 1.875rem;
                            }
                        }
                    }
                }

            }
        }
        &.carousel-content-center {
            &.carousel-slick{
                .cmp-carousel {
                    &.cmp-carousel-slick {
                        .slick-initialized {
                            .slick-list {
                                .slick-track {
                                    .cmp-carousel-slick__item {
                                        margin: 0 20px 0 0;
                                    }
                                }
                                &.slick-single{
                                    .slick-track {
                                        .cmp-carousel-slick__item:last-child {
                                            margin-right: 0;
                                        }
                                    }  
                                }
                            }
                            .slick-dots {
                                margin: 0;
                            }
                        }                    
                    }
                    .carousel-header-info {                        
                        h4{
                            font-size: 0.75rem;
                            margin: 0;
                            text-align: center;
                        }
                    }
                    &__title {
                        width: 100%;
                        h1, h2, h3, h4, h5, h6, p {
                            @include headingtitle_h5;
                            padding: 0 0 1.25rem 0;
                            color: $text-dark-grey;
                            text-align: center;
                        }
                    }
                    &__description {
                        width: 100%;
                        p {
                            @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                            letter-spacing: normal;
                            width: 41.1875rem;
                            color: $text-medium-dark-grey;
                            margin: 0 auto;
                            text-align: center;
                            padding: 0 52px;
                            @include breakpoint(tab) {
                                width: 29.8125rem;
                                padding: 0 30px;
                            }
                            @include breakpoint(mobile) {
                                width: 21.1875rem;
                                padding: 0 20px;
                            }
                        }
                    }            
                }
            }
        }
        &.carousel-content-center.carousel-slick .cmp-carousel.cmp-carousel-slick .slick-initialized .slick-list.slick-single .cmp-carousel-slick__item:only-child{
            padding:0;
        }
        &.carousel-content-center.carousel-slick .cmp-carousel.cmp-carousel-slick .slick-initialized .slick-list .cmp-carousel-slick__item:only-child{
            margin:0;
            @include breakpoint(mobile){
                padding:0 6px 0 15px;
            }   
        }
        &.bg-white {
            .carousel-slick {
                .cmp-carousel {
                    .teaser{
                    .meet-team-card {
                        .cmp-teaser {
                            &__action-link {
                                color: $color-light-blue;
                                border-color: $color-light-blue;
                            }
                        }
                    }
                }
                }
            }
        }
    }
}