.container-events {
    .carousel-slick {
        padding: 0 0 4.375rem 0;
        @include breakpoint (tab-max){
            padding: 0 0 3.125rem 0;
        }
        .cmp-carousel-slick{
          max-width: 100%;
          margin: 0 auto;
            .carousel-header-info {
                padding: 0;
                display: none;
            }
            .slick-initialized {
                max-width: 1600px;
                margin: 0 auto;
                .slick-list {
                    margin: 0;
                    padding:0 5.625rem 0 6.875rem;
                    @include breakpoint(tab){
                        padding: 0 5.125rem 0 2rem;
                    }
                    @include breakpoint(mobile){
                        padding:0 3.6875rem 0 1.125rem;
                    }
                    .slick-track{
                      display: flex;
                        .slick-slide {
                            padding: 0;
                            margin-right: 1.25rem;
                            @include breakpoint(tab) {
                                margin-right: .625rem;
                            }
                            @include breakpoint(mobile) {
                                margin-right: 1.3125rem;
                            }
                            .teaser {
                                padding: 0;
                            }
                            &:last-child{
                                margin-right: 110px !important;
                            }
                        }
                        .slick-dotted.slick-slider {
                            margin: 0;
                        }
                    }
                }
                .slick-dots {
                    position: unset;
                    @include breakpoint(desktop-small) {
                        margin-top: 3.125rem;
                        display: none;
                    }
                    @include breakpoint(tab-max) {
                        margin-top:1.875rem;
                    }
                }  
            }      
        }
    } 
    .carousel-bottom-scroll {
        .slick-dots {
            @include breakpoint(desktop-small) {
                display: block;
            }
        }
        .cmp-carousel{
            &::after,&::before{
                content: unset !important
            }
        }
    }    
}
.container-events-v2 {
    @extend .container-events;
    .carousel-slick {
        .cmp-carousel-slick{
            .slick-initialized {
                .slick-list {
                    @include breakpoint(tab){
                        padding: 0 2rem;
                    }
                    @include breakpoint(mobile){
                        padding: 0 1.125rem;
                    }
                    .slick-track{
                        .slick-slide {
                            margin-right: 1.25rem;
                            @include breakpoint(tab) {
                                margin-right: .75rem;
                            }
                            @include breakpoint(mobile) {
                                margin-right: .625rem;
                            }
                        }
                    }
                }
            }      
        }
    } 
}