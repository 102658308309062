.tab-container{
    .tabs {
        &.vertical-tabs {
            min-height: unset;
            .cmp-tabs {
                padding-left: 0;
                &__tablist {
                    margin: 0;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    @include breakpoint(mobile) {
                        flex-direction: row;
                        flex-wrap: nowrap;
                        overflow-x: scroll;
                        padding: 0 1.125rem;
                    }

                    &::-webkit-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;

                        @include breakpoint(mobile) {
                            display: none;
                        }
                    }
                }

                &__tab {
                    padding: 1.6875rem 1.25rem 1.375rem;
                    transition: 200ms;
                    position: relative;
                    overflow: hidden;
                    border-bottom: 0;
                    white-space: nowrap;
                    margin: 0;

                    @include breakpoint(mobile) {
                        overflow: visible;
                    }

                    &:after {
                        content: '';
                        display: block;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        background-color: $color-light-blue;
                        transition: transform ease 200ms, opacity ease 0ms 200ms;
                        transform: translateX(100%);
                        opacity: 0;
                    }

                    &--active {
                        border-bottom: none;
                        color: initial;
                    }
                }

                &__tabpanel--active {
                    padding: 0;
                    animation: fadeIn 0.6s ease-in-out;
                }

                .ann-mobile-btn {
                    display: none;
                }
            }
        }
        .cmp-tabs__tab::after {
            transform: translateX(-100%);
        }

        .cmp-tabs__tab:not(.cmp-tabs__tab--active)::after {
            transform: translateY(100%);
        }

        .cmp-tabs:hover .cmp-tabs__tablist:hover .cmp-tabs__tab:hover::after {
            transition-delay: 0ms, 0ms;
        }

        .cmp-tabs__tab.cmp-tabs__tab--active::after {
            transform: translateX(0%);
            opacity: 1;
        }
    }
}

//tab style
.tab-list-style {
    &.tabs-center {
        .cmp-tabs {
            ol {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 3.75rem;

                @include breakpoint(mobile) {
                    flex-wrap: nowrap;
                    white-space: nowrap;
                    justify-content: flex-start;
                    overflow: auto;
                }

                li {
                    font-size: 0.875rem;
                    line-height: 1rem;
                    letter-spacing: 2.33px;
                    color: $text-dark-grey;
                    text-transform: capitalize;

                    &.cmp-tabs__tab--active {
                        border-bottom: 2px solid $color-light-blue;
                        font-weight: 600;
                        color: $text-dark-grey;
                    }
                }
            }
        }
    }
}

// Tab component Section

.tab-container{
    .tabs{
        &.vertical-tabs {
            @include breakpoint(desktop-mid) {
                padding: 0 36px;
            }
    
            .cmp-tabs {
                display: flex;
                align-items: flex-start;
    
                @include breakpoint(tab-max) {
                    flex-direction: column;
                }
    
                @include breakpoint(tab) {
                    padding: 0;
                }
    
                &__tablist {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-top: 15.25rem;
                    margin-bottom: 0;
                    position: relative;
                    width: 9.6875rem;
                    margin-right: auto;
                    padding-left: 1.25rem;
                    box-sizing: content-box;
                    border: none;
                    min-height: unset;
                    @include breakpoint(tab-max) {
                        width: 100%;
                        border-bottom: solid 1px $light-gray;
                        border-left: none;
                        justify-content: flex-start;
                        flex-wrap: nowrap;
                        overflow: hidden;
                        overflow-x: scroll;
                        flex-direction: row;
                        margin: 0;
                    }
    
                    @include breakpoint(mobile) {
                        padding-right: 1.125rem;
                    }
                }
    
                &__tab {
                    @include ann-describtion(0.875rem, $lato-regular, 1rem);
                    letter-spacing: 0.145625rem;
                    padding: 2.75rem 0 .3125rem 0;
                    color: $text-light-grey;
                    border-bottom: 1px solid transparent;
                    margin: 0;
                    transition: 0.2s ease-out;
                    position: relative;
                    text-transform: uppercase;
    
                    &:after {
                        content: "";
                        display: block;
                        margin: auto;
                        width: 0px;
                        border-bottom: 1px solid $text-dark-grey;
                        transition: 0.2s ease-out;
                        position: relative;
                        top: 5px;
                        height: 1px;
    
                        @include breakpoint(desktop-mid) {
                            height: 0;
                            top: 4px;
                        }
    
                        @include breakpoint(tab-max) {
                            top: 6px;
                        }
                    }
    
                    &:first-child {
                        padding-top: 0;
    
                        @include breakpoint(tab) {
                            margin-left: 6.25rem;
                        }
    
                        @include breakpoint(mobile) {
                            padding-left: 0;
                            margin-left: 0;
                        }
                    }
    
                    &:last-child {
                        padding-bottom: 2.0625rem;
    
                        @include breakpoint(tab-max) {
                            padding-bottom: .375rem;
                        }
                    }
    
                    @include breakpoint(tab-max) {
                        padding: 0 1.25rem .375rem;
                    }
    
                    &.cmp-tabs__tab--active {
                        border: none;
                        color: $text-dark-grey;
    
                        &:after {
                            width: 100%;
                        }
                    }
                }
    
                &__tabpanel {
                    padding-right: 0;
                    width: 68.7%;
                    border-left: 1px solid $cool-dark-grey;
    
                    @include breakpoint(tab-max) {
                        border-left: none;
                        width: 100%;
                    }
    
                    @include breakpoint(tab) {
                        margin: 3.1875rem 0 3.125rem;
                        padding: 0 2rem;
                    }
    
                    @include breakpoint(mobile) {
                        padding: 0 1.125rem;
                        margin: 0;
                    }
    
                    &--active {
                        .cmp-container {
                            padding: 0;
                        }
                    }
                }
    
                .slider {
                    @include breakpoint(desktop-small) {
                        display: none;
                    }
                }
            }
        }
    }
}


.tabs-wrapper {
    &.bg-white {
        .tabs {
            .cmp-tabs {
                &__tablist {
                    border-bottom: 1px solid $cool-dark-grey;
                }
            }
        }
    }

    &.bg-cool-grey-light,
    &.bg-warm-gray-light {
        .tabs {
            .cmp-tabs {
                &__tablist {
                    border-bottom: 1px solid $light-white;
                }
            }
        }
    }

    .tabs.center-aligned {
        .cmp-tabs {
            @include breakpoint(tab) {
                overflow-x: auto;
            }

            @include breakpoint(mobile) {
                overflow-x: auto;
            }

            &__tablist {
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: nowrap;
                position: relative;
                padding:0 2.602%;
                @include breakpoint(desktop-large){
                    padding:0 50px;
                }
                @include breakpoint(desktop-mid){
                    padding:0 2.25rem;
                }
                @include breakpoint(tab){
                    justify-content:center;
                    padding:0 2rem;
                }

                @include breakpoint(mobile) {
                    justify-content: flex-start;
                    overflow: scroll;
                    padding: 0 0 0 1.125rem;
                }
            }
            &__tab{
                @include ann-describtion(14px, $lato-regular, 16px);
                letter-spacing:2.33px;
                transition:0.4s ease-in-out;
                border-bottom:none;
                color:$text-light-grey;
                padding:27px 20px 22px;
                margin-right:1.25rem;
                position: relative;
                white-space: nowrap;
                text-transform: uppercase;
                height: 65px;
                @include breakpoint(tab){
                    margin-left: 0;
                    padding:28px 0 22px 20px;
                    height: 66px;
                }
                @include breakpoint(mobile){
                    margin-left: 0;
                }
                &:focus-visible {
                    outline-offset: -5px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 2px;
                    background: $color-light-blue;
                    -webkit-transition: width .3s;
                    transition: width .3s;
                    position: relative;
                    bottom: -22px;
                }
                &--active{
                    color:$text-dark-grey;
                    &:after{
                        width: 100%;
                        position: absolute;
                        bottom:0;
                    }
                }
            }
        }
    }
}