body{
    padding:0;
    margin:0;
    font-size:16px;
    font-family:$lato-regular;
}
// Description style 
@mixin ann-describtion($describe-font-size, $describe-font-fam, $describe-line-height){
     font-size:$describe-font-size;
     font-family:$describe-font-fam;
     line-height:$describe-line-height;
 }
 // a link 
@mixin ann-cta-link{
    font-size:11px;
    line-height:16px;
    letter-spacing:1.8px;
    text-transform:uppercase;
    font-family:$lato-regular;
    text-decoration:none;
    border-bottom:1px solid $cool-light-grey;
    padding-bottom:10px;
    transition: ease-in-out 1s;
    display:inline-flex;
}
// Section Counter Style
.ann-largenumber-point-count{
    font-size:150px;
    letter-spacing: -3px;
    font-family:$lato-regular;
    @include breakpoint(tab){
        font-size:90px;
        letter-spacing:-2px;
    }
    @include breakpoint(mobile){
        font-size:90px;
        letter-spacing:-2px;
    }
}
// Uppercase Header
.ann-caps-header{
    font-family:$lato-regular;
    font-size:36px;
    letter-spacing:5px;
    text-transform:uppercase;
    @include breakpoint(tab){
        font-size:18px;
        letter-spacing:2.5px;
    }
    @include breakpoint(mobile){
        font-size: 14px;
        letter-spacing:2px;
    }
    &.caps-medium{
        font-size:24px;
        letter-spacing: 3.5px;
    }
    &.caps-small{
        font-size:18px;
        letter-spacing:2.5px;
    }
    
}
// breadcrumb title
@mixin ann-breadcrumb{
    font-family:$lato-regular;
    font-size:13px;
    line-height: 16px;
    letter-spacing:0.2px;
}
@mixin text-style ($fs, $lh, $ls){
    font-size: $fs;
    line-height: $lh;
    letter-spacing: $ls;
}

