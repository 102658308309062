.collection-teaser-five {
    .cmp-teaser {
        &__title {
            color: $text-dark-grey;
            margin-top: 1.5625rem;
        }
        &__image {
            margin: 0;
        }
        .cmp-img-caption{
            margin-top: 25px;
        }
    }        
}