.global-hero-banner {
    @include breakpoint(desktop-small){
        max-height:1000px;
        position: initial;
    }
    .cmp-teaser {
        display: block;
        position: relative;   
        z-index: 0;     
        &__image {
            max-height:1000px;
            min-height: 388px;
            @include breakpoint(tab){
                max-height:393px;
            }
            @include breakpoint(mobile){
                min-height:775px;
            }
            &:before{
                content:none;
            }
            .cmp-image { 
                @include breakpoint(mobile){
                    min-height:inherit;
                }
                &::before {
                    background-image: linear-gradient(0deg, $black 0%, transparent 0%);
                    background-color: transparent;
                    @include breakpoint(tab-max) {
                        background-image: inherit;
                    }
                }
                img{
                    max-height:inherit;
                    @include breakpoint(mobile){
                        min-height:inherit;
                    }
                }
            }             
        }
        &__pretitle{
            p {
            font-family: $lato-regular;
            font-size: 0.875rem;
            line-height: 1rem;
            letter-spacing: .125rem;
            padding: 0 0 1.25rem 0;
            text-transform: uppercase;
            }
            @include breakpoint(tab-max) {
                color: inherit;
                padding: 0 0 0.625rem 0;
            }
        }
        &__title { 
            h1, h2, h3, h4, h5, h6, p {
                @include headingtitle_h1;
                margin: 0;
                @include breakpoint(tab-max) {
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                    color: inherit;
                    letter-spacing: -0.175rem;
                }
            }
        }
        &__description {  
                padding: 1.875rem 0 0;
            @include breakpoint(tab-max) {
                padding: .625rem 0 0;
            }
            p {
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                letter-spacing: normal;
                @include breakpoint(tab-max) {
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                }            
            }
        }
        &__action-container {
            padding:2.5rem 0 0; 
            @include breakpoint(tab-max){
                padding:1.5625rem 0 0;
            }           
            @include breakpoint(mobile){
                display:inline-flex;
                flex-direction:column;
            }
        }
        &__action-link{
            padding: 0 0 0.4375rem 0;
            line-height:12px;
            border-color: $white;
            @include breakpoint(tab-max) {
                color: inherit;
                border-color: inherite;
                line-height:13px;
            }
            &:nth-child(even){
                margin-left:2.4375rem;
                @include breakpoint(tab){
                    margin-left:19px;
                }
                @include breakpoint(mobile){
                    margin-left:0;
                    margin-top:20px;
                }
            }
            &:last-child{
                position:absolute;
                bottom:4.125rem;
                border:none;
                padding:0;
                margin:0 auto;
                right:0;
                left:0;
                width:24px;
                height:14px;
                background:transparent;
                box-shadow:none;
                color:inherit;
                @include breakpoint(tab-max){
                    bottom:46px;
                }
                &:before{
                    content:"\e92a";
                    font-family:$icon-moon;
                    width:25px;
                    height:15px;
                    font-size:12px;
                    font-weight:bolder;
                    transform: translatey(0px);
                    animation: float 6s ease-in-out infinite;
                    position: relative;
                    top:3px;
                    @include breakpoint(tab-max){
                        font-size:10px;
                    }
                }
            }
        }
    }
    &.content-bottom-center {
        .cmp-teaser {
            &__content {
                position: absolute;
                background: none;                    
                width: 100%;
                text-align: center;
                padding: 0 0 11.25rem;
                bottom: 0;
                @include breakpoint(tab-max){
                    padding: 0 0 6.25rem;
                }
                @include breakpoint(tab) {
                    max-width:476px;
                    left:50%;
                    margin-left:-238px;
                }
                @include breakpoint(mobile){
                    min-width:339px;
                    padding-right:1.125rem;
                    padding-left:1.125rem;
                    margin:0 auto;
                }
            }
            &__title {
                @include breakpoint(desktop-small){
                    max-width: 51.75rem;
                    min-width: 27.0625rem;
                    margin: 0 auto;
                } 
                @include breakpoint(tab) {
                    min-width: 28.75rem;
                    padding:0 20px;
                }
                @include breakpoint(mobile){
                    max-width:270px;
                    margin:0 auto;
                }
            }
            &__description {
                width: 26.25rem;
                margin: 0 auto;
                @include breakpoint(tab-max){
                    color: inherit;
                }
                @include breakpoint(tab) {
                    width: 21.25rem;
                    left: 0;
                }
                @include breakpoint(mobile) {
                    width: 21.1875rem;
                }
                p {
                    color: inherit;
                }
            }
        }        
    }
}   