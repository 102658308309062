.annsacks-navigation-teaser{
    padding:5.625rem 0;
    max-width:51.25rem;
    margin:0 auto;
    @include breakpoint (desktop-xlg) {
        max-width: 64.125rem;
    }
    @include breakpoint(desktop-extra-lite-sm){
        padding:0;
    }
    .cmp-teaser{
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content:space-between;
        &__content{
            width:17.375rem;
            box-sizing:content-box;
            padding-left:7.375rem;
            @include breakpoint (desktop-xlg) {
                padding-left: 157px;
            }
            @include breakpoint(desktop-extra-lite-sm){
                display:none;
            }
        }
        &__description{
            p{
                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                color:$text-medium-dark-grey;
                margin-bottom:2.5rem;
            }
        }
        &__action-link{
            padding:.75rem 1.6875rem;
            color:$color-medium-blue;
            border-color:$color-medium-blue;
        }
        &__image{
            .cmp-image{
                @include breakpoint(desktop-extra-lite){
                    width: 415px;
                }
                @include breakpoint(desktop-xlg){
                    width: 473px;
                }
                @include breakpoint(desktop-extra-lite-lg){
                    width: 475px;
                }
                @include breakpoint(tab){
                    width:364px;
                }
                @include breakpoint (mobile) {
                    width: 154px;
                }
            }
        }
    }
} 

.ann-mob-nav{
    display:none;
    a{
        @include breakpoint(desktop-extra-lite-sm){
            text-transform:uppercase;
            padding:0;
        }
        @include breakpoint(desktop-extra-lite-sm){
            @include ann-describtion(.75rem, $lato-regular,1.5rem);
            letter-spacing:.125rem;
            color:$text-dark-grey;
        }
        @include breakpoint(mobile){
            @include ann-describtion(.75rem, $lato-regular,1rem);
            letter-spacing:.1069rem;
            color:$text-medium-dark-grey;
        }
    }
}
.ann-mob-back-btn{
    display:none;
    @include breakpoint(desktop-extra-lite-sm){
        display:block;
    }
}
.annsacks-navigation-teaser {
    .cmp-image {
        @include breakpoint (desktop-xlg) {
        width: 473px;
        }
        img {
        @include breakpoint (mobile) {
            max-width: 154px;
            max-height: 150px;
            object-fit: cover;
        }
        @include breakpoint (tab) {
            overflow: hidden;
        }
        }
    }
    .cmp-teaser {
        &__content {
            @include breakpoint (desktop-xlg) {
            width: 396px;
            }
        }
    }
}
.tab-pane {
    @include breakpoint (desktop-xlg) {
    margin: 0 8.125rem 0 1rem;
    }
}
