.linear-left-to-right, .linear-right-to-left, .linear-top-to-bottom, .radial-from-center-out,.radial-out-from-center, .linear-bottom-to-top, .linear-center-aligned, .topToBottom, .radialCenterOut, .bottomToTop, .leftToRight{
    .image {
        .cmp-image{
            position: relative;
        }
        .cmp-image:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
    .cmp-teaser{
        &__image{
          position: relative;
        }
        &__image:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            .cmp-teaser__image{
                position: relative;
            }
        }
    }
    &.kf-promo-banner__layout{
        .kf-promo-banner__image{
            &:after{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
} 
.linear-left-to-right, .linear-left-to-right .fc-white, .leftToRight{
        .image {
            .cmp-image:after {
                background: linear-gradient(to right, $black 0%, transparent, transparent );
            }
        }
        .cmp-teaser{
            &__image:after{
                background: linear-gradient(to right, $black 0%, transparent, transparent );
            }
        }
    &.fc-black {
        .image {
            .cmp-image:after {
                background: linear-gradient(to right, $white 0%, transparent, transparent);
            }
        }
        .cmp-teaser{
            &__image:after{
                background: linear-gradient(to right, $white 0%, transparent, transparent);
            }
        }
    }
    &.kf-promo-banner__layout{
        .kf-promo-banner__image{
            &:after{
                background: linear-gradient(to right, $white 0%, transparent, transparent);
            }
        }
    }
}
.linear-right-to-left, .linear-right-to-left .fc-white{
    .image {
        .cmp-image:after {
            background: linear-gradient(to left, $black 0%, transparent, transparent);
        }
    }
    .cmp-teaser{
        &__image:after{
            background: linear-gradient(to left, $black 0%, transparent, transparent);
        }
    }
    &.fc-black {
        .image {
            .cmp-image:after {
                background: linear-gradient(to left, $white 0%, transparent, transparent);
            }
        }
        .cmp-teaser{
            &__image:after{
                background: linear-gradient(to left, $white 0%, transparent, transparent);
            }
        }
    }
}
.linear-top-to-bottom, .linear-top-to-bottom .fc-white, .topToBottom{
    .image {
        .cmp-image:before {
            background: linear-gradient(to bottom, $black 0%, transparent, transparent);
        }
    }
    .cmp-teaser{
        &__image:after{
            background: linear-gradient(to bottom, $black 0%, transparent, transparent);
        }
    }
    &.fc-black {
        .image {
            .cmp-image:before {
                background: linear-gradient(to bottom, $white 0%, transparent, transparent);
            }
        }
        .cmp-teaser{
            &__image:after{
                background: linear-gradient(to bottom, $white 0%, transparent, transparent);
            }
        }
    }
    &.kf-promo-banner__layout{
        .kf-promo-banner__image{
            &:after{
                background: linear-gradient(to bottom, $black 0%, transparent, transparent);
            }
        }
    }
}
.linear-bottom-to-top, .linear-bottom-to-top .fc-white, .bottomToTop{
    .image {
        .cmp-image:before {
            background:linear-gradient(0deg, $black 0%, transparent 100%);
        }
    }
    .cmp-teaser{
        &__image:after{
            background:linear-gradient(0deg, $black 0%, transparent 100%);
        }
    }
    &.fc-black {
        .image {
            .cmp-image:before {
                background: linear-gradient(0 deg, $white 0%, transparent 100%);
            }
        }
        .cmp-teaser{
            &__image:after{
                background: linear-gradient(0 deg, $white 0%, transparent 100%);
            }
        }
    }
    &.kf-promo-banner__layout{
        .kf-promo-banner__image{
            &:after{
                background:linear-gradient(0deg, $black 0%, transparent 100%);
            }
        }
    }
}
.radial-from-center-out, .radial-from-center-out .fc-white, .linear-center-aligned, .linear-center-aligned .fc-white, .radial-out-from-center, .radialCenterOut{
    .image {
        .cmp-image:after {
            background: radial-gradient(circle, $black 0% , transparent 100%);
        }
    }
    .cmp-teaser{
        &__image:after{
            background: radial-gradient(circle, $black 0% , transparent 100%);
        }
    }
    &.fc-black {
        .image {
            .cmp-image:after {
                background: radial-gradient(circle, $white 0% , transparent 100%);
            }
        }
        .cmp-teaser{
            &__image:after{
                background: radial-gradient(circle, $white 0% , transparent 100%);
            }
        }
    }
    &.kf-promo-banner__layout{
        .kf-promo-banner__image{
            &:after{
                background: radial-gradient(circle, $black 0% , transparent 100%);
            }
        }
    }
}
// Linear Gradient Opacity Global Control
.gradient-mask-20, .gradient-mask-25, .gradient-mask-30, .gradient-mask-35, .gradient-mask-40, .gradient-mask-45, .dml-opacity-20, .dml-opacity-25,.dml-opacity-30, 
.dml-opacity-35, .dml-opacity-40, .dml-opacity-45{
    .image{
        position: relative;
        .cmp-image{
            &:before,
            &:after{
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: auto;
                content: "";
                top:0;
                left:0;
            }
        }
    }
    .cmp-teaser{
        &__image:after{
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            content: "";
            top:0;
            left:0;
        }
    }
}
.gradient-mask-20{
    .image{
        .cmp-image:after{
            opacity: 0.2;
        }
    }
    .cmp-teaser{
        &__image:after{
            opacity: 0.2;
        }
    }

}
.gradient-mask-25{
    .image{
        .cmp-image:after{
            opacity: 0.25;
        }
    }
    .cmp-teaser{
        &__image:after{
            opacity: 0.25 ;
        }
    }

}
.gradient-mask-30{
    .image{
        .cmp-image:after{
            opacity: 0.3;
        }
    }
    .cmp-teaser{
        &__image:after{
            opacity: 0.3;
        }
    }

}
.gradient-mask-35{
    .image{
        .cmp-image:after{
            opacity: 0.35;
        }
    }
    .cmp-teaser{
        &__image:after{
            opacity: 0.35;
        }
    }

}
.gradient-mask-40{
    .image{
        .cmp-image:after{
            opacity: 0.40;
        }
    }
    .cmp-teaser{
        &__image:after{
            opacity: 0.40;
        }
    }

}
.gradient-mask-45{
    .image{
        .cmp-image:after{
            opacity: 0.45;
        }
    }
    .cmp-teaser{
        &__image:after{
            opacity: 0.45;
        }
    }
}
.dml-opacity-20, .dml-opacity-25,.dml-opacity-30, .dml-opacity-35, .dml-opacity-40, .dml-opacity-45{
    .image{
        .cmp-image{
            &:before,&:after{
                @include breakpoint(mobile){
                    content:none;
                }
            }
        }
    }
    .cmp-teaser{
        &__image:after{
            @include breakpoint(mobile){
                content:none;
            }
        }
    }
}
.full-tint{
    .image{
        .cmp-image:before{
            background: $black;
            opacity: .7;
        }
    }
    .cmp-teaser{
        &__image{
            &::after{
                background: $black ;
                opacity: .7 ;
            }
        }
    }
    &.fc-black {
        .image{
            .cmp-image:before{
                background: $white ;
                opacity: .7 ;
            }
        }
        .cmp-teaser{
            &__image{
                &::after{
                    background: $white ;
                    opacity: .7 ;
                }
            }
        }
    }
}
.mp-opacity-20, .mp-opacity-25, .mp-opacity-30, .mp-opacity-35, .mp-opacity-40, .mp-opacity-45, .mp-ft-opacity-10, .mp-ft-opacity-20, .mp-ft-opacity-30, .mp-ft-opacity-40{
    @include breakpoint(mobile){
        .image{
            position: relative;
            .cmp-image{
                &:before,
                &:after{
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: auto;
                    content: "";
                    top:0;
                    left:0;
                }
            }
        }
        .cmp-teaser{
            &__image:after{
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: auto;
                content: "";
                top:0;
                left:0;
            }
        }
    }
}
.mp-ft-opacity-10, .mp-ft-opacity-20, .mp-ft-opacity-30, .mp-ft-opacity-40{
    @include breakpoint(mobile){
        .image{
            position: relative;
            .cmp-image{
                &:before,
                &:after{
                    background:$black;
                }
            }
        }
        .cmp-teaser{
            &__image:after{
                background:$black;
            }
        }
        &.fc-black{
            .cmp-teaser{
                &__image{
                    &:after{
                        background:$white;
                    }
                }
            }
        }
    }
}
.dml-ft-opacity-10, .dml-ft-opacity-20, .dml-ft-opacity-30, .dml-ft-opacity-40{
    @include breakpoint(tab-min){
        .cmp-teaser{
            &__image{
                &:after{
                    content:'';
                    position:absolute;
                    width:100%;
                    height:100%;
                    top:0;
                    left:0;
                    background:$black;  
                }
            }
        }
        &.fc-black{
            .cmp-teaser{
                &__image{
                    &:after{
                        background:$white;
                    }
                }
            } 
        }
    }
}
.dml-ft-opacity-10{
    @include breakpoint(tab-min){
        .image{
            .cmp-image:after{
                opacity: 0.1;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.1;
            }
        } 
    }
}
.dml-ft-opacity-20, .dml-opacity-20{
    @include breakpoint(tab-min){
        .image{
            .cmp-image:after{
                opacity: 0.2;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.2;
            }
        } 
    }
}
.dml-opacity-25{
    @include breakpoint(tab-min){
        .image{
            .cmp-image:after{
                opacity: 0.25;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.25;
            }
        } 
    }
}
.dml-ft-opacity-30, .dml-opacity-30{
    @include breakpoint(tab-min){
        .image{
            .cmp-image:after{
                opacity: 0.3;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.3;
            }
        } 
    }
}
.dml-opacity-35{
    @include breakpoint(tab-min){
        .image{
            .cmp-image:after{
                opacity: 0.35;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.35;
            }
        } 
    }
}
.dml-ft-opacity-40, .dml-opacity-40{
    @include breakpoint(tab-min){
        .image{
            .cmp-image:after{
                opacity: 0.4;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.4;
            }
        }  
    }
}
.dml-opacity-45{
    @include breakpoint(tab-min){
        .image{
            .cmp-image:after{
                opacity: 0.45;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.45;
            }
        } 
    }
}
.full-bleed-img-overlay.mp-ft-opacity-10.fc-white .cmp-teaser__image:before{
    @include breakpoint(mobile){
        height: 210px;
    }
}
.mp-ft-opacity-10{
    @include breakpoint(mobile){
        .image{
            .cmp-image:after{
                opacity: 0.1;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.1;
            }
        }
    }
}

.mp-ft-opacity-20, .mp-opacity-20{
    @include breakpoint(mobile){
        .image{
            .cmp-image:after{
                opacity: 0.2;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.2;
            }
        } 
    }
}
.mp-opacity-25{
    @include breakpoint(mobile){
        .image{
            .cmp-image:after{
                opacity: 0.25;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.25;
            }
        } 
    }
}
.mp-ft-opacity-30, .mp-opacity-30{
    @include breakpoint(mobile){
        .image{
            .cmp-image:after{
                opacity: 0.3;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.3;
            }
        }
    }
}
.mp-opacity-35{
    @include breakpoint(mobile){
        .image{
            .cmp-image:after{
                opacity: 0.35;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.35;
            }
        } 
    }
}
.mp-ft-opacity-40, .mp-opacity-40{
    @include breakpoint(mobile){
        .image{
            .cmp-image:after{
                opacity: 0.4;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.4;
            }
        } 
    }
}
.mp-opacity-45{
    @include breakpoint(mobile){
        .image{
            .cmp-image:after{
                opacity: 0.45;
            }
        }
        .cmp-teaser{
            &__image:after{
                opacity: 0.45;
            }
        } 
    }
}