.product-list,
.search-result,
.search-side-panel {
  @media only screen and (max-width: 1023px) {
    &__tile-col {
      .row-cols-1,
      .row-cols-2 {
        .product-list-tile__share {
          opacity: 1;
          top: 10px;
          right: 10px;
          &-icon {
            background: transparent;
            margin: 11px;
            width: 15px;
            height: 13.3px;
            border: none;
          }
        }
      }
      @media screen and (max-width: 480px) {
        .row-cols-2 {
          .product-list-tile__share {
            top: 2px;
            right: 2px;
          }
        }
      }
    }
  }

  .product-list-tile {
    width: 100%;
    position: relative;

    &__details {
      justify-content: space-between;
      word-break: break-word;
      div {
        &:first-child {
          flex: 1;
        }
      }
      .product-tile__discount-price {
        text-align: left;
        text-decoration: none;
      }
      .product-tile__price {
        text-align: left;
        color: #757575;
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 1pc;
        line-height: 1.38;
        letter-spacing: -0.8px;
      }
    }

    &__compare-main {
      display: flex;
      margin-top: 10px;
      @media screen and (max-width: 480px) {
        margin-top: 5px;
      }
    }

    &__info {
      margin-top: 15px;
    }
    &__title {
      height: auto;
      max-width: 340px;
      color: $text-dark-grey;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
    }

    &__description {
      font-family: "Helvetica Now Text W05 Regular";
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: auto;
      max-width: 340px;
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    &__product-sku {
      color: $color-dark-gray;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 20px;
      padding-top: 10px;
    }

    &__price,
    &__discount-price {
      font-family: "Helvetica Now Text W05 Medium";
      padding-top: 10px;
      height: auto;
      color: $text-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__discount-price {
      text-decoration: line-through;
      color: $color-dark-gray;
      padding-left: 6px;
    }
    &__colors {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.4px;
      color: $color-dark-gray;

      &--swatch {
        position: relative;
        display: flex;
        z-index: 1;
        align-items: center;
        transition: all 0.5s ease-in-out;
      }
    }
    &__color-swatch {
      &.selected-swatch,
      &.selected-swatch:focus {
        img {
          padding: 3px;
          border: 1px solid $color-charcoal;
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover,
      &:focus {
        img {
          padding: 3px;
          border: 1px solid $medium-gray;
          transition: all 0.3s ease-in-out;
        }
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        display: block;
      }
    }

    &__compare {
      transition: all 0.5s ease-in-out;
      position: relative;
      flex: none !important;
      float: right;
      .tooltip__container {
        position: absolute;
        z-index: 9;
        right: 0;
      }
      .checkbox {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.4px;
        margin-bottom: 0;
        &_default {
          margin: auto;
        }
      }
      &-button {
        background: none;
        border: none;
        outline: none;
        color: $color-dark-gray;
        z-index: 100;
        &:hover {
          color: $color-dark-gray;
        }
      }
      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    &__share {
      display: flex;
      position: absolute;
      z-index: 1;
      right: 12px;
      top: 12px;
      transition: all 0.5s ease;
      opacity: 0;

      &-icon,
      &-icon-plp {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: $half-white;
        border-radius: 5px;
        margin-left: 5px;
        border-color: transparent;
      }
      &-icon-plp {
        @media screen and (max-width: 1023px) {
          display: none;
        }
      }
      &:hover {
        opacity: 1;
      }
    }

    &--lite {
      .product-list-tile {
        &__title {
          font-size: 16px;
          line-height: 1.43;
          letter-spacing: -0.25px;
        }

        &__price {
          font-size: 14px;
          padding-left: 0;
        }
        &__discount-price {
          font-size: 14px;
          padding-left: 6px;
        }
        &_paddingtop {
          padding-top: 15px;
        }
        &__description {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }

    &--loading {
      .loading-image {
        width: 100%;
        padding-bottom: 130%;
        margin-bottom: 20px;
      }
      .loading-title {
        width: 75%;
        height: 10px;
        margin-bottom: 10px;
      }
      .loading-description {
        width: 70%;
        height: 10px;
        margin-bottom: 10px;
      }
      .loading-swatch {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          margin-right: 5px;
        }
      }
    }

    &__right-controls {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      &--lite &__colors {
        display: block;
      }
      &--lite &__description {
        display: -webkit-box;
      }
      .product-list-tile {
        &__product-sku {
          font-size: 14px;
        }
      }
    }
    @media only screen and (max-device-width: 1023px) and (orientation: landscape) {
      &__title-info {
        height: auto;
      }
      &__control-section {
        margin-top: 45px;
      }
    }
    @media screen and (max-width: 480px) {
      &--lite {
        .product-list-tile {
          &__share {
            opacity: 1;
            display: flex;
            &-icon {
              background: transparent;
              width: 20px;
              height: 20px;
            }
            > div:last-child {
              display: none;
            }
          }
          &__image {
            margin-bottom: 5px;
          }
          &__compare-main {
            display: block;
          }
          &__product-sku {
            font-size: 12px;
          }
        }
      }
      &__title {
        font-size: 18px;
      }
    }

    @media print and (min-width: 280px) {
      &__right-controls {
        display: none;
      }
    }
  }

  .product-list__tile-col {
    .row-cols-2 {
      .col-8.promo-col.product-list {
        &__tile {
          &:last-child {
            height: 584px;
          }
        }
      }
    }
  }
}

.ProductTile_productTileWrapper__slRQS .search-result .product-list-tile {
  &__compare {
    @include breakpoint(tab) {
      display: block !important;
    }
  }
}
