@mixin text-style($fs, $lh, $ls) {
  font-size: $fs;
  line-height: $lh;
  letter-spacing: $ls;
}
.cart-landing {
  .kf-react-container {
    &.cart-landing {
      .order-summary-cart {
        .order-summary {
          .estimated-total {
            .order-summary__item--value-large {
              color: $charcoal;
            }
          }
          .checkout-btn-group {
            a {
              background-color: $maroon;
              font-family: $lato-bold;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 2.5px;
              text-transform: uppercase;
              color: $color-white;
              padding: 13px 27px;
            }
          }
        }
        .cart-accordian {
          .header {
            font-family: $font-regular;
            margin: 0;
          }
        }
      }
      .cart-item-list-section {
        .cart-landing__content {
          .cart-items {
            .cart-item__name {
              letter-spacing: -0.4px;
            }
            .cart-item__desc {
              letter-spacing: -0.4px;
            }
            .mobile-share-cart-id {
              margin-bottom: 0;
            }
            .cart-item {
              .quantity-selector__count {
                color: $slate;
              }
              .cart-item__specs {
                .cart-landing-recalculate__recalculate-main-block {
                  a.cart-landing-recalculate__popup-link {
                    margin-left: 20px;
                  }
                }
              }
            }
            .cart-item__cta-wrapper {
              .remove-sec-cart {
                .cart-item__cta {
                  span {
                    color: $charcoal;
                  }
                }
              }
            }
            .cart-header {
              .cart-id-share {
                .download-specs__cta {
                  span {
                    font-family: $lato-bold;
                    @include text-style(11px, 10px, 2.5px);
                    color: $color-medium-blue;
                    text-transform: uppercase;
                    font-weight: 700;
                    border: 1px solid $color-medium-blue;
                  }
                }
              }
            }
            &__th{
              &.cart-table-header{
                border-bottom: 1px solid $cool-dark-grey;
              }
            }
          }
        }
      }
    }
  }
}
body {
  .embeddedServiceHelpButton{
    .helpButton {
      display: block;
    }
  } 
}