.SearchView_searchViewStyleWrapper__m07Pg.Plp-Search{
  .search-result {
    background-color: $cool-light-grey;

    .container-fluid {
        padding: 0px;
        .no-gutters>.col, .no-gutters>[class*=col-]{
            padding-left: 0;
        }
        .row {
            .col {
                background-color: $white;
            }
            .search-result{
                &__tab-content{
                    .product-listing{
                        &__sticky-nav-wrap{
                            @include breakpoint(tab){
                                margin: 30px 32px 16px 32px;
                            }
                            @include breakpoint(mobile){
                                padding: 17px 18px;
                                margin: 0px;
                            }
                            .product-listing{
                                &__sticky-nav.false{
                                    @include breakpoint(mobile){
                                        margin:unset;
                                    }
                                    .container.kf-react-plp-container{
                                        @include breakpoint(tab){
                                            margin: unset;
                                        }
                                        @include breakpoint(mobile){
                                            margin: unset;
                                        }
                                        .sorting-section{
                                            .sort.sort--open{
                                                .sort__drop-down{
                                                    .sort__item{
                                                        font-size: 16px;
                                                        color: $text-light-grey-cap;
                                                        font-family: 'Lato-Regular';
                                                        letter-spacing: normal;
                                                        text-align: left;
                                                            &.active{
                                                              background-color: $cool-light-grey;
                                                            }
                                                            &:hover{
                                                              background-color: $cool-light-grey;
                                                              
                                                            }}
                                                }}
                                        }
                                    }
                                }
                            }
                            &.outer-sticky-wrap{
                                @include breakpoint(mobile){
                                    padding: 30px 0 1pc;
                                }
                                .sticky{
                                    &.nav-up,&.nav-down{
                                        @include breakpoint(mobile){
                                        left: 0px;
                                        right:0px
                                        }
                                    }
                                }
                              
                            }
                            
                        }
                    }
                }
            }
        }
    }

    &__search-control {
        color: $text-dark-grey;
        font-family: 'Lato-Regular';
        font-size: 36px;
        letter-spacing: 0;
        line-height: 36px;
        padding-bottom: 25px;
        @include breakpoint(tab-max){
            padding-bottom: 15px;
        }
    }

    &__close-button {
        img {
            height: 2pc;
            width: 2pc;
        }

        @include breakpoint (mobile) {
            bottom: 22.5px;

            img {
                height: 21px;
                width: 21.21px;
            }
        }
    }

    &__showing {
        @include breakpoint(desktop-small){
            padding-top: 2px;
        }
        @include breakpoint(desktop-large){
            padding-top: 4px;
        }
        color: $text-medium-dark-grey;
        font-family: $lato-regular !important;
        font-size: 14px !important;
        letter-spacing: normal !important;
        line-height: 20px !important;
        margin-bottom: 0;

        &.--no-results {
            color: $text-medium-dark-grey;
        }

        * {
            display: block;
            font-family: 'Lato-Regular';
            font-size: 14px;
            font-weight: 300;
            letter-spacing: normal;
            line-height: 20px;
            color: $text-medium-dark-grey;
            max-width: 290px;
            margin-bottom: 0;
        }
    }

    &__tab-button {
        color: $text-dark-grey;
        font-family: $font-lato;
        font-size: 14px;
        letter-spacing: 2.33px;
        line-height: 17px;
        text-align: center;
        padding: 18px 30px;

        &.--active {
            border-bottom: 2px solid $color-light-blue;
            color: $text-dark-grey;
        }
    }

    &__header {
        background-color: $white;
        margin: 0px 110px;
        @include breakpoint(desktop-small){
            margin: 0;
            padding-top: 45px;
            .kf-react-plp-container{
                margin: 0 auto;
            }
        }
        @include breakpoint(desktop-large){
            padding-top: 53px;
        }
        @include breakpoint(tab-max){
            padding-top: 32px;
        }
    }

    &__tab-header {
        background-color: $white;
        @include breakpoint(desktop-small){
            padding-top: 120px;
        }
        @include breakpoint(tab-max){
            padding-top: 60px;
        }
    }

    &__tab-content {
        background-color: $cool-light-grey;
        .product-listing{
              &__sticky-nav{
                @include breakpoint(tab){
                  flex-direction: column;
                }
                @include breakpoint(mobile){
                  flex-direction: column;
                }
              } 
            }
          

    }

    &__no-result {
        margin-top: 95px;
        @include breakpoint(tab-max){
            margin-top: 50px;
        }
        .search-side-panel{
            &__title{
                @include text-style(12px, 16px, 2px);
                margin-bottom: 50px;
            }
            &__suggestion-list{
                li{
                    margin-bottom: 15px;
                    a{
                        color: inherit;
                    }
                }
            }
        }
        .search-result{
            &__suggestions-title{
                @include text-style(12px, 16px, 2px);
                margin-bottom: 30px;
            }
            &__suggestions-list{
                @include breakpoint(tab-max){
                    margin-bottom: 20px;
                }
                @include breakpoint(mobile-portrait){
                    margin-bottom: 15px;
                }
                p{
                    @include text-style(14px, 20px, 0px);
                    font-family: 'Lato-Regular';
                    color: $text-medium-dark-grey;
                }
            }
        }
    }

    .product-list__controls {

        background-color: $cool-light-grey;

        .control-left-section {
            margin-left: 135px;

            .product-list__filter-toggle-text {
                color: $text-dark-grey;
                font-family: $font-lato;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }
        }

        .control-right-section {
            .products-per-page {
                display: none;
            }

            .sort {
                &__toggle {
                    box-sizing: border-box;
                    height: 52px;
                    width: 154px;
                    border: 1px solid $text-dark-grey;
                    border-radius: 3px;
                    color: $text-medium-dark-grey;
                    font-family: $font-lato;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;
                    padding: 17px 30px;
                    margin-right: 100px;
                }
            }
        }

        .products-per-page {
            display: none;
        }
    }

    .product-list__filter {

        .filter {
            &__print-and-share {
                color: $text-medium-dark-grey;
                font-family: $font-lato;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }
        }
    }

    .product-tile {
        width: 100%;

        &__price {
            margin-right: 20px;
        }
        .product-tile__image {
            background-color: $white;
            @include breakpoint(tab-max){
                margin-bottom: 10px; 
            }
        }
    }

    .product-list__tiles {
        .row {
            .col {
                background-color: $cool-light-grey;
            }

        }
    }

    .product-list__tile-col {
      

        @include breakpoint(tab) {
            padding-left: 0;
        }

        @include breakpoint(mobile) {
            padding-left: 0;
        }
    }

    &__suggestions-title {
        font-family: 'Lato-Regular';
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        color: $color-dark-gray;
        margin-bottom: 30px;
        color: $text-medium-dark-grey-small;
    }

    &__suggestions-list.wow.animate__fadeInUp li {
        width: 247px;
        color: $text-medium-dark-grey-small;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Lato-Regular';
        letter-spacing: 0.1px;
    }

    &__topics-list.wow.animate__fadeInUp p {
        letter-spacing: .1px;
        font-size: 18px;
        line-height: 28px;
        font-family: 'Lato-Regular';
    }

    &__topics-list.wow.animate__fadeInUp p a {
        color: $text-medium-dark-grey-small;
    }

    &__breadcrumb{
        @include breakpoint(desktop-small){
            margin-top: 35px;
        }
        @include breakpoint(tab-max){
            margin-top: 20px;
            margin-bottom: 0;
        }
        nav ol li a{
            @include text-style(13px, 20px, 0.2px);
            color: $text-light-grey;
            font-family: 'Lato-Regular';
        }

        &-header{
            background-color: $white;
            &.\--no-results{
                background-color: $white;
            }
        }
    }

    &__search-field {
        width: 100%;
      }

    &__search-header{
        @include breakpoint(desktop-small){
            gap: 90px;
        }
        @include breakpoint(desktop-small){
            gap: 105px;
        } 
    }

    &__description{
        max-width: 163px;
        @include breakpoint(tab-max){
            max-width: none;
        }
    }
    .product-list-tile{
        &__compare{
            @include breakpoint(tab-max){
                display: flex;
            }
            @include breakpoint(mobile){
                display: none;
            }
            .checkbox_default{
                font-family: 'Lato-Regular';
                font-size: 14px;
            }
        }
        
        &__title,&__description,&__price{
        font-family: 'Lato-Regular';
        }
        &__title {
            span {
            color: $text-dark-grey;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: normal;
            }
        }
        &__description {
             span {
            color: $text-medium-dark-grey;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: normal;
            }
        }
        &__product-sku {
            color: $text-medium-dark-grey;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: normal;
        }
        &__price {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: normal;
            color: $text-dark-grey;
        }
        &__colors--swatch{
            img{
              border-radius: 0;
            }
        }
        &__right-controls{
            .product-list-tile__compare{
                .checkbox__border{
                    border-radius: 5px;
                }
                .checkbox__border, .checkbox__marker{
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .product-listing{
        &__tile{
            @include breakpoint(desktop-extra-sm){
                margin-bottom: 40px;
            }
        }
        .Collapsible.is-open{
            .Collapsible__contentInner{
                .filter-list{
                    input:checked~span .checkbox__marker{
                        border-radius: 0;
                        width: 16px;
                        height: 16px;
                        left: -1px;
                    }
                    li{
                        display: flex;
                        align-items: end;
                        .checkbox{
                            align-items: inherit;
                        }
                    }
                }
            }
        }
    }
    &__no-result-column{
        @include breakpoint(tab-max){
            margin-bottom: 60px;
        }
        @include breakpoint(mobile-portrait){
            margin-bottom: 65px;
        }
    }
  }

.search-side-panel__title {
  letter-spacing: 1px;
  margin-bottom: 3pc;
  text-transform: uppercase;
}

@media screen and (max-width: 860px) {
  .search-result .container-fluid .row .col {
      background: none;
  }

  .search-result .product-list__tiles .row .col {
      margin: 0px;
      padding-right: 0px;
      padding-left: 0px;
      min-width: 49%;
  }

  div.search-result {
      &__sticky-search {
          min-width: 400px;
          
          .sticky {
              left: 0px;
              width: 100%;
          }
      }

      &__header {
          margin: 0px;
      }

      .product-list__tiles {
          margin-top: 45px;

          .product-tile {
              width: 364px;
              margin-left: 20px;
              margin-top: 20px;

              .product-tile__image {
                  width: 100%;
              }

              .product-tile__price {
                  height: 38px;
                  margin-right: 25px;
                  width: 100%;
              }

              .product-tile__title {
                  width: 180px;
              }
          }
      }

      .product-list__sticky-nav {
          background: none;
      }

      .product-list__controls {
          .control-left-section {
              margin-left: 35px;
          }

          .control-right-section {
              .search-result__sticky-search {
                  width: 768px;
                  position: relative;
                  top: 65px;
                  right: 147px;
              }

              .products-per-page {
                  display: none;
              }

              .sort {
                  &__toggle {
                      margin-right: 25px;
                  }
              }
          }
      }

      &__no-result {
          &-column {
              margin-bottom: 40px;

              &:last-child {
                  margin-bottom: 0;
              }
          }
      }

      .row-cols-2 {
          .product-list__tile {
              margin-bottom: 0;
          }
      }

      .product-list__filter--modal {
          margin-left: 0px;
          background: $cool-light-grey;
      }

      .filter__mobile-header {
          display: flex;
          margin: 0px;
          @include breakpoint(tab) {
              margin: 18px 0 28px;
          }
      }

      .filter__footer {
          background: none;
          position: relative;
          button{
              @include text-style (12px, 24px, 2.5px);
              font-family: $lato-bold;
              text-transform: uppercase;
              height:50px
          }
      }

      .Collapsible__trigger {
          padding: 15px 0;
      }
  }
}

@media only screen and (max-width: 767px) {
  .search-result .product-list__tiles .row .col {
      margin: 30px 45px;
      margin-left: 0px;
  }

  .search-result {
      .product-list__sticky-nav {
          background: none;
      }

      .product-list__tiles {
          margin-top: 45px;

          .product-tile {
              width: 225px;

              .product-tile__details {
                  display: block;
              }

              .product-tile__image {
                  width: 100%;
              }

              .product-tile__price {
                  height: 38px;
                  margin-right: 25px;
                  width: 100%;
              }

              .product-tile__title {
                  width: 180px;
              }
          }
      }

      &__header {
          margin: 0px;
      }

      &__suggestions-list {
          margin-left: 40px;
      }

      .search-side-panel {
          &__suggestions-list {
              margin-left: 40px;
          }
      }
  }
}

@media screen and (max-width: 480px) {
  .search-result .product-list__tiles .row .col {
      margin: 0px;
  }

  .search-result .container-fluid .row .col {
      background: none;
  }

  .search-result {

      .product-list__tiles {
          margin-top: 45px;

          .product-tile {
              width: 140px;

              .product-tile__title {
                  width: 140px;
              }
          }

          &__tile {
              padding: 0 0 0 20px;

              &-col {
                  padding-left: 61px;
              }
          }
      }

      .product-list__filter--modal {
          margin-left: 0px;
          background: $cool-light-grey;
      }

      .filter__mobile-header {
          display: flex;
      }

      .filter__footer {
          background: none;
      }

      .Collapsible__trigger {
          padding: 15px 0;
      }
  }
}

.search-result__tab-content {
  .products-tab {
      .product-list__sticky-nav-wrap {
          .product-list__sticky-nav {
              background: none;
          }
      }
  }
}

.container {
  &.product-list__container {
      .row {
          .col {
              background: $cool-light-grey;
          }
      }
  }
}

@media screen and (max-width: 560px) {
  .product-tile__image {
      width: 100%;
      position: relative;
  }
}

@media print {
  .search-result__breadcrumb {
      display: none;
  }
}

.search-side-panel {
  &__search-filed {
      margin-top: 22px;
  }
  &__container {
      .dynamic-search-close-icon {
          width: 21px;
          height:21px;
          top: 104px;
          right:40px;
      }
      .inner-scroller-section {
          .highlight-text {
              font-family: $playfair-display-regular;
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.75px;
              text-align: left;
              font-weight: normal;
          }
      }
  }
}
.search-result {
  &__tab-button {
      font-family: 'Lato-Regular';
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 2.33px;
      text-align: center;
      color: $text-light-grey;
      text-transform: uppercase;
  }
  &.product-listing {
      .search-side-panel {
          &__inspiration-tile-wrppper {
              flex: 0 0 100% !important;
          }
      }
  }
  &__search-wrap{
      @include breakpoint(tab-max){
          height: 65px;
          background-color: $cool-light-grey;
      } 
      img {
          height: 17.96px;
          width: 17.96px;
          color: $color-light-blue;
      }
      span:last-child img{
          height: 21px;
          width: 21px;
          color: $text-medium-dark-grey;
      }
      input{
          @include breakpoint(tab-max){
              font-family: 'Lato-Regular';
              font-size: 16px;
              line-height: 24px;
              letter-spacing: normal;
              text-align: left;
              color: $text-medium-dark-grey;
              margin-top: 23px;
              margin-bottom: 18px;

          }
      }
  }
  .product-listing{
      &__controls{
          margin: 0;
          justify-content: flex-start;
          .sorting-section{
              width: 100%;
              justify-content: flex-end;
              @include breakpoint(mobile){
                  justify-content: flex-end !important;
              }
              .sort{
                  display: flex;
                  @include breakpoint(tab-max){
                      display: none;
                  }
              }
          }
          &.filter__mobile-header{
              @include breakpoint(tab-max){
                  margin: 18px 0 30px;
                  padding: 0;
                  height: unset;
                  justify-content: space-between;
                  .product-listing{
                      &__filter-toggle {
                          span{
                              @include text-style(16px, 24px, 0px);
                              font-family: 'Lato-Regular';
                              color: $text-dark-grey;
                          }
                      }
                  }
              }
          }
          .product-listing{
              &__total{
                  @include text-style(22px, 32px, 0px);
                  font-family: 'Lato-Regular';
                  color: $text-dark-grey;
                  min-width: 237px;
                  max-height: 32px;
                  margin-bottom: 0px;
                  @include breakpoint(tab-max){
                      @include text-style(18px, 28px, 0px);
                  }
                  @include breakpoint(mobile-portrait){
                      min-width: 154px;
                  }
                  
                  &__non-sticky{
                      @include text-style(22px, 32px, 0px);
                      font-family: 'Lato-Regular';
                      color: $text-dark-grey;
                      min-width: 237px;
                      max-height: 32px;
                      margin-bottom: 0px;
                      @include breakpoint(tab-max){
                          @include text-style(18px, 28px, 0px);
                      }
                      @include breakpoint(mobile-portrait){
                          min-width: 160px;
                      }
                  }
              }
          }
      }
      .sort{
          &__toggle{
              @include text-style(18px, 28px, 0px);
          }
      }
      
      @include breakpoint(desktop-small){  
          &__sticky-nav{
              background-color: $cool-light-grey;
              &-wrap{
                  margin: 80px 0 30px 0;
              }
          }
      }
      @include breakpoint(desktop-large){ 
          &__sticky-nav{
              flex-direction: column
          }
      }

      &__filter-toggle{
          font-family: $lato-regular;
          @include text-style(18px, 28px, 0px);
          @include breakpoint(tab-max){
              .product-listing{
                  &__filter-toggle{
                      &__filter-and-sort{
                          @include text-style(18px, 28px, 0px);
                          color: $text-dark-grey;
                          font-family: 'Lato-Regular';
                      }
                  }
              }
              span{
                  display: flex;
              }
          }
      }
      &__grid-control{
          @include breakpoint(mobile){
              display: none;
          }
      }
      &__grid-control-icon{
          margin: 0;
      }
      &__row{
          &.row-cols-3{
              @include breakpoint(desktop-large){
                  margin-right: -27.9px;
              }
          }
          &.row-cols-2{
              @include breakpoint(desktop-small){
                  margin-right: -29px;
              }
              @include breakpoint(tab){
                  margin-right: 0;
                  justify-content: space-between;
              }
              @include breakpoint(mobile){
                  margin-right: -20px;
              }
          }
      }
      &__tile{
          @include breakpoint(desktop-small){
              padding-right: 29px;
          }
          @include breakpoint(desktop-large){
              padding-right: 27.9px;
          }
          .product-tile{
              &__image{
                  @include breakpoint(desktop-small){
                      div{
                          height: 448px;
                      }
                  }
                  @include breakpoint(desktop-large){
                      div{
                          height: 648px;
                      }
                  }
                  @include breakpoint(tab-max){
                      div{
                          height: 474px;
                      }
                  }
                  @include breakpoint(mobile-portrait){
                      div{
                          height: 216px;
                      }
                  }
              }
          }
      }
      .kf-react-plp-container{
          .row-cols-2{
              .product-listing{
                  &__tile{
                      @include breakpoint(tab-max){
                          padding-left: 0px;
                          
                      }
                      @include breakpoint(mobile-portrait){
                          padding-right: 10px;
                      }
                      @include breakpoint (tab) {
                          padding: 0;
                          max-width: 48.66%;
                      }
                  }
              }
          }
      } 
      .sticky{
          .product-listing{
              &__sticky-nav{
                  @include breakpoint(tab-max){
                      padding: 0;
                      &--shadow{
                          .kf-react-plp-container{
                              padding: 11px 0;
                              @include breakpoint(tab){
                                  padding: 10px 0;
                              }
                              @include breakpoint(mobile){
                                  padding: 10px 0;
                              }
                              .product-listing{
                                  &__filter-toggle-icon{
                                      @include breakpoint(tab){
                                          margin: 18px 0px 18.81px 0px
                                      }
                                      @include breakpoint(mobile){
                                          margin: 18px 0px 18.81px 0px
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      .product-listing{
          &__sticky-nav{
              &.false{
                  @include breakpoint(tab-max){
                      padding: 30px 0 17px 0;
                  }
                  .search-result__search-flex{
                      .kf-react-plp-container{
                          @include breakpoint(tab){
                              max-width: 99%;
                          }
                      }
                  }
              }
          }
      }
      &__back-to-top{
          @include breakpoint(tab-max){
              padding: 0;
          }
           .products-per-page{
              ul{
                  li{
                      @include breakpoint(desktop-large){
                          font-family: 'Lato-Regular';
                          font-size: 12px;
                          letter-spacing: 2px;
                          line-height: 16px;
                          text-align: left;
                          color: $text-medium-dark-grey;
                      }
                  }
              }
          }
          @include breakpoint(tab){
              margin-bottom: 60px;
          }
         
      }
  }
  .filter{
      &__print-and-share{
          @include breakpoint(desktop-small){
              padding-top: 45px;
          }
      }
      .Collapsible{
          &__trigger {
              @include breakpoint(tab-max){
                  font-size: 14px;
                  letter-spacing: 2px;
                  line-height: 16px;
                  margin: 14.5px 0;
              }
          }
      }
  }
  .product-list{
      &-tile__compare{
          .checkbox{
              margin-top: 0;
          }
      }
      &__load-more{
          @include breakpoint(tab-max){
              margin-bottom: 33.5px;
          }
          &:hover{
              color: $color-light-blue; 
              transform: scale(0.93);
          }
          @include breakpoint(tab-max){
              font-family: $lato-bold;
              font-size: 11px;
              line-height: 10px;
              letter-spacing: 2.5px;
              text-align: center;
              color: $color-medium-blue;
              border: 1px solid $color-medium-blue;
              border-radius: 1px;
              margin: 30px 0px 33.5px;
          }
      }
  }
}
.inspiration-tile__left-section-container {
  @include breakpoint(tab){
      gap: 34px;
  }
}
.inner-scroller-section .search-side-panel__result-container .search-side-panel__title {
  @include breakpoint(tab-max){
      margin-top: 36.65px;
      margin-bottom: 24.50px;
  }
}

.searchbar-open{
  .search-side-panel__inspiration-tile-wrppper{
      width: 100%;
  }
}
.checkbox__border {
  width: 15.59px;
  height: 15.59px;
  border-radius: unset;
  border: 1px solid $cool-dark-grey-cap;
  .checkbox__marker {
      border-radius: unset;
      .checkbox__marker-icon {
          width: 9.13px;
          height: 7.42px;
      }
  }
}
input:checked~span .checkbox__marker {
  border-radius: 5px;
  left: -0.5px;
  top: -0.5px;
  animation: zoomIn .25s ease-out;
}
.search-result {
  .filter {
      .Collapsible__trigger {
          div {
              .plus {
                  width: 15.16px;
                  height: 15.16px;
              }
          }
      }
  }
}
//Loadmore back to top button
.product-listing__row {
  .product-listing__back-to-top {
      .product-listing__go-back {
          flex-direction: row-reverse;

          img {
              margin-left: 0;
          }

          .product-listing__go-back-text {
              margin-left: 15px;
              color: $text-medium-dark-grey;
          }
      }
  }
  @include breakpoint (desktop-large) {
      .search-result__sticky-search {
          span:first-child {
              img {
                  width: 17.96px;
                  height: 17.96px;
              }
          }
          span:last-child {
              img {
                  width: 21px;
                  height: 21px;
              }
          }
      }
  }
}

.product-listing__go-back-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  color: $text-medium-dark-grey;
}
.search-result .filter__footer button {
  @include breakpoint(tab){
      margin: 25px 0;
  }
}
.product-listing__close-icon {
  width: 21px !important;
  height: 21px !important;
}
.product-list__filter--modal {
  .product-list__filter--sticky {
      .filter {
          &__inner-container {
              .product-list__controls {
                  .product-list__filter-toggle {
                      img {
                          display: none;
                      }
                  }
              }
          }
      }
  }
}

.search-result .filter {
  .collapsible-outer{
      &:first-child{
          margin-top: 20px;
        }
  }
  &__inner-container {
      @include breakpoint(desktop-extra-lite){
          width: auto !important;
      }
  }
}

div.search-result {
  &__sticky-search {
      @include breakpoint (desktop-large) {
          background-color: $cool-light-grey;
        }
  
  input {
      @include breakpoint (desktop-large) {
          font-family: 'Lato-Regular';
          font-size: 16px;
          letter-spacing: 0px;
          color: $text-medium-dark-grey;
      }
  }
  
  }
  .product-listing{
      &__tile{
          .product-tile{
                  &__badge{
                  position: absolute;
                  left: 20px;
                  top: 20px;
                  padding-top: 0;
                  border: 1px solid $tag-border-color;
                  }
              &__new, &__offer{
                  color: $color-light-blue;
                  font-family: $lato-bold;
                  font-size: 9px;
                  font-weight: normal;
                  letter-spacing: 1.64px;
                  line-height: 16px;
                  text-align: center;
                  text-transform: uppercase;
                  background-color: unset !important;
              }
          }
      }
  }
  .filter__footer{
      @include breakpoint(tab-max){
          position: fixed;
          bottom:0;
          background: $white;
      }
  }
}
.product-listing{
  .product-listing__row{
      .products-per-page{
          ul{
              li{
                  font-family: 'Lato-Regular';
                  font-size: 12px;
                  color: $text-dark-grey;
              }
          }
      }
  }
}
.search-result {
  .product-list__load-more {
      text-transform: uppercase;
      &:hover {
          color: $color-light-blue !important;
      }
  }
  .product-listing{
      &__col{
          .search-result{
              &__header{
                  .search-result{
                      &__instead{
                          @include breakpoint(tab-max){
                              font-family: 'Lato-Regular';
                              font-size: .875rem;
                              color: $text-medium-dark-grey;
                          }
                      }
                  }
              }
          }
      }
  }
}
.product-listing {
  &__row {
      .products-per-page {
          ul {
              li {
                  color: $charcoal;
              }
          }
      }
  }
}
.search-side-panel{
  &__show-container{
      .inner-scroller-section{
          .search-side-panel{
              &__content--result{
                  .search-result{
                      @include breakpoint(desktop-large){
                          margin-right: 0 !important;
                      }
                      @include breakpoint(tab-max){
                          margin-right: 0 !important;
                      }
                      .product-listing{
                          &__tile-col--full-width{
                              .product-listing__row{
                                  @include breakpoint(tab){
                                      margin-right: 0;
                                      display: flex;
                                      justify-content: space-between;
                                  }
                                  .product-listing__tile{
                                      @include breakpoint(tab){
                                          padding-right: 0;
                                          flex: 0 0 48.92%;
                                          max-width: 448.92%;
                                      }
                                      &.promo-col-1{
                                          @include breakpoint(tab) {
                                              padding-right: 0;
                                              flex: 0 0 48.92%;
                                              max-width: 448.92%;
                                          }
                                      }
                                      .product-tile__image{
                                          @include breakpoint(tab){
                                              margin-bottom: 10px;
                                          }
                                      }
                                  }
                              }  
                          }
                      }
                  }
              }
          }
      }
  }
}

.search-side-panel__title {
  letter-spacing: 1px;
  margin-bottom: 3pc;
  text-transform: uppercase;
}

@media screen and (max-width: 860px) {
  .search-result .container-fluid .row .col {
      background: none;
  }

  .search-result .product-list__tiles .row .col {
      margin: 0px;
      padding-right: 0px;
      padding-left: 0px;
      min-width: 49%;
  }

  div.search-result {
      &__sticky-search {
          min-width: 400px;
          
          .sticky {
              left: 0px;
              width: 100%;
          }
      }

      &__header {
          margin: 0px;
      }

      .product-list__tiles {
          margin-top: 45px;

          .product-tile {
              width: 364px;
              margin-left: 20px;
              margin-top: 20px;

              .product-tile__image {
                  width: 100%;
              }

              .product-tile__price {
                  height: 38px;
                  margin-right: 25px;
                  width: 100%;
              }

              .product-tile__title {
                  width: 180px;
              }
          }
      }

      .product-list__sticky-nav {
          background: none;
      }

      .product-list__controls {
          .control-left-section {
              margin-left: 35px;
          }

          .control-right-section {
              .search-result__sticky-search {
                  width: 768px;
                  position: relative;
                  top: 65px;
                  right: 147px;
              }

              .products-per-page {
                  display: none;
              }

              .sort {
                  &__toggle {
                      margin-right: 25px;
                  }
              }
          }
      }

      &__no-result {
          &-column {
              margin-bottom: 40px;

              &:last-child {
                  margin-bottom: 0;
              }
          }
      }

      .row-cols-2 {
          .product-list__tile {
              margin-bottom: 0;
          }
      }

      .product-list__filter--modal {
          margin-left: 0px;
          background: $cool-light-grey;
      }

      .filter__mobile-header {
          display: flex;
          margin: 0px;
          @include breakpoint(tab) {
              margin: 18px 0 28px;
          }
      }

      .filter__footer {
          background: none;
          position: relative;
          button{
              @include text-style (12px, 24px, 2.5px);
              font-family: $lato-bold;
              text-transform: uppercase;
              height:50px
          }
      }

      .Collapsible__trigger {
          padding: 15px 0;
      }
  }
}

@media only screen and (max-width: 767px) {
  .search-result .product-list__tiles .row .col {
      margin: 30px 45px;
      margin-left: 0px;
  }

  .search-result {
      .product-list__sticky-nav {
          background: none;
      }

      .product-list__tiles {
          margin-top: 45px;

          .product-tile {
              width: 225px;

              .product-tile__details {
                  display: block;
              }

              .product-tile__image {
                  width: 100%;
              }

              .product-tile__price {
                  height: 38px;
                  margin-right: 25px;
                  width: 100%;
              }

              .product-tile__title {
                  width: 180px;
              }
          }
      }

      &__header {
          margin: 0px;
      }

      &__suggestions-list {
          margin-left: 40px;
      }

      .search-side-panel {
          &__suggestions-list {
              margin-left: 40px;
          }
      }
  }
}

@media screen and (max-width: 480px) {
  .search-result .product-list__tiles .row .col {
      margin: 0px;
  }

  .search-result .container-fluid .row .col {
      background: none;
  }

  .search-result {

      .product-list__tiles {
          margin-top: 45px;

          .product-tile {
              width: 140px;

              .product-tile__title {
                  width: 140px;
              }
          }

          &__tile {
              padding: 0 0 0 20px;

              &-col {
                  padding-left: 61px;
              }
          }
      }

      .product-list__filter--modal {
          margin-left: 0px;
          background: $cool-light-grey;
      }

      .filter__mobile-header {
          display: flex;
      }

      .filter__footer {
          background: none;
      }

      .Collapsible__trigger {
          padding: 15px 0;
      }
  }
}

.search-result__tab-content {
  .products-tab {
      .product-list__sticky-nav-wrap {
          .product-list__sticky-nav {
              background: none;
          }
      }
  }
}

.container {
  &.product-list__container {
      .row {
          .col {
              background: $cool-light-grey;
          }
      }
  }
}

@media screen and (max-width: 560px) {
  .product-tile__image {
      width: 100%;
      position: relative;
  }
}

@media print {
  .search-result__breadcrumb {
      display: none;
  }
}

.search-side-panel {
  &__search-filed {
      margin-top: 22px;
  }
  &__container {
      .dynamic-search-close-icon {
          width: 21px;
          height:21px;
          top: 104px;
          right:40px;
      }
      .inner-scroller-section {
          .highlight-text {
              font-family: $playfair-display-regular;
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.75px;
              text-align: left;
              font-weight: normal;
          }
      }
  }
}
.search-result {
  &__tab-button {
      font-family: 'Lato-Regular';
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 2.33px;
      text-align: center;
      color: $text-light-grey;
      text-transform: uppercase;
  }
  &.product-listing {
      .search-side-panel {
          &__inspiration-tile-wrppper {
              flex: 0 0 100% !important;
          }
      }
  }
  &__search-wrap{
      @include breakpoint(tab-max){
          height: 65px;
          background-color: $cool-light-grey;
      } 
      img {
          height: 17.96px;
          width: 17.96px;
          color: $color-light-blue;
      }
      span:last-child img{
          height: 21px;
          width: 21px;
          color: $text-medium-dark-grey;
      }
      input{
          @include breakpoint(tab-max){
              font-family: 'Lato-Regular';
              font-size: 16px;
              line-height: 24px;
              letter-spacing: normal;
              text-align: left;
              color: $text-medium-dark-grey;
              margin-top: 23px;
              margin-bottom: 18px;

          }
      }
  }
  .product-listing{
      &__controls{
          margin: 0;
          justify-content: flex-start;
          .sorting-section{
              width: 100%;
              justify-content: flex-end;
              @include breakpoint(mobile){
                  justify-content: flex-end !important;
              }
              .sort{
                  display: flex;
                  @include breakpoint(tab-max){
                      display: none;
                  }
              }
          }
          &.filter__mobile-header{
              @include breakpoint(tab-max){
                  margin: 18px 0 30px;
                  padding: 0;
                  height: unset;
                  justify-content: space-between;
                  .product-listing{
                      &__filter-toggle {
                          span{
                              @include text-style(16px, 24px, 0px);
                              font-family: 'Lato-Regular';
                              color: $text-dark-grey;
                          }
                      }
                  }
              }
          }
          .product-listing{
              &__total{
                  @include text-style(22px, 32px, 0px);
                  font-family: 'Lato-Regular';
                  color: $text-dark-grey;
                  min-width: 237px;
                  max-height: 32px;
                  margin-bottom: 0px;
                  @include breakpoint(tab-max){
                      @include text-style(18px, 28px, 0px);
                  }
                  @include breakpoint(mobile-portrait){
                      min-width: 154px;
                  }
                  
                  &__non-sticky{
                      @include text-style(22px, 32px, 0px);
                      font-family: 'Lato-Regular';
                      color: $text-dark-grey;
                      min-width: 237px;
                      max-height: 32px;
                      margin-bottom: 0px;
                      @include breakpoint(tab-max){
                          @include text-style(18px, 28px, 0px);
                      }
                      @include breakpoint(mobile-portrait){
                          min-width: 160px;
                      }
                  }
              }
          }
      }
      .sort{
          &__toggle{
            font-family: $lato-regular;
            @include text-style(18px, 28px, 0px);
          }
      }
      
      @include breakpoint(desktop-small){  
          &__sticky-nav{
              background-color: $cool-light-grey;
              &-wrap{
                  margin: 80px 0 30px 0;
                  z-index: auto;
              }
          }
      }
      @include breakpoint(desktop-large){ 
          &__sticky-nav{
              flex-direction: column
          }
      }

      &__filter-toggle{
          @include text-style(18px, 28px, 0px);
          @include breakpoint(tab-max){
              .product-listing{
                  &__filter-toggle{
                      &__filter-and-sort{
                          @include text-style(18px, 28px, 0px);
                          color: $text-dark-grey;
                          font-family: 'Lato-Regular';
                      }
                  }
              }
              span{
                  display: flex;
              }
          }
      }
      &__grid-control{
          @include breakpoint(mobile){
              display: none;
          }
      }
      &__grid-control-icon{
          margin: 0;
      }
      &__row{
          &.row-cols-3{
              @include breakpoint(desktop-large){
                  margin-right: -27.9px;
              }
          }
          &.row-cols-2{
              @include breakpoint(desktop-small){
                  margin-right: -29px;
              }
              @include breakpoint(tab){
                  margin-right: 0;
                  justify-content: space-between;
              }
              @include breakpoint(mobile){
                  margin-right: -20px;
              }
          }
      }
      &__tile{
          @include breakpoint(desktop-small){
              padding-right: 29px;
          }
          @include breakpoint(desktop-large){
              padding-right: 27.9px;
          }
          .product-tile{
              &__image{
                  @include breakpoint(desktop-small){
                      div{
                          height: 448px;
                      }
                  }
                  @include breakpoint(desktop-large){
                      div{
                          height: 648px;
                      }
                  }
                  @include breakpoint(tab-max){
                      div{
                          height: 474px;
                      }
                  }
                  @include breakpoint(mobile-portrait){
                      div{
                          height: 216px;
                      }
                  }
              }
          }
      }
      .kf-react-plp-container{
          .row-cols-2{
              .product-listing{
                  &__tile{
                      @include breakpoint(tab-max){
                          padding-left: 0px;
                          
                      }
                      @include breakpoint(mobile-portrait){
                          padding-right: 10px;
                      }
                      @include breakpoint (tab) {
                          padding: 0;
                          max-width: 48.66%;
                      }
                  }
              }
          }
      } 
      .sticky{
          .product-listing{
              &__sticky-nav{
                  @include breakpoint(tab-max){
                      padding: 0;
                      &--shadow{
                          .kf-react-plp-container{
                              padding: 11px 0;
                              @include breakpoint(tab){
                                  padding: 10px 0;
                              }
                              @include breakpoint(mobile){
                                  padding: 10px 0;
                              }
                              .product-listing{
                                  &__filter-toggle-icon{
                                      @include breakpoint(tab){
                                          margin: 18px 0px 18.81px 0px
                                      }
                                      @include breakpoint(mobile){
                                          margin: 18px 0px 18.81px 0px
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      .product-listing{
          &__sticky-nav{
              &.false{
                  @include breakpoint(tab-max){
                      padding: 30px 0 17px 0;
                  }
                  .search-result__search-flex{
                      .kf-react-plp-container{
                          @include breakpoint(tab){
                              max-width: 99%;
                          }
                      }
                  }
              }
          }
      }
      &__back-to-top{
          @include breakpoint(tab-max){
              padding: 0;
          }
           .products-per-page{
              ul{
                  li{
                      @include breakpoint(desktop-large){
                          font-family: 'Lato-Regular';
                          font-size: 12px;
                          letter-spacing: 2px;
                          line-height: 16px;
                          text-align: left;
                          color: $text-medium-dark-grey;
                      }
                  }
              }
          }
          @include breakpoint(tab){
              margin-bottom: 60px;
          }
         
      }
  }
  .filter{
      &__print-and-share{
          @include breakpoint(desktop-small){
              padding-top: 45px;
          }
      }
      .Collapsible{
          &__trigger {
            font-family: $lato-regular;
            padding: 0;
            @include text-style(14px, 16px, 2px);
              @include breakpoint(tab-max){
                  font-size: 14px;
                  letter-spacing: 2px;
                  line-height: 16px;
                  margin: 14.5px 0;
              }
          }
      }
  }
  .product-list{
      &-tile__compare{
          .checkbox{
              margin-top: 0;
          }
      }
      &__load-more{
          @include breakpoint(tab-max){
              margin-bottom: 33.5px;
          }
          &:hover{
              color: $color-light-blue; 
              transform: scale(0.93);
          }
          @include breakpoint(tab-max){
              font-family: $lato-bold;
              font-size: 11px;
              line-height: 10px;
              letter-spacing: 2.5px;
              text-align: center;
              color: $color-medium-blue;
              border: 1px solid $color-medium-blue;
              border-radius: 1px;
              margin: 30px 0px 33.5px;
          }
      }
  }
}
.inspiration-tile__left-section-container {
  @include breakpoint(tab){
      gap: 34px;
  }
}
.inner-scroller-section .search-side-panel__result-container .search-side-panel__title {
  @include breakpoint(tab-max){
      margin-top: 36.65px;
      margin-bottom: 24.50px;
  }
}

.searchbar-open{
  .search-side-panel__inspiration-tile-wrppper{
      width: 100%;
  }
}
.checkbox__border {
  width: 15.59px;
  height: 15.59px;
  border-radius: unset;
  border: 1px solid $cool-dark-grey-cap;
  .checkbox__marker {
      border-radius: unset;
      .checkbox__marker-icon {
          width: 9.13px;
          height: 7.42px;
      }
  }
}
input:checked~span .checkbox__marker {
  border-radius: 5px;
  left: -0.5px;
  top: -0.5px;
  animation: zoomIn .25s ease-out;
}
.search-result {
  .filter {
      .Collapsible__trigger {
          div {
              .plus {
                  width: 15.16px;
                  height: 15.16px;
              }
          }
      }
  }
}
//Loadmore back to top button
.product-listing__row {
  .product-listing__back-to-top {
      .product-listing__go-back {
          flex-direction: row-reverse;

          img {
              margin-left: 0;
          }

          .product-listing__go-back-text {
              margin-left: 15px;
              color: $text-medium-dark-grey;
          }
      }
  }
  @include breakpoint (desktop-large) {
      .search-result__sticky-search {
          span:first-child {
              img {
                  width: 17.96px;
                  height: 17.96px;
              }
          }
          span:last-child {
              img {
                  width: 21px;
                  height: 21px;
              }
          }
      }
  }
}

.product-listing__go-back-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  color: $text-medium-dark-grey;
}
.search-result .filter__footer button {
  @include breakpoint(tab){
      margin: 25px 0;
  }
}
.product-listing__close-icon {
  width: 21px !important;
  height: 21px !important;
}
.product-list__filter--modal {
  .product-list__filter--sticky {
      .filter {
          &__inner-container {
              .product-list__controls {
                  .product-list__filter-toggle {
                      img {
                          display: none;
                      }
                  }
              }
          }
      }
  }
}

.search-result .filter {
  .collapsible-outer{
      &:first-child{
          margin-top: 20px;
        }
  }
  &__inner-container {
      @include breakpoint(desktop-extra-lite){
          width: auto !important;
      }
  }
}

div.search-result {
  &__sticky-search {
      @include breakpoint (desktop-large) {
          background-color: $cool-light-grey;
          left: 253px!important;
        }
  
  input {
      @include breakpoint (desktop-large) {
          font-family: 'Lato-Regular';
          font-size: 16px;
          letter-spacing: 0px;
          color: $text-medium-dark-grey;
      }
  }
  
  }
  .product-listing{
      &__tile{
          .product-tile{
                  &__badge{
                  position: absolute;
                  left: 20px;
                  top: 20px;
                  padding-top: 0;
                  border: 1px solid $tag-border-color;
                  }
              &__new, &__offer{
                  color: $color-light-blue;
                  font-family: $lato-bold;
                  font-size: 9px;
                  font-weight: normal;
                  letter-spacing: 1.64px;
                  line-height: 16px;
                  text-align: center;
                  text-transform: uppercase;
                  background-color: unset !important;
              }
          }
      }
  }
  .filter__footer{
      @include breakpoint(tab-max){
          position: fixed;
          bottom:0;
          background: $white;
      }
  }
}
.product-listing{
  .product-listing__row{
      .products-per-page{
          ul{
              li{
                  font-family: 'Lato-Regular';
                  font-size: 12px;
                  color: $text-dark-grey;
              }
          }
      }
  }
}
.search-result {
  .product-list__load-more {
      text-transform: uppercase;
      &:hover {
          color: $color-light-blue !important;
      }
  }
  .product-listing{
      &__col{
          .search-result{
              &__header{
                  .search-result{
                      &__instead{
                          @include breakpoint(tab-max){
                              font-family: 'Lato-Regular';
                              font-size: .875rem;
                              color: $text-medium-dark-grey;
                          }
                      }
                  }
              }
          }
      }
  }
}
.product-listing {
  &__row {
      .products-per-page {
          ul {
              li {
                  color: $charcoal;
              }
          }
      }
  }
}
.search-side-panel{
  &__show-container{
      .inner-scroller-section{
          .search-side-panel{
              &__content--result{
                  .search-result{
                      @include breakpoint(desktop-large){
                          margin-right: 0 !important;
                      }
                      @include breakpoint(tab-max){
                          margin-right: 0 !important;
                      }
                      .product-listing{
                          &__tile-col--full-width{
                              .product-listing__row{
                                  @include breakpoint(tab){
                                      margin-right: 0;
                                      display: flex;
                                      justify-content: space-between;
                                  }
                                  .product-listing__tile{
                                      @include breakpoint(tab){
                                          padding-right: 0;
                                          flex: 0 0 48.92%;
                                          max-width: 448.92%;
                                      }
                                      &.promo-col-1{
                                          @include breakpoint(tab) {
                                              padding-right: 0;
                                              flex: 0 0 48.92%;
                                              max-width: 448.92%;
                                          }
                                      }
                                      .product-tile__image{
                                          @include breakpoint(tab){
                                              margin-bottom: 10px;
                                          }
                                      }
                                  }
                              }  
                          }
                      }
                  }
              }
          }
      }
  }
}

}
.UtilityWidgets_utilityWidgetsWrapper__UNcYr {
    margin: 0 auto;
    margin-right: 0;
}
.search-result__no-result-column {
.SearchPanel_searchSidePannelWrapper__ixvEx  {
    .search-side-panel__suggestion-list {
        li {
            font-family: $lato-regular;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: normal;
            color: $text-medium-dark-grey;
            }
        }
    } 
}
.InspirationTile_inspirationTileWrapper__0aeUd  {
.inspiration-tile__title {
    .inspiration-tile__category-name {
        font-family: $lato-regular;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $text-dark-grey;
        }
        .inspiration-tile__article-title {
            span {
                font-family: $playfair-display-regular;
                font-size: 22px;
                line-height: 28px;
                letter-spacing: -0.75px;
                color: $text-dark-grey;
            }
        }
    }
    .inspiration-tile__left-section-container__second-coloumn {
        .inspiration-tile__description {
            span {
                font-family: 'Lato-Regular';
                font-size: 14px;
                line-height: 20px;
                letter-spacing: normal;
                color: $text-medium-dark-grey;
            }
        }
    }
}
  .search-result#searchWrapperId{
    div.product-listing__load-more{
        all:unset;
        width: 100%;
    }
    button.product-listing {
        &__load-more {            
            margin: 0 0 40px;
            background-color: $cool-light-grey;
            font-size: 12px;
            font-family: $lato-bold;
            letter-spacing: 2.5px;
            color: $color-medium-blue;
            border-color: $color-light-blue;
            text-transform: uppercase;
            &:hover{
                transform: scale(.93);
                width: 100%;
                height: unset;
                transition: all .3s ease-in-out;
            }
    }
  }

  .product-listing{
    &__tiles{
        margin-bottom: 124px;
    }
    .product-listing__row {
        .products-per-page {
            ul{
                li {
                    text-transform: uppercase;
                    font-family: $lato-regular;
                    font-size: 12px;
                    color: $text-dark-grey;
                    &.disabled{
                        pointer-events: none;
                        text-decoration: underline;
                        text-decoration-thickness: 1px;
                        text-underline-offset: 4.5px;
                        text-decoration-color: $cool-dark-grey;
                        &:first-child {
                            text-decoration: none;
                            letter-spacing: 1.7px;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            } 
        }
        
    }

     .filter{
        .Collapsible__trigger.is-closed{
            margin-top: 14.5px;
            margin-bottom: 14.5px;
        }

        .filter__print-and-share{
            span {
                font-family: $lato-regular;
                font-size: 14px;
                letter-spacing: normal;
                line-height: 20px;
                color: $text-medium-dark-grey;
                text-align: left;
                margin-right: 20px;
            }
        }
        
     } 
  } 
}
  