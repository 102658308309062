.checkbox {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-family: $font-regular;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.55px;
  color: $color-dark-gray;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 15px;
  width: max-content;
  max-width: 100%;

  input {
    visibility: hidden;
    position: absolute;
    transition: all 0.3s ease;
  }

  .checkbox_default{
    color: $color-dark-gray;
  }

  .checkbox_default_text{
    color: $color-dark-gray;
    font-size: 14px;
  }

  input:checked ~ span &__marker {
    background: $color-charcoal;
    border: none;
    animation: zoomIn 0.25s ease-out;
  }

  input:checked ~ &__swatch {
    border: 1px solid $color-charcoal;
    padding: 2px;
    background-clip: content-box;
  }

  input:checked ~ span {
    color: $color-charcoal;
  }

  &__border {
    position: relative;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: 2px;
    border: 1px solid $color-dark-gray;
  }

  &__marker {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    flex-shrink: 0;

    &-icon {
      width: 11px;
      height: 9px;
    }
  }

  &__swatch {
    display: none;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    background-size: cover;
  }

  &--swatch {
    .checkbox__swatch {
      display: flex;
    }
    .checkbox__border {
      display: none;
    }
    .checkbox__marker {
      display: none;
    }
  }

  &--black {
    input:checked ~ span .checkbox__marker {
      background: $color-charcoal;
    }

    input:checked ~ span .checkbox__swatch {
      border: 1px solid $color-charcoal;
    }

    input:checked ~ span {
      color: $color-charcoal;
    }
  }
}

@media screen and (max-width: 767px) {
  .checkbox {
    margin-bottom: 15px;
  }
}