.key-specifier {
    max-width: 420px;
    margin-top: 35px;

    &__header {
        display: flex;
        column-gap: 100px;
    }
    &__block{
        width: 100%;
        margin-bottom: 40px;
        padding: 10px;

        &__close-icon {
            cursor: pointer;
            order: 1;
            float: right;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
    &__title {
        order: 0;
        font-family: $font-light;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.7px;
        color: $color-slate;
    }
    &__sub{
        font-family: $font-medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.4px;
        line-height: 28px;
        margin-bottom: 20px;
        margin-top: 40px;
        color: $color-charcoal;

    }
    &__description {
        margin: 40px 0px 20px 0px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
    }
    &__description1 {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin-top: 40px;
        p{
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
            color: $color-dark-gray;
            a{
                text-decoration: underline;
                font-family: $font-regular;
                font-size: 16px;
                letter-spacing: -0.55px;
                line-height: 24px;
                color: $color-dark-gray;
                &:hover {
                    text-decoration: underline;
                    color: $color-black;
                }
            }
        }
    }
    &__drop{
        margin:20px 0px;
    }
    &__state-zip{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
    }
    &__tnc{
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-error-red;
        padding-top:10px
    }
    &__state{
        width: 50%;
        height: 100%;
        display: inline-block;
        padding-right: 10px;
    }
    &__zip{
        padding-left: 10px;
        display: inline-block;
        width: 50%;
        height: 100%;
    }
    
    &__button{
        margin-top: 40px;
    }
    &__terms{
        padding-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        p{
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
            color: $color-dark-gray;
            a{
                text-decoration: underline;
                font-family: $font-regular;
                font-size: 16px;
                letter-spacing: -0.55px;
                line-height: 24px;
                color: $color-dark-gray;
                &:hover {
                    text-decoration: underline;
                    color: $color-black;
                }
            }
        }
    }
    &__policies{
        display: flex;
        font-family: $font-regular;
        justify-content: space-between;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        text-decoration: underline;
        a {
            margin-right: 20px;
            text-decoration: underline;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
            &:hover {
                text-decoration: underline;
                color: $color-black;
            }
        }
    }
    &__groupcode-section,
    &__business-info-section{
        background-color: $color-off-white;
        margin: 0px -40px;
        padding: 1px 40px 20px 40px;
    }
    &__business-addr-section,
    &__personal-info-section{
        padding-bottom: 20px;
    }
}

.radio-wrapper{
    label{
        margin-bottom: 0px;
        span{
            color: $color-dark-gray;
        }
    }
    input:checked ~ span {
        color: $color-charcoal;
    }
    .radio{
        margin-right: 32px;
        &__value{
            color: $color-dark-gray;
        }
        &__custom{
            width: 22px;
            height: 22px;
            border: 1px solid $color-dark-gray;
            padding: 3px;
        }
        &__custom:after{
            background: $color-charcoal;
        } 
        input:checked + span + span{
            color: $color-charcoal;
        }
    }
}

@media screen and (max-width: 576px){
    .key-specifier{
        &__groupcode-section,
        &__business-info-section{
            margin: 0px -18px;
        }
        &__policies{
            margin-bottom: 90px;
        }
    }
}
@media only screen and (min-width: 1024px) {
.success-message-content {
    width:420px;

  h4 {
    margin-top:35px;
    margin-bottom: 15px; 
  }
  .kf-react-button.large{
    width: 420px;
  }   
}
.success-message-model {
    margin-bottom: 40px;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 24px;
}
}
@media screen and (max-width: 576px) {
    .success-message-content {
        width:375px;
    
      h4 {
        margin-top: 0px;
        margin-bottom: 15px; 
      }
      .kf-react-button.large{
        width: 339px;
      }   
    }
    .success-message-model {
        margin-bottom: 40px;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        width:295px
    }
}
@media only screen and (min-width: 767px) and (max-device-width: 991px) and (orientation: landscape) {
        .success-message-content {
            width: 812px;
            padding: 15px 128px 232px;
        
          h4 {
            margin-bottom: 15px; 
          }
          .kf-react-button.large{
            width: 476px;
          }   
        }
        .success-message-model {
            margin-bottom: 40px;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
            width:420px;
        }
    }