@mixin text-style ($fs, $lh, $ls){
  font-size: $fs;
  line-height: $lh;
  letter-spacing: $ls;
}
.articleCard {
  position: relative;
  text-decoration: none;

  .eyebrow {
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .details {
    height: 210px;
    background-color: $color-light-grey;
    padding: 30px 25px;
    min-height: 210px;
    @media (min-width: 1024px) and (max-width: 1439px) {
      height: 190px;
      min-height: 190px;
    }
    @include breakpoint(tab-max){
      height: 190px;
      min-height: 190px;
    }
    .title {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; 
      letter-spacing: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 20px 0;
      max-width: 390px;
      @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0;
        padding: 10px 0;
      }
      @include breakpoint(tab-max){
        @include text-style(22px, 32px, 0);
        padding: 10px 0;
      }
    }
    .desc {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0; 
    }
  }

  .article-image {
    height: 440px;
    @include breakpoint(xl){
      height: 426px;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      height: 307px;
    }
    @include breakpoint(tab){
      height: 364px;
    }
    @include breakpoint(mobile){
      height: 339px;
    }
    img {
      width: 100%;
      height: 440px;
      object-fit: cover;
      min-height: 440px;
      @include breakpoint(xl){
        height: 426px;
        min-height: 426px;
      }
      @media (min-width: 1024px) and (max-width: 1439px) {
        height: 307px;
        min-height: 307px;
      }
      @include breakpoint(tab){
        height: 364px;
        min-height: 364px;
      }
      @include breakpoint(mobile){
        height: 339px;
        min-height: 339px;
      }
    }
  }
  .cta{
    background-color: $color-light-grey;
    display: block;
    padding: 37px 25px 20px;
    @media (min-width: 1024px) and (max-width: 1439px) {
      padding: 24px 25px 20px;
    }
    @include breakpoint(tab-max){
      padding: 26px 25px 20px;
    }
  }

  .ctaText{
    color: $color-light-blue;
    font-family: $lato-regular;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    letter-spacing: 1.8px;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset:7px
  }
  
}
.article-featured-card {
  &.teaser {
      .cmp-teaser {
          width: 1380px;
          margin: 0 auto;
          display: flex;
          gap: 90px;
          flex-direction: row-reverse;
          align-items: center;
          @include breakpoint (tab) {
              max-width: 46.75rem;
              margin: 0 auto;
              gap: 3.125rem;
          }
          @include breakpoint (mobile) {
              width: 21.1875rem;
              flex-direction: column;
              gap: 30px;
              padding: 20px 0 25px;
          }
          .cq-dd-image.cmp-teaser__image {
              @include breakpoint (mobile) {
                  width: 21.1875rem;
              }
          }
          &__content {
              .cmp-teaser__pretitle {
                  h1, h2, h3, h4, h5, h6, p {
                  @include ann-describtion(.75rem, $lato-regular, 1rem);
                  letter-spacing: 2px;
                  text-transform: uppercase;
                  margin-bottom: 10px;
                  color: $text-dark-grey;
                  }
              }
              .cmp-teaser__title {
                  h1, h2, h3, h4, h5, h6, p {
                  @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                  letter-spacing: -1.17px;
                  color: $text-dark-grey;
                  margin-bottom: 20px;
                  width: 38rem;
                  @include breakpoint (tab) {
                      @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                      width: 23.125rem;
                  }
                  @include breakpoint (mobile) {
                      @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                      width: 18.6875rem;
                  }
                  }
              }
              .cmp-teaser__description {
                  h1, h2, h3, h4, h5, h6, p {
                      @include ann-describtion(1rem, $lato-regular, 1.5rem);
                      color: $text-medium-dark-grey;
                      margin-bottom: 3.125rem;
                      width: 38rem;
                      @include breakpoint (tab) {
                          width: 23.125rem;
                      }
                      @include breakpoint (mobile) {
                          width: 18.6875rem;
                      }
                  }
              }
              .cmp-teaser__action-container {
                  .cmp-teaser__action-link  {
                      @include ann-describtion(.6875rem, $lato-regular, 1rem);
                      letter-spacing: 1.8px;
                      text-transform: uppercase;
                  }
              }
          }
          .cmp-teaser__image {
              .cmp-image {
                  &__image {
                      @include breakpoint (desktop-small) {
                          min-width: 42.625rem;
                      }
                  }
              }
          }
      }
  }
}

// Blog Text only hero - teaser version.
.blog-text-hero, .teaser-card-style{
  padding: 0 2.6045%;
  @include breakpoint(desktop-mid){
      padding: 0 2.25rem;
  }
  @include breakpoint(tab){
      padding: 0 32px;
  }
  @include breakpoint(mobile){
      padding: 0 18px;
  }
  .cmp-teaser{
      max-width:1820px;
      margin:0 auto;
      &__content{
          display: flex;
          flex-direction: column;
          padding-bottom: 2.5rem;
          @include breakpoint(desktop-extra-sm){
              padding-bottom: 1.875rem;
          }   
          @include breakpoint(tab-max){
              padding-bottom: .9375rem;
          }
      }
      &__title{
          width: 100%;
          h1, h2, h3, h4, h5, h6, p{
              font-size:3.375rem;
              line-height:3.875rem;
              letter-spacing:-1px;
              font-family:$playfair-regular;
              color:$text-dark-grey;                           
              @include breakpoint(desktop-extra-lite-sm){
                  font-size:36px;
                  line-height:44px;
                  letter-spacing:-2px
              }
          }
          &:only-child{
              margin-bottom:0;
          }
      }
      &__description{ 
          margin-top: .9375rem;  
          @include breakpoint(desktop-extra-sm){
              margin-top: .625rem;  
          }
          @include breakpoint(tab-max){
              margin-top: .625rem;
          }
          h1,h2,h3,h4,h5,h6,p{
              @include text-style(1.375rem, 2rem, normal);
              font-family: $lato-regular;
              color:$text-dark-grey;
              @include breakpoint(desktop-extra-lite-sm){
                  font-size:18px;
                  line-height:28px;
                  letter-spacing:normal
              }
          }
      }
      &__action-container{
          margin-top: 3.75rem;  
          @include breakpoint(desktop-extra-sm){
              margin-top: 3.125rem;  
          }
          @include breakpoint(tab-max){
              margin-top: 1.875rem;
          }
          .cmp-teaser__action-link{
              margin-right: 1.25rem;
              @include breakpoint(desktop-extra-sm){
                   margin-right: .9375rem
              } 
              @include breakpoint(tab-max){
                  margin-right: .9375rem;
                  margin-bottom: .9375rem;
              }
              &.gbh-primary-button{
                  @include ann-describtion(1rem, $lato-regular, 1.5rem); 
                  height: auto;
                  width: auto;
                  font-weight: 400;
                  border-radius: 50px;
                  text-transform: capitalize;
                  padding: .5rem 1.875rem;
                  color: $white;
                  background-color: $slate;
                  border: 1px solid $slate;
                  @include breakpoint(desktop-extra-sm){
                      @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                  }
                  @include breakpoint(tab-max){
                      @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                  }
              }
              &.gbh-secondary-button{
                  height: auto;
                  width: auto;
                  @include ann-describtion(1rem, $lato-regular, 1.5rem);
                  font-weight: 400;
                  border-radius: 50px;
                  text-transform: capitalize;
                  padding: .5rem 1.875rem;
                  color: $slate;
                  background-color: $white;
                  border: 1px solid $slate;
                  @include breakpoint(desktop-extra-sm){
                      @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                  }
                  @include breakpoint(tab-max){
                      @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                  }
              }
          }  
      }
  }
  &.teaser{
      .cmp-teaser{
          &__description{
              p{
                  color:$text-dark-grey;
              }
          }
      }
  }
}