.additional-footer {
    max-width: 1920px;
    padding: 10px 0;
    margin: 0 auto;

    @include breakpoint(desktop-mid) {
        padding: 12px 0;
    }

    @include breakpoint(tab) {
        padding: 22px 0 25px;
    }

    @include breakpoint(mobile) {
        padding: 30px 0;
    }

    dl,
    ol,
    ul {
        margin: 0;

        li {
            list-style: none;

            label {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                border: 0;
            }
        }
    }
}

.ann-sacks-additional-footer {
    background: $color-dark-blue;
    padding: 0 2.6%;

    @include breakpoint(desktop-large) {
        padding: 0 50px;
    }

    @include breakpoint(tab) {
        padding: 0 32px;
    }

    @include breakpoint(mobile) {
        padding: 0 18px;
    }

    .additional-footer {
        .gbh-footer-wrapper {
            .gbh-footer-add-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: $lato-regular;

                @include breakpoint(tab) {
                    flex-direction: column;
                }

                @include breakpoint(mobile) {
                    flex-direction: column;
                }

                .gbh-footer-nav__links {
                    margin: 0;
                    display: flex;

                    @include breakpoint(tab) {
                        margin-bottom: 17px;
                    }

                    @include breakpoint(mobile) {
                        margin-bottom: 20px;
                        flex-direction: column;
                        align-items: center;
                    }

                    li {
                        padding-right: 20px;
                        list-style: none;
                        display: inherit;

                        @include breakpoint(mobile) {
                            padding-right: 0;
                            padding-bottom: 15px;
                            display: inline-block;
                        }

                        &:last-child {
                            padding-right: 0;

                            @include breakpoint(mobile) {
                                padding-bottom: 0;
                            }
                        }

                        a {
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.2px;
                            color: $white;
                            opacity: .7;
                            transition: 0.2s ease-in-out;
                            cursor: pointer;

                            @include breakpoint(desktop-mid) {
                                line-height: 0;
                                letter-spacing: 0;
                            }

                            @include breakpoint(tab) {
                                line-height: 0;
                                letter-spacing: 0;
                            }

                            @include breakpoint(mobile) {
                                line-height: 20px;
                                letter-spacing: 0.2px;
                                height: 20px;
                                display: block;
                            }

                            &:hover {
                                opacity: 1;
                                text-decoration: none;
                            }
                        }
                    }
                }

                .gbh-copy__rights {
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 1.67px;
                    color: $white;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    opacity: .7;
                }
            }
        }
    }
}

.additional-footer-social-link {
    background: $white;

    @include breakpoint(mobile) {
        width: 100%;
        margin-top: 43px;
    }

    .additional-footer {
        padding: 0;
        max-width: 100%;

        .gbh-footer-add-nav {
            background: transparent;
            color: inherit;

            .gbh-footer-add-panel {
                @include breakpoint(mobile) {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                }

                .gbh-footer-nav__links {
                    li {
                        margin-bottom: 20px;

                        @include breakpoint(desktop-extra-lite-sm) {
                            margin-bottom: 24px;
                        }

                        @include breakpoint(mobile) {
                            margin-bottom: 0;
                        }

                        a {
                            @include ann-describtion(16px, $lato-regular, 24px);
                            letter-spacing: 0;
                            color: $text-medium-dark-grey;
                        }
                    }
                }

                .social-link-wrapper {
                    .gbh-social__links {
                        display: flex;
                        flex-direction: row;

                        li {
                            padding-right: 30px;
                            color: $color-light-blue;
                            cursor: pointer;

                            a {
                                color: $color-light-blue;

                                &:hover {
                                    text-decoration: none;
                                }

                                &:before {
                                    color: $color-light-blue;
                                }
                            }

                            &:last-child {
                                @include breakpoint(mobile) {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.social-icons-center.additional-footer-social-link {
    .additional-footer {
        padding: 0 2.6%;
        max-width: 1920px;
        margin: 0 auto;

        @include breakpoint(desktop-large) {
            padding: 0 50px;
        }

        @include breakpoint(tab) {
            padding: 0 2rem;
        }

        @include breakpoint(mobile) {
            padding: 0 1.125rem;
        }

        footer {
            padding: 4.375rem 0;

            @include breakpoint(tab-max) {
                padding: 3.125rem 0;
            }

            .gbh-footer-add-nav {
                padding: 0;
                margin: 0;

                .gbh-container {
                    background-color: transparent;
                    overflow: hidden;

                    .gbh-footer-add-panel {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 23px;

                        @include breakpoint(mobile) {
                            padding: 0;
                            position: relative;
                            overflow-x: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }

                        .social-link-wrapper {
                            ul {
                                &.gbh-footer-nav__links {
                                    display: none;
                                    padding: 1.5625rem 0;

                                    @include breakpoint(mobile) {
                                        width: 100%;
                                        padding: 2.5rem 1.25rem 0 0;
                                    }

                                    li {
                                        padding: 0 0 0 2.5rem;
                                        height: 30px;
                                        display: flex;

                                        &:nth-child(1) {
                                            padding: 0;
                                        }

                                        @include breakpoint(mobile) {
                                            display: block;
                                            margin-bottom: 1.25rem;
                                            padding: 0;
                                        }
                                    }
                                }

                                &.gbh-social__links {
                                    position: unset;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    @include breakpoint(tab) {
                                        margin-bottom: 0;
                                        padding: 0;
                                    }

                                    @include breakpoint(mobile) {
                                        margin-bottom: 0;
                                        padding: 0;
                                    }

                                    li {
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        height: 30px;
                                        display: flex;

                                        a {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: 0.4s ease-in-out;
                                            line-height: 1.1;

                                            &:before {
                                                color: $text-medium-dark-grey;

                                                &:hover {
                                                    color: $color-light-blue;
                                                }
                                            }

                                            &:hover {
                                                text-decoration: none;
                                            }
                                        }


                                    }
                                }

                                li {
                                    display: inline;

                                    a {
                                        font-size: 0.875rem;
                                        cursor: pointer;
                                        line-height: 1.5rem;
                                        letter-spacing: -0.03125rem;
                                    }
                                }
                            }
                        }

                    }

                    p {
                        &.gbh-copy__rights {
                            font-size: 0.875rem;
                            line-height: 1.25rem;
                            letter-spacing: -0.03125rem;
                            display: none;
                            font-family: $lato-regular;
                            color: $white;

                            @include breakpoint(mobile) {
                                display: none;
                                text-align: left;
                                padding: 0 0 2.8125rem;
                                margin-top: 3.125rem 0 0;
                            }
                        }
                    }
                }
            }
        }

        .gbh-social {
            &__facebook {

                a {
                    line-height: 1.013rem;

                    &:before {
                        content: "\e921";
                        font-size: 1.36rem;
                        font-family: 'icomoon';
                        line-height: 1.313rem;
                    }
                }
            }

            &__twitter {

                a {
                    line-height: .863rem;

                    &:before {
                        content: "\e922";
                        font-size: 1.0539rem;
                        font-family: 'icomoon';
                        line-height: 1.013rem;
                    }
                }
            }

            &__insta {

                a {
                    line-height: 1.063rem;

                    &:before {
                        content: "\e923";
                        font-size: 1.343rem;
                        font-family: 'icomoon';
                        line-height: 1.313rem;
                    }
                }
            }

            &__pintrest {

                a {
                    line-height: 1.063rem;

                    &:before {
                        content: "\e924";
                        font-size: 1.343rem;
                        font-family: 'icomoon';
                        line-height: 1.313rem;
                    }
                }
            }

            &__youtube {
                a {
                    &:before {
                        content: "\e925";
                        font-size: 1.25rem;
                        font-family: 'icomoon';
                    }
                }
            }

            &__linkedin {
                a {
                    &:before {
                        content: "\e927";
                        font-size: 1.25rem;
                        font-family: 'icomoon';
                    }
                }
            }

            &__houzz {
                a {
                    &:before {
                        content: "\e926";
                        font-size: 1.25rem;
                        font-family: 'icomoon';
                    }
                }
            }

            &__email {
                a {
                    line-height: .838rem;

                    &:before {
                        content: "\e920";
                        font-size: .96rem;
                        font-family: 'icomoon';
                        line-height: .938rem;
                    }
                }
            }
        }
    }
}

.gbh-social {
    &__facebook {
        a {
            &:before {
                content: "\e921";
                font-size: 1.36rem;
                font-family: 'icomoon';
            }
        }
    }

    &__twitter {
        a {
            &:before {
                content: "\e922";
                font-size: 1.055rem;
                font-family: 'icomoon';
            }
        }
    }

    &__insta {
        a {
            &:before {
                content: "\e923";
                font-size: 1.313rem;
                font-family: 'icomoon';
            }
        }
    }

    &__pintrest {
        a {
            &:before {
                content: "\e924";
                font-size: 1.313rem;
                font-family: 'icomoon';
            }
        }
    }

    &__youtube {
        a {
            &:before {
                content: "\e925";
                font-size: 1.25rem;
                font-family: 'icomoon';
            }
        }
    }

    &__linkedin {
        a {
            &:before {
                content: "\e927";
                font-size: 1.313rem;
                font-family: 'icomoon';
            }
        }
    }

    &__houzz {
        a {
            &:before {
                content: "\e926";
                font-size: 1.25rem;
                font-family: 'icomoon';
            }
        }
    }

    &__email {
        a {
            &:before {
                content: "\e920";
                font-size: 1rem;
                font-family: 'icomoon';
            }
        }
    }

    &__tiktok {
        a {
            &:before {
                content: "";
                font-size: 1.313rem;
                font-family: 'icomoon';
            }
        }
    }
}