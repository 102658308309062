.article-content-font-regular {
    .cmp-text {
        h1,h2,h3,h4,h5,h6 {
            @include ann-describtion(2.625rem, $playfair-regular, 3.375rem);
            text-align: center;
            margin-bottom: 3.75rem;
            letter-spacing: -0.073rem;
            @include breakpoint(tab) {
                @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                letter-spacing: -.0625rem;
                margin-bottom: 2.5rem;
            }
            @include breakpoint(mobile) {
                @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                letter-spacing: -0.073rem;
            }
        }
        p {
            @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
            color: $text-light-grey;
            padding: 0 2rem;
            text-align: center;
            letter-spacing: normal;
            @include breakpoint(tab) {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                color: $text-medium-dark-grey;
                padding: 0;
            }
            @include breakpoint(mobile) {
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                color: $text-medium-dark-grey;
                padding: 0;
                text-align: center;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    &.article-text-display-style {
        max-width: 1140px;
        margin: 0 auto;
        padding: 5.625rem 0 2.5rem;
        @include breakpoint(desktop-mid) {
            padding: 5.625rem 36px 2.5rem;
        }
        @include breakpoint(tab-max) {
            width: 100%;
            padding-top: 3.125rem;
        }
        @include breakpoint(mobile) {
            padding: 3.125rem 1.125rem 1.875rem;
            max-width: 100%;
            margin: 0;
        }
        .cmp-text {
            h1,h2,h3,h4,h5,h6 {
                letter-spacing: -0.073125rem;
                margin-bottom: 1.875rem;
                @include breakpoint(tab) {
                    @include ann-describtion(2.625rem, $playfair-regular, 3.375rem);
                }
                @include breakpoint(mobile) {
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                }
            }
            p {
                max-width: 41.25rem;
                margin: 0 auto;
                padding: 0 2.5rem;
                @include breakpoint(tab-max) {
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                    padding: 0;
                }
                @include breakpoint(tab) {
                    max-width: 38.25rem;
                }
                @include breakpoint (mobile) {
                    max-width: 20.0625rem;
                }
            }
        }
    }
}