// Sponsored Article Block and Brand Logo Links
#image{
    &.image {
        &.brand-logo-only {
            width: 20.25rem!important;
            margin: 0 auto;
            padding:70px 0;
            @include breakpoint(tab-max){
                padding:50px 0;
            }
            @include breakpoint(tab) {
                width: 28.5rem!important;
            }
            @include breakpoint(mobile) {
                width: 12.5rem!important;
            }
            .cmp-image__image {
                display: unset;
                width: 9.625rem!important;
                padding: 0 0 .72rem 0;
                mix-blend-mode: multiply;
                @include breakpoint(tab-max) {
                    width: 6.25rem!important;
                    padding: 0 0 0.9375rem 0;
                }
            }
            .cmp-image__title {
                margin:0;
                padding:0;
                color:inherit;
                *{
                    font-family: $lato-regular;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    letter-spacing: normal;
                    color: $text-medium-dark-grey;
                    margin: 0;
                    @include breakpoint(tab-max){
                        line-height:1rem;
                    }
                }
            }
        }
        &.center-aligned {
            text-align: center;
        }
    }
}