.find-pro-results {
    padding-top:25px;
    padding-bottom: 120px;
    position: relative;
    &__remodelers-section{
        &:first-child{
            padding-top: 20px; 
        }
    }
    .container {
        max-width: 1380px;
    }

    ol, ul {
        margin-left: 0;
    }

    &__local-professional-details {
        background-color: $color-charcoal;
        padding-top: 65px;
        padding-bottom: 100px;;

        .bread-crumb {
            list-style: none;
            padding: 10px 0;
            margin-bottom: 50px;

             li {
                display: inline;
                font-family: $font-regular;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.3px;
                margin-right: 4px;

                 a {
                    text-decoration: none;
                    color: $color-light-gray;
                 }

                 &.active {
                     color: $color-light-gray;
                     font-family: $font-medium;
                 }
             }

             li+li:before {
                padding: 0;
                color: $color-light-gray;
                content: "/\00a0";
            }
        }

        .local-professional-count {
            font-family: $font-light;
            font-size: 60px;
            letter-spacing: -3.1px;
            color: $color-white;

            .change-location{
                font-family: $font-regular;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.55px;
                color: $color-white;
                margin-left: 20px;
                text-decoration: underline;
                text-underline-position: under;
               
                &:hover {
                    color: $color-white;
                }
            }
        }
    }


    &__no-results {
        background-color: $color-white;
        display: block;

        .bread-crumb {
            list-style: none;
            padding: 10px 0;
            margin-bottom: 50px;

             li {
                display: inline;
                font-family: $font-regular;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.3px;
                margin-right: 4px;

                 a {
                    text-decoration: none;
                    color: $color-dark-gray;
                 }

                 &.active {
                     color: $color-dark-gray;
                     font-family: $font-medium;
                 }
             }

             li+li:before {
                padding: 0;
                color: $color-dark-gray;
                content: "/\00a0";
            }
        }

        .no-results-wrapper{
            padding: 0;
        }

        .no-results-details{
            font-family: $font-light;
            font-size: 60px;
            line-height: 68px;
            letter-spacing: -3.1px;
            color: $color-slate;
        }
        .no-result-sub-text {
            font-family: $font-regular;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
        }
    }

    &__sub-navigation {
        padding: 0;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
        width: 100%;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            padding: 0;
            align-content: center;
            margin: 0;
            li {
                list-style: none;
                font-family: $font-medium;
                font-size: 16px;
                line-height: 14px;
                padding: 23px 0;
                color: $color-dark-gray;
                text-decoration: none;
                margin-right: 30px;
                cursor: pointer;

                &.selected {
                    font-family: $font-medium;
                    color: $color-charcoal;
                }
            }
        }
        &.sticky {
            &.nav-up {
                top: 0 !important;
            }
        }
    }

    &__section-title {
        font-family: $font-light;
        font-size: 60px;
        letter-spacing: -3.1px;
        color: $color-slate;
        padding: 100px 0 30px 0;
    }

    &__designer-link {
        display: block;
        width: 100%;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &.link-added {
            cursor: pointer;
        }
    }

    &__content-section {
        padding: 0 40px;
        margin-left: -40px;
        margin-right: -40px;
        margin-top: -1px;
        position: relative;
        background-color: $color-white;
        
        &__space{
            padding: 40px 0px 50px;
            border-bottom: 1px solid $color-dark-gray;
            border-top: 1px solid $color-dark-gray;
        }

        &:hover {
            background-color: $color-off-white;
            z-index: 1;
            .partner-name{
                text-decoration: none;
            }
            .find-pro-results__content-section__space{
                border-color: $color-white;
            }
        }

        .partner-rating {
            .rating-stars {
                display: flex;
                .star-filled {
                    display: block;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }
            }
        }

        .profile-details {
            display: flex;
        }

        .partner-name {
            font-family: $font-light;
            font-size: 36px;
            line-height: 44px;
            letter-spacing: -1.7px;
            color: $color-slate;
            padding-bottom: 30px;
            text-transform: capitalize;
            max-width: 90%;
        }

        .partner-details {
            width: 42%;
            margin-right: 40px;
            .partner-address,
            .partner-designation,
            .miles-away {
                font-family: $font-regular;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.55px;
                color: $color-dark-gray;
                text-transform: capitalize;
            }
            .miles-away {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .available-btn {
                display: inline-block;
                font-family: $font-medium;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.65px;
                padding: 9px 12px;
                margin-top: 30px;
                background: $color-white;
                border: 1px solid $color-slate;
                color: $color-slate;
                text-transform: uppercase;
            }
            .available-duration {
                font-family: $font-regular;
                line-height: 24px;
                letter-spacing: -0.55px;
                padding:0;
                margin-top: 30px;
                color:$color-dark-gray;
            }
        }

        .partner-photography {
            width: 140px;
            height: 185px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                -o-object-position: 50% 50%;
                object-position: 50% 50%;
                mix-blend-mode: multiply;
            }
        }

        .speciality-title,
        .contact-title {
            font-family: $font-medium;
            font-size: 16px;
            padding-bottom: 20px;
            color: $color-dark-gray;
        }

        .speciality-details {
            ul {
                padding: 0;
                li {
                    list-style: none;
                    font-family: $font-regular;
                    color: $color-charcoal;
                    font-size: 20px;
                    line-height: 44px;
                    letter-spacing: -0.65px;
                }
            }
            p {
                font-family: $font-regular;
                color: $color-slate;
                font-size: 20px;
                line-height: 44px;
                letter-spacing: -0.65px;
            }
        }

        .contact-details {
            a {
                font-family: $font-regular;
                font-size: 20px;
                line-height: 44px;
                letter-spacing: -0.65px;
                color: $color-slate;
            }
            p {
                font-family: $font-regular;
                font-size: 20px;
                line-height: 44px;
                letter-spacing: -0.65px;
                color: $color-slate;
                word-break: break-all;
            }
        }
    }

    &__designer-section {
        .service-info {
            font-family: $font-regular;
            letter-spacing: -0.65px;
            margin: 40px 0 0 0;
            padding: 0;
            p{
                color:$color-dark-gray;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.65px;
                a{
                color:$color-dark-gray;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.65px;
                text-decoration: underline;
                }
            }
            a{
                text-decoration: underline;
            }
        }
        
        .profile-details {
            .partner-details {
                .partner-address {
                    p {
                        font-family: $font-regular;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.55px;
                        color: $color-dark-gray;
                    }
                }

                .partner-designation {
                    p {
                        font-family: $font-regular;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.55px;
                        color: $color-dark-gray;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 920px) and (max-width: 1024px) and (orientation: landscape){
    .find-pro-results {

        &__local-professional-details {
            padding: 50px 0 80px;
            .local-professional-count {
                font-size: 48px;
                letter-spacing: -2.2px;
            }
        }

        &__no-results {
            .no-results-details {
                font-size: 48px;
            }
        }

        &__content-section {
            padding: 0 32px;
            margin-left: -32px;
            margin-right: -32px;
            .profile-details {
                padding-right: 15px;
            }
            &__space{
                padding: 25px 0px 50px;
            }

            .speciality-details {
                ul {
                    padding: 0;
                    li {
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.55px;
                    }
                }
            }
    
            .contact-details {
                p {
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.55px;
                    a{
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.55px;
                    }
                }
            }
        }

        &__section-title {
            font-size: 48px;
            line-height: 48px;
            padding: 60px 10px 20px 0px;
        }

        &__designer-section {
            .service-info{
                font-size: 16px;
                line-height: 28px;
                margin-top: 25px;
            }
        }

    }
}

@media only screen and (max-width: 1024px){ 
    .find-pro-results {
        &__remodelers-section{
            &:first-child{
                padding-top: 0px; 
            }
        }
        &__remodelers-section{
            padding-top: 20px; 
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 900px) and (orientation: portrait){ 
    .find-pro-results {

        &__local-professional-details {
            padding: 50px 0 80px;
            .local-professional-count {
                font-size: 40px;
                letter-spacing: -2.1px;
            }
        }
        

        &__no-results {
            .no-results-details {
                font-size: 48px;
            }
        }

        &__content-section {
            .partner-name{
                padding-bottom: 16px;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -2.5px;
            }
            .partner-details {
                width: 55%;
                margin-right: 19px;
            }
            .partner-photography {
                width: 103px;
                height: 138px;
            }
            .speciality, .contact {
                padding-left: 50px;
            }
        }

        &__section-title {
            font-size: 48px;
            line-height: 48px;
            padding: 60px 10px 20px 0px;
        }

        &__designer-section {
            .service-info {
                font-size: 16px;
                line-height: 28px;
                margin-top: 25px;
            }
        }
    }
}

@media only screen and (max-width: 860px) and (orientation: landscape){
    .find-pro-results {
        &__content-section {
            padding: 0 32px;
            margin-left: -32px;
            margin-right: -32px;
            &__space{
                padding: 25px 0px 50px;
            }
            .partner-name{
                padding-bottom: 16px;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -2.5px;
            }
            .partner-details {
                width: 55%;
                margin-right: 19px;
            }
            .partner-photography {
                width: 103px;
                height: 138px;
            }
            .speciality, .contact {
                padding-left: 50px;
            }
        }
    }
}
@media only screen and (min-width: 992px) { 
    .find-pro-results{
        &__container{
            padding: 0px 60px;
        }
    }
}

@media only screen and (max-width: 992px) { 
    .find-pro-results {
            padding-bottom: 80px;
            .container, .container-sm {
                max-width: -webkit-fill-available;
            }
        
            &__local-professional-details {
                padding: 50px 0 80px;
                .local-professional-count {
                    font-size: 40px;
                    letter-spacing: -2.2px;

                    .change-location{
                        display: block;
                        margin-left: 6px;
                        margin-top: 20px;
                    }
                }
            }

            &__no-results {
                .no-results-details {
                    font-size: 40px;
                    line-height: 46px;
                    letter-spacing: -2.2px;
                    margin-bottom: 20px;
                }
            }    

            &__sub-navigation {
                padding: 0;
                ul {
                    flex-wrap: nowrap;
                    overflow: auto;
                    overflow-y: hidden;
                    justify-content: flex-start;
                    li {
                        white-space: nowrap;
                        margin-right: 30px;
                    }
                }
            }

            &__section-title {
                font-size: 40px;
                line-height: 40px;
                letter-spacing: -2.2px;
                padding: 60px 10px 30px 0px;
            }

            &__content-section {
                padding: 0 32px;
                margin-left: -32px;
                margin-right: -32px;
                &__space{
                    padding: 26px 0px 24px;
                }

                .profile-details {
                    flex-direction: row;
                    justify-content: space-between;
                }

                .partner-rating {
                    text-align: center;
                    margin: auto;
                }

                .partner-name {
                    font-size: 32px;
                    line-height: 40px;
                    letter-spacing: -2.5px;
                    padding-bottom: 16px;
                    cursor: pointer;
                    max-width: auto;
                }
        
                .partner-details {
                    .partner-address,
                    .partner-designation,
                    .available-duration  {
                        font-family: $font-regular;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.55px;
                        color: $color-dark-gray;
                    }
                    .available-btn {
                        font-size: 12px;
                        text-transform: uppercase;
                    }
                }
        
                .partner-photography {
                    width: 104px;
                    height: 138px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .speciality,
                .contact {
                    padding: 0;
                }
                .speciality-title,
                .contact-title {
                    font-family: $font-medium;
                    font-size: 16px;
                    padding-bottom: 10px;
                    margin-top: 25px;
                    color: $color-charcoal;
                }
        
                .speciality-details {
                    ul {
                        padding: 0;
                        li {
                            font-size: 16px;
                            line-height: 28px;
                            letter-spacing: -0.55px;
                        }
                    }
                }
        
                .contact-details {
                    p {
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.55px;
                        a{
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.55px;
                        }
                    }
                }
            }

            &__designer-section {
                .service-info {
                    font-size: 16px;
                    margin-top: 25px;
                }
            }
        }
}

@media only screen and (max-width: 576px){ 
    .find-pro-results {
        &__content-section {
            .speciality-details {
                ul {
                    padding: 0;
                    li {
                        list-style: none;
                        font-family: $font-regular;
                        color: $color-slate;
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.55px;
                    }
                }
                p {
                    font-family: $font-regular;
                    color: $color-slate;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.55px;
                }
                .contact-details {
                    p {
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.55px;
                        a{
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.55px;
                        }
                    }
                }
            }
            .partner-details {
                width: 55%;
                margin-right: 19px;
            }
        }
        &__designer-section {
            .service-info, .service-info-link {
                font-size: 20px;
                margin-top: 25px;
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .find-pro-results {
        &__content-section {
            padding: 0 15px;
            margin-left: -15px;
            margin-right: -15px;
        }
        &__local-professional-details {
            padding: 50px 18px 80px;
        }
        &__sub-navigation {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 813px) and (orientation: landscape){ 
    .find-pro-results {
        &__content-section {
            .speciality-details {
                ul {
                    padding: 0;
                    li {
                        list-style: none;
                        font-family: $font-regular;
                        color: $color-slate;
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.55px;
                    }
                }
                p {
                    font-family: $font-regular;
                    color: $color-slate;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.55px;
                }
                .contact-details {
                    p {
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.55px;
                        a{
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.55px;
                        }
                    }
                }
            }
        }
        &__designer-section {
            .service-info, .service-info-link {
                font-size: 20px;
                margin-top: 25px;
            }
        }
    }
}
@media only screen and (max-width: 480px){
    .find-pro-results {
        &__local-professional-details {
            padding: 50px 0px 60px;
        }
        &__sub-navigation {
            padding: 0;
        }
    }
}
