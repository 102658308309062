.cmp-embed {
  z-index: 0;
  display: flex;
  background-color: $color-light-grey;
  justify-content: flex-end;
  color: $slate;
  align-items: center;
  text-align: right;
  padding: 0 2.6%;
  @include breakpoint(desktop-large) {
    padding: 0 50px;
  }
  @include breakpoint(tab) {
    padding: 0 32px;
  }
  @include breakpoint(mobile) {
    align-items: initial;
    flex-direction: column;
    text-align: left;
    padding: 0 18px;
  }
  .gbh-helpcont {
    width: 100%;
    max-width: 1820px;
    margin: 0 auto;
    padding: 15px 0;
    @include breakpoint(tab-max) {
      padding: 15px 0;
    }
    .gbh-helpus {
      @include ann-describtion(0.8125rem, $lato-regular, 1.25rem);
      margin-right: 1.25rem;
      letter-spacing: 0.0125rem;
      color: $text-medium-dark-grey;
      @include breakpoint(tab) {
        margin-right: 1.25rem;
      }
      @include breakpoint(mobile) {
        padding-bottom: 1.25rem;
        display: block;
      }
    }
    a {
      color: $slate;
      border: 1px solid $slate;
    }
    .gbh__btn--link {
      @include ann-describtion(13px, $lato-regular, 16px);
      letter-spacing: 0.19px;
      text-align: center;
      padding: 13px 15px 15px;
      border: 1px solid $color-light-blue;
      text-transform: capitalize;
      border-radius: 3px;
      width: auto;
      @include breakpoint(tab) {
        width: 9.8125rem;
        padding: 13px 15px 15px;
      }
      @include breakpoint(mobile) {
        text-align: center;
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}

.newsletter-footer-bg-white {
  background: $white;
  padding: 75px 2.6% 90px;
  animation: fadeInUp 2s linear;
  @include breakpoint(desktop-large) {
    padding: 75px 50px 90px;
  }
  @include breakpoint(tab) {
    padding: 60px 32px 50px;
  }
  @include breakpoint(mobile) {
    padding: 20px 18px 32px;
  }
  .cmp-container {
    max-width: 1820px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(tab-max) {
      max-width: 100%;
    }
    @include breakpoint(mobile) {
      flex-direction: column-reverse;
    }
  }
}
.newslettersignin {
  animation-name: fadeInDown;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  min-height: 105px;
  @include breakpoint(tab) {
    width: 100%;
    padding-left: 6.875rem;
    min-height: 100px;
  }
  @include breakpoint(mobile) {
    width: 100%;
    min-height: 110px;
  }
  footer {
    height: 100%;
    .gbh-footer-newsletter-nav {
      background: transparent;
      color: inherit;
      height: 100%;
    }
    .new-gbh-container,
    .gbh-footer-newsletter {
      height: 100%;
    }
    .gbh-footer-news-letter {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
      .text-wrapper,
      p {
        @include ann-describtion(14px, $lato-regular, 20px);
        color: $text-medium-dark-grey;
        margin: 0;
        letter-spacing: 0;
        @include breakpoint(tab) {
          padding-right: 0;
          width: 100%;
        }
      }
      .form-group {
        position: relative;
        border-bottom: 1px solid $cool-dark-grey;
        width: 54.375rem;
        margin-bottom: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        @include breakpoint(desktop-lg) {
          width: 45.3125rem;
        }
        @include breakpoint(desktop-mid) {
          width: 40.875rem;
        }
        @include breakpoint(desktop-extra-sm) {
          width: 38.875rem;
        }
        @include breakpoint(tab) {
          width: 100%;
          margin-bottom: 15px;
        }
        @include breakpoint(mobile) {
          width: 100%;
          margin-bottom: 15px;
          align-items: baseline;
        }
        label {
          display: block;
          width: 80%;
          margin-bottom: 16.5px;
          @include breakpoint(tab) {
            margin-bottom: 17.5px;
          }
          @include breakpoint(mobile) {
            margin-bottom: 18px;
          }
        }
        .form-control {
          width: 80%;
          display: block;
          background: none;
          border: none;
          padding: 0;
          margin-bottom: 0;
          color: $text-medium-dark-grey;
          line-height: 2.25rem;
          font-size: 1.625rem;
          font-family: $lato-regular;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          @include breakpoint(tab) {
            @include ann-describtion(26px, $lato-regular, 36px);
            letter-spacing: 0;
            width: 80%;
          }
          @include breakpoint(mobile) {
            @include ann-describtion(18px, $lato-regular, 28px);
            letter-spacing: 0;
            width: 80%;
          }
        }
        .btn {
          border-radius: 1px;
          position: absolute;
          right: 0px;
          color: $color-medium-blue;
          background-color: $white;
          padding: 13px 27px;
          font-family: $lato-bold;
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5rem;
          letter-spacing: 2.5px;
          text-align: center;
          border: 1px solid $color-medium-blue;
          text-transform: uppercase;
          top: 0;
          margin-bottom: 20px;
          @include breakpoint(mobile) {
            @include ann-describtion(11px, $lato-bold, 10px);
            padding: 15px 26px;
            height: 40px;
          }
          @include breakpoint(tab-max) {
            margin-bottom: 15px;
          }
        }
      }
      .subsribe-error-container {
        margin-left: 0px;
        @media only screen and (min-width: 375px) and (max-width: 767px) {
          position: inherit;
        }
        #subsribe-mail-error {
          font-family: $lato-regular;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          color: $text-medium-dark-grey;
        }
      }
    }
  }
}

.NewsletterFooter_wrapper__UxaGs {
  &.newsletter {
    .newsletter__content {
      margin-top: 0;
    }
  }
  .newsletter {
    .newsletter__thanks {
      * {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: normal;
        font-family: $lato-regular;
        margin-bottom: 20px;
        color: $text-dark-grey;
        @include breakpoint(tab-max) {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }
    }
    .newsletter__success {
      * {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: normal;
        font-family: $lato-regular;
        margin-bottom: 40px;
        color: $text-medium-dark-grey;
        @include breakpoint(tab-max) {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 40px;
        }
      }
    }
    .newsletter__content {
      .kf-react-button {
        font-family: $lato-bold;
        height: 50px;
        width: 100%;
        font-size: 12px;
        letter-spacing: 2.5px;
        &:hover {
          -webkit-transform: scale(0.95);
          transform: scale(0.95);
        }
        @include breakpoint(tab-max) {
          font-size: 11px;
          line-height: 10px;
          height: 40px;
        }
      }
      @include breakpoint(tab) {
        margin-top: 0px;
      }
    }
  }
}

.newsletter-footer-bg-white {
  .Modal_modalWrapper__5Kda6 {
    .modal-popup .modal-popup-content {
      padding: 0;
      @include breakpoint(tab-max) {
        width: 100%;
        height: 100% !important;
        min-width: 100% !important;
        min-height: 100% !important;
      }
      .modal-popup-body {
        padding: 70px 40px 60px;
        @include breakpoint(tab) {
          padding: 60px 32px 0px;
        }
        @include breakpoint(mobile) {
          padding: 60px 18px 0px;
        }
        .newsletter {
          @include breakpoint(tab-max) {
            margin-top: 55px;
          }
          .newsletter__thanks {
            margin-bottom: 0;
          }
        }
      }
      .close-button {
        @include breakpoint(mobile) {
          top: 60px;
          margin-right: 0;
        }
        @include breakpoint(tab) {
          top: 60px;
          right: 32px;
        }
        img {
          width: 40px;
          height: 40px;
          margin-right: -10px;
          @include breakpoint(tab-max) {
            margin-right: 0;
          }
        }
      }
      .button {
        &:hover {
          background-color: $black;
        }
      }
    }
  }
}
// Animation:
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-moz-document url-prefix() {
  footer {
    .gbh-footer-news-letter {
      .form-group {
        .form-control {
          padding: 1.625rem 0 0;
          &:-moz-placeholder {
            display: none;
          }
        }
        .btn {
          top: 27px;
        }
      }
    }
  }
}

.additional-footer-social-link {
  background: $white;

  @include breakpoint(desktop-small) {
    width: 100%;
  }
  @include breakpoint(mobile) {
    width: 100%;
    margin-top: 43px;
  }
  .additional-footer {
    padding: 0;
    max-width: 100%;
    .gbh-footer-add-nav {
      background: transparent;
      color: inherit;
      .gbh-footer-add-panel {
        @include breakpoint(mobile) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .gbh-footer-nav__links {
          li {
            margin-bottom: 20px;
            @include breakpoint(desktop-extra-lite-sm) {
              margin-bottom: 24px;
            }
            @include breakpoint(mobile) {
              margin-bottom: 0;
            }
            a {
              @include ann-describtion(16px, $lato-regular, 24px);
              letter-spacing: 0;
              color: $text-medium-dark-grey;
            }
          }
        }
        .social-link-wrapper {
          .gbh-social__links {
            display: flex;
            flex-direction: row;
            align-items: center;
            li {
              padding-right: 30px;
              color: $color-light-blue;
              cursor: pointer;
              a {
                color: $color-light-blue;
                &:hover {
                  text-decoration: none;
                }
                &:before {
                  color: $color-light-blue;
                }
              }
              &:last-child {
                padding-right: 0;
                @include breakpoint(mobile) {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.gbh-social {
  &__facebook {
    a {
      &:before {
        content: "\e921";
        font-size: 1.36rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__twitter {
    a {
      &:before {
        content: "\e922";
        font-size: 1.055rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__insta {
    a {
      &:before {
        content: "\e923";
        font-size: 1.313rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__pintrest {
    a {
      &:before {
        content: "\e924";
        font-size: 1.313rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__youtube {
    a {
      &:before {
        content: "\e925";
        font-size: 1.25rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__linkedin {
    a {
      &:before {
        content: "\e927";
        font-size: 1.313rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__houzz {
    a {
      &:before {
        content: "\e926";
        font-size: 1.25rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__email {
    a {
      &:before {
        content: "\e920";
        font-size: 1rem;
        font-family: "icomoon", sans-serif;
      }
    }
  }
  &__tiktok {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      &:before {
        content: "";
        background-image: url("../images/tiktok.svg");
        width: 24px;
        height: 24px;
        display: block;
      }
    }
  }
}
