.product-select-style{
    &.curated-style{
        max-width: 100%;
    }
}
.product-size, .product-stoneTop{
    margin-bottom: 25px;
    width: 100%;
    display: inline-block;
    @include breakpoint(mobile){
        margin-bottom: 0;
    }
    .text{
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.02px;
        font-family: $lato-regular;
    }
    .title{
        @extend .text;
        margin-bottom: 15px;
        .lable{
            color:$text-medium-dark-grey;
        }
        .info{
            color:$text-dark-grey;
            margin-left: 5px
        }
    }
    .desc{
        @extend .text;
    }
    .size-row{
        display: flex;
        column-gap: 14.25px;
        .size-column {
            max-width: 170px;
            width: 100%;
            min-width:170px;
            @include breakpoint(mobile){
                max-width: 150px;
                min-width:150px;
            }
            &.active {
                .size-swatch{
                    .img{
                        border-color:$text-dark-grey;
                    }
                   
                }
                .desc{
                    color:$text-dark-grey;
                }
            }
            .desc{
                color:$text-medium-dark-grey;
            }
            .size-swatch {
                max-width: 170px;
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 170px;
                .img{
                    width: 100%;
                    height: 100%;
                    border:1.5px solid $light-white;
                    border-radius: 2px;
                } 
                @include breakpoint(mobile){
                    max-width: 150px;
                    height: 150px;
                }
            } 
        }
    }
    .scroll {
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: thin;
        scrollbar-gutter: stable;
        scrollbar-color: $text-medium-black $cool-dark-grey-cap; 
        padding-bottom: 25px;
        &::-webkit-scrollbar{
            height: 5px;
            -webkit-appearance: none;
            -webkit-appearance: scrollbartrack-vertical;
            -webkit-appearance: scrollbarthumb-vertical;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-charcoal; 
        }
        &::-webkit-scrollbar-thumb:hover{
            background-color: $color-charcoal;
        }
        &::-webkit-scrollbar-track{
            background-color: $color-charcoal; 
        }
        &::-webkit-scrollbar-track-piece{
            background-color: $cool-dark-grey;
        }
        &::-webkit-scrollbar-track-piece:end{
            background-color: $cool-dark-grey;
        }
        &::-webkit-scrollbar-track-piece:start{
            background-color: $cool-dark-grey
        }
    }
}
.product-stoneTop{
    margin-top: 5px;
    .stoneTop-row{
        display: flex;
        align-items: center;
        .column {
            height: 80px;
            width: 70px;
            border: 1px solid $color-extra-light-gray;
            border-radius: 1.2px;
            margin-right: 15px;
            padding: 8px 7px;
            cursor: pointer;
            @include breakpoint(desktop-extra-lite-sm) {
                height: 65px;
                width: 55px;
                padding: 7px;
                margin-right: 10px;
            }
            @include breakpoint(tab-max){
                height: 66.5px;
                width: 56.5px;
            }
            &:hover, &:focus, &:focus-within{
                border: 1.5px solid $text-dark-grey;
                border-radius: 2px;
            }
            .swatch-img{
                width: 100%;
                height: 100%;
            }
            &.active{
                border: 1.5px solid $text-dark-grey;
                border-radius: 2px;
            }
        }
        .tool-tip{
            margin-left: 5px;
            position: relative;
            .vanitytooltip{
                height: 16px;
                width: 16px;
                cursor: pointer;
                
            }
        }
        .product-select-style__list-tooltip--show{
            position:static;
        }
        .product-select-style__list-tooltip-color-wrapper{
            right: 0;
        }
    }
}