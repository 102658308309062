// Global Text only Hero (Non Image Hero)
// Variation 3 & 4
.ann-sacks-plp-hero-banner{
        text-align: center;
        padding:6.25rem 2.6% 4.125rem;
        @include breakpoint(desktop-xlg){
            padding:123px 50px 60px;
        }
        @include breakpoint(desktop-lg){
            padding:93px 0 60px;
        }
        @include breakpoint(desktop-mid){
            padding:93px 36px 3.75rem;
        }
        @include breakpoint(tab){
            padding: 4.5625rem 2rem 2.5rem;
        }
        @include breakpoint(mobile){
            padding: 4.5625rem 1.125rem 2.5rem;
        }
        .cmp-teaser{ 
            display: block;
            max-width:1820px;
            margin:0 auto;
            @include breakpoint(desktop-lg){
                max-width: 1380px;
            }     
            &__content{
                text-align: center;
                margin:0 auto;
                margin-bottom: 3.75rem;
                @include breakpoint(tab){
                    width: 45.46%;
                }
                @include breakpoint(mobile){
                    width: 61.065%;
                }              
            }
            &__title {
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
                    letter-spacing: -0.0938rem;
                    @include breakpoint (mobile) {
                        letter-spacing:  -0.175rem;
                    }
                    @include breakpoint(tab-max) {
                        @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                        letter-spacing: -0.175rem;
                    }
                }
            }
            &__description{
                h1,h2,h3,h4,h5,h6,p {
                    @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                    letter-spacing: normal;
                    margin: 1.25rem auto 0;
                    @include breakpoint(tab-max) {
                        @include ann-describtion(.8125rem, $lato-regular, 1.25rem);
                        letter-spacing: .0125rem;
                    }
                    @include breakpoint(mobile) {
                        text-align: center;
                        width:100%;
                    }
                }
            }
            &__action-link{
                margin: 1.5625rem 0 .875rem;
                padding:0;
                color:$color-light-blue;
                line-height: .75rem;
                padding-bottom:.4375rem;
                border-color:$color-light-blue;
            }
        }
        &.line-separator{
            .cmp-teaser{
                border-bottom:1px solid $cool-dark-grey;
            }
        }
}
// Variation 5 & 6
.hero-banner-article-top{
    padding: 5.8125rem 0 3.75rem;
    @include breakpoint(tab){
        padding: 3.75rem 2rem 2.5rem;
    }
    @include breakpoint(mobile){
        padding: 3.75rem 1.125rem 2.5rem;
    }
    .cmp-teaser{
        max-width: 1820px;
        margin: 0 auto;
        &__content{
            width: 56.25rem;
            margin:0 auto;
            text-align: center;
            @include breakpoint(tab){
                width: unset;
            }
            @include breakpoint(mobile){
                width:100%;
            }
        }
        &__title{
            width: 100%;
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
                letter-spacing: -1.75px;
                margin:0;
                padding: 0;
                color: $text-dark-grey;
                @include breakpoint (tab){
                @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                    letter-spacing: -2px;
                    width: unset;
                }
                @include breakpoint(mobile){
                    @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                    letter-spacing:-2px;
                }
            }
        }
        &__description{
            // padding-top: 1.25rem;
            // padding-bottom: 3.75rem;
            display: block;
            padding-top: 1.875rem;
            width: 100%;
            @include breakpoint(tab-max){
                padding-top: 1.25rem;
                margin: 0 auto;
            }
            @include breakpoint (mobile) {
                max-width: 339px;
            }
            h1,h2,h3,h4,h5,h6,p{
                margin:0;
                @include ann-describtion(1.125rem, $lato-regular, 24px);
                letter-spacing: 0.5px;
                color: $text-medium-dark-grey !important;
                line-height: 1.5rem;

            }
        }
        &__image{
            display: none;
        }
    }
    &.content-center{
        .cmp-teaser{
            text-align: center;
        }
    }
}

.teaser_v2.teaser{
    &.hero-banner-article-bottom{
        max-width:1920px;
        margin:0 auto;
        padding:0 2.628% 1.875rem;
        z-index: auto!important;
        @include breakpoint(desktop-large){
            padding: 0 50px 30px;
        }
        @include breakpoint(desktop-lg){
            max-width: 1380px;
            padding: 0 0 1.875rem 0;
        }
        @include breakpoint(desktop-mid){
            padding: 0 36px 1.875rem;
        }
        @include breakpoint(tab){
            width:100%;
            padding:0 2rem 1.3125rem;
        }
        @include breakpoint(mobile){
            width:100%;
            padding:0 1.125rem 1.25rem;
        }
        .cmp-teaser{
            &__content{
                display:flex;
                justify-content: space-between;
                align-items: center;
                @include breakpoint(tab){
                    justify-content: center;
                    flex-direction: row;
                }
                @include breakpoint(mobile){
                    flex-direction:row;
                }
            }
            &__icons{
                display: flex;
                flex-direction:row;
                align-items: center;
                justify-content: center;
                opacity: 1!important;
                visibility: visible!important;
                top: unset!important;
                width: auto;
                justify-content: space-between;
                padding: 0;
                right: 20px !important;
                border: none;
                @include breakpoint(tab-max){
                    align-items: baseline;
                    right: 0 !important;
                }
                @include breakpoint(mobile){
                    position: unset;
                    justify-content: center;
                }
                *{
                    @include ann-describtion(0.875rem,$lato-regular, 1.25rem);
                    color:$text-medium-dark-grey;
                    margin-right: 30px;
                    display:flex;
                    cursor: pointer;
                    height: fit-content;
                    align-items: center;
                    justify-content: center;
                    &:last-child{
                        margin-right:0;
                    }
                    &:nth-child(3n){
                        margin-right:0;
                    }
                    @include breakpoint(tab){
                        margin-right:1.25rem;
                    }
                    @include breakpoint(mobile){
                        margin-right:1.25rem;
                        span{
                            display:none;
                        }
                        &:before{
                            margin:0;
                        }
                    }
                    
                }
                span{
                    font-family:$lato-regular;
                }
                .social-share__actions{
                    padding-top: 20px;
                    .social-share__cta{
                        img{
                            width: 22px;
                            height: 22px;
                            margin-right: 6.54px;
                            &:last-child{
                                width: 21px;
                                height: 15px;
                                margin-right: 8px;
                            }
                        }
                    }
                    >div{
                        margin-right: 2.4375rem;
                    }
                }
                .print, .favorite, .share{
                    width: auto;
                    padding: 0;
                    margin-right: 30px!important;
                    @include breakpoint(tab){
                        margin-right: 20px!important;
                    }
                    @include breakpoint(mobile){
                        margin-right: 0!important;
                    }
                    &:before{
                        padding: 0;
                        @include breakpoint(tab-max){
                            margin: 0;
                        }
                    }
                    span{
                        display: block!important;
                        @include breakpoint(mobile){
                            display: none!important;
                        }
                    }
                }
                .print{
                    font-size: 1.0625rem;
                    line-height: 1.0625rem;
                    &:before{
                        font-size: 1.0625rem;
                        line-height: 1.0625rem;
                    }
                }
                .favorite{
                    font-size: .815rem;
                    line-height: .8313rem;
                    &:before{
                        font-size: .815rem;
                        line-height: .8313rem;
                    }
                }
                .share{
                    font-size: .971rem;
                    line-height: .9925rem;
                    &:before{
                        font-size: .971rem;
                        line-height: .9925rem;
                        padding: 0;
                    }
                    @include breakpoint(mobile){
                        margin-right: 0!important;
                    }
                }
            }
            &__description{
                text-align:center;
                border: none;
                @include breakpoint(mobile){
                    margin-bottom: 1.375rem;
                    width: auto;
                }
                p{
                    @include ann-describtion(0.75rem,$lato-regular, 1rem);
                    margin:0;
                    color:$text-medium-dark-grey;
                    text-transform:uppercase;
                    letter-spacing:0.125rem;
                }
            }
        }
    }
    &.hero-banner-article-top{
        padding: 8.125rem 0 5.3125rem;
    }
}

.print{
    &:before{
        content: "\e918";
        margin-right: 0.5rem;
    }
}
.favorite{
    &:before{
        content:"\e905";
        margin-right: 0.5rem;
    }
}
.share{
    &:before{
        content:"\e919";
        margin-right: 0.5rem;
    }
}
// Variation 7 & 8
.page-title{
    padding: 0 2.6045%;
    @include breakpoint(desktop-large){
        padding: 0 50px;
    }
    @include breakpoint(tab){
        padding: 0 32px;
    }
    @include breakpoint(mobile){
        padding: 0 18px;
    }
    .cmp-title{
        max-width:1820px;
        margin:0 auto;
        padding-left: 0;
        @include breakpoint (tab-max) {
            width: 100%;
        }
        h1.cmp-title__text{
            @include ann-describtion(4.375rem, $playfair-display-regular, 4.875rem);
            padding:6.875rem 0 8.75rem;
            letter-spacing: -.09375rem;
            font-weight: 400;
            color: $text-dark-grey;
            padding: 6.875rem 0 8.75rem;
            margin: 0;
            @include breakpoint(tab-max){
                @include ann-describtion(2.625rem, $playfair-display-regular, 3rem);
                padding:5rem 0 5.625rem;
                letter-spacing:-0.175rem;
            }
        }
    }
}
// Variation 9
.browse-showroom-hero{
    padding:0;
    .cmp-teaser{
        max-width:1920px;
        margin:0 auto;
        padding:8.125rem 50px 150px;
        @include breakpoint(desktop-mid){
            padding-right:2.25rem;
            padding-left:2.25rem;
        }
        @include breakpoint(tab){
            padding:6.5625rem 2rem 7.1875rem;
        }
        @include breakpoint(mobile){
            padding:5.625rem 1.125rem;
        }
        &__content{
            display:flex;
            justify-content: space-between;
            align-items: flex-start;
            @include breakpoint(mobile){
                flex-direction:column;
            }
        }
        &__title{
            margin-right:auto;
            h1,h2,h3,h4,h5,h6{
                @include ann-describtion(36px, $lato-regular, 2.25rem);
                letter-spacing:normal;
                @include breakpoint(tab-max){
                    @include ann-describtion(1.375rem, $lato-regular, 2rem);
                }
                @include breakpoint(mobile){
                    margin-bottom:30px;
                }
            }
        }
        &__description{
            padding-right:3.75rem;
            max-width:22.5rem;
            @include breakpoint(desktop-extra-lite-lg){
                padding-right: 0
            }
            p{
                @include ann-describtion(16px, $lato-regular, 1.5rem);
                letter-spacing:normal;
                color:$text-medium-dark-grey;
            }
        }
    }
}
// Variation PLP Hero Text
.teaser.plp-text-hero{
    @include breakpoint (desktop-small) {
        padding-bottom: 0;
    }
    @include breakpoint(desktop-mid){
        padding: 0 2.25rem;
    }
    @include breakpoint(tab){
        padding: 0 32px;
    }
    @include breakpoint(mobile){
        padding: 0 18px;
    }
    .cmp-teaser{
        max-width:1820px;
        margin:0 auto;
        &__content{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-bottom:20px;
            align-items:center;
            @include breakpoint(tab-max){
                flex-direction: column;
                align-items:flex-start;
            }
        }
        &__title{
            width: 100%;
            margin: 0 0 20px;
            @include breakpoint(desktop-extra-lite-sm){
                margin:0 0 10px;
            }
            h1, h2, h3, h4, h5, h6, p{
                font-size:3.375rem;
                line-height:3.875rem;
                letter-spacing:-1px;
                font-family:$playfair-regular;
                color:$text-dark-grey;                           
                @include breakpoint(desktop-extra-lite-sm){
                    font-size:36px;
                    line-height:44px;
                    letter-spacing:-2px
                }
            }
            &:only-child{
                margin-bottom:0;
            }
        }
        &__description{   
            @include breakpoint(desktop-small){
                margin-right: 1.875rem;
                margin-top: 0;
            }
            p{
                @include text-style(1.375rem, 2rem, normal);
                font-family: $lato-regular;
                color:$text-dark-grey;
                @include breakpoint(desktop-extra-lite-sm){
                    font-size:18px;
                    line-height:28px;
                    letter-spacing:normal
                }
            }
        }
        &__action-container{
            @include breakpoint (desktop-small) {
            padding-left: 0;
            }
            @include breakpoint(tab){
                padding-top: 20px;
            }
            @include breakpoint(mobile){
                padding-top: 20px;
            }
            .cmp-teaser__action-link{
                @include ann-describtion (12px, $lato-bold, 16px);
                letter-spacing: 2px;
                border: 1px solid $color-medium-blue;
                padding: 10px 26.5px;
                color: $color-medium-blue;
                width: auto;
                max-height:40px;
                font-family:$lato-bold;
                &.gbh-primary-button {
                    background-color: unset;
                }
            }
        }
    }
    &.teaser{
        .cmp-teaser{
            &__description{
                p{
                    color:$text-dark-grey;
                }
            }
        }
    }
}
// PLP Text Only Hero Aditional Variation.
.organize-button{
    padding: 2.5rem 2.6045%;
    @include breakpoint(desktop-mid){
        padding: 0 2.25rem;
    }
    @include breakpoint(tab){
        padding: 0 32px;
    }
    @include breakpoint(mobile){
        padding: 0 18px;
    }
    .cmp-container{
        max-width:1820px;
        margin:0 auto;
        display: flex;
        gap: 15px;
        @include breakpoint(desktop-mid){
            padding: 1.875rem 0 1.875rem;
        }
        @include breakpoint(tab-max){
            padding: 1.875rem 0 1.875rem;
        }
        @include breakpoint (mobile) {
            flex-direction: column;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .page-filter{
            height: 40px;
            &-selected{
                height: 42px;
                @include breakpoint(desktop-lg){
                    padding: 0;
                }
                @include breakpoint(tab-max){
                    padding: 0;
                }
                .cmp-button{
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    font-weight: 400;
                    border-radius: 50px;
                    text-transform: capitalize;
                    padding: .5rem 1.875rem;
                    color: $white;
                    background-color: $slate;
                    border: 1px solid $slate;
                    @include breakpoint(desktop-extra-sm){
                        @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    }
                    @include breakpoint(tab-max){
                        @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    }
                }
            }
            &-selected.primary {
                &.Button_buttonWrapper__9Vvy_ {
                    padding: 9px 30px !important;
                }
                @include ann-describtion (1rem, $lato-regular, 1.5rem);
                letter-spacing: -0.35px;
                border-radius: 50px;
                background-color: $slate;
                font-weight: 400;
                border-radius: 50px;
                text-transform: capitalize;
                height: auto;
                min-width: auto;
                @include breakpoint (tab-max) {
                    @include ann-describtion (14px, $lato-regular, 20px);
                    letter-spacing: -0.35px;

                }
            }
            &-unselected.primary {
                background-color: unset;
                @include ann-describtion (1rem, $lato-regular, 1.5rem);
                letter-spacing: -0.35px;
                font-weight: 400;
                border-radius: 50px;
                text-transform: capitalize;
                color: $slate;
                @include breakpoint (tab-max) {
                    @include ann-describtion (14px, $lato-regular, 20px);
                    letter-spacing: -0.35px;

                }
                &.Button_buttonWrapper__9Vvy_{
                padding: 0;
                border-radius: 50px;
                height: 42px;
                background-color: unset;
                color: $slate;
                padding: 0;
                border: 1px solid $slate;
                @include breakpoint(tab-max){
                    padding: 0;
                }
                .cmp-button{
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    font-weight: 400;
                    border-radius: 50px;
                    text-transform: capitalize;
                    padding: .5rem 1.875rem;
                    color: $slate;
                    background-color: $white;
                    border: 1px solid $slate;
                    @include breakpoint(desktop-extra-sm){
                        @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    }
                    @include breakpoint(tab-max){
                        @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    }
                }
            }   
        }
    }
    }
}
// Blog Text only hero - teaser version.
.blog-text-hero{
    padding: 0 2.6045%;
    @include breakpoint(desktop-mid){
        padding: 0 2.25rem;
    }
    @include breakpoint(tab){
        padding: 0 32px;
    }
    @include breakpoint(mobile){
        padding: 0 18px;
    }
    .cmp-teaser{
        max-width:1820px;
        margin:0 auto;
        &__content{
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            @include breakpoint(desktop-extra-sm){
                padding-bottom: 30px;
            }   
            @include breakpoint(tab-max){
                padding-bottom: 15px;
            }
        }
        &__title{
            width: 100%;
            h1, h2, h3, h4, h5, h6, p{
                font-size:3.375rem;
                line-height:3.875rem;
                letter-spacing:-1px;
                font-family:$playfair-regular;
                color:$text-dark-grey;                           
                @include breakpoint(desktop-extra-lite-sm){
                    font-size:36px;
                    line-height:44px;
                    letter-spacing:-2px
                }
            }
            &:only-child{
                margin-bottom:0;
            }
        }
        &__description{ 
            margin-top: 15px;  
            @include breakpoint(desktop-extra-sm){
                margin-top: 10px;  
            }
            @include breakpoint(tab-max){
                margin-top: 10px;
            }
            h1,h2,h3,h4,h5,h6,p{
                @include text-style(1.375rem, 2rem, normal); 
                font-family: $lato-regular;
                color:$text-dark-grey;
                @include breakpoint(desktop-extra-lite-sm){
                    font-size:18px;
                    line-height:24px;
                    letter-spacing:0.5px;
                }
                @include breakpoint(tab-max){
                    font-size:18px;
                    line-height:24px;
                    letter-spacing:0.5px;
                }
            }
        }
        &__action-container{
            margin-top: 60px;  
            @include breakpoint(desktop-extra-sm){
                margin-top: 50px;  
            }
            @include breakpoint(tab-max){
                margin-top: 50px;
            }
            .cmp-teaser__action-link{
                margin-right: 20px;
                @include breakpoint(desktop-extra-sm){
                     margin-right: 15px;
                } 
                @include breakpoint(tab-max){
                    margin-right: 15px;
                    margin-bottom: 15px;
                }
                &.gbh-primary-button{
                    @include ann-describtion(1rem, $lato-regular, 1.5rem); 
                    height: auto;
                    width: auto;
                    font-weight: 400;
                    border-radius: 50px;
                    text-transform: capitalize;
                    padding: 8px 30px;
                    color: $white;
                    background-color: $charcoal;
                    border: 1px solid $charcoal;
                    @include breakpoint(desktop-extra-sm){
                        @include ann-describtion(14px, $lato-regular, 20px);
                    }
                    @include breakpoint(tab-max){
                        @include ann-describtion(14px, $lato-regular, 20px);
                    }
                }
                &.gbh-secondary-button{
                    height: auto;
                    width: auto;
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    font-weight: 400;
                    border-radius: 50px;
                    text-transform: capitalize;
                    padding: .5rem 1.875rem;
                    color: $charcoal;
                    background-color: $white;
                    border: 1px solid $charcoal;
                    @include breakpoint(desktop-extra-sm){
                        @include ann-describtion(14px, $lato-regular, 20px);
                    }
                    @include breakpoint(tab-max){
                        @include ann-describtion(14px, $lato-regular, 20px);
                    }
                }
            }  
        }
    }
    &.teaser{
        .cmp-teaser{
            &__description{
                p{
                    color:$text-dark-grey;
                }
            }
        }
    }
}