.favorites {
  padding-bottom: 120px;

  .masonry { 
    -webkit-column-gap: 3em;
    -moz-column-gap: 3em;
    column-gap: 3em;
    margin-bottom: 3em;
    padding: 0;

      .item {
          display: inline-block;
          padding: 0;
          margin: 0 0 3em 0;
          width: 100%;
          transition: 1s ease all;
          -webkit-transition: 1s ease all;
          -moz-transition: 1s ease all;
            img{ 
              max-width:100%;
            }
        }
  }
  &__close{
    cursor: pointer;
  }
  &__container {
    max-width: 86.25rem;
  }

  &__header {
      background: $color-white;
  }

  &__title {
      font-family: $font-light;
      color: $color-slate;
      font-size: 100px;
      letter-spacing: -7.5px;
      line-height: 110px;
      margin: 0;
  }

  &__tab-header {
      justify-content: center;
      border-bottom: 1px solid $color-dark-gray;
      margin-top: 60px;
  }
  &__tab-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tab-button {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: transparent;
    border-bottom-width: 4px;
    background-color: transparent;
    font-family: $font-medium;
    font-size: 20px;
    padding: 15px 30px;
    line-height: 28px;
    letter-spacing: -0.4px;
    text-align: center;
    color: $color-dark-gray;
    transition: .3s ease all;
    -webkit-transition: .3s ease all;
    -moz-transition: .3s ease all;
    &.--active {
      border-bottom: 4px solid $color-charcoal;
      color: $color-charcoal;
    }
  }

  &__favorite-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
    margin-bottom: -20px;
    min-height: 74px;
    .sort {
      margin-left: 0;
      &__drop-down {
        top: 74px;
        right: 14px;
      }
    }
  }

  &__manage-tools {
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 24px;
    position: relative;
    background:transparent;
    border: none;
    padding-left: 20px;

    .manage-tools-icon {
        position: absolute;
        left: 0;
        top: 23%;
        margin: 0;
    }
  }

  &__sticky-nav-wrap {
      opacity: 0;
      z-index: 4;
      display: none;
    &.show-nav-wrap {
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      top: 20px;
      background: #fff;
      opacity: 1;
      transition: opacity .5s ease-in-out;
    }
  }

  &__sticky-nav {
    background: $color-white;
    transition: all 0.3s ease;
    padding-right: 15px !important;
    padding-left: 15px !important;

    &--shadow {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    }

    .manage-strip-controls {
      display: flex;
      justify-content: space-between;
      padding:20px;
      position: relative;
      align-items: center;

      .manage-selected-items {
        color: $color-slate;
        font-family: $font-light;
        font-size: 26px;
        letter-spacing: -1.5px;
        line-height: 34px;
      }

      .close-icon {
        cursor: pointer;
          img {
              width: 16px;
              height: 16px;
          }
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
      .manage-menu-item {
          display: inline-block;
          margin-right: 20px;
          color: $color-charcoal;
          font-family: $font-regular;
          font-size: 18px;
          letter-spacing: -0.45px;
          line-height: 24px;
          margin-right: 30px;
          cursor: pointer;
          &:last-child {
            margin-right: 40px;
          }
          &--disable-remove{
            cursor: default;
            color: $color-dark-gray;
        }
      }
  }

  &__tab-content {
    margin-top: 53px;
    margin-bottom: 68px;

    .products-tab {
      .product-tile {
        width: auto;
        margin: 10px;
      }
    }

    .image-tile {
      cursor: pointer;
      .product-tile__price, 
      .product-tile__discount-price {
        padding-right: 0;
        text-align: right;
        @media screen and (max-width:767px) {
          padding-right: 0;
          padding-left: 0;
          text-align: left;
        }
      }
      img{
        width:100%
      }
      .product-tile__image {
        padding-bottom: 133.4%;
      }
    }

    .product-tile__color-swatch {
      img {
        width: 20px;
      }
    }

    .video-tile {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .play-icon {
        background: rgba(0,0,0,0.7);
        position: absolute;
        display: flex;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        justify-content: center;
        align-items: center;
      }
      video {
        width: 100%;
      }
    }

    .article-tile {
      display: block;
      background: $color-extra-light-gray;
      text-decoration: none;
      cursor: pointer;

      .content-section {
        padding: 20px;
          .category-name {
            color: $color-slate;
            font-family: $font-medium;
            font-size: 16px;
            letter-spacing: -0.35px;
            line-height: 22px;
          }
          .article-title {
            color: $color-slate;
            font-family: $font-light;
            font-size: 26px;
            letter-spacing: -1.5px;
            line-height: 34px;
            margin-bottom: 10px;
            @media screen and (max-width:767px) {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.55px;
              font-family: $font-regular; 
            }
          }
          .sub-title {
            color: $color-slate;
            font-family: $font-regular;
            font-size: 20px;
            letter-spacing: -0.65px;
            line-height: 28px;
            margin-bottom: 10px;
            @media screen and (max-width:767px) {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.45px;  
            }
          }
          .description {
            color: $color-dark-gray;
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
            @media screen and (max-width:767px) {
              font-size: 14px;
              line-height: 22px;
              letter-spacing: -0.45px;
            }
          }
      }
      .image-section {
        max-height: 300px;
        img {
          height: 300px;
          object-fit: cover;
          width: 100%;
          display:block;
        }
      }
    }
  }

  &__modal {
      max-width: 420px;
  }

  &__modal-title {
      font-family: $font-light;
      font-size: 36px;
      letter-spacing: -1.7px;
      line-height: 44px;
      color: $color-slate;
      padding-top: 35px;
  }

  &__modal-desc {
      margin: 20px 0 40px;
      font-family: $font-regular;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
  }
  &__modal-cta {
      display: flex;
      column-gap: 20px;
      .button {
          width: 100%;
      }
  }

  &__load-more {
    width: 100%;
    height: 40px;
    background: $color-white;
    border: solid 1px $color-slate;
    font-family: $font-medium;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.25px;
    text-align: center;
    color: $color-slate;
    transition: all 0.3s ease-in-out;
    margin-bottom: 43px;

    &:hover {
      background: $color-slate;
      color: $color-white;
    }
  }

  &__back-to-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__go-back {
    display: flex;
    font-family: $font-regular;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.45px;
    color: $color-charcoal;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;

    img {
      height: 20px;
      margin-left: 15px;
    }
  }

  
  .edit-wrapper {
    position: relative;
    margin-bottom: 50px;
    max-width: 300px;

    &.--selected {
      border-radius: 4px;
      padding: 2px;
      border: 1px solid $color-extra-light-gray;
    }
   
    .icon-select {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      background: $color-white;
      border: 1px solid $color-extra-light-gray;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .modal-popup {
        .modal-popup-content {
          min-height: auto ;
          width: 90% ;
          .modal-popup-body{
            padding: 50px 0 0 0 ;
          }
        }
    }
  .kf-react-container {
    @media screen and (max-device-width: 991px)  and (orientation: landscape){
      .kf-react-button.large {
        padding: 0 ;
        }
    }
  }
}
@media  screen and (min-width:768px) and (max-width: 896px) {
  .modal-popup {
        .modal-popup-content {
          .modal-popup-body{
            padding: 50px 30px 30px 30px ;
          }
        }}
      }
@media  screen and  (max-width:810px) , only screen and  (max-width: 1024px) and (orientation: landscape) {
  &__modal-desc {
    margin: 15px 0 40px
  }
}

}

.favorites-lightbox {
display: flex;
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 1004;
height: 100vh;
background: rgba(0, 0, 0, 0.5);
overflow-y: auto;

&__modal {
  background: $color-white;
  margin: auto;
  position: relative;
  width: 50%;
  padding: 10px;
  text-align: center;
  &__scroll{
    width: 100%;
  }
  video {
      width: 100%;
  }
  &-img{
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    mix-blend-mode: multiply;
    height: 100%;
  }
}

&__close {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  width: 40px;
  height: 40px;
  align-items: center;
  z-index: 1;
  img {
      cursor: pointer;
      width: 16px;
      height: 16px;
  }
}
}

.favorite-no-results {
padding: 0 65px 65px 110px;
width: 599px;
.no-result-description {
  p {
    color: $color-slate;
    font-family: $font-light;
    font-size: 60px;
    letter-spacing: -3.1px;
    line-height: 68px;
    margin-top: 60px;
  }
}

.redirect-to-shopping {
  color: $color-slate;
  font-family: $font-regular;
  font-size: 20px;
  letter-spacing: -0.65px;
  line-height: 28px;
  margin-top: 40px;
    p{
      color: $color-slate;
      font-family: $font-regular;
      font-size: 20px;
      letter-spacing: -0.65px;
      a {
        color: $color-slate;
        font-family: $font-regular;
        font-size: 20px;
        letter-spacing: -0.65px;
        text-decoration: none;
        border-bottom: 1px solid $color-slate;
    }
  }

}
}

.my-masonry-grid {
display: flex;
margin-left: -60px;
width: auto;
}

.my-masonry-grid_column {
padding-left: 60px;
background-clip: padding-box;
}

@media screen and (min-width: 1280px) {
  .favorites-lightbox {
    &__modal {
      width: 30%;
    }
  }
}



@media only screen and (max-width: 320px) {
  .favorites {
    .masonry {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
  }
  }

@media only screen and (max-width:  810px), only screen and  (max-width:  990px) and (orientation: landscape){
   .favorites{
    &__back-to-top {
       border-top: solid 1px $color-light-gray;
       padding-top: 20px;
       justify-content:flex-end;
    }

    .edit-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px){
.favorites {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
}
@media only screen and (min-width: 768px) {
.favorites {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
}
@media only screen and (max-width: 414px) {
  .favorites{
    .product-tile{
      &__description{
        display: none;
      }
      &__details{
        display: block;
      }
      &__price{
        padding-left: 0;
      }
    }
  }
  
}
@media only screen and (max-width: 860px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 991px){
  .favorites {
    .product-tile {
      &__colors--swatch {
        display: none;
      }
      &__compare-main{
        height: 0;
      }
    }
    &__tab-content {
      margin-top: 31px;
    }
    .edit-wrapper {
      margin-bottom: 20px;
    }

    .article-tile {
      .content-section {
        padding:15px 10px;
      }
      .image-section {
        max-height: 360px;
        img {
          height: 360px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px){
  .favorites {
    .product-tile {
      &__colors--swatch {
        display: none;
      }
      &__compare-main{
        height: 0;
      }
    }
    &__tab-content {
      margin-top: 43px;
    }
    .edit-wrapper {
      margin-bottom: 20px;
    }
    .article-tile {
      .content-section {
        padding:15px 10px;
      }
      .image-section {
        max-height: 160px;
        img {
          height: 160px;
        }
      }
    }
    &__close{
      float:right
    }
  }
}

@media screen and (max-width: 1023px) {
.my-masonry-grid {
  display: flex;
  margin-left: -20px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}

.edit-wrapper {
  margin-bottom: 20px;
}

.favorites {
  &__title {
    font-size: 68px;
    letter-spacing: -4.5px;
    line-height: 60px;
  }

  &__sticky-nav {
    .manage-strip-controls {
      .close-icon {
          right: 20px;
      }
      .manage-selected-items {
          font-size: 22px;
      }
    }
  }

  &__controls {
    .manage-menu-item {
      font-size: 16px;
    }
  }

  &__tab-button {
    font-size: 20px;
    display: flex;
    gap: 5px;
  }

  &__load-more {
    margin: 0 18px 40px 18px;
  }

  &__back-to-top {
    margin: 0 18px;
  }
  .modal-popup {
    .modal-popup-content {
      .modal-popup-body{
        width:100%
      }
    } 
  }
}

}
@media screen and (max-width: 767px) {
.favorites {
   .modal-popup {
        .modal-popup-content {
          .modal-popup-body{
            padding: 50px 0 10px 0 ;
          }
        }}

 .masonry {
  .item {
    img {
      width: 100%;
    }
  }
 }

 &__title {
    font-size: 46px;
    letter-spacing: -2.0px;
    line-height: 50px;
  }

  &__tab-buttons {
    justify-content: flex-start;
  }

  &__tab-content {
    margin-top: 43px;
    margin-bottom: 68px;
    .video-tile {
      .play-icon {
        img {
          width: auto !important;
        }
      }
    }
  }

  &__tab-header {
    margin-top: 0;
    overflow-x: scroll;
  }

  &__controls {
    display: block;
  }

  &__sticky-nav {
    .manage-strip-controls {
      display: block;
      justify-content: space-between;
      padding: 20px;
      position: relative;
      align-items: center;
    }
  }

  &__sticky-nav-wrap {
    z-index: 4;
    display: none;
  }

  &__modal {
      padding: 0 30px 10px 30px;
  }

  &__modal-title {
    font-size: 32px;
    letter-spacing: -2.2px;
    line-height: 40px;
  }

  &__modal-desc {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.55px;
  }

  &__modal-cta {
    display: block;
    a {
      margin-bottom: 20px;
    }
  }
}

.favorites-lightbox {
  &__modal {
    width: 90%;
  }
}

.favorite-no-results {
  padding: 0;
  width: 100%;
  .no-result-description {
    p {
      font-size: 40px;
      letter-spacing: -2.5px;
      line-height: 60px;
      margin-top: 40px;
    }
  }
  .redirect-to-shopping {
    margin-top: 35px;
  }
}
} 
@media screen and (max-width: 767px) {
  .favorites__tab-content{
     margin-top: 43px;
     .product-tile__description {
        display: none;
      }
     .product-tile__price{
        padding-left: 0px;
      }
     .product-tile__details{
       flex-direction: column;
     }
     .article-tile {
      .content-section {
        padding: 15px 10px;
      }
     }
   }
}
