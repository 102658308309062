.bg-dark-blue-two {
    .basic-text-promo {
        .cmp-teaser {
            .gbh-secondary-button {
                border: 1px solid $white;
                color: $white;
            }

            &__action-link:nth-child(2) {
                color: $white;
                border-bottom: 1px solid $cool-light-grey;
            }
        }
    }
}

.teaser.basic-text-promo {
    padding: 0 2.25rem;

    @include breakpoint(desktop-xxxlg) {
        padding: 0;
    }

    @include breakpoint(tab) {
        padding: 0 2rem;
    }

    @include breakpoint(mobile) {
        padding: 0 1.125rem;
    }

    .cmp-teaser {
        max-width: 1380px;
        margin: 0 auto;
        display: block;
        padding: 4.375rem 0 4.375rem;

        @include breakpoint(tab-max) {
            max-width: 100%;
            padding: 3.125rem 0;
        }

        &__content {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-start;

            @include breakpoint(tab) {
                flex-wrap: wrap;
                align-items: flex-start;
            }

            @include breakpoint(mobile) {
                flex-direction: column;
            }
        }

        &__title {
            width: 37.375rem;

            @include breakpoint(tab) {
                width: 45.320%;
            }

            @include breakpoint(mobile) {
                width: 100%;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include headingtitle_h5;
            }
        }

        &__description {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                font-family: $lato-regular;
                font-size: 1rem;
                letter-spacing: 0;
                line-height: 1.5rem;
                color: $text-medium-dark-grey;
                padding: 0;
            }

            width: 17.875rem;

            @include breakpoint(tab) {
                width: 45.320%;
            }

            @include breakpoint(mobile) {
                width: 100%;
                padding-top: 2.1875rem;
            }
        }

        &__action-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            align-content: flex-end;
            position: relative;
            width: auto;

            @include breakpoint(tab) {
                align-content: flex-end;
                align-items: center;
                width: 100%;
                margin-top: 2.1875rem;
            }

            @include breakpoint(mobile) {
                width: 100%;
                align-items: center;
                padding-top: 2.1875rem;
            }
        }

        &__action-link {
            margin-left: 0;

            &:nth-child(2) {
                margin: 0 0 0;
                line-height: 16px;
                letter-spacing: .114375rem;
                padding: 0;
                padding-bottom: .4375rem;
                color: $color-light-blue;
                border-color: $color-light-blue;
            }

            &.gbh-secondary-button {
                padding: .75rem 1.6875rem;
                color: $color-medium-blue;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 2px;
                font-family: $font-lato;
                @include breakpoint(tab) {
                    width: 45.320%;
                    display: inline-flex;
                }
            }
        }
    }
}

.teaser.basic-text-promo-two-up {
    @extend .basic-text-promo;

    .cmp-teaser {
        &__content {
            flex-wrap: wrap;

            @include breakpoint(tab) {
                align-items: flex-start;
            }
        }

        &__title {
            color: $text-dark-grey;
            width: auto;

            @include breakpoint(tab) {
                width: 45.320%;
            }

            @include breakpoint(mobile) {
                width: 100%;
            }
        }

        &__description {
            width: 100%;
            order: 3;
            margin-top: 1.25rem;

            @include breakpoint(tab) {
                width: 45.320%;
                margin: 0 0 1.25rem;
                order: unset;
            }

            @include breakpoint(mobile) {
                width: 100%;
                margin-top: 0;
                order: unset;
                padding-top: 2.1875rem;
            }
        }

        &__action-container {
            @include breakpoint(tab) {
                margin-top: 15px;
            }
        }
        .cmp-teaser__action-link.gbh-secondary-button {
            font-family: $lato-bold;
             font-size: 12px;
             line-height: 24px;
             letter-spacing: 2.5px;
             font-family: $lato-bold;
         }
        
    }
}

.teaser.basic-text-promo-two-up-without-desc {
    @extend .basic-text-promo;
    padding: 0 2.25rem;

    @include breakpoint(tab) {
        padding: 0 2rem;
    }

    @include breakpoint(mobile) {
        padding: 0 1.125rem;
    }

    .cmp-teaser {
        &__title {
            width: 48.625rem;
            margin-right: auto;

            @include breakpoint(tab) {
                width: 45.320%;
            }

            @include breakpoint(mobile) {
                width: 100%;
                text-align: center;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                @include breakpoint(tab-max) {
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                    letter-spacing: -0.073125rem;
                }
            }
        }

        &__description {
            width: 17.875rem;
            margin-right: auto;

            @include breakpoint(tab) {
                width: 21.25rem;
                margin-right: 0;
            }
        }

        &__action-container {
            @include breakpoint(tab) {
                width: 45.320%;
                margin: 0;
            }
        }

        &__action-link {
            &.gbh-secondary-button {
                padding: .75rem 1.6875rem;

                @include breakpoint(tab-max) {
                    width: 100%;
                    display: inline-flex;
                }
            }
        }
        .cmp-teaser__action-link.gbh-secondary-button {
            font-family: $lato-bold;
             font-size: 12px;
             line-height: 24px;
             letter-spacing: 2.5px;
             font-family: $lato-bold;
         }
    }
}

.bg-white .teaser.basic-text-promo .cmp-teaser .cmp-teaser__action-container .cmp-teaser__action-link.gbh-secondary-button {
    border-color: $color-medium-blue;
    color: $color-medium-blue;
}