.delivery-option {
    &__title {
        font-family:  $font-light;
        color: $color-slate;
        font-size: 60px;
        line-height: 1.13;
        letter-spacing: -3.1px;
        margin: 0;
    }
    &__alert-box {
        padding: 20px 25px;
        margin-top: 30px;
        border-radius: 5px;
        background-color: $color-dark-gray;
        &.--general {
            margin-top: 0;
            margin-bottom: 50px;
        }
    }
    &__alert-title {
        color: $color-white;
        font-family: $font-medium;
        font-size: 16px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }
    &__alert-mssg {
        p {
            display: inline-block;
            color: $color-white;
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
        }
        a {
            cursor: pointer;
            text-decoration: underline;
            color: $color-white;
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
                color: $color-white;
            }
        }
    }

    &__row {
        margin-bottom: 50px;
        .option-title {
            font-family: $font-medium;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.4px;
            color: $color-charcoal;
            margin-bottom: 10px;
        }

        .option-details {
            margin-bottom: 30px;
            @media screen and (max-width: 990px) {
                margin-bottom: 35px;
            }
            font-family: $font-regular;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
        }

        .cart-item__total-price {
            margin-top: 0;
        }
        .cart-item{
            &__label{
                &.--quantity{
                    font-size: 16px;
                    text-decoration: underline;
                    @media screen and (max-width: 991px) {
                        text-decoration: none;
                    }
                }
            }
        }
        .combo-cart-items {
            .cart-item {
                &__label{
                    &.--quantity{
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &__shipping-type-row {
        padding: 0;
        display: flex;
    }

    &__shipping-type-column, &__freight-column {
        min-height: 214px;
        padding: 25px;
        border-radius: 5px;
        border: solid 1px $color-light-gray;
        background-color: $color-white;
        cursor: pointer;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
        @media screen and (max-width: 990px) {
            margin-right: 0;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        &.active {
            background-color: $color-off-white;
            border: solid 1px $color-dark-gray;
            .delivery-type {
                color: $color-slate;
            }
        }

        .delivery-date {
            font-family: $font-medium;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.4px;
            color: $color-charcoal;
            margin-bottom: 10px;
        }

        .delivery-type {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-dark-gray;
            margin-bottom: 20px;
        }

        .delivery-charges {
            font-family: $font-light;
            font-size: 26px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-slate;
        }
    }
    &__freight-column {
        width: 100%;
        min-height: inherit;
        padding: 25px;
    }
    &__shipping-type-column {
        width: 100%;

        &.active {
            min-height: 0;
        }
    }
    &__process-error, &__process-error p {
        text-align: right;
        margin-top: 20px;
        color: $color-error-red;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 22px;
    }
    &__submit-button {
        min-width: 180px;
        float: right;
    }

  }
  
  @media only screen and (max-width: 990px) {
    .delivery-option {
        &__title {
            font-size: 32px;
        }

        &__shipping-type-row {
            flex-direction: column;
        }
    
        &__shipping-type-column, &__freight-column {
            width: 100%;
            min-height: inherit;
            display: grid;
            grid-template-columns: 5fr 1fr;
            &:last-child {
                margin: 0;
            }
            .delivery-date {
                order: 1;
            }
            .delivery-charges {
                order: 2;
                span {
                    float: right;
                }
            }
            .delivery-type {
                order: 3;
            }
        }
        &__submit-button {
            width: 100%;
        }
    }
  }
  
  @media only screen and (max-width: 480px){
    .delivery-option {
        &__title {
            font-size: 32px;
        }

        &__row {
            .option-title {
                font-family: $font-light;
                font-size: 22px;
                line-height: 32px;
                letter-spacing: -1.2px;
                color: $color-charcoal;
            }
    
            .option-details {
                letter-spacing: -0.65px;
            }
            .cart-item {
                padding-bottom: 0;
                &__info{
                    display: none;
                }
                &__specs{
                    margin-bottom: 0;
                    width: 100%;
                }
                &__cta-wrapper{
                    margin-top: 10px;
                }
            }
        }

        &__shipping-type-row {
            flex-direction: column;
        }
    
        &__shipping-type-column, &__freight-column {
            width: 100%;
            min-height: inherit;
            display: block;
            .delivery-charges {
                span {
                    float: none;
                }
            }
        }
        &__submit-button {
            width: 100%;
        }
    }
}