.commerce-teaser {
    width: 206px;

    &__background-gray{
        background-color: $color-charcoal;
        display: grid;
        padding: 10px;
    }
    &__background{
        .commerce-teaser__image-section{
            .commerce-teaser__image{ 
                width: 100%;
                height: 275px;
                object-fit: cover;
            }
        }
    }
    &__title-gray{
        font-family: $font-regular;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.4px;
        color: $color-white;
        padding-top: 15px;
    }
    &__description-gray{
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color:$color-light-gray;
        padding-top: 15px;
        padding-bottom: 10px;
    }
    &__title{
        font-family: $font-regular;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.4px;
        color: $color-charcoal;
        padding-top: 15px;
    }
    &__description{
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        padding-top: 15px;
        padding-bottom: 10px;
    }
}