.video-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1004;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

    &__content {
        margin: auto;
        position: relative;
        .modal-popup {
            .modal-popup-content {
                height: auto;
                .modal-popup-body {
                    width: 900px;
                    height: 80vh;
                    padding: 55px 40px;
                    .close-button {
                        top:5px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px) and (orientation: landscape) {
        &__content {
            top: 22px;
            .modal-popup {
                .modal-popup-content {
                    .modal-popup-body {
                        width: 80vw;
                        height: 80vh;
                        .close-button {
                            top:5px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) and (orientation: portrait) {
        &__content {
            .modal-popup {
                .modal-popup-content {
                    min-height: auto;
                    .modal-popup-body {
                        width: 100vw;
                        .close-button {
                            top:10px;
                        }
                    }
                }
            }
        }
    }
}