.teaser.history-module-vertical-image{
    padding:4.375rem 0;
    @include breakpoint(desktop-mid){
        padding-right:2.25rem;
        padding-left:2.25rem;
    }
    @include breakpoint(tab){
        padding:50px 32px;
    }
    @include breakpoint(mobile){
        padding:50px 18px;
    }
    .cmp-teaser{
        max-width:1380px;
        margin:0 auto;
        display:flex;
        align-items: center;
        justify-content: space-between;
        &__content{
            max-width:33.75rem;
            margin:2.25rem 11.25rem 0 3.75rem;
            padding: 0;
            @include breakpoint(tab){
                margin:52px 63px 0 0;
                max-width:408px;
            }
            @include breakpoint(mobile){
                margin:0 0 50px;
            }
        }
        &__pretitle > *{
            @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
            letter-spacing:-1.5px;
            margin-bottom:4.375rem;
            @include breakpoint(tab-max){
                font-size:36px;
                line-height:44px;
                letter-spacing:-2px;
                margin-bottom:40px;
            }
        }
        &__title{
            padding: 0 !important;
            *{
                @include ann-describtion(1.125rem, $lato-regular, 24px);
                letter-spacing: normal;
            }
        }
        &__description{
            max-width:480px;
            margin-top:1.25rem;
            @include breakpoint(tab-max){
                margin-right:0;
            }
            *{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing:normal;
            }
        }
        &__image{
            max-width:37.5rem;
            width:100%;
            @include breakpoint(tab-max){
                max-width:277px;
            }
            .cmp-img-caption{
                display: block;
            }
        }
    }
    &.image-right{
        .cmp-teaser{
            flex-direction:row-reverse;
            @include breakpoint(mobile){
                flex-direction:column-reverse;
            }
        }
    }
    .cmp-img-caption{
        display: none;
        max-width:1380px;
        margin:20px auto 0;
        *{  
            max-width:37.5rem;
            @include ann-describtion(12px, $lato-regular, 16px);
            letter-spacing:.0138rem;
            margin-left:auto !important;
                @include breakpoint(tab-max){
                    max-width:277px;
                }
                @include breakpoint(mobile){
                    margin:0 auto !important;
                }
            }
    }
}
.history-module-sqr-image{
    padding:4.375rem 0;
    @include breakpoint(desktop-mid){
        padding-right:2.25rem;
        padding-left:2.25rem;
    }
    @include breakpoint(tab){
        padding:50px 32px;
    }
    @include breakpoint(mobile){
        padding:50px 0;
    }
    .cmp-teaser{
        max-width:1380px;
        margin:0 auto;
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        &__content{
            max-width:30.625rem;
            margin:10rem 0 0 7.5rem;
            @include breakpoint(tab){
                margin:17px 0 0 37px;
                max-width:100%;
            }
            @include breakpoint(mobile){
                margin:0 18px 50px;
            }
        }
        &__pretitle, &__pretitle > *{
            @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
            letter-spacing:-1.5px;
            margin-bottom:4.375rem;
            @include breakpoint(tab-max){
                font-size:36px;
                line-height:44px;
                letter-spacing:-2px;
                margin-bottom:40px;
            }
        }
        &__title{
            *{
                @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                letter-spacing: normal;
            }
        }
        &__description{
            max-width:100%;
            margin-top:1.25rem;
            @include breakpoint(desktop-lg){
                margin-right:0;
            }
            @include breakpoint(tab-max){
                margin-right:0;
            }
            *{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing:normal;
            }
        }
        &__image{
            max-width:48.125rem;
            width:100%;
            @include breakpoint(tab-max){
                min-width: 372px;
            }
            @include breakpoint(mobile){
                max-width:100%;
            }
        }
    }
    &.image-left{
        .cmp-teaser{
            @include breakpoint(mobile){
                flex-direction:column-reverse;
            }
        }
    }
    .cmp-img-caption{
        max-width:1380px;
        margin:20px auto 0;
        *{  
            max-width:37.5rem;
            @include ann-describtion(12px, $lato-regular, 16px);
            letter-spacing:.0138rem;
            @include breakpoint(desktop-lg){
                max-width:41.25rem;
            }
            @include breakpoint(tab-max){
                max-width:372px;
            }
            @include breakpoint(mobile){
                margin:0 18px !important;
            }
            }
    }
}
.searchbar-open, .gbh-of-scroll{
    .history-module-with-video, .dot-whack-teaser, .dot-whack-teaser-small, .in-page-search, .product-details__sticky-wrapper, .curated-hero-banner, .product-listing__sticky-nav-wrap, .breadcrumb{
        z-index:0;
        position: relative;
    }
    .inspiration-list__sticky-nav-wrap, .inspiration-list__tile-col .inspiration-landing-tile__wrapper .inspiration-landing-tile__varient-list, .inspiration-list__sticky-nav-wrap .sticky.nav-up{
        z-index: 0;
    }
}

.plppage:has(.gbh-product-menu.open){
    .curated-hero-banner, .product-listing__sticky-nav-wrap, .breadcrumb{
        z-index:0;
        position: relative;
    }
}