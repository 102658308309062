.image-gallery{
    width: 100%;
    margin-bottom: 44px;
    &__stack{
      height: 60vh;
    }

    &__image-stack-view{
        display:flex;
        overflow-x: auto;
        // padding-left: 32px;
        padding-left: 0px;
        height: 100%;
        margin-right: -50px;
        margin-left: 0;
        
        &::-webkit-scrollbar {
            height: 5px;
          }
          &::-webkit-scrollbar-track {
            //box-shadow: inset 0 0 2px #757575; 
            background-color: #e6e6e6;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: #757575; 
            border-radius: 5px;
          }
          &::-webkit-scrollbar-button {
            width: 25px;
          }
    }

    &__image-stack-list{
      display: inline-flex;
      padding-right: 3px;
      padding-bottom: 40px;
      height: 100%;
      img{
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        mix-blend-mode: multiply;
      }
      &:hover {
        cursor: pointer;
      }
      iframe {
        height: 100%;
        margin: auto;
        aspect-ratio: 16/9;
        @media only screen and (max-width: 1024px) and (orientation: portrait) {
          height: 500px;
        }
        @media only screen and (max-width: 768px) and (orientation: portrait) {
          height: 400px;
        }
        @media only screen and (max-width: 480px) and (orientation: portrait) {
          height: 200px;
        }
      }
    }

    &__thumb{
        display: flex;
        padding-left: 45px;
        padding-top: 35px;
    }
    
    &__image-thumb-view{
        display:flex;
        overflow-x: auto;
        padding-left: 32px;
        margin-left: 0;
        align-items: center;
    }

    &__image-thumb-list{
        display: inline-block;
        padding-right: 5px;
    }

    &__image-thumb{
        height: 66px;
        width: 88px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            mix-blend-mode: multiply;
        }
        &:hover {
          cursor: pointer;
      }
    }
  
    &__image-thumbSelected{
      border: 1px solid $color-dark-gray;
      width: 88px;
      padding: 2px;
      border-radius: 5px;
      height: 66px;
      img {
        width: 100%;
        height: 60px;
        object-fit: cover;
        object-position: 50% 50%;
        mix-blend-mode: multiply;
      }
      &:hover {
        cursor: pointer;
    }
    }
  }
  
@media only screen and (min-width: 768px) and (max-width: 991px){
  .image-gallery{
    &__image-stack-view {
      margin-left: 0px;
    }
    &__image-thumb-view{
      padding-left: 5px;
    }
  }
}

@media only screen and (max-width: 1920px){
  .image-gallery{
    &__image-stack-list {
       img{
        height: 100%;
       }
    }
 }
}

@media only screen and (max-width: 900px) and (orientation: landscape){
  .modal-popup-body{
    .image-gallery{
      &__stack{
        height: 51vh;
        margin-bottom: 15px;
      }
      &__thumb{
        height: 28vh;
      }
      &__image-stack-list{
        padding-bottom: 11px;
      }
      &__image-stack-view{
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px #757575; 
          border-radius: 5px;
        }
      }
      &__image-thumb-view{
        padding-left: 19px;
        margin-left: 0;
      }
    }
  }
  .image-gallery{
    &__stack{
      height: 450px;
    }
    &__thumb{
      height: 103px;
      padding-left: 0px;
    }
    &__link-more{
      display: flex;
      align-items: center;
      color: #757575;
      font-size: 14px;
      cursor: pointer;
    }
    &__image-stack-view{
      margin-left: 0;
      &::-webkit-scrollbar {
          display: none;
        }
    }
    &__image-thumb-view{
      padding-left: 0;
      margin-left: 0;
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px #757575;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #757575; 
        border-radius: 5px;
      }
      &::-webkit-scrollbar-button {
        width: 25px;
      }
    }
  }
  .tab-content{
    .image-gallery{
      &__image-thumb-view{
        padding-left: 29px;
        margin-left: 0;
        &::-webkit-scrollbar-button {
          width: 15px;
        }
      }
      &__image-stack-view{
        padding-left: 29px;
        margin-left: 0;
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
.image-gallery {

    &__stack {
      height: 487px;
      margin-bottom: 13px;
    }

    &__image-stack-view{
      // padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }

    &__thumb{
      padding-left: 0px;
      height: 135px;
    }
    &__link-more {
        display: flex;
        align-items: center;
        color: #757575;
        font-size: 14px;
        cursor: pointer;

        &.viewmore-mobile {
          width: 80px;
          text-align: center;
          display: block;
          line-height: 24px;
          margin: 0 20px;
        }
      }

      &__image-thumb-view{
        padding-left: 0px;
        margin-left: 0px;
      } 

      &__image-stack-list{
        padding-bottom: 0px;
        img{
          width: 375px;
          object-fit: contain;
        }
      }

      &__image-stack-view{
        
        &::-webkit-scrollbar {
            display: none;
          }
      }
      &__image-thumb-view{
        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px #757575; 
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: #757575; 
          border-radius: 5px;
        }
      }
    }
    .tab-content{
      .image-gallery{
        &__image-thumb-view{
          padding-left: 18px;
          &::-webkit-scrollbar-button {
            width: 10px;
          }
        }
      }
    }
}
  