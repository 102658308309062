.two-large-featured-image {
    padding: 4.375rem 0;
    margin: 0 1.875rem 0 0;
    @include breakpoint(tab) {
        padding: 3.125rem 0;
        margin: 0 .625rem 0 0;
    }
    @include breakpoint(mobile) {
        padding: 0;
        margin: 0 0 2.5rem 0;
    }
    &:first-child {
        @include breakpoint(mobile) {
            padding-top: 3.125rem;
        }
    }
    &:last-child {
        margin: 0;
        @include breakpoint(mobile) {
            padding-bottom: 3.125rem;
            margin: 0;
        }
    }
    .cmp-teaser {
        display: flex;
        flex-direction: column;
        &__image {
            @include breakpoint(desktop-small) {
                min-height: 48.125rem;
                max-width: 42.1875rem;
                width: 100%;
            }
            @include breakpoint(desktop-mid){
                min-height: 100%;
                max-width: 100%;
            }
            @include breakpoint(mobile){
                min-height:400px;
            }
            .cmp-image__image {
                @include breakpoint(desktop-small) {
                    min-height: 48.125rem;
                }
                @include breakpoint(mobile){
                    min-height:inherit;
                }
                img {
                    @include breakpoint(desktop-small) {
                        min-height: inherit;
                    }
                    @include breakpoint(mobile){
                        min-height:inherit;
                    }
                }
            }
        }
    }
    .cmp-img-caption {
        padding: 1.875rem 0 0 0;
        @include breakpoint(tab-max) {
            padding: 1.5625rem 0 0;
        }
        h1, h2, h3, h4, h5, h6, p {
            @include ann-describtion(0.75rem, $lato-regular, 1.1875rem);
            letter-spacing: normal;
            color: $text-dark-grey;
            margin: 0;
            a{
                @include ann-describtion(0.75rem, $lato-regular, 1.1875rem);
                color: $text-dark-grey;
                margin: 0;
                letter-spacing: normal;
            }
        }
    }
}