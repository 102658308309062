.support-files {
    color: $color-white;
    background-color: $color-charcoal;
    font-family: $font-light;
    font-stretch: normal;
    font-style: normal;
    .kf-react-container {
        margin: 0 auto;
    }
    &__content {
        max-width: 1260px;
        margin: 0 auto;
        border-bottom: 1px solid $color-dark-gray;
    }
    .collapsible__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            font-size: 60px;
            line-height: 68px;
            letter-spacing: -3.1px;
        }

        .plus {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;

          .line {
            background: $color-white;
            transition: all 0.5s ease;

            &.line-1 {
              width: 100%;
              height: 1px;
            }
            &.line-2 {
              width: 1px;
              height: 100%;
              transform: translateX(-15px);
            }
          }
        }
    }
    .Collapsible__trigger {
        padding-top: 25px;
        padding-bottom: 40px;
        display: block;
        &.collapsible__trigger {
            padding-bottom: 35px;
            .plus {
                .line {
                    &.line-2 {
                    height: 0;
                    }
                }
            }
        }
    }
    &__download-section {
        padding-bottom: 50px;
    }
    &__sub-heading {
        font-family: $font-light;
        font-size: 26px;
        color: $color-white;
        line-height: 34px;
        letter-spacing: -1.5px;
        display: inline-block;
    }
    &__file-type {
        img {
            padding-right: 17.1px;
        }
    }
    .technical-tile__download-section {
        grid-template-columns: auto 240px;
        grid-template-rows: 40px auto auto;
        color: $color-light-gray;
        padding-top: 0px;
        .technical-tile__select-all{
            .checkbox{
                display: inline-flex;
            }
            .checkbox_default {
                color: $color-light-gray;
            }
        }
        .checkbox {
            color: $color-light-gray;
            .checkbox_default {
                color: $color-light-gray;
            }
            .checkbox__marker {
                background-color: $color-white;
            } 
            input:checked ~ .checkbox__border .checkbox__marker {
                background-color: $color-black;
            }
            input:checked ~ span {
                color: $color-light-gray;
            }
        }
        .technical-tile__file-type {
            color: $color-white;
        }
        .technical-tile__files {
            padding-bottom: 50px;
            padding-top: 40px;
            width: 720px;
            section {
                padding-top: 15px;
                padding-bottom: 15px;
                border-bottom-color: $color-dark-gray;
                &:last-child {
                    padding-bottom: 0px;
                }
            }
            &.--technical-info {
                section {
                    div {
                        color: $color-white;
                        font-family: $font-medium;
                    }
                    .checkbox {
                        color: $color-light-gray;
                        .checkbox__marker {
                            background-color: $color-white;
                        }
                        input:checked ~ .checkbox__border .checkbox__marker {
                            background-color: $color-black;
                        }
                        input:checked ~ span {
                            color: $color-light-gray;
                        }
                    }
                }
                &__border {
                    border-bottom: 1px solid $color-dark-gray;
                }
            }
            &.--installation {
                section {
                    max-width: 310px;
                    min-width: unset;
                    @media all and (max-width: 767px) {
                        max-width: unset;
                    }
                }
            }
        }
    }
    &__install-support {
        grid-column-start: 1;
        grid-column-end: 3;
        display: grid;
        grid-template-columns: auto 240px;
        padding-top: 40px;
    }
    &__support {
        margin-top: 20px;
    }
    &__videos {
        margin-bottom: 40px;
        margin-top: 10px;
        display: inline-block;
        section {
            padding-bottom: 10px;
            &:last-child {
                padding-bottom: 0;
            }
            img {
                height: 14px;
                padding-right: 10px;
            }
        }
    }
    &__support-links {
        section {
            padding-bottom: 10px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    &__parts-links {
        margin-top: 40px;
        display: inline-block;
        section {
            padding-bottom: 10px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    &__links {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.35px;
        color: $color-white;
        text-decoration: none;
        padding-bottom: 7px;
        &:hover {
            color: $color-white;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.support-files__container {
    background-color: $color-charcoal;
    @media all and (min-width: 991px) {
        .support-files:first-child {
            .support-files__content {
                border-top: 1px solid $color-dark-gray;
            }
        }
    }
    padding-top: 50px;
    padding-bottom: 50px;
    &.--isDiscontinued{
        .support-files{ 
            &__links { 
                color: $color-dark-gray; 
                &:hover {
                    color: $color-dark-gray; 
                }
            }
            .collapsible__header {
                span {
                    color: $color-slate;
                }
                .plus{
                    .line{
                        background: $color-charcoal;
                    }
                }
            }
                background-color: $color-white;
        }
        .technical-tile__download-section {
            .checkbox {
                color: $color-dark-gray;
                .checkbox__border{
                    outline: none;
                }
                .checkbox__marker{
                    background-color: initial;
                }
                input:checked ~ .checkbox__border .checkbox__marker {
                    background-color: $color-kohler-blue;
                }
                input:checked ~ span {
                    color: $color-dark-gray;
                }
            }
            .technical-tile__file-type {
                color: $color-dark-gray;
            }
            .technical-tile__download-btn {
                background-color: $color-white;
                border: 1px solid $color-slate;
                color: $color-slate;
                &.disabled {
                    background-color: $color-slate;
                    border: none;
                    color: $color-dark-gray;
                }
            }
            .technical-tile__files {
                section {
                    border-bottom-color: $color-dark-gray;
                }
                &.--technical-info {
                    section {
                        span {
                            color: $color-dark-gray;
                        }
                        div {
                            color:  $color-dark-gray;
                        }
                        .checkbox {
                            color: $color-dark-gray;
                            .checkbox__marker{
                                background-color: initial;
                            }
                            input:checked ~ .checkbox__border .checkbox__marker {
                                background-color: $color-kohler-blue;
                            }
                            input:checked ~ span {
                                color: $color-dark-gray;
                            }
                        }
                    }
                }
            }
        }
            background-color: $color-white;
    }
}

@media all and (max-width: 990px) {
    .support-files {
        border-top: 1px solid $color-slate;
        &__content {
            border-bottom: none;
        }

    
        &:last-child {
            border-bottom: none;
        }
        .Collapsible__trigger {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .collapsible__header {
            span {
                font-size: 40px;
                line-height: 1.15;
                letter-spacing: -2.2px;
            }
        }

        .technical-tile__download-section {
            padding-top: 40px;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            .technical-tile {
                &__download-btn {
                    grid-row-start: 10;
                }
                &__files {
                    padding-top: 0px;
                    width: 100%;
                }
            }
        }
        &__install-support {
            padding-top: 0;
            grid-column-gap: 20px;
        }
        &__install {
            margin-bottom: 20px;
        }
        &__support {
            margin-top: 10px;
        }
        &__parts-links {
            margin-top: 30px;
        }
        &__videos {
            margin-bottom: 30px;
        }
        &__support-links {
            margin-bottom: 20px;
        }
    }
    .support-files__container {
        padding-top: 0;
        &.--isDiscontinued {
            .collapsible__header {
                span {
                    color: $color-slate;
                }
                .plus {
                    .line {
                        background: $color-charcoal;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 480px) {
    .support-files {
        &__install-support {
            grid-template-columns: 1fr;
            .technical-tile__files {
                padding-bottom: 40px;
            }
        }
        &__install {
            margin-bottom: 0;
        }
        &__support {
            margin-top: 0;
            margin-bottom: 20px;
        }
        &__parts-links {
            margin-top: 20px;
        }
        &__videos {
            margin-top: 30px;
            margin-bottom: 0;
        }
        &__support-links {
            margin-top: 30px;
            margin-bottom: 0;
        }
        .Collapsible__trigger {
            padding-bottom: 25px;
        }
        .technical-tile__download-section {
            .technical-tile__files {
                &.--technical-info {
                    margin-right: 0;
                }
            }
        }
        &__links {
            padding-bottom: 14px;
        }
    }
}

@media print{
    .support-files__container {
        display: none;
    }
}