.order-tile {
  display: grid;
  row-gap: 35px;
  grid-template-columns: 5fr 1fr;
  padding-top: 30px;
  margin-bottom: 50px;
  border-top: 1px solid $color-dark-gray;

  &:last-child {
    margin-bottom: 0;
  }

  &__order-no {
    order: 1;
    font-family: $font-light;
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: -1.6px;
    color: $color-slate;
  }

  &__details {
    order: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__label {
    margin-bottom: 5px;
    font-family: $font-medium;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.35px;
    color: $color-charcoal;
  }

  &__value {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-slate;
  }

  &__view-order {
    order: 2;
  }

  &__date {
    order: 1;
  }

  &__items {
    order: 2;
  }

  &__total {
    order: 3;
  }

  &__status {
    order: 4;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 4fr 2fr;

    &__order-no {
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
    }

    &__details {
      grid-template-columns: 2fr repeat(3, 1fr);
    }
    .kf-react-button {
      line-height: 24px;
    }
    .kf-react-button.ghost {
      color: $color-slate;
    }
  }

  @media screen and (max-width: 576px) {
    margin-bottom: 40px;
    grid-template-columns: 1fr;

    &__details {
      order: 2;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
    }

    &__view-order {
      order: 3;
    }

    &__items {
      order: 3;
    }

    &__total {
      order: 4;
    }

    &__status {
      order: 2;
    }
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    grid-template-columns: 4fr;
    margin-bottom: 40px;
    &__view-order {
      order: 3;
    }
  }

  @media screen and (min-width: 1024px) {
    &__view-order {
      padding-left: 30px !important;
      padding-right: 30px !important;
      line-height: 24px;
    }
  }
}
