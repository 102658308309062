.virtual-designer-details {
  position: relative;

  .top-section {
    flex-flow: column;
    display: flex;
    flex-direction: row;
    position: relative;
    .top-left {
      order: 1;
      -ms-flex-order: 1;
      padding: 80px 60px 50px 100px;
    }
    .top-right {
      order: 2;
      -ms-flex-order: 2;
      height: 772px;
    }
  }

  &__breadcrumb {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: $color-slate;
    margin-top: 30px;
    display: inline-block;
    position: absolute;
    left: 107px;
    order: 3;
    top: 0;
    z-index: 1;
    .cmp-breadcrumb__item {
      padding: 0;
    }
    .cmp-breadcrumb__item-link {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: $color-slate;
      text-decoration: none;
      &:after {
        content: "/";
        margin: 0 2px;
      }
    }
    .cmp-breadcrumb__item:last-child .cmp-breadcrumb__item-link:after {
      content: "";
    }
    &-link {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: $color-slate;
      text-decoration: none;
    }
  }

  &__profile-section {
    background-color: $color-off-white;

    .partner-photography {
      width: 140px;
      height: 185px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%;
        mix-blend-mode: multiply;
      }
    }

    .professional-name {
      color: $color-slate;
      font-family: $font-light;
      font-size: 100px;
      font-weight: 300;
      letter-spacing: -7.5px;
      line-height: 110px;
      padding-top: 50px;
      padding-bottom: 47px;
    }

    .profile-details {
      display: flex;
      justify-content: space-between;
      max-width: 90%;
      margin-bottom: 20px;
    }

    .professional-address,
    .professional-designation,
    .miles-away {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-slate;
    }

    .miles-away {
      margin-top: 20px;
    }

    .available-btn {
      display: inline-block;
      font-family: $font-medium;
      line-height: 12px;
      letter-spacing: 0.65px;
      padding: 9px 12px;
      margin-top: 25px;
      border: 1px solid $color-slate;
      color: $color-slate;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 12px;
    }

    .available-month {
      display: inline-block;
      font-family: $font-medium;
      line-height: 12px;
      letter-spacing: 0.65px;
      padding: 0;
      margin-top: 25px;
      color: $color-slate;
    }

    .professional-photography {
      width: 153px;
      max-height: 180px;
      background-color: $color-off-white;
      margin-top: 0;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 30px;
      img {
        width: 100%;
      }
    }
  }

  .cover-image-wrapper {
    .cover-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
    }
  }

  &__contact-section {
    background-color: $color-charcoal;
    color: $color-white;
    padding: 61px 0 98px 0;

    .profile-description {
      p {
        font-family: $font-regular;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-light-gray;
        padding-right: 165px;
        text-align: justify;
      }
    }

    .contact-wrapper {
      display: flex;
      .speciality-title,
      .contact-title {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-dark-gray;
        margin-bottom: 20px;
      }

      .specialities,
      .contacts {
        font-family: $font-regular;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-light-gray;
        padding-right: 80px;
      }

      .specialities {
        p {
          font-family: $font-regular;
          font-size: 20px;
          letter-spacing: -0.65px;
          line-height: 36px;
          color: $color-light-gray;
        }
      }

      .contacts {
        p {
          font-family: $font-regular;
          font-size: 20px;
          letter-spacing: -0.65px;
          line-height: 36px;
          color: $color-light-gray;

          a {
            font-family: $font-regular;
            font-size: 20px;
            letter-spacing: -0.65px;
            line-height: 36px;
            color: $color-light-gray;
            text-decoration: none;

            &:hover {
              color: $color-light-gray;
            }
          }
        }

        .social-medias {
          display: flex;
          margin-top: 37px;
          .social-icon {
            margin: 0 10px;
            width: 17px;
            display: block;
            cursor: pointer;
            text-align: center;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 13.7%;
      padding-right: 60px;
    }
    &__breadcrumb {
      left: 14%;
    }
  }
}
@media only screen and (min-width: 1581px) and (max-width: 1591px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 102px;
    }
    &__breadcrumb {
      left: 102px;
    }
  }
}
@media only screen and (min-width: 1569px) and (max-width: 1580px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 96px;
    }
    &__breadcrumb {
      left: 96px;
    }
  }
}
@media only screen and (min-width: 1560px) and (max-width: 1568px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 92px;
    }
    &__breadcrumb {
      left: 92px;
    }
  }
}
@media only screen and (min-width: 1509px) and (max-width: 1559px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 65px;
    }
    &__breadcrumb {
      left: 65px;
    }
  }
}
@media only screen and (min-width: 1481px) and (max-width: 1508px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 63px;
    }
    &__breadcrumb {
      left: 63px;
    }
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1480px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 40px;
    }
    &__breadcrumb {
      left: 51px;
    }
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1440px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding-left: 33px;
    }
    &__breadcrumb {
      left: 38px;
    }
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding: 50px 20px;
    }
    .top-section .top-right {
      height: 510px;
    }
    &__breadcrumb {
      left: 40px;
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1300px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .virtual-designer-details {
    .top-section .top-left {
      padding: 50px 32px;
    }
    .top-section .top-right {
      height: 510px;
    }
    &__breadcrumb {
      left: 40px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .virtual-designer-details {
    overflow: hidden;

    &__breadcrumb {
      left: 36px;
      top: -10px;
    }

    &__profile-section {
      padding: 0 15px 30px 18px;

      .desktop-only {
        display: none;
      }

      .professional-details {
        margin-right: 20px;
      }

      .professional-name {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -2.2px;
        max-width: 100%;
        padding-top: 20px;
        padding-bottom: 25px;
      }

      .profile-details {
        justify-content: space-between;
      }
      .professional-photography {
        width: 120px;
        margin: 0;
      }

      .available-btn {
        margin-top: 20px;
      }
    }

    &__contact-section {
      padding: 50px 18px 35px 18px;
      .profile-description {
        p {
          padding: 0 0 35px 0;
        }
      }
      .contact-wrapper {
        display: block;
        .speciality-title,
        .contact-title {
          margin-bottom: 10px;
        }
        .specialities,
        .contacts {
          padding: 0;
          margin-bottom: 35px;
        }

        .specialities {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .virtual-designer-details {
    overflow: hidden;
    .top-section .top-left {
      order: 2;
      padding: 0px 18px 50px;
    }
    .top-section .top-right {
      order: 1;
      height: 282px;
    }
    &__breadcrumb {
      position: relative;
      left: 0;
      order: 3;
      top: 0;
      padding: 20px 3px;
      margin: 0;
    }

    &__profile-section {
      padding: 0 0 30px 18px;

      .desktop-only {
        display: none;
      }

      .professional-details {
        margin-right: 10px;
      }

      .professional-name {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -2.2px;
        max-width: 100%;
        padding-top: 30px;
      }

      .professional-photography {
        width: 180px;
        margin: 0;
        position: absolute;
        right: 0;
        bottom: -15px;
      }
    }

    &__contact-section {
      padding: 50px 0px 35px;
      .profile-description {
        p {
          padding: 0 0 35px 0;
        }
      }

      .contact-wrapper {
        display: block;
        .speciality-title,
        .contact-title {
          margin-bottom: 10px;
        }
        .specialities,
        .contacts {
          padding: 0;
          margin-bottom: 35px;
        }

        .specialities {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
