.full-bleed-image{
    padding:0;
    .cmp-teaser{
        &__content{
            width: 33.75rem;
            margin: 0 auto;
            padding: 5.625rem 0 5.313rem;
            text-align: center;
            @include breakpoint(tab){
                width:29.75rem;
                padding: 2.5rem 0 45px;
            }
            @include breakpoint(mobile){
                width:100%;
                padding: 2.5rem 1.125rem 45px;
            }
        }
        &__pretitle{
            @include ann-describtion(.875rem, $lato-regular, 1rem);
            margin-bottom: 2.1875rem;
            letter-spacing:0.125rem;
            text-transform:uppercase;
            @include breakpoint(tab-max){
                @include ann-describtion(0.75rem, $lato-regular, 1rem);
                margin-bottom: 1.5625rem;
            }
        }
        &__title{
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(.875rem, $lato-regular, 1rem);
                letter-spacing:0.125rem;
                text-transform:uppercase;
                @include breakpoint(tab-max){
                    @include ann-describtion(0.75rem, $lato-regular, 1rem);
                    margin-bottom: 1.5625rem;
                }
            }
        }
        &__description{
            margin: 2.1875rem 0 1.875rem;
            @include ann-describtion(1rem, $lato-regular, 1.5rem);
            text-align:left;
            @include breakpoint(tab-max){
                text-align: center;
                margin:1.5625rem 0 1.875rem;
            }
        }
        &__action-link{
            padding:0;
            line-height:.75rem;
            padding-bottom:.4375rem;
        }
        &__image{
            max-height:746px;
            @include breakpoint(tab){
                max-height:453px;
            }
            @include breakpoint(mobile){
                min-height:475px;
            }
            img{
                max-height:inherit;
                @include breakpoint(mobile){
                    min-height:inherit;
                }
            }
        }
    }
}
.full-bleed-image-hover-content{
    .cmp-teaser{
        position: relative;
        &__image{
            max-height:57rem;
            @include breakpoint(tab){
                max-height:362px;
            }
            @include breakpoint(mobile){
                min-height:461px;
                max-height:461px;
            }
            .cmp-image{
                max-height:inherit;
                min-height:inherit;
            }
            img{
                width:100%;
                height:100%;
                object-fit:cover;
                max-height:inherit;
                min-height:inherit;
            }
        }
        &__content{
            position:absolute;
            padding:4.375rem 6.875rem;
            display:flex;
            flex-direction:row;
            justify-content:flex-start;
            font-family:$lato-regular;
            color:$white;
            width:100%;
            text-align: left;
            @include breakpoint(desktop-small){
                bottom:-30px;
            }
            transition:0.5s ease-in-out;
            @include breakpoint(tab-max){
                color:inherit;
                position:static;
                padding-top:40px;
                padding-bottom:50px;
                flex-direction:column;
            }
            @include breakpoint(tab){
                padding:42px 32px 31.5px;
            }
            @include breakpoint(mobile){
                max-width:276px;
                padding-right:18px;
                padding-left:18px;
                box-sizing:content-box;
            }
        }      
        &__title{
            max-width:15rem;
            padding-right:6.25rem;
            @include breakpoint(tab){
                max-width:100%;
                padding:0;
                margin-bottom: 10px;
            }
            @include breakpoint(mobile){
                max-width:210px;
                padding-right:0;
                margin-bottom: 40px;
            }
            *{
                font-size:1.375rem;
                line-height:2rem;
                letter-spacing:normal;
                font-family: $font-lato;
                color: $white;
                @include breakpoint(tab-max){
                    color:$text-dark-grey;
                }
            }
        }
        &__description{
            *{
                font-size:1rem;
                line-height:1.5rem;
                letter-spacing:normal;
                letter-spacing: -0.55px;
                color: $white;
                @include breakpoint(tab-max){
                    color:$text-medium-dark-grey;
                }
            }
        }
        .cmp-teaser__action-container{
            .cmp-teaser__action-link{
                font-size:11px;
                line-height:16px;
                letter-spacing:1.83px;
                padding-bottom:7px;
                margin-top:1.875rem;
                color:$white;
                border-color: $white;
                @include breakpoint(tab-max){
                    color:$color-light-blue;
                    border-color:$color-light-blue;
                }
                @include breakpoint(tab-max){
                    margin-top:30px;
                }
                
            }
        }
     
        .hover-content-organized{
            max-width: 22.5rem;
            @include breakpoint(tab-max){
                max-width:100%;
            }
            @include breakpoint(tab){
                margin-top:14px;
            }
            @include breakpoint(mobile){
                margin-top:40px;
            }
        }
    }
}
// Full Width Image Overlay New Enhancement Start...

// Variation 1
.teaser.full-bleed-img-overlay {
    padding:0;
    .cmp-teaser {
        &__image {
            height: auto;
            @include breakpoint(desktop-lg){
                height:auto;
            }
            @include breakpoint(tab-max){
                height:auto;
            }
            &.cq-dd-image{
                @include breakpoint(mobile) {
                    position: absolute;
                    top: 9.3rem;
                    height: 29rem;
                    left: 0;
                    right: 0;
                }
                &:after{
                    @include breakpoint(mobile){
                        background: unset;
                    }
                }
            }
        }
        &__content {
            width: 380px; 
            position: absolute;
            top: 0;
            bottom: 0;   
            left:50%;
            right: 0;
            margin: 4.375rem 0 6.875rem;
            margin-left:-690px;
            max-width:1380px;
            z-index: 1;
            @include breakpoint(desktop-small){
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
            }
            @include breakpoint(desktop-mid){
                max-width: 100%;
                margin-left: 0;
                left: 0;
                padding: 0 2.25rem;
            }
            @include breakpoint(tab) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                left: 0;
                margin: 2.5rem 2rem 2.9375rem;
            }
            @include breakpoint(mobile) {
                width: 100%;
                left: 0;
                bottom: unset;
                padding: 3.125rem 1.125rem;
                margin: 0;
                position: relative;
            }
        }
        &__pretitle {
            h1,h2,h3,h4,h5,h6,p{
                text-transform: uppercase;
                padding-bottom: 26.75rem;
                @include ann-describtion(.75rem, $lato-regular, 1rem);
                letter-spacing: .125rem;
                @include breakpoint(desktop-small){
                    position: absolute;
                    top: 0;
                }
                @include breakpoint(tab){
                    position: absolute;
                    top: 0;
                }
                @include breakpoint(mobile){
                    padding-bottom: .9375rem;
                    color: $text-medium-black;
                }
            }
        }
        &__title {
            h1,h2,h3,h4,h5,h6,p{
                @include headingtitle_h6;
                @include breakpoint(tab){
                    @include ann-describtion(1.375rem, $lato-regular, 2rem);
                }
                @include breakpoint(mobile) {
                    padding: 0 0 2.8125rem;
                    @include ann-describtion(1.375rem, $lato-regular, 2rem);
                    color: $text-medium-black !important;
                }
            }
        }
        &__description {
            padding: 1.25rem 0 3.125rem;
            width:100%;
            @include breakpoint(tab) {
                width: 21.125rem;
                padding: .9375rem 0 1.875rem;
            }
            @media only screen and (min-width: 561px) and (max-width: 767px){
                padding: 0;
                width: 100%;
                margin-top: 24rem;
            }
            @include breakpoint(mobile-portrait) {
                padding: 0;
                margin-top: 15.375rem;
                width: 100%;
                color: $text-medium-black;
            }
            p {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0;
                @include breakpoint(mobile){
                    color: $text-medium-black !important;
                }
            }
        }
        &__action-link.gbh-secondary-button {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            padding: 0 27px;
            height: 60px;
            align-items: center;
            font-family: $lato-regular;

            @include breakpoint(tab-max) {
                height: 50px;
                margin: 0;
            }
        }
    }
    &.fc-white{
        .cmp-teaser{
            &__action-link{
                @include breakpoint(tab-max){
                    color:$color-light-blue;
                    border-bottom: 1px solid $color-light-blue;
                }
                &.gbh-secondary-button{
                    @include breakpoint(desktop-small){
                        color: $white;
                        border:1px solid $white;
                    }
                    @include breakpoint(tab){
                        color: $white;
                        border-color: $white;
                    }
                    @include breakpoint(mobile){
                        color: $charcoal;
                        border-color: $charcoal;
                    }
                }
            }
        }
    }
    &.fc-stone{
        .cmp-teaser{
            &__action-link{
                @include breakpoint(tab-max){
                    color:$color-light-blue;
                    border-bottom: 1px solid $color-light-blue;
                }
                &.gbh-secondary-button{
                    @include breakpoint(desktop-small){
                        color: $text-dark-grey;
                        border:1px solid $text-dark-grey;
                    }
                    @include breakpoint(tab-max){
                        color: $color-medium-blue-cap;
                        border-color: $color-medium-blue-cap;
                    }
                }
            }
        }
    }     
}
// Variation 2,3,4
.teaser.full-bleed-image-right{
    padding:4.375rem 2.25rem;
    @include breakpoint(tab){
        padding: 3.125rem 2rem;
    }
    @include breakpoint(mobile){
        padding: 3.125rem 1.125rem;
    }
    .cmp-teaser {
        max-width:1380px;
        margin:0 auto;
        display: flex;
        justify-content:space-between;
        align-items: center;
        align-content: center;
        padding: 0;
        @include breakpoint(tab){
            align-items: flex-start;
        }
        @include breakpoint(mobile){
            flex-direction: column;
            position: relative;
        }
        &__content{
            margin: 0;
            padding: 0;
            width: 27.319%;
            position: unset;
            @include breakpoint(tab){
                width: 40%;
                padding-right:0;
                margin: 0;
            }
            @include breakpoint(mobile){
                margin: 0;
                width: 100%;
            }
        }
        &__pretitle {
            @include breakpoint(desktop-small){
                padding: 0;
                margin: 0;
            }
            @include breakpoint(mobile){
                position: absolute;
                top: 0;
                margin-bottom: 15px;
            }
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(.75rem, $lato-regular, 1rem);
                 letter-spacing: 2px;
                 text-transform: uppercase;
            }           
        }
        &__title {
            padding-top: 5rem;
            @include breakpoint(tab){
                padding-top: .9375rem;
            }
            @include breakpoint(mobile){
                margin-bottom: 35.6875rem;
                position: absolute; 
                top: 24px;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
                letter-spacing: -1.5px;
                padding: 0;
                @include breakpoint(tab-max){
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                    letter-spacing: -0.175rem; 
                }
                @include breakpoint(mobile){
                    position: static;
                }
            }
        }
        &__description{
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: normal; 
                color: $text-light-grey !important; 
                padding-top: 2.5rem;
                @include breakpoint(desktop-small){
                    width: 15rem;
                    margin-bottom: 45px;
                }
                @include breakpoint(tab){
                    padding: 2.5rem 0;
                    width: 17rem;
                    margin-top:0;
                    margin-bottom: 40px;
                }
                @include breakpoint(mobile){
                    padding: 2.1875rem 0 2.5rem;
                    margin: 0;
                    margin-bottom: 40px;
                }
                
            }
        }
        &__image {
            width: 69.566%;
            @include breakpoint(tab){
                width: 45.3%;
            }
            @include breakpoint(mobile){
                width: 100%;
                margin-top: 72px;
                padding-left: 0;
            }            
            .cmp-image {
                @include breakpoint(desktop-small){
                    max-height:60rem;
                }
                @include breakpoint(tab){
                    min-height:21.1875rem;
                }
                @include breakpoint(mobile){
                    min-height:21.1875rem;
                }
            }
            .cmp-teaser__image{
                width: unset;
                @include breakpoint(tab){
                    width: unset;
                }
                @include breakpoint(mobile){
                    width: unset;
                    margin-top: 85px;
                    padding-left: 0;
                }  
            }
        }
        &__action-link.gbh-secondary-button {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            padding: 0 27px;
            height: 60px;
            align-items: center;
            font-family: $lato-regular;

            @include breakpoint(tab-max) {
                height: 50px;
                margin: 0;
            }
        }
    }
    &.content-left{
        .cmp-teaser{
            @include breakpoint(desktop-small ){
                flex-direction: row-reverse;
            }
            @include breakpoint(tab){
                flex-direction: row-reverse;
            }
        }
    }
}
.bg-white .cmp-teaser__action-container .cmp-teaser__action-link.gbh-secondary-button{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;
    padding: 0 27px;
    height: 60px;
    align-items: center;
    font-family: $lato-regular;

    @include breakpoint(tab-max) {
        height: 50px;
        margin: 0;
    }
}
// Variation 5,6,7
.image-cluster{
    padding: 4.375rem 2.25rem;
    @include breakpoint(tab){
        padding: 3.125rem 2rem;
    }
    @include breakpoint(mobile){
        padding: 3.125rem 0; 
    }
    .cmp-container{
        position: relative;
        display: flex;
        flex-direction: row;
        max-width: 1380px;
        margin: 0 auto;
        justify-content: space-between;
        @include breakpoint(mobile){
            flex-direction: column-reverse;
        }          
    }
    .teaser-content-left{  
        width:19.1875rem;
        padding: 0;
        display: flex;
        align-items: center;
        margin:0 auto 0 0;
        @include breakpoint(tab-max){
            padding:0;
        }
        @include breakpoint(tab){
            width: 39.70%;
        }
        @include breakpoint(mobile){
            width: 100%;
        }
        .cmp-teaser{
            justify-content: flex-end;
            @include breakpoint(mobile){
                position: unset;
            }
            &__image{
                display: none;
            }
            &__content{
                width: 19.1875rem;
                margin:0;
                @include breakpoint(desktop-mid){
                    padding:0;
                }
                @include breakpoint(tab){
                    width: 18.5625rem;
                    padding: 1.75rem 0 0 0;
                }
                @include breakpoint(mobile){
                    width: 100%;
                    position: unset;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(3.375rem, $playfair-regular, 3.875rem);
                    letter-spacing: -0.0625rem;
                    @include breakpoint(tab){
                        @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                        width: 18.5625rem;
                        letter-spacing: -0.125rem;
                    }
                    @include breakpoint(mobile){
                        @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                        letter-spacing: -0.125rem;
                        padding-bottom: 2.5rem;
                        width: 18.75rem;
                    }
    
                }
                @include breakpoint(mobile){
                    position: absolute;
                    top: 0;
                    width: 100%;
                    padding: 0 1.125rem;
                }
            }
            &__description{
                padding: 3.125rem 0 2.5rem ;
                margin:0 auto 0 0;
                @include breakpoint(tab){
                    padding: 1.875rem 0 2.5rem ;
                    width: 17rem;
                }
                @include breakpoint(mobile){
                    margin: 0;
                    padding: 0 1.125rem;
                    width: 100%;
                }
                p{
                    color: $text-medium-dark-grey;
                    @include breakpoint(mobile){
                        padding: 1.875rem 0 2.5rem;
                    }
                }
            }
            &__action-link{
                @include ann-describtion(.75rem, $lato-bold, 1rem);
                letter-spacing: .15625rem;
                border: 1px solid $color-medium-blue;
                padding: .75rem 1.6875rem .875rem;
                @include breakpoint(mobile){
                    width: 100%;
                    padding: 0.9375rem 1.6875rem; 
                    margin: 0;
                }
            }
            &__action-container{
                @include breakpoint(mobile){
                    padding: 0 1.125rem;
                }
            }
        }
    }
}
.teaser-content-left {
    @extend .image-right-text-center;
    padding: 5.9375rem 6.875rem 6.25rem;
    @include breakpoint(tab) {
        padding: 3.625rem 2rem 3.875rem;
    }
    @include breakpoint(mobile){
        padding: 3.75rem 1.125rem;
    }
    .cmp-teaser {
        justify-content: space-between;
        padding: 0;
        position: relative;
        &__content {
            width: 23.5625rem;
            position: relative;
            @include breakpoint(mobile){
                padding: 0;
                width:100%;
            }
        }
        &__pretitle {
            padding-bottom: 4.875rem;
            margin: 0;
            @include breakpoint(tab-max) {
                padding-bottom: 0.625rem;
            }            
        }
        &__title {
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
                padding: 0;
                margin: 0;
                letter-spacing: -0.09375rem;
                @include breakpoint(tab) {
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                    letter-spacing: -0.075rem;
                }
                @include breakpoint(mobile){
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                }
            }
        }
        &__description {
            width: 15rem;
            padding: 2.5rem 0 3.375rem;
            margin: 0;
            @include breakpoint(tab) {
                padding: 2.5rem 0;
            }
            @include breakpoint(mobile) {
                width: 100%;
                padding: 2.1875rem 0 3.375rem;
                margin: 31rem 0 0;
            }
        }
    }
}
.image-cluster-right{
    width: 61.44%;
    margin: 0;
    @include breakpoint(tab){
        width: 50.15%;
    }
    @include breakpoint(mobile){
        width: 100%;
        margin-top: 7.875rem;
    }
    .cmp-container{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        @include breakpoint(tab-max){
            justify-content: space-between;
            align-content: space-between;
        }
        .image {
            width: 48.23%;
            &:nth-child(1) { 
                height: 100%;
                padding-bottom: 1.4375rem;
                .cmp-image{
                    height: 100%;
                    .cmp-image__image{
                        width: 100%;
                        height: 25.5625rem;
                        object-fit: cover;
                    }
                }
            }
            &:nth-child(2) { 
                height: 100%;
                padding-bottom: 1.4375rem;
                .cmp-image{
                    height: 100%;
                    .cmp-image__image{
                        width: 100%;
                        height: 25.5625rem;
                        object-fit: cover;
                    }
                }
            }
            &:nth-child(3) { 
                height: 100%;
                .cmp-image{
                    height: 100%;
                    .cmp-image__image{
                        width: 100%;
                        height: 25.5625rem;
                        object-fit: cover;
                    }
                }
            }
            &:nth-child(4) { 
                height: 100%;
                .cmp-image{
                    height: 100%;
                    .cmp-image__image{
                        width: 100%;
                        height: 25.5625rem;
                        object-fit: cover;
                    }
                }
            }
            @include breakpoint(tab){
                &:nth-child(1) { 
                    height: 100%;
                    padding-bottom: 0;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height: auto;
                            min-height: 11.25rem;
                            object-fit: cover;
                        }
                    }
                }
                &:nth-child(2) { 
                    height: 100%;
                    padding-bottom: .8125rem;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height: auto;
                            min-height: 11.25rem;
                            object-fit: cover;
                        }
                    }
                }
                &:nth-child(3) { 
                    height: 100%;
                    padding-bottom: .5625rem;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height: auto;
                            min-height: 11.25rem;
                            object-fit: cover;
                        }
                    }
                }
                &:nth-child(4) { 
                    height: 100%;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height: auto;
                            min-height: 11.25rem; 
                            object-fit: cover;
                        }
                    }
                }
            }
            @include breakpoint(mobile){
                width: 48%;
                &:nth-child(1) { 
                    height: 100%;
                    padding-left: 0;
                    padding-bottom:0;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height:auto;
                            object-fit: cover;
                            min-height: 11.25rem;
                        }
                    }
                }
                &:nth-child(2) { 
                    height: 100%;
                    padding-right: 0;
                    padding-bottom: 15px;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            min-height: 11.25rem;
                        }
                    }
                }
                &:nth-child(3) { 
                    height: 100%;
                    padding-bottom:.625rem;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height:auto;
                            object-fit: cover;
                            min-height: 11.25rem;
                        }
                    }
                }
                &:nth-child(4) { 
                    height: 100%;
                    padding-right: 0;
                    .cmp-image{
                        height: 100%;
                        .cmp-image__image{
                            width: 100%;
                            height:auto; 
                            object-fit: cover;
                            min-height: 11.25rem;
                        }
                    }
                }
            }
        }
    }
}
// Search Overlap Issue V1.
.searchbar-open{
    &.modal-open{
        .full-bleed-img-overlay{
            position: relative;
            z-index: 0;
        }
    }
}
.gbh-of-scroll{
    .full-bleed-img-overlay{
        position: relative;
        z-index: 0;
    }
}