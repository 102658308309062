.services-hero {
    padding: 0;
    max-width:1920px;
    margin:0 auto;
    &.content-left {
        .cmp-teaser {
            display: flex;
            flex-direction: row-reverse;
            @include breakpoint(tab-max) {
                flex-direction: column;
            }
            &__content {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                @include breakpoint (tab) {
                    justify-content: normal;
                }
                @include breakpoint (desktop-xxxlg) {
                    padding-left: 0px;
                    left: 50px;
                    top: 17rem;
                }
            }
        }
    }
    &.content-right {
        .cmp-teaser {
            display: flex;
            flex-direction: row;
            @include breakpoint(tab-max) {
                flex-direction: column;
            }
            &__image {
                width:44.375%;
                @include breakpoint(tab-max){
                    width:100%;
                }
                .cmp-image{
                    @include breakpoint(desktop-small){
                        max-width:710px;
                        max-height:800px;
                    }
                    @include breakpoint(tab){
                        min-width:812px;
                        min-height:380px;
                    }
                    @include breakpoint(mobile){
                        min-width:375px;
                        min-height:201px;
                    }
                }
                @include breakpoint(tab) {
                    width: 100%;
                    margin-top:13.875rem;
                }
                @include breakpoint(mobile) {
                    width: 100%;
                    margin-top:18rem;
                }
            }
            &__content {
                margin:0 auto 0 11.375rem;
                @include breakpoint(tab-max){
                    margin:0;
                }
                @include breakpoint(tab) {
                    width: 100%;
                    padding:3.125rem 2rem 3.75rem;
                }
                @include breakpoint(mobile) {
                    width: 100%;
                    padding: 2.5rem 1.125rem 3.8125rem;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6{
                    margin-bottom:3.125rem;
                    width:100%;
                    @include breakpoint(tab-max){
                        margin-bottom:1.25rem;
                    }
                }
                p {
                    font-size: 1.375rem;
                    line-height: 2rem;
                    font-family: $lato-regular;
                    margin-bottom: 1.125rem;
                    letter-spacing:normal;
                    @include breakpoint(tab) {
                        font-size: 1.125rem;
                        line-height: 1.75rem;
                        font-family: $lato-regular;
                        margin:0;
                    }
                    @include breakpoint(mobile) {
                        font-size: 1.125rem;
                        line-height: 1.75rem;
                        font-family: $lato-regular;
                    }
                }
                width:23.75rem;
                @include breakpoint(tab-max){
                    width:100%;
                    position:absolute;
                    top:0;
                }
                @include breakpoint(tab){
                    margin:5.4375rem 0 2.8125rem;
                }
                @include breakpoint(mobile){
                    margin:5.625rem 0 3.125rem;;
                    left:0;
                    padding:0 1.125rem;
                    width:19.1875rem;

                }
            }
            &__description {
                margin-top:0;
                @include breakpoint(tab) {
                    margin-bottom:2.375rem;
                }
                p {
                    letter-spacing: normal;
                    @include breakpoint(tab-max) {
                        @include ann-describtion(1rem, $lato-regular, 1.5rem);
                        letter-spacing: normal;
                    }
                }
            }
            &__action-container {
                @include breakpoint(tab){
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    align-items: center;
                    max-width:38.75rem;
                }
                @include breakpoint(mobile) {
                    text-align: center;
                }
            }
            &__action-link {
                margin-top: 1.4375rem;
                display: table;
                padding:0;
                color:$white;
                border-color:$white;
                @include breakpoint(tab-max){
                    display: inline-block;
                    align-items:center;
                    margin-top:0;
                }
                @include breakpoint(mobile) {
                    margin-top:1.5rem;
                }
                &.gbh-secondary-button{
                    padding:.75rem 1.75rem;
                    margin-top:3.125rem;
                    @include breakpoint(tab){
                        margin-top:0;
                        width:57.8%;
                    }
                    @include breakpoint(mobile){
                        margin-top:2.1875rem;
                    }
                }
            }
        }
    }
    .cmp-teaser {
        &__image {
            @include breakpoint(desktop-small){
                width: 100%;
                min-height: 57.75rem;
                max-width: 57.75rem;
            }
            @include breakpoint(mobile){
                min-height: 211px;
            }
            .cmp-image{
                min-height: inherit;
                img{
                    min-height: inherit;
                }
            }
        }
        &__content {
            width:30rem;
            padding: 0 0 0 2.6045%;
            margin: 0 auto 0 0;
            @include breakpoint(tab-max){
                margin:0 auto;
                min-height: auto;
            }
            @include breakpoint(tab) {
                position: static;
                padding: 2.5rem 0 3.125rem 2rem;
                width: 100%;
                margin-right: auto;
                left: 0;
                top: 0;
                bottom: 0;
            }
            @include breakpoint(mobile) {
                width: 100%;
                padding: 2.5rem 1.125rem 3.125rem;
            }
        }

        &__title {
            h1,h2,h3,h4,h5,h6,p{
                @include headingtitle_h2;
                width: 30rem;
                color: $text-dark-grey;
                padding-bottom: 0;
                @include breakpoint(tab-max) {
                    width: 100%;
                    letter-spacing:-0.125rem;
                    padding-right:4rem;
                    position: relative;
                }
                @include breakpoint (mobile) {
                    top: 0;
                }
            }
        }

        &__description {
            h1,h2,h3,h4,h5,h6{
                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                margin-bottom:1.125rem;
            }
            margin-top: 2.5rem;
            width: 26.25rem;
            @include breakpoint(tab-max) {
                width: 100%;
                margin-top: 1.25rem;
            }
            p {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: normal;
                color: $text-medium-dark-grey;
                margin: 0;
                @include breakpoint (mobile) {
                    padding-bottom: 30px;
                }
            }
        }
        &__action-container {
            margin-top: unset;
        }

        &__action-link {
            margin-top: 3.125rem;
            color: $color-light-blue;
            border-color:$color-light-blue;
            @include ann-describtion(11px, $lato-regular, 12px);
            letter-spacing: 1.83px;
            @include breakpoint(tab-max) {
                margin-top: 0;
                letter-spacing: 1.8px !important;
            }
        }
    }
}