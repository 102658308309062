/*============ Default SCSS ===============*/
// D 90/96/-1.75px, L/m 42/48/-1.2px 
@mixin headingtitle_h1 {
    font-size:5.625rem;
    line-height:6rem;
    letter-spacing: -0.109375rem;
    font-family:$playfair-regular;
    font-weight: normal;
    @include breakpoint(tab){
        font-size:2.625rem;
        line-height: 3rem;
        letter-spacing:-0.075rem;
    }
    @include breakpoint(mobile){
        font-size:2.625rem;
        line-height: 3rem;
        letter-spacing:-0.075rem;
    }
}
// D 70/78/-1.5px, L/m 36/44/-1px 
@mixin headingtitle_h2{
    font-size:4.375rem;
    line-height:4.875rem;
    letter-spacing: -0.09375rem;
    font-family:$playfair-regular;
    font-weight: normal;
    @include breakpoint(tab){
        font-size:2.25rem;
        line-height: 2.75rem;
        letter-spacing: -0.0625rem;
    }
    @include breakpoint(mobile){
        font-size:2.25rem;
        line-height: 2.75rem;
        letter-spacing: -0.0625rem;
    }
}
// D 54/62/-1px, L/m 28/34/-0.75px
@mixin headingtitle_h3{
    font-size:3.375rem;
    line-height:3.875rem;
    letter-spacing:-0.0625rem;
    font-family: $playfair-regular;
    font-weight: normal;
    @include breakpoint(tab){
        font-size:1.75rem;
        line-height: 2.125rem;
        letter-spacing:-0.046875rem;
    }
    @include breakpoint(mobile){
        font-size:1.75rem;
        line-height:2.125rem;
        letter-spacing: -0.046875rem;
    }
}
// D 42/54/-1px, L/m 36/44/-2px
@mixin headingtitle_h4{
    font-size:2.625rem;
    line-height:3.375rem;
    letter-spacing: -0.0625rem;
    font-family: $playfair-regular;
    font-weight: normal;
    @include breakpoint(tab){
        font-size: 2.25rem;
        line-height: 2.75rem;
        letter-spacing: -0.125rem;
    }
    @include breakpoint(mobile){
        font-size: 2.25rem;
        line-height: 2.75rem;
        letter-spacing: -0.125rem;
    }
}
// D 42/54/-1.17px, L/m 28/34/-1.17px
@mixin headingtitle_h5 {
    font-size:2.625rem;
    line-height:3.375rem;
    letter-spacing: -0.073125rem;
    font-family: $playfair-regular;
    font-weight: normal;
    @include breakpoint(tab) {
        font-size: 1.75rem;
        line-height: 2.125rem;
        letter-spacing: -0.073125rem;
    }
    @include breakpoint(mobile) {
        font-size: 1.75rem;
        line-height: 2.125rem;
        letter-spacing: -0.073125rem;
    }
}
// D 26/36/normal, L/m 18/28/normal
@mixin headingtitle_h6 {
    font-size:1.625rem;
    line-height:2.25rem;
    letter-spacing: normal;
    font-family: $lato-regular;
    font-weight: normal;
    @include breakpoint(tab) {
        font-size: 1.125rem;
        line-height: 1.75rem;
        letter-spacing: normal;
    }
    @include breakpoint(mobile) {
        font-size: 1.125rem;
        line-height: 1.75rem;
        letter-spacing: normal;
    }
}