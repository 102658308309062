.full-bleed-video{
    padding: 0;
    .cmp-teaser{
        @include breakpoint(desktop-extra-lite){
            min-height:55.625rem;
        }
        &__image{
            max-height:55.625rem;
            position: relative;
            display:flex;
            @include breakpoint(tab){
                max-height:378px;
            }
            @include breakpoint(mobile){
                min-height:300px;
            }
            .hero-video{
                width:100%;
                height:100%;
                object-fit:cover;
                @include breakpoint(tab-max){
                    max-height:inherit;
                }
                @include breakpoint(mobile){
                    min-height:300px;
                }
            }
            .poster-img{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display:block;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .gbh-play-video{
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 3;
                margin-right: 15px;
                -webkit-transition: .4s ease-in-out;
                transition: .4s ease-in-out;
                left: 0;
                top: 0;
                &.active{
                    .playinside{
                        &::before{
                            content: "";
                            background-image: url(../images/Pause-White.svg);
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 45px;
                            height: 50px;
                            z-index: 999999999;
                            margin: auto;
                            @include breakpoint(tab-max){
                                width: 32px;
                                height: 35px;
                            }
                        }
                        &::after{
                            content: "Pause video";
                            font-size: .75rem;
                            letter-spacing: .125rem;
                            line-height: 1rem;
                            text-align: center;
                            text-transform: uppercase;
                            font-family: $lato-regular;
                            display: flex;
                            justify-content: center;
                            padding-top: 2rem;
                            margin-top: 48px;
                            @include breakpoint(tab-max){
                                padding-top: 1.21875rem;
                                font-size: .625rem;
                                letter-spacing: .10625rem;
                            }
                        } 
                    }
                    +.poster-img {
                        display:none;
                    }
                }
                .playinside{
                    background: transparent;
                    border: none;
                    color:$white;
                    text-align: center;
                    width:100%;
                    height:100%;
                    &::before{
                        content: "";
                        background-image: url(../images/Play-white.svg);
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 45px;
                        height: 50px;
                        z-index: 999999999;
                        margin: auto;
                        @include breakpoint(tab-max){
                            font-size:36px;
                        }
                    }
                    &::after{
                        content: "Play video";
                        font-size: .75rem;
                        letter-spacing: .125rem;
                        line-height: 1rem;
                        text-align: center;
                        text-transform: uppercase;
                        font-family: $lato-regular;
                        display: flex;
                        justify-content: center;
                        padding-top: 1.84375rem;
                        margin-top: 48px;
                        @include breakpoint(tab-max){
                            padding-top: 1.21875rem;
                            font-size: .625rem;
                            letter-spacing: .10625rem;
                        }
                    }
                }
            }
            .hero-mute-icon{
                color:$white;
                position: absolute;
                bottom: 48px;
                right:50px;
                font-size: 26px;
                width:30px;
                height:26px;
                cursor: pointer;
                z-index:8;
                transform: translate(-50%,-50%);
                @include breakpoint(tab){
                    right:17px;
                    bottom:30px;
                }
                @include breakpoint(mobile){
                    right:3px;
                    bottom:15px;
                }
                .icon-Mute{
                    &:before{
                        content:"\e936";
                        font-family:$icon-moon;
                    }
                }
                .icon-Un-Mute{
                    &:before{
                        content:"\e938";
                        font-family:$icon-moon;
                    }
                }
            }
            .vidio_popup_close_button{
                display:none;
            }
        }
        &__pretitle, &__title{
            *{
                @include ann-describtion(14px,$lato-regular, 16px);
                letter-spacing:2px;
                color:$text-dark-grey;
                text-align: center;
                @include breakpoint(tab-max){
                    @include ann-describtion(12px, $lato-regular, 16px);
                }
            }
        }
        &__content{
            padding:5.625rem 0 6.25rem;
            max-width:540px;
            margin:0 auto;
            @include breakpoint(tab){
                max-width:476px;
            }
            @include breakpoint(tab-max){
                padding:40px 0 60px;
            }
            @include breakpoint(mobile){
                padding-right:18px;
                padding-left:18px;
            }
        }
        &__description{
            p{
                @include ann-describtion(16px, $lato-regular, 24px);
                margin-top:40px;
                @include breakpoint(tab-max){
                    text-align: center;
                    margin-top:30px;
                }
            }
        }
    }
    &.play-icon-color-dark{
        .gbh-play-video .playinside, .hero-mute-icon{
            color:$text-medium-dark-grey;
            &::after,&::before{
                color:$text-medium-dark-grey;
            }
        }
    }
    &.play-icon-color-white{
        .gbh-play-video .playinside, .hero-mute-icon{
            color:$white;
        }
        &::after,&::before{
            color:$white;
        }
    }
}