// Tabs Title Component
.tabs-header {
    position: relative;

    @include breakpoint(desktop-mid) {
        padding: 0 36px;
    }

    @include breakpoint(tab) {
        padding: 3.125rem 2rem 3.5625rem;
    }

    @include breakpoint(mobile) {
        padding: 3.125rem 1.125rem 3.125rem;
    }

    .cmp-title {
        width: 25.5rem;
        position: absolute;
        top: 3.125rem;
        margin-bottom: 4.375rem;

        @include breakpoint(tab-max) {
            margin-bottom: 0;
        }

        @include breakpoint(tab) {
            position: relative;
            top: 0;
        }

        @include breakpoint(mobile) {
            width: 100%;
            position: relative;
            top: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include headingtitle_h3;
            margin: 0;
            color: $text-dark-grey;

            @include breakpoint(tab) {
                font-size: 2.25rem;
                line-height: 2.75rem;
                letter-spacing: -0.125rem;
            }

            @include breakpoint(mobile) {
                font-size: 2.25rem;
                line-height: 2.75rem;
                letter-spacing: -0.125rem;
                padding-right: 3rem;
            }
        }
    }
}
