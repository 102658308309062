.sticky-header {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    width: 100%;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
    padding: 0px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    .container {
        max-width: 1380px;
    }

    &__details-wrap {
        border-bottom: 1px solid $color-extra-light-gray;
    }

    .sticky-header__container {
        display: flex;
        padding: 20px 15px;

        img {
            width: 45px;
            height: 60px;
            object-fit: cover;
        }

        .sticky-header__details {
            display: grid;
            width: 200px;
            margin-left: 20px;

            .sticky-header__sku{
                font-family: $font-regular;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.45px;
                color: $color-dark-gray;
            }

            .sticky-header__brand-name {
                font-family: $font-light;
                font-size: 22px;
                line-height: 32px;
                letter-spacing: -1.2px;
                color: $color-charcoal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        .sticky-header__rating-desc {
            display: grid;
            width: 360px;
            margin-left: 20px;
            align-items: end;

            .sticky-header__rating {
                height: 22px;
            }

            .sticky-header__description {
                height: 24px;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.55px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin: 4px 0px 5px;
                color: $color-dark-gray;
                font-family: $font-regular;
            }
        }

        .sticky-header__color-block {
            display: inline;
            width: 120px;
            margin-left: 40px;

            .sticky-header__color {
                height: 20px;
                border-radius: 4px;
                border:  1px solid $color-dark-gray;
                margin: 5px 0 0 0;
            }
        }

        .sticky-header__right {
            display: flex;
            align-items: center;
            margin-left: auto;

            &--retailers{
                margin-right: auto;
            }

            &-out-of-stock{
                margin-left: 25px;
            }

            .sticky-header__status {
                width: 100%;
                margin-left: 60px;
                margin-right: 60px;
                span {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    letter-spacing: -0.35px;
                    color: $color-charcoal;
                    font-family: $font-regular;
                }
            }

            .sticky-header__price-block{
                display: grid;
                text-align: right;

                .sticky-header__price-text {
                    margin-bottom: 5px;
                    font-family: $font-regular;
                }

                .sticky-header__price {
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 1.45;
                    letter-spacing: -1.2px;
                    color: $color-charcoal;
                    font-family: $font-light;
                }
            }

            .button {
                width: 180px;
                padding-left: 30px;
                padding-right: 30px;
                margin-left: 40px;
            }
        }
    }

    .sticky-header__nav-container {
        display: flex;
        padding: 20px 15px;

        .sticky-header__nav-items {
            a {
                text-decoration: none;
                color: $color-dark-gray;
                font-size: 16px;
                line-height: 14px;
                letter-spacing: -0.5px;
                margin-right: 30px;
                cursor: pointer;
                font-family: $font-regular;

                &.sticky-nav-active {
                    color: $color-charcoal;
                    letter-spacing: -0.35px;
                    font-family: $font-medium;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .sticky-header__nav-container {
            white-space: nowrap;
            overflow-x: auto;
            scroll-behavior: smooth;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                display: none;
            }

            .sticky-header__nav-items {
                margin-left: 18px;
            }
        }
    }
    @media screen and (max-width: 990px) {
        .sticky-header__container {
            display: none;
        }
    }
}
