.kf-lazyload-wrapper {
    min-height: auto;
  }
    .inspiration-list {
      &__container {
        max-width: 86.25rem;
      }
    
      &__header {
        margin-bottom: 43px;
      }
  
      &__curated{
        padding-top: 3.75rem !important;
      }
  
      &__title-info {
        margin-top: 0;
        height: 9.563rem;
      }
    
      &__title {
        font-size: 5rem;
        line-height: 1.1;
        letter-spacing: -4.45px;
        color: $color-slate;
        height: 4.25rem;
        margin-top: 10px;
      }
    
      &__total {
        bottom: 12px;
        padding-top: 45px;
        font-size: 2rem;
        max-height: 2.5rem;
        line-height: 1.25;
        letter-spacing: -1.5px;
        color: $color-dark-gray;
        display: flex;
        min-width: 405px;
        .inputWithIcon{
          .search {
            max-width: 285px;
            margin-left: 30px;
            padding-left: 47px;
            font-size: 18px;
            line-height: 26px;
            color:$text-light-grey-cap;
          }
          .search-icon{
            position: relative;
            bottom: 35px;
            left: 50px
          }
        }
      }
    
      &__filter-toggle {
        display: flex;
        align-items: center;
        font-family: $font-regular;
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: -0.45px;
        color: $color-charcoal;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        outline: none;
    
        &-text {
          list-style: none;
          padding: 0;
          margin: 0;
          text-align-last: left;
          height: 24px;
          overflow: hidden;
    
          li {
            height: 24px;
            transition: all 0.3s ease-in-out;
    
            &.hide {
              opacity: 0;
              transform: scale(0.9);
            }
    
            &.show {
              opacity: 1;
            }
          }
    
          li:last-child {
            transform: translateY(-30%);
          }
    
          &--show {
            li:last-child {
              transform: translateY(-100%);
            }
          }
        }
    
        &-icon {
          width: 21px;
          height: 20px;
          margin-right: 10px;
        }
      }
    
      &__close-icon {
        width: 16px;
        height: 16px;
      }
    
      &__sticky-nav-wrap {
        position: relative;
      }
    
      &__sticky-nav {
        background: $color-white;
        transition: all 0.3s ease;
        padding-right: 15px;
        padding-left: 15px;
    
        &--shadow {
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
        }
      }
    
      &__controls {
        display: flex;
        justify-content: flex-start;
        height: 65px;
        align-items: center;
  
        .control-left-section {
          width: 300px;
          position: absolute;
        }
  
        .control-right-section {
          display: flex;
          align-items: center;
          justify-content:flex-end;
          width: 100%;
          margin-left: 350px;
          position: relative;
        }
    
        section {
          display: flex;
          position: absolute;
          right: 15px;
          min-height: 65px;
          align-items: center;
        }
      }
    
      &__filter {
        width: 300px;
        height: 100%;
        position: absolute;
        opacity: 1;
        transition: all 0.5s ease-in-out;
  
        &-curated{
          .filter__print-and-share{
            margin-top: 25px;
          }
        }  
        &--hide {
          opacity: 0;
        }
    
        &--sticky {
          position: sticky;
          top: 80px;
        }
      }
    
      &__tiles {
        margin-top: 10px;
        margin-bottom: 124px;
        @media only screen and (min-width: 1024px) and (max-width:1100px) {
          margin-bottom:50px;
        }
      }
      &__not-found {
        text-align: center;
        color: $text-dark-grey;
        font-size: 26px;
        line-height: 36px;
      }
      &__try-other {
        text-align: center;
        color: $text-medium-dark-grey;
        font-size: 18px;
        line-height: 28px;
      }
  
      &__tiles-knowledge-articles {
        margin-bottom: 63px;
      }
    
      &__tile-col {
        transition: all 0.5s ease-in-out;
        margin-left: 360px;
        min-height: calc(100vh - 100px);
    
        &--full-width {
          margin-left: 15px;
        }
      }
    
      &__tile {
        margin-bottom: 30px;
        padding: 0 2.5px;
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    
      &__load-more {
        width: 100%;
        background: $color-white;
        border: solid 1px $color-slate;
        font-family: $font-medium;
        line-height: 1.71;
        text-align: center;
        color: $color-slate;
        transition: all 0.3s ease-in-out;
        margin-bottom: 43px;
      }
    
      &__back-to-top {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    
      &__print-and-share {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        display: flex;
  
        .social-share__box {
          top: 30px;
          transform: translateX(-50%);
        }
    
        span {
          margin-right: 30px;
          display: flex;
          align-items: center;
    
          img {
            height: 16px;
          }
        }
      }
    
      &__go-back {
        display: flex;
        font-family: $font-regular;
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: -0.45px;
        color: $color-charcoal;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        outline: none;
    
        img {
          height: 20px;
          margin-left: 15px;
        }
      }
  
      .sorting-section {
        margin-right: -30px;
      }
      
      //products per page
      .products-per-page {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    
        @media screen and (max-width: 768px) {
          display: none;
        }
      
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
      
          li {
            display: inline-block;
            font-family: $font-regular;
            font-size: 1.125rem;
            line-height: 1.33;
            letter-spacing: -0.45px;
            color: $color-charcoal;
            margin-right: 15px;
            cursor: pointer;
      
            &.disabled {
              pointer-events: none;
              color: $color-dark-gray;
            }
      
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      
      //sort
      .sort {
        margin-left: 5rem;
      
        &__toggle {
          display: flex;
          align-items: center;
          position: relative;
          font-family: $font-regular;
          font-size: 1.125rem;
          line-height: 1.33;
          letter-spacing: -0.45px;
          color: $color-charcoal;
          padding: 0;
          margin: 0;
        }
        a {
          font-family: $font-medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: -0.35px;
          color: $color-dark-gray;
          text-decoration: underline;
        }
      }
    
      &__hide-discontinued {
        margin-top: 25px;
      }
    
      &__print-and-share {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        display: flex;
    
        span {
          margin-right: 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            height: 16px;
          }
        }
      }
    
      &__chips {
        transition: all 0.5s ease;
        &-tag {
          display: inline-block;
          border-radius: 5px;
          border: solid 1px $color-light-gray;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          padding: 8px 15px;
          vertical-align: middle;
          margin-bottom: 10px;
          margin-right: 10px;
        }
    
        &-close-icon {
          width: 10px;
          height: 10px;
          margin-left: 10px;
          cursor: pointer;
        }
    
        &-clear-all {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.35px;
          color: $color-dark-gray;
          text-decoration: underline;
          margin-top: 5px;
          margin-bottom: 25px;
          cursor: pointer;
        }
      }
  
      .social-share__box {
        margin: 5px auto 15px;
        position: relative;
      }
          
      @media screen and (max-width: 768px) {
        &__print-and-share {
          display: none;
        }
        &__title-info {
          margin-top: 0;
          height: 145px;
        }
        &__buying-guide {
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
        &__mobile-header {
          display: flex;
        }
        &__apply {
            display: block;
        }
      }
    
      .Collapsible {
        border-bottom: 1px solid $color-dark-gray;
        &:first-child {
          border-top: 1px solid $color-dark-gray;
        }
      
        &.is-open {
          .Collapsible__contentInner {
            opacity: 1;
          }
        }
      
        &__trigger {
          width: 100%;
          display: block;
          font-family: $font-medium;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.35px;
          color: $color-charcoal;
          padding: 15px 0px;
          cursor: pointer;
      
          div {
            display: flex;
            justify-content: space-between;
      
            .plus {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
      
              .line {
                background: $color-charcoal;
                transition: all 0.5s ease;
      
                &.line-1 {
                  width: 100%;
                  height: 1px;
                }
      
                &.line-2 {
                  width: 1px;
                  height: 100%;
                  transform: translateX(-10px);
                }
              }
            }
          }
      
          &.is-open {
            .plus {
              .line {
                &.line-2 {
                  height: 0;
                }
              }
            }
          }
        }
      
        &__contentInner {
          opacity: 0;
          transition: all 0.3s ease-in-out;
          padding-bottom: 15px;
        }
      }
    
    }
    
    #price-range {
      .price_range__description {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        margin-bottom: 16px;
      }
    
      .input-wrap {
        display: grid;
        grid-template-columns: 1fr 40px 1fr;
        align-items: center;
        text-align: center;
        margin-bottom: 15px;
    
        span {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
        }
    
        input {
          width: 100%;
          height: 60px;
          border-radius: 4px;
          border: 1px solid $color-medium-gray;
          font-size: 16px;
          line-height: 1;
          letter-spacing: -0.5px;
          text-indent: 10px;
        }
        input.error{
          border: 1px solid #ff2a2a;
          color: $color-dark-gray;
        }
      }
    
      .error{
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.3px;
        color: #ff2a2a;
      }
    }
  
    .inspiration-landing-tile__wrapper {
      .social-share {
        position: absolute;
        right: 0;
        &__box {
          right: 5px;
          top: 50px;
        }
      }
  
      &:hover {
        .inspiration-landing-tile {
          .inspiration-landing-tile__image {
            &--hover {
              opacity: 1;
            }
            img:nth-child(2) {
              opacity: 1;
              transform: scale(1);
            }
          }
          .inspiration-landing-tile__colors--count {
            opacity: 0;
          }
          .inspiration-landing-tile__colors--swatch,
          .inspiration-landing-tile__compare {
            opacity: 1;
          }
          .inspiration-landing-tile__share {
            opacity: 1;
          }
        }
      }
    }
    
    //product tile
    .inspiration-landing-tile {
      width: 100%;
      position: relative;
    
      &__image {
        width: 100%;
        padding-bottom: 130%; //for maintaining aspect ratio
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    
        div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
    
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          mix-blend-mode: multiply; //temp style for demonstration
          transition: all 0.5s ease-in-out;
        }
    
        img:first-child {
          position: absolute;
          opacity: 1;
        }
        img:nth-child(2) {
          opacity: 0;
          transform: scale(1.03);
        }
      }
  
      &__badge{      
        position: absolute;
        z-index: 1;
        top: 15px;
        left: 25px;
        display: flex;
      }
      &__new{
        font-family: $font-regular;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.65px;
        color: $color-white;
        margin-bottom: 0px;
        padding:9px 12px;
        background-color: $color-kohler-blue;
        text-align: center;
      }
      &__offer{
        background-color: $color-white;
        font-family: $font-regular;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.65px;
        color: $color-kohler-blue;
        margin-bottom: 0px;
        padding:9px 12px;
        text-align: center;
      }
    
      &__details {
        display: flex;
        justify-content: space-between;
        padding-right: 25px;
        word-break: break-word;
        div{
          &:first-child{
            flex:1
          }
        }
      }
    
      &__title {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.4px;
        color: $color-charcoal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
      }
    
      &__startingat {
        color: $color-dark-gray;
        font-family: $font-regular;
        font-size: 14px;
        letter-spacing: -0.7px;
        text-align: right;
      }
    
      &__price {
        padding-left: 20px;
        font-family: $font-bold;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.8px;
        color: $color-charcoal;
        margin-bottom: 5px;
      }
      &__discount-price {
        padding-left: 20px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.8px;
        color: $color-dark-gray;
        text-decoration: line-through;
      }
      &__colors {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.4px;
        color: $color-dark-gray;
        margin-top: 15px;
    
        &--count {
          opacity: 1;
          
          transition: all 0.5s ease-in-out;
        }
    
        &--swatch {
          position: absolute;
          display: flex;
          z-index: 1;
          align-items: center;
          opacity: 0;
          transition: all 0.5s ease-in-out;
        }
      }
    
      &__color-swatch {
        img {
          width: 20px;
          height: 20px;
          border-radius: 5px;
          margin-right: 5px;
          display: block;
        }
      }
      
      &__compare {
        opacity: 0;
        transition: all 0.5s ease-in-out;
        margin-top: 15px;
        position: relative;
      @media screen and (max-width:992px) {
        opacity: 1;
      }
        .tooltip__container{
          position: absolute;
          z-index: 9;
          right: 0;
        }
    
        &-button {
          background: none;
          border: none;
          outline: none;
      
          &-icon {
            width: 16px;
            height: 9px;
            margin-left: 15px;
            transform: rotateX(180deg);
            transition: transform 0.5s ease-in-out;
          }
        }
      
        &__drop-down {
          max-height: 0px;
          position: absolute;
          top: 60px;
          right: 0;
          background-color: $color-white;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.15);
          list-style: none;
          padding: 0;
          overflow: hidden;
          transition: max-height 0.5s ease-in-out;
          width: max-content;
        }
      
        &__item {
          font-family: $font-regular;
          font-size: 18px;
          line-height: 1.33;
          letter-spacing: -0.5px;
          text-align: left;
          color: $color-dark-gray;
          cursor: pointer;
          opacity: 0;
          padding: 10px 25px;
          transition: opacity 0.5s ease-in-out;
          margin: auto 2px;
      
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 2px;
          }
          &.active, &:hover {
            background-color: $color-extra-light-gray;
          }
        }
      
        &--open {
          .sort__drop-down {
            max-height: 280px;
          }
      
          .sort__item {
            opacity: 1;
          }
        }
      
        @media screen and (max-width: 576px) {
          margin-left: 0;
      
          &__drop-down {
            right: 15px;
          }
        }
      }
      
      //filter
      .filter {
        height: calc(100vh - 100px);
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: 0 2px; //required for ADA focus border to be visible
      
        &::-webkit-scrollbar {
          display: none;
        }
      
        &--show-less {
          .Collapsible {
            display: none;
      
            &:nth-child(-n + 5) {
              display: block;
            }
          }
        }
      
        &__mobile-header {
          display: none;
        }
      
        &__apply {
          display: none;
          width: 100%;
          height: 60px;
          background-color: $color-light-gray;
          font-family: $font-medium;
          font-size: 18px;
          line-height: 1.33;
          letter-spacing: -0.35px;
          color: $color-white;
          border: none;
          outline: none;
          margin-top: 40px;
          margin-bottom: 40px;
          transition: all 0.5s ease;
      
          &--active {
              background-color: $color-kohler-blue;
          }
        }
      
        &__show-more {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          padding: 0;
          margin: 0;
          margin-top: 16px;
          background: none;
          border: none;
          outline: none;
        }
      
        &__buying-guide {
          margin-top: 50px;
          padding-bottom: 25px;
          border-bottom: 1px solid $color-light-gray;
      
          h3 {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-charcoal;
            margin: 0;
          }
          a {
            font-family: $font-medium;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-dark-gray;
            text-decoration: underline;
          }
        }
      
        &__hide-discontinued {
          margin-top: 25px;
        }
      
        &__print-and-share {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          display: flex;
      
          span {
            margin-right: 30px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              height: 16px;
            }
          }
        }
      
        &__chips {
          transition: all 0.5s ease;
          &-tag {
            display: inline-block;
            border-radius: 5px;
            border: solid 1px $color-light-gray;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-dark-gray;
            padding: 8px 15px;
            vertical-align: middle;
            margin-bottom: 10px;
            margin-right: 10px;
          }
      
          &-close-icon {
            width: 10px;
            height: 10px;
            margin-left: 10px;
            cursor: pointer;
          }
      
          &-clear-all {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-dark-gray;
            text-decoration: underline;
            margin-top: 5px;
            margin-bottom: 25px;
            cursor: pointer;
          }
        }
    
        .social-share__box {
          margin: 5px auto 15px;
          position: relative;
        }
            
        @media screen and (max-width: 768px) {
          &__print-and-share {
            display: none;
          }
          &__title-info {
            margin-top: 0;
            height: 145px;
          }
          &__buying-guide {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }
          &__mobile-header {
            display: flex;
          }
          &__apply {
              display: block;
          }
        }
      
        .Collapsible {
          border-bottom: 1px solid $color-dark-gray;
          &:first-child {
            border-top: 1px solid $color-dark-gray;
          }
        
          &.is-open {
            .Collapsible__contentInner {
              opacity: 1;
            }
          }
        
          &__trigger {
            width: 100%;
            display: block;
            font-family: $font-medium;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-charcoal;
            padding: 15px 0px;
            cursor: pointer;
        
            div {
              display: flex;
              justify-content: space-between;
        
              .plus {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
        
                .line {
                  background: $color-charcoal;
                  transition: all 0.5s ease;
        
                  &.line-1 {
                    width: 100%;
                    height: 1px;
                  }
        
                  &.line-2 {
                    width: 1px;
                    height: 100%;
                    transform: translateX(-10px);
                  }
                }
              }
            }
        
            &.is-open {
              .plus {
                .line {
                  &.line-2 {
                    height: 0;
                  }
                }
              }
            }
          }
        
          &__contentInner {
            opacity: 0;
            transition: all 0.3s ease-in-out;
            padding-bottom: 15px;
          }
        }
      
      }
      
      #price-range {
        .price_range__description {
          font-family: $font-regular;
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.65px;
          color: $color-dark-gray;
          margin-bottom: 16px;
        }
      
        .input-wrap {
          display: grid;
          grid-template-columns: 1fr 40px 1fr;
          align-items: center;
          text-align: center;
          margin-bottom: 15px;
      
          span {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-dark-gray;
          }
      
          input {
            width: 100%;
            height: 60px;
            border-radius: 4px;
            border: 1px solid $color-medium-gray;
            font-size: 16px;
            line-height: 1;
            letter-spacing: -0.5px;
            text-indent: 10px;
          }
          input.error{
            border: 1px solid #ff2a2a;
            color: $color-dark-gray;
          }
        }
      
        .error{
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.3px;
          color: #ff2a2a;
        }
      }
    
      .inspiration-landing-tile__wrapper {
        .social-share {
          position: absolute;
          right: 0;
          &__box {
            right: 5px;
            top: 50px;
          }
        }
    
        &:hover {
          .inspiration-landing-tile {
            .inspiration-landing-tile__image {
              &--hover {
                opacity: 1;
              }
              img:nth-child(2) {
                opacity: 1;
                transform: scale(1);
              }
            }
            .inspiration-landing-tile__colors--count {
              opacity: 0;
            }
            .inspiration-landing-tile__colors--swatch,
            .inspiration-landing-tile__compare {
              opacity: 1;
            }
            .inspiration-landing-tile__share {
              opacity: 1;
            }
          }
        }
      }
      
      //product tile
      .inspiration-landing-tile {
        width: 100%;
        position: relative;
      
        &__image {
          width: 100%;
          padding-bottom: 130%; //for maintaining aspect ratio
          position: relative;
          overflow: hidden;
          margin-bottom: 20px;
      
          div {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
      
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            mix-blend-mode: multiply; //temp style for demonstration
            transition: all 0.5s ease-in-out;
          }
      
          img:first-child {
            position: absolute;
            opacity: 1;
          }
          img:nth-child(2) {
            opacity: 0;
            transform: scale(1.03);
          }
        }
    
        &__badge{      
          position: absolute;
          z-index: 1;
          top: 15px;
          left: 25px;
          display: flex;
        }
        &__new{
          font-family: $font-regular;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.65px;
          color: $color-white;
          margin-bottom: 0px;
          padding:9px 12px;
          background-color: $color-kohler-blue;
          text-align: center;
        }
        &__offer{
          background-color: $color-white;
          font-family: $font-regular;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.65px;
          color: $color-kohler-blue;
          margin-bottom: 0px;
          padding:9px 12px;
          text-align: center;
        }
      
        &__details {
          display: flex;
          justify-content: space-between;
          padding-right: 25px;
          word-break: break-word;
          div{
            &:first-child{
              flex:1
            }
          }
        }
      
        &__title {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.4px;
          color: $color-charcoal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }
      
        &__startingat {
          color: $color-dark-gray;
          font-family: $font-regular;
          font-size: 14px;
          letter-spacing: -0.7px;
          text-align: right;
        }
      
        &__price {
          padding-left: 20px;
          font-family: $font-bold;
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.8px;
          color: $color-charcoal;
          margin-bottom: 5px;
        }
        &__discount-price {
          padding-left: 20px;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.8px;
          color: $color-dark-gray;
          text-decoration: line-through;
        }
        &__colors {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.4px;
          color: $color-dark-gray;
          margin-top: 15px;
      
          &--count {
            opacity: 1;
            
            transition: all 0.5s ease-in-out;
          }
      
          &--swatch {
            position: absolute;
            display: flex;
            z-index: 1;
            align-items: center;
            opacity: 0;
            transition: all 0.5s ease-in-out;
          }
        }
      
        &__color-swatch {
          img {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            margin-right: 5px;
            display: block;
          }
        }
        
        &__compare {
          opacity: 0;
          transition: all 0.5s ease-in-out;
          margin-top: 15px;
          position: relative;
        @media screen and (max-width:992px) {
          opacity: 1;
        }
          .tooltip__container{
            position: absolute;
            z-index: 9;
            right: 0;
          }
      
          &-button {
            background: none;
            border: none;
            outline: none;
            color: $color-dark-gray;
            z-index: 100;
      
            &:hover {
              color: $color-dark-gray;
            }
          }
        }
      
        &__share {
          display: flex;
          position: absolute;
          z-index: 1;
          right: 25px;
          top: 15px;
          transition: all 0.5s ease;
          opacity: 1;
      
          &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            background: $color-white;
            border-radius: 5px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      
        &--lite {
          .inspiration-landing-tile__colors,.inspiration-landing-tile__share {
            display: none;
            @media screen and (min-width:768px) and (max-width:992px) {
              display: flex;
            }
          }
      
          .inspiration-landing-tile__details {
            display: block;
            padding-right: 0px;
          }
      
          .inspiration-landing-tile__title {
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: -0.25px;
          }
      
          .inspiration-landing-tile__price {
            font-size: 14px;
            padding-left: 0;
          }
        }
      
        &--loading {
          .loading-image {
            width: 100%;
            padding-bottom: 130%;
            margin-bottom: 20px;
          }
          .loading-title {
            width: 75%;
            height: 10px;
            margin-bottom: 10px;
          }
          .loading-description {
            width: 70%;
            height: 10px;
            margin-bottom: 10px;
          }
          .loading-swatch {
            list-style: none;
            padding: 0;
            margin: 0;
      
            li {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 5px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    @media screen and (min-width:768px) and (max-width:992px) {
      .product-tile__shareicon{
        display: none;
      }
    }
    @media only screen and (min-width: 1024px) and (max-width: 1199px){
      .inspiration-list{
        &__filter{
          width: 230px;
        }
        &__tile-col{
          margin-left: 260px;
        }
        &__container{
          max-width: 79.30rem;
        }
    
        &__buying-guide-mobile {
          display: none;
        }
  
        &__controls {
          .control-right-section {
            margin-left: 250px;
          }
          section {
            right: 30px;
            .sort__drop-down {
              right: -30px;
            }
          }
        }
        
        &__tile-col--full-width {
          margin-left: 15px;
        }
      }
    
      .inspiration-landing-tile{
        &__details{
          padding-right: 10px;
        }
        &__compare-main{
          display: block;
        }
      }
    }
    @media only screen and (max-width: 860px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 991px){
      .inspiration-list{
        &__back-to-top{
          margin-bottom: 80px;
          border-top: 1px solid $color-light-gray;
          padding-top: 20px;
        }
        &__title{
          height: auto;
          font-size: 46px;
          line-height: 50px;
          letter-spacing: -3.4px;
          border-right: none;
        }
        &__tile-col {
          margin: auto 11px;
        }
        &__filter--sticky{
          top: 8px;
        }
        &__tiles {
          margin-top: 20px;
          margin-bottom: 0;
        }
        &__grid-control {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;
          border-bottom: 1px solid $color-light-gray;
        }
        &__total {
          position: relative;
          display: flex;
          min-width: 405px;
          .search {
            max-width: 285px;
            margin-left: 30px;
            font-size: 18px;
            line-height: 26px;
            color:$text-light-grey-cap;
          }
        }
        &__filter{
          width: 100%;
          position: fixed;
          z-index: 999;
          top: 0;
          left: 0;
          background: $color-white;
          padding: 0 32px;
          display: none;
        }
        &__filter--modal {
          display: block;
         }
        &__header {
          margin-bottom: 0;
        }
        &__container{
          padding: 0 32px;
        }
        &__controls {
          justify-content: space-between;
        }
      }
      .filter{
        &__inner-container {
          padding-bottom: 40px;
        }
        &__footer {
          background: $color-white;
          position: absolute;
          width: 100%;
          bottom: -70px;
          button {
            margin: 0px;
            width: 100%;
            display: block;
            margin-left: 0px;
          }
        }
        &__buying-guide, &__print-and-share {
          display: none;
        }
        &__mobile-header {
          display: flex;
          height: 94px;
        }
      }
      .inspiration-list__tile{
        margin-bottom: 60px;
      }
      .inspiration-landing-tile{
        &__share {
          opacity: 1;
          top: 15px;
          right: 20px;
          &-icon {
            background: $color-white;
            margin-left: 5px;
            width: 36px;
            height: 36px;
          }
        }
        &__colors--swatch, &__compare {
          opacity: 1;
        }
       &__colors--count {
          opacity: 0;
        }
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px){
      .inspiration-landing-tile{
        &--lite &__colors{
          display: block;
        }
        &--lite &__description{
          display: -webkit-box;
        }
        &--lite &__details {
          display: flex;
       }
      }
      .inspiration-list {
        &__buying-guide-mobile {
          display: none;
        }
        &__container.container{
          padding: 0px 32px;
        }
        &__controls {
          .control-right-section {
            margin-left: 150px;
          }
          
          section {
            right: 15px;
            .sort__drop-down {
              right: 0;
            }
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .inspiration-landing-tile__description {
        color: #757575; 
        font-size: 14px;
        letter-spacing: -0.48px;
        line-height: 20px;
      }
      .inspiration-list {
        &__header {
          margin-bottom: 0px;
        }
  
        &__curated{
          padding-top: 40px !important;
        }
    
        &__back-to-top{
          margin-bottom: 80px;
          border-top: 1px solid $color-light-gray;
          padding-top: 20px;
        }
    
        &__title {
          font-size: 46px;
          line-height: 1.09;
          letter-spacing: -3.4px;
          border-right: none;
          height: auto;
          margin-bottom: 0;
          margin-top: 40px;
        }
    
        &__total {
          font-size: 26px;
          line-height: 1.23;
          letter-spacing: -1.15px;
          position: relative;
          display: flex;
          flex-direction: column;
          min-width: 70%;
          .inputWithIcon{
            min-width: 100%;
          }
        }
    
        &__filter {
          width: 100%;
          position: fixed;
          z-index: 999;
          top: 0;
          left: 0;
          background: $color-white;
          padding: 0px 18px;
          display: none;
          overflow: hidden;
    
          &--modal {
              display: block;
              overflow: auto;
              bottom: 0;
          }
        }
    
        &__filter--sticky{
          position: relative;
          top: 9px;
        }
    
        &__tiles {
          margin-top: 20px;
          margin-bottom: 0;
          padding-left: 18px;
          padding-right: 18px;
        }
  
        &__container{
          padding-left: 18px;
          padding-right: 18px;
        }
  
        &__controls {
          .control-right-section {
              margin-left: 50%;
              justify-content: flex-end;
          }
  
          section {
            right: 15px;
            .sort__drop-down {
              right: 0;
            }
          }
        }
    
        &__tile-col {
          margin: auto 11px;
        }
    
        &__tile {
          margin-bottom: 50px;
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
    
        &__load-more {
          margin-bottom: 40px;
          padding: 8px 30px;
          font-size: 14px;
        }
    
        &__tile-col--full-width{
          .inspiration-landing-tile__startingat{
            text-align: left;
          }
        }
    
        &__grid-control {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;
          border-bottom: 1px solid $color-light-gray;
    
          &-icon {
            width: 17px;
            height: 17px;
            margin-left: 20px;
          }
        }
        &__tile-col{
          .row-cols-1{
            .inspiration-landing-tile {
              &__share{
                opacity: 1;
                top: 15px;
                right: 20px;
              }
              &__share-icon{
                background: transparent;
                margin-left: 20px;
                width: auto;
                height: auto;
                cursor: pointer;
              }
            }
          }
        }
    
        &__buying-guide-mobile {
          padding-top: 18px;
          padding-bottom: 25px;
          border-top: 1px solid $color-light-gray;
          border-bottom: 1px solid $color-light-gray;
          margin: 0 auto 40px auto;
          padding-left: 0;
          padding-right: 0;
    
            h3 {
              font-family: $font-medium;
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.4px;
              color: $color-charcoal;
            }
    
            a {
              font-family: $font-medium;
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.4px;
              color: $color-dark-gray;
              text-decoration: underline;
              display: block;
            }
        }
  
        .sorting-section {
          margin-right: -20px;
        }
      }
    
      .inspiration-landing-tile{
        &__colors--swatch {
          opacity: 1;
        }
        &__details {
          padding-right: 0px;
        }
        &__discount-price {
          padding-left: 0;
        }
        &:hover {
          .inspiration-landing-tile__compare{
            opacity: 1;
          } 
        }
        &--lite{
          &__badge{      
            top: 9px;
            left: 9px;
          }
          &__new{
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0.5px;
            padding: 6px 8px;
          }
          &__offer{
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0.5px;
            padding: 6px 8px;
          }
        }
        &__compare-main{
          height: 39px;
        }
      }
    
      .filter {
  
    
        &__footer {
          button {
            margin-top: 35px;
            width: 100%;
          }
        }
        &__mobile-header {
          display: flex;
          height: 30px;
          margin: 9px 0 40px;
          justify-content: space-between;
        }
      }
    }
    
    .promo-banner {
      height: 100% !important;
      margin-left: -15px;
      margin-right: -15px;
    
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    @media screen and (max-width: 480px){
      .inspiration-landing-tile--lite{
        .inspiration-landing-tile__share {
          opacity: 1;
          display: block;      
          &-icon {
            background: transparent;
            width: 20px;
            height: 20px;
          }
          >div:last-child {
            display: none;
          }
        }
        .inspiration-landing-tile__image{
          margin-bottom: 10px;
        }
      }
    }
  
  @media (hover: none) {
    .inspiration-list {
      &__load-more {
        &:hover {
          color: #494949 !important;
          text-decoration: none;
        }
      }
    }
  }
  
  /* Safari only */
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .inspiration-list {
      &__controls {
        justify-content: space-between;
        section {
          position: relative;
        }
      }
    }
  }
  @media screen and (min-width: 992px){
    .inspiration-list{
      &__print-and-share {
       display: none;
      }
    } 
  }
  @media print{ 
    @page {
      margin: 1cm;
    }
    .breadcrumb {
      display: none;
    }
    .inspiration-landing-tile__image{
      page-break-inside: avoid;
      break-inside: avoid;
    } 
    .inspiration-list {
      &__header {
        div {
          overflow: hidden;
          div {
            float: left;
          }
        }
      }
      &__tile {
        page-break-inside: avoid;
      }
    }
  }
  
  