.ann-sacks-category-landing-banner {
    padding: 0;  
    z-index: 0;
    position: relative;
    .cmp-teaser{
        &__image{
            @include breakpoint (desktop-small) {
                max-height: 830px;
            }
            @include breakpoint (desktop-xlg) {
                max-height: 1000px;
            }
            @include breakpoint(tab){
                min-height:445px;
            }
            @include breakpoint(mobile){
                max-height:600px;
                min-height:600px;
            }
            img{
                min-height:inherit;
            }
        }
        &__content{
            position:absolute;
            top:0;
            width:100%;
            height:100%;
            display: flex;
            flex-direction: column;
            @include breakpoint (desktop-small) {
                padding: 0 110px;
            }
            @include breakpoint (desktop-xlg) {
                padding: 0 50px;
            }

            @include breakpoint(desktop-large){
                max-width: 1920px;
                margin: 0 auto 0 -960px;
                left: 50%;
                padding: 0 50px;
            }
            @include breakpoint(desktop-mid){
                padding:0 2.25rem;
            }
            @include breakpoint(tab){
                padding:50px 32px;
            }
            @include breakpoint(mobile){
                padding:50px 18px;
            }
        }
        &__title{
            max-width:420px;
            *{
                font-size:4.375rem;
                line-height:4.875rem;
                letter-spacing:-1.5px;
                font-family:$playfair-regular;
                width: 26.25rem;
                @include breakpoint(tab-max){
                    font-size:36px;
                    line-height:44px;
                    letter-spacing:-2px;
                }
                @include breakpoint (mobile) {
                    width: 21.1875rem;
                }
            }
        }
        &__description{
            max-width:405px;
            margin-top:20px;
            @include breakpoint(tab-max){
                margin-top:10px;
            }
            @include breakpoint(tab){
                max-width:420px;
            }
            @include breakpoint(mobile){
                max-width:339px;
            }
            *{
                font-size:18px;
                line-height:28px;
                font-family:$lato-regular;
                letter-spacing:normal;
                @include breakpoint(tab-max){
                    font-size:16px;
                    line-height:24px;
                }
            }
        }
        &__action-container{
            margin-top: 1.875rem; 
            @include breakpoint(tab-max){
                margin-top: 1.25rem;
            }
            .cmp-teaser__action-link{
                font-size:11px;
                line-height:12px;
                letter-spacing:1.83px;
                font-family:$lato-regular;
                padding-bottom:7px;
                &:first-child {
                    margin-bottom: 100px;
                    @include breakpoint (tab-max) {
                        margin-bottom: 40px;
                    }
                }
                &:last-child{
                    position:absolute;
                    bottom:4.125rem;
                    border:none;
                    padding:0;
                    margin:0 auto;
                    right:0;
                    left:0;
                    width:24px;
                    height:14px;
                    background:transparent;
                    box-shadow:none;
                    color:inherit;
                    &:before{
                        content:"\e92a";
                        font-family:$icon-moon;
                        width:25px;
                        height:15px;
                        font-size:12px;
                        font-weight:normal;
                        transform: translatey(0px);
                        animation: float 6s ease-in-out infinite;
                        position: relative;
                        top:3px;
                        @include breakpoint(tab-max){
                            font-size:10px;
                        }
                    }
                }
            }
        }
    }
    &.content-bottom-center{
        .cmp-teaser{
            &__title,&__description,&__action-container{
                margin-right:auto;
                margin-left:auto;
                text-align: center;
            }
            &__content{
                padding-bottom:180px;
                justify-content: flex-end;
                @include breakpoint (desktop-small) {
                    padding-bottom: 100px;
                }
                @include breakpoint(desktop-xlg){
                    padding-bottom:180px;
                }
                @include breakpoint(tab){
                    padding-bottom:50px;
                }
                @include breakpoint(mobile){
                    padding-bottom:50px;
                }
            }
        }
    }
    &.content-bottom-left{
        .cmp-teaser{
            &__content{
                justify-content: flex-end;
                padding-bottom:195px;
                @include breakpoint (desktop-small) {
                    padding: 0 2.6%;
                }
                @include breakpoint(desktop-xlg){
                    padding: 11.25rem 50px;
                }
                @include breakpoint(tab){
                    padding-bottom:50px;
                }
                @include breakpoint(mobile){
                    padding-bottom:50px;
                }
            }
        }
    }  
}
.wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
   }