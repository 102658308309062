.authmodal  {
    .modal-popup {
        .modal-popup-content {
            .create-account {
                .create-account__inner-container{
                    padding: 90px 0 20px;
                    .brand-logo{
                        display: flex;
                        text-align: left;
                        padding: 0 80pt;
                    }
                    .brand-logo-img{
                        padding: 40px 15px 0px 15px;
                        width: 185px;
                    }
                    @include breakpoint(mobile){
                        .create-account__row{
                                padding: 0;
                            }
                        .brand-logo{
                            flex-wrap: wrap;     
                            padding: 0 16px 30px 18px;
                            >img{
                                padding: 40px 15px 0;
                                width: 168px;
                            }
                        }
                    }
                    @include breakpoint(tab-max){
                        padding: 50px 32px;
                    }
                }
            }
        }
    }
}

.auth-modal{
    .signup-modal__view-account-page-btn{
        color: $white;
        font-family: $helvetica-medium;
        &:hover{
            background-color: $black;
        }
    }
}

.auth-modal__content {
    .sign-in {
      @include breakpoint(desktop-small) {
        width: 500px;
      }
  
      &__keep-me-logged {
        padding-top: 0px;
        .checkbox {
          input:checked ~ .checkbox__marker {
            position: absolute;
            top: -1px;
            left: -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 5px;
            flex-shrink: 0;
          }
          &__border {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid $dark-gray;
            margin-top: unset;
          }
          .checkbox_default {
            padding-top: 2px;
            color: $dark-gray;
          }
        }
      }
  
      &__forgot-password {
        padding-top: unset;
      }
  
      &__footer {
        a {
          //margin-right: 20px;
          text-decoration: underline;
          font-family: $helvetica-medium;
          line-height: 22px;
          letter-spacing: -0.3px;
          color: $color-dark-gray;
        }
      }
    }
  
    @media screen and (max-width: 576px) {
      .sign-in {
        padding: 40px 18px 30px;
        margin: 0;
        display: flex;
        flex-direction: column;
  
        &__footer {
          position: initial;
          a {
            margin-right: 10px;
            font-size: 13px;
          }
        }
      }
      .modal-popup .modal-popup-content {
        .close-button {
          right: 18px;
        }
      }
    }
    .sign-in {
      @include breakpoint(mobile) {
        padding: 50px 18px 163px;
      }
      form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        div,
        ul {
          width: 100%;
        }
        .loader {
          width: 25px;
        }
        .input-field {
          &__suffix {
            text-align: right;
          }
        }
      }
      &__keep-me-logged {
        margin-bottom: 0;
        width: 50% !important;
      }
      .signin-header {
        font-family: $helvetica-bold;
        color: $slate;
        padding-bottom: 35px;
        text-transform: uppercase;
        font-size: 20px;
      }
      &__forgot-password {
        text-align: right;
        margin-left: auto;
        @include breakpoint(mobile) {
          width: 100%;
          text-align: left;
          padding-top: 20px;
        }
      }
      &__sign-in-button {
        .button {
          &.large {
            font-family: $helvetica-medium;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.35px;
            height: 50px;
          }
        }
      }
      &__footer {
        justify-content: space-between;
        @include breakpoint(mobile) {
          justify-content: unset;
          flex-wrap: wrap;
          padding-top: 30px;
        }
        li {
          @include breakpoint(mobile) {
            padding-right: 40px;
          }
          a {
            margin: 0;
          }
        }
        li:first-child {
          text-align: left;
        }
        li:nth-child(2) {
          text-align: center;
        }
        li:last-child {
          text-align: right;
        }
      }
    }
  }
  @include breakpoint(tab-max) {
    .sign-in {
      width: 100%;
      padding: 40px 18px 30px;
      margin: 0;
    }
    .modal-popup .modal-popup-content {
      height: 100vh;
      width: 100vw;
      max-height: 100%;
      margin: 0;
      .close-button {
        right: 32px;
      }
    }
  }
  .auth-modal__tab {
    &.active {
      color: $slate;
    }
    .slider {
      color: $slate;
      height: 4px;
    }
    font-size: 18px;
  }
  .forgot-password {
    &__header {
      h2 {
        font-family: $helvetica-bold;
        font-size: 20px;
        line-height: normal;
        letter-spacing: 1.82px;
        text-transform: uppercase;
      }
    }
    @include breakpoint(tab-max) {
      max-height: 100%;
    }
    @include breakpoint(tab) {
      padding-left: 32px;  
      padding-right: 32px;  
    }
  }
  .create-account__left ul li {
    &::marker {
      font-size: 12px;
    }
    list-style: square;
    padding-left: 5px;
  }
  
  .create-account {
    .create-account {
      &__inner-container {
        @include breakpoint(desktop-small) {
          width: 870px;
          padding: 50px 0 50px;
        }
      }
      &__row {
        @include breakpoint(desktop-small) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
      &__left {
        border: 0;
        @include breakpoint(desktop-small) {
          padding: 0 0 0 50px;
          width: 396px;
        }
        @include breakpoint(tab-max) {
          padding: 0;
        }
        ul {
          margin: 15px 0 40px;
          li {
            @include text-style(16px, 24px, normal);
            font-family: $helvetica-regular;
            list-style: square;
            padding-left: 5px;
            padding-bottom: 10px;
            margin-left: 30px;
            color: $color-dark-gray;
            &::marker {
              font-size: 12px;
            }
          }
        }
      }
      &__access-request-cta {
        letter-spacing: -0.45px;
      }
      &__left-section-title {
        @include text-style(20px, 29px, 1.82px);
        font-family: $helvetica-bold;
      }
      &__left-section-desc {
        margin-top: 15px;
        line-height: 24px;
        @include breakpoint(tab-max) {
          width: auto;
        }
      }
      &__right {
        @include breakpoint(desktop-small) {
          margin: auto;
          padding: 0;
          width: 270px;
        }
        @include breakpoint(tab-max) {
          padding: 40px 0 0 0;
          margin: 0;
          width: auto;
        }
        .account-name {
          @include text-style(16px, 24px, -0.35px);
          font-family: $helvetica-medium;
          color: $color-charcoal;
        }
        .account-desc {
          @include text-style(14px, 22px, -0.45px);
          font-family: $helvetica-medium;
        }
        button {
          &.kf-react-button {
            &.button {
              &.large {
                @include text-style(18px!important, 26px!important, -0.35px!important);
                margin: 0!important;
                border: none!important;
                height: 50px!important;
                font-family: $helvetica-medium!important;
                @include breakpoint(desktop-small) {
                  width: 240px;
                }
              }
            }
          }
        }
      }
      &__account-type-box {
        margin-bottom: 30px;
      }
      &__access-reques {
        @include breakpoint(tab-max) {
          width: auto;
        }
      }
    }
    &__access-request{
        @include breakpoint(tab-max) {
            width: auto;
        }
    }
    &__left-section-title {
        @include breakpoint(tab-max) {
            width: auto;
        }
    }
    &__right{
        @include breakpoint(tab-max){
            .button.large{
                height: 50px;
            }
        }
    }
  }
  .signup-modal {
    width: auto;
    margin: 60px 50px 0;
    padding: 0;
    @include breakpoint(desktop-small) {
      width: 400px;
    }
    @include breakpoint(tab) {
      margin: 30px auto 0;
      padding-bottom: 30px;
    }
    @include breakpoint(mobile) {
      width: auto;
      padding: 0px;
      margin: 30px auto 0;
    }
    @include breakpoint (desktop-large) {
      margin: 60px 0px 0px;
    }
    &__header {
      h2 {
        @include text-style(20px, normal, 1.82px);
        font-family: $helvetica-bold;
        text-transform: uppercase;
        margin-top: 15px;
      }
    }
    &__form {
      .input-field {
        &__value {
          @include text-style(18px, 26px, -0.7px);
          font-family: $helvetica-regular;
        }
      }
    }
    &__footer {
      justify-content: space-between;
      display: flex;
      @include breakpoint(mobile) {
        justify-content: unset;
        flex-wrap: wrap;
        padding: 0px;
        margin: 50px 0 0;
      }
      li {
        @include breakpoint(mobile) {
          padding-right: 40px;
          padding-bottom: 30px;
        }
        a {
          margin: 0;
        }
      }
      li:first-child {
        text-align: left;
      }
      li:nth-child(2) {
        text-align: center;
      }
      li:last-child {
        text-align: right;
      }
      
    }
    &.--welcome {
      @include breakpoint(desktop-large) {
        margin-top: 20px;
      }
      
    }
  }
  