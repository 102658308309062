.toast-msg {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    padding: 15px 0;
    background-color: $color-slate;
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }
    &__message {
        font-family: $font-regular;
        font-size: 18px;
        letter-spacing: -0.45px;
        line-height: 24px;
        color: $color-white;
        a {
            cursor: pointer;
            text-decoration: underline;
            font-family: $font-regular;
            font-size: 18px;
            letter-spacing: -0.45px;
            line-height: 24px;
            color: $color-white;
        }
        p {
            font-family: $font-regular;
            font-size: 18px;
            letter-spacing: -0.45px;
            line-height: 24px;
            color: $color-white;
        }
    }
    &__close {
        img {
            cursor: pointer;
            width: 16px;
            height: 16px;
        }
    }
}