.collection-feature-fifty-fifty {
    .cmp-container {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;

        @include breakpoint(tab-max) {
            max-width: 100%;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    .collection-feature-image-left {
        width: 44.37%;
        padding: 0;
        margin-right: 14.9375rem;
        display: flex;

        @include breakpoint(tab) {
            width: 45.81%;
            margin-right: 4.25rem;
        }

        @include breakpoint(mobile) {
            width: 100%;
            margin-right: 0;
        }

        .cmp-teaser {
            &__image {
                img {
                @include breakpoint(desktop-small) {
                    min-height: 44.375rem;
                    display: flex;
                }

                @include breakpoint(tab) {
                    min-height: 30.875rem;
                }

                @include breakpoint(mobile) {
                    min-height: 31.25rem;
                }
            }
            }
        }
    }

    .collection-feature-content-right {
        width: 28.18%;
        padding: 0;

        @include breakpoint(tab) {
            width: 41.88%;
            padding-top: 1.625rem;
            padding-bottom: 1.625rem;
        }

        @include breakpoint(mobile) {
            width: 100%;
            padding: 3.75rem 1.125rem 3.125rem;
        }

        .cmp-teaser {
            &__image {
                .cmp-image {
                    background: $cool-light-grey;
                    overflow: hidden;
                    border-bottom: 2px solid $cool-light-grey;
                    max-width: 180px;
                    max-height: 198px;
                    padding: 2.5rem 1.25rem;

                    @include breakpoint(tab) {
                        min-width: 92px;
                        min-height: 101px;
                        margin-bottom: 1.5625rem;
                        padding: 1.25rem 0.625rem 0;
                    }

                    @include breakpoint(mobile) {
                        min-width: 154px;
                        min-height: 171px;
                        padding: 2rem 0.9375rem 0;
                    }
                }

                &.cq-dd-image {
                    margin-bottom: 3.125rem;
                    width: 11.25rem;
                    height: 12.375rem;
                    position: relative;

                    @include breakpoint(tab) {
                        width: 5.75rem;
                        height: 6.3125rem;
                        margin-bottom: 1.5625rem;
                    }

                    @include breakpoint(mobile) {
                        width: 9.625rem;
                        height: 10.6875rem;
                        margin: 0 auto 3.125rem;
                    }

                    &::after {
                        content: ' ';
                        display: block;
                        background: $cool-light-grey;
                        height: 2.6875rem;
                        width: 11.25rem;
                        position: absolute;
                        bottom: 0;
                        left: 0;

                        @include breakpoint(tab) {
                            width: 5.75rem;
                            height: 1.3125rem;
                        }

                        @include breakpoint(mobile) {
                            width: 9.625rem;
                            height: 2.0625rem;
                        }
                    }
                }
            }

            &__title {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    @include headingtitle_h3;
                    color: $text-dark-grey;
                    visibility: visible;

                    @include breakpoint(tab) {
                        font-size: 2.25rem;
                        line-height: 2.75rem;
                        letter-spacing: -0.125rem;
                    }

                    @include breakpoint(mobile) {
                        font-size: 2.25rem;
                        line-height: 2.75rem;
                        letter-spacing: -0.125rem;
                    }
                }
            }

            &__description {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                    letter-spacing: normal;
                    color: $text-medium-dark-grey;
                    margin-right: 4.6875rem;
                    padding-top: 1.875rem;

                    @include breakpoint(tab-max) {
                        margin-right: 0;
                        padding-top: 1.25rem;
                    }

                    &:first-child {
                        @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                        letter-spacing: normal;
                        color: $text-dark-grey !important;
                        padding-top: 3.125rem;

                        @include breakpoint(tab-max) {
                            padding-top: 1.875rem;
                        }
                    }
                }
            }

            &__action-link {
                color: $color-medium-blue;
                margin-top: 2.5rem;

                @include breakpoint(tab-max) {
                    width: 100%;
                    margin-top: 2.5rem !important;
                }
            }
        }
    }
}