.organize-teaser-indent {
    margin-top: 0px !important;
    &.teaser {
    padding: 6.25rem 0;
    @include breakpoint(desktop-mid) {
        padding: 6.25rem 2.25rem;
    }
    @include breakpoint(tab) {
        padding: 3.125rem 2rem;
    }
    @include breakpoint(mobile) {
        padding: 3.125rem 0;
    }
    .cmp-teaser {
        max-width: 1380px;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &__image {
            max-width: 48.125rem;
            min-height: 36.125rem;
            width: 100%;
            @include breakpoint(tab) {
                max-width: 372px;
                min-height: 280px;
            }
            @include breakpoint(mobile) {
                max-width: 100%;
                min-height: 280px;
            }
            .cmp-image {
                min-height: inherit;
                @include breakpoint(mobile) {
                    margin-top: 82px;
                }
                img {
                    min-height: inherit;
                }
            }
        }
        &__content {
            max-width: 360px;
            margin-top: 4.1875rem;
            margin-right: auto;
            @include breakpoint(tab) {
                max-width: 336px;
                margin-top: unset;
            }
            @include breakpoint(mobile) {
                max-width: 100%;
                padding: 0 18px;
                margin-top: 70px;
            }
        }
        &__title {
            @include breakpoint(mobile) {
                position: absolute;
                top: 50px;
                margin-bottom: 50px;
            }
            h1, h2, h3, h4, h5, h6, p {
                @include ann-describtion(26px, $lato-regular, 36px);
                color: $text-dark-grey;
                letter-spacing: normal;
                @include breakpoint(tab-max) {
                    @include ann-describtion(24px, $lato-regular, 36px);
                }
            }
        }
        &__description {
            margin-top: 40px;
            @include breakpoint(tab) {
                margin-top: 30px;
                width: 19.125rem;
            }
            @include breakpoint(mobile) {
                margin-top: 0;
            }
            h1, h2, h3, h4, h5, h6, p  {
                @include ann-describtion(16px, $lato-regular, 24px);
                letter-spacing: normal;
                color: $text-medium-dark-grey;
            }
        }
        &__action-container {
            margin-top: 60px;
            @include breakpoint (tab) {
                display: flex;
                align-items: center;
                margin-top: 55px;
            }
            @include breakpoint(mobile) {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 40px;
            }
            .cmp-teaser__action-link {
                @include ann-describtion(11px, $lato-regular, 16px);
                letter-spacing: 1.83px;
                padding-bottom: 7px;
                color: $color-light-blue;
                &.gbh-secondary-button {
                    @include ann-describtion(12px, $lato-bold, 16px);
                    letter-spacing: 2px;
                    padding: 23px 26px;
                    color: $color-medium-blue;
                    font-weight: 700;
                    height: 60px;
                }
                &:first-child {
                    margin-right: 30px;
                    @include breakpoint(mobile) {
                        margin-right: 0;
                    }
                }
                &:last-child {
                    @include breakpoint(mobile) {
                        margin-top: 20px;
                    }
                }
            }
        }
        
    }
    .cmp-img-caption {
        max-width: 1380px;
        margin: 15px auto 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include breakpoint(mobile) {
            padding: 0 18px;
            margin: 15px 0 0;
            justify-content: flex-start;
        }
        * {
            max-width: 48.125rem;
            width: 100%;
            @include ann-describtion(12px, $lato-regular, 16px);
            letter-spacing: 0.22px;
            color: $text-dark-grey;
            @include breakpoint(tab) {
                max-width: 372px;
            }
        }
    }

    &.image-right {
        .cmp-teaser {
            flex-direction: row-reverse;
            @include breakpoint(mobile) {
                flex-direction: column;
            }
        }
    }
}
}