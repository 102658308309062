.teaser.visit-showroom {
    padding: 0;
    max-width: 1600px;
    margin: 0 auto;

    .cmp-teaser {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        @include breakpoint (mobile) {
            flex-direction: column;
        }

        &__image {
            .cmp-teaser__image.cmp-image__image {
                @include breakpoint(desktop-small) {
                    width: 59.3rem;
                }

                @include breakpoint(tab) {
                    width: 406px;
                    max-height: 417px;
                }

                @include breakpoint(mobile) {
                    min-width: 375px;
                    margin-top: 0;
                }
            }
        }

        &__content {
            position: static;
            padding-left: 36px;
            box-sizing: initial;
            width: 26.25rem;
            max-width: 1380px;
            margin: 0 auto;
            margin-right: 6.875rem;

            @include breakpoint(desktop-mid) {
                padding: 0 36px;
            }

            @include breakpoint(tab-max) {
                margin: 0;
                padding: 0 65px 0 32px;

            }

            @include breakpoint(tab) {
                max-width: 309px;
                left: 0;
                justify-content: center;
            }

            @include breakpoint(mobile) {
                padding: 1.875rem 1.125rem 3.125rem;
                box-sizing: border-box;
                width: 100%;
            }
        }

        &__pretitle {
            @include breakpoint(mobile) {
                position: relative;
                top: 0;
            }
        }

        &__title {
            margin-bottom: 2.5rem;
            padding-right: 0;

            @include breakpoint(tab) {
                margin-bottom: 15px;
                line-height: 2.25rem;
                width: 12.5rem;
            }

            @include breakpoint(mobile) {
                margin-bottom: 30px;
                position: relative;
                top: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                @include headingtitle_h3;
                color: $text-dark-grey;

                @include breakpoint(tab) {
                    letter-spacing: -1.17px;
                    padding: 0;
                }

                @include breakpoint(mobile) {
                    position: static;
                    letter-spacing: -1.17px;
                }
            }
        }

        &__description {

            p {
                @include ann-describtion(16px, $lato-regular, 24px);
                color: $text-medium-dark-grey;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 40px;

                    @include breakpoint(tab-max) {
                        margin-bottom: 30px;
                    }

                    @include breakpoint(mobile) {
                        margin-bottom: 40px;
                        padding-bottom: 0;
                        letter-spacing: normal;
                    }
                }

                @include breakpoint(tab-max) {
                    width: 100%;
                    margin-bottom: 1.875rem;
                }

                @include breakpoint(tab) {
                    width: 18.625rem;
                    padding: 0;
                    margin-bottom: 10px;
                }

                @include breakpoint(mobile) {
                    margin-top: 0;
                }

                &:first-child {
                    @include breakpoint(mobile) {
                        margin-bottom: 10px;
                        padding: 0;
                    }
                }
            }
        }

        &__action-container {
            @include breakpoint(tab-max) {
                margin: 0;
            }
        }

        &__action-link.gbh-secondary-button {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            padding: 0 27px;
            height: 60px;
            align-items: center;
            font-family: $lato-regular;

            @include breakpoint(tab) {
                min-width: 204px;
                height: 50px;
            }
        }
    }

    &.content-right {
        .cmp-teaser {
            justify-content: space-between;
            flex-direction: row;

            @include breakpoint(tab) {
                padding: 0;
            }

            @include breakpoint(mobile) {
                flex-direction: column;
                position: relative;
                padding: 0;
            }



            &__description {
                @include breakpoint(mobile) {
                    margin: 0 0 1.875rem;
                    padding-right: 0;
                }
            }
        }
    }
}