@font-face{
    font-family:"Helvetica Now Text W05 Light";
    font-display:swap;
    src:url('../../styles/fonts/8448bbbb-ba62-45df-ae79-4ded34b0727b.woff2') format("woff2"),
    url('../../styles/fonts/ad48d569-0df5-4f5d-8ad3-8ce63dd84d7d.woff') format("woff");
}
@font-face{
    font-family:"Helvetica Now Text W05 Regular";
    font-display:swap;
    src:url('../../styles/fonts/5eec4451-e90c-42e7-b4d8-9b8654a65568.woff2') format("woff2"),
    url('../../styles/fonts/f7cf763b-e79b-4c28-af92-803e0c3f16d4.woff') format("woff");
}
@font-face{
    font-family:"Helvetica Now Text W05 Medium";
    font-display:swap;
    src:url("../../styles/fonts/2fd3ef81-07ed-45b4-a344-7a0defbdadcf.woff2") format("woff2"),
    url("../../styles/fonts/52492279-0374-4238-ba8b-751b50089cf0.woff") format("woff");
}

@font-face{
    font-family:"Helvetica Now Text W05 Bold";
    font-display:swap;
    src:url("../../styles/fonts/2a13627f-dba8-48e1-935c-a404f5293139.woff2") format("woff2"),
    url("../../styles/fonts/ce7a1fe7-2605-427a-b064-a01ebce69295.woff") format("woff");
}

$font-light: "Helvetica Now Text W05 Light";
$font-regular: "Helvetica Now Text W05 Regular";
$font-medium: "Helvetica Now Text W05 Medium";
$font-bold: "Helvetica Now Text W05 Bold";