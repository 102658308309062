.meet-team-card {
    color: $text-dark-grey;
    .cmp-teaser {
        &__image {
            margin: 0 0 1.875rem 0;
            min-height: 21.375rem;
            @include breakpoint(tab-max){
                margin: 0 0 1.25rem 0;
                min-height: 308px;
            }
            .cmp-teaser__image{
                margin: 0;
            }
        }
        &__title {
            h1, h2, h3, h4, h5, h6, p {
                padding: 0 0 .625rem 0;                
                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                letter-spacing: normal;
                color: $text-dark-grey;
                text-transform: capitalize;
            }            
        }
        &__description {
            p {
                padding: 0 0 1.25rem 0;
                @include ann-describtion(0.8125rem, $font-regular-base, 1.25rem);
                letter-spacing: 0.0125rem;
                color: $text-medium-dark-grey;
                margin: 0;
                width: 15rem;
            }
        }
        &__action-link {
            color: $color-light-blue;
            padding: 0;
            line-height: .75rem;
            padding-bottom: 5px;
            letter-spacing: 0.114375rem!important;
        }
    }
}
.store-team-two-up {
    @extend .meet-team-card;
}