.page-loader {
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__spinner {
        width: 40px;
        height: 40px;
        animation-name: kf-page-loader-spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes kf-page-loader-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}