.loading-spinner {
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid;
    animation: load 1s infinite linear;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.--white {
        border: 2px solid rgb(219, 219, 219);
    }
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
