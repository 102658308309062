.product-details {
  background-color: $cool-light-grey;
  padding-bottom: 0;
  margin-bottom: 0;
  @include breakpoint(tab) {
    padding: 0;
  }
  .header-section {
    @include breakpoint(mobile-portrait) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .modal-popup .modal-popup-content {
    width: 100%;
  }
  .modal-popup .modal-popup-content .close-button {
    right: 20px;
    top: 20px;
    @include breakpoint(mobile) {
      right: 30px;
    }
  }
  .print-overview-left,
  .print-overview-right {
    padding: 0;
    @include breakpoint(mobile) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .print-overview {
    margin: 0;
  }
  .kf-react-container {
    max-width: 1920px !important;
    width: 100%;
    padding-left: 50px !important;
    padding-right: 50px !important;
    @include breakpoint(desktop-extra-sm) {
      padding-left: 27px !important;
      padding-right: 27px !important;
    }
    @include breakpoint(tab-max) {
      padding-left: 32px !important;
      padding-right: 32px !important;
    }
    @include breakpoint(mobile) {
      padding-left: 18px !important;
      padding-right: 18px !important;
    }
  }
  &__content-section {
    padding: 0;
  }
  .add-to-cart {
    @include breakpoint(tab) {
      margin: 0;
      padding: 0;
    }
    @include breakpoint(mobile) {
      padding: 0;
    }
    .bottom-section {
      justify-content: center;
      padding: 0 0 20px;
      @include breakpoint(tab) {
        column-gap: 17px;
        width: 100%;
        margin: 0px;
      }
      .add-to-compare {
        margin-right: 29px;
        @include breakpoint(tab) {
          margin-right: 0;
        }
        @include breakpoint(mobile) {
          display: none;
        }
        span {
          color: $text-medium-dark-grey;
          font-family: $lato-regular;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          border-radius: 5px!important;
        }
      }
      .favorites {
        margin-right: 28px;
        @include breakpoint(tab) {
          margin-right: 0;
        }
      }
      .favorites {
        @include breakpoint(mobile) {
          position: inherit;
        }
      }
      .text {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        padding-left: 10px;
      }
      .checkbox {
        font-size: 14px;
        color: $text-medium-dark-grey;
        margin-bottom: 0;
        align-items: center;
      }
      .print-share-container {
        @include breakpoint(mobile) {
          display: none;
        }
        padding-top: 0px;
        .print-and-share span {
          margin-right: 30px;
          color: $text-medium-dark-grey;
          font-family: $lato-regular;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          cursor: pointer;
          @include breakpoint(tab) {
            margin-right: 0px;
          }
        }
        .print-and-share span img {
          padding-right: 8px;
          margin-left: 0;
          margin-top: -1px;
          @include breakpoint(tab-max) {
            padding-right: 0;
            margin-right: 8px;
          }
        }
        .print-and-share {
          @include breakpoint(tab) {
            column-gap: 17px;
          }
        }
        .print-and-share .share-button img {
          padding-right: 8px;
          margin-top: -4px;
          @include breakpoint(tab-max) {
            padding-right: 0;
            margin-right: 8px;
            width: 14px;
            height: 15px;
          }
        }
        .social-share {
          height: 0px;
        }
      }
    }
    .bottom-section-print-block {
      justify-content: flex-start;
    }
    .quantity-selector-block {
      .quantity-selector-container {
        height: 51px;
        padding-left: 0;
        @include breakpoint(tab-max) {
          padding-right: 0;
        }
        .quantity-selector {
          height: 50px;
          &__count {
            color: $text-light-grey-cap;
            font-family: $lato-regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin-top: 12px;
            background: transparent;
          }
        }
        .plus {
          width: 8.49px;
          height: 8.49px;
        }
        .line-1 {
          padding-right: 10px;
        }
        .line-2 {
          padding-left: 1px;
          margin-left: 4.5px;
        }
      }
      .add-to-cart-container {
        padding-right: 0;
        padding-bottom: 20px;
        .kf-react-button {
          &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            &:hover,
            &:focus {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        }
        .button.large {
          height: 50px;
          color: $white;
          font-family: $lato-bold;
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 2px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          background-color: $maroon;
          border-radius: 1px;
        }
        .button.disabled {
          background-color: $annsacks-add-to-cart-btn !important;
        }
      }
      .add-to-cart-button-main-container {
        padding-left: 0;
      }
    }
    .checkbox__marker {
      height: 15px;
      width: 15px;
      border: 1px solid $cool-dark-grey;
      border-radius: 5px!important;
      margin-right: 0;
      margin-top: 0;
    }
    .out-of-stock-container {
      margin: 0 -15px 25px -15px;
      @include breakpoint(tab) {
        margin: 0 0 20px;
      }
      .out-of-stock {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        @include breakpoint(mobile) {
          padding-bottom: 10px;
        }
      }
      .out-of-stock-description {
        color: $text-light-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        padding-bottom: 20px;
        @include breakpoint(mobile) {
          padding-bottom: 20px;
        }
      }
      .input-field {
        margin-bottom: 25px;
      }
      .input-field__border {
        padding: 8px 15px;
        border: 1px solid $cool-dark-grey;
        border-radius: 3px;
        background-color: $white;
      }
      .input-field__value input {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26px;
      }
      .email-address-field .input-field__value label {
        color: $text-light-grey;
        font-family: $lato-regular;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        padding-left: 0;
        margin-bottom: 0px;
      }
      .notify-btn .button.primary {
        background-color: $color-medium-blue;
      }
      .notify-btn .button.primary:hover {
        background-color: $color-medium-blue-cap;
      }
      .notify-btn .button.large {
        height: 50px;
        color: $white;
        font-family: $lato-bold;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 2.5px;
        line-height: 24px;
        text-align: center;
        border-radius: 1px;
        text-transform: uppercase;
      }
      .notify-btn .button.disabled {
        background-color: $cool-dark-grey !important;
      }
      .out-of-stock-success-popup
        .modal-popup
        .modal-popup-content
        .modal-popup-body
        .success-popup__title {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
        padding-top: 0;
        padding: 0 25px;
        @include breakpoint(mobile) {
          font-size: 18px;
          letter-spacing: 0;
          line-height: 28px;
          padding-top: 0px;
        }
      }
      .out-of-stock-success-popup
        .modal-popup
        .modal-popup-content
        .modal-popup-body
        .success-popup__message {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        padding: 20.8px 0 0 0;
        margin: 0 104px 0 104px;
        @include breakpoint(mobile) {
          font-size: 13px;
          letter-spacing: 0.2px;
          line-height: 20px;
          padding: 25px 0 0 0;
          margin: 0;
        }
      }
      .out-of-stock-success-popup
        .modal-popup
        .modal-popup-content
        .modal-popup-body
        .success-popup__done-cta {
        padding-top: 35px;
        padding-bottom: 30px;
        margin-left: 0;
        text-align: center;
        @include breakpoint(mobile) {
          padding-top: 30px;
          padding-bottom: 0;
        }
      }
      .out-of-stock-success-popup
        .modal-popup
        .modal-popup-content
        .modal-popup-body
        .success-popup__done-cta
        .doneCTA {
        height: 50px;
        width: 250px;
        color: $color-medium-blue;
        font-family: $lato-bold;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 2.5px;
        line-height: 10px;
        text-align: center;
        text-transform: uppercase;
        border: 1px solid $color-medium-blue;
        border-radius: 1px;
        padding-top: 15px;
        padding-bottom: 15px;
        @include breakpoint(mobile) {
          font-size: 11px;
          font-weight: normal;
          letter-spacing: 2.5px;
          line-height: 10px;
        }
      }
      .out-of-stock-success-popup .modal-popup .modal-popup-content {
        width: 540px;
        padding: 0;
        height: 53vh;
        @include breakpoint(mobile) {
          width: 338px;
          padding: 0;
          height: 40vh;
        }
        @include breakpoint(tab) {
          width: 540px;
          padding: 0;
          height: 100vh;
        }
      }
      .input-field__error-message {
        font-family: $lato-regular;
      }
      .out-of-stock-success-popup
        .modal-popup
        .modal-popup-content
        .close-button {
        top: 39.5px;
        right: 39.29px;
        @include breakpoint(mobile) {
          top: 19.5px;
          right: 19.29px;
        }
      }
      .modal-popup .modal-popup-content .close-button img {
        height: 21px;
        width: 21.21px;
      }
    }
    .product-details__stock-alert span {
      font-family: $lato-regular;
    }
    .product-details__available-color-section {
      padding-bottom: 0;
    }
  }
  .recommended-list-main-block {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 80px;
    width: 100%;
    margin: 0 0 40px;
    @include breakpoint(desktop-lg) {
      justify-content: space-between;
    }
    @include breakpoint(desktop-mid) {
      justify-content: center;
      column-gap: 60px;
    }
    @include breakpoint(mobile) {
      column-gap: 30px;
    }
  }
  .recommended-list-details {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .recommended-list-first-link,
  .recommended-list-second-link {
    color: $color-light-blue;
    font-family: $lato-regular;
    font-size: 11px;
    letter-spacing: 1.83px;
    line-height: 12px;
    text-decoration: underline;
    text-transform: uppercase;
    text-decoration-thickness: 1px;
    text-underline-offset: 7px;
    text-decoration-color: $color-light-blue;
  }
  .recommended-list-second-link {
    @include breakpoint(tab) {
      padding-left: 26px;
    }
  }
  .recommended-list-first-link:hover,
  .recommended-list-second-link:hover {
    color: $color-light-blue;
  }
  .add-to-cart-main-block {
    margin: 0;
  }
  .pdp-annsacks-not-have-price {
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 35px 0 35px 0;
    margin: 0;
  }
  &__breadcrumb {
    margin-top: 36.62px;
    margin-bottom: 66px;
    @include breakpoint(desktop-extra-sm) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    @include breakpoint(mobile) {
      margin-top: 12px;
      margin-bottom: 16px;
    }
    @include breakpoint(tab) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  &__breadcrumb-link,
  &__breadcrumb > section {
    cursor: pointer;
    font-family: $lato-regular;
    color: $text-medium-dark-grey;
    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-decoration: none;
    &:hover {
      color: $text-medium-dark-grey;
    }
  }
  .orderSampleWrapper .stickyHeader .sortWrapper {
    padding: 15px 10px;
    @media screen and (max-width: 1560px) {
      margin-right: -9px;
    }
  }
  .image-reel__image-thumb-view-ul {
    .image-reel__image-list {
      margin-bottom: 14px;
      @include breakpoint(desktop-extra-lite-sm) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      @include breakpoint(tab-max) {
        margin-right: 11px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .inputWrapper {
    .inputBox {
      input {
        color: $text-light-grey-cap;
        @include breakpoint(mobile) {
          border: 1px solid $cool-dark-grey;
          color: $text-dark-grey;
          letter-spacing: 0.44px;
          font-size: 16px;
          background-color: $color-white;
        }
      }
      .labelLF {
        @include breakpoint(mobile) {
          top: 58px;
        }
      }
    }
    .select-dropdown__field {
      @include breakpoint(mobile) {
        border-radius: 3px;
      }
    }
    .select-dropdown__value {
      @include breakpoint(mobile) {
        color: $text-medium-dark-grey;
        font-size: 16px;
      }
    }
    .select-dropdown__display {
      font-size: 16px;
      letter-spacing: 0.44px;
    }
  }
}
.product-details__image-gallery {
  padding: 0;
  height: 100%;
  @include breakpoint(tab-max) {
    min-height: auto;
  }
}
.product-details .image-reel__image-thumb-view {
  width: 61px;
  margin-right: 0;
  max-width: 61px;
  padding-right: 0;
  @include breakpoint(desktop-extra-lite-sm) {
    order: 2;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: $charcoal;
      border-radius: unset;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px $cool-dark-grey;
      background: $cool-dark-grey;
      border-radius: unset;
    }
  }
}
.product-details__need-link-block {
  margin: 34.5px 0 44.5px 0;
  @include breakpoint(mobile) {
    margin: 34.34px 0 44.5px 0;
  }
  @include breakpoint(tab) {
    margin: 19.84px 0 30px 0;
  }
}

@media print {
  .product-select-style__product-devider,
  .recommended-list-details,
  .sticky-header.container-fluid,
  .product-select-style__color-tooltip,
  .product-select-style__shape-tooltip,
  .pdfList,
  .asproductrecommendation,
  .trimCalculatorTooltipIcon,
  .tabNav > ul > li:last-child,
  .help-support.aem-GridColumn.aem-GridColumn--default--12,
  .product-select-style__color-select-tooltip,
  .teaser_v2.teaser.pdp-feature-image-top img,
  .product-details__print-note,
  .teaser_v2.teaser.pdp-feature-image-top {
    display: none !important;
  }

  div#product-details__overview-block {
    margin-top: 50px;
  }
  .image-reel__stack-list-image {
    margin: 0px !important;
  }
  div#trim-calculator_main-block,
  .product-select-style__main-container {
    max-width: 100% !important;
    padding: 0 0 4.61px 0 !important;
    float: none;
  }
  .summaryWrapper .product-details__content-section {
    float: none !important;
  }
  .product-summary__icons-block {
    border-bottom: 1px solid $text-light-grey !important;
  }
  .product-select-style__finish-tooltip {
    display: none;
  }
  .header,
  .inputWrapper,
  .totalLFWrapper,
  .totalBox,
  .rightBox {
    padding: 7.5pt;
  }
}
.price-list {
  padding-top: 20%;
}
.price-list,
.price-list-sku {
  display: flex;
  justify-content: flex-end;
  @include breakpoint(mobile) {
    padding-top: 0;
    justify-content: left;
  }
  .list-label {
    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 20px;
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
  }
}
.price-list-sku {
  width: 100%;
  height: 50.22px;
  margin-top: 60px;
  justify-content: space-between;
}
.sku-divider {
  border-top: 2px solid $cool-dark-grey;
}
.width100 {
  width: 100%;
}
.price-list__price {
  padding-left: 20px;
  @include breakpoint(tab) {
    padding: 0;
  }
}
.price-listTooltipIcon {
  padding-left: 5px;
}
.price-list__list-tooltip--show {
  display: block;
  position: relative;
}
.price-list__list-tooltip--hide {
  display: none;
}
.product-select-style__color-custom-block {
  &.product-select-style-shape {
    .product-select-style__list-tooltip--show {
      position: static;
      .product-select-style__list-tooltip-color-wrapper {
        left: 0;
        bottom: 133px;
        @include breakpoint(tab-max) {
          bottom: 118px;
        }
      }
    }
  }
}
.product-select-style__color-custom-block {
  &.product-select-style-color {
    &.product-color-dropdown {
      margin-bottom: 20px;
      @include breakpoint(tab-max) {
        margin-bottom: 0;
      }
    }
    .product-select-style__list-tooltip--show {
      position: static;
      .product-select-style__list-tooltip-color-wrapper {
        bottom: 113px;
        left: 0;
        @include breakpoint(tab-max) {
          bottom: 100px;
        }
      }
    }
  }
}

.product-select-style {
  &__color-custom-block {
    display: flex;
    align-items: center;
    .product-select-style__color-custom-select-block {
      margin-bottom: 0;
      &.mb-20 {
        margin-bottom: 20px;
      }
      @include breakpoint(mobile) {
        margin-bottom: 10px;
      }
    }
  }
  &__color-custom-block,
  &__shape-custom-block {
    .product-select-style__list-tooltip--show {
      @include breakpoint(mobile) {
        position: absolute;
      }
      > .product-select-style__list-tooltip-color-wrapper {
        @include breakpoint(tab) {
          bottom: 30px;
          left: 310px;
        }
        @include breakpoint(mobile) {
          left: -135px;
        }
      }
    }
  }
  &__color-img-block,
  &__shape-img-block {
    .product-select-style__list-tooltip--show {
      > .product-select-style__list-tooltip-color-wrapper,
      > .product-select-style__list-tooltip-wrapper {
        @include breakpoint(tab) {
          bottom: 50px;
          left: 20px;
        }
      }
    }
  }
  &__color-img-block {
    @include breakpoint(tab) {
      display: flex;
      flex-flow: wrap;
      row-gap: 10px;
    }
  }
  &__modal-close {
    > img {
      filter: invert(100%);
    }
  }
  .product-select-style__color {
    &.color-finish {
      margin-bottom: 20px;
      @include breakpoint(mobile) {
        margin-bottom: 10px;
      }
    }
  }
}
.pdp-container {
  max-width: 1920px;
  width: 100%;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  @include breakpoint(desktop-extra-sm) {
    padding-left: 27px;
    padding-right: 27px;
  }
  @include breakpoint(tab-max) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include breakpoint(mobile) {
    padding-left: 18px;
    padding-right: 18px;
  }
}
.tabWrapper {
  @extend .pdp-container;
  padding-top: 111px;
  padding-bottom: 112px;
  @include breakpoint(desktop-large) {
    max-width: 1820px;
    padding: 111px 0 112px;
  }
  @include breakpoint(desktop-lg) {
    max-width: 1380px;
    padding: 111px 0 112px;
  }
  @include breakpoint(desktop-mid) {
    padding: 50px 27px 60px;
  }
  @include breakpoint(tab) {
    padding: 50px 32px 60px;
  }
  @include breakpoint(mobile) {
    padding: 50px 18px;
  }
}
.tabContent {
  margin-top: 0;
  padding-top: 80px;
  @include breakpoint(tab-max) {
    padding-top: 40px;
  }
  @include breakpoint(mobile) {
    padding-left: 0;
    padding-right: 0;
  }
}
.wrapper {
  @include breakpoint(desktop-extra-sm) {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.product-info {
  display: flex;
  justify-content: space-between;
  @include annsacks-desk(gap, 42, 158);
  @include breakpoint(mobile) {
    display: block !important;
  }
  @include breakpoint(tab-max) {
    display: flex;
  }
  .product-img-block {
    max-width: 60.8%;
    width: 100%;
    margin-bottom: 40px;
    @include breakpoint(desktop-extra-lite-sm) {
      max-width: 50%;
    }
    @include breakpoint(tab-max) {
      max-width: 50%;
    }
    @include breakpoint(mobile) {
      max-width: 100%;
    }
    .image-reel {
      display: flex;
      gap: 24px;
      flex-wrap: nowrap;
      @include breakpoint(desktop-extra-lite-sm) {
        flex-direction: column;
        position: relative;
        gap: unset;
      }
      &.resourcecredits {
        @include breakpoint(desktop-extra-lite-sm) {
          padding-bottom: 30px;
        }
      }
      &__image-thumb-view-ul {
        @include breakpoint(desktop-extra-lite-sm) {
          display: flex;
          gap: 11px;
          margin-bottom: 20px;
          height: 61.5px;
        }
        @include breakpoint(tab-max) {
          gap: unset;
        }
      }
      &__photo-credit {
        @include breakpoint(desktop-extra-lite-sm) {
          position: unset;
          bottom: 0;
          margin-top: 16px;
          padding-left: 0;
        }
        @include breakpoint(tab-max) {
          position: relative;
          margin-bottom: 20px;
          color: $text-dark-grey;
        }
        @include breakpoint(mobile) {
          margin-top: 20px;
          font-size: 14px;
          color: $text-medium-dark-grey;
          line-height: 20px;
          font-style: normal;
        }
        @include breakpoint(tab) {
          margin-top: 20px;
          font-size: 14px;
          color: $text-medium-dark-grey;
          line-height: 20px;
          font-style: normal;
        }
        @include breakpoint(ipad-landscape) {
          margin-top: 15px;
        }
      }
      .image-reel__image-stack-view {
        max-width: unset;
        width: auto;
        margin-left: 0;
        flex: 100%;
        @include breakpoint(desktop-extra-lite-sm) {
          margin-bottom: 20px;
          order: 1;
        }
      }
    }
    .image-gallery__image-stack-view {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .product-info-block {
    max-width: 39.2%;
    width: 100%;
    padding-bottom: 0;
    .product-summary {
      padding: 0;
    }
    @include breakpoint(desktop-extra-lite-sm) {
      max-width: 50%;
      overflow: hidden;
    }
    @include breakpoint(tab) {
      max-width: 45%;
      overflow: visible;
    }
    @include breakpoint(mobile) {
      max-width: 100%;
    }
  }
  .image-reel__image-stack-view-block {
    width: auto;
    height: auto;
    position: relative;
    .image-reel__image-stack-list {
      width: 100%;
      background-color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      @include annsacks-desk(max-width, 463, 863);
      @include annsacks-desk(height, 482, 944);
      margin: auto;
      @include breakpoint(mobile) {
        @include annsacks-mobile(max-width, 375, 463);
        @include annsacks-mobile(height, 390, 482);
      }
      & li {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    .image-reel__stack-list-image {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}
.pdp-recommened {
  @extend .pdp-container;
  @include breakpoint(mobile) {
    padding: 0;
  }
  .pdp-tile__main-block {
    display: flex;
    margin-top: 0;
    padding: 70px 0;
    justify-content: space-between;
    @include annsacks-desk(gap, 44, 60);
    @include breakpoint(tab-max) {
      max-width: 646px;
      margin: auto;
      flex-direction: column;
      padding-top: 46px;
      gap: inherit;
    }
    @include breakpoint(tab) {
      padding-bottom: 77px;
    }
    @include breakpoint(mobile) {
      padding-bottom: 60px;
    }
    .pdp-recommended-info {
      @include annsacks-desk(max-width, 300, 554);
      width: 100%;
      padding-top: 84px;
      @include breakpoint(tab-max) {
        max-width: 100%;
        padding-top: 0;
      }
      @include breakpoint(mobile) {
        padding: 0 18px;
      }
      .pdp-tile__title {
        @include annsacks-desk(font-size, 28, 54);
      }
      .pdp-tile__description {
        @include annsacks-desk(font-size, 18, 22);
      }
    }
    .pdp-recommended-cards {
      @include annsacks-desk(max-width, 594, 900);
      width: 100%;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
      @include breakpoint(tab-max) {
        max-width: 100%;
        overflow: hidden;
      }
      .pdp-tile-container-card-link {
        width: 100%;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 0;
        @include breakpoint(tab-max) {
          margin-top: 40px;
          line-height: 0;
        }
        .selected {
          background-color: $text-medium-dark-grey;
        }
      }
      .pdp-tile__product-outer-block {
        border: 1px solid $cool-medium-grey;
      }
    }
  }
}
.shop-all-btn {
  display: flex;
  gap: 50px;
  align-items: center;
}
.pdp-recommended-content {
  max-width: 612px;
  width: 100%;
  margin-bottom: 70px;
  @include breakpoint(tab) {
    margin-bottom: 30px;
  }
  @include breakpoint(mobile) {
    margin-bottom: 45px;
  }
  .pdp-tile__title {
    @include breakpoint(tab) {
      margin-bottom: 50px;
    }
    @include breakpoint(mobile) {
      margin-bottom: 60px;
    }
  }
  .pdp-tile__description {
    @include breakpoint(tab) {
      margin-bottom: 30px;
    }
    @include breakpoint(mobile) {
      margin-bottom: 15px;
    }
  }
}
.pdp-recommened {
  .shop-all-btn {
    @include breakpoint(desktop-mid) {
      align-items: flex-start;
      flex-direction: column;
      gap: 30px;
    }
    @include breakpoint(tab-max) {
      align-items: flex-start;
      flex-direction: column;
      gap: inherit;
      position: relative;
    }
    .pdp-tile__button-block {
      @include breakpoint(tab-max) {
        margin-bottom: 94px;
      }
      @include breakpoint(mobile) {
        width: 100%;
        line-height: 0;
      }
    }
    .pdp-tile__shop-all-btn {
      @include breakpoint(tab-max) {
        width: 340px;
      }
      @include breakpoint(mobile-portrait) {
        width: 100%;
      }
    }
  }
}
.product-img-block {
  .image-gallery__image-stack-list > img {
    @include annsacks-mobile(width, 286, 353);
    @include annsacks-mobile(height, 286, 353);
    margin: auto;
    @include breakpoint(mobile) {
      object-fit: cover;
    }
  }
}
.product-details
  .product-info
  .product-details__content-section.product-info-block
  .summaryWrapper.select-style-block
  .product-details__content-section
  .trim-calculator_main-block {
  @include breakpoint(tab) {
    margin: 30px 0;
  }
}
.product-details {
  .add-to-cart {
    .out-of-stock-container {
      .out-of-stock-success-popup {
        .modal-popup {
          &.show-modal-popup {
            .modal-popup-content {
              width: 338px !important;
              height: 278px !important;
              @include breakpoint(mobile) {
                height: 278px !important;
                min-height: 278px !important;
              }
              .close-button-holder {
                .close-button {
                  @include breakpoint(tab-max) {
                    top: 20px;
                    right: 20px;
                  }
                  img {
                    width: 41px;
                    height: 41px;
                    right: 18px;
                    @include breakpoint(tab-max) {
                      width: 21px;
                      height: 21px;
                    }
                  }
                }
              }
              .modal-popup-body {
                padding: 0;
                @include breakpoint(mobile) {
                  padding: 50px 25px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.asproductoverview {
  .page-container {
    .product-details {
      .modal-popup {
        .modal-popup-content {
          .close-button-holder {
            .close-button {
              img {
                width: 41px;
                height: 41px;
                left: -14px;
                position: absolute;
                @include breakpoint(tab-max) {
                  left: -35px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.add-to-cart {
  .bottom-section {
    .favorites {
      img {
        width: 15px;
        height: 13.3px;
      }
    }
  }
}
.add-to-compare {
  .checkbox__marker {
    background: $color-white;
  }
  input:checked ~ span {
    .checkbox__marker {
      background: $text-medium-black;
    }
  }
  .tooltip__container {
    position: absolute;
  }
  align-self: center;
}

// Product Details UI
.product-details {
  .product-info {
    .product-details__content-section.product-info-block {
      .summaryWrapper {
        .summaryWrapper__top-block {
          .product-select-style__product-devider {
            @include breakpoint(desktop-small) {
              display: block;
              height: 1px;
              border-top: 1px solid $text-light-grey;
            }
          }
        }
        .product-details__content-section {
          .trim-calculator_main-block {
            margin-bottom: 25px;
            @include breakpoint(mobile){
              overflow: unset;
            }
            .header {
              @include breakpoint(desktop-mid) {
                margin-bottom: 20px;
              }
              @include breakpoint(tab-max) {
                margin-bottom: 20px;
              }
            }
            .totalLFWrapper {
              .valText {
                font-family: $lato-regular;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: $text-light-grey;
              }
              @include breakpoint(tab-max) {
                padding: 30px 0 20px;
              }
            }
            .inputWrapper {
              .inputBox {
                select {
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.44px;
                  color: $text-medium-dark-grey;
                }
                .trim-calculator__overage-select-box {
                  background-color: $cool-light-grey;
                }
                #trim-calculator__overage-input-box {
                  background-color: $cool-light-grey;
                }
              }
            }
            .totalBox {
              #trim-calculator__finalTotal {
                font-family: $lato-regular;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0;
                color: $text-dark-grey;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .add-to-cart-main-block {
            .add-to-cart-container {
              @include breakpoint(tab-max) {
                margin-bottom: 20px;
                padding-bottom: 0;
              }
            }
          }
          .product-select-style {
            .product-select-style__finish-form {
              @include breakpoint(mobile) {
                padding-bottom: 0;
              }
              .form {
                > div {
                  width: 100%;
                }
              }
            }
            .product-select-style__color {
              @include breakpoint(mobile) {
                padding-top: 20px;
              }
            }
          }
        }
        .product-select-style__list-tooltip--show {
          @include breakpoint(mobile) {
            position: absolute;
            display: block;
            right: 200px;
          }
        }
      }
    }
  }
}
//made-to-order popup
.product-details {
  .modal-popup {
    &.made-to-order {
      @include breakpoint(mobile) {
        padding: 0 18px;
      }
      .modal-popup-content {
        max-width: 540px;
        width: 100%;
        height: auto;
        @include breakpoint(tab-max) {
          max-width: 612px;
        }
        @include breakpoint(mobile) {
          max-width: 100%;
          height: auto !important;
          min-height: auto !important;
          overflow-x: hidden;
        }
      }
      &.email-modal,
      &.phone-modal {
        .modal-popup-content {
          height: auto;
        }
      }
    }
  }
}
.asproductoverview {
  .page-container {
    .product-details {
      .modal-popup {
        &.made-to-order {
          .modal-popup-content {
            .modal-popup-body {
              background-color: transparent;
              padding: 0;
            }
            .close-button-holder {
              .close-button {
                right: 30px;
                top: 30px;
                @include breakpoint(tab-max) {
                  right: 10px;
                  top: 10px;
                }
                img {
                  width: 40px;
                  height: 40px;
                  position: static;
                }
              }
            }
          }
        }
      }
    }
  }
}
.made-to-order {
  font-family: $lato-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .custom-order-head {
    padding: 70px 110px;
    background-color: $color-light-grey;
    text-align: center;
    @include breakpoint(tab-max) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
    @include breakpoint(mobile) {
      padding: 50px 76px;
    }
    .title {
      font-size: 22px;
      line-height: 32px;
      color: $text-dark-grey;
      margin-bottom: 10px;
      @include breakpoint(tab-max) {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 28px;
      }
      @include breakpoint(mobile) {
        max-width: 240px;
        width: 100%;
        margin: 0 auto;
      }
    }
    .desc {
      max-width: 340px;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: $text-medium-dark-grey;
      margin: auto;
    }
  }
  .custom-order-body {
    padding: 40px 60px;
    @include breakpoint(tab-max) {
      padding: 24px 70px 35px 65px;
    }
    @include breakpoint(mobile) {
      padding: 24px 30px 51px;
    }
  }
  .custom-order-tab {
    .order-tab-head {
      padding-left: 22px;
      @include breakpoint(mobile) {
        padding-left: 0;
      }
      ul {
        margin: 0;
        display: flex;
        column-gap: 40px;
        list-style: none;
        @include breakpoint(mobile) {
          column-gap: 45px;
        }
        li.item {
          font-size: 14px;
          color: $text-light-grey-cap;
          letter-spacing: 2.33px;
          text-transform: uppercase;
          line-height: 22px;
          padding-bottom: 5px;
          @include breakpoint(mobile) {
            letter-spacing: 2.33px;
          }
          @media only screen and (max-width: 470px) {
            white-space: nowrap;
            transition: ease 0.3s;
          }
          &.active {
            color: $text-dark-grey;
            position: relative;
            @media only screen and (max-width: 400px) {
              transform: translateX(-60px);
            }
            &::before {
              content: "";
              border-bottom: 1px solid $text-dark-grey;
              width: 100%;
              position: absolute;
              bottom: 0;
            }
          }
          &:first-child {
            @media only screen and (max-width: 400px) {
              transform: translateX(-60px);
            }
            &.active {
              @media only screen and (max-width: 400px) {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
    .connect-info {
      margin-top: 15px;
      margin-bottom: 20px;
      @include breakpoint(tab-max) {
        margin-top: 14px;
        margin-bottom: 0px;
      }
      &.contact-us-main {
        @media only screen and (max-width: 400px) {
          animation: fadeIn 1s forwards;
          opacity: 0;
        }
      }
    }
    .contact-info {
      margin-top: 45px;
      max-height: 220px;
      overflow-y: scroll;
      position: relative;
      @include breakpoint(tab-max) {
        margin-top: 44px;
        max-height: 230px;
      }
      @media only screen and (max-width: 400px) {
        animation: fadeIn 1s forwards;
        opacity: 0;
      }
      ul {
        margin: 0;
        list-style: none;
      }
      li {
        border-bottom: 1px solid $cool-dark-grey-cap;
        padding-bottom: 19.5px;
        margin-bottom: 14.5px;
        display: flex;
        column-gap: 24.5px;
        &:last-child() {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: 0;
        }
        @include breakpoint(tab-max) {
          padding-bottom: 24.5px;
          margin-bottom: 24.5px;
        }
        .icon {
          width: 20px;
        }
      }
      .info {
        h3 {
          font-size: 18px;
          line-height: 28px;
          color: $text-dark-grey;
          margin-bottom: 2px;
          @include breakpoint(tab-max) {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 4px;
          }
          .right-arrow {
            font-size: 12px;
            margin-left: 12.5px;
          }
        }
        p {
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: $text-medium-dark-grey;
        }
      }
    }
  }
  .custom-order-product-info {
    border-top: 1px solid $cool-dark-grey-cap;
    display: flex;
    justify-content: space-between;
    column-gap: 31px;
    padding: 17.5px 30px 25.8px 20px;
    @include breakpoint(tab-max) {
      padding: 26px 68px 20px;
      justify-content: flex-start;
    }
    @include breakpoint(mobile) {
      column-gap: 20px;
      padding: 26px 37px 14px 12px;
    }
    .order-product-img {
      max-width: 81px;
      width: 100%;
      height: 84px;
      border: 1px solid $color-extra-light-gray;
      border-radius: 2px;
      @include breakpoint(tab-max) {
        max-width: 80px;
        height: 83px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .order-product-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      @include breakpoint(mobile) {
        display: block;
      }
    }
    .order-product-cnt {
      max-width: 260px;
      width: 100%;
      @include breakpoint(mobile) {
        margin-bottom: 14px;
      }
      .title {
        font-size: 18px;
        color: $text-dark-grey;
        line-height: 24px;
        margin-bottom: 4px;
        @include breakpoint(mobile) {
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 2px;
        }
      }
      .desc {
        font-size: 13px;
        color: $text-medium-dark-grey;
        line-height: 20px;
        letter-spacing: 0.2px;
        @include breakpoint(mobile) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .order-product-price-info {
      text-align: right;
      @include breakpoint(mobile) {
        text-align: left;
      }
      .price-lable {
        font-size: 11px;
        color: $text-medium-dark-grey;
        letter-spacing: 1.67px;
        display: block;
      }
      .price {
        font-size: 16px;
        color: $text-dark-grey;
        line-height: 24px;
        .range {
          font-size: 10px;
          color: $text-medium-dark-grey;
          letter-spacing: 1.67px;
          line-height: 16px;
        }
      }
    }
  }
  .letus-form {
    padding-bottom: 35px;
    margin-bottom: 38px;
    border-bottom: 1px solid $cool-dark-grey;
    @include breakpoint(tab-max) {
      padding-bottom: 50px;
      margin-bottom: 41px;
    }
    .input-field__border {
      border: 1px solid $cool-dark-grey-cap;
      &.--with-error {
        border-color: $color-red-alert;
      }
      &.--focused {
        border-color: $text-dark-grey;
      }
    }
    .--with-no-error {
      position: relative;
      &::after {
        content: "";
        background-image: url(../images/checkmark.svg);
        width: 14px;
        height: 11px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: inline-block;
        position: absolute;
        top: 24px;
        right: 15px;
        -webkit-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;
      }
    }
    .connect-row {
      display: flex;
      width: 100%;
      @include breakpoint(tab-max) {
        display: block;
      }
      .connect-col {
        width: 50%;
        @include breakpoint(tab-max) {
          width: 100%;
        }
        &:first-child {
          padding-right: 10px;
          @include breakpoint(tab-max) {
            padding-right: 0;
          }
        }
        &:last-child {
          padding-left: 10px;
          @include breakpoint(tab-max) {
            padding-left: 0;
          }
        }
      }
      .input-field {
        width: 100%;
      }
      &:last-child() {
        .input-field {
          margin-bottom: 0;
        }
      }
    }
  }
  .user-error {
    font-size: 16px;
    color: $color-red-alert;
    line-height: 1pc;
    padding-top: 15px;
  }
  .preference {
    margin-bottom: 40px;
    @include breakpoint(tab-max) {
      margin-bottom: 30px;
    }
    .title {
      font-size: 22px;
      line-height: 32px;
      color: $text-medium-dark-grey;
      margin-bottom: 30px;
      @include breakpoint(tab-max) {
        font-size: 18px;
        color: $text-dark-grey;
        margin-bottom: 15px;
        white-space: nowrap;
      }
    }
    .pref-option {
      display: flex;
      column-gap: 40px;
      label {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
    .radio {
      span {
        font-size: 16px;
        color: $text-medium-dark-grey;
        line-height: 24px;
      }
    }
  }
  .contact-me-btn {
    margin-bottom: 20px;
    @include breakpoint(tab-max) {
      margin-bottom: 30px;
    }
    @include breakpoint(mobile) {
      margin-bottom: 20px;
    }
  }
  .recaptcha-info {
    margin-bottom: 20px;
    @include breakpoint(tab-max) {
      margin-bottom: 40px;
    }
    @include breakpoint(mobile) {
      margin-bottom: 20px;
    }
    .recaptcha-links {
      font-size: 13px;
      line-height: 20px;
      color: $text-medium-dark-grey;
      letter-spacing: 0.2px;
      font-family: $lato-regular;
      a {
        color: $text-medium-dark-grey;
        text-decoration: underline;
      }
    }
  }
  .contact-us__policies {
    ul {
      list-style: none;
      display: flex;
      column-gap: 20px;
      margin-bottom: 0;
      @include breakpoint(mobile) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        row-gap: 15px;
        width: 241px;
      }
      li {
        a {
          font-size: 13px;
          color: $text-medium-dark-grey;
          letter-spacing: 0.2px;
          line-height: 20px;
          text-decoration: underline;
        }
        &:last-child {
          @include breakpoint(mobile) {
            grid-column: span 2;
          }
        }
      }
    }
  }
}
.order-tab-content {
  .error-info-text {
    font-size: 14px;
    line-height: 20px;
    color: $text-medium-dark-grey;
    margin-bottom: 30px;
    padding-left: 22px;
    @include breakpoint(tab-max) {
      padding-left: 0;
    }
  }
}
.connect-info {
  &.contact-us-main {
    .input-field {
      .input-field__border {
        .input-field__prefix {
          margin-left: 0;
        }
      }
    }
  }
}
.connect-row {
  &.connect-email {
    .input-field__prefix {
      img {
        max-width: 21px;
        width: 100%;
      }
    }
  }
  &.connect-phone {
    .input-field__prefix {
      img {
        max-width: 15.16px;
        width: 100%;
      }
    }
  }
}
.product-details {
  .add-to-cart .quantity-selector-block {
    @include breakpoint(mobile) {
      margin: 0px;
      column-gap: 20px;
    }
    .quantity-selector-container {
      @include breakpoint(mobile) {
        max-width: 102px;
      }
      .quantity-selector {
        @include breakpoint(mobile) {
          width: 102px;
        }
      }
    }
    .add-to-cart-container {
      @include breakpoint(mobile) {
        max-width: 217px;
      }
      .made-to-order {
        .button.disabled {
          background-color: $cool-dark-grey-cap !important;
        }
        .email-btn-block,
        .talk-btn-block {
          .button {
            background-color: $color-light-blue;
            letter-spacing: 2.5px;
          }
        }
        .button.contact-me-btn {
          background-color: $color-medium-blue;
          letter-spacing: 2.5px;
        }
      }
    }
    .col-12.add-to-cart-container {
      @include breakpoint(mobile) {
        max-width: unset;
      }
    }
  }
}
.made-to-order {
  &.email-modal {
    .custom-order-head {
      padding: 78px 100px 63px;
      @include breakpoint(tab) {
        padding: 60px 50px 50px;
      }
      @include breakpoint(mobile) {
        padding: 55px 50px 20px;
      }
      .title {
        font-size: 22px;
        line-height: 32px;
        color: $text-dark-grey;
        margin-bottom: 10px;
        letter-spacing: normal;
        @include breakpoint(tab-max) {
          margin-bottom: 0;
        }
        @include breakpoint(mobile) {
          max-width: 240px;
          width: 100%;
          margin: 0 auto;
        }
      }
      .desc {
        max-width: 340px;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: $text-medium-dark-grey;
        margin: auto;
        @include breakpoint(tab-max) {
          margin-top: 10px;
        }
        @include breakpoint(tab) {
          max-width: 340px;
        }
        @include breakpoint(mobile) {
          max-width: 229px;
        }
      }
    }
    .custom-order-body {
      padding: 90px 59px 80px;
      @include breakpoint(tab) {
        padding: 58px 68px 82px;
      }
      @include breakpoint(mobile) {
        padding: 62px 30px 80px;
      }
    }
  }
  .preference.phone-talk {
    margin-bottom: 0;
    @include breakpoint(mobile) {
      flex-direction: column;
      row-gap: 20px;
    }
    .pref-option {
      margin-bottom: 40px;
      @include breakpoint(mobile) {
        flex-wrap: wrap;
      }
      .option-col {
        &:last-child {
          @include breakpoint(mobile) {
            margin-top: 15px;
          }
        }
      }
    }
  }
  &.phone-modal {
    .custom-order-body {
      padding: 40px 60px 83px;
    }
  }
}
@keyframes fadeIn {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#trim-calculator__overage-input-box {
  background-color: $color-white;
}
@media print {
  @include breakpoint(tab-max) {
    .product-details {
      .container {
        min-width: unset !important;
        max-width: 100% !important;
      }
    }
    .preview-cart {
      display: none;
    }
  }
}
