// Container bg
.container-bg-halfWhite{
    background: $color-light-grey;
}
.container-bg-medium-grey{
    background: $color-medium-grey;
}
// Carousel Header style
.carousel-header-info{
    .cmp-carousel__title,p{
        @include headingtitle_h3;
    }
    .cmp-carousel__description{
        p{
            @include ann-describtion(1.375rem,$font-regular-base,2rem);
            letter-spacing: normal;
            color: $text-medium-grey;
            margin:0;
            @include breakpoint(tab){
                @include ann-describtion(1.125rem,$font-regular-base,1.75rem)
            }
            @include breakpoint(mobile){
                @include ann-describtion(1.125rem,$font-regular-base,1.75rem)
            }
        }
    }
    .cmp-carousel__action-container{
        .cmp-carousel__action-link{
            font-family: $font-bold;
            display: inline-flex;
            font-size: 0.75rem;
            line-height: 1.5rem;
            letter-spacing: 2.5px;
            color: $color-light-blue;
            border: 1px solid $color-light-blue;
            border-radius: 0;
            padding: 0.75rem 1.6875rem;
            line-height: 2;
            text-transform: uppercase;
            background: transparent;
            box-shadow: none;
            text-decoration: none;
        }
    }
}
// Carousel dots
.carousel-slick{
    .slick-dots{
        top: inherit;
        line-height:0;
        li{
            margin: 0 0.4375rem;
            height: 0.625rem;
            width: 0.625rem;
            button{
                height: 0.625rem;
                width: 0.625rem;
                border: 1px solid $text-medium-dark-grey;
                padding:4px;
                &::before{
                    font-size: 0;
                    width:10px;
                    height:10px;
                }
            }
            &.slick-active{
                button{
                    background: $text-medium-dark-grey;
                    border: 1px solid $text-medium-dark-grey;
                }
            }
        }
    }
}
// Common slick slider style
.cmp-carousel-slick{
    .carousel-header-info{
        .cmp-carousel__action-container{
            .cmp-carousel__action-link{
                @include breakpoint(tab) {
                    position: absolute;
                    bottom: 0;
                    width: 90%;
                    justify-content: center;
                }
                @include breakpoint(mobile){
                    position: absolute;
                    bottom: 21px;
                    width: 90%;
                    justify-content: center;
                }
            }
        }
    }
}
// Carousel swape scroll
.carousel-swipe-scroll {
    overflow: hidden;
    text-align: center;
  @include breakpoint(desktop-small) {
    padding: 7.5rem 0 6.25rem 3.75rem;
  }
  @include breakpoint(tab) {
    padding: 3.75rem 2rem 4.5625rem;
  }
  @include breakpoint(mobile) {
    padding: 3.75rem 1.125rem 6.125rem;
  }
  &.carousel-content-center {
    .cmp-carousel {
      &__title {
        text-align: center;
        width: 100%;
        margin: 0;
        h1, h2, h3, h4, h5, h6, p {
          @include ann-describtion(1.625rem, $font-regular-base, 2.25rem);
          color: $text-medium-dark-grey;
          margin: 0;
          @include breakpoint(tab) {
            @include ann-describtion(1.125rem, $font-regular-base, 1.5rem);
          }
          @include breakpoint(mobile) {
            @include ann-describtion(1.125rem, $font-regular-base, 1.75rem);
          }
        }
      }
    }
    .cmp-carousel{
        max-width: inherit;
        &.cmp-carousel-slick{
            .carousel-header-info{
                @include breakpoint(tab) {
                    display: inherit;
                    width: 100%;
                }
                @include breakpoint(mobile) {
                    width: 100%;
                }
            }
            .slick-initialized {
                .slick-list {                    
                    .slick-track {
                        width:100%;                        
                        .cmp-carousel-slick__item {
                            margin: 0 0.938rem;
                            @include breakpoint(tab) {
                              display: block;
                            }
                            @include breakpoint(mobile) {
                              display: block;
                            }
                            .productcard{
                                .product-card{
                                    max-width: 440px;
                                    min-width:100%;
                                    .product-card__link{
                                        width:25rem;
                                        margin:0 auto;
                                        @include breakpoint (tab){
                                            width:100%;
                                            margin:40px auto;
                                        }
                                        @include breakpoint (mobile){
                                            width:100%;
                                            margin:40px auto;
                                        }
                                        &:hover{
                                            text-decoration:none;
                                        }
                                        .product-card__img-container{
                                            width: 100%;
                                            background: $white;
                                            padding: 6rem 0;
                                            height:auto;
                                            margin-bottom:1.25rem;
                                            @include breakpoint(tab){
                                                height:auto;
                                                padding:4rem 0;
                                                margin-bottom:15px;
                                            }
                                            @include breakpoint(mobile) {
                                                height:auto;
                                                padding:4rem 0;
                                                margin-bottom:15px;
                                            }
                                            .product-card__img{
                                                padding:0;
                                            }
                                        }
                                        .product-card__details{
                                            margin:0;
                                            &:first-child{
                                                margin-top:1.25rem;
                                                padding-right:1.25rem;
                                            }
                                            .product-card__left{
                                                .product-card__name{
                                                    span{
                                                        font-size:1.125rem;
                                                        line-height:1.625rem;
                                                        font-family:$font-regular-base;
                                                        color:$text-dark-grey;
                                                        display:block;
                                                        @include breakpoint(tab){
                                                            display:block;
                                                        }
                                                        @include breakpoint(mobile){
                                                            display:block;
                                                        }
                                                    }
                                                }
                                                .product-card-desc, .product-card__color-text span{
                                                    font-size:0.8125rem;  
                                                    line-height:1.125rem; 
                                                    letter-spacing:0.0125rem;
                                                    font-family:$font-regular-base;
                                                    color:$text-medium-dark-grey;
                                                    margin-top:1.875rem;
                                                    display:block;
                                                }
                                                .product-card-desc{
                                                    margin-top:0.313rem;
                                                }
                                            }
                                            .product-card__right{
                                                .product-card__price{
                                                    padding-right:0;
                                                    font-size:1rem;
                                                    line-height:1.5rem;
                                                    color:$text-dark-grey;
                                                    font-family:$font-regular-base;
                                                }
                                            }
                                        }
                                    }
                                }
                            }                            
                        }
                    }
                }
                &.slick-dotted{
                    &.slick-slider{
                        @include breakpoint(tab){
                            margin-bottom:0;
                        }
                        @include breakpoint(mobile){
                            margin-bottom:0;
                        }
                    }                    
                }
            }
            .slick-dots {
                position: relative;
                margin-top: 3.75rem;
                @include breakpoint(tab) {
                    margin-top: 1.875rem;
                }
                @include breakpoint(mobile) {
                    margin: 2.1875rem 0 6.125rem 0;
                }
        }
        &__title {
          width: 22%;
          margin-right: 3.125rem;
          float: left;
          @include breakpoint(tab) {
            width: 100%;
            margin-right: 3.125rem;
          }
          @include breakpoint(mobile) {
            width: 100%;
            margin-right: 3.125rem;
          }
        }
        &__description {
          width: 45%;
          float: left;
          @include breakpoint(tab) {
            width: 100%;
          }
          @include breakpoint(mobile) {
            width: 100%;
          }
        }
        &__action-container {
          float: right;
          margin-right: 3.75rem;
          @include breakpoint(tab) {
            width: 90%;
            position: absolute;
            bottom: -50px;
            justify-content: center;
            left: 50%;
            margin: 0 0 70px -45%;
          }
          @include breakpoint(mobile) {
            width: 90%;
            position: absolute;
            bottom: -50px;
            justify-content: center;
            left: 50%;
            margin: 0 0 70px -45%;
          }
          .cmp-carousel__action-link {
            position: relative;
            width: 100%;
            justify-content: center;
          }
        }
    }
    .slick-dotted {
      &.slick-slider {
        @include breakpoint(tab) {
          margin-bottom: 0;
        }
        @include breakpoint(mobile) {
          margin-bottom: 0;
        }
      }
      .slick-dots {
        position: relative;
        margin-top: 3.75rem;
        @include breakpoint(tab) {
          margin-top: 1.875rem;
        }
        @include breakpoint(mobile) {
          margin-top: 2.188rem;
        }
      }
    }
  }
  &.carousel-title-center {
    .carousel-header-info {
      h4 {
        font-family: $font-regular-base;
        font-size: 1.625rem;
        line-height: 1.38;
        letter-spacing: normal;
        color: $text-light-grey;
        text-align: center;
        padding: 3.125rem 0 0;
        margin: 0;
        text-transform: inherit;
      }
    }
  }
  .teaser{
    .cmp-teaser{
        &__content{
            background-color:transparent;
            text-align:left;
        }
        &__title{
            @include ann-describtion(0.875rem, $font-regular-base,1rem);
            letter-spacing:0.125rem;
            text-transform:uppercase;
        }
        &__description{
            p{
                font-family: $lato-regular;
                margin-top: 10px;
                letter-spacing: normal;
            }
        }
        &__pretitle{
            @include ann-describtion(0.875rem, $font-regular-base, 1rem);
            text-transform:uppercase;
            margin-top:1.875rem;
            letter-spacing:0.125rem;
            color:$text-dark-grey;
            @include breakpoint(tab){
                @include ann-describtion(0.75rem, $font-regular-base, 1rem);
            }
            @include breakpoint(mobile){
                margin-top:1.563rem;
            }
        }
    }
  }
  }
  .cmp-carousel{
    max-width: 100%;
    margin: unset;
    .product-card{
        text-align: left;
    }
  }
}
// Carousel Swipe hidden
.carousel-swipe-hidden{
    position: relative;
    margin-bottom: 3.125rem;
    @include breakpoint(tab) {
        padding-top: 3.125rem;
    }
    @media only screen and (min-width: 992px)  {
       display: flex;
       justify-content: center;
    }
    .carousel-swipe-scroll{
        @media only screen and (min-width: 1381px)  {
            padding: 0 6.875rem;
        }
        @include breakpoint(desktop-small) {
            padding: 0;
        }
        @include breakpoint(tab) {
            padding: 0;
        }
        .cmp-carousel-slick{
            .carousel-header-info{
                @include breakpoint(mobile) {
                    width: 19.6875rem;
                    margin: 0 auto;
                    padding: 0;
                }
                .cmp-carousel__title,p{
                    font-family: $playfair-regular;
                    font-size: 5.625rem;
                    line-height: 1.07;
                    letter-spacing: -0.109375rem;
                    text-align: center;
                    color: $text-dark-grey;
                    margin: 0 auto;
                    padding: 0;
                    @media only screen and (min-width: 992px)  {
                        width: 43.75rem;
                        float: inherit;
                        padding: 6.25rem 0 1.875rem 0;
                     }
                     @media only screen and (min-width: 768px) and (max-width: 991px) {
                        width: 70%;
                     }
                    @include breakpoint(tab) {
                        font-size: 2.625rem;
                        line-height: 1.14;
                        letter-spacing: -0.073125rem;
                    }
                }
                .cmp-carousel__action-container{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    .cmp-carousel__action-link{
                        @include breakpoint(tab) {
                            width: 90%;
                        }
                    }
                }
            }
            .slick-initialized{
                .slick-list{
                    padding: 0;
                    .slick-track{
                        display: flex;
                        .cmp-carousel-slick__item{
                            margin: 0 0.9375rem;
                            @include breakpoint(tab) {
                                margin: 0 0.4375rem;
                            }
                        }
                    }
                }
                .slick-dots{
                    opacity: 0;
                }
            }
        }
    }
}
// Carousel Sticky
.carousel-sticky{
    position: relative;
    .cmp-container{
        .carousel-swipe-scroll{
            @media only screen and (min-width: 992px) {
                position: relative;
                padding-top: 8.75rem;
            }
            .cmp-carousel{
                &.cmp-carousel-slick{
                    position:relative;
                    .carousel-header-info{
                        h4{
                            @include ann-describtion(0.75rem, $font-regular-base, 1rem);
                            color:$text-dark-grey;
                            margin-bottom:0.938rem;
                            letter-spacing:2px;
                        }
                    }
                    @include breakpoint(tab){
                        flex-direction:column;
                    }
                    @include breakpoint(mobile){
                        flex-direction:column;
                    }
                }
                &__title{
                    @include headingtitle_h3;
                    width: auto;
                    margin: 0 3.125rem 4.375rem 0;
                    h1,h2,h3,h4,h5,h6{
                        margin:0;
                    }
                    @include breakpoint(tab){
                        margin-right: 0;
                        width: 100%;
                        margin-bottom:1.875rem;
                    }
                    @include breakpoint(mobile){
                        margin-right: 0;
                        width: 100%;
                        margin-bottom:1.875rem;
                    }
                }
                &__description{
                    width:100%;
                    p{
                        @include ann-describtion(1.125rem, $font-regular-base, 1.75rem);
                        color:$text-medium-dark-grey;
                        width:100%;
                        @include breakpoint(tab){
                            @include ann-describtion(0.875rem, $font-regular-base, 1.25rem);
                        }
                        @include breakpoint(mobile){
                            @include ann-describtion(0.875rem, $font-regular-base, 1.25rem);
                        }
                    }
                    @include breakpoint(tab){
                        margin-bottom:2.5rem;
                    }
                    @include breakpoint(mobile){
                        margin-bottom:2.5rem;
                    }
                }
                &__action-container{
                    float: inherit;
                    margin-right: 0;
                    .cmp-carousel__action-link{
                        @include breakpoint(tab) {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            width: 90%;
                            margin: 0 auto;
                        }
                    }
                }
                .slick-initialized{
                    .slick-list{
                        @media only screen and (min-width: 1025px) {
                             margin-left: 29.6875rem;
                        }
                        @include breakpoint(desktop-small) {
                            margin-left: 25rem;
                        }
                        padding:0;
                        .slick-track{
                            padding-top: 0;
                            .cmp-carousel-slick__item{
                                @include breakpoint(mobile){
                                    margin:0 0.3125rem 0 0;
                                }
                            }
                        }
                    }
                    @include breakpoint(mobile){
                        width:100%;
                    }
                    @include breakpoint(tab){
                        width:100%;
                    }
                    &.slick-dotted{
                        &.slick-slider{
                            margin-bottom:0;
                        }
                    }
                }
            }
            &.carousel-swipe-active{
                .cmp-carousel-slick{
                    .slick-initialized{
                        .slick-list{
                            margin-left: 0;
                            .slick-track{
                                .cmp-carousel-slick__item{
                                    &.cmp-carousel-slick__item--active, &.slick-current{
                                        .teaser{
                                            position: relative;
                                            .cmp-teaser{
                                                @include breakpoint(tab) {
                                                    flex-direction: column;
                                                }
                                                &__content{
                                                    display: block;
                                                    width: 70%;
                                                    padding-top: 6.25rem;
                                                    @include breakpoint(tab) {
                                                        position: inherit;
                                                        width: 100%;
                                                        padding: 0.625rem 0 3.125rem 0;
                                                    }
                                                }
                                                &__pretitle{
                                                    @include breakpoint(tab) {
                                                        margin: 4.0625rem 0 0.625rem 0;
                                                    }
                                                }
                                                h2{
                                                    font-size: 1.375rem;
                                                    line-height: 1.45;
                                                    letter-spacing: 0.0125rem;
                                                    color: $text-medium-black;
                                                }
                                                &__description{
                                                    width: 16.875rem;
                                                }
                                            }
                                            .cmp-img-caption{
                                                display: block;
                                                position: absolute;
                                                left: 30.5vw;
                                                margin-top: 1.25rem;
                                                @include breakpoint(tab) {
                                                    width: 100%;
                                                    top: 50%;
                                                    left: 0;
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                    .teaser{
                                        .cmp-teaser{
                                            display: flex;
                                            flex-direction: row-reverse;
                                            @media only screen and (min-width: 992px)  {
                                                align-items: center;
                                            }
                                            .cmp-teaser__image{
                                                width: 100%;
                                            }
                                            .cmp-teaser__content{
                                                display: none;
                                            }
                                        }
                                        .cmp-img-caption{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        .slick-dots{
                            @include breakpoint(mob-max) {
                                top: inherit;
                            }
                            @media only screen and (min-width: 992px) {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
// carousel slick banner
.carousel-slick-banner{
    .title{
        max-width: 43.75rem;
        margin: 0 auto;
        .cmp-title{
            .cmp-title__text{
                font-family: $playfair-regular;
                font-size: 5.625rem;
                line-height: 1.07;
                letter-spacing: -0.109375rem;
                text-align: center;
                color: $text-dark-grey;
                text-align: center;
                margin: 8.125rem 0 0 0;
                width: 100%;
                @include breakpoint(tab) {
                    font-size: 2.625rem;
                    line-height: 1.14;
                    letter-spacing: -0.075rem;
                    margin: 0;
                }
            }
        }
    }
    .cmp-container{
        .carousel-swipe-scroll{
            padding: 6.25rem 0 6.25rem 6.875rem;
            @include breakpoint(tab) {
                padding: 2.8125rem 0 3.125rem 0;
            }
            @include breakpoint(desktop-small) {
                padding: 4.375rem 0;
            }
            .cmp-carousel-slick {
                display: flex;
                flex-direction: column-reverse;
                .slick-initialized {
                    .slick-list {
                        padding: 0;
                        @include breakpoint(tab) {
                            padding: 0;
                        }
                        .slick-track {
                            padding-top: 0;
                            .cmp-carousel-slick__item {
                                @media only screen and (min-width: 992px) {
                                    margin: 0 1.75rem 0 0;
                                }
                                .teaser {
                                    padding: 0;
                                }
                            }
                        }
                    }
                    @media only screen and (min-width: 992px) {
                        .slick-dots{
                            display: flex !important;
                            bottom: -2.8125rem;
                            li{
                                margin: 0 0.875rem 0 0;
                            }
                        }
                    }
                }
            }
            .carousel-header-info{
                padding-top: 4.375rem;
                @include breakpoint(tab) {
                    display: inherit;
                    flex-direction: column;
                    padding-top: 4.375rem;
                    .cmp-carousel__title{
                        p{
                            padding: 0;
                        }
                    }
                }
                @media only screen and (min-width: 992px)  {
                    h4{
                        position: absolute;
                        font-family: $font-regular-base;
                        font-size: 0.75rem;
                        line-height: 1.33;
                        letter-spacing: 0.125rem;
                        color: $text-dark-grey;
                    }
                    .cmp-carousel__title{
                        padding-top: 1.375rem;
                    }
                }
                @include breakpoint(desktop-small) {
                    .cmp-carousel__action-container{
                        margin-right: 3.75rem;
                    }
                }
            }
        }
    }
}

//Tab with carsousel
.tab-list-style{
    .cmp-tabs{
        .cmp-tabs__tabpanel{
            padding:0;
        }
    }
}
.cmp-tabs__tabpanel{
    padding:0;
}
//Carousel Header Content
.carousel-header-content{    
    @include breakpoint(desktop-small) {
        padding: 5.625rem 0 5.625rem 6.875rem;
    }
    @media screen and (min-width: 1024px) and (max-width: 1380px) {
        padding-left: 36px;
    }
    @include breakpoint(tab) {
        padding:3.125rem 0 3.75rem 2rem;
    }
    @include breakpoint(mobile) {
        padding: 3.125rem 0 3.75rem 1.125rem;
    }
    .cmp-carousel{
        &.cmp-carousel-slick{
            .carousel-header-info{                
                h1,h2,h3,h4,h5,h6{
                    font-size: 0.875rem;
                    line-height: 1rem;
                    letter-spacing: 0.125rem;
                    color: $text-dark-grey;
                    padding-bottom: 0.625rem;
                    @include breakpoint(tab){
                        font-size: 0.75rem;
                    }
                    @include breakpoint(mobile){
                        font-size: 0.75rem;
                    }
                }
                &__action-container {
                    float: none;
                    margin: 0;
                    position: absolute;
                    right: 6.875rem;
                    top: 1.75rem;
                    @include breakpoint(tab) {
                        width: auto;
                        position: absolute;
                        right: 2rem;
                        margin: 0;
                        top: 0;
                        left: unset;
                    }
                    @include breakpoint(mobile) {
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        justify-content: center;
                        top: 52.875rem;
                        left: 0;
                        right: 0;
                        margin: 0;
                    }
                }
            }
        }            
    }        
}

//Carousel Slick
.carousel-slick {
    @extend .carousel-swipe-scroll;
    @extend .carousel-header-content;
    .carousel-header-info {
        position: relative;
        padding: 0 0 5rem 0;
        @include breakpoint(tab) {
            padding: 0 2rem 3.75rem 0;
        }
        @include breakpoint(mobile) {
            padding: 0 1.125rem 3.75rem 0;
        }    
        .cmp-carousel {        
            &__title{
                width: 19.6875rem;            
                margin: 0;
                float: none;
                @include breakpoint(tab) {
                    width: 100%;
                    margin: 0;
                }
                @include breakpoint(mobile) {
                    width: 100%;
                    margin: 0;
                }
                p {
                    @include headingtitle_h6;
                    color: $text-medium-dark-grey;
                    padding: 0 0 1.25rem 0;
                    @include breakpoint(tab) {
                        @include headingtitle_h6;
                        color: $text-medium-dark-grey;
                        padding: 0 0 1.25rem 0;
                    }
                    @include breakpoint(mobile) {
                        @include headingtitle_h6;
                        color: $text-medium-dark-grey;
                        padding: 0 0 1.25rem 0;
                    }
                }
            }
          }
          @include breakpoint(tab){
            flex-direction:column;
          }
          @include breakpoint(mobile){
            flex-direction:column;
          }
    }
    &__title{
        @include headingtitle_h3;
        width: auto;
        margin: 0 3.125rem 4.375rem 0;
        h1,h2,h3,h4,h5,h6{
        margin:0;
        }
        @include breakpoint(tab){
        margin-right: 0;
        width: 100%;
        margin-bottom:1.875rem;
        }
        @include breakpoint(mobile){
        margin-right: 0;
        width: 100%;
        margin-bottom:1.875rem;
        }
    }
    &__description{
        width:100%;
        p{
        @include ann-describtion(1.125rem, $font-regular-base, 1.75rem);
        color:$text-medium-dark-grey;
        width:100%;
        @include breakpoint(tab){
            @include ann-describtion(0.875rem, $font-regular-base, 1.25rem);
        }
        @include breakpoint(mobile){
            @include ann-describtion(0.875rem, $font-regular-base, 1.25rem);
        }
        }
        @include breakpoint(tab){
        margin-bottom:2.5rem;
        }
        @include breakpoint(mobile){
        margin-bottom:2.5rem;
        }
    }
    &__action-container{
        float: inherit;
        margin-right: 0;
        .cmp-carousel__action-link{
        @include breakpoint(tab) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 90%;
            margin: 0 auto;
        }
        }
    }
    .slick-initialized{
        .slick-list{
            padding:0;
            .slick-track{
            padding-top: 0;
            display: flex;
            // .slick-slide{
            //     width: auto!important;
            // }
            .cmp-carousel-slick__item{
                .teaser{
                    &__description {
                        width: 19.6875rem;
                        float: none;            
                        @include breakpoint(tab) {
                            width: 100%;
                        }
                        @include breakpoint(mobile) {
                            width: 100%;
                        }
                        p {
                            @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                            letter-spacing: normal;
                            @include breakpoint(tab) {
                                @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                                letter-spacing: normal;
                            }
                            @include breakpoint(mobile) {
                                @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                                letter-spacing: normal;
                            }
                        }
                    }
                    &__action-container {
                        float: none;
                        margin: 0;
                        position: absolute;
                        right: 6.875rem;
                        top: 0;
                        @include breakpoint(tab) {
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            justify-content: center;
                            left: 0;
                            margin: 0;
                            top: 5rem;
                        }
                        @include breakpoint(mobile) {
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            justify-content: center;
                            top: 39.125rem;
                            left: 0;
                            right: 0;
                            margin: 0;
                        }
                        .cmp-carousel__action-link {
                            position: relative;
                            width:100%;
                            justify-content:center;
                        }
                    }
                }
            
            }
        }
    }
    }
    .slick-dots {
        bottom: 0;
    }
    &.carousel-content-center {
        .cmp-carousel {
            .carousel-header-info {
                text-align: center;
                padding: 4.375rem 0;
                h4{
                    font-size: 0.75rem;
                    margin: 0;
                }
            }
            &__title {
                width: 100%;
                p {
                    @include headingtitle_h5;
                    padding: 0;
                    color: $text-dark-grey;
                    @include breakpoint(tab) {
                        @include headingtitle_h5;
                        padding: 0 0 1.875rem 0;
                        color: $text-dark-grey;
                    }
                    @include breakpoint(mobile) {
                        @include headingtitle_h5;
                        padding: 0 0 1.875rem 0;
                        color: $text-dark-grey;
                    }
                }
            }
            &__description {
                width: 100%;
                p {
                    @include ann-describtion(1.125rem, $font-regular-base, 1.75rem);
                    letter-spacing: normal;
                    width: 41.1875rem;
                    color: $text-medium-dark-grey;
                    margin: 0 auto;
                    @include breakpoint(tab) {
                        @include ann-describtion(1.125rem, $font-regular-base, 1.75rem);
                        letter-spacing: normal;
                        width: 29.8125rem;
                        color: $text-medium-dark-grey;
                        margin: 0 auto;
                    }
                    @include breakpoint(mobile) {
                        @include ann-describtion(1.125rem, $font-regular-base, 1.75rem);
                        letter-spacing: normal;
                        width: 21.1875rem;
                        color: $text-medium-dark-grey;
                        margin: 0 auto;
                    }
                }
            }            
        }
    }
    .cmp-carousel {
        &.cmp-carousel-slick {
            .slick-initialized {
                .slick-list {
                    margin:0;
                    padding: 0 6.875rem;
                    @include breakpoint(desktop-mid){
                        padding:0 2.25rem;
                    }
                    @include breakpoint(tab) {
                        padding: 0 2rem;
                    }
                    @include breakpoint(mobile) {
                        padding: 0 1.125rem;
                    }
                }
            }
        }
    }
    .hide {
        height: 0;
    }
}

.slick-slider-hidden {
    .slick-dots {
        display: none;
    }
}
// left right Arrows style
.slick-prev:before,
.slick-next:before {
    font-family: icomoon;
}
.slick-prev:before {
    content: '\e92c';
}
[dir='rtl'] .slick-prev:before {
    content: '\e92d';
}
.slick-next:before {
    content: '\e92d';
}
[dir='rtl'] .slick-next:before {
    content: '\e92c';
}

.product-card {
    &__link {
        .product-card__img-container {
            @include breakpoint(tab-max) {
                min-height: 216px;
                min-width: 166px;
            }
        }
    }
}