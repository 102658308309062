.utilitynavheader .gbh-utility-nav .gbh-utility-navbar .gbh-nav__findStore {
  .findstore-util-nav {
    &__location-content,
    &__zipcode-content,
    &__input-control,
    &__submit,
    &__location-link,
    &__store,
    &__signStore,
    &__search-que {
      font-family: $lato-regular;
    }
    &__access-utility-container {
      .row {
        margin-left: 0;
        margin-right: 0;
      }
      padding-left: 0;
      padding-right: 0;
    }

    &__search-utility-container {
      border-right: 1px solid $text-medium-dark-grey;
      padding-left: 0;
      padding-right: 0;
      @include breakpoint(desktop-extra-lite-sm) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: 0;
      }
    }

    &__input-container {
      border-bottom: 1px solid $color-white;
      width: 100%;
      padding-top: 0;
      .auto-suggestion__border-bottom {
        border: none;
      }
    }
    &__link-container {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 100px;
      @include breakpoint(desktop-extra-lite-sm) {
        padding-top: 15px;
      }
    }
    &__link-container-text {
      padding-bottom: 105px;
    }
    &__input-control {
      font-size: 26px;
      letter-spacing: 0;
      line-height: 36px;
      background-color: $color-dark-blue;
      padding-bottom: 28px;
      @include breakpoint(desktop-extra-lite-sm) {
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 15px;
      }
    }
    &__submit {
      height: 50px;
      width: 115px;
      color: $color-white;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 24px;
      text-align: center;
      border: 1px solid $color-white;
      border-radius: 1px;
      background-color: $color-dark-blue;
      margin-left: 133px;
      margin-bottom: 25px;
    }
    &__location-link {
      font-family: $helvetica-light;
      font-size: 1pc;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: #cdcdcd;
      text-decoration: underline;
      text-transform: uppercase;
      @include breakpoint(desktop-extra-lite-sm) {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.2px;
      }
    }
    // When location matched start code here
    &__input-container-text {
      padding-top: 20px;
    }
    &__supply-section {
      padding-left: 0;
      padding-right: 0;
    }
    &__store {
      color: $color-white;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 25px;
    }
    &__signStore {
      color: $color-white;
      font-size: 26px;
      letter-spacing: 0;
      line-height: 36px;
    }
    &__address-container {
      p,
      p:first-child,
      .checkbox span:last-child {
        font-family: $lato-regular;
      }
      p:first-child {
        display: none;
      }
      p {
        color: $color-white;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 0;
      }
      p:nth-of-type(2) {
        color: $color-white;
        font-size: 16px;
        letter-spacing: 1.7px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .checkbox {
        margin-bottom: 21px;
        margin-top: 30px;
        input ~ span {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
        }
        input:checked ~ span {
          color: $color-white;
        }
        span:last-child {
          color: $color-white;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
        input:checked ~ .checkbox__marker {
          background: $color-medium-blue;
        }

        input ~ .checkbox__border .checkbox__marker {
          .checkbox__marker-icon {
            display: none;
          }
        }
        input:checked ~ .checkbox__border .checkbox__marker {
          border: none;
          background: $color-medium-blue-cap;
          color: $color-white;
          border: 1px solid $color-dark-gray;
          border-radius: 5px;
          .checkbox__marker-icon {
            display: block;
          }
        }
        .checkbox__border {
          margin-top: 0;
        }
      }
    }
    &__search-que {
      color: $color-white;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      text-align: center;
    }
    &__mobile-container {
      display: none;
      @include breakpoint(desktop-extra-lite-sm) {
        background: $color-dark-blue !important;
        padding: 85px 32px 30px;
        display: block;
      }
      @include breakpoint(mobile) {
        padding: 48px 18px 30px;
      }
      .findstore-util-nav__close {
        @include breakpoint(desktop-extra-lite-sm) {
          padding-top: 0;
          margin-bottom: 44px;
        }
        @include breakpoint(mobile) {
          margin-bottom: 37px;
        }
      }
    }
    &__menu {
      @include breakpoint(desktop-extra-lite-sm) {
        padding-bottom: 0;
      }
    }
    .auto-suggestion__input-container {
      .auto-suggestion__input {
        @include ann-describtion(1.625rem, $lato-regular, 2.25rem);
        letter-spacing: normal;
        background-color: $color-dark-blue;
        padding-bottom: 28px;
        color: $white;

        @include breakpoint(desktop-extra-lite-sm) {
          font-size: 18px;
          line-height: 28px;
          padding-bottom: 15px;
        }
        @include breakpoint(mobile) {
          width: 100%;
        }
      }
    }
    .auto-suggestion__border-bottom {
      @include breakpoint(desktop-extra-lite-sm) {
        border: none;
      }
    }
    .findstore-util-nav__show-container {
      .findstore-util-nav__search-utility-container {
        .findstore-util-nav__location-content * {
          color: $color-white;
          font-size: 26px;
          font-family: $lato-regular;
          letter-spacing: 0;
          line-height: 36px;
        }
        .findstore-util-nav__zipcode-content * {
          color: $color-white;
          font-size: 16px;
          font-family: $lato-regular;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
    }
    .kf-react-container {
      @include breakpoint(desktop-extra-lite-sm) {
        padding: 0 36px !important;
      }
      @include breakpoint(mobile) {
        padding: 0 18px !important;
      }
    }
    .findstore-util-nav__input-container {
      @include breakpoint(mobile) {
        align-items: end;
        flex-direction: row !important;
      }
    }
    .auto-suggestion__input {
      background-color: $color-dark-blue;
      &::placeholder {
        color: $white;
      }
    }
  }
  .findstore-util-nav__location-content {
    @include breakpoint(mobile) {
      font-size: 26px;
      letter-spacing: 0;
      line-height: 36px;
      text-align: left;
      padding: 39px 50px 20px 0;
    }
    font-family: $lato-regular;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
    padding: 10px 0 0;
    @include breakpoint(desktop-extra-lite-sm) {
      padding: 0;
      text-align: left;
    }
  }
  .findstore-util-nav__zipcode-content {
    font-family: $lato-regular;
    letter-spacing: 0;
    color: $white;
    font-size: 1pc;
    line-height: 1.5;
    padding: 20px 0 0;
    @include breakpoint(mobile) {
      padding-top: 0;
    }
    @include breakpoint(desktop-extra-lite-sm) {
      text-align: left;
    }
  }
  .findstore-util-nav__content {
    @include breakpoint(desktop-small) {
      padding: 72px 120px 79px 110px;
    }
    background-color: $color-dark-blue;
    z-index: 2;
    position: relative;
    .findstore-util-nav__loc-search-container {
      @include breakpoint(desktop-small) {
        padding: 25px 50px 25px 119px;
      }
      @media (min-width: 1201px) and (max-width: 1280px) {
        padding: 25px 50px;
      }
      .findstore-util-nav__submit {
        margin-left: 0;
        color: $white;
        text-transform: uppercase;
        font-family: $lato-bold;
        font-size: 9pt;
        line-height: 24px;
        letter-spacing: 2.5px;
        opacity: 1;
        text-decoration: none;
      }
      .findstore-util-nav {
        &__link-container {
          display: flex;
          justify-content: space-between;
          .findstore-util-nav__location-link-container {
            span {
              height: 20px;
              width: 103px;
              color: $white;
              font-family: $lato-regular;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 20px;
              text-transform: capitalize;
            }
          }
        }
        &__view-link-container {
          .findstore-util-nav__view-store-link {
            height: 20px;
            color: $white;
            font-family: $lato-regular;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            text-transform: capitalize;
            position: relative;
            align-self: end;
            top: 0 !important;
            right: 0 !important;
            width: 100%;
          }
        }
      }
    }
  }
}
.gbh-utility-navbar {
  .findstore-util-nav {
    &__view-link-container .findstore-util-nav__view-store-link,
    &__address-container .findstore-util-nav__view-store-detail {
      font-family: $lato-regular;
    }
    &__view-link-container {
      .findstore-util-nav__view-store-link {
        color: $color-white;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 2.5px;
        opacity: 1;
        @include breakpoint(desktop-extra-lite-sm) {
          margin-bottom: 15px;
        }
      }
      &__input-control {
        opacity: 1;
        width: 100%;
        &::placeholder {
          color: $color-white;
        }
      }
    }
    &__container {
      height: 100vh;
      @include breakpoint(desktop-extra-lite-sm) {
        overflow-y: auto;
      }
    }
    &__content {
      @include breakpoint(desktop-extra-lite-sm) {
        height: 100vh;
        padding-top: 30px;
      }
      @include breakpoint(desktop-extra-lite-sm) {
        padding: 0 0 50px;
      }
      @include breakpoint(mobile) {
        padding: 0 0 25px;
      }
    }
    &__submit {
      width: 115px;
      margin-left: 0;
      height: 50px;
      font-family: $helvetica-neue-medium;
      font-size: 16px;
      color: $color-white;
      border-radius: 1px;
      border: 1px solid $color-white;
    }
    &__location-link {
      letter-spacing: 0;
      line-height: 20px;
      font-size: 14px;
      color: $color-white;
      text-transform: lowercase;
    }
  }
}
.gbh-utility-nav {
  .gbh-utility-navbar {
    .findstore-util-nav {
      &__location-content {
        font-family: $lato-regular;
        font-size: 26px;
        letter-spacing: 0;
        line-height: 36px;
        @include breakpoint(desktop-extra-lite-sm) {
          font-size: 22px;
          line-height: 32px;
          padding: 0 0 10px;
        }
      }
      &__zipcode-content {
        font-family: $lato-regular;
        letter-spacing: 0;
        color: $white;
      }
      &__location-link {
        font-family: $lato-regular;
        text-transform: capitalize;
      }
      &__view-store-link {
        font-family: $lato-regular;
      }
    }
    &__address-container {
      .findstore-util-nav__view-store-detail {
        color: $color-white;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        padding-top: 25px;
      }
    }
  }
  .findstore-util-nav {
    &__address-container {
      padding-top: 0 !important;
      .findstore-util-nav__view-store-detail {
        color: $color-white;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        padding-top: 25px;
      }
    }
  }
}
.findstore-util-nav .kf-react-container {
  padding: 0 !important;
}

.remove-scroll {
  overflow-y: hidden !important;
}
