.breadcrumb + .hero-banner-full-bleed-video{
    margin-top:-89px;
    @include breakpoint(tab-max){
        margin-top:-69px;
    }
}
.hero-banner-full-bleed-video{
    padding:0;
    .cmp-teaser{
        position: relative;
        height:100%;
        z-index:0;
        @include breakpoint(mobile){
            background:$color-light-grey;
        }
        &__image{
            display: flex;
            max-height:1000px;
            position: relative;
            @include breakpoint(tab){
                min-height:464px;
            }
            @include breakpoint(mobile){
                min-height:535px;
                max-height:535px;
            }
            .poster-img{
                position: initial;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display:block;
                img{
                    width:auto;
                    height:100%;
                    object-fit:cover;
                }
            }
            .gbh-play-video{
                right:85px;
                bottom:50px;
                width:18px;
                height:20px;
                position: absolute;
                z-index:999;
                transform:translate(-50%,-50%);
                margin-right:15px;
                transition:0.4s ease-in-out;
                @include breakpoint(tab){
                    right:53px;
                    bottom:30px;
                }
                @include breakpoint(mobile){
                    right:40px;
                    bottom:50px;
                }
                &.active{
                    .playinside{
                        &::after{
                            content:"\e933";
                            font-weight:normal;
                        }
                    }
                    +.poster-img {
                        display:none;
                    }
                }
                .playinside{
                    display: block;
                    background: transparent;
                    border: none;
                    color:$white;
                    text-align: center;
                    width:100%;
                    height:100%;
                    @include breakpoint (mobile) {
                    left: 235px;
                    top: 40px;
                    }
                    &::before {
                        display: none;
                    }
                    &::after{
                        content:"\e93b";
                        padding:0;
                        font-size:18px;
                        font-family: $icon-moon;
                        top:-3px;
                        position: relative;
                        font-weight:600;
                        @include breakpoint (tab-max) {
                            position: absolute;
                        }
                    }
                }
            }
            .hero-mute-icon{
                color:$white;
                position: absolute;
                bottom: 11px !important;
                right:55px !important;
                font-size: 26px;
                width:30px;
                height:26px;
                cursor: pointer;
                z-index:8   ;
                transform: translate(-50%,-50%);
                @include breakpoint(tab){
                    right:40px !important;
                    bottom:32px !important;
                }
                @include breakpoint(mobile){
                    right:10px !important;
                    bottom:10px !important;
                }
                .icon-Mute{
                    &:before{
                        content: "\e93a";
                        font-family:$icon-moon;
                    }
                }
                .icon-Un-Mute{
                    &:before{
                        content:"\e939";
                        font-family:$icon-moon;
                    }
                }
            }
            .vidio_popup_close_button{
                display:none;
            }
            .hero-video{
                width:100%;
                height:100%;
                object-fit:cover;
                max-height:inherit;
                display:flex;
                position:static;
                min-height:inherit;
                @include breakpoint(tab-max){
                    display: block;
                    height: 100%;
                    position: relative;
                    object-position: top;
                }
            }
        }
        &__content{
            width:100%;
            position: absolute;
            z-index:2;
            bottom:0;
            padding:0 2.603% 105px;
            color: $white;
            background-color: transparent;
            @include breakpoint(desktop-large){
                max-width:1920px;
                left:50%;
                margin-left:-960px;
                padding:0 50px;
            }
            @include breakpoint(tab-max){
                margin-left:0;     
                left:0;
                padding-bottom:50px;
            }
            @include breakpoint(tab){  
                padding-right:32px;
                padding-left:32px;
            }
            @include breakpoint(mobile){
                padding:30px 18px 40px;
                position: static;
                background:$color-light-grey;
                width: 100%
            }
        }
        &__title{
            h1,h2,h3,h4,h5,h6,p{
                font-size:3.375rem;
                line-height:3.875rem;
                letter-spacing:-0.0625rem;
                font-family: $playfair-regular;
                font-weight: normal;
                padding-right:1.875rem;
                color: $white;
                @include breakpoint(tab-max){
                    padding-right:0;
                    font-size: 1.75rem;
                    line-height: 2.125rem;
                    letter-spacing: -0.0731rem;
                }
                @include breakpoint(mobile){
                    color: $white;
                    line-height: 2.125rem;
                }
            }
            &-link{
                text-decoration: none;
                cursor: auto;
                color: $white;
            }
            @include breakpoint(mobile){
                position:absolute;
                top:384px;
                padding-bottom:50px;
                width:215px;
                z-index:2;
            }
        }
        &__description{
            @include ann-describtion(1rem, $lato-regular, 1.5rem);
            margin:30px 0 20px;
            padding-right:7.5rem;
            @include breakpoint(tab-max){
                padding-right:0;
            }
            @include breakpoint(tab){
                margin:20px 0;
            }
            @include breakpoint(mobile){
                margin-top:0;
                margin-bottom:20px;
                width: 19.375rem;
            }
            p{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0.44px;
                color: $white;
                @include breakpoint(mobile){
                    background:$color-light-grey;
                    color: $text-dark-grey;
                }
            }
        }
        &__action-link{
            padding-bottom:7px;
            line-height:.75rem;
            color:$white!important;
            @include breakpoint(desktop-small){
                color:$white;
                border-color:$cool-light-grey !important;
            }
            @include breakpoint(mobile){
                color:$color-light-blue!important;
                border-color:$color-light-blue;
            }
            &:nth-child(even){
                @include breakpoint(tab-min){
                    margin-left:19px;
                }
                @include breakpoint(mobile){
                    margin-top:.9375rem;
                }
            }
            &:last-child{
                position:absolute;
                bottom:4.125rem;
                border:none;
                padding:0;
                margin:0 auto;
                right:0;
                left:0;
                width:24px;
                height:14px;
                background:transparent;
                box-shadow:none;
                color:inherit;
                @include breakpoint(tab-max){
                    bottom:2.875rem;
                }
                @include breakpoint(mobile){            
                    bottom: 0;
                    top: 474px;
                    color:$white!important;
                }
                &:before{
                    content:"\e92a";
                    font-family:$icon-moon;
                    width:24px;
                    height:14px;
                    font-size:12px;
                    font-weight:bolder;
                    transform: translatey(0px);
	                animation: float 6s ease-in-out infinite;
                    @include breakpoint(tab-max){
                        font-size:10px;
                    }
                }
            }
        }
        &__title, &__description{
            max-width:33.75rem;
            @include breakpoint(tab){
                max-width:340px;
            }
            @include breakpoint(mobile){
                max-width:inherit;
            }
        }
        &__action-container{
            @include breakpoint(mobile){
                display:inline-flex;
                flex-direction:column;
                align-items: flex-start;
            }
        }
    }
    &.content-top-left{
        .cmp-teaser{
            &__content{
                top:6.25rem;
                .cmp-teaser__title{
                    @include breakpoint(mobile){
                        position: absolute;
                        top: 50px;
                    }
                }
                @include breakpoint(tab-max){
                    top:50px;
                    left:0;
                }
            }
        }
    }
    &.content-bottom-left{
        .cmp-teaser{
            &__content{
                @include breakpoint(desktop-small){
                    padding-bottom:105px;
                }
                @include breakpoint(tab){
                    padding-bottom:80px;
                }
                @include breakpoint(tab-max){
                    bottom:0;
                    left:0;
                }
                .cmp-teaser__title {
                    @include breakpoint (mobile) {
                        position: absolute;
                        top: 333px;
                    }
                }
            }
            &__action-link:last-child{
                @include breakpoint(tab){
                    bottom:26px;
                }
            }
        }
    }
    &.content-dead-center{
        .cmp-teaser{
           &__content{
                width:100%;
                height:100%;
                display:flex;
                justify-content: center;
                align-items: center;
                flex-direction:column;
                text-align: center;
                @include breakpoint(tab){
                    padding:0;
                    max-width:inherit;
                }
                @include breakpoint(mobile){
                    top:216px;
                    text-align: center;
                }
           }
           &__title{
                *{
                    @include breakpoint(desktop-small){
                        padding:0 15px;
                    }
                }
                @include breakpoint(mobile){
                    top:13.5rem;
                    padding:0;
                    text-align: center;
                }
           }
           &__description{
                @include breakpoint(desktop-small){
                    padding:0 3.75rem;
                }
           }
        }
    }
    &.content-left-center{
        .cmp-teaser{
            &__content{
                display:flex;
                flex-direction: column;
                justify-content:center;
                height:100%;
                .cmp-teaser__title {
                    @include breakpoint (mobile) {
                    position: absolute;
                    height: 100%;
                    top: 216px;                    }
                }
                @include breakpoint(tab-min){
                    padding-bottom:0;
                }
            }
            &__title{
                @include breakpoint(mobile){
                    padding-bottom:0;
                }
            }
        }
    }
    &.content-right-center{
        .cmp-teaser{
            &__content{
                display:flex;
                flex-direction: column;
                justify-content:center;
                height:100%;
                align-items:flex-end;
                max-width:inherit;
                text-align: right;
                @include breakpoint(desktop-large){
                    max-width:1920px;
                    right:50%;
                    margin-right:-960px;
                    left:unset;
                    margin-left:auto;
                }
                @include breakpoint(tab-min){
                    padding-bottom:0;
                }
                @include breakpoint(tab){
                    box-sizing:border-box;
                }
                @include breakpoint(mobile){
                    align-items:center;
                    text-align: center;
                }
            }
            &__title{
                *{
                    @include breakpoint(desktop-small){
                        padding-right:0;
                        padding-left:30px;
                    }
                }
                @include breakpoint(mobile){
                    top:216px;
                    padding-bottom:0;
                    place-self: flex-end;
                    text-align: right;
                }
            }
            &__description{
                @include breakpoint(desktop-small){
                    padding-right:0;
                    max-width:420px;
                }
            }
        }
    }
    &.play-icon-dark, &.play-icon-color-dark{
        .gbh-play-video{
            &.active{
                .playinside{
                    &::after{
                        color:$text-medium-dark-grey;
                    }
                }
            }
            .playinside{
                &::after{
                    color:$text-medium-dark-grey;
                }
            }
        }
        .hero-mute-icon{
            .icon-Mute{
                &:before{
                    color:$text-medium-dark-grey;
                }
            }
            .icon-Un-Mute{
                &:before{
                    color:$text-medium-dark-grey;
                }
            }
        }
    }
    &.fc-stone{
        .cmp-teaser{
            &__action-link{
                color:$color-light-blue;
                border-color:$color-light-blue;
                &:last-child{
                    color:$text-medium-dark-grey;
                }
            }
        }
    }
    &.fc-white{
        .cmp-teaser{
            &__action-link{
                @include breakpoint(mobile){
                    color:$color-light-blue;
                    border-color:$color-light-blue;
                    &:last-child{
                        &::before{
                            color:$white;
                        }
                    } 
                } 
            }
            &__description p{
                @include breakpoint(mobile){
                    color: $text-dark-grey!important;
                }
            }
        }
    }
    &.hero-banner-horizontal-video{
        @include breakpoint(mobile){
            .cmp-teaser{
                &__image{
                    @include breakpoint (desktop-small) {
                    min-height: 1000px;
                    }
                    .hero-video{
                        min-height: 211px;
                        @include breakpoint (mobile) {
                            min-height: 535px !important;
                        }
                    }
                }
                &__content{
                    position: static;
                    top: 0;
                    right: 0;
                    left: 0;
                    text-align: left;
                    color:$text-dark-grey;
                    max-width:100%;
                }
                &__title{
                    @include breakpoint (mobile) {
                        position: absolute;
                    }
                }
                &__description{
                    padding-top:20px;
                }
            }
            &.content-dead-center {
                .cmp-teaser {
                &__content, &__title, &__description > p, &__container {
                    text-align: center;
                    margin: 0 auto;
                }
            } 
        }
            &.content-right-center{
                .cmp-teaser{
                    &__content, &__title{
                        margin:0 auto;
                        text-align:right;
                        align-items: flex-end;
                    }
                }
            }
            &.fc-white{
                .cmp-teaser{
                    &__title *{
                        color:$text-dark-grey;
                    }
                    &__action-link{
                        color:$color-light-blue;
                        border-color:$color-light-blue;
                        &:last-child{
                            &::before{
                                color:$white;
                            }
                        }   
                    }
                }
            }
        }
    }
}
.vidio_popup_close_button{
    display:none;
}

.teaser .cmp-teaser__action-container .cmp-teaser__action-link{
    font-size: .875rem;
    font-family: $lato-regular;
    line-height: 1rem;
    letter-spacing: .145625rem;
    white-space: nowrap;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 1.375rem 1.25rem;
    position: relative;
    -webkit-transition: .6s ease-in-out;
    transition: .6s ease-in-out;
    background: none;
    padding: 0;
    padding-bottom: 7px;
    @include ann-describtion(11px, $lato-regular, 16px);
        letter-spacing: .145625rem;
        text-transform: uppercase;
    }
    .teaser.fc-white {
        .cmp-teaser__action-container {
            .cmp-teaser__action-link {
                border-color: $cool-light-grey-caps;
            }
        }
    }

.hero-banner-full-bleed-video.teaser .cmp-teaser__action-container .cmp-teaser__action-link{
    letter-spacing: 1.8px;
    border-color: $white;
    @include breakpoint(mobile){
        color: $color-light-blue!important;
        &:last-child{
            color: $white!important;
        }  
    }
}
.ann-sacks-category-landing-banner, 
.hero-banner-full-bleed-video, 
.global-hero-banner, 
.hero-banner-collection {
&.teaser {
    .cmp-teaser__action-container .cmp-teaser__action-link {
    &:last-child {
    position: absolute;
    bottom: 4.125rem;
    border: none;
    padding: 0;
    margin: 0 auto;
    right: 0;
    left: 0;
    width: 24px;
    height: 14px;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: inherit;
    }
    }
}
}