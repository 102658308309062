.summaryWrapper {
    &__top-block {
        @include breakpoint(tab) {
            padding: 0;
        }
        @include breakpoint(mobile) {
            padding: 0;
        }
    }
}
.trimWrapper{
    width:100%;
    border: 1px solid $cool-dark-grey;
    padding: 25px 30px;
    margin: 10px 0 40px 0px;
    overflow: auto;
    @include breakpoint(tab-max){
        border: 0;
        padding: 0;
    }
}
.header{
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 25px;
}

.inputWrapper {
    display: flex;
    justify-content: space-between;
    @include annsacks-desk(column-gap, 37, 81);
    .inputBox {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 148px;
        .inputBoxLabel {
            color: $text-medium-dark-grey;
            font-family: $lato-regular;
            font-size: 13px;
            letter-spacing: 0.2px;
            line-height: 20px;
        }
        .labelLF {
            position: absolute;
            right: 10px;
            top: 55px;
            height: 30px;
            width: 30px;
            background-color: $white;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 12px;
            letter-spacing: 2px;
            line-height: 24px;
        }

        input, select {
            height: 60px;
            margin-top: 15px;
            border-radius: 3px;
            border: 1px solid $input-border-color;
            padding: 10px 20px;
            width: 100%;
            font-family:$lato-regular;
            font-size:18px;
            line-height:24px;
            letter-spacing:0.5px;
            color:$text-light-grey;
        }
        select {
            color: $text-medium-dark-grey;
            font-family: $lato-regular;
            font-size: 16px;
            letter-spacing: 0.44px;
            line-height: 24px;
            outline: none;
            @include breakpoint(mobile) {
                color: $text-light-grey-cap;
                font-size: 18px;
                letter-spacing: 0.5px;
            }
        }
        select:active, select:focus, select:focus-visible {
            outline: none !important;
        }
    }
}

.trimCalculatorTooltipIcon {
    cursor: pointer;
    margin-left: 5px;
    width: 16px;
    height: 17px;
}

.totalLFWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 37px 0 25px;
    border-bottom: 1px solid $cool-dark-grey;
    margin-bottom: 20px;
    .labelText {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
    }
    .valText {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: right;
    }
    .text{
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: right;
    }
    
}
.totalBox{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .leftBox {
      display: flex;
      flex-direction: column;
      span{
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
      label{
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
      }
      .trimCalculatorTooltipIcon {
        cursor: pointer;
        margin-left: 5px;
    }
  }
  .rightBox{
      .finalToal {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 36px;
        text-align: right;
      }
      .valText {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: right;
      }
        .text {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: right;
      }
  }       
}

@include breakpoint(desktop-extra-sm){
    .summaryWrapper {
        float: none;
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .summaryWrapper-left{
        display: none;
    }
}

@include breakpoint(mobile) {

    .summaryWrapper {
        float: none;
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
    
    .summaryWrapper-left{
        display: none;
    }
    
    .inputWrapper {
        .inputBox {
            .labelLF {
                right: 18px;
            }
        }
    }
}
@include breakpoint(ipad-landscape) {
    .summaryWrapper {
        float: none;
        width: 100%;
        margin: 0px;
        padding: 0px;
    }    
    .summaryWrapper-left{
        display: none;
    }
}
.cart-landing-recalculate .trimWrapper {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0 0 40px 0;
}
.cart-landing-recalculate .header {
    margin-bottom: 40px;
}
.cart-landing-recalculate .inputWrapper .inputBox {
    width: 190px;
}
.cart-landing-recalculate .inputWrapper .inputBox input, .cart-landing-recalculate .inputWrapper .inputBox select{
    border: 1px solid $dark-gray;
    border-radius: 4px;
}
.cart-landing-recalculate .totalLFWrapper {
    padding: 40px 0 25.72px;
    border-bottom: 1px solid $cool-dark-grey-cap;
    margin-bottom: 22.78px;
}
.cart-landing-recalculate .totalBox {
    margin-bottom: 22px;
}
.cart-landing-recalculate .totalBox:nth-child(2) {
    margin-bottom: 27.12px;
}
.cart-landing-recalculate .totalBox:nth-child(3) {
    margin-bottom: 0;
}
.trim-calculator {
    &__list-tooltip-wrapper {
		position: absolute;
		z-index: 3;
		bottom: 30px;
        width: max-content;
        left: 20px;
        .Tooltip_tooltipWrapper__JMsKi{
            .tooltip{
                &__container{
                    width: 100%;
                }
            }
        }
	}

	&__list-tooltip--show {
		display: block;
		position: relative;
	}

	&__list-tooltip--hide {
		display: none;
	}
}
.summaryWrapperDiscountinued {
    height: 546px;
    @include breakpoint(tab) {
        height: 100%;
        padding-bottom: 40px;
    }
    @include breakpoint(mobile) {
        height: 100%;
        padding-bottom: 7px;
    }
}

.trim-calculator_main-block{
    .inputWrapper{
        .select-dropdown{
            margin-top: 15px;
            &__display{
                display: block;
                font-size: 18px;
                font-family: $lato-regular;
                letter-spacing: 0.5px;
                line-height: 24px;
                color: $text-light-grey;
            }
            &__value{
                display: unset;
                font-size: 18px;
                font-family: $lato-regular;
                letter-spacing: 0.5px;
                line-height: 24px;
                color: $text-light-grey;
            }
            .select-dropdown{
                &__placeholder{
                    display: none;
                }
                &__value{
                    bottom: 0;
                }
                &__list-item{
                    padding: 12px 20px;
                    &:hover{
                        background-color: $cool-light-grey;
                    }
                    &.selected-item{
                        background-color:$color-light-blue;
                        .select-dropdown{
                            &__list-value{
                                color: $color-white;
                            }
                        }
                    }
                    .select-dropdown{
                        &__list-value{
                            display: unset;
                            font-size: 14px;
                            color: $text-medium-dark-grey;
                            font-family: $lato-regular;
                            &::after{
                                content: "%";
                                margin-left: 1px;
                            }
                        }
                    }
                }
                &__field{
                    border-color:$cool-dark-grey;
                    .select-dropdown{
                        &__display{
                            &::after{
                                content: "%";
                                margin-left: 1px;
                            }
                        }
                    }
                } 
            }
            &.dropdown--open{
                .select-dropdown{
                    &__field{
                        border-color: $text-dark-grey;
                        .select-dropdown{
                            &__display{
                                color: $text-dark-grey;
                            }
                        }
                    }
                    &__value{
                        color: $text-dark-grey;
                    }
                }
            }
        }
    }
}