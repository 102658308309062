.view-all-stores {
  padding-top: 1px;
  &__container {
    max-width: 86.25rem;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 90px;
    @include breakpoint(tab-max) {
      margin-top: 35px;
    }
  }

  &__countries-list {
    padding-bottom: 40px;
  }

  &__sticky-nav-wrap {
    position: relative;
    z-index: 1;
  }

  &__sticky-container {
    position: relative;
    z-index: 0;
    @include breakpoint(desktop-extra-lite-sm){
      z-index: auto;
    }
  }

  &__data-container {
    .plus .line.line-2 {
      transform: translateX(-10px) !important;
    }
    padding: 0 0 35px !important;
    @include breakpoint(ipad-landscape){
      padding: 0 0 60px !important;
    }    
    @include breakpoint(tab){
      padding: 0 0 60px !important;
    }
    @include breakpoint(mobile){
      padding: 0 !important;
    }
  }

  &__sticky-nav {
    background: $color-white;
    transition: all 0.3s ease;
    padding-right: 15px;
    padding-left: 15px;

    &--shadow {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 3px 0;
    background-color: $color-white;
    section {
      display: flex;
    }
    span {
      font-family: $lato-regular;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
      color: $text-dark-grey;
    }
  }
  &__filter-toggle-icon {
    width: 21px;
    height: 14px;
    margin-right: 10px;
  }

  &__filter {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    .filter__chips {
      border-bottom: 1px solid $color-dark-gray;
    }

    .product-list__filter--sticky {
      margin-top: 40px;
    }

    &--hide {
      opacity: 0;
    }

    &--sticky {
      position: sticky;
      top: 80px;
    }
    .filter {
      .Collapsible {
        &__trigger {
          color: $text-dark-grey !important;
          font-family: $lato-regular !important;
          font-size: 9pt !important;
          letter-spacing: 2px !important;
          line-height: 16px !important;
        }
        &__contentInner {
          .checkbox {
            padding-bottom: 10px;
            input:checked ~ span {
              color: $text-dark-grey !important;
              font-size: 14px !important;
              font-family: $lato-regular !important;
              border-radius: 0;
            }
            .checkbox_default {
              color: $text-medium-dark-grey !important;
            }
          }
        }
      }
      .filter__chips-tag {
        display: inline-block;
      }
      .filter__chips-clear-all {
        text-decoration: underline;
      }
    }
  }

  .filter {
    .Collapsible {
      border-bottom: 1px solid $color-dark-gray;
    }
    @include breakpoint(desktop-extra-sm) {
      height: max-content;
      margin-bottom: 30px;
    }
    &__mobile-header {
      @media (min-width: 275px) and (max-width: 1024px) {
        display: flex;
      }
    }
  }

  &__tile-col {
    transition: all 0.5s ease-in-out;
    margin-left: 60px;

    &--full-width {
      margin-left: 60px;
      @include breakpoint(desktop-extra-sm) {
        margin-left: 0;
      }
      @include breakpoint(desktop-extra-lite-lg) {
        margin-left: 30px;
      }
    }
  }

  &__state-name {
    font-size: 42px;
    font-family: $playfair-regular;
    color: $text-dark-grey;
    line-height: 68px;
    letter-spacing: -1.17px;
  }

  &__state-list {
    margin-bottom: 80px;
    @media (min-width: 1600px) {
      margin-bottom: 75px;
    }
    .collapsible-accordion {
      .Collapsible__trigger {
        color: $text-dark-grey !important;
        font-family: $playfair-regular !important;
        font-size: 28px !important;
        letter-spacing: -1.17px !important;
        line-height: 34px !important;
      }
      .collapsible__trigger--open {
        display: none !important;
        @media (min-width: 275px) and (max-width: 1200px) {
          display: block !important;
        }
      }
    }

    .collapsible-accordion .Collapsible__contentInner {
      padding-bottom: 0;
      @media (min-width: 1600px) {
        .row {
          margin: 0;
          padding: 0;
        }
        .col-12 {
          padding-left: 0;
          padding-right: 60px;
          &:last-child {
            padding: 0;
          }
        }
      }
    }

    span,
    .view-all-stores__store-description {
      display: block;
      margin-right: 40px;
      @media (min-width: 1600px) {
        margin-right: 0;
      }
    }
    .checkbox__border {
      margin-right: 10px;
    }
    &:last-child {
      .collapsible-accordion {
        .Collapsible__contentInner {
          padding-bottom: 0;
          .view-all-stores__set-as-my-store .checkbox {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  &__store-name {
    @include text-style(22px, 32px, 0);
    font-family: $lato-regular;
    color: $text-medium-dark-grey;
    margin: 40px 0 20px 0;
    @media (min-width: 1600px) {
      margin: 40px 0 30px 0;
    }
    @include breakpoint(tab-max){
      @include text-style(1.125rem, 1.75rem, 0);
    }
  }

  &__store-description {
    font-size: 18px;
    line-height: 28px;
    margin: 0 45px 15px 0;
    letter-spacing: 0;
    font-family: $lato-regular;
    text-decoration: underline;
    color: $text-medium-dark-grey;
    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
      color: $color-dark-gray;
      text-decoration: underline;
    }
    @media (min-width: 275px) and (max-width: 1023px) {
      font-size: 16px;
    }
  }
  &__store-address-primary,
  &__store-address-secondary,
  &__store-address-zone,
  &__store-mobile a,
  &__store-email {
    font-size: 16px;
    line-height: 24px;
    color: $text-medium-dark-grey;
    margin-bottom: 10px;
    font-family: $lato-regular;
    letter-spacing: normal;
  }
  &__store-mobile {
    cursor: pointer;
    margin-bottom: 20px;
    @include breakpoint(desktop-extra-sm) {
      font-size: 14px;
    }
  }
  &__store-address-primary,
  &__store-address-secondary {
    margin-bottom: 0;
  }
  &__store-address-primary,
  &__store-address-zone,
  &__store-mobile {
    @media (min-width: 275px) and (max-width: 1023px) {
      font-size: 14px;
    }
  }

  &__store-email {
    margin-bottom: 20px;
  }

  &__set-as-my-store {
    .checkbox {
      margin-bottom: 0 !important;
      align-items: center !important;
      input ~ span {
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
      }
      &__border {
        border: 1px solid $text-medium-dark-grey;
        border-radius: 0 !important;
      }
      input:checked ~ span .checkbox__marker {
        border-radius: 0 !important;
      }
      .checkbox_default {
        color: $text-medium-dark-grey !important;
      }
    }
  }

  .sticky {
    .view-all-stores__countries-list {
      padding-top: 40px;
    }
  }

  #viewAllStore {
    border-bottom: none;
    @include breakpoint(desktop-extra-lite-lg){
      border-bottom: 1px solid $cool-dark-grey;
    }
  }
  .filter__footer {
    @include breakpoint(tab-max){
      bottom: 100px;
    }
  }
  .filter__apply {
    display: none;
    @include breakpoint(tab-max){
      display: block;
    }
  }

  &__filter {
    .filter {
      .Collapsible {
        .plus {
          display: block;
          width: 8.49px;
          height: 8.49px;
          .line.line-1 {
            width: 100%;
            height: 1px;
            transform: translateY(4.4px);
          }
          .line.line-2 {
            width: 1px;
            height: 100%;
            -webkit-transform: translateX(-10px);
            transform: translateX(3.8px);
          }
          .line {
            background: $charcoal;
            transition: all 0.5s ease;
          }
        }
      }
    }
  }
  &__filter-toggle {
    background: 0;
    border: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .view-all-stores {
    &__state-list {
      span,
      .view-all-stores__store-description {
        margin-right: 30px;
        word-break: break-word;
      }
    }
  }
}

@include breakpoint(desktop-extra-lite-lg){
  .view-all-stores {
    &__state-list {
      margin-bottom: 0px;
      &:first-child{
        .Collapsible{
          border-top: 1px solid $cool-dark-grey;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .view-all-stores {
    &__data-container {
      padding-bottom: 61px;
    }
    &__state-list {
      margin-bottom: 0px;
      &:first-child{
        .Collapsible{
          border-top: 1px solid $cool-dark-grey;
        }
      }
    }
    &__filter-toggle {
      display: flex;
      align-items: center;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 1.125rem;
      line-height: 1.33;
      letter-spacing: -0.45px;
      color: $color-charcoal;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      outline: none;
      &-icon {
        width: 21px;
        height: 30px;
        margin-right: 10px;
        margin-left: 0px;
      }
    }
  }
}

@media only screen and (max-width: 860px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 1024px) {
  .view-all-stores {
    #viewAllStore {
      border-bottom: 1px solid $cool-dark-grey;
      &.collapsible--open {
        padding-bottom: 40px;
      }
    }
    &__state-list {
      .collapsible-accordion .collapsible__trigger--open {
        display: block;
      }
      &:first-child {
        .Collapsible {
          border-bottom: 1px solid $cool-dark-grey;
        }
      }
    }
    &__countries-list {
      padding-bottom: 0;
    }
    &__data-container {
      padding-bottom: 60px;
    }
    &__controls {
      padding: 30px 0;
      height: auto;
    }
    &__breadcrumb {
      margin-top: 40px;
      margin-bottom: 21px;
    }
    &__container {
      padding: 0px 32px;
    }
    &__filter {
      width: 100%;
      position: fixed;
      z-index: 99;
      top: 0;
      left: 0;
      background: $color-white;
      padding: 0px 32px;
      display: none;
      &--modal {
        display: block;
      }
    }
    .product-list__filter-toggle-icon {
      margin-left: 0px;
      margin-right: 10px;
    }
    &__tile-col--full-width {
      margin-left: 0px;
    }
    &__countries-list {
      .dropdown {
        z-index: 99;
      }
    }
    &__tiles {
      margin-top: 20px;
    }
    &__tile-col {
      margin: auto 0;
    }
    &__tile {
      margin-bottom: 40px;
    }
  }
  .sticky {
    .view-all-stores {
      &__countries-list {
        .dropdown {
          z-index: 99;
        }
      }
      &__controls {
        background-color: $color-white;
        box-shadow: 0px -3px 17px 0px rgba(0, 0, 0, 0.1);
        padding-left: 15px;
        padding-bottom: 15px;
        margin: 0 -15px;
      }
    }
    .view-all-stores__controls {
      background-color: $color-white;
      box-shadow: 0px -3px 17px 0px rgba(0, 0, 0, 0.1);
      padding: 18px 32px;
      border: none;
      margin: 0 -32px;
    }
    .view-all-stores__countries-list {
      display: none;
    }
    .view-all-stores__filter--modal {
      display: block;
      position: relative;
      top: -65px;
    }
  }
}

@media screen and (max-width: 767px) {
  .view-all-stores {
    .product-list__filter--sticky {
      margin-top: 0px;
    }
    #viewAllStore {
      border-bottom: 1px solid $cool-dark-grey;
      &.collapsible--open {
        padding-bottom: 40px;
      }
    }
    &__state-list {
      .Collapsible__contentInner {
        &:first-child {
          margin-top: 34px;
        }
      }
      .collapsible-accordion .collapsible__trigger--open {
        display: block;
      }
      &:first-child {
        .Collapsible {
          border-top: 1px solid $cool-dark-grey;
        }
      }
    }
    &__breadcrumb {
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 0px 18px;
    }
    &__countries-list {
      padding-bottom: 0px;
      .dropdown {
        z-index: 99;
      }
    }
    &__container {
      padding: 0 18px 60px !important;
    }
    &__controls {
      padding: 33px 0px 43px;
      height: auto;
    }

    &__filter {
      width: 100%;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $color-white;
      padding: 0px 18px;
      display: none;

      &--modal {
        display: block;
      }
    }

    &__tiles {
      margin-top: 20px;
    }

    &__tile-col {
      margin: auto 0;
    }

    &__tile {
      margin-bottom: 40px;
    }

    &__state-list {
      margin-bottom: 0;
      span,
      .view-all-stores__store-description {
        margin-right: 0;
      }
    }

    &__state-name,
    &__store-name {
      line-height: 34px;
      letter-spacing: -0.55px;
    }
    &__store-name {
      margin-bottom: 20px;
      margin-top: 0px;
    }

    &__set-as-my-store {
      margin-bottom: 40px;
    }
    .product-list__filter-toggle-icon {
      margin-left: 0px;
      margin-right: 10px;
    }
  }
  .checkbox .checkbox_default {
    line-height: 18px;
  }

  .sticky {
    .view-all-stores__controls {
      background-color: $color-white;
      box-shadow: 0px -3px 17px 0px rgba(0, 0, 0, 0.1);
      padding: 18px;
      border: none;
      margin: 0 -15px;
    }

    .view-all-stores__countries-list {
      display: none;
    }
    .view-all-stores__filter--modal {
      display: block;
      position: relative;
      top: -65px;
    }
  }
}
