.product-card-service{
    margin: 50px 0px;
    p{
        margin-bottom: 0px;
    }
    &__details-header{
        padding-right: 7.5rem;
        @media screen and (min-width: 1024px) {
        padding-top: 85px;
        padding-bottom: 85px;
        }
    }
    &__service-install{
        padding-right: 0px;
        padding-right: 7.5rem;
    }
    &__details-align{
        order: 2;
        .product-card-service__details-header{
            padding-right: 0px; 
            padding-left: 7.5rem;
            @media screen and (min-width: 1024px) {
                padding-top: 85px;
                padding-bottom: 85px;
            }
        }
    }
    &__img-container-align{
        order: 1;
    }
    &__name{
        font-family: $font-light;
        font-size: 45px;
        line-height: 56px;
        letter-spacing: -3.19px;
        color: $color-slate;
        margin-bottom: 0px;
    }
    &__desc{
        font-family: $font-regular;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        padding:15px 0px 32px;
    }
    &__virtual-list{
        padding-bottom: 40px;
        margin-top: 25px;
        ul{
            padding-left: 17px;
            margin-bottom: 0px;
            margin-left: 0px;
            li{
                list-style-type: disc;
                font-family: $font-regular;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.55px;
                color: $color-dark-gray;
            }
        }
    }
    &__virtual-service{
        display: flex;
        border-top: 1px solid $color-dark-gray;
        &-txt{
            font-family: $font-medium;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.4px;
            color: $color-slate;
            padding-top: 35px;
        }
        .tooltip-wrapper{
            position: relative;
            img{
                height:25px;
                width:25px;
                margin-left: 15px;
                margin-top: 35px;
            }
            .tooltip__container{
                position: absolute;
                left: 40px;
                bottom: 30px;
                z-index: 9;
            }
        }
    }
    &__virtual-service-zip{
        display: flex;
        flex-direction: column;
        .left{
            width: 75%;
        }
    }
    &__zipcode-input-error .input-field__border{
        border-color: $color-error-red;
    }
    &__zipcode-error{
        margin-top: 10px;
		font-family: $font-regular;
		font-size: 16px;
		line-height: 1.38;
		letter-spacing: -0.3px;
		color: $color-error-red;
    }
    &__zipcode-availability{
        padding-top: 20px;
        display: flex;
        align-items: center;
        .input-field{
            margin-bottom: 0px;
            width: 190px;
            padding-right: 15px;
        }
    }
    &__check-availability-cta {
        color: $color-slate;
        border: 1px solid $color-slate;
        background-color: transparent;
    }
    &__eligible-service{
        display: flex;
        justify-content: space-between;
        p{
            margin-bottom: 0px;
        }
        &-txt{
            font-family: $font-medium;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.4px;
            color: $color-charcoal;
            display: inline-block;
            margin-top: 35px;
        }
        &-edit{
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $color-slate;
            text-decoration: underline;
            padding-left: 10px;
            cursor: pointer;
            display: inline-block;
        }
        &-message{
            font-family: $font-regular;
            display: inline;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.45px;
            color: $color-dark-gray;
        }
    }
    &__tooltip--show {
		display: block;
	}
	&__tooltip--hide {
		display: none;
	}
    &__eligible-service-container{
        width: 75%;
        margin-bottom: 30px;
        .error{
            color: $color-error-red;
            font-size: 16px;
            a{
                color: $color-error-red;
                font-size: 16px; 
            }
        }
    }
    &__find-a-pro{
        text-decoration: underline;   
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        padding-left: 3px;
    }
    &__price {
        justify-content: flex-end;
        text-align: right;
        padding-top: 35px;
    }
    &__price-value {
        font-family: 'Helvetica Now Text W05 Regular';
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        color: $color-slate;
        white-space: pre;
    }

    &__discount-price {
        text-align: right;
        margin-top: 15px;
        .offer-percent {
            font-family: $font-medium;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.5px;
            color: $color-slate;
            text-transform: uppercase;
            margin-right: 15px;
        }
        .offer-percent-text{
            padding-left: 7px;
        }
        .strike-through-price {
            font-family: $font-regular;
            font-size: 22px;
            line-height: 30px;
            letter-spacing: -0.4px;
            color: $color-dark-gray;
            text-decoration: line-through;
        }
    }
    &__offer-price{
        display: inline-block;
    }
    &__cart{
        margin-top: 0px;
    }
    &__zipcode{
        font-family: $font-medium;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: $color-charcoal;
        padding-left: 5px;
        display: inline-block;
    }
    &__img-container {
        width: 540px;
        max-width: 540px;
        height: 540px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .quantity-selector-container{
        padding-right: 20px;
        padding-left: 0;
        .quantity-selector {
            &__count{
                font-size: 18px;
                font-family: $font-regular;
                color: $color-slate;
            }
            border: 1px solid $color-dark-gray;
            .quantity-selector {
                button {
                    &:disabled {
                        .plus {
                            .line {
                                background-color: $color-dark-gray;
                            }
                        }
                    }
                }
                .plus {
                    .line {
                        background: $color-slate;
                    }
                }
            }
        }
    }
    .add-to-cart-container{
        padding: 0;
    }
    .mobile-landscape-image {
        display: none;
    }
    .product-card-service-main-image {
        display: block;
    }
    .mobile-landscape-image {
        display: none;
    }
    .product-card-service-main-image {
        display: block;
    }
    .mobile-landscape-image {
        display: none;
    }
    .product-card-service-main-image {
        display: block;
    }
    .cartAction{
        display: flex;
        .quantity-selector-container{

        }
        .add-to-cart-container{
            width: 100%;
        }
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
    .product-card-service{
        .add-to-cart-container{
            .button.large{
                min-width: 150px;
            }
        }
        &__details-align{
            .product-card-service__details-header{
                padding-right: 0px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1140px){
    .product-card-service {
        &__img-container {
            max-width: 380px;
            height: 380px;
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px){
    .product-card-service{
        &__details-header{
            padding-right: 90px;
        }
        &__service-install{
            padding-right: 90px;
        }
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .product-card-service {
        &__details-align {
            .product-card-service__details-header {
                padding-right: 0px;
            }
        }
        &__img-container {
            width: 380px;
            max-width: 380px;
            height: 380px;
        }
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .product-card-service {
        &__details-align {
            .product-card-service__details-header {
                padding-right: 0px;
            }
        }
        &__img-container {
            width: 580px;
            max-width: 580px;
            height: 580px;
        }
    }
}


@media only screen and (max-width: 900px) and (orientation: landscape), only screen and (min-width: 667px) and (max-width: 991px){
    .product-card-service{
        margin: 50px 0px;
        .kf-react-container{
            padding-right: 0px !important;
        }
        .row{
            flex-wrap: nowrap;
        }
        &__name{
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -1.98px;
        }
        &__eligible-service-container {
            margin-top: 10px;
            padding-right: 0;
        }
        &__zipcode-availability{
            width: 60%;
            .input-field {
                width: 60%;
            }
            .button{
                width: 40%;
            }
        }

        &__details-align{
            order: 2;
            .product-card-service__details-header{
                padding-right: 0; 
                padding-left: 0;
            }
        }
        &__details-header{
            padding-right: 32px ;
            padding-left: 0 ;
            .row {
                margin: 0;
                .col {
                    padding:0 0 20px 0;
                }
            }
        }
        &__service-install{
                padding-right: 32px ;
                padding-left: 0 ;
                .row {
                    margin: 0;
                    .col {
                        padding:0 0 20px 0;
                    }
                }
            }

        .add-to-cart-container{
            .button.large{
                min-width: 150px;
            }
        }
        &__zipcode{
            padding-left: 0px;
        }
        &__eligible-service-txt{
            display: block;
        }

        .mobile-landscape-image {
            display: none;
           
        }
        .product-card-service-main-image {
            display: block;
            max-width: 340px;
            height: 453px;
            margin-bottom: 50px;
        }
        

        &__cart {
            padding-left: 0;
            .row {
                margin: 0;
                display: flex;
                justify-content: flex-start;
            }
        }
        .quantity-selector-container {
            padding-right: 10px;
            flex: 0 0 41.66667% !important;
        }
        .add-to-cart-container {
            padding: 0;
            flex: 0 0 79%;
            max-width: 60%;
        }

        &__eligible-service {
            display: flex;
            p {
                margin-right: 10px
            }
        }
        &__virtual-service-zip {
            margin: 0;
        }
        &__img-container-align{
            padding-right: 40px;
        }
        &__details-align{
            padding-right: 32px !important;
        }
    }
}
@media  only screen and (max-width: 667px) and (orientation: landscape){
    .product-card-service{
        .product-card-service-main-image {
            max-width: 316px;
        }
          }

}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    .product-card-service{
        margin: 50px 0px;
        .kf-react-container{
            padding: 0 !important;
        }
        &__details, &__details-align {
            order: 2;
        } 
        &__details-header {
            padding-left: 18px;
            padding-right: 18px;
        }
        &__service-install{
            padding-left: 18px;
            padding-right: 18px;
        }
        &__details-align {
            .product-card-service__details-header {
                padding-right: 18px;
                padding-left: 18px;
            }
        }
        &__img-container, &__img-container-align{
            order: 1;
            padding: 0px;
            margin-bottom: 40px;
        }
        &__img-container {
            height: 375px;
        }
        &__name{
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -1.98px;
        }
        &__zipcode-availability{
            .input-field {
                width: 49%;
            }
            .button{
                color: $color-slate;
                width: 49%;
            }
        }
        .quantity-selector-container{
            padding-left: 0;
            padding-right: 10px;
        }
        &__cart{
            padding-left: 0;
        }
        &__virtual-list{
            ul{
                padding-left: 18px;
                padding-right: 18px;
                margin-bottom: 0px;
                margin-left: 0px;
            }
        }
        &__eligible-service-txt{
            display: inline-block;
        }
        &__virtual-service{
            .tooltip-wrapper{
                img{
                    display: block;
                    margin-left: 15px;
                }
                .tooltip__container {
                    right: 0;
                    left: inherit;
                    bottom: 40px;
                    z-index: 9;
                }
            }
        }
    }
}