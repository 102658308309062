.date-picker{
    position: relative;
    &__header{
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
    }
    &__hide-input{
        display: none;
    }
    &__calendar{
        display: none;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
        padding: 30px 30px 20px;
        width: 100%;
        z-index: 99;
    }
    &__positioning{
        display: block;
        position: relative;
        box-shadow: none;
        padding: 0px;
        top: 0px;
    }
    &__calendar-show{
        display: block;
    }
    &__select-done{
        text-align: right;
        span{
            font-family: $font-medium;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.34px;
            color: $color-dark-gray;
            cursor: pointer;
        }
    }
    &__month{
        font-family: $font-regular;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
    }
    &__change-year{
        font-family: $font-regular;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        cursor: pointer;
        padding-left: 5px;
    }
    &__year-input {
        margin-left: 5px;
    }
    &__dates, &__nav{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        padding-left: 0px;
        text-align: center;
        margin-left: 0px;
        li{
            list-style: none;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 22px;
        }
    }
    &__point-date{
        color: $color-white;
        background-color: $color-charcoal;
        letter-spacing: normal;
        cursor: pointer;
    }
    &__out-date{
        letter-spacing: -0.32px;
        color: $color-light-gray;
    }
    &__inner-date{
        cursor: pointer;
        color: $color-dark-gray;
        letter-spacing: -0.65px;
    }
    &__past{
        cursor: not-allowed;
        color: $color-light-gray;
        letter-spacing: -0.65px;
    }
    &__nav{
        li{
            letter-spacing: -0.65px;
            color: $color-dark-gray;
        }
        .sr-only, .sr-only:focus {
            width: 16px;
            height: 16px;
            margin-left: -16px;
            font-size: 8px;
        }
    }
    &__prev-month, &__next-month{
        cursor: pointer;
        margin-left: 20px;
    }
    &__prev-month-disabled{
        cursor: not-allowed;
        margin-left: 20px;
    }
}