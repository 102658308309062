.full-bleed-hero-banner {
    position: relative;
    padding:0;
    margin:0;
    .cmp-teaser{
        display:flex;
        flex-direction:column;
        &__content{      
            @include breakpoint(desktop-large){
                max-width:1920px;
                left:50%;
                margin-left:-960px;
                padding:0 50px;
            }
            @include breakpoint(desktop-xlg){
                padding: 0 2.6045%;
            }
            @include breakpoint(desktop-lg){
                padding: 0 6.875rem;
            }
            @include breakpoint(desktop-small){
                width:100%;
                position: absolute;
                z-index:2;
                text-align: left;
                display: flex;
                flex-direction: column;
            }
            @include breakpoint (tab) {
                width: 38.25rem;
            }
            @include breakpoint(tab-max){
                position: relative;
                top:0;
                left:0;
                padding: 2.5rem 2rem 2.8125rem;
                text-align: left;
            }
            @include breakpoint(mobile){
                padding: 2.5rem 1.125rem 2.8125rem;
                box-sizing:border-box;
                width: 20.8125rem;
            }
            *{
                @include breakpoint(desktop-small){
                    color:$white;
                }
            }
        }
        &__title{
            @include breakpoint(desktop-small){
                width: 33.75rem;
            }
            h1,h2,h3,h4,h5,h6,p{
                @include headingtitle_h3;
                margin-bottom:1.875rem;
                margin-top:0;
                color:$white;
                padding-right:1.875rem;
                @include breakpoint (tab) {
                    width: 38.25rem;
                }
                @include breakpoint(tab-max){
                    @include ann-describtion (28px, $playfair-regular, 34px);
                    letter-spacing: -1.17px;
                    color:$text-dark-grey;
                    padding-right:290px;
                }
                @include breakpoint(mobile){                    
                    padding-right: 6rem;
                }
            }
        }
        &__description{
            @include breakpoint(desktop-small){
                width: 26.25rem;
            }
            @include breakpoint(tab){
                width: 38.25rem;
            }
            p{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0.44px;
                margin-top:0;
                margin-bottom: 1.25rem;
                color:$white;
                @include breakpoint(tab-max){
                    color:$text-medium-dark-grey;
                    margin-bottom:1.875rem;
                }
            }
        }
        &__action-container{
            display: flex;
            flex-direction: row;
            @include breakpoint (desktop-xlg) {
            align-items: center;
            }
            @include breakpoint(tab-max){
                flex-direction: column;
            }
            .cmp-teaser__action-link{
                padding-bottom: 7px;
                line-height:.75rem;          
                @include ann-describtion (11px, $lato-regular, 16px);
                letter-spacing: 1.8px;  
                @include breakpoint(desktop-small){
                    color:$white;
                    border-color:$white;                 
                }
                @include breakpoint(tab-max){
                    color:$color-light-blue;
                    border-color: $color-light-blue;
                    justify-content: flex-start;
                    width: fit-content;  
                }
                &.gbh-primary-button {
                    border: none !important;
                    font-size: .6875rem;
                    line-height: 1.5rem;
                    letter-spacing: .114375rem;
                    text-transform: uppercase;
                    font-family: $lato-regular;
                    text-decoration: none;
                 }
                &:first-child{
                    margin-right: 2.4375rem;
                    background: none;
                    padding: 0 0 7px!important;
                    @include breakpoint(tab-max){
                        margin-right:0;
                        margin-bottom: 1.25rem;
                    }
                }
                &:last-child{
                    background: none;
                    padding: 0 0 7px !important;
                    @include breakpoint(tab-max){
                        margin-right:0;
                        margin-bottom: 0;
                    }
                }
            }
        }
      
        &__image{
            width: 100%;
            max-height:1002px;
            position: relative;
            @include breakpoint(desktop-lg){
                min-height:835px;
            }
            @include breakpoint(tab){
                max-height:432px;
            }
            @include breakpoint(mobile){
                min-height:469px;
            }
            .cmp-image{
                img{
                    min-height:inherit;
                }
            }
        }
    }
    &.content-top-left{
        .cmp-teaser{
            &__content{
                top: 90px;
                @include breakpoint(desktop-lg){
                    padding: 0 6.875rem;
                    top: 5.625rem;
                }
                @include breakpoint(desktop-mid){
                    padding: 0 6.875rem;
                }
                @include breakpoint(tab-max){
                    position: relative;
                    top:0;
                    left:0;
                    width:100%;
                }
            }
        }
    }
    &.content-bottom-left{
        .cmp-teaser{
            &__content{                
                @include breakpoint(desktop-small){
                    bottom: 7.5rem;
                }
                @include breakpoint(tab-max){
                    position: relative;
                    top:0;
                    left:0;
                    width:100%;
                }
            }
        }
    }
    &.content-bottom-center{
        .cmp-teaser{
            &__content{
                @include breakpoint(desktop-small){
                    width: 33.75rem;
                    padding-right:0;
                    text-align: center;
                    margin-left: -16.875rem;
                    bottom: 7.5rem;
                    align-items: center;
                    left: 50%;
                }
                @include breakpoint(tab-max){
                    position: relative;
                    top:0;
                    left:0;
                    width:100%;
                    text-align:left;
                    margin-left:0;
                }
                @include breakpoint(tab){
                    padding-right:2rem;
                }
                @include breakpoint(mobile){
                    padding-right:1.125rem;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    padding:0 15px;
                    @include breakpoint(tab-max){
                        padding:0;
                    }
                    @include breakpoint(mobile){
                        padding-right: 2rem;
                    }
                }
            }
        }
    }
    &.content-bottom-right{
        .cmp-teaser{
            &__content{
                @include breakpoint(desktop-large){
                    max-width:1920px;
                    right:50%;
                    margin-right:-960px;
                }
                @include breakpoint(desktop-small){
                    bottom: 7.5rem;
                    text-align: right;
                    left: unset;
                    align-items: flex-end;
                }
                @include breakpoint(tab-max){
                    position: relative;
                    top:0;
                    width:100%;
                    text-align:left;
                    right:0;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6{
                    padding-right:0;
                    padding-left:2.5rem;
                    @include breakpoint(tab-max){
                        padding-left:0;
                    }
                    @include breakpoint(mobile){
                        padding-right: 2rem;
                    }
                }
            }
            &__description{
                p{
                    padding-right:0;
                    text-align:right;
                    @include breakpoint(tab-max){
                        padding-left:0;
                        text-align: left;
                    }
                    @include breakpoint(tab){
                        padding-right:2rem;
                    }
                }
            }
        }
    }
    &.content-top-right{
        .cmp-teaser{
            &__content{                
                @include breakpoint(desktop-large){
                    max-width:1920px;
                    right:50%;
                    margin-right:-960px;
                }                                
                @include breakpoint(desktop-xlg){
                    padding: 0 2.6045%;
                }
                @include breakpoint(desktop-lg){
                    padding: 0 6.875rem;
                    top: 5.625rem;
                }
                @include breakpoint(desktop-mid){
                    padding: 0 6.875rem;
                }
                @include breakpoint(desktop-small){
                    text-align: right;
                    left:unset;
                    top: 90px;
                    align-items: flex-end;
                }
                @include breakpoint(tab-max){
                    position: relative;
                    top:0;
                    left:0;
                    width:100%;
                    text-align: left;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6{
                    padding-right:0;
                }
            }
            &__description{
                padding-right:0;
            }
        }
    }
    &.content-dead-center{
        .cmp-teaser{
            &__content{                
                @include breakpoint(desktop-small){
                    width: 33.75rem;
                    padding-right:0;
                    text-align: center;
                    left: 50%;
                    margin-left: -16.875rem;
                    align-items: center;
                    top: 50%;
                    margin-top: -122px;
                }
                @include breakpoint(tab-max){
                    position: relative;
                    top:0;
                    left:0;
                    width:100%;
                    text-align:left;
                    margin-left:0;
                    margin-top:0;
                }
                @include breakpoint(tab){
                    padding-right:2rem;
                }
                @include breakpoint(mobile){
                    padding-right:1.125rem;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    padding:0 15px;
                    @include breakpoint(tab-max){
                        padding:0;
                    }
                    @include breakpoint(mobile){
                        padding-right: 2rem;
                    }
                }
            }
        }
    }
    &.teaser{
        .cmp-teaser{
            &__content{
                @include breakpoint(desktop-small){
                    background-color: transparent;      
                }
            }
            &__title, &__description, &__action-link{
                @include breakpoint(desktop-small){
                    color:$white;
                    border-color:$white;
                    h1,h2,h3,h4,h5,h6,p{
                        color:$white;
                    }
                }
            }
        }
    }      
}