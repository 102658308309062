.cart-items {
  .order-summary {
    display: none;
  }
  &__title {
    font-family: $font-light;
    font-size: 58px;
    line-height: 1.13;
    letter-spacing: -3.1px;
    color: $color-slate;
  }
  &__alert {
    width: 100%;
    border-radius: 5px;
    background-color: $color-error-red;
    margin-top: 40px;
    padding: 20px 25px;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-white;
    &-title {
      margin-right: 10px;
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
    }
  }
  &__th--mobile {
    display: none;
  }
  &__td {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    padding-bottom: 20px;
    margin-top: 40px;
  }
  &__empty {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    span {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-slate;
      text-decoration: underline;
      padding-bottom: 5px;
    }
    &.--loading {
      span {
        pointer-events: none;
      }
      div {
        cursor: not-allowed;
      }
    }
  }
  &__download-print {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: 30px;
    span {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      img {
        padding-right: 10px;
      }
    }
  }
  &__no-items {
    border-top: 1px solid $color-light-gray;
    &-message {
      margin-top: 50px;
      font-family: $font-light;
      font-size: 36px;
      line-height: 1.22;
      letter-spacing: -1.7px;
      color: $color-slate;
    }
    &-links {
      margin-top: 20px;
      font-family: $font-regular;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      a {
        text-decoration: underline;
        text-underline-position: under;
        font-family: $font-regular;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        &:hover {
          text-decoration: underline;
          color: $color-black;
        }
      }
    }
  }
.your-cart{
  margin-bottom: 40px;
}
  @media screen and (width: 1024px) {
    &__title {
      font-size: 40px;
      line-height: 1.15;
      letter-spacing: -2.2px;
    }
  }
  @media screen and (max-width: 480px) {
    &__title {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 1023px) {
    .order-summary {
      display: block;
      &__item {
        margin-bottom: 0;
      }
    }
    &__title {
      font-size: 40px;
      line-height: 1.15;
      letter-spacing: -2.2px;
    }
    &__checkout-btn {
      margin-top: 30px;
    }
    &__th--mobile {
      display: flex;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    &__td--mobile {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
    }
    &__no-items {
      &-message {
        margin-top: 30px;
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -2.5px;
      }
      &-links {
        margin-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .cart-item__price,
  .cart-item__total {
    font-size: 14px;
  }

  .order-summary__item--value-large {
    font-size: 21px;
  }
}

.download-specs--mobile {
  &__cta {
    display: flex;
  }
  p,
  span {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-slate;
    img {
      margin-left: 10px;
    }
  }
  p {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  span {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:first-child {
      margin-right: 85px;
    }
  }
  @media screen and (max-width: 480px) {
    &__cta {
      justify-content: space-between;
    }
    span:first-child {
      margin-right: 0;
    }
  }
}
