//============================= //
@mixin button($btn-bg, $btn-text, $btn-border, $btn-size) {
    background:$btn-bg;
    color:$btn-text;
    font-family: $lato-bold;
    text-align: center;
    border: 1px solid $btn-border;
    box-sizing: border-box;
    text-transform:uppercase;
    &:hover, &:active {
        text-decoration:none;
        color:$btn-text;
    }
    &:disabled{
      background-color: $light-gray;
      border: 0;
    }
  @if ($btn-size == 'large') {
    padding:.75rem 1.6875rem;
    font-size:.75rem;
    line-height:1.5rem;
    letter-spacing:0.15625rem;
  }
  @else if ($btn-size == 'small') {
    padding:.9375rem 1.6563rem;
    font-size:0.688rem;
    line-height:0.625rem;
    letter-spacing:0.15625rem;
    }

    @include breakpoint(mobile){
      width: 100%;
    }
  }

  // Button class declaration
  .gbh-primary-button-large{
        .cmp-teaser__action-link{
            @include button($color-medium-blue, $white, $color-medium-blue, large);
        }
  }
  .gbh-primary-button-small{
        .cmp-teaser__action-link{
            @include button($color-medium-blue, $white, $color-medium-blue, small);
        }
  }
  .gbh-secondary-button-large{
        .cmp-teaser__action-link{
            @include button(transparent, $color-light-blue, $color-light-blue, large);
        }
  }
  .gbh-secondary-button-small{
        .cmp-teaser__action-link{
            @include button(transparent, $color-light-blue, $color-light-blue, small);
        }
  }  
.cmp-teaser__action-link {
    font-size: 0.6875rem;
    line-height: 1.5rem;
    letter-spacing: 0.114375rem;
    text-transform: uppercase;
    font-family: $font-regular-base;
    text-decoration: none;
    border-bottom: 1px solid $cool-light-grey;
    padding: 0;
    transition: 1s ease-in-out;
    box-shadow: none;
    background: transparent;
    display: inline-flex;
    justify-content: center;
    border-radius: 0;
    box-shadow:none;
    border-radius:0;
    white-space: nowrap;
    &:hover{
      text-decoration: none;
      color:inherit;
    }
    &.gbh-secondary-button{
      @include button(transparent, inherit, $color-medium-blue, large);
  }    
}
// image cluster CTA button

.gbh__btn--link {
  @extend .cmp-teaser__action-link;
  @include button(transparent, $color-light-blue, $color-medium-blue, large);
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}	