.radio-button {
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    input {
        position: absolute;
        visibility: hidden;
    }
    &__marker {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        height: 20px;
        width: 20px;
        border: 1px solid $color-dark-gray;
        border-radius: 10px;
        background-color: $color-white;
    }
    &__checked {
        display: none;
    }
    &__label {
        padding-left: 10px;
        color: $color-slate;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        margin-right: 30px;
    }
    input:checked ~ &__marker {
        .radio-button__checked {
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
            height: 14px;
            width: 14px;
            border-radius: 10px;
            background-color: $color-kohler-blue;
        }
    }
    &.--black {
        input:checked ~ .radio-button__marker {
            .radio-button__checked {
                background-color: $color-charcoal;
            }
        }
    }
    &.--disabled {
        cursor: not-allowed;
        .radio-button__marker {
            border-color: $color-light-gray;
        }
        input:checked ~ .radio-button__marker {
            .radio-button__checked {
                background-color: $color-light-gray;
            }
        }
    }
}
