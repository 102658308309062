@font-face {
  font-family: "Helvetica Now Text W05 Light";
  src: url("/fonts/8448bbbb-ba62-45df-ae79-4ded34b0727b.woff2") format("woff2"),
    url("/fonts/ad48d569-0df5-4f5d-8ad3-8ce63dd84d7d.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Now Text W05 Regular";
  src: url("/fonts/5eec4451-e90c-42e7-b4d8-9b8654a65568.woff2") format("woff2"),
    url("/fonts/f7cf763b-e79b-4c28-af92-803e0c3f16d4.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Now Text W05 Medium";
  src: url("/fonts/2fd3ef81-07ed-45b4-a344-7a0defbdadcf.woff2") format("woff2"),
    url("/fonts/52492279-0374-4238-ba8b-751b50089cf0.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Text W05 Bold";
  src: url("/fonts/2a13627f-dba8-48e1-935c-a404f5293139.woff2") format("woff2"),
    url("/fonts/ce7a1fe7-2605-427a-b064-a01ebce69295.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.woff") format("woff"),
    url("/fonts/icomoon.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

$font-light: "Helvetica Now Text W05 Light";
$font-regular: "Helvetica Now Text W05 Regular";
$font-medium: "Helvetica Now Text W05 Medium";
$font-bold: "Helvetica Now Text W05 Bold";
