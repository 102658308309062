//Medium Sitewide Message

.vertical-medium-message-V1 {
  position: fixed;
  z-index: 2;
  vertical-align: middle;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  padding: 0;
  max-width: 31.25rem;
  max-height: 694px;
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 1px;
    background-color: $cool-light-grey;
  }
  @include breakpoint(tab) {
    height: auto;
    max-height: 85vh;
    overflow: scroll;
    max-width: 100vw;
  }
  @include breakpoint(mobile) {
    width: 100vw;
    height: 100vh;
  }

  &.teaser {
    padding: 0;
    color: $text-dark-grey;
    .cmp-teaser {
      &__content {
        text-align: left;
        width: 31.25rem;
        margin: 0 auto;
        background: $white;
        @include breakpoint(tab) {
          width: auto;
          margin: 0 auto;
        }
        @include breakpoint(mobile) {
          width: auto;
          margin: 0 auto;
        }
      }
      &__title {
        position: relative;
        button.close {
          font-family: $icon-moon;
          content: "\e92e";
          position: absolute;
          right: 2.5rem;
          top: 2.1875rem;
          font-size: 1rem;
          color: $text-medium-black;
          cursor: pointer;
          @include breakpoint(tab) {
            right: 2rem;
            top: 4.125rem;
          }
          @include breakpoint(mobile) {
            right: 1.125rem;
            top: 0.4375rem;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-family: $lato-regular;
          @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
          letter-spacing: normal;
          margin-bottom: 0;
          @include breakpoint(desktop-small) {
            @include ann-describtion(1.375rem, $lato-regular, 2rem);
            padding: 4.375rem 2.5rem 10.125rem;
          }
          @include breakpoint(tab) {
            padding: 6.375rem 0 2.5rem;
            width: 29.75rem;
            margin: 0 auto;
            padding-bottom: 10.125rem;
          }
          @include breakpoint(mobile) {
            padding: 2.5rem 1.125rem 9.125rem;
          }
        }
      }
      &__image {
        background-image: url(../images/AnnSacks_ModelNumberExample.svg);
        margin: 0 auto;
        display: block;
        position: absolute;
        z-index: 1;
        background-repeat: no-repeat;
        min-height: auto;
        @include breakpoint(desktop-small) {
          width: 26.25rem;
          height: 5.125rem;
          top: 8.875rem;
          left: 2.5rem;
        }
        @include breakpoint(tab) {
          top: 15rem;
          width: 29.75rem;
          height: 4.125rem;
          position: relative;
          z-index: 0;
          padding: 2.5rem 0 0;
        }
        @include breakpoint(mobile) {
          top: 6.75rem;
          width: 21.1875rem;
          height: 4.125rem;
          left: 1.125rem;
        }
      }
      &__description {
        background-color: $cool-light-grey;
        padding: 2.5rem 2.5rem 3.125rem;
        @include breakpoint(mobile) {
          padding: 2.5rem 1.125rem 3.125rem;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        p {
          @include ann-describtion(1rem, $lato-regular, 1.5rem);
          letter-spacing: normal;
          padding-bottom: 1.25rem;
          margin-bottom: 0;
          @include breakpoint(tab) {
            width: 29.75rem;
            margin: 0 auto;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
        ul {
          margin: 0 0 0 0.875rem;
          @include breakpoint(tab) {
            width: 29.75rem;
            margin: 0 auto;
          }
          li {
            @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
            letter-spacing: normal;
            padding-bottom: 1.5625rem;
            @include breakpoint(tab) {
              margin-left: 0.9375rem;
            }
            &:last-child {
              padding-bottom: 1.875rem;
              @include breakpoint(mobile) {
                padding-bottom: 1.25rem;
              }
            }
            &::marker {
              font-size: 0.8125rem;
              line-height: 1.25rem;
              letter-spacing: 0.0125rem;
            }
          }
        }
      }
    }
  }
}

.vertical-medium-message-V2 {
  @extend .vertical-medium-message-V1;
  &.teaser {
    .cmp-teaser {
      &__title {
        position: relative;
        button.close {
          @include breakpoint(tab) {
            right: 2rem;
            top: 1.375rem;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          @include breakpoint(desktop-small) {
            padding-bottom: 3.125rem;
          }
          @include breakpoint(tab) {
            text-align: center;
            padding: 3.25rem 0 3.125rem;
          }
          @include breakpoint(mobile) {
            padding-bottom: 3.125rem;
          }
        }
      }
      &__image {
        background-image: none;
        width: 0;
        height: 0;
      }
      &__description {
        @include breakpoint(tab) {
          padding-top: 3.5625rem;
        }
        p {
          @include breakpoint(tab) {
            width: 38.125rem;
            padding-right: 8.5rem;
          }
        }
        ul {
          @include breakpoint(tab) {
            width: 38.125rem;
            padding-right: 8.5rem;
          }
        }
      }
      &__action-container {
        padding: 0 2.5rem 3.125rem;
        background-color: $cool-light-grey;
        @include breakpoint(tab) {
          text-align: center;
        }
        @include breakpoint(mobile) {
          padding: 0 1.125rem 3.125rem;
        }
        .cmp-teaser__action-link {
          width: 100%;
          background-color: $color-medium-blue !important;
          color: $white;
          @include ann-describtion(0.75rem, $lato-bold, 1.5rem);
          letter-spacing: 0.15625rem;
          padding: 0.75rem 1.6875rem 0.875rem;
          @include breakpoint(tab) {
            max-width: 38.125rem;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
