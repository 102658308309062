* {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  text-rendering: optimizeLegibility;
}
.mainfooter {
  footer {
    @include breakpoint(desktop-small) {
      padding: 0 2.6%;
    }

    .gbh-footer-main-nav {
      background-color: transparent;
      .gbh-container {
        max-width: 1820px;
        padding: 60px 0 200px;
        margin: 0 auto;
        border-top: 1px solid $light-white;
        display: flex;
        justify-content: space-between;
        @include breakpoint(tab-max) {
          max-width: 100%;
          flex-direction: column;
          padding: 0 0 20px;
          border-top: none;
        }
      }
      .gbh-footer-main-nav-link {
        .footer-inner-head {
          @include breakpoint(desktop-small) {
            padding-bottom: 0;
          }
          @include breakpoint(tab-max) {
            border-top: 1px solid $cool-dark-grey;
            padding: 24.5px 3.94% 20px;
          }
          div {
            font-size: 16px;
            font-family: $lato-regular;
            line-height: 24px;
            color: $text-medium-dark-grey;
            letter-spacing: 0;
            text-transform: capitalize;

            @include breakpoint(desktop-small) {
              margin-bottom: 15px;
            }
          }
          .accordion {
            display: none;
            @include breakpoint(tab-max) {
              display: flex;
              top: 27px;
            }
            .line {
              @include breakpoint(tab-max) {
                background: $text-medium-dark-grey;
              }
            }
          }
        }
        ul {
          @include breakpoint(tab-max) {
            margin-bottom: 20px;
          }
        }
      }
      .row {
        width: 100%;
        justify-content: space-between;
        margin: 0;
        gap: 40px;
        @include breakpoint(desktop-lg) {
          flex-wrap: nowrap;
        }
        @media (max-width: 1023px) {
          display: block;
        }
      }
      .col {
        padding: 0;
        &:not(.set-element-size) {
          max-width: unset;
          margin-right: 0;
          &:last-child {
            .footer-inner-head {
              border-bottom: 0;
            }
          }
        }
      }
    }
    ul {
      li {
        &:last-child {
          @media (max-width: 1023px) {
            padding-bottom: 0;
          }
        }
        a {
          font-size: 12px;
          font-family: $lato-regular;
          line-height: 40px;
          color: $text-medium-dark-grey;
          letter-spacing: 2px;
          text-transform: uppercase;
          &:hover {
            font-weight: 600;
            text-decoration: none;
          }
        }
      }
    }
  }
}
.ann-sacks-additional-footer {
  background: $color-dark-blue;
  padding: 0 2.6%;
  .additional-footer {
    max-width: 1920px;
    padding: 10px 0;
    margin: 0 auto;
    @include breakpoint(desktop-mid) {
      padding: 12px 0;
    }
    @include breakpoint(tab-max) {
      padding: 22px 0 25.5px;
    }
    @include breakpoint(mobile) {
      padding: 30px 0;
    }

    .gbh-footer-add-panel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: $helvetica-regular;
      @include breakpoint(tab-max) {
        flex-direction: column;
      }
      @include breakpoint(tab) {
        row-gap: 17px;
      }
      .gbh-footer-nav__links {
        display: flex;
        margin: 0;
        @include breakpoint(tab-mid) {
          margin-bottom: 5px;
        }
        @include breakpoint(mobile) {
          margin-bottom: 20px;
          align-items: center;
          flex-direction: column;
        }
        li {
          padding-right: 20px;
          list-style: none;
          display: inherit;
          @include breakpoint(mobile) {
            padding-right: 0;
            padding-bottom: 15px;
            display: inline-block;
          }
          &:last-child {
            @include breakpoint(mobile) {
              padding-bottom: 0;
            }
          }
          a {
            font-family: $lato-regular;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: $white;
            opacity: 0.7;
            -webkit-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
            cursor: pointer;
            @include breakpoint(desktop-mid) {
              letter-spacing: 0;
            }
            @include breakpoint(tab-mid) {
              letter-spacing: 0;
            }
            @include breakpoint(mobile) {
              line-height: 20px;
              letter-spacing: 0.2px;
              height: 20px;
              display: block;
            }
          }
        }
      }
      .gbh-copy__rights {
        font-size: 10px;
        font-family: $lato-regular;
        line-height: 16px;
        letter-spacing: 1.67px;
        color: $white;
        margin-bottom: 0;
        text-transform: uppercase;
        opacity: 0.7;
      }
    }
  }
}
