.spacer {
    &.separator{
        .cmp-separator{
            padding: 0;
            &__horizontal-rule{
                @include breakpoint (desktop-small) {
                    height: 70px !important;
                }
                @include breakpoint (tab) {
                    height: 50px !important;
                }
                @include breakpoint (mobile) {
                    height: 0px !important;
                }
                background-color:transparent;
                padding: 0;
                margin: 0;
                z-index: 1;
                position: relative;
            }
        }
    }
}