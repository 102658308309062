// Annsacks Order Status HOB Enhancement
.order-status {
  .order-status {
    &__form-wrapper {
      .order-status {
        &__banner {
          .order-status {
            &__title {
              @include text-style(2.25rem, 2.75rem, -0.0625rem);
              font-family: $playfair-display-regular;
              @include breakpoint(tab-max) {
                @include text-style(1.75rem, 2.125rem, -0.0488rem);
              }
            }
            &__desc {
              @include text-style(0.875rem, 1.375rem, -0.0281rem);
              @include breakpoint(mobile) {
                padding-top: 0.625rem;
              }
            }
          }
        }
        &__form {
          .order-status {
            &__help-text {
              @include text-style(0.875rem, 1.375rem, -0.0281rem);
            }
            &__support {
              div {
                @include text-style(1rem, 1.5rem, -0.0344rem);
                font-family: $helvetica-regular;
                color: $slate;
                @include breakpoint(tab-max) {
                  letter-spacing: -0.025rem;
                }
              }
            }
            &__form-content {
              .input-field__value {
                label {
                  @include text-style(1.125rem, 1.625rem, -0.0313rem);
                  font-family:$font-regular;
                  transform: translate(0, 88%) scale(1.1);
                  position: relative;
                  top: -8px;
                }
              }
              .input-field__value input:not(:placeholder-shown) + label, .Input_inputFieldWrapper__vj2wJ .input-field__value input:focus + label{
                transform:translate(0, 0) scale(1);
                top: 0;
              }
              .order-status__find-btn{
                .order-status__find-btn{
                  @include text-style(.75rem, 1.5rem, .1563rem);
                  text-transform: uppercase;
                  font-family: $lato-bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
