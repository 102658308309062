.tooltip__container {
  width: 240px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
  span {
      font-size: 14px;
      font-family: $font-regular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
  }
}
