@import './base/colors';
.modal-popup {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    height: 100%;
    z-index: 1004;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    .close-button-holder{
        &.stick-close-button{
            position: sticky;
            top: 0px;
            width: 100%;
            background: $color-white;
            height: 50px;
        }
    }
    .modal-popup-content {
        position: relative;
        background-color: white;
        overflow-y: auto;
        .modal-popup-body{
            padding: 35px 40px 50px 40px;
            @media (min-width: 1920px) { 
                padding: 35px 50px 50px;
            } 
        }
        margin: auto;
        width: fit-content;
        max-height: 95%;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 1px $color-light-gray; 
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: $color-dark-gray; 
            border-radius: 5px;
        }

        .close-button {
            position: absolute;
            right: 40px;
            top: 32px;
            cursor: pointer;
            display: block;
            float: right;
            z-index: 1;
            img {
               width: 16.6px;
            }
        }
        .modal-popup-body {
            margin: 0;
        }
    }
    
    &.show-modal-popup {
        opacity: 1;
        visibility: visible;
        transform: scale(1.0);
        transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    }
}

@media screen and (max-width: 768px) {
    .modal-popup {
        .modal-popup-content {
            .close-button {
                top: 22px;
                right: 18px
            }
        }
    }
}
@media only screen and (max-width: 860px) and (orientation: landscape){
    .modal-popup {
        .modal-popup-content {
            .close-button {
                top: 17px;
                right: 32px
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .modal-popup {
        .modal-popup-content {
            margin: none !important;
            min-height: 100vh;
            width: 100%;
            .modal-popup-body{
                padding: 59px 18px 50px 17.5px;
            }
        }
    }
}
