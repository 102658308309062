.order-status {
    &__banner {
        padding-left: 110px;
        @media screen and (min-width: 993px) and (max-width: 1380px) {
            padding-left: 60px;
        }
        @media screen and (min-width: 769px) and (max-width: 992px) {
            padding-left: 32px;
            padding-right: 32px;
        }
        @media screen and (max-width: 768px) {
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    &__form {
        padding-right: 110px;
        @media screen and (min-width: 993px) and (max-width: 1380px) {
            padding-right: 60px;
        }
        @media screen and (min-width: 769px) and (max-width: 992px) {
            padding-left: 32px;
            padding-right: 32px;
        }
        @media screen and (max-width: 768px) {
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    &__form-wrapper {
        background-color: $color-off-white;
    }
    &__header {
        max-width: 540px;
        margin: 120px 120px 0 0;
    }
    &__form-content {
        max-width: 420px;
        margin: 150px auto 150px 120px;
        .input-field:first-child {
            margin-bottom: 0;
        }
    }
    &__error-box {
        margin-bottom: 15px;
        span {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: -0.3px;
            color: $color-error-red;
            p {
                color: $color-error-red;
                font-family: $font-regular;
            }
        }        
    }
    &__breadcrumb {
        margin-top: 30px;
        color: $color-dark-gray;
        font-family: $font-regular;
        font-size: 14px;
        letter-spacing: -0.3px;
        line-height: 24px;
        a {
            cursor: pointer;
            text-decoration: none;
            color: $color-dark-gray;
            &:hover {
                color: $color-slate;
            }
        }
    }
    &__title {
        color: $color-slate;
        font-family: $font-light;
        font-size: 100px;
        letter-spacing: -7.5px;
        line-height: 110px;
    }
    &__desc {
        margin-top: 20px;
        color: $color-dark-gray;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
    }
    &__help-text {
        cursor: pointer;
        text-decoration: underline;
        margin-top: 10px;
        margin-bottom: 20px;
        color: $color-slate;
        font-family: $font-regular;
        font-size: 14px;
        letter-spacing: -0.45px;
        line-height: 22px;
    }
    &__tooltip-wrapper {
        position: absolute;
        z-index: 9;
        top: -40px;
        left: 180px;
    }
    &__tooltip--show {
        display: block;
        position: relative;
    }
    &__tooltip--hide {
        display: none;
    }
    .input-field__wrapper {
        margin-bottom: 0;
    }
    &__find-btn {
        margin-top: 50px;
    }
    &__support {
        margin-top: 60px;
        div {
            margin-bottom: 10px;
            color: $color-slate;
            font-family: $font-light;
            font-size: 26px;
            letter-spacing: -1.5px;
            line-height: 34px;
        }
        a {
            cursor: pointer;
            text-decoration: none;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $color-slate;
        }
    }

    @media screen and (max-width: 1024px) {
        &__header {
            max-width: none;
            margin: 50px 60px 50px auto;
        }
        &__form-content {
            max-width: none;
            margin: 40px auto 70px 60px;
        }
        &__breadcrumb {
            margin-top: 20px;
        }
        &__title {
            font-size: 46px;
            letter-spacing: -3.4px;
            line-height: 50px;
        }
        &__desc {
            margin-top: 15px;
        }
        &__support {
            margin-top: 50px;
        }
    }

    @media screen and (max-width: 992px) {
        &__header {
            margin: 50px 0 27px;
        }
        &__form-content {
            margin: 40px 0 70px;
            .input-field {
                margin-bottom: 0;
            }
        }
        &__find-btn {
            margin-top: 40px;
        }
        .recaptcha-links
        {
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 576px) {
        &__tooltip-wrapper {
            top: -15px;
            left: 85px;
        }
    }
}

.orderWarning {
    .modal-popup {
        &-content {
            width: 500px;
            padding: 70px 40px 40px;
        }
    }
    .order-warning {
        &__modal {
            color: $color-slate;
        }
        &__modal-title {
            font-family: $font-light;
            font-size: 36px;
            line-height: 44px;
            letter-spacing: -1.7px;
        }
        &__modal-desc {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            margin-top: 30px;
        }
        &__modal-cta {
            margin-top: 60px;
            .kf-react-button.button {
                width: 100%;
            }
        }
    }
    @media screen and (min-width: 480px) and (max-width: 990px) {
        .modal-popup {
            &-content {
                width: 100%;
                height: 100vh;
                padding: 55px 100px;
            }
        }
    }
    @media screen and (max-width: 480px) {     
        .modal-popup {
            &-content {
                padding: 55px 18px 18px;
            }
        }
    }
    @media screen and (max-width: 990px) {
        .order-warning {
            &__modal-title {
                font-family: $font-light;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -2.2px;
            }
        }
    }
}