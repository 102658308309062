.color-swatch {
  input[type="checkbox"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    border: solid 1px $color-white;
    &:checked {
      border: solid 1px $color-charcoal;
    }
  }
  input[type="checkbox"] + label {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
  }
  input[type="checkbox"]:checked + label {
    color: $color-charcoal;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    display: inline-block;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    border: solid 1px $color-white;
    margin-bottom: 0px;
    &.--no-swatch {
      border-color: $color-light-gray;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      vertical-align: top;
    }
  }
  input[type="radio"]:checked + label {
    display: inline-block;
    background-clip: content-box;
    padding: 3px;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    border: solid 1px $color-dark-gray;
    margin-bottom: 0px;
    &.--no-swatch {
      border-color: $color-dark-gray;
    }
  }
  .--no-swatch {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
