.toast-msg {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    padding: 15px 0;
    background-color: $maroon !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    @include breakpoint(tab){
      padding: 15px 32px;
      flex-shrink: 0;
    }
    @include breakpoint(mobile){
      padding: 15px 18px;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }
    &__message {
        font-family: $lato-regular;
        font-size: 18px;
        letter-spacing: -0.45px;        
        color: $color-white;
        font-weight: 400;
        line-height: 26px;
        a {
            cursor: pointer;
            text-decoration: underline;
            font-family: $lato-regular;
            font-size: 18px;
            letter-spacing: -0.45px;
            line-height: 26px;
            color: $color-white;
        }
        p {
            font-family: $lato-regular;
            font-size: 18px;
            letter-spacing: -0.45px;
            line-height: 26px;
            color: $color-white;
        }  
        
    }
    &__close {
        img {
            cursor: pointer;
            width: 16px;
            height: 16px;
        }
    }
    @include breakpoint (tab-max) {
        line-height: 30px;
    }
}