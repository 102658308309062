.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;

    input { 
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: $color-dark-gray;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px #000;
        }

        &:checked + .slider:before {
            -webkit-transform: translateX(31px);
            -ms-transform: translateX(31px);
            transform: translateX(31px);
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-slate;
        -webkit-transition: .4s;
        transition: .4s;
    
        &:before {
            position: absolute;
            content: "";
            height: 28px;
            width: 28px;
            left: 0px;
            bottom: 1px;
            background-color: $color-white;
            transition: .4s;
        }
        &.round {
            border-radius: 34px;
            &:before {
                border-radius: 50%;
            }
        }
      }
  }

  @media screen and (orientation:landscape) and (max-width: 990px), screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1024px){
    .switch {
        width: 50px;
        height: 20px;
     .slider{
         &:before{
            height: 18px;
            width: 18px;
         }
     }
    }
  }