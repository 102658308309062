.account-details {
  margin: 50px 20px 0;

  &__edit-cta {
    background-color: #dbdbdb;
    padding: 0 20px;
    display: inline-block;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    border: none;
    margin: 0;
    margin-bottom: 20px;
    border-radius: 4px;
    outline: none;
    width: 100%;
  }

  &__save-cta {
    background-color: #dbdbdb;
    padding: 0 20px;
    display: inline-block;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    border: none;
    margin: 0;
    margin-bottom: 40px;
    border-radius: 4px;
    outline: none;
    width: 50%;
  }

  &__cancel-cta {
    width: 50%;
    height: 40px;
    line-height: 40px;
    margin-bottom: 40px;
    display: inline-block;
    text-align: center;

    a {
      display: inline-block;
      cursor: pointer;
    }
  }

  &__section {
    &--last-child {
      border-bottom: none;
    }

    &-title {
      margin-bottom: 20px;
    }
  }

  &__border {
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #dbdbdb;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    box-sizing: content-box;
    height: 0;
  }

  &__checkbox-group {
    position: relative;
    height: 31px;
    margin-bottom: 30px;
  }

  &__form {
    &-group {
      margin-bottom: 30px;
    }

    &-label {
      display: block;
      margin-bottom: 10px;
    }
  }

  .form {
    &__group {
      label {
        width: 100%;
      }
    }
  }

  &__close-modal {
    .pop-up {
      &__title {
        display: block;
        width: 100%;
        margin-top: 0;
        padding-bottom: 15px;
      }

      &__content {
        padding: 25px;
        max-width: 600px;
      }

      &__header {
        margin-bottom: 30px;
        padding: 0;
      }

      &__body {
        padding: 0;
      }
    }
  }

  &__close-accnt-text {
    margin-bottom: 20px;
  }

  &__close-account {
    &__footer {
      margin-top: 20px;
    }
  }

  &__delete-section {
    padding: 20px;
    text-align: center;

    a {
      background-color: #dbdbdb;
      padding-left: 20px;
      padding-right: 20px;
      text-transform: uppercase;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .2);
      border-radius: 4px;

      &:hover {
        background-color: #bebebe;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .account-details {
    margin: 50px 10px 0;

    &__edit-cta {
      float: right;
      width: auto;
    }

    &__save-cta {
      width: auto;
      margin: 0 10px;

      &--float {
        float: right;
      }

      &--for-modal {
        width: 50%;
        margin: 0;
      }
    }

    &__cancel-cta {
      width: auto;

      &--float {
        float: right;
      }

      &--for-modal {
        width: 50%;
        padding: 0 20px;
      }
    }

    &__section {
      margin-left: 8.3333%;
      width: 83.33333%;

      &-head, &-footer {
        &:after {
          content: '';
          display: table;
          clear: both;
        }
      }

      &-footer {
        float: right;
        margin-right: 8.3333%;
      }
    }

    &__checkbox-group {
      margin-left: 50%;

      &--shipping {
        float: right;
        margin-top: -46px;
      }

      &--for-modal {
        margin-left: 0;
      }
    }

    &__form {
      &-label {
        width: 50%;
        padding: 0 10px;
        display: inline-block;
      }

      &-value {
        width: 50%;
        padding: 0 10px;
        display: inline-block;
      }
    }

    .form {
      &__group {
        position: relative;

        label {
          width: 50%;
          display: inline-block;
          padding: 0 10px;
        }
      }

      &__label-hint {
        position: absolute;
        width: 50%;
        text-align: right;
        top: 20px;
        left: -10px;
      }

      &__input[type=text], &__input[type=password], select {
        width: 50%;
        display: inline-block;
        padding: 0 10px;
      }

      .error {
        margin-left: 50%;
      }
    }

    &__close-account {
      .form {
        &__group {
          label {
            width: 16.66667%;
          }
        }

        &__input[type=password] {
          width: 83.33333%;
          display: inline-block;
          padding: 0 10px;
        }

        .error {
          margin-left: 16.66667%;
        }
      }
    }
  }
}

@media only screen and (min-width: 980px) {
  .account-details {
    &__section {
      margin-left: 16.66667%;
      width: 66.66667%;

      &-footer {
        margin-right: 16.66667%;
      }
    }

    .form {
      &__group {
        position: relative;

        label {
          width: 41.66667%;

        }
      }

      &__label-hint {
        width: 41.66667%;
        text-align: right;
        top: 20px;
        left: -10px;
      }

      &__input[type=text], &__input[type=password], select {
        width: 58.33333%;

      }

      .error {
        margin-left: 41.66667%;
      }
    }

    &__checkbox-group {
      margin-left: 41.66667%;

      &--for-modal {
        margin-left: 0;
      }
    }

    &__close-account {
      .form {
        &__group {
          label {
            width: 16.66667%;
          }
        }

        &__input[type=password] {
          width: 83.33333%;
        }

        .error {
          margin-left: 16.66667%;
        }
      }
    }
  }
}
