#press-room-as {
  .press-kit {
    &__date,
    &__description,
    &__view-kit-link,
    &__view-para,
    &__download-link {
      font-family: $lato-regular;
    }
    &__info {
      min-height: auto;
      margin-bottom: 0;
      @include breakpoint(mobile) {
        max-width: 64%;
      }
    }
    &__image-block {
      @include breakpoint(mobile) {
        max-width: 36%;
      }
    }
    &__block {
      margin: auto;
    }
    &__row {
      border-bottom: 1px solid $cool-dark-grey;
      padding-bottom: 50px;
      margin: 0 0 50px 0;
      @include breakpoint(mobile) {
        margin: 0 0 30px 0;
        padding-bottom: 40px;
      }
      @include breakpoint(tab) {
        margin: 0 0 39px 0;
        padding-bottom: 50px;
      }
    }
    &__date {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 20px;
      @include breakpoint(mobile) {
        font-size: 14px;
        margin-bottom: 10px;
      }
      @include breakpoint(tab) {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    &__title {
      color: $text-dark-grey;
      font-family: $playfair-regular;
      font-size: 36px;
      letter-spacing: -2px;
      line-height: 45px;
      margin-bottom: 20px;
      width: 455px;
      @include breakpoint(mobile) {
        font-size: 18px;
        letter-spacing: -0.67px;
        line-height: 30px;
        margin-bottom: 10px;
        width: 185px;
      }
      @include breakpoint(tab) {
        font-size: 36px;
        letter-spacing: -2px;
        line-height: 45px;
        margin-bottom: 20px;
        width: 476px;
      }
    }
    &__description {
      color: $dark-gray;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      margin-bottom: 20px;
      width: 455px;
      @include breakpoint(mobile) {
        font-size: 14px;
        letter-spacing: -0.65px;
        line-height: 22px;
        margin-bottom: 10px;
        width: 185px;
      }
      @include breakpoint(tab) {
        font-size: 14px;
        letter-spacing: -0.65px;
        line-height: 22px;
        margin-bottom: 20px;
        width: 476px;
      }
    }
    &__view-kit-icon {
      height: 15px;
      width: 15px;
    }
    &__view-kit-icon-block {
      @include breakpoint(mobile) {
        margin: 0;
      }
    }
    &__view-kit-icon-block > a,
    &__view-kit-icon-block > a:hover {
      color: $text-medium-dark-grey;
      text-decoration: none;
    }
    &__view-kit-link {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      display: flex;
      align-items: center;
      &:before {
        content: "";
        background-image: url(../images/External.svg);
        width: 15px;
        height: 15px;
        margin-right: 8px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: inline-block;
      }
    }
    &__view-kit-link-show {
      margin-left: 0;
    }
    &__image {
      height: 320px;
      width: 240px;
      border: none;
      @include breakpoint(mobile) {
        height: 164px;
        width: 123px;
      }
      @include breakpoint(tab) {
        height: 270px;
        width: 202px;
      }
    }
    &__view-para {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      padding-top: 30px;
      margin: 0;
      padding-bottom: 0;
    }
    &__download-icon {
      height: 16.76px;
      width: 16px;
    }
    &__download-block {
      padding-top: 15px;
    }
    &__download-link {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 8px;
    }
  }
  .collapsible-accordion {
    margin-bottom: 0;
    .Collapsible__trigger {
      padding: 40px 0;
      @include breakpoint(tab) {
        padding: 29.5px 0;
      }
      @include breakpoint(mobile) {
        padding: 29.5px 0;
      }
    }
    .Collapsible {
      border-bottom: 1px solid $cool-dark-grey;
      &__contentInner {
        padding-bottom: 39.5px;
      }
    }
    .Collapsible__trigger {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
      @include breakpoint(mobile) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
      @include breakpoint(tab) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
    .collapsible__trigger--open {
      padding: 40.5px 0 0 0;
    }
  }
  .collapsible-accordion .Collapsible__trigger .collapsible__header .plus {
    height: 10.72px;
    width: 10.72px;
    margin-top: 10px;
    @include breakpoint(mobile) {
      margin-top: 9px;
    }
    @include breakpoint(tab) {
      margin-top: 9px;
    }
    .line {
      background: $text-medium-dark-grey;
    }
    .line-1 {
      padding-right: 12px;
    }
    .line-2 {
      padding-left: 0.5px;
      margin-left: 4px;
    }
  }

  .press-room__main_pannel {
    .press-kit {
      .lazyload-wrapper.kf-lazyload-wrapper {
        &:nth-last-child(3) {
          @include breakpoint(desktop-small) {
            .press-kit__row {
              margin: 0 0 90px 0;
            }
          }
        }
      }
    }
  }
  .press-room__inner-container {
    .press-room__main_pannel {
      .press-kit {
        .product-list__load-more.gbh-data-layer {
          @include breakpoint(tab) {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
