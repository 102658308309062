.teaser.featured-services {
    max-width: 1600px;
    margin: 0 auto;

    @include breakpoint(mobile) {
        padding: 70px 0 70px;
    }

    @include breakpoint(tab) {
        padding: 0;
    }

    @include breakpoint(mobile) {
        padding: 50px 0 50px;
    }

    .cmp-teaser {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint (desktop-small) {
            padding: 70px 0;
        }

        @include breakpoint(mobile) {
            position: relative;
        }

        &__pretitle {
            @include breakpoint(mobile) {
                position: absolute;
                top: 0;
                padding-bottom: 5px;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include ann-describtion(.75rem, $lato-regular, 1rem);
                letter-spacing: .125rem;
                padding-bottom: 0.625rem;
                margin: 0;
                text-transform: uppercase;

                @include breakpoint (tab) {
                    padding-bottom: 10px;
                }
            }
        }

        &__content {
            max-width: 360px;
            box-sizing: content-box;
            margin: 43px 0 0 6.875rem;

            @include breakpoint(desktop-mid) {
                padding: 0 36px;
                margin: 0;
            }

            @include breakpoint(tab) {
                padding: 0 65px 0 2rem;
                width: 17.5rem;
                margin: 0;
                position: static;
            }

            @include breakpoint (mobile) {
                width: 100%;
                padding: 0 1.125rem;
                box-sizing: border-box;
                margin: 0;
                max-width: 100%;
                position: static;
            }
        }

        &__title {
            @include breakpoint(mobile) {
                position: absolute;
                top: 19px;
                width: 16.5625rem;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include headingtitle_h3;
                margin-bottom: 4.375rem;
                padding-bottom: 0;

                @include breakpoint(tab) {
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                    letter-spacing: -0.073125rem;
                    padding-right: 0;
                    margin-bottom: 15px;
                }

                @include breakpoint(mobile) {
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                    letter-spacing: -0.073125rem;
                    padding-right: 3rem;
                    margin-bottom: 2.5rem;
                    position: static;
                }
            }
        }

        &__image {
            @include breakpoint(mobile) {
                width: 100%;
                margin-top: 93px;

                .cmp-teaser__image {
                    margin-top: 0;
                }
            }

            .cmp-image {
                @include breakpoint(desktop-small) {
                    min-width: 830px;
                }

                @include breakpoint(tab) {
                    min-width: 406px;
                }

                @include breakpoint(mobile) {
                    min-width: 375px;
                }

                &__image {
                    right: 0;
                    width: 100%;
                }
            }
        }

        &__description {
            margin-bottom: 2.5rem;
            width: 23rem;

            @include breakpoint(tab-max) {
                width: 100%;
            }

            @include breakpoint(tab) {
                margin-bottom: 0;
            }

            @include breakpoint(mobile) {
                padding-right: 0;
                margin: 2.5rem 0;

            }

            p:first-child {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0.44px;
                margin-bottom: 20px;
                color: $text-dark-grey !important;
                padding: 0;

                @include breakpoint(tab-max) {
                    margin-bottom: 10px;
                }
            }

            p {
                @include ann-describtion(16px, $lato-regular, 24px);
                letter-spacing: normal;

                @include breakpoint(tab-max) {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: normal;
                    padding-bottom: 0;
                }
            }
        }

        &__action-link.gbh-secondary-button {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            padding: 0 27px;
            height: 60px;
            align-items: center;
            font-family: $lato-regular;

            @include breakpoint(tab-max) {
                height: 50px;
                margin: 0;
            }
        }
    }

    &.content-left {
        .cmp-teaser {
            display: flex;
            flex-direction: row-reverse;

            @include breakpoint(mobile) {
                flex-direction: column;
            }
        }
    }

    &.content-right {
        @include breakpoint(mobile) {
            padding: 50px 0 50px;
        }

        .cmp-teaser {
            display: flex;
            flex-direction: row;

            @include breakpoint(mobile) {
                flex-direction: column;
            }

            &__pretitle {
                @include breakpoint(tab) {
                    padding: 0;
                    margin-bottom: .3125rem;
                }
            }

            &__content {
                margin-right: auto;
                margin-left: 7.5rem;
                max-width: 28.1875rem;

                @include breakpoint(tab-max) {
                    margin-left: 0;
                }

                @include breakpoint(tab) {
                    max-width: 17.5rem;
                    padding: 0 3.875rem 0 4.0625rem;
                }

                @include breakpoint(mobile) {
                    padding-right: 1.125rem;
                    padding-left: 1.125rem;
                    max-width: 100%;
                }
            }

            &__title {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    padding-right: 3.75rem;

                    @include breakpoint(tab) {
                        @include headingtitle_h5;
                        margin-bottom: 1.375rem;
                        padding-right: 0;
                    }

                    @include breakpoint(mobile) {
                        padding-right: 4rem;
                        margin-bottom: 1.875rem;
                    }
                }
            }

            &__description {
                margin-bottom: 2.5rem;

                @include breakpoint(tab) {
                    margin-bottom: 1.875rem;
                    padding-right: 0;
                }
            }

            &__image {
                @include breakpoint(mobile) {
                    margin-top: 3.375rem;
                }
            }
        }
    }
}

.bg-warm-gray-medium {
    .featured-services {
        &.content-left {
            .cmp-teaser {
                @include breakpoint (desktop-extra-lite) {
                    padding: 70px 0;
                }

                @include breakpoint (tab) {
                    padding: 50px 0;
                }

                .cq-dd-image {
                    &.cmp-teaser__image {
                        .cmp-image {
                            @include breakpoint (desktop-extra-lite) {
                                min-width: 888px;
                            }

                            .cmp-teaser__image {
                                &.cmp-image__image {
                                    @include breakpoint (desktop-extra-lite) {
                                        max-width: 888px;
                                    }

                                    @include breakpoint (tab) {
                                        min-width: 438px;
                                    }
                                }
                            }
                        }
                    }
                }
                &__content{
                    position: initial;
                    background-color: transparent!important;
                }
            }
        }
    }
}