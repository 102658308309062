.associate-membership {
    max-width: 400px;
    
    &__header {
        display: flex;
        column-gap: 100px;
    }
    &__block{
        width: 100%;
        margin-bottom: 40px;
        padding: 10px;

        &__close-icon {
            cursor: pointer;
            order: 1;
            float: right;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
    &__title {
        order: 0;
        font-family: $font-regular;
        font-size: 36px;
        line-height: 1.22;
        letter-spacing: -1.7px;
        color: $color-slate;
    }
    &__description {
        margin: 15px 0px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
    }
    &__description1 {
        margin: 15px 0px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
    }
    &__button{
        margin-top: 20px;
        padding-bottom: 20px;
    }
    &__terms{
        padding-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        p{
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
            color: $color-dark-gray;
            a{
                text-decoration: underline;
                font-family: $font-regular;
                font-size: 16px;
                letter-spacing: -0.55px;
                line-height: 24px;
                color: $color-dark-gray;
                &:hover {
                    text-decoration: underline;
                    color: $color-black;
                }
            }
        }
        span {
            margin-right: 10px;
            text-decoration: underline;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.65px;
            cursor: pointer;
        }
        .checkbox_default{
            display: none;
        }
    }
    &__policy-term-error{
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-error-red;
        padding-top:10px
    }
    &__policies{
        display: flex;
        font-family: $font-regular;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        text-decoration: underline;
        a {
            margin-right: 20px;
            text-decoration: underline;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
            &:hover {
                text-decoration: underline;
                color: $color-black;
            }
        }
    }
    .policies{
        text-decoration: underline;
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        margin: 20px 0;
    }
    .protectedByGoogle{
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
    }
    &__retired{
        display: flex;
        font-family: $font-regular;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        a {
            margin-right: 20px;
            text-decoration: underline;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
            &:hover {
                text-decoration: underline;
                color: $color-black;
            }
        }
    }
    
    .success-alert {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        &__modal {
                background: $color-black;
                color: $color-white;
                margin: 0px;
                position: relative;
                width: 1020px;
                padding: 50px;
                text-align: center;
                max-height: 20px;
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
            }
        } 
    }

    
  
}
