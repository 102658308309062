.trade-membership-main {
  max-width: 780px;
  margin: 90pt auto;
  color: $slate;

  @include breakpoint(tab) {
    padding: 0 118px;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  legend {
    margin-bottom: 0px;
  }

  .input-field {
    &__value {
      label {
        color: $text-light-grey !important;
      }
      input {
        color: $text-dark-grey !important;
      }
    }
    &__error-message {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }

  .contact-title {
    display: grid;
    margin-bottom: 30px;
    .contact-title {
      &__fields {
        display: flex;
        justify-content: space-between;
        font-family: $lato-regular;
        color: $text-medium-dark-grey;
        .question {
          font-family: $lato-regular;
          color: $text-medium-dark-grey;
          @include text-style(26px, 36px, 0px);
          margin: 0;
        }
        .required-fields {
          @include text-style(14px, 20px, 0px);
          padding-top: 10px;
          flex: none;
        }
      }
    }
  }

  .contact-info-form {
    .row.mx-0 {
      .primary-address-checkbox {
        margin-bottom: 18px;
      }
      .pr-10 {
        padding-right: 10px;
      }
      .pl-10 {
        padding-left: 10px;
      }
      @include breakpoint(tab-max) {
        .user-address {
          padding-right: 0 !important;
        }
        .user-aptFloor {
          padding-left: 0 !important;
        }
        .user-city {
          padding-right: 0 !important;
        }
        .user-state {
          padding-left: 0 !important;
        }
      }
      @include breakpoint(mobile) {
        .user-fname {
          padding-right: 0 !important;
        }
        .user-lname {
          padding-left: 0 !important;
        }
        .user-country {
          padding-right: 0 !important;
          margin-bottom: 20px;
        }
        .user-phone {
          padding-left: 0 !important;
        }
      }
      &:last-child {
        .input-field {
          margin-bottom: 0;
        }
      }
    }
  }

  .optional-form {
    margin: 60px 0;
    border-top: 1px solid $dark-gray;
    .question {
      margin-bottom: 20px;
      margin-top: 60px;
      @include text-style(26px, 36px, 0px);
      font-family: $lato-regular;
      color: $text-medium-dark-grey;
    }
    .description {
      margin-bottom: 20px;
      font-family: $lato-regular;
      color: $text-medium-dark-grey;
      @include text-style(16px, 24px, 0px);
    }
    .description-list {
      list-style-position: inside;
      padding-left: 10px;
      margin-bottom: 40px;
      margin-left: 0px;
      li {
        font-family: $lato-regular;
        color: $text-medium-dark-grey;
        @include text-style(16px, 26px, 0px);
      }
    }
    .business-form-wrapper {
      padding-top: 20px;
      @include breakpoint(mobile) {
        padding-top: 10px;
      }
    }
    .upload-notes {
      p {
        font-family: $lato-regular;
        color: $text-medium-dark-grey;
        @include text-style(16px, 24px, 0px);
        margin-bottom: 8px;
      }
      &__supported-formats-value {
        font-family: $lato-bold;
        font-weight: normal;
      }
      &__supported-size-value {
        font-family: $lato-bold;
        font-weight: normal;
      }
    }
    .trade-customer-radio-option {
      display: grid;
      margin-top: 40px;
      .radio-wrapper {
        display: flex;
        .trade-customer {
          padding-right: 20px;
        }
        .radio {
          margin-right: 20px;
        }
      }
    }
    .asset-upload-tax-container {
      .upload-image-container {
        background-color: $half-white;
        padding: 19px 30px;
        margin: 40px 0;
        &__error-msg {
          color: $color-red-alert;
          font-family: $font-lato;
          @include text-style(13px, 20px, 0.2px);
          text-align: center;
        }
        @include breakpoint(tab-max) {
          padding: 30px;
        }
        .drag-and-drop-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          padding: 50px 0 20px;
          color: $slate;
          @include breakpoint(tab-max) {
            padding: 0;
          }
          .drag-and-drop-text-large {
            @include text-style(26px, 36px, 0px);
            color: $text-medium-dark-grey-small;
            font-family: $lato-regular;
            @include breakpoint(tab-max) {
              display: none;
            }
          }
          .drag-and-drop-text-small {
            @include text-style(16px, 24px, 0px);
            color: $text-light-grey;
            font-family: $lato-regular;
            margin: 20px 0 25px;
            @include breakpoint(tab-max) {
              display: none;
            }
          }
          .drag-and-drop-upload-btn {
            .button,
            .kf-react-button {
              padding: 0;
              border: none;
              height: auto;
              button {
                @include text-style(12px, 24px, 2.5px);
                color: $color-medium-blue;
                font-family: $lato-bold;
                text-transform: uppercase;
                padding: 12px 27px 14px;
                height: 50px;
                border: 1px solid $slate;
              }
            }
          }
        }
        .contact-us-image-preview {
          @include breakpoint(tab-max) {
            margin-bottom: 30px;
          }
        }
      }
      .tax-request-container {
        .tax-request-footer {
          &__message {
            font-family: $lato-regular;
            color: $text-medium-dark-grey;
            @include text-style(16px, 24px, 0px);
          }
        }
        .checkbox {
          margin: 18px 0 0;
        }
      }
      .contact-us-image-preview {
        margin-top: 0;
        height: 120px;
        display: flex;
        position: relative;
        .preview-wrapper {
          width: 90pt;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $light-white;
        }
        textarea {
          font-family: $lato-regular;
          color: $text-dark-grey;
          flex: 1;
          border: none;
          resize: none;
          padding: 15px 30px 15px 20px;
        }
        .text-count {
          position: absolute;
          right: 10px;
          top: 75%;
        }
        .image-delete {
          img {
            width: 11.06px;
            height: 10.71px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
        }
        .image-box {
          height: 100%;
          width: 90pt;
          cursor: pointer;
        }
      }
    }
  }
  .trade-membership-footer {
    padding-top: 60px;
    border-top: 1px solid $dark-gray;
    .Button_buttonWrapper__9Vvy_ .button.disabled,
    .Button_buttonWrapper__9Vvy_ .kf-react-button.disabled {
      display: block;
      background-color: $cool-dark-grey;
    }
    .trade-membership-footer {
      &__checkbox {
        margin-bottom: 40px;
        .terms-conditions-check {
          display: flex;
          margin-bottom: 20px;
          .Checkbox_checkboxWrapper__KI6CF {
            width: unset;
            .checkbox {
              margin-bottom: 0px;
            }
          }
          @include breakpoint(tab) {
            flex-direction: column;
          }
          @include breakpoint(mobile) {
            flex-direction: column;
          }
          a {
            font-family: $lato-regular;
            color: $text-medium-dark-grey;
            @include text-style(16px, 24px, 0px);
            text-decoration: underline;
            @include breakpoint(tab) {
              white-space: nowrap;
              padding-left: 25px;
            }
            @include breakpoint(mobile) {
              white-space: nowrap;
              padding-left: 25px;
            }
            @include breakpoint(desktop-small) {
              margin-left: 5px;
            }
          }
        }
      }
      &__response-recieve-time {
        font-family: $lato-regular;
        color: $text-medium-dark-grey;
        @include text-style(16px, 24px, 0px);
      }
    }
    &__submit-button {
      margin-top: 60px;
      height: 50px;
      background-color: $color-medium-blue-cap;

      &.disabled {
        background-color: $cool-dark-grey;
      }
    }
    .recaptcha-links {
      margin-top: 20px;
      font-family: $lato-regular;
      color: $text-medium-dark-grey;
      @include text-style(13px, 20px, 0.2px);
      a {
        font-family: $lato-regular;
        color: $text-medium-dark-grey;
        @include text-style(13px, 20px, 0.2px);
      }
    }
    .trade-membership-policies {
      margin-top: 30px;
      a {
        margin-right: 20px;
        @include text-style(13px, 20px, 0.2px);
        font-family: $lato-regular;
        color: $text-medium-dark-grey;
        text-decoration: underline;
      }
    }
    .Button_buttonWrapper__9Vvy_ .button.large,
    .Button_buttonWrapper__9Vvy_ .kf-react-button.large {
      @include text-style(12px, 24px, 2.5px);
      font-family: $lato-bold;
      height: 50px;
      text-transform: uppercase;
      margin-top: 0px;
      background-color: $color-medium-blue-cap;
      color: $color-white;
      font-weight: normal;
      &.disabled {
        background-color: $cool-dark-grey;
      }
    }
  }
  .checkbox {
    margin: 0;
    &__border {
      width: 20px !important;
      height: 20px !important;
      border-radius: 5px !important;
      .checkbox {
        &__marker {
          width: 20px !important;
          height: 20px !important;
          border-radius: 5px !important;
        }
      }
    }
    .checkbox_default {
      font-family: $lato-regular;
      color: $text-medium-dark-grey !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0px !important;
    }
    input:checked ~ span {
      font-family: $lato-regular;
      color: $text-medium-dark-grey !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0px !important;
      .checkbox {
        &__marker {
          background-color: $color-light-blue !important;
        }
      }
    }
  }
}

.trade-membership-confirmation-message-wrapper {
  background-color: $color-light-grey;
  .trade-membership {
    &__success-message {
      position: relative;
      display: flex;
      padding: 110px 60px 106px 0;
      gap: 240px;
      @include breakpoint(tab-max) {
        display: block;
        padding: 70px 0 50px;
      }
      .message-content {
        width: 100%;
        @include breakpoint(tab-max) {
          margin-bottom: 30px;
        }
        .success-message-body {
          p {
            font-family: $playfair-display-regular;
            color: $text-dark-grey;
            @include text-style(54px, 62px, -1px);
            @include breakpoint(tab-max) {
              @include text-style(28px, 34px, -1.17px);
            }
          }
        }
      }
      .trade-membership-response-message {
        .response-message-body {
          p {
            font-family: $lato-regular;
            color: $text-medium-dark-grey;
            @include text-style(16px, 24px, 0px);
            margin-bottom: 40px;
          }
        }
        .start-shopping-btn {
          padding: 12px 27px 14px;
          background-color: $color-medium-blue-cap;
          color: $color-white;
          @include text-style(12px, 24px, 2.5px);
          font-family: $lato-bold;
          text-transform: uppercase;
          font-weight: bold;
          width: 190px;
          height: 50px;
          @include breakpoint(tab-max) {
            padding: 0 100px;
            width: 340px;
          }
          @include breakpoint(mobile) {
            width: 100%;
          }
        }
      }
    }
  }
}

.trade-membership {
  &__success {
    &--hidden {
      display: none;
    }
  }
}
