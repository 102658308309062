@keyframes scroll-left {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0)
    }
  
    to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
    }
  }
  
  .animate__scroll-left {
    -webkit-animation-name: scroll-left;
    animation-name: scroll-left;
  }
.wrapper {
    width: 100%;
    background-color: #f7f7f7;
        &__tab-devider {
            @extend .pdp-container;
            &::before{
                content: "";
                display: block;
                border-top: 1px solid $cool-dark-grey-cap;
            }
            @include breakpoint(mobile) {
                margin: 0 18px;
            }
        }
    }
   .pdfList {
       display: flex;
       flex-direction: column;
       width: 40%;
   
       .files {
           margin-bottom: 20px;
           width: 100%;
           @include breakpoint(mobile) {
            margin-bottom: 0px;
           }
       }
   }
   
   .tabNav{
       ul{
           margin: 0;
           padding: 0;
           display: flex;
           @include annsacks-desk(gap, 40, 60);
           transition: scroll-left 0.3s ease;
          li {
             list-style-type: none;
             width: auto;
             text-transform: uppercase; 
             color: $text-light-grey-cap;
             font-family: $lato-regular;
             font-size: 14px;
             letter-spacing: 2.33px;
             line-height: 16px;
             text-align: center;
             cursor: pointer;
             padding-bottom: 3px;
             white-space: nowrap;
           }
           .active {
               border-bottom: 1px solid  $text-dark-grey;
               color: $text-dark-grey;
          }
       }
   }
   .tabContent{
       margin-top:60px;
       .skuinfo-row{
        border-bottom: 1px solid $filter-border-line;
        align-items: center;
        padding-bottom: 25px;
        @include breakpoint(mobile){
            padding-bottom: 45px;
        }
       }
       .content-row, .skuinfo-row{
        display: flex;
        justify-content: space-between;
        gap: 70px;
        margin-bottom: 40px;
        @include breakpoint(tab-max){
            display: block;
        }
        
        @include breakpoint(mobile){
            justify-content:unset;
            margin-bottom: 30px;
        }
        .price-list{
            padding-top: 0;
        }
       }
       .width50, .col-text, .skuinfo-text {
           max-width: 870px;
           width: 100%;
            @include breakpoint(tab-max){
                max-width: 100%;
                margin-bottom: 29px;
            }
        }
        .col-text{
            p{
                color:$text-dark-grey;
                font-family: $lato-regular;
                font-size: 22px;
                letter-spacing: normal;
                line-height: 32px;
            }
        }
        .pdfList, .skuprice-info{
            max-width: 713px;
            width: 100%;
            @include breakpoint(tab-max){
                max-width: 100%;
                display:block;
            }
            .price-list{
               @include breakpoint(desktop-extra-sm){
                    justify-content: flex-start;
                }
                @include breakpoint(tab-max){
                    justify-content: flex-start;
                }  
            }
        }
    .skuprice-info{
        align-items: flex-end;
        .price-list__price{
            padding-left: 20px;
            font-size: 16px;
            line-height: 24px;
            color: $text-dark-grey;
        }
    }
    .tabHeading{
        color:  $text-dark-grey;
        font-family: $lato-regular;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 20px;
        &.curated-heading{
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 3.5px;
            text-transform: uppercase;
        }
    }
       
   
       ul{
           margin-bottom: 40px;
           @media screen and (max-width: 992px){
            margin-bottom: 30px;
           }
           li{
               color:  $text-dark-grey;
               font-family: $lato-regular;
               font-size: 22px;
               letter-spacing: 0;
               line-height: 32px;
               margin-bottom: 20px;
           }
       }
       .note {
           color: $text-medium-dark-grey;
           font-family: $lato-regular;
           font-size: 14px;
           letter-spacing: 0;
           line-height: 20px;
           .label{
            text-transform: uppercase;
           }
       }
       .ptext{
           color:  $text-dark-grey;
           font-family: $lato-regular;
           font-size: 22px;
           letter-spacing: 0;
           line-height: 32px;
       }
       .uploadFile {
           box-sizing: border-box;
           height: 80px;
           width: 100%;
           border: 1px solid $dark-grey-medium;
           display: flex;
           justify-content: space-between;
           align-items: center;
           padding: 0 40px 0 30px;
           border-radius: 2px;
           cursor: pointer;
           @media screen and (max-width: 992px){
               padding: 0 19.5px 0 20.5px;
           }
   
           .pdfWrapper {
               display: flex;
               justify-content: space-between;
               align-items: center;
               color: $text-dark-grey;
               img{
                   margin-right: 15px;
               }
           }
        }
       .wetFlooringTitle {
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
            margin-top: 40px;
            @include breakpoint(mobile) {
                font-size: 18px;
                line-height: 28px;
                margin-top: 30px;
            }
            @include breakpoint(tab) {
                font-size: 18px;
                line-height: 28px;
                margin-top: 30px;
            }
       }
       .wetFlooringInfo {
            margin-top: 15px;
       }
       .link-block{
        margin-bottom: 20px;
        display: inline-block;
        width: 100%;
        &:last-child{
            margin-bottom: 0;
        }
       }
       .wetFlooringImg, .linkicon {
            height: 15px;
            width: 15px;
       }
       .wetFlooringLink, .link {
            color: $text-medium-dark-grey;
            font-family: $lato-regular;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-left: 8px;
            text-decoration: underline;
       }
       .customNotes {
            color: $text-medium-dark-grey;
            font-family: $lato-regular;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin-top: 20px;
            @include breakpoint(tab-max) {
                margin-bottom: 30px;
            }
        }
   }
   
   @include breakpoint(tab) {
       .wrapper {
           width: 100%;
       }
       .tabWrapper {
            padding: 50px 32px 60.85px 32px;
        }
       .pdfList { 
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            a{
                margin-bottom: 0;
            }
        }
       .tabContent {
           margin-top: 50px;
           flex-direction: column;
           ul{
               li{
                   font-size: 18px;
                   line-height: 28px;
               }
               margin-bottom: 30px;
           }
           .width50 {
               width: 100%;
           }
       }
   }
   
   @include breakpoint(mobile) {
       .wrapper {
           width: 100%;
       }
       .tabWrapper {
            padding: 49.66px 0 50.65px 0;
        }
       .pdfList { width: 100%;}
       .tabNav{
        ul{
            overflow: auto;
            &::-webkit-scrollbar{
                 width:0;
            }
        }
        }
       .tabContent {
           margin-top: 50px;
           padding: 0 18px;
           flex-direction: column;
           ul{
               li{
                   font-size: 18px;
                   line-height: 28px;
               }
               margin-bottom: 30px;
           }
           .width50, .uploadFile {
               width: 100%;
           }
       }
   }
   @include breakpoint(ipad-landscape) {
       .wrapper {
           padding: 50px 0;
           width: 100%;
       }
       .pdfList { width: 100%;}
       .tabContent {
           margin-top: 50px;
           flex-direction: column;
           ul{
               li{
                   font-size: 18px;
               }
           }
           .width50 {
               width: 100%;
           }
           .uploadFile {
               width: 500px;
           }
       }
   }
   .tabWrapper-list-type {
        list-style-type: disc;
        list-style-position: inside;
   }
   .tabWrapper-no-list-type {
        list-style-type: none;
   }
   .price-listTooltipIcon{
    cursor: pointer;
   }
   .price-list__list-tooltip-wrapper{
    position: absolute;
    bottom: 18px;
    @include breakpoint(desktop-small) {
        right: -18px;
    }
   }

.title-info{
    &.tech-info{
        margin-bottom: 65px;
    }
    &.feature-info{
        margin-bottom: 65px;
        .info-row{
            margin-bottom:65px;
            display: inline-block;
            width: 100%;
            &:last-child{
                margin-bottom: 0;
            }
            @include breakpoint(tab-max){
                margin-bottom: 60px;
            }
        }
    }
    .info-row-content{
        margin-bottom: 50px;
        @include breakpoint(tab-max){
            margin-bottom: 80px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    ul{
        margin-bottom: 30px;
        margin-left: 20px;
        @include breakpoint(tab-max){
            margin-bottom: 25px;
        }
        li{
            &:last-child{
                margin-bottom: 0;
            }
        }
        
    }
    .title{
        color:$text-dark-grey;
        font-family: $lato-regular;
        font-weight: normal;
        font-size: 24px;
        letter-spacing: 3.5px;
        line-height: 36px;
        margin-bottom: 20px;
        text-transform: uppercase;
        @include breakpoint(tab-max){
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 2.57px;
        }
    }
}

.tech-info{
    .info-row{
        display: flex;
        column-gap: 70px;
        @include breakpoint(tab-max){
            display: block;
        }
        .cont-col{
            max-width: 1186px;
            width: 100%;
            @include breakpoint(tab-max){
                max-width: 100%;
                margin-bottom: 25px;
            }
        }
        .pdf-col{
            max-width: 533px;
            width: 100%;
            @include breakpoint(tab-max){
                max-width: 100%;
            }
        }
    }
    .files{
        margin-bottom: 25px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.files{
    .heading{
        font-size: 14px;
        font-family: $lato-regular;
        line-height: 20px;
        letter-spacing: 0;
        color: $text-medium-dark-grey;
        margin-bottom: 15px;
    }
}
.wetfloor{
    .wetFlooringTitle{
        margin-bottom: 15px;
    }
}
.drawing_pdf{
    .uploadFile{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}