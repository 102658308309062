.ann-main-nav {
    .cmp-tabs__tabpanel--active {
        .globalnavigation {
            .gbh-full-w-menu {
                height: auto;
                min-height: 100%;
                overflow: hidden;
            }
        }
    }
}


.globalnavigation {
    .gbh-full-w-menu {
        width: 100%;
        background:$white;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 9;
        top:0;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        transition: all 0.3s linear;
        height: 0;
        overflow: hidden;
        min-height: 0;
        @include breakpoint(desktop-extra-lite-sm) {
            height: 100%;
            top: 0;
        }

        .gbh-full-w-menu__inner {
            width: 100%;
            height: 508px;
            background-color: $white;
            border-top: 1px solid $extra-light-gray;
            display: flex;
            flex-direction: row;
            position: relative;
            
            @include breakpoint(desktop) {
                overflow: hidden;
            }
            @include breakpoint(desktop-lg) {
                padding-left: 60px;
                height: 392px;
            }
            @include breakpoint(desktop-max) {
                padding-left: 36px;
            }
            @include breakpoint(desktop-extra-lite-sm) {
                padding-left: 0;
                background-color: $charcoal;
                height: 100vh;
                max-height: 1000px;
            }
            @media only screen and (min-width: 1381px) and (max-width: 1600px) {
                padding-left:110px;
            }
            .gbh-full-max-width{
                max-width: 1380px;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                width: 100%;
                @media only screen and (min-width: 1381px) and (max-width: 1600px) {
                    margin: 0;
                }
            }

            .gbh-full-w-menu__left-cont {
                display: flex;
                flex-direction: column;
                width: 52.5%;
                padding: 70px 0;

                @include breakpoint(desktop-lg) {
                    padding: 50px 0;
                    width: calc(100% - 513px);
                }

                @include breakpoint(desktop-extra-lite-sm) {
                    width: 100%;
                    padding: 0;
                }

                .gbh-full-w-menu__left-cont__top {
                    display: flex;
                    flex-direction: row;
                    height: 100%;

                    .gbh-full-w-menu__left-cont__top__cont {
                        width: 50%;
                        padding: 0 60px 0 0;
                        border-right: 1px solid $light-gray;
                        margin-right: 60px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @include breakpoint(desktop-small) {
                            padding: 0 40px 0 0;
                            margin-right: 40px;
                        }

                        @include breakpoint(desktop-lg) {
                            width: 60%;
                        }

                        @include breakpoint(desktop-xlg) {
                            width: 60%;
                        }

                        @include breakpoint(desktop-extra-lite-sm) {
                            display: none;
                        }
                    }
                }

                .gbh-full-w-menu__left-cont__description {
                    width: 100%;

                    p {
                        margin-bottom: 0;
                    }
                }
            }
            .gbh-full-w-menu__right-img{
                width: 40.625%;
                position: absolute;
                right: 0;
                height: 100%;
                @include breakpoint (desktop-extra-lite-sm){
                    display: none;
                }
                .cmp-image{
                    height: 100%;
                    @include breakpoint(desktop-extra-lite-sm){
                        height: auto;
                    }
                }
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .gbh-dd-right-img {
                width: 650px;
                overflow: hidden;

                img {
                    width: 100%;
                }

                @include breakpoint(desktop-lg) {
                    width: 408px;

                    img {
                        width: 100%;
                    }
                }

                @include breakpoint(desktop-extra-lite-sm) {
                    display: none;
                }
            }

            h2 {
                font-size: 3.125rem;
                font-family: $lato-regular;
                line-height: 3.375rem;
                color: $charcoal;
                letter-spacing: -0.1875rem;
                width: 360px;

                @include breakpoint(desktop-small) {
                    width: 248px;
                }

                @include breakpoint(desktop-lg) {
                    font-size: 2.25rem;
                    line-height: 2.625rem;
                    letter-spacing: -0.0675rem;
                    width: auto;
                }

            }

            .gbh-promenu-close-btn {
                &.icon-Close {
                    color: $white;
                    font-size: 16px;
                    @include breakpoint(desktop-extra-lite-sm) {
                        margin: 21px 32px 30px;
                    }
                    @include breakpoint(mobile-portrait) {
                        margin: 26px 18px 30px;
                    }
                }
            }

            p {
                font-size: 1rem;
                font-weight: normal;
                line-height: 1.5rem;
                letter-spacing: -0.034375rem;
                color: $dark-gray;
                font-family: $lato-regular;

            }

            ul.gbh-full-w-menu__left-cont__menu-list {
                width: 50%;
                height: auto;
                padding: 0;
                padding: 0;
                height: auto;
                background-color: transparent;
                box-shadow:none;
                border:none;
                @include breakpoint(desktop-xlg) {
                    width: 40%;
                }

                @include breakpoint(desktop-lg) {
                    width: 40%;
                }

                @include breakpoint(desktop-extra-lite-sm) {
                    width: 100%;
                    height: 100vh;
                    padding: 76px 32px;
                    background:$white;
                }
                @include breakpoint(mobile) {
                    padding: 76px 18px;
                }

                li {
                    color: $dark-gray;
                    font-size: 1.375rem;
                    padding: 0;
                    line-height: 2rem;
                    font-family: $lato-regular;
                    cursor: pointer;
                    letter-spacing: -0.0625rem;
                    &:first-child{
                        @include breakpoint(desktop-extra-lite-sm){
                            padding-top:3.625rem;
                        }
                    }

                    &:hover,
                    &.active {
                        background-color: transparent !important;
                        color: $charcoal;
                        font-family: $helvetica-medium;
                        @include breakpoint(desktop-extra-lite-sm) {
                            color: $white;
                        }
                    }

                    a {
                        color: $dark-gray;
                        font-size: 1.375rem;
                        padding: 0;
                        line-height: 2rem;
                        letter-spacing: -0.0625rem;
                        font-family: $lato-regular;

                        @include breakpoint(desktop-extra-lite-sm) {
                            font-size: .875rem;
                            color: $white;
                            letter-spacing: .125rem;
                            line-height: 1rem;
                            font-family: $lato-regular;
                            color:$text-medium-dark-grey;
                            padding-bottom:2.5625rem;
                        }

                        &:hover,
                        &.active {
                            background-color: transparent !important;
                            color: $charcoal;
                            font-family: $helvetica-medium;

                            @include breakpoint(desktop-extra-lite-sm) {
                                color: $white;
                            }
                        }
                    }
                    img{
                        @include breakpoint(desktop-extra-lite-sm){
                            display: none;
                        }
                    }
                }
                .gbh-mob-back-btn {
                    @include breakpoint(desktop-extra-lite-sm) {
                        margin:3.4375rem 0 2.125rem;
                    }
                }
            }
        }
    }
}