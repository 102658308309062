// In-page Navigation
.teaser{
    &.in-page-navigation {
    box-shadow: 0px 0.625rem 1.25rem 0px rgba(0,0,0,0.07);
    padding: 0;
    line-height: 0;   
    z-index: 0 !important; 
    .cmp-teaser {
        &__content {
            max-width: 1820px;
            margin: 0 auto;
            overflow: hidden;
            background: transparent;
            padding: 0 36px;
            @include breakpoint(tab) {
                max-width: unset;
                padding: 0 4.8125rem;
            }
            @include breakpoint(mobile) {
                max-width: unset;
                padding: 0 .625rem;
            }
        }
        &__action-container {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            overflow-x: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @include breakpoint(tab){
                padding: 0 0.625rem;
            }
            @include breakpoint(tab-max) {
                justify-content: flex-start;
                align-items: center;
            }
            .cmp-teaser__action-link {
                @include ann-describtion(0.875rem, $lato-regular, 1rem);
                color: $text-light-grey;
                letter-spacing: 0.145625rem;
                margin-right: 1.25rem;
                white-space: nowrap;
                text-transform: uppercase;
                box-shadow: none;
                padding: 1.375rem 1.25rem;
                position: relative;
                transition:0.6s ease-in-out;
                background:none;
                border: 0;
                &:last-child {
                    margin: 0;
                }
                &::after{
                    content:"";
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    transform: scaleX(0);
                    transition: transform 0.6s ease-in-out;
                }            
                &.active {
                    color: $text-dark-grey;
                    &::after{
                        border-bottom: 2px solid $color-light-blue;
                        transform:scaleX(1);
                    }
                }
            }
        }
    }    
    }
}

//Sticky Navigation
.sticky-navigation{
    position: sticky;
    top: 0;
    z-index: 8;
}
// Sticky Overlap Issue
.searchbar-open {
    &.modal-open{
        .in-page-navigation{
            z-index: 0;
        }
        .inspiration-list__sticky-nav-wrap{
            z-index: 0;
        }
    }
}