.blog-listing-pagination-container{
.blog-listing-pagination{
.products-per-page{
  height: 1pc;
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 10px;
    letter-spacing: 1.7px;
    line-height: 1pc;

    .disabled {
      color: $text-medium-dark-grey;
  }

  ul {
    li {
      display: inline-block;
      text-transform: uppercase;
      font-family: $lato-regular;
      line-height: 1pc;
      margin-right: 15px;
      font-size: 9pt;
    letter-spacing: 2px;
      cursor: pointer;
      color: $text-medium-dark-grey;
        &.disabled{
          pointer-events: none;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4.5px;
        -webkit-text-decoration-color: $cool-dark-grey;
        text-decoration-color: $cool-dark-grey;
        }
        &.disabled:first-child {
          text-decoration: none;
          font-size: 10px;
          letter-spacing: 1.7px;
          color: $text-medium-dark-grey;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  

}
}}