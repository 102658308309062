.no-gutters > [class*="col-"].search-side-panel__search-product-tile {
  background-color: $cool-light-grey;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.utilitywidgets .utility-widgets .search-widget {
  .search-side-panel {
    &__container {
      @include breakpoint(desktop-lg) {
        width: 76.85% !important;
        transition: all 0.6s ease-out;
      }
      background-color: $cool-light-grey;

      .inner-scroller-section {
        .highlight-text {
          font-family: $lato-bold !important;
          font-size: 18px !important;
          letter-spacing: 0 !important;
          color: $text-dark-grey;
        }
        @include breakpoint(desktop-small) {
          padding: 0px 26px 147px 42px !important;
          overflow-y: auto;
          height: 100vh;
          width: 100%;
          div.inspiration-tile {
            padding-top: 44.5px;
            padding-bottom: 44.5px;
          }
        }
        @include breakpoint(desktop-large) {
          padding: 0px 51px 147px;
          div.inspiration-tile {
            padding-top: 44.5px;
            padding-bottom: 44.5px;
          }
        }
        @include breakpoint(mobile) {
          div.inspiration-tile {
            padding-top: 29.5px;
            padding-bottom: 29.5px;
          }
          .search-side-panel__header-container {
            width: 100%;
          }
          width: auto;
          overflow-x: hidden;
          padding: 0 18px 60px 0px !important;
          position: inherit;
          margin: 0 0 0 18px !important;
        }
        @include breakpoint(tab) {
          padding: 0px 32px 50px;
          overflow-x: hidden;
          div.inspiration-tile {
            padding-top: 29.5px;
            padding-bottom: 29.5px;
          }
        }
        .plp-promo-banner {
          &__layout {
            @include breakpoint(desktop-small) {
              height: 448px;
            }
            @include breakpoint(desktop-large) {
              height: 648px;
            }
            @include breakpoint(tab-max) {
              height: 638px;
            }
            @include breakpoint(mobile-portrait) {
              height: 218px;
            }
          }
          &__image {
            height: 100%;
            img {
              height: 100%;
            }
          }
        }
        .product-listing {
          &__row {
            &.row-cols-3 {
              @include breakpoint(desktop-large) {
                margin-right: -28px;
              }
            }
          }
          &__tile {
            @include breakpoint(mobile-portrait) {
              &.promo-col-3 {
                margin-left: -3px;
                padding-right: 13px;
                margin-bottom: 10px;
              }
              &-col {
                margin: 0;
              }
            }
            @include breakpoint(desktop-large) {
              padding-right: 28px;
            }
            @include breakpoint(desktop-lg) {
              padding-right: 29px;
            }
          }
        }
      }
    }
    &__header {
      background: $cool-light-grey;
      top: 0;
      @include breakpoint(tab-max) {
        left: 0;
      }
    }

    &__search-filed {
      width: 100%;
      ::placeholder {
        font-size: 36px;
      }
      input {
        padding-top: 0;
        &[type="search"]::-webkit-search-cancel-button {
          display: none;
        }
      }
      @include breakpoint(tab-max) {
        margin-top: 51px;
      }
    }

    &__search-control {
      color: $text-dark-grey;
      font-family: $font-regular-base;
      font-size: 36px;
      letter-spacing: 0;
      line-height: 36px;
      background-color: $cool-light-grey;
      padding-top: 20px;
      width: 548px;
      padding-right: 20px;
      @include breakpoint(desktop-small) {
        width: 600px;
        height: 100px;
      }
    }

    &__search-btn {
      top: -10px;
      color: $color-medium-blue;
      font-family: $font-regular-base;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 24px;
      text-align: center;
      box-sizing: border-box;
      height: 50px;
      width: 116px;
      border: 1px solid $color-medium-blue;
      border-radius: 1px;
      padding: 10px;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    &__suggestion-list {
      li {
        span {
          span {
            color: $text-medium-dark-grey;
            font-family: $font-regular-base;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 28px;
          }
        }
      }
    }

    &__recent-list {
      list-style: none;
      padding: 0;
      margin-left: 0px;

      li {
        color: $text-medium-dark-grey;
        font-family: $font-regular-base;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        margin-bottom: 15px;

        span {
          color: $text-medium-dark-grey;
          font-family: $font-regular-base;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 28px;
          position: inherit;
        }
      }
    }

    &__title {
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 48px;
    }

    &__result-total {
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      @include breakpoint(tab-max) {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }

    &__header-container {
      gap: 0px;
      color: $cool-dark-grey-cap;
      @include breakpoint(tab-max) {
        padding-top: 0px;
      }
    }

    &__search-product-tile {
      .product-tile {
        .product-tile__image {
          width: 100%;
        }

        .product-tile__details {
          word-break: unset;

          .product-tile__title {
            width: 191px;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.4px;
          }

          .product-tile__price {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.8px;
          }
        }
      }
    }

    &__clearAll-button {
      font-size: 14px;
      font-family: $lato-regular;
      line-height: 20px;
      letter-spacing: normal;
      color: $text-medium-dark-grey;
    }

    &__content {
      margin-top: 110px;
      @include breakpoint(tab-max) {
        margin-top: 10px;
      }
      .animate {
        &__fadeInUp {
          .product-tile {
            &__image-tile {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 860px) and (orientation: landscape) {
    .no-gutters > [class*="col-"].search-side-panel__search-product-tile {
      padding-left: 0px !important;
    }

    .search-side-panel {
      &__search-control {
        font-size: 26px;
      }

      &__result-total {
        font-size: 14px;
        line-height: 20px;
      }

      &__suggestion-list {
        li {
          color: $text-light-grey;
        }
      }

      &__search-product-tile {
        .product-tile {
          .product-tile__price {
            height: 38px;
            margin-right: 25px;
            width: 100%;
          }
        }
      }

      &__title {
        margin-bottom: 28px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .search-side-panel {
      &__container {
        width: 100%;

        .inner-scroller-section {
          padding: 0;
          overflow-x: hidden;
          margin: 18px 18px 0;
        }
      }

      &__header-container {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .search-side-panel {
      &__search-product-tile {
        .product-tile {
          .product-tile__title {
            width: 150px;
          }
        }
      }

      &__container {
        width: 100%;
      }

      &__title {
        margin-bottom: 28px;
      }

      &__header-container {
        width: 90%;
      }
      .inner-scroller-section {
        div.inspiration-tile {
          padding-top: 29.5px;
          padding-bottom: 29.5px;
        }
      }
    }
  }

  .inner-scroller-section {
    .search-side-panel {
      &__suggestion-list {
        li {
          font-family: $lato-regular;
          color: $text-medium-dark-grey;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0;
          margin-bottom: 16px;
        }
      }

      &__search-control {
        font-family: $lato-regular;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0;
        color: $text-dark-grey;
        @include breakpoint(desktop-small) {
          padding: 0px 20px 0px 5.5px;
          margin-bottom: 27px;
          height: 100%;
        }
      }

      &__title {
        font-size: 12px;
        color: $text-light-grey;
        font-family: $lato-regular;
        letter-spacing: 2px;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 50px;
        @include breakpoint(tab-max) {
          margin-bottom: 30px;
          margin-top: 40px;
        }
      }

      &__search-btn {
        font-family: $lato-bold;
        font-size: 12px;
        letter-spacing: 2.5px;
        line-height: 24px;
        padding: 12px 27px 14px 27px;
      }

      &__result-container {
        @include breakpoint(tab-max) {
          margin-top: 5px;
        }
        .search-side-panel {
          &__title {
            margin-bottom: 30px;
            @include breakpoint(tab-max) {
              margin-top: 40px;
            }
          }
        }
      }
    }
    .inspiration-tile {
      &__img {
        @include breakpoint(desktop-small) {
          height: 127px;
          width: 127px;
        }
        @include breakpoint(desktop-large) {
          height: 145px;
          width: 145px;
        }
        @include breakpoint(tab-max) {
          height: 136px;
          width: 136px;
        }
        @include breakpoint(mobile-portrait) {
          height: 80px;
          width: 80px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .search-side-panel {
      &__container {
        background-color: $cool-light-grey;
      }

      &__search-control {
        width: 100%;
        padding-right: 20px;
      }

      &__header-container {
        gap: 0px;
        margin-top: 85px;
      }
    }
  }

  .search-side-panel__search-all-button,
  .search-side-panel__search-btn {
    font-family: $lato-bold;
    @include text-style(12px, 24px, 2.5px);
    text-align: center;
    color: $color-medium-blue-cap;
    text-transform: uppercase;
    font-weight: bold;
    padding: 12px 27px 14px;
    height: 50px;
  }
  .search-result {
    .product-listing__tile-col {
      @include breakpoint(tab) {
        margin: auto;
      }
      @include breakpoint(mobile) {
        margin: auto;
      }
    }
  }
}

.searchbar-open {
  .search-side-panel__inspiration-tile-wrppper {
    width: 100%;
  }
}
