.carousel-navigation-with-arrows{
    padding:0;
    width:100%;
    .cmp-carousel{
        @include breakpoint (desktop-small) {
            max-width: 100%;
        }
        .carousel-header-info{
            padding:0;
        }
        &.cmp-carousel-slick{
            .slick-initialized{
                margin:0;
                .slick-arrow{
                    width:3.125rem;
                    height:3.125rem;
                    top:50%;
                    opacity:0.3;
                    border:1px solid $white;
                    @include breakpoint(tab-max){
                        display:none !important;
                    }
                    &:hover{
                        opacity:1;
                    }
                    &.slick-prev{
                        max-width: 1920px;
                        left: 50%;
                        z-index:9;
                        @include breakpoint(desktop-large){                            
                            margin-left: -910px;
                        }
                        @include breakpoint(desktop-xxxlg){                            
                            margin-left: -47.3959%;
                        }
                        @include breakpoint(desktop-lg){
                            margin-left: -43.125%;
                        }
                    }
                    &.slick-next{
                        max-width: 1920px;
                        right: 50%;
                        z-index:9;
                        @include breakpoint(desktop-large){                            
                            margin-right: -910px;
                        }
                        @include breakpoint(desktop-xxxlg){                            
                            margin-right: -47.3959%;
                        }
                        @include breakpoint(desktop-lg){
                            margin-right: -43.125%;
                        }
                    }
                }
                .slick-list{
                    margin-left:0;
                    padding:0 !important;
                }
                .slick-slide{
                    margin-right:0;
                }
                .slick-dots{
                    position:absolute;
                    margin:0 0 45px;
                    @include breakpoint (tab) {
                        top: 390px !important;
                    }
                    @include breakpoint(tab-max){
                        margin:0 0 30px;
                        bottom:unset;
                    }
                    li{
                        button{
                            border:1px solid $white;
                            opacity:0.5;
                        }
                        &.slick-active{
                            button{
                                background:$white;
                                opacity:1;
                            }
                        }
                    }
                }
            }
        }
    }
    &.fc-stone{
        .cmp-carousel{
            &.cmp-carousel-slick{
                .slick-initialized{
                    .slick-arrow{
                        border:1px solid $text-light-grey;
                        &.slick-prev:before, &.slick-next:before{
                            color: $text-light-grey;
                        }
                        &:hover{
                            opacity:1;
                        }
                        &.slick-disabled{
                            opacity: 0.3;
                            cursor: not-allowed;
                            &:hover{
                                opacity:0.3;
                            }
                        }
                    }
                    .slick-dots{                        
                        li{
                            button{
                                border:1px solid $text-light-grey;
                            }
                            &.slick-active{
                                button{
                                    background:$text-light-grey;
                                }
                            }
                        }
                    }
                }
            }
        }
        .full-bleed-hero-banner{
            .cmp-teaser{
                &__title{
                    h1,h2, h3,h4,h5,h6,p{
                        color:$text-dark-grey;
                    }
                }
                &__description{
                    p{
                        color: $text-medium-dark-grey;
                    }
                }
                &__action-link{
                    color:$color-light-blue;
                    background:linear-gradient(90deg,$color-light-blue 33.33333%,transparent 0 66.66667%,$color-light-blue 0) var(--d,0) 100%/300% 1px no-repeat;
                    border-color:$color-light-blue;
                }
            }
        }  
    }
}
.full-bleed-second-image{
    padding:0;
    .cmp-carousel{
        max-width: unset;
        &.cmp-carousel-slick{
            .carousel-header-info{
                padding:0;
            }
            .slick-initialized{
                margin-bottom:0;
                .slick-slide{
                    margin-right:1.25rem;
                    @include breakpoint(tab){
                        margin-right:0;
                    }
                    @include breakpoint(mobile){
                        margin-right:0;
                    }
                    @media (min-width: 1920px){
                        width: 1430px !important;
                    }
                }
                .slick-list{
                    padding-left:10rem;
                    padding-right:19.375rem;
                    max-width:inherit;
                    @include breakpoint(desktop-lg){
                        padding-left:0;
                        padding-right:9.375rem;
                    }
                    @include breakpoint(tab){
                        padding-right:9.8125rem;
                        padding-left:0;
                    }
                    @include breakpoint(mobile){
                        padding:0;
                    }
                    .slick-track{
                        display: flex;
                    }
                }
                .slick-arrow {
                    width: 50px;
                    top: 50% !important;
                    height: 50px;
                    border: 1px solid rgba(255,255,255,0.3);
                    z-index:9;
                    &::before {
                        justify-content: center;
                    }
                    &:hover{
                        border:1px solid $white;
                    }
                    @include breakpoint(tab-max){
                        display:none !important;
                    }
                    &.slick-next{
                        right:27.5rem;
                        @include breakpoint(desktop-lg){
                            right:17.5rem;
                        }
                    }
                    &.slick-prev{
                        left:270px;
                        @include breakpoint(desktop-lg){
                            left:6.875rem;
                        }
                    }
                    &.slick-disabled{
                        cursor:not-allowed;
                        &:before{
                            opacity:1;
                        }
                    }
                }
                .slick-dots{
                    right: 27.5rem;
                    text-align: right;
                    position: absolute;
                    width: auto;
                    margin:0 0 70px;
                    @include breakpoint(desktop-large){
                        margin:0 0 4.375rem;
                    }
                    @include breakpoint(desktop-lg){
                        right:17.5rem;
                    }
                    @include breakpoint(tab-max){
                        width:100%;
                        right:0;
                        text-align: center;
                        margin:0 0 40px;
                    }
                    li{
                        button{
                            border-color: $white !important;
                            background:transparent;
                        }
                        &.slick-active{
                            button{
                                background:$white !important;
                            }
                        }
                    }
                    &:hover{
                        @include breakpoint(desktop-small){
                            li{
                                button{
                                    border-color:$text-medium-dark-grey !important;
                                }
                                &.slick-active{
                                    button{
                                        background:$text-medium-dark-grey !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .slick-arrow:hover{
                    .slick-dots{
                        @include breakpoint(desktop-small){
                            li{
                                button{
                                    border-color:$text-medium-dark-grey !important;
                                }
                                &.slick-active{
                                    button{
                                        background:$text-medium-dark-grey !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .slick-slide{
                    .full-bleed-image-hover-content .cmp-teaser__content{
                        opacity:0;
                    }
                }
                .slick-slide.slick-current.slick-active{
                    .full-bleed-image-hover-content .cmp-teaser__content{
                        @include breakpoint(tab-max){
                            opacity:1;
                        }
                    }
                }
                &:hover{
                    .slick-arrow{
                        @include breakpoint (desktop-small) {
                            opacity: 1;
                            &.slick-disabled{
                                border: 1px solid rgba(255,255,255,0.3) !important;
                            }
                        }
                    }
                    .slick-slide.slick-current.slick-active{
                        .full-bleed-image-hover-content .cmp-teaser__content{
                            @include breakpoint(desktop-small){
                                opacity:1;
                                bottom:0;
                                height: 278px;
                            }
                        }
                    }
                    .slick-dots{
                        @include breakpoint(desktop-small){
                            li{
                                button{
                                    border-color:$white !important;
                                }
                                &.slick-active{
                                    button{
                                        background:$white !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.bg-warm-gray-light, .bg-white{
    .full-bleed-second-image{
        .cmp-carousel{
            &.cmp-carousel-slick{
                .slick-initialized{
                    &:hover{
                        .slick-dots{
                            @include breakpoint(desktop-small){
                                li{
                                    button{
                                        border-color:$text-medium-dark-grey !important;
                                    }
                                    &.slick-active{
                                        button{
                                            background:$text-medium-dark-grey !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }   
}
