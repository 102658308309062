@import '../base/variables';

//gbh code below
.kf-promo-banner {
    margin-top: 0px;
    @include breakpoint(tab-max){
        height:100%;
    }
    @include breakpoint(tab){
        min-height: 474px
    }
    @include breakpoint(mobile){
        min-height:441px;
    }
    &__layout {
        position: relative;
        .kf-promo-banner__image{
            position: absolute;
            height:100%;
            width:100%;
            top:0;
            left:0;
            grid-column:1 !important;
            grid-row: 1 !important;
        }
        .kf-promo-banner__info {
            z-index: 1;
            padding:0 ;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction:column;
            align-self: center;
            grid-column:1 !important;
            grid-row: 1 !important;
            justify-content: flex-start !important;
        }
        .kf-promo-banner__desc {
            max-width:266px;
            margin-bottom:20px !important;
            @include breakpoint(desktop-extra-lite-sm){
                margin-bottom:29px !important;
            }
            @include breakpoint(tab){
                margin-bottom:25px !important;
            }
            @include breakpoint(mobile){
                margin-bottom:15px !important;
            }
            *{
                font-size:36px;
                letter-spacing:-2px;
                line-height:normal;
                font-family: $playfair-regular;
                @include breakpoint(desktop-extra-lite-sm){
                    font-size:27px;
                    line-height:36px;
                    letter-spacing:-1.5px;
                }
            }
            .kf-promo-banner__cta{
                font-size:12px;
                line-height:24px;
                letter-spacing:2.5px;
                // font-family:$lato-Bold;
            }
        }
        &.--col-1 {
            &.top-left{
                .kf-promo-banner__info{
                    padding:40px 0 0 40px;
                    @include breakpoint(desktop-extra-lite-sm){
                        padding:25px 0 0 25px;
                    }
                }
            }
            &.top-center,&.dead-center{
                .kf-promo-banner__info{
                    padding-top:145px;
                    text-align: center;
                    left:50%;
                    margin-left:-133px;
                    @include breakpoint(desktop-extra-lite-sm){
                        padding-top:50px;
                    }
                    @include breakpoint(tab-max){
                        margin-left: -50%;
                        align-items: center;
                    }
                }
                .kf-promo-banner__desc{
                    margin:0 auto;
                }
            }
            &.bottom-left{
                .kf-promo-banner__info{
                    bottom:0;
                    padding:0 0 40px 40px;
                    justify-content: end !important;
                    @include breakpoint(desktop-extra-lite-sm){
                        padding:0 0 25px 25px;
                    }
                }
            }
        }
        &.--col-2 {
            &.top-left{
                .kf-promo-banner__info{
                    padding:55px 0 0 55px;
                    @include breakpoint(desktop-extra-lite-sm){
                        padding:35px 0 0 35px;
                    }
                    @include breakpoint(mobile){
                        padding:25px 0 0 25px;
                    }
                }
            }
            &.top-center,&.dead-center{
                .kf-promo-banner__info{
                    align-items: center;
                    justify-content: center !important;
                    text-align: center;
                    width:100%;
                    height:100%;

                }
                .kf-promo-banner__desc{
                    margin:0 auto;
                }
            }
            &.bottom-left{
                .kf-promo-banner__info{
                    bottom:0;
                    padding:0 0 55px 55px;
                    justify-content: end !important;
                    @include breakpoint(desktop-extra-lite-sm){
                        padding:0 0 35px 35px;
                    }
                    @include breakpoint(mobile){
                        padding:0 0 25px 25px;
                    }
                }
            }
        }
        &.--col-3 {
            &.top-left{
                .kf-promo-banner__info{
                    padding:70px 0 0 70px;
                    @include breakpoint(desktop-extra-lite-sm){
                        padding:35px 0 0 35px;
                    }
                    @include breakpoint(mobile){
                        padding:25px 0 0 25px;
                    }
                }
            }
            &.top-center,&.dead-center{
                .kf-promo-banner__info{
                    align-items: center;
                    justify-content: center !important;
                    text-align: center;
                    width:100%;
                    height:100%;

                }
                .kf-promo-banner__desc{
                    margin:0 auto;
                }
            }
            &.bottom-left{
                .kf-promo-banner__info{
                    bottom:0;
                    padding:0 0 70px 70px;
                    justify-content: end !important;
                    @include breakpoint(desktop-extra-lite-sm){
                        padding:0 0 35px 35px;
                    }
                    @include breakpoint(mobile){
                        padding:0 0 25px 25px;
                    }
                }
            }
        }
    }
    &__image {
        width: 100%;
        position: relative;
        picture {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__info {
        display: block;
        background-color: transparent;
        .button.small, .kf-react-button.small{
            padding: 12px 27px 14px 27px;
            // font-family: $lato-Bold;
            min-height:50px;
        }
    }
    .kf-promo-banner__desc{
        margin-bottom: 18px;
        p{
            color: $white;
            font-family: $playfair-regular;
            font-size: 36px;
            letter-spacing: -2px;
            line-height: 48px;
           
        }
    }
   .Button_buttonWrapper__9Vvy_ {
    .kf-promo-banner__cta {
        &.ghost {
            color: $white;
            border-color: white;
            background-color: transparent;
            font-size: 12px;
            letter-spacing: 2.5px;
            text-transform: uppercase;
            &:hover {
                color: $charcoal;
                background-color: $white;
            }
        }
    }
}
}
.slick-slide {
    .slick-active {
        .slick-current{
            width: 100% !important;
        }
    }
}
.promotest {
    .modal-body{
        a {
            text-decoration: none;
            color: $color-link;
            &:hover {  
                text-decoration: none;
            }  
        }
        
    }
    .modal-header{
        .close {
            position: unset !important;
        }
    } 
}
.pb-sitewide-messaging {
    &.carousel {
        margin: 0;
        padding: 0 36px;
        @include breakpoint(tab) {
            padding: 0 2rem;
        }
        @include breakpoint(mobile) {
            padding: 0 1.125rem;
        }
        .cmp-carousel {
            width: 52.359%;
            margin: 0 auto;
            @include breakpoint(tab) {
                width: 84.3%;
            }
            @include breakpoint(mobile) {
                width: 88.41%;
            }
            &.cmp-carousel-slick {
                .slick-initialized {
                    &.slick-slider {
                        display: flex;
                        justify-content: center;
                    }                    
                    .slick-list { 
                        margin: 0;
                        padding: 0;
                        .slick-slide {
                            margin: 0;
                        }
                    }
                    .slick-arrow {
                        z-index: 9;
                        border: none;
                        top: 50%;
                        opacity: 1;
                        &.slick-disabled {
                            opacity: 0.75;
                            cursor: not-allowed;                            
                        }
                        &.slick-prev {
                            left: 0%;
                        }
                        &.slick-next{
                            right: 0%;
                        }
                    }
                }
            }
        }        
    }
    &.carousel-slick {
        .slick-initialized {
            .slick-dots {
                width: 0;
                height: 0;
                left: -999999px;
                position: absolute;
            }
            .slick-prev:before, 
            .slick-next:before{
                font-size: 13px;
            }
        }        
        .carousel-header-info {
            padding: 0;
        }
        .slick-dotted.slick-slider {
            margin: 0;
        }
        .promo-banner {
            margin: 0;
        }
    }    
    .promo-banner-message {
        .cmp-promo__description {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            @include breakpoint(mobile) {
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
            }
            h1, h2, h3, h4, h5, h6, p {
                @include ann-describtion (1rem, $lato-regular, 1.5rem);
                letter-spacing: normal;
                margin: 0;
                padding: .8125rem 1.875rem .8125rem 0;
                @include breakpoint(mobile) {
                    @include ann-describtion (0.8125rem, $lato-regular, 1.25rem);
                    padding: .875rem 0 .3125rem;
                    width: 100%;
                    letter-spacing: .0125rem;
                }
            }
            .cmp-promo__action-link {
                &.btn {
                    @extend .cmp-teaser__action-link;
                    background: none;
                    border: 0;
                    border-bottom: 1px solid transparent;
                    margin-right: 1.875rem;
                    font-size: .8125rem;
                    line-height: 1.25rem;
                    letter-spacing: .0125rem;
                    @include breakpoint(mobile) {
                        margin-bottom: .875rem;
                    }
                }
                &.btn:last-child {
                    margin-right: 0;
                    text-transform: capitalize;
                }
            }
        }        
    }
    &.bg-warm-gray-medium {
        .promo-banner-message {
            background: transparent !important;
            .cmp-promo__description {
                h1, h2, h3, h4, h5, h6, p {
                    color: $text-dark-grey;                    
                }
                .cmp-promo__action-link {
                    &.btn {
                        color: $color-light-blue;
                        border-color: $color-light-blue;
                    }
                }
            }            
        }
        .slick-prev:before, .slick-next:before {
            color: $text-dark-grey;
        }        
    }
    &.bg-dark-blue-two {
        .promo-banner-message {
            .cmp-promo__description {
                h1, h2, h3, h4, h5, h6, p {
                    color: $white;                    
                }
                .cmp-promo__action-link {
                    &.btn {
                        color: $white;
                        border-color: $cool-light-grey;
                    }
                }
            }
        }        
        .slick-prev:before, .slick-next:before {
            color: $white;
        }
    }
    &.bg-medium-plumb {
        background-color: $color-medium-light-plump;
        .promo-banner-message {
            .cmp-promo__description {
                h1, h2, h3, h4, h5, h6, p {
                    color: $white;                    
                }
                .cmp-promo__action-link {
                    &.btn {
                        color: $white;
                        border-color: $cool-light-grey;
                    }
                }
            }
        }        
        .slick-prev:before, .slick-next:before {
            color: $white;
        }
    }
}
// promo Banner POPup model style start
.product-list__tile-col--full-width .kf-promo-banner.\--fixed-height {
    @include breakpoint(tab) {
        height: 568px;
    }
}
.product-list__tile {
    @include breakpoint(tab) {
        flex: 0 0 50%;
    }
}
.product-list__tile-col--full-width .kf-promo-banner__layout.\--col-2 .kf-promo-banner__info, 
.product-list__tile-col--full-width .kf-promo-banner__layout.\--col-3 .kf-promo-banner__info {
    @include breakpoint(tab) {
        padding: 36px 0 0 35px;
    }
}
.kf-promo-banner__layout.\--col-1 .kf-promo-banner__info{
    padding:0;
}
.kf-promo-banner.\--fixed-height {
    @include breakpoint(desktop-xxxlg){
        max-height: 465px;
    }
    @include breakpoint(desktop-large) {
        max-height: 648px;
        height: 100%;
    }
    @include breakpoint(tab) {
        max-height: 474px;
        min-width: 371px;
    }
}
.row-cols-2 {
    .kf-promo-banner.\--fixed-height {
        @include breakpoint(mobile) {
            min-height: 216px;
            height: 216px;
        }
    }
}

.kf-promo-banner__layout.\--col-2 , 
.kf-promo-banner__layout.\--col-3  {
    @include breakpoint(tab-max) {
        min-height: inherit;
    }
}
