.cart-info {
  margin: 0 0 7.5rem 0;
  &__title,
  &__link {
    font-family: $font-regular;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.9px;
    color: $slate;
    margin: 30px 0 0 0;
    @media screen and (max-width: 1023px) {
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.8px;
    }
  }

  &__link {
    font-family: $font-medium;
    color: $charcoal;
    text-decoration: underline !important;
  }
  &__desc {
    span {
      font-family: $font-medium;
      color: $charcoal;
      cursor: pointer;
      text-decoration: underline;
    }
    font-family: $font-regular;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.9px;
    color: $slate;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.8px;
    }
  }

  @media screen and (max-width: 767px) {
    &__support {
      display: block;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    &__link {
      float: left;
      margin: 0;
    }
  }
  @media screen and (max-width: 1023px) {
    margin: 50px 0 60px 0;
  }
}
