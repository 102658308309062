.pdp-tile {
    background: $cool-light-grey-caps;

    &__main-block,
    &__header-block,
    &__image-block {
        padding: 0;
    }

    &__title {
        font-family: $playfair-regular;
    }

    &__description,
    &__details,
    &__product-title,
    &__product-amount,
    &__product-price-label,
    &__product-amount-currency,
    &__product-size-label,
    &__product-color-total,
    &__product-color-label,
    &__product-amount-currency {
        font-family: $lato-regular;
    }

    &__shop-all-btn {
        font-family: $lato-bold;
    }

    &__top-devider {
        border-top: 1px solid $cool-dark-grey-cap;
        width: 100%;
    }

    &__header-block-tile {
        margin-bottom: 0;
    }

    &__header-block-grout {
        margin-bottom: 0;
    }

    &__image-block {
        float: right;
        margin-bottom: 99.45px;
        padding-left: 67px;

        @include breakpoint(tab) {
            clear: both;
            margin: 90px 0 0 0;
            padding-left: 0;
            max-width: 100%;
            margin-bottom: 0;
            padding-left: 0;
        }

        @include breakpoint(mobile) {
            clear: both;
            margin: 95px 0 0 0;
            padding-left: 0;
            margin-bottom: 0;
            padding-left: 0;
            max-width: 100%;
        }

        @include breakpoint(ipad-landscape) {
            clear: both;
            margin: 90px 0 0 0;
            padding-left: 0;
            max-width: 100%;
            margin-bottom: 0;
            padding-left: 0;
        }
    }

    &__image-block-grout {
        margin-bottom: 51.09px;

        @include breakpoint(tab) {
            margin: 39.5px 0 0 0;
            margin-bottom: 0;
        }

        @include breakpoint(mobile) {
            margin: 44.22px 0 0 0;
            margin-bottom: 0;
        }

        @include breakpoint(ipad-landscape) {
            margin: 39.5px 0 0 0;
            margin-bottom: 0;
        }
    }

    &__title {
        color: $text-dark-grey;
        font-size: 54px;
        letter-spacing: -1px;
        line-height: 62px;
        margin-bottom: 80px;

        @include breakpoint(tab) {
            min-height: 34px;
            font-size: 28px;
            letter-spacing: -1.17px;
            line-height: 34px;
            margin-bottom: 50px;
        }

        @include breakpoint(mobile) {
            min-height: 34px;
            font-size: 28px;
            letter-spacing: -1.17px;
            line-height: 34px;
            margin-bottom: 61px;
        }

        @include breakpoint(ipad-landscape) {
            min-height: 34px;
            font-size: 28px;
            letter-spacing: -1.17px;
            line-height: 34px;
            margin-bottom: 50px;
        }
    }

    &__description {
        color: $text-dark-grey;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 15px;

        @include breakpoint(tab) {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 28px;
            margin-bottom: 30px;
        }

        @include breakpoint(mobile) {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 28px;
            margin-bottom: 15px;
        }
    }

    &__details {
        color: $text-medium-dark-grey;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;

        @include breakpoint(tab) {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
        }

        @include breakpoint(mobile) {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            width: 100%;
        }
    }

    &__shop-all-btn {
        height: 50px;
        width:236px;
        border: 1px solid $color-medium-blue-cap;
        border-radius: 1px;
        color: $color-medium-blue-cap;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 2.5px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        padding: 0;
        background:transparent;
        @include breakpoint(tab) {
            height: 50px;
        }

        @include breakpoint(mobile) {
            height: 50px;
            width: 308px;
        }
    }

    &__image-ul-list {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        column-gap: 20px;
        @include breakpoint(tab-max){
            column-gap: 20px;
        }
        @include breakpoint(mobile){
            padding:0 18px;
        }
    }

    &__image-details-block {
        max-width: 440px;
        width: 100%;
        cursor: pointer;
        @include breakpoint(tab-max){
            width: 100%;
        }
        @include breakpoint(mobile-portrait){
            width: 81.5vw;
        }
    }


    &__image-block .pdp-tile__image-ul-list .pdp-tile__image-li-list {
        opacity: 0;
        animation: scaleToOne 0.9s forwards;
        width: 50%;
        @include breakpoint(mobile-portrait){
            opacity: 1;
            animation: none;
            width: 100%;
        }
    }

    @for $i from 1 through 4 {
        &__image-block .pdp-tile__image-ul-list .pdp-tile__image-li-list:nth-child(#{$i}) {
            animation-delay: #{$i * 0.5}s;
            // background-color: rgb(88, 95, 85);
        }
    }

    //<--------
    &__product-outer-block {
        @include annsacks-desk(height, 420, 584);
        text-align: center;
        width: auto;
        margin-bottom: 16px;
        padding:58.5px 44px;
        background-color: $color-white;
        @include breakpoint(tab-max){
            padding:42px 30px;
            display: flex;
            align-items: center;
        }
    }

    &__product-image {
        height: 100%;
        width: 100%;
        @include breakpoint(mobile){
            height: 331px;
        }
    }

    &__product-description-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    &__product-title {
        color: $text-dark-grey;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26px;

        @include breakpoint(tab) {
            width: 50%;
        }
    }

    &__product-price-label {
        color: $text-medium-dark-grey;
        font-size: 11px;
        letter-spacing: 1.67px;
        line-height: 14px;
        text-align: right;

        @include breakpoint(mobile){
            visibility: hidden;
        }
    }

    &__product-amount {
        color: $text-dark-grey;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }

    &__product-amount-block {
        text-align: right;
    }

    &__product-price-label,
    &__product-amount {
        margin: 0;
        text-align: right;
    }

    &__product-amount-currency {
        color: $text-medium-dark-grey;
        font-size: 10px;
        letter-spacing: 1.67px;
        line-height: 16px;
        text-align: right;
        margin-left: 3px;

        @include breakpoint(tab) {
            margin-left: 5px;
        }

        @include breakpoint(mobile) {
            margin-left: 5px;
        }
    }

    &__product-size-block {
        clear: both;
        margin-top: 2px;

        @include breakpoint(tab) {
            margin-top: 5px;
        }

        @include breakpoint(mobile) {
            margin-top: 5px;
        }
    }

    &__product-color-block {
        margin-top: 25.56px;

        @include breakpoint(tab) {
            margin-top: 15px;
        }

        @include breakpoint(mobile) {
            margin-top: 15px;
        }
    }

    &__product-size-label {
        width: 171px;
        color: $text-medium-dark-grey;
        font-size: 16px;
        letter-spacing: 0.2px;
        line-height: 18px;
        @include breakpoint(tab-max){
            font-size:16px;
            line-height:24px;
        }
    }

    &__product-color-total,
    &__product-color-label {
        color: $text-medium-dark-grey;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 18px;
        @include breakpoint(tab-max){
            font-size:16px;
            line-height:24px;
            letter-spacing: normal;
            margin-top:0;
        }
    }

    &__product-color-label {
        margin-left: 3px;
    }

    &__image-ul-list {
        @include breakpoint(mobile) {
            display: flex;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar{
            opacity:0;
        }
    }

    &__need-link {
        color: $color-light-blue;
        font-family: $lato-regular;
        font-size: 11px;
        letter-spacing: 1.83px;
        line-height: 12px;
        text-transform: uppercase;
        text-decoration: none;
        padding-bottom: 8px;
        border-bottom: 1px solid $color-light-blue;
        white-space: nowrap;
    }

    &__need-link:hover {
        color: $color-light-blue;
        text-decoration: none;
        cursor: pointer;
    }
    &__need-link-block{
        @include breakpoint(tab-max){
            position: absolute;
            bottom:0;
        }
        @include breakpoint(tab){
            padding:30px 0 40px;
        }
        @include breakpoint(mobile){
            padding:30pc 0 45px;
        }
    }
}

//Slider Code Start
.pdp-tile-container-card-link {
    display: inline-block;
    margin: 0;
    text-align: start;
    width: 350px;
    margin-bottom: 115px;

    @include breakpoint(mobile) {
        width: 100%;
        margin-top: 40px;
        text-align: center;
        margin-bottom: 0;
        position: relative;
        z-index: 1;
    }

    @include breakpoint(tab) {
        width: 100%;
        margin-top: 40px;
        text-align: center;
        margin-bottom: 0;
    }

    @include breakpoint(ipad-landscape) {
        width: 100%;
        margin-top: 40px;
        text-align: center;
        margin-bottom: 0;
    }

    .normal {
        display: inline-block;
        position: relative;
        min-height: 11px;
        min-width: 11px;
        margin-right: 15px;
        box-sizing: border-box;
        border: 1px solid $text-light-grey;
        cursor: pointer;
    }

    .selected {
        display: inline-block;
        position: relative;
        min-height: 10px;
        min-width: 10px;
        margin-right: 15px;
        background-color: $text-light-grey;
        transition: background-color 0.6s ease;
        cursor: pointer;
    }
}

.pdp-tile-container-card-link-grout {
    margin-bottom: 116px;

    @include breakpoint(mobile) {
        margin-bottom: 0;
    }

    @include breakpoint(tab) {
        margin-bottom: 0;
    }
}

.pdp-tile .pdp-image-li-list__grout .pdp-tile__image-details-block {
    min-height: 643.53px;

    @include breakpoint(mobile) {
        min-height: 483px;
    }

    @include breakpoint(tab) {
        min-height: 483px;
    }
}