.pop-up {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;

  &__overlay {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
  }

  &__content {
    position: relative;
    width: auto;
    max-width: 800px;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 50px 2.5%;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    z-index: 3;
  }

  &__close {
    float: right;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    color: inherit;
    text-decoration: none;
    background: 0 0;
  }

  &__close:hover,
  &__close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  &__header {
    padding: 10px;
    border-bottom: solid 1px #ccc;
  }

  &__body {
    padding: 2px 16px;
  }

  &__footer {
    padding: 16px;
  }
}
