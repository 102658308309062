.searchbar-open .header-container {
  z-index: 1;
}
.brand-switcher {
  background: transparent;
  @include breakpoint(desktop-small) {
    display: flex;
    align-items: center;
    height: 45px;
  }
  .gbh-nav-panel {
    .navbar {
      padding: 8px 0;
      @include breakpoint(tab-max) {
        padding: 0;
        display: flex;
      }
    }
    @include breakpoint(desktop-extra-lite-lg){
      padding: 0 2.6%;
    }
    @include breakpoint(mobile) {
      overflow-x: scroll;
      padding: 0 18px;
    }
    @include breakpoint(tab) {
      padding: 0 32px;
    }
  }
  @include breakpoint(tab-max) {
    display: flex;
    align-items: center;
    width: 100% !important;
    z-index: 1;
    position: relative;
    height: 30px;
  }
  .navbar-nav.gbh-nav__list {
    flex-direction: row;
    margin: 0;
    @include breakpoint(tab-max) {
      line-height: 0;
    }
    a {
      cursor: pointer;
    }
  }

  .gbh-nav__list {
    .gbh-nav__item {
      padding: 0 1.25rem 0 0;
      @include breakpoint(tab) {
        line-height: 1.375rem;
      }
      &:first-child {
        @include breakpoint(mobile) {
          padding-left: 0;
        }
      }
      .gbh-nav--link {
        white-space: nowrap;
        font-family: $helvetica-medium;
        color: $cool-dark-grey-cap;
        font-size: 0.75rem;
        line-height: 1.0625rem;
        padding: 0;
        letter-spacing: -0.05px;
        text-transform: uppercase;
        text-decoration: none;
        @include breakpoint(tab-max) {
          font-size: 0.625rem;
          line-height: 0.6875rem;
        }
        &:hover {
          color: $white;
        }
        &.gbh-nav__link--active {
          color: $white;
          font-family: $helvetica-bold;
          letter-spacing: 0.2px;
        }
      }
    }
  }
  @include breakpoint(mobile) {
    .gbh-nav {
      overflow: scroll;
      padding: 0.2rem 0;
    }
    .navbar-nav.gbh-nav__list {
      flex-direction: row;
      margin: 0;
    }
  }
}
.sitewide-warning {
  margin-bottom: 0;
  button{
    border: none!important;
  }
}
.utilitynavheader {
  background-color: $color-dark-blue;
  .gbh-utility-navbar {
    ul li.gbh-nav__signin .utility-widgets .auth-widget {
      @include breakpoint(desktop-extra-lite-sm) {
        position: fixed;
        z-index: 999;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        max-height: none;
        font-size: 0;
        font-family: $lato-regular;
        background: $color-dark-blue;
        visibility: visible;
        padding: 15px 18px 15px;
        padding: 20px 2pc;
      }
      .auth-nav.auth-nav--active .auth-nav__menu {
        background-color: $color-dark-blue;
        &::after {
          background-color: $color-dark-blue;
          position: absolute;
          content: "";
          width: 100%;
          height: 100vh;
          top: 13px;
          z-index: -1;
          opacity: 0.6;
          animation: fadein 1s;
        }
      }
    }
    .gbh-nav__region {
      @include breakpoint(desktop-extra-lite-sm) {
        display: none !important;
      }
    }
  }
}
.header-container .cmp-container .brand-switcher .gbh-nav-panel .navbar {
  background-color: $color-dark-blue;
  padding: 0;
}

.utility-widgets.utility-widgets__brand-switcher {
  display: none;
  @include breakpoint(desktop-small) {
    div:nth-child(2) {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}

@media screen and (min-width: 1201px) {
  .auth-widget .auth-nav .auth-nav__menu:after {
    pointer-events: none;
  }
}