.details-dimensions {
  font-family: $font-regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.35px;
  width: 100%;
  color: $color-charcoal;

  &__dimensions {
    padding-bottom: 40px;
    border-bottom: 1px solid $color-light-gray;
    .details-dimensions__list {
      display: flex;
      justify-content: space-between;
    }
    .details-dimensions__data {
      img {
        padding-right: 10px;
      }
      .details-dimensions__text {
        font-family: $font-medium;
      }
      .details-dimensions__text-sm {
        font-family: $font-medium;
        display: none;
      }
      .details-dimensions__value {
        color: $color-dark-gray;
      }
      @media screen and (max-width: 480px) {
        .details-dimensions__text {
          display: none;
        }
        .details-dimensions__text-sm {
          display: inline-block;
          white-space: pre;
        }
      }
    }
  }

  &__details {
    padding-bottom: 40px;
    .details-dimensions__list {
      ul {
        padding-left: 15px;
        margin-left: 7px;
        margin-bottom: 0px;
      }
      .details-dimensions__list-item {
        color: $color-dark-gray;
        letter-spacing: -0.55px;
        padding-bottom: 10px;
        list-style-position: outside;
        &:last-child {
          padding-bottom: 0px;
        }
      }
    }
  }
  &__section-header {
    display: flex;
    padding-top: 40px;
    padding-bottom: 30px;
    .details-dimensions__section-title {
      font-family: $font-medium;
      flex: 1;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.0219rem;
      color: $color-charcoal;
    }
    .details-dimensions__section-view-more {
      font-family: $font-medium;
      text-decoration: underline;
      color: $color-dark-gray;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.0219rem;
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

@media print {
  .details-dimensions {
    &__dimensions {
      .details-dimensions__list {
        display: flex !important;
      }
    }

    .collapsible-accordion {
      page-break-after: always !important;
    }
  }
}
