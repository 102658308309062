.shipping-address {
    &__checkout-fast {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        span {
            font-family: $font-light;
            font-size: 26px;
            line-height: 1.31;
            letter-spacing: -1.55px;
            color: $color-slate;
        }
        &-cta {
            display: flex;
            .button:first-child {
                min-width: auto;
                font-family: $font-medium;
            }
        }
    }
    &__message {
        margin-bottom: 50px;
        &.--sales {
            margin-bottom: 25px;
            .shipping-address__signin-mssg {
                margin-bottom: 10px;
            }
        }
    }
    &__signin-mssg {
        margin-bottom: 20px;
        font-family: $font-medium;
        font-size: 20px;
        letter-spacing: -0.4px;
        line-height: 28px;
        color: $color-charcoal;
    }
    &__sales-email {
        margin-bottom: 50px;
        max-width: 380px;
    }
    &__select-address {
        font-family: $font-regular;
        font-size: 20px;
        letter-spacing: -0.65px;
        line-height: 28px;
        color: $color-dark-gray;
    }
    &__address-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 20px;
    }
    &__submit {
        min-width: 180px;
        margin-top: 50px;
        float: right;
    }
    &__note {
        margin-top: 50px;
        &-title {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-charcoal;
        }
        &-desc {
            margin-top: 5px;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-dark-gray;
        }
        .textarea__wrapper {
            margin-top: 25px;
        }
    }
    &__signup-success {
        border-radius: 5px;
        background-color: $color-off-white;
        padding: 30px;
        margin-bottom: 50px;

        h1 {
            color: $color-charcoal;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.4px;
            line-height: 28px;
            margin-bottom: 5px;
            font-family: $font-medium;
        }

        p {
            color: $color-slate;
            font-size: 20px;
            letter-spacing: -0.65px;
            line-height: 28px;
        }
    }
    &__process-error, &__process-error p {
        text-align: right;
        margin-top: 20px;
        color: $color-error-red;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 22px;
    }
}

.shipping-form {
    &__required-fields {
        display: inline-block;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin-bottom: 20px;
        @media only screen and (min-width: 812px)  and (max-width: 1023px){
            margin-bottom: 40px;
        }
    }
    &__set-default {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .checkbox {
            margin-bottom: 0;
            input:checked ~ span {
                color: $color-charcoal;
            }
        }
        .shipping-form__label-input {
            margin-top: 0;
            margin-bottom: 0;
        }
        &-check {
            display: inline-flex;
            height: 60px;
        }
        &.--hide-label {
            margin-top: 10px;
            .shipping-form__set-default-check {
                height: auto;
            }
        }
    }
    &__toggle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            color: $color-charcoal;
            font-family: $font-medium;
            font-size: 16px;
            letter-spacing: -0.35px;
            line-height: 24px;
        }
        .switch {
            min-width: 60px;
            margin-bottom: 0;
            @media screen and (min-width: 778px) and (max-width: 991px) {
                min-width: unset;
            }
        }
    }
    &__label-input {
        margin-top: 20px;
    }
    &__submit-button {
        min-width: 161px;
        margin-top: 60px;
        @media screen and (max-width: 560px){
        margin-bottom: 60px;
        }
        float: right;
    }

    &__auth {
        padding: 30px;
        border-radius: 5px;
        background-color: $color-off-white;
        margin-bottom: 30px;

        &-title {
            font-family: $font-medium;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.4px;
            color: $color-charcoal;
            margin-bottom: 5px;
        }
        &-desc {
            font-family: $font-regular;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.65px;
            color: $color-slate;
            margin-bottom: 30px;
        }
        &-cta {
            margin-top: 10px;
            color: $color-slate;
            display: flex;
            .button .ghost {
                color: $color-slate;
            }
        }
        &-cta-create-account {
            margin-right: 20px;
        }
    }
    &__pl10 {
        padding-left: 10px !important;
    }
    &__pr10 {
        padding-right: 10px !important;
    }
    .hidden-state-input{
        z-index: -1;
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
    }
}

.check-out__shipping-modal {
    .modal-popup {
        .modal-popup-content {
            .modal-popup-body {
                width: 780px;
                padding: 35px 60px 50px;
            }
            @media screen and (max-width: 1023px) {
                width: 100%;
                max-height: 100%;
                padding: 35px 32px 50px;
                .modal-popup-body {
                    width: 100%;
                    min-width: 339px;
                }
            }
            @media only screen and (min-width: 812px)  and (max-width: 1023px){
                .modal-popup-body {
                    width: 748px;
                    margin: auto;
                }
            }
            @media screen and (max-width: 767px) {
                padding: 35px 18px 50px;
            }
        }
    }
}
@media screen and (max-width: 990px) {
    .shipping-address {
        &__message {
            margin-bottom: 60px;
        }
        &__address-list {
            grid-template-columns: 1fr;
            row-gap: 20px;
            column-gap: 0;
        }
        &__submit {
            float: none;
            width: 100%;
        }
    }
    .shipping-form {
        &__submit-button {
            width: 100%;
        }
        &__auth-cta {
            display: block;
            .button {
                width: 100%;
                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .shipping-form {
        &__pl10 {
            padding-left: 15px !important;
        }
        &__pr10 {
            padding-right: 15px !important;
        }
    }
}

@media screen and (max-width: 480px) {
    .shipping-form {
        &__required-fields {
            margin-bottom: 40px;
        }
    }
    .shipping-address {
        &__checkout-fast {
            display: block;
            span {
                line-height: 1.31;
                letter-spacing: -1.55px;
            }
            &-cta {
                display: block;
                margin-top: 25px;
                .button {
                    width: 100%;
                    &:first-child {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .shipping-form {
        &__auth {
            padding-right: 30px 20px;
        }
    }
}