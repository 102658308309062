.zipcode-page {
  background: $color-off-white;
  width: 100%;

  .bread-crumb {
    list-style: none;
    padding: 10px 0;
    margin-bottom: 50px;
    margin-left: 0;

    li {
      display: inline;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.3px;
      margin-right: 4px;
      a {
        text-decoration: none;
        color: $color-slate;
      }

      &.active {
        color: $color-slate;
        font-family: $font-medium;
      }
    }

    li + li:before {
      padding: 0;
      color: $color-slate;
      content: "/\00a0";
    }
  }

  &__main-container {
    max-width: 1380px;
    padding: 0px;
    margin-right: auto;
    margin-left: auto;
    @media screen and (min-width: 1024px) and (max-width: 1439px) {
      padding: 0 36px;
    }
    @media only screen and (max-device-width: 1023px) and (orientation: landscape) {
      padding: 0 32px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 18px;
    }
  }

  &__inner-container {
    display: flex;
    padding-top: 65px;
    padding-bottom: 120px;
  }

  &__zipcode-left {
    max-width: 44rem;
    margin-right: 11.25rem;
    .zipcode-info,
    .zipcode-info p {
      font-family: $font-light;
      font-size: 60px;
      line-height: 68px;
      letter-spacing: -3.1px;
      color: $color-slate;
    }
  }

  &__zipcode-right {
    .zipcode-description,
    .zipcode-description p {
      font-family: $font-regular;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      color: $color-slate;
      margin-bottom: 60px;
    }
    .zipcode-text-box {
      width: 540px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-dark-gray;
      padding-bottom: 15px;

      .zipcodeInput {
        font-family: $font-light;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.7px;
        color: $color-dark-gray;
        background: transparent;
        border: none;
        width: 60%;
        margin: 0;

        &::placeholder {
          opacity: 1;
          font-family: $font-light;
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -1.7px;
          color: $color-dark-gray;
        }
      }

      .submitCTA {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.25px;
        min-width: 105px;
        height: 44px;
      }
    }

    .location-link-container {
      .findstore-util-nav__zipcode-error {
        position: relative;
        top: -10px;
      }
      margin-top: 20px;
      .location-link {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        text-decoration: underline;
        display: block;
        cursor: pointer;
        color: $color-slate;
      }
    }
  }
}

@media only screen and (max-width: 860px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .zipcode-page {
    &__inner-container {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    &__zipcode-left {
      max-width: 22rem;
      margin-right: 4rem;
      .zipcode-info,
      .zipcode-info p {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -2.2px;
      }
    }

    &__zipcode-right {
      .zipcode-text-box {
        .zipcodeInput {
          font-size: 32px;
          line-height: 44px;
          letter-spacing: -2.5px;

          &::placeholder {
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -2.5px;
          }
        }
      }

      .zipcode-text-box {
        .submitCTA {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 24px;
          width: 105px;
          padding: 8px 30px;
          text-align: center;
          height: inherit;
          display: flex;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1120px) {
  .zipcode-page {
    &__inner-container {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    &__zipcode-left {
      max-width: 32rem;
      margin-right: 8rem;
      .zipcode-info,
      .zipcode-info p {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -1.2px;
      }
    }

    &__zipcode-right {
      .zipcode-text-box {
        .zipcodeInput {
          font-size: 32px;
          line-height: 44px;
          letter-spacing: -2.5px;

          &::placeholder {
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -2.5px;
          }
        }
      }

      .zipcode-text-box {
        .submitCTA {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 24px;
          width: 105px;
          padding: 8px 30px;
          text-align: center;
          height: inherit;
          display: flex;
        }
      }
    }
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) and (orientation: landscape) {
  .zipcode-page {
    &__zipcode-left {
      margin-right: 10rem;
    }
  }
}

@media screen and (max-width: 991px) {
  .zipcode-page {
    &__zipcode-right {
      .zipcode-text-box {
        width: 340px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .zipcode-page {
    &__inner-container {
      padding-top: 50px;
      padding-bottom: 60px;
      flex-direction: column;
    }

    &__zipcode-left {
      max-width: 100%;
      margin-right: 0;
      .zipcode-info,
      .zipcode-info p {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -2.2px;
        margin-bottom: 20px;
      }
    }

    &__zipcode-right {
      padding: 0;

      .zipcode-text-box {
        .zipcodeInput {
          font-size: 32px;
          line-height: 44px;
          letter-spacing: -2.5px;

          &::placeholder {
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -2.2px;
          }
        }
      }

      .zipcode-text-box {
        .submitCTA {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 24px;
          padding: 8px 30px;
          min-width: 45px;
          height: 40px;
          display: block;
          text-align: center;
        }
      }
    }
  }
}
