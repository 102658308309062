.collapsed-widget {
  width: 335px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #000000;
  font-family: $font-regular;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
  padding: 10px 20px;
  z-index: 99;
  cursor: pointer;
  pointer-events: auto;

  &__compare-details {
    display: flex;
    align-items: center;
    .collapsed-widget__number {
      font-family: $font-light;
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: -0.55px;
    }

    img {
      height: 30px;
      padding: 0px 8px;
    }

    .collapsed-widget__products-compare-text {
      display: inline-block;
      font-family: $font-regular;
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: -0.5px;
      padding-left: 20px;
      padding-right: 15px;
    }
    .collapsed-widget__compare-text {
      display: none;
    }
  }
  &__collapse-icon {
    img {
      width: 15px;
      height: 10px;
    }
  }
}

@media screen and (orientation:landscape) and (max-width: 900px){
  .collapsed-widget {
    width: 185px;
    height: 40px;

    &__compare-details {
      .collapsed-widget__number {
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: -0.34px;
      }

      img {
        height: 25px;
        padding: 0px 5px;
      }

      .collapsed-widget__compare-text {
        display: inline-block;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.45px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .collapsed-widget__products-compare-text {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .collapsed-widget {
    width: 185px;
    height: 40px;

    &__content-left {
      .collapsed-widget__number {
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: -0.34px;
      }

      img {
        height: 25px;
        padding: 0px 5px;
      }

      .collapsed-widget__compare-text {
        display: inline-block;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.45px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .collapsed-widget__products-compare-text {
        display: none;
      }
    }
  }
}
