.pdp-grout-chart {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  padding: 125px 40px 60px 40px;
  overflow: hidden;
  @include breakpoint(tab-max){
    padding: 0;
  }
  @include breakpoint(mobile) {
    padding: 0; 
  }
  &__container {
    max-width: 1355px;
    width: 100%;
    height: 100%;
    padding: 6.8125rem 3.875rem 6.125rem 2.9375rem;
    background-color: $color-white;
    overflow-y: auto;
    position: relative;
    @include breakpoint(desktop-mid){
      padding: 6.8125rem 2.25rem 6.125rem;
    }
    @include breakpoint(tab-max) {
      padding:74px 32px 61px;
    }
    @include breakpoint(mobile){
      padding:96px 18px 100px;
    }
  }
  &__close-icon {
    width:50px;
    height:50px;
    position: absolute;
    right:37px;
    top: 32px;
    @include breakpoint(tab-max){
      width:40px;
      height:40px;
    }
    @include breakpoint(mobile) {
      right: 18px;
      top: 18px;
    }
    img {
        width:100%;
        height:100%;
        cursor: pointer;
        position: relative;
        @include breakpoint(mobile){
          width: 40px !important;
          height: 40px !important;
        }
    }
  }
  &__left-column {
    width: 100%;
    max-width: 31.125rem;
    float: left;
    padding: 0;
    @include breakpoint(tab-max) {
      width: 100%;
      max-width: 100%;
      float: none;
      clear: both;
    }
  }
  &__right-column {
    width: 100%;
    max-width: 41.625rem;
    float: right;
    padding: 0;
    @include breakpoint(tab-max) {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      margin: 0px;
      float: none;
      clear: both;
    }
  }
  &__need {
    padding-right: 0;
    @include breakpoint(mobile) {
      width: 100%;
    }
  }
  &__need-title {
    margin-bottom: 15px;
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
    @include breakpoint(tab-max) {
      font-size: 22px;
      line-height: 32px;
    }
    @include breakpoint(mobile){
      margin-bottom:20px;
    }
   
  }
  &__need-title > p, &__instruction-title > p {
    margin: 0;
  }
  &__need-details {
    color: $text-medium-dark-grey-small;
    text-align: left;
    font-family: $lato-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 30px;
    @include breakpoint(mobile) {
      font-size: 16px;
      line-height: 28px;
    }
    @include breakpoint(tab){
      max-width: 673px;
    }
    ul, ol {
      margin: 0px;
      padding: 0px 0px 0px 16px;
    }
  }

  &__instruction-title {
    text-align: "left";
    display: flex;
    justify-content: left;
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 15px;
    @include breakpoint(mobile){
      font-size: 18px;
    }
  }

  &__instruction-details {
    color: $text-medium-dark-grey-small;
    font-family: $lato-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
    ul, ol {
      margin: 0px;
      padding: 0px 0px 0px 16px;
    }
  }
  .pdp-grout-chart_table-data {
    overflow-x:auto;
    @include breakpoint(mobile) {
      width: 100%;
      &::-webkit-scrollbar{
          height: 4px;
          width: 4px;
          background: $color-medium-grey;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background: $color-medium-grey; 
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $charcoal; 
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $charcoal; 
      }
      
      *::-webkit-scrollbar-thumb:horizontal{
          background-color: $charcoal;
          border-radius: 10px;
      }
    }
    .table-bordered{
      border-spacing: 0;
      border:1px solid $cool-dark-grey;
      @include breakpoint(mobile){
        margin-bottom:25px;
      }
      td{
        color: $text-medium-dark-grey;
        padding: 5px 6px;
        text-align: center;
        border-color: $cool-dark-grey;
        &:first-child{
          text-align: left;
         @include breakpoint(mobile){
          min-width: 186px;
         }
        }
        @include breakpoint(tab-max){
          padding: 5px 10px;
        }
      }
      th{
        color: $text-dark-grey;
        border-color: $cool-dark-grey;
        text-align: left;
        border-bottom-width: 1px;
        @include breakpoint(tab-max){
          padding:6px 15px;
        }
      }
    }
  }
  &__table {
    width: 100%;
    @include breakpoint(desktop-extra-lite-sm){
      margin-right: 0;
    }
    @include breakpoint(tab) {
      margin-left: 0px;
    }
    @include breakpoint(mobile) {
      margin: 0px;
      overflow: auto;
      width: 100%;
    }
  }
  &__table-tile,
  &__table-width {
    font-family: $lato-bold;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__table-width {
    text-align: center;
  }
  &__table-data {
    color: $text-medium-dark-grey-small;
    font-family: $lato-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__coverage-title {
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 15px;
    @include breakpoint(tab) {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
    }
    @include breakpoint(mobile) {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
  &__coverage-desc {
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 23px;
  }
  &__coverage-warning {
    width: 624px;
    color: $text-medium-dark-grey-small;
    font-family: $lato-regular;
    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 20px;
    @include breakpoint(mobile) {
      width: 100%;
      margin-top:35px;
    }
  }
}

.pdp-grout-chart-main{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}