.pairs-well-with{
    .container{
      max-width: 1380px;
    }
    &--recommended-product{
      padding-top: 80px;
      padding-bottom: 110px;
    }
    .product-card__link .product-card__img-container{
      width: 375px;
      height: 375px;
      background-color: $color-off-white;
      @media only screen and (max-width: 900px){
        width: 166px;
        height: 166px;
      }
    }
    &__title{
      h4{
        font-family: $font-light;
        display: inline-block;
        color: $color-slate;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -1.6px;
        padding-bottom: 25px;
        span{
          font-family: $font-bold;
        }
      }
    }
    &.--recently-viewed {
      .pairs-well-with__title {
        h4 {
          padding-left: 0;
        }
      }
    }
    &__carousel{
        overflow-x: auto;
        scroll-behavior: smooth;
        padding-bottom: 60px;
        margin: 0px;
        display: flex;
        .product-card{
          margin-right: 30px;
          display: inline-block;
          max-width: 450px;
          &:last-child{
            margin-right: 0;
          }
          a{
            display: inline-block;
            width: 100%;
          }
        }
        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px $color-dark-gray; 
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-dark-gray; 
          border-radius: 5px;
        }
        &::-webkit-scrollbar-button {
          width: 35px;
        }
    }
}
@media only screen and (max-width: 900px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 991px){
  .pairs-well-with{
    &--recommended-product{
      padding: 50px 0px 50px;
    }
    &__title{
      h4{
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -1.5px;
        padding-left: 0px;
        padding-bottom: 30px;
      }
    }
    .pairs-well-with__title{
      padding: 0px;
    }
    .pairs-well-with__carousel{
      padding: 0px 0px 30px;
        &::-webkit-scrollbar-button {
          width: 0px;
        }
    }
  }
}
@media only screen and (max-width: 767px){
  .pairs-well-with{
    &--recommended-product{
      padding: 50px 0px 50px;
    }
    &__title{
      h4{
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -1.5px;
        padding-left: 0px;
        padding-bottom: 30px;
      }
    }
    .pairs-well-with__title{
      padding: 0px;
    }
    .pairs-well-with__carousel{
        padding: 0px 0px 30px;
        &::-webkit-scrollbar-button {
          width: 0px;
        }
    }
  }
}

@media only screen and (max-width: 990px){
  .pairs-well-with__carousel{
    padding: 0px 0px 11px;
    .product-card{
      margin-right: 7px;
    }
  } 
}