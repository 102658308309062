.pdp-may-like {
    background: $cool-light-grey-caps;
    padding: 70px 0;
    &__header {
        color: $text-medium-dark-grey;
        text-align: center;
        font-family: $lato-regular;
        font-size: 26px;
        letter-spacing: 0;
        line-height: 36px;
        margin-bottom: 70px;

        @include breakpoint(tab) {
            font-size: 22px;
            line-height: 32px;
        }

        @include breakpoint(mobile) {
            font-size: 22px;
            line-height: 32px;
        }

        @include breakpoint(ipad-landscape) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    &__main-block,
    &__header-block,
    &__image-block {
        padding: 0;
    }

    &__top-devider {
        margin: 0 102px 102px 108px;
        border-top: 1px solid $cool-dark-grey-cap;

        @include breakpoint(tab) {
            width: 100%;
            margin: 0 0 50px 0;
        }

        @include breakpoint(mobile) {
            width: 100%;
            margin: 0 0 50px 0;
        }

        @include breakpoint(ipad-landscape) {
            width: 100%;
            margin: 0 0 50px 0;
        }
    }

    &__product-title,
    &__product-amount,
    &__product-price-label,
    &__product-amount-currency,
    &__product-size-label,
    &__product-color-total,
    &__product-color-label,
    &__product-amount-currency {
        font-family: $lato-regular;
    }

    &__container {
        margin: 0 110px;

        @include breakpoint(tab) {
            margin: 0 32px 0 32px;
        }

        @include breakpoint(mobile) {
            margin: 0 18px 0 18px;
        }

        @include breakpoint(ipad-landscape) {
            margin: 0 32px 0 32px;
        }
    }

    &__image-block {
        margin-bottom: 60px;
        padding-left: 0;

        @include breakpoint(tab) {
            clear: both;
            padding-left: 0;
            max-width: 100%;
            margin-bottom: 0;
            padding-left: 0;
        }

        @include breakpoint(mobile) {
            clear: both;
            padding-left: 0;
            margin-bottom: 0;
            padding-left: 0;
            max-width: 100%;
        }

        @include breakpoint(ipad-landscape) {
            clear: both;
            padding-left: 0;
            max-width: 100%;
            margin-bottom: 0;
            padding-left: 0;
        }
    }

    &__image-ul-list {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
    }

    &__image-details-block {
        float: left;
        cursor: pointer;
        @include annsacks-desk(width, 340, 458);
       @include breakpoint(mobile){
        width: 100%;
       }
    }

    // __jy -------->

    &__image-block .pdp-may-like__image-ul-list .pdp-may-like__image-li-list {
        opacity: 0;
        animation: scaleToOne 0.9s forwards;
        // animation-fill-mode: both;
        @include breakpoint(mobile){
            width: 100%;
        }
    }

    @for $i from 1 through 3 {
        &__image-block .pdp-may-like__image-ul-list .pdp-may-like__image-li-list:nth-child(3n+ #{$i}) {
            animation-delay: #{$i * 0.5}s;
            // background-color: rgb(88, 95, 85);
        }
    }

    // <-----------

    &__product-outer-block {
        height: 597px;
        text-align: center;
        width: auto;
        margin-bottom: 20.62px;
        padding: 110px 40px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(mobile){
            height: 488px;
        }
    }

    &__product-image {
        height: 378px;
        width: 100%;

        @include breakpoint(tab) {
            height: 274px;
            width: 261px;
        }

        @include breakpoint(mobile) {
            height: 274px;
            width: 261px;
        }
    }

    &__product-description-block {
        width: 100%;
    }

    &__product-title {
        width: 38%;
        color: $text-dark-grey;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26px;
        float: left;

        @include breakpoint(tab) {
            width: 50%;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
        }

        @include breakpoint(mobile) {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
        }
    }

    &__product-price {
        float: right;
        height: 38px;
        width: auto;
        margin: 0 20px 0 83px;

        @include breakpoint(tab) {
            margin: 0 10px 0 50px;
        }

        @include breakpoint(mobile) {
            margin: 0 10px 0 50px;
        }
    }

    &__product-price-label {
        color: $text-medium-dark-grey;
        font-size: 11px;
        letter-spacing: 1.67px;
        line-height: 14px;
        text-align: right;
    }

    &__product-amount {
        color: $text-dark-grey;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }

    &__product-amount-block {
        text-align: right;
    }

    &__product-price-label,
    &__product-amount {
        margin: 0;
        text-align: right;
    }

    &__product-amount-currency {
        color: $text-medium-dark-grey;
        font-size: 10px;
        letter-spacing: 1.67px;
        line-height: 16px;
        text-align: right;
        margin-left: 3px;

        @include breakpoint(tab) {
            margin-left: 5px;
        }

        @include breakpoint(mobile) {
            margin-left: 5px;
        }
    }

    &__product-size-block {
        margin-bottom: 25.56px;
        clear: both;
        margin-top: 2px;

        @include breakpoint(tab) {
            margin-top: 5px;
            margin-bottom: 21.97px;
        }

        @include breakpoint(mobile) {
            margin-top: 5px;
            margin-bottom: 21.97px;
        }
    }

    &__product-size-label {
        width: 171px;
        color: $text-medium-dark-grey;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 18px;
    }

    &__product-color-total,
    &__product-color-label {
        color: $text-medium-dark-grey;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 18px;

        @include breakpoint(tab) {
            font-size: 13px;
            letter-spacing: 0.2px;
            line-height: 20px;
        }

        @include breakpoint(mobile) {
            font-size: 13px;
            letter-spacing: 0.2px;
            line-height: 20px;
        }
    }

    &__product-color-label {
        margin-left: 3px;
    }

    &__image-ul-list {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        justify-content: center;
        column-gap: 30px;
        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px $color-dark-gray;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-dark-gray;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-button {
            width: 35px;
        }

        @include breakpoint(mobile) {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px $color-dark-gray;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-dark-gray;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-button {
                width: 35px;
            }
        }

        @include breakpoint(tab) {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px $color-dark-gray;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-dark-gray;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-button {
                width: 35px;
            }
        }

        @include breakpoint(ipad-landscape) {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px $color-dark-gray;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-dark-gray;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-button {
                width: 35px;
            }
        }
    }
}

//Slider Code Start
.pdp-may-like-container-card-link {
    display: inline-block;
    margin: 0;
    text-align: center;
    width: 100%;
    height: 11px;

    @include breakpoint(mobile) {
        width: 100%;
        margin-top: 21.03px;
        text-align: center;
    }

    @include breakpoint(tab) {
        width: 100%;
        margin-top: 41.03px;
        text-align: center;
    }

    .normal {
        display: inline-block;
        position: relative;
        min-height: 11px;
        min-width: 11px;
        margin-right: 15px;
        box-sizing: border-box;
        border: 1px solid $text-light-grey-cap;
        cursor: pointer;
    }

    .selected {
        display: inline-block;
        position: relative;
        min-height: 10px;
        min-width: 10px;
        margin-right: 15px;
        background-color: $text-light-grey-cap;
        transition: background-color 0.6s ease;
        cursor: pointer;
    }
}

.pdp-main__replacement-suggestions {
    display: none;
}