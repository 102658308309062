.container {
  max-width: 100%;
  position: relative;
}

.organize-container {
  .cmp-container {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    justify-content: space-between;
    flex-direction: row;

    @include breakpoint(desktop-large) {
      padding: 0 50px;
    }

    @include breakpoint(desktop-lg) {
      max-width: 1380px;
      margin: 0 auto;
      padding: 0;
    }

    @include breakpoint(desktop-mid) {
      padding: 0 36px;
    }

    @include breakpoint(desktop-xlg) {
      max-width: 1480px;
      margin: 0 auto;
    }

    @include breakpoint(tab) {
      padding: 0 2rem;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      padding: 0 1.125rem;
    }
  }
  &.two-large-container {
    .cmp-container {
      display: flex;
      max-width: 1380px;
      margin: 0 auto;
      justify-content: space-between;
      flex-direction: row;
      padding: 0;
      @include breakpoint(desktop-mid) {
        padding: 0 36px;
      }
      @include breakpoint(tab) {
        padding: 0 2rem;
      }
      @include breakpoint(mobile) {
        padding: 0 1.125rem;
        flex-direction: column;
      }
    }
  }
}

.article-center-container {
  text-align: center;

  @include breakpoint(desktop-mid) {
    padding: 0 2.25rem;
  }

  @include breakpoint(tab) {
    padding: 0 2rem;
  }

  @include breakpoint(mobile) {
    padding: 0 1.125rem;
  }

  .cmp-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1380px;
    margin: 0 auto;
    box-sizing: border-box;

    @include breakpoint(tab) {
      justify-content: space-between;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }
}

.organize-container-indent {
  .cmp-container {
    display: flex;
    max-width: 1380px;
    margin: 0 auto;
    justify-content: space-between;
    flex-direction: row;
    padding-right: 3.75rem;
    padding-left: 3.75rem;

    @include breakpoint(tab) {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    @include breakpoint(mobile) {
      padding-right: 1.125rem;
      padding-left: 1.125rem;
      flex-direction: column;
    }
  }
}
// Sponsored Article Block and Brand Logo Links Container
.brand-logo-container {
  @extend .organize-container;

  .cmp-container {
    align-items: center;
    flex-wrap: wrap;
    @include breakpoint (desktop-xlg) {
      padding: 6.25rem 8.0625rem;
    }

    @include breakpoint(tab) {
      padding: 3.125rem 2rem;
    }

    @include breakpoint(mobile) {
      align-items: flex-start;
      padding: 3.125rem 1.125rem;
      display: flex;
    }
    .image {
      &.deco-brand-logo {
        padding-bottom: 50px;
      }
    }
    .text {
      width: 100%;
      text-align: center;
      margin-bottom: 2.1875rem;
      @include breakpoint(mobile) {
        text-align: left;
      }
  
      .cmp-text {
        padding: 0!important;
        p {
          @include ann-describtion(14px, $lato-regular, 16px);
          letter-spacing: 2px;
          color: $text-dark-grey;
          text-transform: uppercase;
          margin: 0;
        }
      }
    }
  }
}

.editorial-feature-collection {
  padding: 70px 0;

  @include breakpoint(tab) {
    padding: 3.125rem 0;
  }

  @include breakpoint(mobile) {
    padding: 3.125rem 1.125rem;
  }

  .cmp-container {
    max-width: 1380px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include breakpoint(desktop-mid) {
      padding: 0 2.25rem;
    }

    @include breakpoint(tab-max) {
      max-width: 100%;
    }

    @include breakpoint(tab) {
      padding: 0 2rem;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }
}

.tool-box-container {
  @include breakpoint(desktop-small) {
    padding: 0 2.25rem;
  }

  &.bg-warm-gray-medium {
    .link-tool-box {
      border-color: $text-light-grey;
    }
  }

  &.bg-dark-blue-three {
    .link-tool-box {
      border-color: $text-medium-dark-grey;
    }
  }

  .cmp-container {
    max-width: 1380px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include breakpoint(tab-max) {
      max-width: 100%;
      flex-direction: column;
    }

    @include breakpoint(tab) {
      padding: 0 2rem;
    }

    @include breakpoint(mobile) {
      padding: 0 1.125rem;
    }
  }
}

.tab-container {
  overflow: hidden;

  .cmp-container {
    max-width: 1380px;
    margin: 0 auto;
    padding: 4.375rem 0;

    @include breakpoint(tab) {
      padding: 0;
    }

    @include breakpoint(mobile) {
      padding: 0 0 3.125rem 0;
    }
  }
}

// FAQ Container
.organize-teaser-accordion {
  .cmp-container {
    display: flex;
    max-width: 1380px;
    margin: 0 auto;
    padding: 4.375rem 0;

    @include breakpoint(desktop-mid) {
      padding: 4.375rem 2.25rem;
    }

    @include breakpoint(tab) {
      padding: 3.125rem 2rem 3.125rem;
      flex-direction: column;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      padding: 3.125rem 1.125rem;
    }
  }
}

.promotion-tiles-indent {
  .cmp-container {
    display: flex;
    max-width: 1380px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 0;

    @include breakpoint(desktop-mid) {
      padding: 0 2.25rem;
    }

    @include breakpoint(tab) {
      justify-content: center;
      padding: 0 2rem;
    }

    @include breakpoint(mobile) {
      padding: 0 1.125rem;
    }
  }

  &-scroll {
    padding: 4.375rem 2.25rem;

    @include breakpoint(tab) {
      overflow: hidden;
      overflow-x: scroll;
      padding: 3.125rem 2rem 0.625rem;
    }

    @include breakpoint(mobile) {
      padding: 3.125rem 1.125rem;
    }

    .cmp-container {
      padding: 0;
      display: flex;
      max-width: 1380px;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;

      @include breakpoint(tab) {
        flex-direction: row;
      }
    }
  }
}

// Three Up Content Container Styles
.container.three-up-container {
  padding: 0 36px;
  @include breakpoint(tab) {
    padding: 0 32px;
  }
  @include breakpoint(mobile) {
    padding: 0 18px 48px 18px;
  }
  .cmp-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1380px;
    margin: 0 auto;
    padding: 4.375rem 0;
    @include breakpoint(tab) {
      padding: 3.125rem 0;
    }
    @include breakpoint(mobile) {
      padding: 0;
      position: relative;
    }
  }
  .teaser {
    @include breakpoint(mobile) {
      &:nth-child(4) {
        padding-bottom: 5.75rem;
      }
    }
  }
}
// Moodboard Container Styles
.moodboard-container {
  padding: 0 6.875rem;
  @include breakpoint (desktop-xlg) {
    padding: 0 3.125rem;
    }
  @include breakpoint (desktop-mid) {
    padding: 0 2%;
    row-gap: 3.75rem;
  }
  @include breakpoint(tab) {
    padding: 0 2rem;
  }
  @include breakpoint(mobile) {
    padding: 0 1.125rem;
  }

  .cmp-container {
    row-gap: 3.75rem;
    @include breakpoint (desktop-xlg) {
      max-width: 1830px;
      row-gap: 5rem;
    }
    @include breakpoint (tab) {
      column-gap: 4.375rem;
    }
    @include breakpoint (tab-max) {
      row-gap: 2.5rem;
    }
    max-width: 1380px;
    margin: 0 auto;
    padding: 4.375rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include breakpoint(tab-max) {
      padding: 3.125rem 0;
    }
  }
}

.organize-aem-form-teaser {
  .cmp-container {
    display: flex;
    flex-direction: row-reverse;

    @include breakpoint(tab) {
      flex-direction: column-reverse;
    }

    @include breakpoint(mobile) {
      flex-direction: column-reverse;
    }
  }
}

// Store Virtual Tour Container
.store-virtual-image-right {
  .cmp-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(desktop-xlg) {
      max-width: 1600px;
      margin: 0 17.5rem 0 10rem;
      flex-direction: row;
    }
    @include breakpoint(desktop-lg) {
      max-width: 1600px;
      margin: 0 auto;
      justify-content: flex-start;
      flex-direction: row;
    }
    @include breakpoint(tab) {
      align-items: flex-start;
      flex-direction: row-reverse;
    }
    @include breakpoint(mobile) {
      flex-direction: column;
    }
    .image {
      .cmp-image {
        img {
        @include breakpoint (desktop-small) {
          max-height: 59.375rem;
        }
        @include breakpoint (tab-max) {
          min-width: 372px;
          max-height: 298px;
        }
      }
      }
      width: 100%;
      @include breakpoint(desktop-xlg) {
        max-width: 59.374rem;
        max-height: 59.375rem;
        padding: 0;
        margin: 0;
      }
      @include breakpoint(desktop-lg) {
        max-width: 59.374rem;
      }
      @include breakpoint(mobile) {
        width: 100%;
        zoom: 1.0009;
        min-height: 13.1875rem;
      }
      .cmp-teaser__action-container {
        @include breakpoint(desktop-small) {
          display: none;
        }
        @include breakpoint(mobile) {
          display: none;
        }
        .cmp-teaser__action-link {
          @include breakpoint(tab) {
            &.gbh-primary-button {
              margin-top: 3.75rem;
              @include ann-describtion(0.75rem, $lato-regular, 1.5rem);
              letter-spacing: 0.1563rem;
              padding: 0.75rem 1.6875rem 0.875rem;
              @include breakpoint(mobile) {
                margin-top: 2.5rem;
              }
            }
            &.gbh-secondary-button {
              margin-top: 1.25rem;
              @include ann-describtion(0.75rem, $lato-regular, 1.5rem);
              letter-spacing: 0.1563rem;
              padding: 0.75rem 1.6875rem 0.875rem;
            }
          }
        }
      }
    }
  }
}
// Store Virtual Tour Container

.four-up-container {
  padding: 0 36px;
  @include breakpoint(tab) {
    padding: 0 2rem;
  }
  @include breakpoint(mobile) {
    padding: 0;
  }
  .cmp-container {
    padding: 4.375rem 0;
    flex-wrap: wrap;
    display: flex;
    max-width: 1380px;
    margin: auto;
    @include breakpoint(tab-max) {
      padding: 3.125rem 0;
    }
    &.four-up-cont-with-btn {
      @include breakpoint(mobile) {
        padding-bottom: 8.75rem;
      }
    }
  }
}
.ann-sacks-main-menu {
  height: 45px;
  @include breakpoint(desktop-extra-lite) {
    padding: 0 2.6%;
    height: 45px;
  }

  @include breakpoint(desktop-mid) {
    padding: 0 2.6%;
    height: 45px;
  }

  @include breakpoint(tab) {
    padding: 0 2rem;
    height: 30px;
  }

  @include breakpoint(mobile) {
    padding: 0 1.125rem;
    height: 30px;
  }

  .cmp-container {
    max-width: 1820px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include breakpoint(tab-max) {
      display: flex;
      align-items: center;
      height: 30px;
    }

    @include breakpoint(mobile) {
      overflow: hidden;
      overflow-x: scroll;
    }
    padding: 0;
  }

  &.ann-sacks-tab-bar {
    background: $white;
    z-index: 1;
    @include breakpoint (desktop-extra-lite) {
      z-index: 3;
      bottom: 1px;
    }

    .cmp-container {
      justify-content: space-evenly;
      align-items: baseline;

      @include breakpoint(desktop-extra-lite-sm) {
        width: 100%;
      }
      @include breakpoint(tab){
        overflow-y: scroll;
      }
    }
  }
}

.ann-sacks-logobar {
  padding: 0 2.6%;
  @include breakpoint (desktop-extra-lite) {
    z-index: 1;
    min-height: 75px;
    height: 100%;
  }
  @include breakpoint(desktop-large) {
    min-height: 90px;
    height: 100%;
  }
  @include breakpoint(desktop-extra-lite-sm) {
    border-bottom: 1px solid $cool-medium-grey;
  }

  @include breakpoint(tab) {
    padding: 0 2rem;
  }

  @include breakpoint(mobile) {
    padding: 0 1.125rem;
  }

  .cmp-container {
    max-width: 1820px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.6875rem 0 1.5625rem;
    position: relative;
    @include breakpoint(desktop-extra-lite-sm) {
      padding: 1.3125rem 0;
      justify-content: start;
    }
  }
}
.ann-sacks-logobar {
  .cmp-container {
    .cmp-image+div {
      @include breakpoint (desktop-extra-lite-sm) {
        display: block;
      }
    }
  }

}