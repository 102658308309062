.my-folders {
  padding: 0 10px;

  &__title {
    margin-top: 40px;
    margin-bottom: 13px;
    text-align: center;
  }

  &__sub-title {
    text-align: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 15px;
  }

  &__item {
    margin: 15px 0;
    position: relative;
  }

  &__title-description {
    text-align: center;
    padding: 30px 0;

    .plus-sign {
      font-size: 40px;
      margin-right: 30px;
    }
  }

  &__folder-name {
    color: #666;
    cursor: pointer;

    &:hover {
      color: #666;
      text-decoration: underline;
    }

    &--sign-up {
      cursor: initial;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__folder-number {
    color: #999;
    cursor: pointer;
  }

  &__img-container {
    width: 100%;
    display: block;
    cursor: pointer;

    &--create-folder {
      display: none;
    }
  }

  &__img {
    width: 100%;
  }

  &__dropdown-cta {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: none;
    outline: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .2);

    &:hover, &:focus {
      background-image: linear-gradient(180deg, #456ca1 33%, #396195 97%);
    }
  }

  &__dropdown-panel {
    position: absolute;
    display: none;
    background-color: #fff;
    top: 14%;
    right: 5%;
    padding: 25px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .2);

    &--active {
      display: block;
    }

    p {
      padding-bottom: 10px;
    }
  }

  &__panel-link {
    color: #3b659c;
    cursor: pointer;

    &:hover {
      color: #3b659c;
      text-decoration: underline;
    }
  }
}

@media (min-width: 768px) {
  .my-folders {
    &__title-description {
      .plus-sign {
        display: none;
      }
    }

    &__item {
      width: 33%;
      float: left;
      padding: 0 1px;

    }

    &__list {
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    &__img-container {
      &--create-folder {
        display: block;
      }
    }
  }
}
