.cart-indicator {
    &__three-digit {
        span {
            @media only screen and (max-width: 900px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px){
                background: transparent;
            }
        }
    }
    &__count {
        span {
            @include breakpoint(mobile) {
                color: $text-medium-black;
            }
            background-color: transparent;
        }
    }
}