// Landscape Moodboard Container Styles
.landscape-moodboard-container {	
    padding: 70px 2.6%;
    @include breakpoint(desktop-large){
        padding: 70px 50px;
    }
    @include breakpoint(desktop-lg){
        padding: 70px 36px;
    }
    @include breakpoint(tab){
        padding: 50px 2rem;
    }
    @include breakpoint(mobile){
        padding: 50px 1.125rem;
    }

    .cmp-container {
        max-width: 1820px;
        margin: 0 auto;
        display:flex;
        flex-wrap:wrap;
        row-gap: 5rem;
        column-gap: 4.4%;
        @include breakpoint(desktop-lg){
            max-width: 1380px;
            row-gap: 3.75rem;
            column-gap: 4.35%;
        }
        @include breakpoint(tab){
            row-gap: 2.5rem;
            column-gap: 9.36%;
        }
        @include breakpoint(mobile){
            row-gap: 2.5rem;
            column-gap: unset;
        }
    }
    &.three-up-moodboard{
        .moodboard-landscape-teaser{
            @include breakpoint(desktop-xlg){
                width: 30.40%;
            }
            @include breakpoint(desktop-lg){
                width: 30.43%;
            }
            @include breakpoint(tab){
                width: 27.09%;
            }
            @include breakpoint(mobile){
                width: 100%;
            }
        }
    }
    &.two-up-moodboard{
        .moodboard-landscape-teaser{
            @include breakpoint(desktop-xlg){
                width: 47.80%;
            }
            @include breakpoint(desktop-lg){
                width: 47.82%;
            }
            @include breakpoint(tab){
                width: 45.32%;
            }
            @include breakpoint(mobile){
                width: 100%;
            }
        }
    }
    .moodboard-landscape-teaser{
        .cmp-teaser{
            position: relative;
            z-index: 0;
            &__title{
                @include breakpoint(desktop-extra-lite-lg){
                    max-width: 880px;
                }
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(1.375rem, $lato-regular, 2rem);
                    font-weight: 400;
                    color: $text-dark-grey;
                    margin: 1.875rem 0 0;
                    letter-spacing: normal;
                    @include breakpoint(tab-max){
                        @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                        letter-spacing: .0313rem;
                        margin: 1.25rem 0 0;
                    }
                }
            }
            &__description{
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                    letter-spacing: normal;
                    font-weight: 400;
                    color: $text-medium-dark-grey;
                    margin: .625rem 0 0;
                    @include breakpoint(tab-max){
                        @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    }
                }
            }
            &__icons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                align-content: center;
                column-gap: .3125rem;
                position: absolute;
                top: .625rem;
                right: .625rem;
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s,opacity .5s linear;
                .favorite {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    height: 2.25rem;
                    width: 2.25rem;
                    background: $white;
                    border-radius: .3125rem;
                    span {
                        display: none;
                    }
                    &:before {
                        font-family: icomoon;
                        color: $text-medium-dark-grey;
                        margin: 0;
                    }
                }
                .share {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    height: 2.25rem;
                    width: 2.25rem;
                    background: $white;
                    border-radius: .3125rem;
                    &:before {
                        font-family: icomoon;
                        color: $text-medium-dark-grey;
                        margin: 0;
                    }
                    span {
                        display: none;
                    }
                }
            }
            .share-cmp{
                right: 0;
            }
            &.landscape-modal{
                background: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                .landscape-modal-content{
                    .cmp-teaser__title{
                        h1,h2,h3,h4,h5,h6,p{
                            color: $white;
                            margin: 1.25rem 0 0;
                        }
                    }
                }
            }
        }
        &:hover{
            .cmp-teaser__icons{
                visibility: visible;
                opacity: 1;
            }
        }
        .close-modal{
            @include breakpoint (tab-max){
                top: 30px;
            }
            &::before{
                position: fixed;
                top: 30px;
                right: 30px;
                color: $white;
                font-size: 30px;
                font-weight: 100;
                width: 30px;
                height: 30px;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                font-family: $icon-moon;
                content: "\e92e";
            }
        }
        &:focus{
            outline: none !important;
            text-decoration: unset;
        }
    }
}
// Landscape modal
.landscape-modal{
    .cmp-teaser{
        &__content{
            display: none;
        }
    }
    .landscape-modal-content{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        @include breakpoint(desktop-small){
            max-width: 1176px;
            margin: 0 auto;    
        }
        @include breakpoint(desktop-mid){
            padding: 0 36px;
        }    
        @include breakpoint (tab){
            padding: 0 32px;
        }
        @include breakpoint (mobile){
            align-items: center;
            padding: 0 18px;
        }
        .cmp-teaser__image{
            img{
                @include breakpoint (desktop-large){
                    height: 47.1875rem;
                }
                @include breakpoint (desktop-xlg){
                    height: 46rem;
                }
                @include breakpoint (desktop-lg){
                    height: 45rem;
                }
                @include breakpoint (desktop-mid){
                    height: 40rem;
                }
                @include breakpoint (desktop-extra-lite-lg){
                    height: 38rem;
                }
                @include breakpoint (desktop-small){
                    width: 73.75rem;
                }
                @include breakpoint (tab){
                    width: 29.75rem !important;
                    height: 17.5rem;
                }
                @include breakpoint (mobile){
                    width: 21.1875rem;
                    height: 15.875rem;
                }
            }
        }
    }
}