.kf-react-container{
  &.check-out{
    @include breakpoint(desktop-xlg){
      max-width: unset !important;
      padding: 0 !important;
      margin-right: unset !important;
      margin-left: unset !important;
    }
    .check-out__grid{
      @include breakpoint(desktop-xlg){
        max-width: 1187px!important;
        padding: 0!important;
        margin-right: auto!important;
        margin-left: auto!important;
      }
    }
    .checkout-cart-section{
      .delivery-option__row{
        .cart-item{
          @include breakpoint(desktop-xlg){
            padding: 30px 0!important;
          }
        }
        .cart-item__brand{
          @include breakpoint(desktop-xlg){
            margin-bottom: 13px;
          }
        }
      }
    }
    .shipping-address__address-list {
      .address-tile {
        &.--new {
          #kf-shipping-phone-wrapper{
            margin-bottom: 0;
          }
          .shipping-form__set-default.no-checkbox{
            .shipping-form__label-input{
              margin-top: 20px!important;
            }
          }
          .checkbox input ~ span{
            color: $color-dark-gray!important;
          }
        }
      }
    }
  }
  .checkout-need-help{
    @include breakpoint(desktop-xlg){
      margin: 6.25rem 0 0 2.6045%;
      padding: 1.875rem 0 3.75rem;
    }
    a{
      text-decoration: underline;
    }
  }
  .shipping-form__submit-button button {
    &.disabled{
      background-color: $cool-dark-grey;
    }
  }
}