.quantity-selector {
  border: 1px solid $cool-dark-grey;
  border-radius: 5px;
  
  &__count {
    height: 24px;
    width: 35px;
    color: $text-light-grey-cap;
    font-family: $lato-regular;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 7.5px;
  }
  &.small {
    .quantity-selector__count {
      height: 24px;
      width: 35px;
      color: $text-light-grey-cap;
      font-family: $lato-regular;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
}