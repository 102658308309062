.tabs{
    &.vertical-tabs{
        .cmp-tabs{
            .cmp-tabs__tabpanel{
                .tabbed-benefits {
                    padding: 0;
            
                    @include breakpoint(tab-max) {
                        border-left: none;
                    }
            
                    .cmp-teaser {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding-left: 5.6875rem;
                        @include breakpoint(desktop-lg){
                            flex-direction: inherit;
                        }
                        @include breakpoint(tab-max) {
                            padding: 0;
                        }
            
                        @include breakpoint(mobile) {
                            flex-direction: column-reverse;
                        }
            
                        &__content {
                            max-width: 22.625rem;
                            background: transparent;
                            text-align: left;
                            margin-top: 3.125rem;
                            padding: 0;
                            min-height: unset;
                            @include breakpoint(tab) {
                                width: 21.1875rem;
                                padding: 0;
                                margin-right: 9%;
                                margin-top: 67px;
                            }
            
                            @include breakpoint(mobile) {
                                width: 100%;
                                padding: 3.375rem 0 3.125rem;
                                max-width: initial;
                                margin: 0;
                            }
                        }
            
                        &__description {
                            padding: 0;
                            animation: fadeIn;
            
                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6 {
                                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                                letter-spacing: normal;
                                margin-bottom: 0.9375rem;
                                position: relative;
                                color: $text-dark-grey;
            
                                &:before {
                                    content: "";
                                    width: 0.75rem;
                                    height: 0.75rem;
                                    position: absolute;
                                    left: -1.875rem;
                                    top: 0.5625rem;
                                    background: transparent
                                }
            
                                @include breakpoint(tab-max) {
                                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                                    padding-left: 0;
                                    margin-bottom: .625rem;
                                }
                            }
            
                            p {
                                @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                                letter-spacing: normal;
                                margin-bottom: 3.125rem;
                                color: $text-medium-dark-grey;
            
                                @include breakpoint(tab-max) {
                                    margin-bottom: 1.875rem;
                                }
            
                                &:last-child {
                                    margin: 0;
                                }
                            }
                        }
            
                        &__image {
                            max-width: 22.5rem;
                            margin-right: -0.125rem;
            
                            @include breakpoint(tab) {
                                width: 21.25rem;
                                height: 28.813rem;
                                padding: 0;
                                margin-right: 0;
                                position: initial;
                            }
            
                            @include breakpoint(mobile) {
                                max-width: 100%;
                                height: 100%;
                                padding: 0;
                                position: initial;
                                max-height: unset;
                                min-height: 100%;
                            }
            
                            .cmp-image {
                                @include breakpoint(desktop-small) {
                                    min-width: 360px;
                                }
            
                                @include breakpoint(tab) {
                                    min-width: 341px;
                                    min-height: auto;
                                    height: auto;
                                }
            
                                @include breakpoint(mobile) {
                                    min-width: 339px;
                                    min-height: 250px;
                                    max-height: 250px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
