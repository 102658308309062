.article-content-style {
    padding: 4.375rem 2.25rem 4.375rem;
    max-width: 1600px;
    margin: 0 auto;

    @include breakpoint(tab) {
        padding: 3.125rem 0 3.125rem 2rem;
    }

    @include breakpoint(mobile) {
        padding: 3.125rem 1.125rem;
    }

    .cmp-teaser {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-content: center;
        max-width: 1380px;
        margin: 0 auto;

        @include breakpoint(tab) {
            flex-direction: inherit;
            align-items: flex-start;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        &__content {
            width: 28.125rem;

            @include breakpoint(tab) {
                width: 45.18%;
            }

            @include breakpoint(mobile) {
                width: 100%;
            }
        }

        &__pretitle {
            h1, h2, h3, h4, h5, h6, p {
            @include ann-describtion(0.75rem, $lato-regular, 1rem);
            letter-spacing: .125rem;
            color: $text-dark-grey;
            text-transform: uppercase;
            padding-bottom: 10px;

            @include breakpoint (tab-max) {
                padding-bottom: .625rem;
            }
        }
        }

        &__title {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include headingtitle_h3;
                padding-bottom: 3.125rem;

                @include breakpoint(tab-max) {
                    font-size: 2.25rem;
                    line-height: 2.75rem;
                    letter-spacing: -0.125rem;
                    padding-bottom: 1.875rem;
                }
            }
        }

        &__description {
            padding: 0;
            @include breakpoint (desktop-small) {
                width: 28.125rem;
            }
            @include breakpoint (tab) {
                width: 23.5rem;
            }

            p {
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                letter-spacing: normal;
                color: $text-medium-dark-grey;
                padding-bottom: 1.875rem;
                margin: 0;

                @include breakpoint(tab) {
                    padding-bottom: 2.5rem;
                }

                @include breakpoint(mobile) {
                    padding-bottom: 20px;
                }
                &:last-child {
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    letter-spacing: normal;
                    padding-bottom: 2.5rem;

                }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                letter-spacing: 0;
                color: $text-dark-grey;
                padding-bottom: 1.875rem;
                margin: 0;

                @include breakpoint(tab) {
                    padding-bottom: 1.25rem;
                }

                @include breakpoint(mobile) {
                    padding-bottom: 1.25rem;
                    padding-right: 1.375rem;
                }
            }
        }

        .cmp-teaser__action-link.gbh-secondary-button{
            color:$color-medium-blue;
            border-color:$color-medium-blue;
            padding: 1rem 1.6875rem;
            @include ann-describtion (12px, $lato-regular, 16px);
            letter-spacing: 2px;
            @include breakpoint(tab){
                width: 14.125rem;
            }
        }
    }

    .cq-dd-image {
        &.cmp-teaser__image {
            width: 56.52%;

            @include breakpoint(tab) {
                width: 21.1875rem;
                max-height: 350px;
            }

            @include breakpoint(mobile) {
                width: 100%;
            }
            .cmp-image {
                .cmp-image__image {
                    img {
                        @include breakpoint (tab) {
                            max-height: 350px;
                        }
                    }
                }
            }
        }
    }

    &.article-right-image {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        @include breakpoint (desktop-lg) {
        padding-right: 0;
        }
        @include breakpoint(mobile) {
            padding: 1.25rem 1.125rem 3.125rem;
        }

        .cmp-teaser {
            flex-direction: row-reverse;
            @include breakpoint (desktop-lg) {
                margin-right: 0;
            }

            @include breakpoint(tab) {
                flex-direction: row-reverse;
            }

            @include breakpoint(mobile) {
                flex-direction: column;
            }

            &__content {
                @include breakpoint (desktop-small) {
                    padding-left: 3.75rem;
                }
                @include breakpoint (desktop-lg) {
                    padding-left: 0;
                }

                @include breakpoint(tab) {
                    padding: 0 0 3.125rem;
                }

                @include breakpoint(mobile) {
                    padding-left: 0;
                    padding-top: 50px;
                }
            }

            &__image {
                .cmp-image {
                    @include breakpoint(desktop-small) {
                        max-width: 780px;
                        max-height: 784px;
                    }

                    @include breakpoint(tab) {
                        min-width: 338px;
                    }

                    @include breakpoint(mobile) {
                        min-width: 338px;
                    }
                }
            }
        }

        .cmp-img-caption {
            width: 56%;
            padding-top: 1.1875rem;

            @include breakpoint(tab) {
                width: 41.7%;
                position: absolute;
                top: 400px;
                padding-top: .9375rem;
            }

            @include breakpoint(mobile) {
                width: auto;
                position: absolute;
                top: 43.2%;
                left: 1.125rem;
                padding-top: .875rem;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                font-family: $lato-regular;
                color: $text-dark-grey;
                font-size: .75rem;
                letter-spacing: .01375rem;
                line-height: 1rem;

                @include breakpoint(tab) {
                    letter-spacing: .0125rem;
                    font-size: .6875rem;
                }
            }
        }
    }

    &.article-left-image {
        position: relative;
        @include breakpoint (desktop-lg) {
            padding-left: 0;
        }

        @include breakpoint(tab) {
            padding: 3.125rem 2rem 0;
        }

        .cmp-teaser {
            @include breakpoint (desktop-lg) {
                max-width: 84.375rem;
                margin: 0;
            }
            .cmp-teaser__image {
                @include breakpoint(mobile) {
                    width: 100%;
                }
            }

            &__content {
                padding-left: 0;
                padding-right: 6.75rem;
                width: 34.68%;

                @include breakpoint(tab) {
                    padding: 0 0 49px;
                    width: 45.18%;
                }

                @include breakpoint(mobile) {
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 70px;
                    width: 100%;
                }
            }

            &__description {
                @include breakpoint(tab) {
                    padding: 0;
                    padding-right: 29px;
                }
            }

            &__image {
                .cmp-image {
                    @include breakpoint(desktop-small) {
                        max-width: 780px;
                        max-height: 784px;
                    }

                    @include breakpoint(tab) {
                        min-width: 338px;
                        min-height: 350px;
                    }

                    @include breakpoint(mobile) {
                        min-width: 338px;
                    }
                }
            }
        }

        .cmp-img-caption {
            max-width: 1380px;
            margin: 0 auto;
            padding-top: 1.1875rem;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                font-family: $lato-regular;
                color: $text-dark-grey;
                font-size: .75rem;
                letter-spacing: .01375rem;
                line-height: 1rem;

                @include breakpoint(tab) {
                    letter-spacing: .0125rem;
                }
            }

            @include breakpoint(tab) {
                width: 41.7%;
                padding-top: 16px;
                position: absolute;
                top: 400px;
            }

            @include breakpoint(mobile) {
                position: absolute;
                top: 42%;
                padding-top: .875rem;
            }
        }
    }
}