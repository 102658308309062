.carousel-slick{
    padding:0;
    &.carousel-hidden-desktop {
        padding:0;
        .cmp-carousel{
            .carousel-header-info{
                display:none;
            }
            .slick-initialized{
                margin:0;
                padding: 4.375rem 0;
                @include breakpoint(tab-max){
                    padding: 3.125rem 0;
                }
                .slick-list{
                    padding:0 5rem 0 6.875rem;
                    margin:0;
                    @include breakpoint(tab){
                        padding:0 1.375rem 0 2rem;
                    }
                    @include breakpoint(mobile){
                        padding:0 1.6875rem 0 1.125rem;
                    }
                    .slick-track{
                        .slick-slide{
                            .teaser{
                                .cmp-img-caption{
                                    h1,h2, h3, h4,h5,h6,p{
                                        padding-top:0;
                                    }
                                }
                            }
                        }
                    }
                }
                .slick-slide{
                    margin-right:0;
                }                
                .slick-dots{
                    display: none;
                }
                &.three-up-items{
                    .slick-dots{
                        @include breakpoint(mobile){
                            margin-top: 1.875rem;
                            margin-bottom: 0;
                        }
                    }
                } 
            }
        } 
        &.content-five-up{
            .cmp-carousel{
                .slick-initialized{
                    padding: 4.375rem 0 4.375rem;
                    @include breakpoint(tab-max){
                        padding:3.125rem 0 3.125rem;
                    }
                    .slick-list{
                        @include breakpoint(mobile){
                            padding-right:4.8125rem;
                        }
                    }                    
                    .slick-dots{
                        display: none;
                        margin-top: 3.125rem;
                        top: unset!important;
                        @include breakpoint(tab-max){
                            margin-top: 2.5rem;
                        }
                    }
                } 
            } 
            .teaser{
                .cmp-img-caption{
                    h1,h2,h3,h4,h5,p{
                        @include ann-describtion(.875rem, $lato-regular, 16px);
                    }
                }
            }
        }
                  
    }
    &.carousel-header {
        padding: 4.375rem 0;
        @include breakpoint(tab){
            padding:3.125rem 0;
        }
        @include breakpoint(mobile){
            padding:3.125rem 0 6.875rem;
        }
        &.content-three-up{
            padding-top: 0;
            @include breakpoint(tab-max){
                padding-top: 3.125rem;
            }
            .cmp-carousel{
                &.cmp-carousel-slick{
                    .carousel-header-info{
                        padding: 4.375rem 0 50px;
                        @include breakpoint(tab-max){
                            padding: 0 2rem 2.5rem;
                        }
                        .cmp-carousel{                            
                            &__action-container{
                                @include breakpoint(mobile){
                                    bottom: 10px;
                                    margin:0 0 3.125rem;
                                }
                            }
                            &__action-link{
                                @include breakpoint(mobile){
                                    top: 0;
                                }
                            }
                        }
                        h1,h2,h3,h4,h5,h6{
                            @include breakpoint(tab-max){
                                margin-bottom: 1.25rem;
                            }
                        }
                    }
                    .slick-initialized{
                        .slick-dots{
                            margin-top: 3.125rem;
                            @include breakpoint(tab){
                                margin-top: 1.875rem;
                            }
                            @include breakpoint(mobile){
                                margin: 1.875rem 0 2.5rem 0;
                            }
                        }
                    }
                }
            }
            
        }
        &.content-five-up{
            padding: 4.375rem 0 4.375rem;
            @include breakpoint(tab){
                padding: 3.125rem 0 3.125rem;
            }
            @include breakpoint(mobile){
                padding:3.125rem 0 6.25rem;
            }
            .cmp-carousel {        
                &.cmp-carousel-slick {            
                    .carousel-header-info { 
                        padding-bottom: 5rem;
                        @include breakpoint(tab-max){
                            padding-bottom: 3.125rem;
                        }
                        @include breakpoint(mobile){
                            position:static;
                            padding-bottom: 40px;
                        }
                        h1,h2,h3,h4,h5,h6,p{
                            font-size:12px;
                            line-height:16px;
                            letter-spacing:2px;
                            margin-bottom: 1.25rem;
                            font-family:$lato-regular;
                            color:$text-dark-grey;
                            @include breakpoint(tab-max){
                                margin-bottom: .625rem;
                            }
                        }               
                        .cmp-carousel {
                            &__title {
                                width:100%;
                                h1,h2,h3,h4,h5,h6,p{
                                    margin-bottom: 2.0625rem;
                                    @include breakpoint(tab){
                                        margin-bottom: 1.0625rem;
                                    }
                                    @include breakpoint(mobile){
                                        margin-bottom: 1.3125rem;
                                    }
                                }
                            }
                            &__action-container{
                                margin-top: 1.75rem;
                                top: 0;
                                @include breakpoint(tab){
                                    margin-top: 0;
                                }
                                @include breakpoint(mobile){
                                    width:100%;
                                    top:unset;
                                    bottom:0;
                                    padding:0 1.125rem;
                                    margin-top:0;
                                    margin-bottom:3.125rem;
                                }
                            }
                        }
                    }
                    .slick-initialized{
                        .slick-list{
                            padding-right: 5.625rem;
                            @include breakpoint(tab){
                                padding-right:2rem;
                            }
                            @include breakpoint(mobile){
                                padding-right:4.8125rem;
                            }
                            .slick-track{
                                .slick-slide{
                                    @include breakpoint(tab){
                                        margin-right:0;
                                    }
                                    .collection-teaser-five{
                                        .cmp-teaser{
                                            .cmp-teaser__image{
                                                    @include breakpoint(mobile){
                                                        margin-bottom: 1.875rem;
                                                    }
                                                    .cmp-teaser__image{
                                                        @include breakpoint(mobile){
                                                            margin-bottom: 0;
                                                        }
                                                    }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .slick-dots{
                            line-height:0;
                            margin-top:3.125rem;
                            top: unset!important;
                            @include breakpoint(tab){
                                margin-top:2.5rem;
                            }
                            @include breakpoint(mobile){
                                margin:2.5rem 0 3.125rem;
                            }
                        }
                    }
                }
            }
        }
        .cmp-carousel {        
            &.cmp-carousel-slick {            
                .carousel-header-info { 
                    max-width:1380px;
                    margin:0 auto;
                    padding:0 0 3.125rem;
                    position: relative;
                    @include breakpoint(mobile){
                        position:static;
                    }
                    @include breakpoint(desktop-lg){
                        margin: 0 6.875rem
                    }
                    @include breakpoint(tab){
                        padding:0 2rem 2.5rem;
                    }
                    @include breakpoint (mobile){
                        padding:0 1.125rem 2.5rem;
                        position:static;
                    }
                    @include breakpoint(tab-max){
                        margin:0 auto;
                    }
                    h1,h2,h3,h4,h5,h6{
                        @include ann-describtion(.75rem, $lato-regular, 1rem);
                        text-transform:uppercase;
                        letter-spacing:.125rem;
                        margin-bottom: 1.25rem;
                        padding:0;
                        text-align:left;
                        color:$text-dark-grey;
                        @include breakpoint(tab){
                            margin-bottom: .625rem;
                        }
                        @include breakpoint(mobile){
                            margin-bottom: .625rem;
                        }
                    }               
                    .cmp-carousel {
                        &__title {
                            width:100%;
                            h1,h2,h3,h4,h5,h6,p{
                                @include headingtitle_h2;
                                color: $text-dark-grey;
                                margin-bottom: 1.25rem;
                                text-transform:initial;
                                @include breakpoint(tab-max){
                                    letter-spacing:-0.125rem;
                                }
                                @include breakpoint(tab){
                                    margin-bottom: 1.25rem;
                                }
                                @include breakpoint(mobile){
                                    margin-bottom: .625rem;
                                }
                            }
                        }
                        &__description{
                            @include breakpoint(mobile){
                                width:100%;
                            }
                            p{
                                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                                width: 41.1875rem;
                                text-align:left;
                                color:$text-medium-dark-grey;
                                letter-spacing:normal;
                                @include breakpoint(tab-max){
                                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                                }
                                @include breakpoint(tab){
                                    width:26.9375rem;
                                }
                                @include breakpoint(mobile){
                                    width:100%;
                                }
                            }
                        }
                        &__action-container{
                            position: absolute;
                            right:0;
                            margin: 4rem 0 0 0;
                            // top:0;
                            @include breakpoint(tab){
                                margin:0;
                                width:auto;
                                text-align: right;
                                right:2rem;
                                top: 0;
                            }
                            @include breakpoint(mobile){
                                bottom:3.75rem;
                                padding:0 1.125rem;
                                top:inherit;
                                width:100%;
                            }
                        }
                        &__action-link{
                            border:1px solid $color-medium-blue;
                            color:$color-medium-blue;
                            @include breakpoint(tab-max){
                                position: relative;
                                bottom:0;
                            }
                            @include breakpoint(tab){
                                width:auto;
                            }
                            @include breakpoint(mobile){
                                width:100%;
                            }
                        }
                    }
                }
                .slick-initialized{
                    .slick-list{
                        padding:0 5rem 0 6.875rem;
                        margin:0;
                        @include breakpoint(tab){
                            padding:0 2rem;
                        }
                        @include breakpoint(mobile){
                            padding:0 1.6875rem 0 1.125rem;
                        }
                    }
                    .slick-dots{
                        width:auto;
                        margin:3.1875rem auto 0;
                        display:inline-block;
                    }
                }
            }
        } 
    }
    &.content-three-up{
          //for collection slider 
          &.carousel-header-content {
            @include breakpoint(mobile){
                padding-bottom: 140px;
            }
            .more-collection-teaser {
                .cmp-teaser{
                    &__image{
                        .cmp-image{
                            @include breakpoint(desktop-lg){
                                max-width: unset;
                                max-height: unset;
                            }
                            @include breakpoint(tab){
                                min-width: unset;
                                min-height: unset;
                            }
                            @include breakpoint(mobile){
                                min-width: unset;
                                min-height: unset;
                            }
                        }
                    }
                }
            }
        }
         //for collection slider 
        .more-collection-teaser{
            padding:0;
            .cmp-teaser{
                &__image{
                    .cmp-image{
                        @include breakpoint(desktop-lg){
                            max-width: 440px;
                            max-height: 600px;
                        }
                        @include breakpoint(tab){
                            min-width: 320px;
                            min-height: 410px;
                        }
                        @include breakpoint(mobile){
                            min-width: 320px;
                            min-height: 410px;
                        }
                    }
                }
            }
        }
        .slick-initialized{
            .slick-dots{
                @include breakpoint(tab){
                    display: block;
                    margin: 30px 0 0;
                }
            }
        }
        .cmp-carousel{
            &.cmp-carousel-slick{
                .slick-initialized{
                    .slick-track{
                        .slick-slide{
                            @include breakpoint(desktop-small){
                                max-width: 100%;
                            }
                            @include breakpoint(tab-max){
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        } 
    }
    &.content-four-up{
        .slick-initialized{
            .slick-track{
                .slick-slide{
                    margin-right: 20px;
                }
            }
            .slick-dots{
                top:unset!important;
            }
        }
    }
    &.content-five-up{
        .cmp-carousel{
            max-width: 100%;
            margin: 0 auto;
            &.cmp-carousel-slick{
                .slick-initialized{
                    .slick-list{
                        padding:0 5.625rem 0 6.875rem;
                        @include breakpoint(tab){
                            padding:0 2rem;
                        }
                        @include breakpoint(mobile){
                            padding:0 4.8125rem 0 1.125rem;
                        }
                    }
                    .slick-track{
                        .slick-slide{
                            @include breakpoint(desktop-small){
                                margin-right:0;
                                max-width: 100%;
                            }
                            @include breakpoint(tab-max){
                                margin-right:0;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        } 
        .collection-teaser-five{
            .cmp-teaser{
                &__image{
                    .cmp-image{
                        @include breakpoint(desktop-lg){
                            max-width:260px;
                            max-height:328px;
                        }
                        @include breakpoint(tab){
                            min-width:242px;
                            min-height:321px;
                        }
                        @include breakpoint(mobile){
                            min-width:260px;
                            min-height:328px;
                        }
                    }
                }
            }
        }
    }
}

// More Collections Teaser collection Slider
.more-collection-teaser {
    @include breakpoint(desktop-small){
        padding:0 1.875rem 0 0 ;
    }
    @include breakpoint(tab-max){
        padding:0 .625rem 0 0 ;
    }
    .cmp-teaser {
        &__image{
            &.cq-dd-image {
            margin-bottom:0;
            .cmp-image__image{
                margin:0;
            }
            .cmp-img-caption{
                margin-top: 1.875rem;
                @include breakpoint(tab-max){
                    margin-top: 20px;
                }
            }
        }
        }
    }
    .cmp-img-caption{
        text-align: left;
        h1,h2,h3,h4,h5,h6,p {
            @include ann-describtion(0.875rem, $lato-regular, 1rem);
            color: $text-dark-grey;
            letter-spacing: .125rem;
            text-transform: uppercase;
            padding:0;
            @include breakpoint(tab-max){
                font-size: .75rem;
            }
        }
    }       
}
.collection-teaser-five {
    padding-right: 1.25rem;
    padding-left:0;
    @include breakpoint(tab){
        padding-right:.625rem;
    }
    @include breakpoint(mobile){
        padding-right: 1.25rem;
    }
    .cmp-teaser {
        &__image{
            margin-bottom:1.5625rem;
            .cmp-teaser__image{
                margin:0 !important;
            }
        }
    } 
    .cmp-img-caption{
        text-align: left;
        h1,h2,h3,h4,h5,h6,p {
            @include ann-describtion(.875rem, $lato-regular, 1rem);
            color: $text-dark-grey;
            letter-spacing: .125rem;
            text-transform: uppercase;
            @include breakpoint(tab){
                font-size: .75rem !important;
            }
            @include breakpoint(mobile){
                font-size: .875rem;
            }
        }
    }         
} 

.carousel-slick{
    &.content-three-up{
        &.carousel-header-content{
            .cmp-carousel{
                max-width: 100%;
            }
            .slick-initialized{
                .slick-dots{
                    top: unset!important;
                    @include breakpoint(mobile){
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
