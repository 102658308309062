.ann-sacks-tab-bar{
    .tabs{
        width:100%;
        @include breakpoint (desktop-extra-lite-sm) {
            height: fit-content;
            margin-top: 25px;
        }
        .cmp-tabs{
            &__tab{
                @include ann-describtion(14px, $lato-regular, 20px);
                letter-spacing: normal;
                color:$text-medium-dark-grey!important;
                margin:0;
                border:none;
                padding:0 0 1rem 0;
                margin-right:2.1875rem;
                text-transform:capitalize;
                cursor:pointer;
                @include breakpoint(desktop-extra-lite-sm){
                    @include ann-describtion (14px, $lato-regular, 16px);
                    letter-spacing:.125rem;
                    padding-bottom:25px;
                    margin:0;
                    text-transform:uppercase;
                    display:inline-flex;
                    &:last-child{
                        padding-bottom:0;
                    }
                }
            }
        }
    }
    &.nav-up .cmp-tabs .cmp-tabs__tablist .cmp-tabs__tab{
        color:$text-medium-dark-grey!important;
    }
}