.editorial-feature{
    &-left{
        width: 43.6875rem;
        margin-right:auto;
        padding:0;
        @include breakpoint(tab){
            width: 21.25rem;
            margin-right:9.4%;
        }
        @include breakpoint(mobile){
            width:100%;
            margin:0;
        }
        .cmp-teaser{
            display:flex;
            flex-direction:column-reverse;
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    @include breakpoint (desktop-small) {
                        width: 45rem;
                    }
                    @include headingtitle_h1;
                    color:$text-dark-grey;
                    margin-bottom: 3.125rem;
                    @include breakpoint(tab){
                        margin-bottom: 5rem;
                        letter-spacing: -0.175rem;
                    }
                    @include breakpoint(mobile){
                        margin-bottom: 2.5rem;
                        letter-spacing: -0.175rem;
                        padding-right: 1.625rem;
                    }
                }
            }
            &__description{
                p{
                    @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                    letter-spacing: normal;
                    color:$text-medium-dark-grey;
                    margin-bottom:8.75rem;
                    padding-right:24rem;
                    @include breakpoint(tab){
                        padding-right: 4.25rem;
                        margin-bottom: 3.75rem;
                    }
                    @include breakpoint(mobile){
                        padding:0;
                        margin-bottom: 3.125rem;
                        padding-right: 1.625rem;
                    }
                }
            }
            &__image{
                .cmp-image{
                    @include breakpoint(desktop-small){
                        max-width: 660px;
                        min-height: 850px;
                    }
                    @include breakpoint(tab){
                        min-width: 339px;
                        min-height: 363px;
                    }
                    @include breakpoint(mobile){
                        min-width: 263px;
                        max-height: 329px;
                        padding-left:4.77rem;
                    }
                    &__image{
                        @include breakpoint(tab-max){
                            min-height:inherit;
                        }
                        img{
                            padding:0;
                            margin:0;
                            min-height: 850px;
                            @include breakpoint(tab-max){
                                min-height:inherit;
                            }
                        }
                    }
                }
            }
        }
    }
    &-right{
        width: 22.5rem;
        padding: 4.8125rem 7.5rem 0 0;
        box-sizing:content-box;
        @include breakpoint(tab){
            padding:3.125rem 0 0;
            width: 21.25rem;
        }
        @include breakpoint(mobile){
            width:100%;
            padding:3.75rem 0 0;
        }
        .cmp-teaser{
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(1.625rem,$lato-regular, 2.25rem);
                    letter-spacing: normal;
                    padding-top:9.375rem;
                    padding-bottom:4.375rem;
                    @include breakpoint(tab){
                        @include ann-describtion(1.375rem,$lato-regular, 2rem);
                        padding: 3.75rem 4.1875rem 2.1875rem 0;
                    }
                    @include breakpoint(mobile){
                        @include ann-describtion(1.375rem,$lato-regular, 2rem);
                        padding:3.125rem 5.1875rem 2.1875rem 0;
                    }
                }
            }
            &__description{
                p{
                    margin-bottom:1.875rem;
                    width: 19.6875rem;
                    padding-right: 10px;
                    @include breakpoint (tab) {
                        padding-right: unset;
                    }
                    @include breakpoint(mobile){
                        padding-right: 38px;
                        margin-bottom:1.875rem;
                    }
                }
            }
            &__action-container {
            .cmp-teaser__action-link.gbh-secondary-button{
                @include ann-describtion (12px, $lato-bold,16px);
                letter-spacing: 2px;
                color: $color-medium-blue;
                padding: 1.125rem 2.7813rem;
                @include breakpoint(tab){
                    width: 21.1875rem;
                }
            }
            }
            &__image{
                @include breakpoint(tab-max){
                    min-height:inherit;
                }
                .cmp-image{
                    @include breakpoint(desktop-small){
                        min-width: 370px;
                        min-height: 462.5px;
                    }
                    @include breakpoint(tab){
                        min-width: 272px;
                        max-height:363px;
                        padding-right: 4.25rem;
                    }
                    @include breakpoint(mobile){
                        min-width: 142px;
                        max-height: 189px;
                        padding-right:12.3rem;
                    }
                    &__image{
                        @include breakpoint(tab-max){
                            min-height:inherit;
                        }
                        img{
                            padding:0;
                            margin:0;
                            min-height: 462.5px;
                            @include breakpoint(tab-max){
                                min-height:inherit;
                                max-height: 22.6875rem;
                            }
                        }
                    }
                }
            }
        }
    }
}