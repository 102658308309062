.select-box-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.select-box-label {
  font-weight: bold;
}

.select-box {
  height: 60px;
  padding: 0px 10px;
  border-radius: 5px;
}

.select-box-without-label {
  margin-top: 32px;
}
