.large-collection-header {
    &.text {
        .cmp-text {
            padding: 70px 0 50px;
            @include breakpoint (tab-max) {
                padding: 50px 0 40px;
            }
            h1, h2, h3, h4, h5, h6, p {
                width: 699px;
                @include ann-describtion(5.625rem, $playfair-regular, 6rem);
                letter-spacing: -0.1094rem;
                text-align: center;
                margin: 0 auto;
                color: $text-dark-grey !important;
                @include breakpoint (tab-max) {
                    width: 21.75rem;
                    font-size: 2.625rem;
                    line-height: 3rem;
                    letter-spacing: -0.175rem;
                }
            }
        }
    }
}
.large-collection-photo-carousel {
    &.carousel-slick {
        @include breakpoint (desktop-xlg) {
            padding-left: 270px;
        }
        @include breakpoint (desktop-lg) {
            padding-left: 110px;
        }
        @include breakpoint (tab-max) {
            padding-left: unset;
        }
        .cmp-carousel {
            &.cmp-carousel-slick{
                display: flex;
                flex-direction:column-reverse;
                max-width: 100%;
                @include breakpoint (tab){
                    padding-left: 32px;
                }
                @include breakpoint (mobile){
                    padding-left: 18px;
                }
                .carousel-header-info {
                    p:first-child {
                        text-transform: uppercase;
                        @include ann-describtion(.75rem, $lato-regular, 1rem);
                        color: $text-dark-grey;
                    }
                    width: 1380px;
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                    letter-spacing: .125rem;
                    color: $text-dark-grey;
                    @include breakpoint (desktop-small) {
                        padding-bottom: 70px;
                    }
                    @include breakpoint (desktop-mid) {
                        width: 86.25%;
                    }
                    @include breakpoint (tab) {
                        padding-left: 0; 
                        width: 100%;
                        padding-bottom: 50px;
                    }
                    @include breakpoint (mobile) {
                        padding-left: 0;
                        padding-bottom: 50px;
                    }
                    .cmp-carousel__title {
                        @include breakpoint (desktop-small) {
                        width: 21.625rem;
                        padding-bottom: 0 !important;
                        margin-bottom: 0;
                        position: absolute;
                        top: 20px;
                        }
                        h1, h2, h3, h4, h5, h6, p {
                            @include ann-describtion(3.375rem, $playfair-regular, 3.875rem);
                            letter-spacing: -0.0625rem;
                            color: $text-dark-grey;
                            text-transform: unset;
                            @include breakpoint (tab-max) {
                                padding-bottom: 0;
                                letter-spacing: -0.125rem;
                                padding-bottom: 1.875rem;
                                @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                                margin-bottom: 0;
                            }
                        }
                    }
                    .cmp-carousel__description {
                        width: 33rem;
                            p {
                                color: $text-medium-dark-grey;
                                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                                letter-spacing: normal;
                                color: $text-medium-dark-grey;
                                text-transform: unset;
                                margin-bottom: 0;
                                @include breakpoint (tab-max){
                                    @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                                    letter-spacing: 0.5px;
                                    margin-bottom: 0;
                                }
                            }
                        @include breakpoint (tab-max) {
                            margin-bottom: 40px;
                            width: 100%;
                            }
                            }
                    .cmp-carousel__action-container {
                        @include breakpoint (mobile) {
                            width: 21.75rem;
                        }
                    .cmp-carousel__action-link {
                        color: $color-medium-blue;
                        height :50px;
                        align-items: center;
                        @include ann-describtion(.75rem, $lato-bold, 1rem);
                        letter-spacing: 2px;
                        @include breakpoint (tab-max) {
                            width: 100%;
                            position: relative;
                            margin-bottom: unset;
                            @include ann-describtion(.75rem, $lato-regular, 1rem);
                            letter-spacing: 2px;
                        }
                        @include breakpoint (mobile) {
                            bottom: 0;
                    }
                }
                            }        
                        }   
                .cmp-carousel-slick__content {
                    .slick-list {
                        padding-left: 0 !important;
                        @include breakpoint (desktop-xlg) {
                            padding: 0 332px 0 0!important;
                        }
                        @include breakpoint (desktop-lg) {
                            padding-right: 172px !important;
                        }
                        @include breakpoint (tab) {
                            padding-right: 282px;
                        }
                        @include breakpoint (mobile) {
                            padding-right: 0px;
                        }
                        
                        .slick-track {
                            display: flex;
                            .slick-slide {
                                margin-right: 1.25rem;
                                @media (min-width: 1920px){
                                    width: 1298px !important;
                                }
                                @media (min-width: 2560px){
                                    width: 1731px !important;
                                }
                                @media (min-width: 1380px) and (max-width: 1440px){
                                    width: 974px !important;
                                }
                                @media (min-width: 1200px) and (max-width: 1280px){
                                    width: 866px!important;
                                }
                                @media (min-width: 1024px) and (max-width: 1200px){
                                    width: 695px!important;
                                }
                                @media (min-width: 0px) and (max-width: 375px){
                                    width: 357px !important;
                                }
                                @media (min-width: 376px) and (max-width: 390px){
                                    width: 373px !important;
                                }
                                @media (min-width: 391px) and (max-width: 414px){
                                    width: 373px !important;
                                }
                                @media (min-width: 600px) and (max-width: 638px){
                                    width: 634px !important;
                                }
                            }
                        @include breakpoint (tab) {
                            margin-left: 0;
                            }
                        }
                    }
                    ul.slick-dots {
                        @include breakpoint (desktop-small) {
                            width: 1170px;
                        }
                        top: 0 !important;
                        right: 0;
                        text-align: left;
                        margin-top: 2.5rem;
                        margin-bottom: 2.5rem;
                        @include breakpoint (tab) {
                            width: 748px;
                            margin: 30px 32px;
                            margin-left: 0;
                        }
                        @include breakpoint (mobile) {
                            margin: 30px 0;
                        }
                    }
                }
            }
        }
    }
    }
.bg-cool-grey-medium {
    &.container {
        background-color: $cool-medium-grey;
    }
}