.value-prop-benefits{
    padding:4.375rem 0;
    display:flex;
    max-width:1380px;
    margin:0 auto;
    align-items: center;
    @include breakpoint (desktop-mid){
        padding-right:2.25rem;
        padding-left:2.25rem;
    }
    @include breakpoint(tab){
        padding:50px 2rem;
        align-items: flex-start;
    }
    @include breakpoint(mobile){
        padding:50px 1.125rem;
        flex-direction:column;
    }
    .cmp-teaser{
        &__image{
            display:none;
        }
        &__content{
            display:flex;
            justify-content:space-between;
            align-items: center;
            width:100%;
            max-width:22.625rem;
            margin-right:auto;
            @include breakpoint(tab){
                max-width:21.25rem;
                align-items:baseline;
            }
            @include breakpoint(mobile){
                flex-direction: column;
            }
        }
        &__title{
            h1,h2,h3,h4,h5,h6,p{
            @include ann-describtion(2.625rem, $playfair-regular, 3rem);
            letter-spacing: -1.17px;
            color: $text-dark-grey;
            @include breakpoint (tab-max) {
                @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);       
               }
            }
            @include breakpoint(mobile){
                margin-bottom:3.125rem;
                width:100%;
            }
        }
        &__description{
            p{
                @include ann-describtion(.75rem, $lato-regular, 1rem);
                text-transform:uppercase;
                letter-spacing:.125rem;
                margin-bottom:2.336875rem;
                &:last-child{
                    margin-bottom:0;
                }
                @include breakpoint(tab){
                    margin-bottom:1.25rem;
                }
                @include breakpoint(mobile){
                    margin-bottom:1.708125rem;
                }
            }
        }
    }
    .cmp-img-caption{
        p{
            @include ann-describtion(.875rem, $lato-regular, 1rem);
            text-transform:uppercase;
            letter-spacing:.125rem;
            margin-bottom:1.875rem;
            &:last-child{
                margin-bottom:0;
            }
            @include breakpoint(tab-max){
                margin-bottom:1.25rem;
            }
        }
    }
    &.single-column{
        @include breakpoint(mobile){
            margin-left:0;
            box-sizing:content-box;
        }
        .cmp-teaser{
            &__title{
                @include breakpoint (mobile) {
                    margin-bottom: 2.1875rem;
                }
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                    letter-spacing: -1.17px;
                    color: $text-dark-grey;
                    padding-bottom: 0;
                    @include breakpoint (tab-max) {
                        @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);       
                    }
                    @include breakpoint (mobile) {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .cmp-img-caption{
            min-width:33.5625rem;
            color: $text-medium-dark-grey;
            @include breakpoint(tab){
                min-width:16.75rem;
                margin-right:4.5rem;
            }
            @include breakpoint(mobile){
                width:100%;
                min-width:100%;
                margin-top: 0;
            }
            p {
                color: $text-medium-dark-grey;
            }
        }
    }
    &.two-column{
        max-width:1380px;
        padding:4.375rem 0;
        align-items:center;
        @include breakpoint (desktop-mid){
            padding-right:2.25rem;
            padding-left:2.25rem;
        }
        @include breakpoint (tab){
            padding:50px 2rem;
        }
        @include breakpoint (mobile){
            padding:50px 1.125rem;
            align-items: flex-start;
        }
        .cmp-teaser{
            &__content{
                flex-direction:column;
                align-items:baseline;
                max-width:22.625rem;
                margin-left:0;
                margin-right:auto;
                @include breakpoint(tab-max){
                    width:19.25rem;
                }
            }
            &__title{
                @include breakpoint(mobile){
                    width:100%;
                    margin-bottom:1.25rem;
                }
                h1,h2,h3,h4,h5,h6,p{
                    padding-bottom: 0;
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                    letter-spacing: -1.17px;
                    color: $text-dark-grey;
            @include breakpoint (tab-max) {
                @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);       
               }
                    @include breakpoint(mobile){
                        margin-bottom:0;
                    }
                }
                
            }
            &__description{
                p{
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    text-transform:inherit;
                    letter-spacing: 0.44px;
                    @include breakpoint (desktop-small) {
                        margin-top: 20px;
                    }
                    @include breakpoint(mobile){
                        margin-bottom:3.125rem;
                        padding-right:3.875rem;
                    }
                }    
            }
        }
        .cmp-img-caption{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-right:15.9375rem;
            max-width:21.5625rem;
            justify-content: flex-start;
            color: $text-medium-dark-grey;
            @include breakpoint(tab-max){
                margin-right:0;
                justify-content: flex-start;
            }
            @include breakpoint(tab){
                max-width:18.5625rem;
                margin-right:2.6875rem;
            }
            @include breakpoint(mobile){
                max-width:16.375rem;
                margin-top: 0;
            }
            p{
                @include ann-describtion(.875rem, $lato-regular, 1rem);
                text-transform:uppercase;
                letter-spacing:.125rem;
                margin-bottom:1.875rem;
                width:6.25rem;
                margin-right:8rem;
                color: $text-medium-dark-grey;
                &:nth-child(even){
                    margin-right:0;
                }
                @include breakpoint(tab-max){
                    @include ann-describtion(.75rem, $lato-regular, 1rem);
                    margin-bottom:1.875rem;
                    margin-right:auto;
                    width: 6rem;
                }
                &:nth-child(5n), &:last-child{
                    margin-bottom:0;
                }
            }
        }
    }
}
.bg-color-charcoal {
    .cmp-container {
        .teaser {
            &.value-prop-benefits {
                .cmp-img-caption {
                    p {
                        color: $white;
                    }
                }
            }
        }
    }
} 
