.order-list {
    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        margin-bottom: 30px;
        font-family: $font-light;
        font-size: 60px;
        line-height: 1.13;
        letter-spacing: -3.1px;
        color: $color-slate;
    }

    &__empty-state {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 55px;
        border-top: 1px solid $color-dark-gray;

        &__desc {
            font-family: $font-light;
            font-size: 36px;
            line-height: 1.22;
            letter-spacing: -1.7px;
            color: $color-dark-gray;
        }

        .kf-react-button {
            width: 300px;
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    @media screen and (max-width: 1024px) {
        margin-bottom: 80px;

        &__title {
            margin-bottom: 20px;
            font-size: 40px;
            line-height: 46px;
            letter-spacing: -2.5px;
        }

        &__empty-state {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            column-gap: 30px;
            padding-top: 45px;
    
            &__desc {
                font-size: 32px;
                line-height: 1.25;
                letter-spacing: -2.2px;
                margin-bottom: 0;
            }
            .kf-react-button {
                width: auto;
                padding-left: 60px;
                padding-right: 60px;
            }
        }
    }

    @media screen and (max-width: 576px) {
        &__empty-state {
            display: block;
            padding-top: 45px;
    
            &__desc {
                font-size: 32px;
                line-height: 1.25;
                letter-spacing: -2.2px;
                margin-bottom: 40px;
            }
        }
    }
    &.pastOrderHeader {
        @media screen and (min-width: 1024px) { 
            margin-top: 120px ; 
        } 
   } 
}