.productcard{
    .product-card {
        &__img-container {
            min-height: 597px;
            @include breakpoint(tab){
                min-height: 216px;
            }
            @include breakpoint(mobile){
                min-height: 216px; 
            }
        }

        &__link {
            &__details {
                &__left {
                    &__color-text {
                        span {
                            line-height: 18px;
                            font-family: $lato-regular;
                            letter-spacing: 0.2px;
                            font-size: 13px;
                            color: $text-medium-dark-grey;
                        }
                    }
                }
            }
        }
    }
    @media screen and (orientation:landscape) and (max-width: 900px) {
        .product-card__link {
            .product-card__details {
              display: flex;
            }
        }
        .product-card__link .product-card__img-container+.product-card__details{
            display: flex;
        }
        .product-card__details {
            .product-card__left {
                flex: 0 0 73%;
                max-width: 73%;
            }
            .product-card__right {
                flex: 0 0 27%;
                max-width: 27%;
            }
        }
    }
}
.product-card__link .product-card__details .product-card__right .product-card__discount-price{
    text-decoration: unset;
    span{
        text-decoration: line-through;    
    }

}
@media screen and (min-width: 1024px){
    .product-card {
      width: 458px;
        &__link {
          width: 100%;
          visibility: visible!important;
          .product-card__img-container{
            display: flex;
            width: 100%;  
            height: 597px;
            background: $color-white;
            >img{
                width: 100%;
                height: 322px;
                align-self: center;
            }
        }
      }
    }
  }
.product-card {
    &__price-type {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 10px;
        letter-spacing: 1.67px;
        line-height: 16px;
        text-align: right;
        margin-left: 3px;
        font-weight: normal;
        @include breakpoint(tab) {
            margin-left: 5px;
        }
        @include breakpoint(mobile) {
            margin-left: 5px;
        }
    }
    &__startingat {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 11px;
        letter-spacing: 1.67px;
        line-height: 14px;
        text-align: right;
        padding-right: 17px;
        @include breakpoint(tab) {
            padding-right: 0;
        }
        @include breakpoint(mobile) {
            padding-right: 0;
        }
    }
    &__color-finish {
        margin-left: 3px;
    }
}
.productcard {
    .product-card__details {
        @include breakpoint(tab) {
            .product-card__left {
                flex: 0 0 67%;
                max-width: 67%;
            }
            .product-card__right {
                flex: 0 0 33%;
                max-width: 33%;
            }
        }
        @include breakpoint(mobile) {
            .product-card__left {
                flex: 0 0 67%;
                max-width: 67%;
            }
            .product-card__right {
                flex: 0 0 33%;
                max-width: 33%;
            }
        }
    }
}