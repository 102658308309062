.promotest{
    width: 500px;
    left:50%;
    margin-left:-250px;
    padding:0 !important;
    @include breakpoint(tab-max){
        width:100%;
        left:0;
        margin-left:0;
    }
    .modal-dialog {
        @include breakpoint(tab-max){
            max-width:100%;
        }
        .modal-content {
            border:none;
            border-radius:0;
            @include breakpoint(desktop-small){
                height: 85vh;
                max-height:42.75rem;
            }
            @include breakpoint(tab-max){
                place-self:flex-start;
            }
            @include breakpoint(mobile){
                height:100vh;
            }
            .modal-header{
                flex-direction:row-reverse;
                z-index:3;
                padding:0;
                border:none;
                @include breakpoint (mobile) {
                    padding-top: 15px;
                }
                .close{
                    padding: 35px 40px 15px 0;
                    margin: 0;
                    line-height: 20px;
                    @include breakpoint(tab){
                        padding:22px 32px 13px 0;
                        line-height:17px;
                    }
                    @include breakpoint(mobile){
                        padding:7px 18px 17px 0;
                        line-height:16px;
                    }
                    span{
                        font-size:32px;
                        color:$text-light-grey;
                        cursor: pointer;
                        font-weight:normal;
                        line-height:0;
                        @include breakpoint(tab-max){
                            color:$charcoal;
                        }
                        @include breakpoint(mobile){
                            right:18px;
                            top:10px;
                        }
                    }
                }
            }
            .modal-body {
                padding:0;
                overflow-y:auto;
                background-color:$cool-light-grey;
                &::-webkit-scrollbar{
                    width: 1px;
                }
                .cmp-promo-content{
                    position: relative;
                    z-index:2;
                    padding:0 0 50px;
                    .cmp-promo-title{
                        background-color:$white;
                        *{
                            @include ann-describtion(22px,$lato-regular,32px);
                            text-align: left;
                            letter-spacing: normal;
                            color: $text-dark-grey;
                            padding:0 40px 50px;
                            margin:0;
                            @include breakpoint(tab-max){
                                @include ann-describtion(18px, $lato-regular, 28px);
                                text-align:center;
                            }
                            @include breakpoint(mobile){
                                text-align:left;
                                padding:0 18px 50px;
                            }
                        }
                    }
                    .cmp-promo-description{
                        padding:40px 40px 50px;
                        color:$text-dark-grey;
                        @include breakpoint(tab){
                            padding:57px 0 50px 105px;
                            margin-right:auto;
                        }
                        @include breakpoint(mobile){
                            padding:40px 18px 50px;
                        }
                        p{
                            @include ann-describtion(16px, $lato-regular, 24px);
                            letter-spacing: normal;
                            padding-bottom:20px;
                            &:last-child{
                                padding-bottom:0;
                            }
                        }
                        ul {
                            padding-bottom:30px;
                            margin-left:17px;
                            margin-bottom:0;
                            li {
                                @include ann-describtion(14px,$lato-regular,20px);
                                letter-spacing:normal;
                                color: $text-dark-grey;
                                padding-bottom:25px;
                                margin-bottom: 0;
                                &:last-child{
                                    padding-bottom:0;
                                }
                            }
                        }
                        p,ul{
                            @include breakpoint(tab){
                                width:475px;
                                margin-right:auto;
                            }
                        }
                    }
                    .cmp-promo-btn-wrapper{
                        @include breakpoint(tab){
                            max-width:611px;
                            margin-left: 105px;
                        }
                        @include breakpoint(mobile){
                            margin:0 18px;
                        }
                    }
                    .cmp-teaser__action-link {
                        width: 420px;
                        background: $color-medium-blue;
                        color: $white;
                        padding: 12px 0 14px 0;
                        margin: 0 auto;
                        border:none;
                        display: block;
                        @include ann-describtion(0.75rem, $lato-bold, 1.5rem);
                        letter-spacing: 0.15625rem;
                        @include breakpoint(tab-max){
                            width:100%;
                        }
                    }
                }
            }
        }
    }
}