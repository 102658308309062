// Global tab start here
body {
  &.gbh-of-scroll {
    overflow: hidden !important;
    height: 100vh;
  }
}

.utilitynavheader {
  @media screen and (min-width: 992px) {
    padding: 0;
  }
}

.gbh-utility-nav {
  .gbh-nav__findStore {
    @include breakpoint(desktop-extra-lite-sm) {
      a {
        &.gbh-nav__findStore--link {
          color: $text-dark-grey;
          min-width: 107px;
          &::before {
            visibility: visible;
            content: "\e903";
            font-size: 20px;
            color: $text-dark-grey;
            order: 2;
            margin-right: unset;
            margin-left: 10px;
            top: 4px;
            position: relative;
            width: 14.68px;
            height: 21.34px;
          }
        }
        transition: inherit;
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInRight;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
      }
      .auth-nav-icon {
        color: $cool-medium-grey !important;
        &::before {
          color: $cool-medium-grey !important;
        }
      }
    }
  }
  .gbh-utility-navbar ul li a {
    padding: 0;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.45px;
    text-decoration: none;
    text-transform: capitalize;
  }
}

.ann-main-nav {
  margin-top: 0;
  width: auto;
  .cmp-tabs {
    ol {
      list-style: none;
      padding-left: 0;
    }
    text-align: left;
    // display: flex;
    @include breakpoint(desktop-extra-lite-sm) {
      background-color: $cool-light-grey;
      width: 100%;
      -webkit-transition: 500ms ease all;
      transition: 500ms ease all;
      z-index: 99;
      padding: 0;
      margin: 0;
    }
    &__tablist {
      max-width: 1820px;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      height: 100%;
      @include breakpoint(desktop-extra-lite-sm) {
        flex-direction: column;
        position: relative;
        z-index: 2;
        text-align: left;
        flex-wrap: inherit;
        height: auto;
        padding: 0;
      }
      .slider {
        border-color: $color-dark-blue;
      }
    }

    &__tab {
      color: $text-medium-dark-grey;
      transition: 0.4s ease-in-out;
      @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
      letter-spacing: normal;
      padding: 0 0 1rem;
      margin: 0 2.1875rem 0 0;
      text-transform: capitalize;
      cursor: pointer;
      line-height: 1pc;
      font-size :14px;
      position: relative;
      white-space: nowrap;
      text-transform: uppercase;
      @include breakpoint(desktop-extra-lite-sm) {
        padding-bottom: 2.5625rem;
        margin-right: 0;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        border: none;
      }
      @include breakpoint(mobile) {
        padding-bottom: 25px;
        border: none;
      }
      &.cmp-tabs__tab--active {
        @include breakpoint (desktop-extra-lite) {
          border-bottom: 3px solid $black!important;
        }
        @include breakpoint(desktop-extra-lite-sm) {
          color: $text-medium-dark-grey;
          border-color: transparent;
          &:after {
            content: none;
          }
        }
      }
      &:last-child {
        margin: 0;
      }
    }
    &__tabpanel--active {
      position: absolute;
      left: 0;
      @include breakpoint(desktop-extra-lite-sm) {
        top: 0;
      }
      animation-name: slideInleft;
    }

    .gbh-close {
      color: $white;
      font-size: 16px;
      position: relative;
      z-index: 999;
      top: 0;
      padding: 22px 18px;
      background-color: $white;
      margin-right: 0;
      display: none;
      @include breakpoint(desktop-extra-lite-sm) {
        width: 21px;
        height: 21px;
        padding: 0 0 30px;
        display: block;
        color: $text-medium-dark-grey;
        background-color: $cool-light-grey;
        box-sizing: content-box;
        &::before {
          font-size: 21px;
        }
      }
    }
  }
  &.open {
    .cmp-tabs {
      @include breakpoint(desktop-extra-lite-sm) {
        left: 0;
        z-index: 2;
      }
      @include breakpoint(mobile) {
        padding: 0;
      }
    }
  }
  .gbh-mobilebottom-menu {
    @include breakpoint(desktop-extra-lite-sm) {
      display: block;
      width: 100%;
      left: 0;
      bottom: 30px;
      top: 0;
      z-index: 0;
    }
    ul {
      @include breakpoint(desktop-extra-lite-sm) {
        justify-content: flex-end;
        height: 100%;
      }
      @include breakpoint(mobile) {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
      }
      @include breakpoint(mobile-landscape) {
        margin-left: -32px;
        position: unset;
        width: 104.5%;
      }
      li {
        @include breakpoint(mobile) {
          padding: 17px 32px;
        }
        @include breakpoint(desktop-extra-lite-sm) {
          &.gbh-region-mob {
            background-color: $black;
          }
          &.gbh-find-place {
            top: 30px;
            padding-top: 0;
            a {
              padding: 0;
            }
          }
        }
      }
    }
  }
  button.gbh-mob-back-btn {
    @include breakpoint(desktop-small) {
      display: none;
    }
  }
}

.hamber-menu {
  cursor: pointer;
  display: none;

  @include breakpoint(desktop-extra-lite-sm) {
    display: flex;
  }
}

.globalnavigation {
  @include breakpoint(desktop-extra-lite-sm) {
    width: 100% !important;
  }

  // product menu start
  .gbh-product-menu {
    background-color: rgba($color: $black, $alpha: 0.7);
    min-height: unset;
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 0;
    left: -110%;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    overflow: auto;
    @include breakpoint(desktop-extra-lite-sm) {
      &.open {
        left: 0;
      }
      top: 0;
      overflow: inherit;
      height: 100vh;
      max-height: 750pt;
      position: absolute;
      z-index: 99;
      @include breakpoint (desktop-extra-lite-sm) {
        height: 100%;
      }
      .gbh-pro-menu {
        &__inner {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          overflow: scroll;
          height: 100% !important;
          display: flex;
          position: relative;
          .tab-content {
            .tab-pane:nth-child(odd) {
              @include breakpoint(desktop-extra-lite-sm) {
                margin-right: 1.25rem;
              }
              @include breakpoint(mobile) {
                margin-right: 1.875rem;
              }
            }
            .tab-pane {
              margin-top: 0;
              display: block;
              margin-bottom: 0;
              height: 513px;
              .cmp-container {
                height: 459px;
                padding: 0;
                overflow: hidden;
                .annsacks-navigation-teaser {
                  padding: 0;
                  max-width: 51.25rem;
                  margin: 0 auto;
                  .cmp-teaser {
                    &__content {
                      display: none;
                    }
                    &__action-container {
                      display: none;
                    }
                    .cmp-image {
                      &__image {
                        width: 364px;
                        height: 459px;
                      }
                    }
                  }
                }
                .teaser {
                  width: 100%;
                }
              }
            }
          }
          .gbh-pro-menu__cont {
            padding: 0;
            z-index: auto;
        top: 6.875rem;
        background: #f7f7f7;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 21px 2rem 0;
        margin-top: 52px;
          } 
        }
      }
      ul.nav {
        width: auto;
        height: auto;
        top: 50px;
        @include breakpoint (tab) {
          top: 10px;
        }
        @include breakpoint (mobile) {
          top: 50px;
        }
      }
    }
    &.open {
      top: 0 !important;
      transition: all 1s ease-out;
    }
    &::-webkit-scrollbar-track {
      background-color: $cool-light-grey;
    }
    &::-webkit-scrollbar {
      width: 0;
      background-color: $cool-light-grey;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $cool-medium-grey;
    }
    &.open {
      left: 0;
      background: $white;
      .gbh-pro-menu__inner {
        .gbh-pro-menu__cont {
          .tab-pane {
            .ann-mob-nav {
              @include breakpoint(desktop-extra-lite-sm) {
                display: block;
                font-size: 0.75rem;
                line-height: 1rem;
                letter-spacing: 0.1069rem;
                color: $text-medium-dark-grey;
                .nav-link {
                  padding-left: 0;
                  padding-top: 30px;
                }
              }
            }
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      scrollbar-width: none;
    }

    .gbh-pro-menu__inner {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 100%;
      @include breakpoint(desktop-extra-lite-sm) {
        flex-direction: column;
        background: $cool-light-grey;
      }
      .tab-content {
        width: 100%;
        background: $white;
        @include breakpoint(desktop-extra-lite-sm) {
          position: absolute;
          left: 0;
          width: 100%;
          top: 60px;
          padding-top: 15px;
        }
        .active {
          display: flex;
          width: 100%;
          justify-content: center;
          @include ann-describtion(12px, $lato-bold, 24px);
          letter-spacing: 2.33px;
          color: $color-medium-blue;
          .globalnavigation-subcategory {
            display: flex;
            width: 100%;
            @include breakpoint(desktop-extra-lite-sm) {
              width: 100%;
            }
          }
        }
        .tab-pane {
          @include breakpoint(desktop-extra-lite-sm) {
            margin-top: 0;
            display: block;
            margin-bottom: 0;
          }
          @include breakpoint(mobile) {
            margin-bottom: 1.4375rem;
          }
          &:nth-child(odd) {
            @include breakpoint(desktop-extra-lite-sm) {
              margin-right: 1.25rem;
            }
            @include breakpoint(mobile) {
              margin-right: 1.875rem;
            }
          }
        }
      }
      .gbh-pro-menu__cont {
        transition: inherit;
        animation-name: fadeInLeft;
        display: flex;
        @include breakpoint(desktop-extra-lite-sm) {
          z-index: 99;
          top: 7.5rem;
          background: $cool-light-grey;
          height: 100%;
          display: flex;
          flex-direction: row;
          padding: 0 2rem;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-top: 21px;
          margin-top: 80px;
          row-gap: 95px;
          padding-left: 14px;
          position: absolute;
          left: 0;
          width: 100%;
        }
        @include breakpoint(mobile) {
          padding: 0 1.125rem;
          margin-top: 110px;
          row-gap: 25px;
        }
      }
      .gbh-pro-menu__list {
        .gbh-promenu-close-btn {
          display: none;

          @include breakpoint(desktop-extra-lite-sm) {
            display: flex;
          }
        }
      }
      .ann-mob-shop-link {
        display: none;
        @include breakpoint(desktop-extra-lite-sm) {
          @include ann-describtion(0.6875rem, $lato-regular, 0.75rem);
          letter-spacing: 0.1144rem;
          padding-bottom: 7px;
          position: absolute;
          bottom: 20px;
          right: 32px;
          color: $color-light-blue;
          border-bottom: 1px solid $color-light-blue;
          display: none;
        }
        @include breakpoint(mobile) {
          bottom: 40px;
        }
      }
    }
    @include breakpoint(mobile) {
      .gbh-pro-menu {
        &__inner {
          .tab-content {
            .tab-pane:nth-child(odd) {
              margin-right: 1.875rem;
            }
            .tab-pane {
              margin-bottom: 1.4375rem;
              height: 181px;
              .cmp-container {
                height: 150px;
                .annsacks-navigation-teaser {
                  .cmp-teaser {
                    .cmp-image {
                      &__image {
                        width: 154px;
                        height: 150px;
                      }
                    }
                  }
                }
              }
            }
          }
          .gbh-pro-menu__cont {
            @include breakpoint(mobile) {
            padding: 0 18px;
            }
          }
        }
      }
    }
    &.hide-mobile-images {
      .gbh-pro-menu__inner {
        .gbh-pro-menu__cont {
          display: block;
          .tab-pane {
            width: unset !important;
            height: unset !important;
            margin-bottom: 25px;
            .ann-mob-nav {
              margin-top: unset;
              .nav-link {
                @include ann-describtion(14px, $lato-regular, 16px);
                letter-spacing: 2px;
                padding: 0;
              }
            }
            .cmp-container {
              @include breakpoint(desktop-extra-lite-sm) {
                display: none;
              }
              .annsacks-navigation-teaser {
                .cmp-teaser {
                  @include breakpoint(desktop-extra-lite-sm) {
                    display: none;
                  }
                }
              }
            }

            &:nth-child(odd) {
              @include breakpoint(desktop-extra-lite-sm) {
                margin-right: 1.25rem;
              }
              @include breakpoint(mobile) {
                margin-right: 1.875rem;
              }
            }
          }
        }
      }
    }
  }

  .gbh-promenu-close-btn {
    margin: 96px 0 0 50px;
    cursor: pointer;

    @include breakpoint(desktop-lg) {
      margin: 96px 0 0 40px;
    }

    &.icon-Close-Large {
      font-size: 50px;
      color: $text-medium-dark-grey;
      height: 30px;
      position: absolute;
      right: 3.125rem;
      top: 2.5rem;
      margin: 0;
      z-index: 9;
      -webkit-text-stroke: 2px $white;

      @include breakpoint(desktop-extra-lite-sm) {
        font-size: 16px;
        margin: 0;
        position: relative;
        padding: 22px 0 47px 32px;
        display: none;
      }
      @include breakpoint(mobile) {
        padding: 22px 0 47px 18px;
      }
    }

    @include breakpoint(desktop-extra-lite-sm) {
      position: absolute;
      margin: 30px 18px;
      z-index: 9999;
      width: 16px;

      img {
        width: 100%;
      }
    }
  }

  ul.nav {
    background-color: $cool-light-grey;
    height: 100vh;
    max-height: 1000px;
    margin: 0;
    padding-top: 90px;
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    z-index: 2;
    overflow: auto;
    scrollbar-width: none;
    @include breakpoint(desktop-extra-lite-sm) {
      width: auto;
      padding-left: 0;
      padding-right: 2rem;
      padding-bottom: 30px;
      height: auto;
    }
    @include breakpoint(mobile) {
      padding: 0.875rem 0;
      top: 4rem;
    }
    &::-webkit-scrollbar-track {
      background-color: $cool-light-grey;
    }
    &::-webkit-scrollbar {
      width: 0;
      background-color: $cool-light-grey;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $cool-medium-grey;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 6px 0 30px;
      font-size: 15px;
      font-family: $lato-regular;
      letter-spacing: -0.4px;
      color: $charcoal;
      line-height: normal;
      display: none;
    }

    li {
      width: auto;
      padding-bottom: 35px;
      &:last-child {
        margin-bottom: 150px;
      }
      @include breakpoint(desktop-extra-lite-sm) {
        width: 100%;
        display: none;
      }

      img {
        display: none;

        @include breakpoint(desktop-extra-lite-sm) {
          display: flex;
        }
      }

      span {
        @include ann-describtion(1.375rem, $lato-regular, 2rem);
        letter-spacing: -0.0625rem;
        color: $dark-gray;
        padding: 2px 0 2px 0;
        cursor: pointer;

        @include breakpoint(desktop-extra-lite-sm) {
          @include ann-describtion(32px, $lato-regular, 38px);
          color: $white;
          padding: 0 0 0 18px;
          letter-spacing: -1.9px;
        }
        @include breakpoint(mobile-landscape) {
          padding: 0 0 0 32px;
        }
        @include breakpoint(tab) {
          padding: 0 0 0 32px;
        }

        &:hover,
        &.active {
          text-decoration: none;
        }
      }
      a {
        &.link-active {
          color: $text-dark-grey !important;
        }
        @include ann-describtion(14px, $lato-regular, 16px);
        font-family: $lato-regular !important;
        letter-spacing: 2px;
        color: $text-medium-dark-grey;
        text-transform: uppercase;
        padding: 0;
        @include breakpoint(tab) {
          @include ann-describtion(0.75rem, $lato-regular, 1.5rem);
          letter-spacing: 0.125rem;
          color: $text-dark-grey;
        }
        @include breakpoint(mobile) {
          @include ann-describtion(0.75rem, $lato-regular, 1rem);
          letter-spacing: 0.1069rem;
        }
        &:hover,
        &.active {
          text-decoration: none;
          color: $text-dark-grey;
        }

        &.nav-link {
          border: 0;
          display: inline-block;
          transition: all 0.5s ease-in-out;
          cursor: pointer;
        }
      }
      &:last-child {
        @include breakpoint(desktop-extra-lite-sm) {
          padding-bottom: 50px;
        }
      }
    }
    &.gbh-pro-collection {
      background-color: $white;
      @include breakpoint(mob-desktop-extra-lite-sm) {
        margin-top: 30px;
      }

      .gbh-promenu-close-btn {
        display: none;

        @include breakpoint(desktop-extra-lite-sm) {
          display: flex;
        }
      }

      li.nav-item {
        width: intrinsic;
        width: -moz-max-content;
        width: -webkit-max-content;
        width: auto;

        @include breakpoint(desktop-extra-lite-sm) {
          width: 100%;
        }

        .nav-link {
          width: 100%;
          height: 100%;
          @include breakpoint(mob-desktop-extra-lite-sm) {
            max-height: 477px;
            img {
              object-fit: fill;
              overflow: hidden;
            }
          }
          @include breakpoint(mobile-landscape) {
            max-height: 501px;
          }
          @include breakpoint(mobile) {
            max-height: 256px;
            overflow: hidden;
          }
        }
      }

      @include breakpoint(desktop-extra-lite-sm) {
        background-color: $charcoal;
        margin-top: 0;
        align-content: start;
        z-index: 9;

        li.nav-item {
          .nav-link {
            padding: 0;
          }
          span {
            @include ann-describtion(1.375rem, $helvetica-medium, 1.875rem);
            color: $white;
            letter-spacing: -0.0625rem;
            min-height: 55px;
            margin: 10px 0;
          }
        }
      }

      li.nav-item.gbh-pro-collection__category-link {
        @include breakpoint(desktop-extra-lite-sm) {
          position: absolute;
          right: 18px;
          width: auto;
          margin-top: 50px;
        }
        @include breakpoint(tab) {
          right: 32px;
        }
        @include breakpoint(mobile-landscape) {
          right: 32px;
        }

        .nav-link {
          @include breakpoint(mobile) {
            max-height: auto;
          }
          span {
            @include ann-describtion(1.375rem, $lato-regular, 2rem);
            letter-spacing: -0.0625rem;
            color: $dark-gray;
            @include breakpoint(desktop-extra-lite-sm) {
              @include ann-describtion(16px, $lato-regular, 24px);
              letter-spacing: -0.55px;
              color: $light-white;
              min-height: auto;
              margin-bottom: 0;
              &::before {
                content: "Shop ";
              }
              &:hover {
                font-family: $lato-regular;
              }
            }
            @include breakpoint(mobile) {
              padding-right: 0px;
              margin-top: 0;
            }
            &:hover {
              font-family: $helvetica-medium;
              color: $charcoal;
              @include breakpoint(desktop-extra-lite-sm) {
                color: $light-white;
                font-family: $lato-regular;
              }
            }
          }
        }

        a {
          @include breakpoint(desktop-extra-lite-sm) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $light-white;
            text-decoration: underline;
          }

          img {
            display: none;
          }
        }
      }
    }
    &.gbh-two-column {
      @include breakpoint(desktop-extra-lite-sm) {
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        overflow: auto;
        li.nav-item {
          width: 49.5%;
          .nav-link {
            width: 100%;
            height: 100%;

            @include breakpoint(mob-desktop-extra-lite-sm) {
              max-height: 540px;
            }
            @include breakpoint(mobile) {
              display: flex;
              justify-content: space-between;
              max-height: 287px;
              overflow: hidden;
            }
          }

          &:nth-child(odd) {
            margin-right: 5px;
          }
        }
      }
      @include breakpoint(mobile) {
        li.nav-item {
          width: 49%;
        }
        background-color: $white;
      }
    }
  }

  button.gbh-mob-back-btn {
    @include breakpoint(desktop-small) {
      display: none;
    }
    @include breakpoint(desktop-extra-lite-sm) {
      color: $text-dark-grey;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin: 0;
      align-items: center;
      width: 100%;
      font-family: $lato-regular;
      border: none;
      background: transparent;
      position: relative;
      @include breakpoint(mobile) {
        margin: 0;
        padding-bottom: unset;
      }
      &::before {
        content: "\e92c";
        margin-right: 12.75px;
        font-size: 1rem;
        color: $text-medium-dark-grey;
      }
    }

    a {
      display: flex;
      margin-left: auto;
      color: $white;
      color: $light-white;
      text-decoration: underline;
    }
  }

  .gbh-pro-category {
    color: $white;
    position: absolute;
    z-index: 9;
    right: 16px;
    top: 85px;
    text-decoration: underline;
    display: none;

    @include breakpoint(desktop-extra-lite-sm) {
      display: flex;
    }
  }

  .gbh-pro-menu__list {
    width: 530px;
    padding-left: 2.6%;
    box-sizing: content-box;
    @include breakpoint(desktop-mid) {
      padding-left: 36px;
    }
  }

  .gbh-pro-menu__cont {
    .gbh-pro-collection {
      width: 100%;
      padding: 5.625rem 7.5rem;
      overflow: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 118px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #d4c9c9;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      @include breakpoint(desktop-extra-lite-sm) {
        width: 100%;
        padding: 22px 0 22px 14px;
      }

      @include breakpoint(desktop-lg) {
        padding-left: 40px;
      }
      .gbh-mob-back-btn {
        @include breakpoint(desktop-extra-lite-sm) {
          padding: 0 32px;
        }
        @include breakpoint(mobile) {
          padding: 0 18px;
        }
      }
    }

    .gbh-pro-collection-cont {
      background: $light-white;
      @include breakpoint(desktop-extra-lite-sm) {
        display: flex;
        z-index: 10;
        flex-wrap: wrap;
        overflow-y: scroll;
        padding-bottom: 50px;
        margin-bottom: 5pc;
      }
      .tab-pane {
        width: 300px;
        flex-direction: column;
        background-color: $half-white;
        height: 100vh;
        min-height: unset;
        padding-top: 157px;
        max-height: 1000px;

        @include breakpoint(desktop-extra-lite-sm) {
          height: auto;
          max-height: inherit;
          width: 49%;
          opacity: 1;
          display: block;
          margin-bottom: 5px;
          padding: 0;
        }
        .gbh-subcattext {
          margin: 15px 18px;
          color: $text-dark-grey;
          display: block;
        }

        &.active {
          display: flex;
          p {
            font-family: $lato-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $dark-gray;
            padding-top: 20px;
            padding-right: 48px;
          }
          img {
            width: 300px;
            height: 400px;
            object-fit: cover;
            @include breakpoint(desktop-lg) {
              width: 100%;
              height: 385px;
            }
          }
        }
        &.fade {
          transition: inherit;
          -webkit-animation-name: inherit;
          animation-name: inherit;
          -webkit-animation-duration: inherit;
          animation-duration: inherit;
          img,
          p,
          span,
          a,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            transition: inherit;
            -webkit-animation-name: opacity;
            animation-name: opacity;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
          }
        }
      }
    }

    .fade {
      transition: inherit;
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      @include breakpoint(desktop-extra-lite-sm) {
        width: 45%;
        opacity: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
  @include breakpoint(desktop-large) {
    .gbh-pro-menu {
      &__inner {
        .tab-content {
          .tab-pane {
            .cmp-container {
              .annsacks-navigation-teaser {
                .cmp-teaser div div .cmp-image .cmp-image {
                  .cmp-image {
                    &__image {
                      width: 473px;
                      height: 674px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include breakpoint(desktop-lg) {
    .gbh-pro-menu {
      &__inner {
        .tab-content {
          .tab-pane {
            .cmp-container {
              .annsacks-navigation-teaser {
                .cmp-teaser div div .cmp-image .cmp-image {
                  .cmp-image {
                    &__image {
                      width: 415px;
                      height: 600px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .teaser .cmp-teaser {
    &__action-container {
      box-sizing: border-box;
      height: 50px;
      width: 130px;
      border: 1px solid $color-medium-blue;
      border-radius: 1px;
      display: flex;
      align-self: flex-end;
      position: relative;
    }
    &__action-link {
      height: 24px;
      width: 76px;
      color: $color-medium-blue;
      font-family: $lato-bold;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 24px;
      text-align: center;
      border: none;
      text-transform: uppercase;
      margin: auto;
    }
  }
  ul.nav {
    @include breakpoint(desktop-extra-lite-sm) {
      margin-top: unset;
      top: -30px;
      padding: 1.875rem 32px 0 32px;
      padding-top: 81px;
      margin-top: unset;
    }
    @include breakpoint(mobile) {
      padding: 1.875rem 18px;
    }
  }
}

.brand-logo {
  margin: 0;

  &.image {
    margin: 0;
  }
}
.auth-nav {
  .auth-nav__menu {
    @include breakpoint(desktop-extra-lite-sm) {
      position: fixed;
      border-bottom: 1px solid $text-medium-dark-grey;
      background: $color-dark-blue !important;
      bottom: 4.375rem;
    }

    @include breakpoint(tab) {
      padding: 1.5625rem 2rem 1.75rem!important;
    }
    @include breakpoint(mobile) {
      padding: 1.5625rem 1.125rem 1.75rem!important;
    }
    .auth-nav__menu-items{
      @include breakpoint(tab-max){
        margin-left: 0!important;
      }
    }
    .container {
      padding: 0;
      .auth-nav__menu-wrap {
        @include breakpoint(mobile) {
          padding-top: 0;
        }
        .auth-nav__menu-items {
          span,
          .gbh-data-layer-custom {
            line-height: 0;
            padding-bottom: 1.6875rem;
            a {
              letter-spacing: 0.125rem;
              text-transform: uppercase;
              color: $white;
            }
            &:last-child {
              padding: 0;
            }
          }
          .gbh-data-layer-custom {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .auth-nav__menu-wrap {
    .auth-nav__menu-items {
      span {
        a {
          font-size: 0.875rem !important;
          font-family: $lato-regular !important;
          line-height: 1rem !important;
          letter-spacing: 0.125rem !important;
          color: $white !important;
          text-transform: uppercase;
        }
      }
      p a {
        font-size: 0.875rem !important;
        font-family: $lato-regular !important;
        line-height: 1rem !important;
        letter-spacing: 0.125rem !important;
        color: $white !important;
        text-transform: uppercase;
      }
    }
  }
  &--active{
    .auth-nav__menu-mobile-back-menu{
      @include breakpoint(tab-max){
        padding: 0!important;
      }
    }
  }
}
@include breakpoint(desktop) {
  .ann-main-nav {
    .cmp-tabs {
      .gbh-close {
        display: none;
      }
    }
  }
}
@include breakpoint(desktop-lg) {
  .ann-main-nav {
    .cmp-tabs {
      .gbh-close {
        display: none;
      }
    }
  }
}

// Animation

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    transition: all 0.5s ease-out;
  }

  to {
    opacity: 1;
    transition: all 0.5s ease-out;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
    transition: all 0.5s ease-out;
  }

  to {
    opacity: 1;
    transition: all 0.5s ease-out;
  }
}
@keyframes slideInLeft {
  4.07% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}

.ann-sacks-logobar {
  z-index: 1;
  position: relative;
  background: $white;
  .cmp-container {
    .aem-GridColumn {
      &.brand-logo {
        max-width: 120px;
        margin: 0 30px 0 0;
        @include breakpoint(desktop-extra-lite-sm) {
          height: 30px;
          width: 100%;
          margin: 0;
        }
        .cmp-image {
          @include breakpoint(desktop-extra-lite-sm) {
            height: 100%;
          }
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
    .brand-logo {
      max-width: 120px;
      margin: 0 30px 0 0;
      @include breakpoint(desktop-extra-lite-sm) {
        height: 30px;
      }
      .cmp-image {
        @include breakpoint(desktop-extra-lite-sm) {
          height: 100%;
        }
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    .gbh-hamber-menu {
      display: none;
      @include breakpoint(desktop-extra-lite-sm) {
        display: block !important;
        margin-right: 20px;
      }
      &.gbh-close {
        @include breakpoint(desktop-extra-lite-sm) {
          margin-right: 0;
        }
        &:before {
          color: $text-medium-dark-grey;
        }
      }
    }
  }
  &.header-light {
    background: transparent;
    border-bottom: solid 1px;
    border-color: rgba($color: $extra-light-gray, $alpha: 0.3);
  }
  // mobile view icon
  .aem-GridColumn--default--12.aem-GridColumn.minicart,
  .minicart {
    display: none;
    @include breakpoint(desktop-extra-lite-sm) {
      display: inline-block;
      width: 30px !important;
      margin-left: 15px;
      a {
        &:after {
          content: "\e904";
          font-size: 18px;
          color: $charcoal;
        }
        img {
          display: none;
        }
      }
    }
  }
}
.ann-sacks-main-menu {
  background: $color-dark-blue;
  &.ann-sacks-tab-bar {
    .cmp-container {
      .utilitywidgets {
        @include breakpoint(desktop-extra-lite-sm) {
          position: absolute;
          top: -42px;
          right: 32px;
        }
        @include breakpoint(tab) {
          right: 32px;
        }
        @include breakpoint(mobile) {
          right: 18px;
        }
        .utility-widgets {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: row;
        }
        .search-widget {
          justify-content: flex-end;
          margin-right: 0;
          .search-button {
            .search-icon {
              margin-left: 10px;
            }
          }
        }

        @include breakpoint(desktop-extra-lite-sm) {
          position: absolute;
          top: -42px;
          right: 32px;
        }
        @include breakpoint(tab) {
          right: 32px;
        }
        @include breakpoint(mobile) {
          right: 18px;
        }
        .utility-widgets {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: row;
          @include breakpoint(desktop-extra-lite-sm) {
            display: none;
          }
        }
        .search-widget {
          justify-content: flex-end;
          margin-right: 0;
          .search-button {
            .search-icon {
              margin-left: 10px;
            }
          }
        }
      }
      @include breakpoint(desktop-extra-lite-sm) {
        width: 100%;
        z-index: 2;
        position: fixed;
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        padding: 5pc 32px 0;
        background-color: $cool-light-grey;
        row-gap: 25px;
        -webkit-animation: fadeInLeft 0.5s;
        animation: fadeInLeft 0.5s;
      }
      @include breakpoint(mobile) {
        padding-left: 18px;
      }
    }
    .tabs {
      width: 100%;
      align-self: auto;
    }
  }
}

.contrast-el.ann-sacks-logobar {
  background-color: transparent;
}
.cmp-mobilebottom-menu {
  display: none;
}

.bg-dim {
  background: rgba(0, 0, 0, 0.3);
  background-position: inherit;
  height: 350vh;
  top: 100px;
  left: 0px;
}

.button-format {
  .tabs.ann-main-nav {
    &.open {
      .cmp-tabs {
        height: 100%;
      }
    }

  }
}

.ann-main-nav {
  &.open {
  .cmp-tabs {
    .container {
      .cmp-container {
        padding-bottom: 100% !important;
      }
    }
    }
    &__tab {
      a {
        color: $charcoal;
        @include breakpoint(desktop-extra-lite-sm) {
        }
      }
    }
  }
}

// Header Mega menu container
.ann-sacks-tab-bar {
  @include breakpoint(mobile) {
    padding: 0 1.125rem;
  }
  @include breakpoint(tab) {
    padding: 0 2rem;
  }
  @include breakpoint(desktop-mid) {
    padding: 0 2.25rem;
  }
  @include breakpoint(desktop-small) {
    margin: 0 auto;
    .UtilityWidgets_utilityWidgetsWrapper__UNcYr {
      @include breakpoint(desktop-small) {
        margin-left: auto;
      }
    }
  }
  @include breakpoint(desktop-extra-lite-sm) {
    display: none;
  }
  @include breakpoint(desktop-extra-lite) {
    padding: 0 2.6%;
  }
  .cmp-container {
    max-width: 1820px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @include breakpoint(desktop-extra-lite-sm) {
      width: 100%;
    }
    .dynamicsearch {
      justify-content: flex-end;
      align-items: flex-end;
      margin-left: auto;
      text-align: right;
      max-width: 200px;
      @include breakpoint(desktop-extra-lite-sm) {
        width: auto;
        position: absolute;
        top: -2.5rem;
        right: 2rem;
      }
      @include breakpoint(mobile) {
        right: 1.125rem;
      }
    }
  }
  .auth-nav-header {
    background: $color-dark-blue !important;
  }
}
.search-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  border: 0;
  .search-text {
    font-family: $lato-regular;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-medium-dark-grey;
    letter-spacing: normal;
    text-transform: capitalize;
  }
}

// contrast scss start here
.transparent-menu-white {
  .ann-sacks-logobar {
    background-color: transparent;
  }
  .brand-logo {
    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(346deg)
        brightness(104%) contrast(102%);
    }
  }

  .ann-main-nav .cmp-tabs .cmp-tabs__tablist .cmp-tabs__tab {
    color: $white;
    a {
      color: $white;
    }
  }
  .breadcrumb {
    .cmp-breadcrumb .cmp-breadcrumb__list .cmp-breadcrumb__item {
      span {
        color: $white;
      }
      .cmp-breadcrumb__item-link {
        color: $white;
        span {
          color: $white;
        }
      }
    }
    .cmp-breadcrumb
      .cmp-breadcrumb__list
      .cmp-breadcrumb__item.cmp-breadcrumb__item--active {
      span {
        color: $white;
      }
    }
  }
  .search-button {
    .search-text {
      color: $white;
      a {
        color: $white;
      }
    }
    .search-icon {
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(346deg)
          brightness(104%) contrast(102%);
      }
    }
  }
  .gbh-hamber-menu {
    color: $white;
  }
  .ann-sacks-logobar
    .aem-GridColumn--default--12.aem-GridColumn.minicart
    a:after,
  .ann-sacks-logobar .minicart a:after {
    color: $white;
  }
}

.transparent-menu-black {
  .ann-sacks-logobar {
    background-color: transparent;
  }
}
// contrast scss end here

.header-mega-menu-container {
  &.container {
    padding: 0 36px;
    @include breakpoint(mobile) {
      padding: 0 18px;
    }
    @include breakpoint(mobile-landscape) {
      padding: 0 32px;
    }
    @include breakpoint(tab) {
      padding: 0 32px;
    }
    @include breakpoint(desktop-extra-lite-sm) {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}

.ann-main-nav.tabs .cmp-tabs__tablist {
  @media screen and (max-width: 1200px) {
    position: relative;
    z-index: 2;
    text-align: left;
    flex-wrap: inherit;
    height: auto;
    padding: 0;
    overflow-x: scroll;
    @include breakpoint (desktop-extra-lite-sm) {
      overflow-x: unset;
    }
    .selectionBarcss.selectionBar-horizontal {
      display: none;
    }
  }
}
// skip to main content start &  Help us improve styles start

.cmp-embed {
  &.index-help {
    z-index: 0;
    display: flex;
    background-color: $color-light-grey;
    justify-content: flex-end;
    color: $slate;
    align-items: center;
    text-align: right;
    padding: 0 2.6%;
    @include breakpoint(desktop-large) {
      padding: 0 50px;
    }
    @include breakpoint(tab) {
      padding: 0 32px;
    }
    @include breakpoint(mobile) {
      align-items: initial;
      flex-direction: column;
      text-align: left;
      padding: 0 18px;
    }
    .gbh-helpcont {
      width: 100%;
      max-width: 1820px;
      margin: 0 auto;
      padding: 15px 0;
      @include breakpoint(tab-max) {
        padding: 15px 0;
      }
      .gbh-helpus {
        @include ann-describtion(0.8125rem, $lato-regular, 1.25rem);
        margin-right: 1.25rem;
        letter-spacing: 0.0125rem;
        color: $text-medium-dark-grey;
        @include breakpoint(tab) {
          margin-right: 1.25rem;
        }
        @include breakpoint(mobile) {
          padding-bottom: 1.25rem;
          display: block;
        }
      }
      a {
        color: $slate;
        border: 1px solid $slate;
      }
      .gbh__btn--link {
        @include ann-describtion(13px, $lato-regular, 16px);
        letter-spacing: 0.19px;
        text-align: center;
        padding: 13px 15px 15px;
        border: 1px solid $color-light-blue;
        text-transform: capitalize;
        border-radius: 3px;
        width: auto;
        @include breakpoint(tab) {
          width: 9.8125rem;
          padding: 13px 15px 15px;
        }
        @include breakpoint(mobile) {
          text-align: center;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }
  position: relative;
  z-index: 0;
}
.skip-content {
  width: 0;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-color: $light-gray;
  position: absolute;
  z-index: -9;
  top: auto;
  overflow: hidden;
  opacity: 0;
  color: $charcoal;
  transition: opacity 0.5s ease;
}

.skip-content:focus:not(:focus-visible),
.skip-content:focus-visible,
.skip-content:focus {
  opacity: 1;
  width: 100%;
  z-index: 999;
  outline: 1px dashed currentcolor;
  outline-offset: -2px;
  text-decoration: underline;
  position: initial;
}
//Signin Popup modal Motion design
.modal-popup {
  &.show-modal-popup {
    animation: fadeIn 1s;
  }
}
//Order status font-style chenges
.utility-widgets {
  .auth-widget {
    .auth-nav__user {
      font-family: $helvetica-medium;
      color: $color-white;
    }
  }
}
.auth-nav__user {
  color: $white;
  @include breakpoint(desktop-extra-lite-sm) {
    @include ann-describtion(14px, $font-regular-base, 16px);
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
.globalnavigation .global-menu-selected {
  @include breakpoint(desktop-extra-lite) {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    @include ann-describtion(18px, $lato-regular, 22px);
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 30px 0 0 0 !important;
    color: $text-dark-grey;
  }
}

/* New button SASS for header*/
.ann-sacks-logobar {
  z-index: unset;
}
.ann-sacks-main-menu {
  &.container {
    .cmp-container {
      .button {
        padding: 0;
        flex-direction: column;
        transform: none;
        transition: none;
        height: 40px;
        @include breakpoint (desktop-extra-lite-sm) {
          height: auto;
          overflow: hidden;
        }
        .cmp-button {
          @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
          letter-spacing: normal;
          padding: 0 0 1rem;
          margin: 0 2.1875rem 0 0;
          color: $text-medium-dark-grey;
          text-transform: capitalize;
          cursor: pointer;
          border: none;
          font-weight: normal;
          @include breakpoint(desktop-extra-lite-sm) {
            margin: 0;
            text-transform: uppercase;
            text-align: start;
            padding: unset;
            @include ann-describtion(14px, $lato-regular, 16px);
            letter-spacing: 2px;
            transform: none;
            height: auto;
          }
          @include breakpoint(desktop-mid) {
            font-size: 14px;
            line-height: 20px;
          }
          &.cmp-button-active{
            @include breakpoint (desktop-extra-lite) {
            border-bottom: 3px solid $black;
            border-radius: unset;
            }
          }
        }
      }
      @include breakpoint(mobile) {
        overflow-x: hidden;
      }
    }
    &.ann-sacks-tab-bar {
      .cmp-container {
        @include breakpoint(desktop-extra-lite) {
          justify-content: start;
        }
      }
      .utilitywidgets {
        @include breakpoint(desktop-extra-lite) {
          margin-left: auto;
        }
      }
    }
  }

  &.button-format {
    position: static !important;
    &.ann-sacks-tab-bar {
        @include breakpoint (desktop-extra-lite-sm) {
        position: absolute;
        }
    }
    &.container {
        .cmp-container {
            @include breakpoint (desktop-extra-lite-sm) {
                z-index: 3;
                position: fixed;
                display: block;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                padding: 50px 18px 0;
                background-color: $cool-light-grey;
                row-gap: 25px;
                animation:  fadeInLeft .5s;
                overflow: scroll;
                &.icon-close-medium {
                    &.icon-Close-Medium {
                        &.icon-close {
                            padding-bottom: 16.75rem;
                            height: 100vh;
                        }
                    }
                } 
                @include breakpoint (mobile) {
                    padding-left: 18px;
                    padding-right: 18px;
                    height: 100vh;
                }
                @include breakpoint (tab) {
                    padding-left: 32px;
                    padding-right: 32px;
                }
                @include breakpoint (desktop-extra-lite-lg) {
                    padding-left: 26px;
                    padding-right:26px;
                }
            }
        }
    }
   
}
  .button {
    &.menu-link {
      @include breakpoint(desktop-extra-lite-sm) {
        position: relative;
        z-index: 2;
        text-align: left;
        -ms-flex-wrap: inherit;
        flex-wrap: inherit;
        height: auto;
        padding: unset;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 0.125rem;
        margin: 0;
        display: inline-flex;
        align-items: flex-start;
        margin: 25px 0 0;
        width: 100%;
        .button.kf-react-button.cmp-button {
          display: block;
        }
      }
    }
  }
}

//utility nav
.utility-widgets {
  .auth-widget {
    font-family: $lato-regular;
    background: $color-dark-blue;
    @include breakpoint(desktop-extra-lite-sm) {
      position: fixed;
      z-index: 3;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      display: flex;
      font-family: $lato-regular;
      background: $color-dark-blue;
      padding: 20px 2pc;
      visibility: hidden;
    }
  }
  .cart-indicator-widget {
    .cart-indicator.cart-indicator__with-count {
      .cart-indicator__count {
        span {
          @include breakpoint(tab-max) {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.auth-nav {
  .auth-nav__menu-wrap {
    .auth-nav__menu-items {
      a {
        padding-top: 0;
      }
    }
  }
}

.auth-nav__menu-items {
  @include breakpoint(desktop-extra-lite-sm) {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
  .gbh-data-layer-custom {
    p {
      a {
        @include breakpoint(desktop-extra-lite-sm) {
          padding-top: 0 !important;
        }
      }
    }
  }
  span {
    @include breakpoint(desktop-extra-lite-sm) {
      line-height: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
.ann-sacks-logobar {
  .button {
    &.order-button {
      @include breakpoint(desktop-extra-lite-sm) {
        position: absolute;
        top: 464px;
        z-index: 2;
        left: 0;
        display: none;
        .cmp-button {
          padding-left: 0;
          text-transform: unset;
        }
      }
      text-transform: uppercase;
      @include breakpoint(tab) {
        left: -14px;
      }
    }
    &.appointment-button {
      padding: 0;
      height: auto;
      @include breakpoint(desktop-extra-lite-sm) {
        position: absolute;
        top: 461px;
        z-index: 2;
        left: 108px;
        margin-left: 15px;
        display: none;
      }
      a {
        height: auto;
      }
    }
    .cmp-button {
      @include ann-describtion(0.8125rem, $lato-regular, 1rem);
      letter-spacing: 0.011875rem;
      color: $color-light-blue;
      text-transform: none;
      border: 1px solid $color-light-blue;
      border-radius: 0.3125rem;
      height: auto;
    }
  }
}
span.icon-close {
  &.icon-close-medium {
    &::before {
      @include breakpoint(desktop-extra-lite-sm) {
        content: "\e907";
        display: block;
        z-index: 999;
        position: absolute;
        top: 0;
        font-size: 21px;
        left: 0px;
      }
    }
  }
}
//Nav-up and Nv-down
.nav-up {
  position: fixed !important;
  top: 0 !important;
  z-index: 99;
  transition: all 1s;
  @include breakpoint(desktop-extra-lite) {
    padding-top: 10px !important;
  }
}
.nav-down {
  position: fixed !important;
  top: -80px;
  z-index: 99;
  transition: all 1s;
}

.ann-sacks-logobar {
  padding: 1.6875rem 0 1.5625rem;
  .cmp-container {
    display: flex;
    padding: 0 2.6%;
    .cmp-image {
      flex: 1;
      + div {
        @include breakpoint(desktop-small) {
          display: none;
        }
        .search-text {
          display: none;
        }
      }
      img {
        width: 116px;
        height: 30.5px;
      }
    }
    .auth-nav-icon {
      color: $cool-medium-grey !important;
    }
  }
  .button.order-button {
    text-transform: uppercase;
    position: absolute;
    right: 180px;
    .button.kf-react-button.cmp-button {
      border: none;
      a {
        text-transform: uppercase;
        color: $text-medium-dark-grey;
        @include ann-describtion(0.8125rem, $lato-regular, 1.25rem);
        height: 19px;
        letter-spacing: 0.0125rem;
        @include breakpoint(desktop-extra-lite-sm) {
          height: 20px;
          width: 90px;
        }
      }
    }
  }
  .cmp-button__text {
    font-size: 0.8125rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    color: $text-medium-dark-grey;
    border: none;
    text-transform: none;
    text-transform: uppercase;
  }
  .cmp-button .kf-react-button {
    line-height: 1.25rem;
    border: none;
    text-transform: none;
    height: 36px;
    padding: 0;
  }
}

.annsacks-navigation-teaser {
  padding: 5.625rem 0;
  max-width: 100%;
  margin: 0 auto;
  .cmp-teaser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: start;
    &__content {
      width: 17.375rem;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      padding-left: 0;
    }
    &__description {
      animation: fadeInUp 1s ease-in-out;
      font-size: 1pc;
      color: inherit;
      display: block;
      p {
        @include ann-describtion(1.375rem, $lato-regular, 2rem);
        color: $text-medium-dark-grey;
        margin-bottom: 2.5rem;
      }
    }
  }
}
.ann-mob-nav {
  display: none;
  a {
    @include breakpoint(desktop-extra-lite-sm) {
      text-transform: uppercase;
      padding: 0;
    }
    @include breakpoint(desktop-extra-lite-sm) {
      @include ann-describtion(0.75rem, $lato-regular, 1.5rem);
      letter-spacing: 0.125rem;
      color: $text-dark-grey;
    }
    @include breakpoint(mobile) {
      @include ann-describtion(0.75rem, $lato-regular, 1rem);
      letter-spacing: 0.1069rem;
      color: $text-medium-dark-grey;
    }
  }
}

.gbh-pro-menu__inner {
  .icon-Close-Medium {
    &::before {
      content: "\e907";
      @include breakpoint(desktop-extra-lite-sm) {
        left: 2rem;
        position: relative;
        top: -50px;
        z-index: 999999;
      }
      @include breakpoint(mobile) {
        left: 18px;
      }
    }
  }
}

#scroll-close {
  &::before {
    @include breakpoint(desktop-extra-lite-sm) {
      position: relative;
      left: 14px;
      top: 25px;
    }
    @include breakpoint(mobile) {
      left: 18px;
    }
  }
}
// Menu Overlap 
.ann-sacks-tab-bar{
  &.ann-sacks-tab-menu{
    @include breakpoint(desktop-extra-lite-sm){
       display: block !important;
    }
  }
}

.breadcrumb .cmp-breadcrumb .cmp-breadcrumb__list .cmp-breadcrumb__item .cmp-breadcrumb__item-link span{
  color:$text-medium-dark-grey;
  @include ann-describtion(13px, $helvetica-regular, 13px);
  letter-spacing: -.45px;
  text-transform: capitalize;
  @include breakpoint(desktop-extra-lite-lg){
      @include ann-describtion(13px, $helvetica-regular, 20px);
  }
  @include breakpoint(tab-max){
      line-height:16px;
  }
}
.ann-sacks-main-menu.button-format.container .cmp-container.icon-close-medium.icon-Close-Medium.icon-close {
  padding-bottom: 16.75rem;
}
.tabs.ann-main-nav.open {
.cmp-tabs__tabpanel {
  .container {
    .cmp-container {
      padding-bottom: 100% !important;
    }
  }
}
}
.gbh-pro-menu__inner {
  #scroll-close {
    &::before {
      display: none;
    }
  }
}
.gbh-hamber-menu.icon-Hamburger.icon-close-medium.icon-close {
  @include breakpoint (desktop-extra-lite-sm) {
 opacity: 0;
 z-index: 9999;
  }
}
.icon-close-medium.icon-Close-Medium.icon-close, span#scroll-close {
  @include breakpoint (desktop-extra-lite-sm) {
  &::before {
   content: "\e907" !important;
   z-index: 3;
   position: relative;
  }
  }
}
.gbh-pro-menu__inner {
  @include breakpoint (desktop-extra-lite-sm) {
  &::before {
  content: "\e907" !important;
  top: 35px;
  left: 32px;
  position: absolute;
  z-index: 7;
  }
  }
  @include breakpoint (mobile) {
    &::before {
      left: 18px;
    }
  }

}
.nav-up .brand-logo img,
.nav-up .cart-indicator-widget .cart-indicator img,
.nav-up .icon-Hamburger:before {
  filter: unset !important;
}

body:has(.gbh-product-menu.open) {
  .ann-sacks-main-menu.container .cmp-container .button .cmp-button.cmp-button-active{
    border: none;
  }
}