// Wishlist Popup
.wishlist {
  &__product-info {
    display: flex;
    text-align: left;
  }

  &__product-image {
    width: 48%;
    max-width: 198px;
    padding: 15px 10px 40px;
  }

  &__product-details {
    padding: 20px 70px 0 40px;

    &--sku {
      font-size: 12px;
      font-weight: normal;
      color: #999;
      line-height: 1.375rem;
    }

    &--name {
      font-size: 24px;
      font-weight: 200;
      margin-bottom: 25px;
    }

    &--variant {
      margin-bottom: 10px;
      font-size: 12px;

      &-label {
        font-size: inherit;
        font-weight: 700;
      }

      &-value {
        font-size: inherit;
        font-weight: 400;
        line-height: 1.375rem;
      }
    }
  }

  &__user-info {
    margin: 45px 0 0;
  }

  &__user-info-title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 10px;
  }

  &__field-row {
    display: flex;
  }

  &__action-row {
    display: block;
    margin-top: 35px;
  }

  &__label-col {
    width: 16%;
    padding: 0 10px;

    label {
      font-size: 14px;
      font-weight: 700;
      margin-top: 10px;
      display: block;
      text-align: right;

      .character-limit {
        color: #999;
      }
    }
  }

  &__field-col {
    width: 68%;
    padding: 0 0 0 10px;

    &.merge-col {
      width: 84%;
    }

    select, input[type="text"] {
      width: 100%;
      padding: 1px 15px 0;
      height: 40px;
    }

    textarea {
      width: 100%;
      height: 160px;
      border: none;
      background: #fff;
      padding: 15px;
      resize: none;
    }
  }

  &__action-col {
    padding: 0 0 0 20px;
    width: 16%;
    text-align: left;

    a {
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      vertical-align: -8px;
    }
  }

  .folder-section {
    padding-bottom: 20px;
  }
}

// Wishlist Alert Popup
.wishlist-alert {
  &__title-info {
    font-size: 16px;
    font-weight: 400;
    margin: 25px 0;

    &--message {
      font-size: inherit;
      font-weight: 400;
    }

    &--folder-name {
      font-size: inherit;
      font-weight: 700;
    }
  }

  &__product-info {
    display: flex;
    text-align: left;
  }

  &__product-image {
    width: 100%;
    max-width: 218px;
    padding: 15px 10px 20px 58px;
  }

  &__product-details {
    padding: 20px 10px;

    &--sku {
      font-size: 12px;
      font-weight: 400;
    }

    &--name {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 25px;
      width: 80%;
    }
  }

  &__user-info {
    padding: 0;
    margin: 0;
  }

  &__action-row {
    display: block;
    margin-top: 35px;
  }
}

.wishlist-popup {
  .pop-up {
    &__content {
      max-width: 800px;
      padding: 50px 40px;
    }

    &__title {
      font-weight: 200;
      font-size: 30px;
      display: block;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 10px;
    }

    &__body {
      padding: 30px 0 0 0;
    }

    &__divider {
      margin: 48px 0;
      border: 0;
      border-top: 1px solid #979797;
    }
  }
}

.wishlist-popup.wishlist-alert {
  .pop-up {
    &__content {
      max-width: 578px;
      padding: 50px 40px;
    }
  }
}
