.product-card {
  max-width: 458px;
  width: 100%;
  display: inline-block;
  &__link {
    display: block;
    text-decoration: none;
    position: relative;

    .product-card__img-container{
      width: 458px;
    }
    .product-card__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
    }

    .product-card__img-container + .product-card__details{
      margin-top: 15px;
    }
    .product-card__link{
      width: 100%;
    }
    .product-card__details {
      display: flex;
      margin-bottom: 5px;

      &:last-child {
        margin-top: 10px;
      }

      .product-card__left  {
        -ms-flex: 0 0 73%;
        flex: 0 0 73%;
        max-width: 73%;
        .product-card__name {
          font-family: $font-regular;
          margin: 0;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -0.91px;
          color: $color-slate;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          .highlight-text{
            font-family:$font-bold;
          }
        }
        .product-card-desc {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-family: $font-regular;
          margin: 0;
          line-height: 22px;
          letter-spacing: -0.55px;
          font-size: 16px;
          color: $color-dark-gray;
        }
        .product-card__color-text {
          span {
            line-height: 22px;
            font-family: $font-regular;
            letter-spacing: -0.55px;
            font-size: 16px;
            color: $color-dark-gray;
          }
        }
      }
      .product-card__right {
        -ms-flex: 0 0 27%;
        flex: 0 0 27%;
        max-width: 27%;

        .product-card__price {
          display: block;
          text-align: right;
          padding-right: 25px;
          line-height: 22px;
          font-family: $font-medium;
          font-size: 16px;
          letter-spacing: -0.8px;
          color: $color-slate;
        }
        .product-card__discount-price {
          text-decoration: line-through;
          text-align: right;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .product-card {
    max-width: 167px;
    &__link {
      .product-card__img-container + .product-card__details{
        margin-top: 10px;
      }
      .product-card__details{
        display: none;

        .product-card__left{
          flex: 0 0 90%;
          max-width: 90%;
          .product-card__name{
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.25px;
          }
        }
        .product-card__right {
          flex: 0 0 90%;
          max-width: 90%;
          margin-top: 5px;
          .product-card__price{
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.8px;
          }
        }
      }
      .product-card__img-container + .product-card__details{
        display: block;
      }     
    }
  }
}

@media only screen and (max-width: 990px) {
  .product-card {
    max-width: 166px;
    &__link {
      .product-card__img-container {
        width: 166px;
      }
      .product-card__details{
        .product-card__left{
          .product-card__name{
            font-size: 16px;
          }
        }
      }
    }
  }
}
