// Product List Promotional Tiles
.promotional-tile-one {    
    &.teaser {
        padding: 0;
        @include breakpoint(tab) {
            padding: 0 2rem;
        }
        @include breakpoint(mobile) {
            padding: 0 1.125rem;
        }
        .cmp-teaser {            
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            background: $color-dark-blue;
            @include breakpoint(desktop-small) {
                width: 21.25rem;
            }
            @include breakpoint(tab) {
                width: 46.75rem;
                flex-direction: row-reverse;
                height: 31.25rem;
            }
            @include breakpoint(mobile) {
                width: 100%;
                flex-direction: column;                
            }            
            &__image {
                height: 24.625rem;
                @include breakpoint(tab) {
                    width: 25.5rem;
                }
                &::before {
                    background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 0%);
                    background-color: transparent;
                    @include breakpoint(tab) {
                        background-image: linear-gradient(45.15deg, #000000 0%, rgba(0, 0, 0, 0.5) 0%);
                    }
                    @include breakpoint(mobile) {
                        background-image: linear-gradient(45.15deg, #000000 0%, rgba(0, 0, 0, 0.5) 0%);
                    }
                }
                img {
                    width: 100%;
                    @include breakpoint(tab) {
                        min-height: 31.25rem;
                    }
                    @include breakpoint(mobile) {
                        min-height: 24.625rem;
                    } 
                }
            }
            &__content {                
                padding: 1.3125rem 2.8125rem 1.4375rem 1.875rem;
                width: 21.25rem;
                @include breakpoint(tab) {
                    padding-bottom: 3.125rem;
                }
                @include breakpoint(mobile) {
                    padding: 1.25rem 2.0625rem 3rem 1.875rem;
                }
            }
            &__title {
                h1, h2, h3, h4, h5, h6, p {
                    @include ann-describtion (1.6875rem, $playfair-regular, 2.25rem);
                    letter-spacing: -0.09375rem;
                    color: $white;
                    padding-bottom: 1.875rem;
                    margin: 0;
                    @include breakpoint(desktop-small) {
                        @include ann-describtion (2.25rem, $playfair-regular, 3rem);
                        letter-spacing: -0.125rem;
                    }
                }
            }
            &__pretitle, &__description{
                display: none;
            }
            &__action-container {
                .cmp-teaser__action-link {
                    color: $white;
                    border: 1px solid $white;
                    @include ann-describtion (0.75rem, $lato-bold, 1.5rem);
                    letter-spacing: 0.15625rem;
                    padding: 0.75rem 1.6875rem 0.875rem;   
                }
            }
        }
    }
}
.promotional-tile-two {
    @extend .promotional-tile-one;
    &.teaser {
        .cmp-teaser {                        
            @include breakpoint(desktop-small) {
                flex-direction: row;
                width: 44.375rem;
                height: 38.375rem;
            }            
            &__image {                
                @include breakpoint(desktop-small) {
                    width: 23.125rem;
                }
                img {
                    @include breakpoint(desktop-small) {
                        min-height: 38.375rem;
                    } 
                }
            }
            &__content {
                @include breakpoint(desktop-small) {
                    padding: 0 1.3125rem 3.125rem 1.875rem;
                }
                @include breakpoint(tab) {
                    padding: 0 2.75rem 3.125rem 1.875rem;
                }
            }
        }
    }
}
.promotional-tile-three {
    @extend .promotional-tile-two;
    &.teaser {
        .cmp-teaser {
            @include breakpoint(desktop-small) {
                width: 67.5rem;
                height: 40.625rem;
            }            
            &__image {
                @include breakpoint(desktop-small) {
                    width: 46.25rem;
                }
                &::before {
                    background-image: linear-gradient(45.15deg, #000000 0%, rgba(0, 0, 0, 0.5) 0%);
                    background-color: transparent;                    
                }
                img {
                    @include breakpoint(desktop-small) {
                        min-height: 40.625rem;
                    } 
                }
            }            
        }
    }
}