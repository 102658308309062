.two-up-collections {
    margin:4.375rem 3.75rem 4.375rem 0;
    @include breakpoint(tab){
        margin:50px 70px 50px 0;
    }
    @include breakpoint(mobile){
        margin:50px 0 40px;
    }
    &:last-child{
        margin-right:0;
        @include breakpoint(mobile){
            margin:0 0 50px;
        }
    }
    .cmp-teaser{
        &__image{
            
            @include breakpoint(tab){
                max-height:255px;
            }
            @include breakpoint(mobile){
                max-height:247px;
            }
            .cmp-image{
                max-height:inherit;
            }
        }
        &__content{
            margin-top:1.875rem;
            max-width:28.5rem;
            @include breakpoint(tab-max){
                margin-top:1.25rem;
            }
        }
        &__pretitle, &__pretitle *{
            margin-bottom:10px;
            font-size: 12px;
            line-height:16px;
            letter-spacing:2px;
            font-family:$lato-regular;
        }
        &__title{
            margin-bottom:10px;
            *{
                font-size:2.625rem;
                line-height:3rem;
                letter-spacing:-2.8px;
                font-family:$playfair-regular;
                @include breakpoint(tab-max) {
                    font-size:28px;
                    line-height:34px;
                    letter-spacing: -1.17px;
                }
            }
        }
        &__description{
            *{
                font-size:16px;
                line-height:24px;
                letter-spacing:normal;
                font-family:$lato-regular;
                @include breakpoint(tab-max){
                    font-size:14px;
                    line-height:20px;
                }
            }
        }
        .cmp-teaser__action-container{
            .cmp-teaser__action-link{
                margin-top:20px;
                padding-bottom:7px;
                font-size:11px;
                line-height:16px;
                letter-spacing:1.83px;
                font-family:$lato-regular;
                display:inline-flex;
                color:$color-light-blue;
                border-color: $color-light-blue;
                @include breakpoint(tab-max){
                    letter-spacing: 1.8px;
                }
            }
        }
        
    }
    &.pre-tile-badge{
        position: relative;
        .cmp-teaser{
            &__pretitle{
                margin:0;
                *{
                    font-size:9px;
                    line-height:16px;
                    letter-spacing:1.64px;
                    font-family:$lato-bold;
                    min-height:30px;
                    display:flex;
                    align-items: center;
                    position:absolute;
                    top:10px;
                    left:10px;
                    border:1px solid;
                    color:$white;
                    padding:0 11px;
                    margin:0;
                }
            }
        }
    }
}