.quantity-selector {
  border-radius: 5px;
  border: 1px solid $cool-dark-grey;
  text-align: center;
  display: flex;
  height: 60px;
  &.small {
    height: 40px;
    min-width: 90px;
    .quantity-selector__count {
      font-family: $lato-regular;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.7px;
      text-align: center;
      color: $text-light-grey;
    }
    .plus {
      width: 10px;
      height: 10px;
      .line {
        &.line-2 {
          transform: translateX(-5px);
        }
      }
    }
  }
}
.cart-item__qty {
  .quantity-selector.small {
    min-width: 100px;
  }
  .quantity-selector__count {
    min-width: 37px;
  }
}
