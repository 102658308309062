.message-ignored {
  position: fixed;
  left: 0;
  right: 0;
  top: 108px;
  z-index: 9999;
  background: transparent;

  &--show {
    display: block;
  }

  &--hide {
    display: none;
  }

  &__container {
    position: relative;
    padding: 17px 20px;
    width: 80%;
    max-width: 800px;
    text-align: center;
    margin: auto;
    border-radius: 5px;
    background-color: #fff;

    &--error {
      color: #e50000;
      border: solid 1px #e50000;
      background-color: #f9cccc;
    }

    &--warning {
      color: #e50000;
      border: solid 1px #e50000;

    }

    &--success {
      color: #28a745;
      border: solid 1px #28a745;
      background-color: #cdf9cd;
    }
  }

  &__close {
    position: absolute;
    text-align: center;
    width: auto;
    height: auto;
    right: 10px;
    top: 10px;
    color: #000;
    background-color: transparent;
    border: none;
  }
}
