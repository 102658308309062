.preview-cart {
    padding: 52px 40px 30px;
    @include breakpoint(desktop-small) {
    padding-right: 0px;
    }
    &__header {
      border-bottom: 1px solid $cool-dark-grey;
      padding-bottom: 21px;
      @include breakpoint(desktop-small) {
      margin-right: 40px;
      }
    }
    &__title {
      font-family: $lato-regular;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
      color: $text-light-grey;
    }
    &__welcome {
      margin-top: 0;
    }
    &__welcome, &__signin {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      display: none;
    }
    &__signin {
      cursor: pointer;
      text-decoration: underline;
    }
    &__list {
      padding: 0px;
      @include breakpoint(desktop-small) {
        padding: 0px 20px 0px 0px;
        margin: 0px 15px 0px 0px;
      }
      
      &::-webkit-scrollbar {
        @include breakpoint(desktop-small) {
          scrollbar-width: thin;
          width: 5px;
          background-color: $light-gray;
          display: block;

        }
      }
      &::-webkit-scrollbar-thumb {
        @include breakpoint(desktop-small) {
          background-color: $dark-gray;
        }
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px $light-gray;
        background-color: $light-gray;
      }

    }
    &__empty {
      margin-top: 50px;
      padding-left: 4px;
      p {
        margin-bottom: 33px;
        color: $text-light-grey;
        font-family: $lato-regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }
      span {
        font-family: $lato-regular;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 1.83px;
        color: $color-light-blue;
        text-decoration: none;
        border-bottom: 1px solid $color-light-blue;
        padding-bottom: 9px;
      }
      a {
        color: $color-light-blue;
        font-family: $lato-regular;
        font-size: 11px;
        letter-spacing: 1.83px;
        line-height: 24px;
        text-transform: uppercase;
      }
    }
    &__footer {
      padding: 30px 40px 0 40px;
    }
    &__subtotal {
      margin-bottom: 20px;
      @include breakpoint(desktop-small) {
      margin-right: 40px
      }
      &--label {
        color: $text-dark-grey;
        font-family: $lato-regular;
        @include text-style (18px, 24px, 0);
      }
      &--value {
        color: $text-dark-grey;
        font-family: $lato-regular;
        @include text-style (18px, 26px, 0);
        padding-right: 4px;
      }
    }
    &__cta {
      .button:first-child {
        box-sizing: border-box;
        height: 50px;
        width: 170px;
        min-width: 170px;
        border: 1px solid $color-medium-blue;
        border-radius: 1px;
        color: $color-medium-blue;
        font-family: $lato-bold;
        @include text-style (12px, 24px, 2.5px);
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 15px;
      }
      .button:last-child {
        height: 50px;
        width: 170px;
        min-width: 170px;
        border-radius: 1px;
        background-color: $maroon;
        color: $color-white;
        font-family: $lato-bold;
        @include text-style (12px, 24px, 2px);
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
  
  .preview-cart-item {
    position: relative;
    padding: 30px 0 20px;
    position: relative;
    &:last-child{
      border-bottom: 1px solid $dark-gray;
    }
    &__alert {
      padding-top: 15px;
    }
    &__image {
      height: 100px;
      width: 100px;
      margin-top: 12px;
      margin-right: 15px;
    }
    &__header {
      margin-bottom: 2px;
      a {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
        padding-right: 4px;
      }
    }
    &__brand {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__name {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 26px;
      margin-bottom: 15px;
    }
    &__finish {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 1.7px;
      line-height: 16px;
      margin-bottom: 0;
    }
    &__additional-Info {
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 10px;
      letter-spacing: 1.7px;
      line-height: 16px;
      margin-top: 10px;
    }
    &__price, &__discount {
      font-family: $lato-regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__price {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 16px;
      letter-spacing: -0.8px;
      line-height: 22px;
      margin-bottom: 3px;
    }
    &__discount {
      color: $text-medium-dark-grey;
      margin-bottom: 10px;
    }
    &__remove {
      position: absolute;
      right: 0;
      bottom: 20px;
      span {
        font-family: $lato-regular;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: $text-dark-grey;
      }
    }
    &__offPercent {
      font-family: $lato-regular;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1.7px;
      color: $text-medium-dark-grey;
      padding-right: 0px;
    }
    &__footer {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__discount-container{
      display: flex;
      justify-content: normal;
      align-items: end;
      flex-direction: column-reverse;
      margin-bottom: 20px;
    }
}
.preview-cart__stock-alert {
    color: $color-red-alert;
    font-family: $font-lato;
    font-size: 14px;
    line-height: 20px;
    padding-top: 15px;
}
.preview-cart--open{
  .preview-cart__list{
    scrollbar-width :auto
  }
}