//Help & Support
.container{
    .cmp-container{
        &#help-and-support{
            .help-support {
                background: transparent;
                color: $white;
                padding: 70px 2.6%;
                @include breakpoint(desktop-extra-lite-lg) {
                    padding: 50px 36px;
                }
                @include breakpoint(tab) {
                    padding: 50px 32px;
                }
                @include breakpoint(mobile) {
                    padding: 50px 1.125rem;
                }
                .gbh-container {
                    max-width: 1820px;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0;
                    @include breakpoint(desktop-extra-lite-sm) {
                        width: 100%;
                        flex-direction: column;
                        gap: 45px;
                    }
                }
                &-left {
                    display: flex;
                    flex-direction: column;
                    width: 24.6875rem;
                    margin-right: 0;
                    @include breakpoint(desktop-xlg) {
                        width: 24.6875rem;
                    }
                    @include breakpoint(desktop-extra-lite-sm) {
                        width: 22.5rem;
                        margin-right:auto;
                    }
                    &-title {
                        @include ann-describtion( 16px, $lato-regular, 24px);
                        letter-spacing: 0.44px;
                        padding-bottom: 20px;
                        color: $white;
                        p{
                            @include ann-describtion( 16px, $lato-regular, 24px);
                            letter-spacing: 0.44px;
                            padding-bottom: 0;
                            color: $white;
                        }
                    }
                    &-description {            
                        width: 24.6875rem;
                        @include breakpoint(desktop-extra-lite-sm) {
                            width: 292px;
                        }
                        @include breakpoint(mobile) {
                            width: 17.5rem;
                        }
                        h1, h2, h3, h4, h5, h6, p {
                            @include ann-describtion(36px!important, $playfair-regular!important, 48px!important);
                            letter-spacing: -1px!important;                
                            margin-bottom: 2.8125rem;                
                            @include breakpoint(desktop-extra-lite-sm) {
                                @include ann-describtion(28px!important, $playfair-regular!important, 36px!important);
                                letter-spacing: -0.75px!important;
                                margin: 0;
                            }                
                        }
                    }
                }
                &-right {
                    display: flex;
                    flex-direction: column;
                    width: 37.125rem;
                    @include breakpoint(desktop-xlg) {
                        width: 44.5625rem;
                    }
                    @include breakpoint(desktop-extra-lite-sm) {
                        width: 100%;
                        justify-content: space-between;
            
                    }
                    .chat {
                        position: relative;
                        padding: 29.5px 0;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        border-bottom: 1px solid $text-medium-dark-grey;
                        &:first-child {
                            padding-top: 0;
                        }
                        &:nth-child(3) {
                            flex-direction:row;
                            justify-content:space-between;
                            .chat-title{
                                padding-bottom:0;
                            }
                            .chat-description {
                                width: 237px;
                                position: absolute;
                                margin-top: 30px;
                                @include breakpoint(mobile){
                                    position: relative;
                                    top:0;
                                }
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                            padding-bottom: 0;                
                        }
                        &-title, &-title p {
                            @include ann-describtion(18px, $lato-regular, 24px);
                            letter-spacing: 0.5px;
                            padding-bottom: 2px;
                            width: fit-content;
                            @include breakpoint(mobile) {
                                width: 100%;
                                padding-bottom:.355rem;
                            }
                        }
                        &-description {
                            width: 124px;                
                            p {
                                @include ann-describtion(13px, $lato-regular, 20px);
                                letter-spacing: 0.2px;
                                opacity: 0.7;
                                margin-bottom: 0;
                                color: $white;
                                a {
                                    text-decoration: underline;
                                    color: $white;
                                }
                            }
                        }
                        &-button {
                            border: 1px solid $white;
                            padding: 11px 1.6875rem 13px;
                            font-size: 0.75rem;
                            line-height: 1.5rem;
                            letter-spacing: 0.15625rem;
                            font-family: $lato-bold;
                            border-radius: 1px;
                            width: auto;
                            text-align: center;
                            text-transform: uppercase;
                            margin-bottom: 1.25rem;
                            &:last-child{
                                display: none;
                            }
                            @include breakpoint(tab){
                                margin-bottom:1.25rem;
                            }
                            @include breakpoint(mobile) {
                                position: relative;
                                top: 0;
                                width: 100%;
                                margin: 1.875rem 0 1.25rem;
                                padding: .75rem 1.6875rem;
                            }
                            a {
                                @include ann-describtion(12px, $lato-regular, 24px);
                                color: $white;
                                letter-spacing: 2.5px;
                                text-decoration: none;
                                &:hover {
                                    color: $white;
                                }
                            }
                        }
                        .contact-number {
                            position: absolute;
                            right: 0;
                            top: 32px;
                            width: 14.875rem;
                            min-width: unset;
                            @include breakpoint (desktop-extra-lite) {
                                width:180px;
                            }
                             @include breakpoint (desktop-extra-lite-lg) {
                                width: 208px;
                            }
                            @include breakpoint (desktop-extra-lite) {
                                padding-right: 3.625rem;  
                            } 
                            @include breakpoint(tab) {
                                width: 21.1875rem;
                            }
                            @include breakpoint(mobile) {
                                position: relative;
                                top: 0;
                                width: 100%;
                                margin: .625rem 0 0;
                            }
                            ul {
                                margin: 0;
                                padding: 0;
                                li {
                                    list-style: none;
                                    padding-bottom: 0.938rem;
                                    &:last-child {
                                        @include breakpoint(mobile) {
                                            padding-bottom: 0;
                                        }
                                    }
                                    a {
                                        color: $white;
                                        @include ann-describtion(16px, $lato-regular, 24px);
                                        text-decoration: none;
                                        letter-spacing: normal;
                                    }
                                }
                            }   
                        }
                        .support-links {
                            width: 11.25rem;
                            align-self:flex-end;
                            top: 0;
                            margin-bottom: 0;
                            @include breakpoint (desktop-extra-lite) {
                                width: 180px;
                            }
                            @include breakpoint (desktop-extra-lite-lg) {
                                width: 208px;
                            }
                            @include breakpoint(tab) {
                                width: 21.1875rem;
                            }
                            @include breakpoint(mobile) {
                                position: relative;
                                width: 100%;
                            }
                            ul {
                                margin: 0;
                                padding: 0;
                                @include breakpoint(tab) {
                                    width: 100%;
                                }
                                li {
                                    list-style: none;
                                    &.chat-button {
                                        min-width: unset;
                                        a {
                                            min-width: unset;
                                            text-decoration: none;
                                            @include ann-describtion(12px, $lato-bold, 24px);
                                            letter-spacing: 2.5px;
                                            margin-bottom:0;
                                        }
                                    }                        
                                    a {
                                        @include ann-describtion(16px, $lato-regular, 24px);
                                        letter-spacing: normal;
                                        text-decoration: underline;
                                        color: $white;
                                        display:block;
                                        @include breakpoint(desktop-extra-lite-sm){
                                            margin-bottom:.3125rem;
                                        }
                                    }
                                    &:last-child{
                                        a{
                                            margin-bottom:0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
} 
.contact-us-main {
    .select-dropdown {
      margin-bottom: 20px;
    }
    .tabs {
       .tab-list-item {
            font-size:14px;
            text-transform: uppercase;
            color:$text-light-grey;
            letter-spacing:2.33px;
            line-height:16px;
            font-family:$lato-regular;
            margin-right:20px;
            padding:0 20px 22px !important;
            &:last-child{
                margin-right:0;
            }
            @include breakpoint(mobile){
                padding:0 10px 22px !important;
            }
        }
        .tab-list-active {
            color:$text-dark-grey;
            border-bottom:2px solid $color-light-blue;
            font-weight: normal;
        }
        .tab-list{
            border-bottom: 1px solid $light-white;
            @include breakpoint(tab-max){
                margin-top: 40px 0 30px;
            }
            @include breakpoint(mobile){
                margin-top: 39px;
            }
            @include breakpoint(tab){
                margin-top: 39px;
            }
        }
    }
    .input-field {
        .input-field__border {
            padding: 17px 15px ;
            border: 1px solid $cool-dark-grey;
            border-radius: 3px;
            &.--focused{
                border: 1px solid $text-dark-grey;
            }
            .input-field__value {
                input{
                    font-family:$lato-regular;
                    color: $text-dark-grey;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: normal;
                }
                input:placeholder-shown+label  {
                    font-size: 18px;
                    line-height: 26px;
                    -webkit-transform: translateY(45%) scale(1);
                    transform: translateY(45%) scale(1);
                    max-width: 72.66%;
                }
                input:focus+label{
                    transform: translate(0) scale(1);
                    font-size: 13px;
                }
                input:-webkit-autofill,
                input:-webkit-autofill:focus,
                input:-internal-autofill-selected {
                    background-color: transparent !important;
                }
                label {
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: normal;
                    color: $text-light-grey;
                    font-family:$lato-regular;
                }
                
            }
        }
    }
    .select-dropdown__value{
        font-family:$lato-regular;
        color: $text-dark-grey;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: normal;
        @include breakpoint(tab-max){
            font-size:16px;
        }
    }

    .select-dropdown__placeholder.\--label{
        font-size: 13px;
        line-height: 20px;
        letter-spacing: normal;
        color: $text-light-grey;
        font-family:$lato-regular;
    }
    .optional-form {
        .form-wrapper {
            &.textarea__wrapper {
                textarea {
                    height: 198px;
                    font-family: $lato-regular;
                    letter-spacing: normal;
                    line-height: 26px;
                }
            }
           
        }
    }
    .question {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 26px;
        letter-spacing: 0;
        line-height: 36px;
        padding-bottom: 0;
        margin-bottom: 19px;
        @include breakpoint(tab-max) {
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
            margin-bottom: 0;
        }
        @include breakpoint(mobile) {
            padding-bottom: 0px;
        }
        @include breakpoint(tab) {
            padding-bottom: 0px;
        }
  
    }
    .input-field__error-message, .select-dropdown__error-message {
        color: $color-red-alert;
        font-family: $lato-regular;
        font-size: 13px;
        letter-spacing: 0.22px;
        line-height: 20px;
    }
    .required-fields {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
    }
}