.promo-code {
  padding: 0 15px 30px;

  &__label-block {
    float: left;
    margin-top: 6px;
    padding-bottom: 0;
    text-align: right;
  }

  &__code-label {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.5rem;
    margin-bottom: 10px;
    padding: 0 10px 0 0;
  }

  &__input-block {
    width: 41.66667%;
    float: left;
  }

  &__code-input {
    font-size: 16px;
    font-weight: 400;
    color: #999;
    background: #fff;
    box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, .02);
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 0 15px 1px 20px;
    margin: 0 0 5px 0;
    width: 100%;
    height: 40px;
  }

  &__action-block {
    padding: 0 10px;
    width: 16.66667%;
    float: left;
  }

  &__apply-button {
    font-size: 16px;
    font-weight: 600;
    border: 0 none;
    text-transform: uppercase;
    vertical-align: top;
    background-color: #dbdbdb;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #232323;
    border-radius: 4px;
    width: 100%;
    padding: 0 6px;
  }
}
