.more-carousel{
  ul{
    overflow-x: auto;
    scroll-behavior: smooth;
    padding-bottom: 10px;
    white-space: nowrap;
    margin: 0px;
    li{
      margin-right: 5px;
      display: inline-block;
      
      &:last-child{
        margin-right: 0;
      }
      a{
        display: inline-block;
        width: 100%;
        .collection-list{
          height: 264px;
        }
        span {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            display: block;
        }
      }
    }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px grey; 
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #757575; 
        border-radius: 5px;
      }
  }
}

