.checkbox {
  color: $text-dark-grey !important;
  font-size: 14px !important;
  font-family: $lato-regular !important;
  input:checked ~ span {
    color: $text-dark-grey !important;
    font-size: 14px !important;
    font-family: $lato-regular !important;
  }
  .checkbox_default {
    color: $text-dark-grey;
    letter-spacing: 0.23px;
    line-height: 18px !important;
  }
  &__border {
    width: 15.59px !important;
    height: 15.59px !important;
    border-radius: unset !important;
    border: 1px solid $input-border-color !important;
    @include breakpoint(tab) {
      width: 20px !important;
      height: 20px !important;
    }
  }
  &__marker {
    width: 15.59px !important;
    height: 15.59px !important;
    border-radius: unset !important;
    @include breakpoint(tab) {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
div.checkbox {
  font-size: 14px;
}
