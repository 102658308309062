.more-from-collection {
  margin-top: 40px;
  padding-bottom: 80px;

  @media screen and (orientation: portrait) and (max-width: 768px) {
    padding-bottom: 60px;
  }

  &.parts {
    border-bottom: 1px solid $color-dark-gray;
  }

  &__title {
    color: $color-charcoal;
    font-family: $font-medium;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  &__track {
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 30px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-extra-light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-dark-gray;
    }
  }
  &__product {
    display: inline-block;
    width: 198px;
    height: 264px;
    background: #f4f4f4;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
  }
  &__parts {
    margin-left: 0;
    font-family: $font-regular;

    li:first-child {
      align-items: center;
      border-top: 1px solid $color-dark-gray;
      border-bottom: 1px solid $color-dark-gray;
      height: 45px;
    }

    li {
      display: flex;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      font-size: 16px;

      div:first-child {
        width: 100%;
        max-width: 180px;

        a {
          color: $color-dark-gray;
        }

        @media screen and (orientation: landscape) and (max-width: 1024px) {
          max-width: 273px;
        }

        @media screen and (orientation: portrait) and (max-width: 768px) {
          max-width: 158px;
        }
      }
    }

    li:not(:first-child) {
      padding-top: 15px;

      div:first-child {
        font-family: $font-medium;
        text-decoration: underline;
        letter-spacing: -0.35px;
      }
    }
  }
}
