.history-text-title-list-style{
    max-width:1380px;
    margin:0 auto;
    padding:4.375rem 0;
    @include breakpoint(desktop-mid){
        padding-right:2.25rem;
        padding-left:2.25rem;
        margin:0;
    }
    @include breakpoint(tab-max){
        padding:50px 32px;
    }
    @include breakpoint(mobile){
        padding-right:18px;
        padding-left:18px;
    }
    .cmp-text{
        padding:5.625rem 3.75rem;
        border-top:1px solid;
        border-bottom:1px solid;
        display:flex;
        justify-content: space-between;
        @include breakpoint(tab){
            padding:40px 0;
            align-items: center
        }
        @include breakpoint(mobile){
            padding:30px 0 40px;
            flex-direction:column;
        }
        h1,h2,h3,h4,h5,h6{
            @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
            letter-spacing:-1.5px;
            max-width:240px;
            width:100%;
            margin:0;
            @include breakpoint(tab-max){
                @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                letter-spacing:-2px;
            }
            @include breakpoint(mobile){
                margin-bottom:40px;
            }
        }
        p{
            @include ann-describtion(1rem, $lato-regular, 1.5rem);
            letter-spacing:normal;
            max-width:780px;
            margin-right:3.75rem;
            @include breakpoint(tab-max){
                margin:0;
            }
            @include breakpoint(tab){
                max-width:476px;
            }
        }
    }
}