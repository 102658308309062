@mixin breakpoint($point) {
  @if $point == desktop-xxxlg {
    @media (min-width: 1440px) and (max-width: 1920px) {
      @content;
    }
  } @else if $point == desktop-large {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1440px) and (max-width: 1919px) {
      @content;
    }
  }
  @else if $point == lg {
    @media (max-width: 1439px) {
      @content;
    }
  }
  @else if $point == desktop-extra-lite-sm {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-lite-lg {
    @media (min-width: 1024px) and (max-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-lite {
    @media (min-width: 1201px) {
      @content;
    }
  } @else if $point == desktop-extra-sm {
    @media (min-width: 1024px) and (max-width: 1280px) {
      @content;
    }
  }
  @if $point == desktop-xlg {
    @media (min-width: 1601px) {
      @content;
    }
  }
  @if $point == desktop-lg {
    @media (min-width: 1024px) and (max-width: 1600px) {
      @content;
    }
  }
  @if $point == desktop-mid {
    @media (min-width: 1024px) and (max-width: 1440px) {
      @content;
    }
  } @else if $point == desktop-small {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == tab {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == mobile-portrait {
    @media (max-width: 560px) {
      @content;
    }
  } @else if $point == tab-max {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $point == ipad-landscape {
    @media (min-width: 992px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == tab-min {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == xs {
    @media (max-width: 768px) {
      @content;
    }
  }
}

//  copied from mira styling --jy
@mixin xxl {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1440px) and (max-width: 1919px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1219px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 767px) {
    @content;
  }
}
