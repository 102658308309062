.carousel-slick{
    &.carousel-header-content {
        &.content-three-up {
            padding: 0;
            .carousel-header-info{
                display: flex;
                flex-direction: column;
                position: relative;
                max-width:1380px;
                margin:0 auto;
                @include breakpoint(desktop-small){
                    padding: 70px 6.875rem 50px;
                    max-width: 1600px;
                    margin: 0 auto;
                }
                @include breakpoint(desktop-mid){
                    padding:6.25rem 2rem 5rem;
                }
                @include breakpoint(tab-max){
                    position:static;
                }
                @include breakpoint(tab){
                    padding: 50px 2rem 30px;
                }
                @include breakpoint(mobile){
                    padding: 50px 1.125rem 30px;
                }
                .cmp-carousel{
                    &__title{
                        width: 100%;
                        h1,h2,h3,h4,h5,h6,p{
                            @include ann-describtion(1.625rem, $lato-regular, 2.25rem);
                            margin: 0;
                            letter-spacing: 0;
                            color: $text-medium-dark-grey;
                            text-align: left;
                            padding: 0 0 10px;
                            @include breakpoint(tab-max){
                                font-size: 1.125rem;
                                line-height: 1.75rem;
                                padding: 0 0 20px;
                            }
                        }
                    }
                    &__description{
                        width: 19.6875rem;
                        @include breakpoint(tab){
                            width: 100%;
                        }
                        h1,h2,h3,h4,h5,h6,p{
                            @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                            letter-spacing: 0;
                            color: $text-medium-dark-grey;
                            text-align: left;
                            @include breakpoint (desktop-small) {
                                width: 19.6875rem;
                            }
                        }
                    }
                    &__action-container{
                        position: absolute;
                        right: 0;
                        @include breakpoint (desktop-small) {
                            right: 6.875rem;
                        }
                        @include breakpoint(desktop-mid){
                            right:2rem;
                        }
                        @include breakpoint(tab-max){
                            bottom: 0;
                            margin:2.5rem 0 5rem;
                            text-align: center;
                            left:0;
                        }
                    }
                    &__action-link{
                        color: $color-medium-blue;
                        position: relative;
                        bottom:0;
                        @include breakpoint(tab){
                            width: unset;
                            top:30px;
                        }
                        @include breakpoint (mobile) {
                            top: 30px;
                        }
                    }
                }
            }
            .slick-initialized {                
                .slick-list {
                    padding-right:5rem;
                    // max-width: 1600px;
                    // margin: 0 auto;
                    @include breakpoint(tab){
                        padding-right: 7.5rem;
                    }
                    @include breakpoint(mobile){
                        margin-left: 0;
                        padding-right: 1.6875rem;
                    }
                    .slick-track {
                        .slick-slide {
                            @include breakpoint(desktop-small){
                                margin-right: 1.875rem;
                            }
                            @include breakpoint(tab-max){
                                margin-right: .625rem;
                            }
                        }
                    } 
                }
                .slick-dots{
                    margin: 2.1875rem 0 10.625rem;
                    line-height:0;
                    @include breakpoint(desktop-small){
                        margin: 50px 0 70px;
                    }
                    @include breakpoint(tab-max){
                        margin: 30px 0 140px;
                    }
                }
            }
            .more-collection-teaser {
                padding: 0;
                .cmp-teaser {
                    &__title {
                        text-transform: uppercase;
                        text-align: left;
                        color: $text-dark-grey;
                        margin-top: 1.875rem;   
                        letter-spacing: 2px;
                        @include ann-describtion(14px, $lato-regular, 16px);
                        @include breakpoint (tab-max) {
                            font-size: 12px; 
                            margin-top: 10px;
                        }
                    }           
                }
            }            
        }
    }    
}

.carousel-slick{
    &.carousel-header-content{
        &.carousel-swipe-scroll{
            padding: 4.375rem 0;
            @include breakpoint(tab-max){
                padding: 3.125rem 0;
            }
            @include breakpoint(mobile){
                padding: 3.125rem 0;
            }
            .carousel-header-info{
                max-width: 1820px;
                margin:0 auto;
                padding:0 0 3.125rem;
                @include breakpoint(desktop-lg){
                    margin:0 2.6%;
                }
                @include breakpoint(tab){
                    padding:0 2rem 2.5rem;
                }
                @include breakpoint(mobile){
                    padding:0 1.125rem 2.5rem;
                }
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(.875rem, $lato-regular, 1rem);
                    letter-spacing:.125rem;
                    color:$text-dark-grey!important;
                    margin-top:.625rem;
                    margin-bottom:.625rem;
                    padding:0;
                    text-align:left;
                    @include breakpoint(tab-max){
                        @include ann-describtion(.75rem, $lato-regular, 1rem);
                    }
                }
                .cmp-carousel{
                    &__title{
                        width: auto;
                        h1, h2, h3, h4, h5, h6{
                            @include headingtitle_h3;
                            color:$text-dark-grey;
                            letter-spacing:-0.0625rem;
                            margin:0;
                            @include breakpoint(tab-max){
                                letter-spacing:-0.073125rem;
                            }
                        }
                    }
                    &__description {
                        p {
                            @include breakpoint (mobile) {
                                width: 337px;
                            }
                        }
                        &__action-container {
                            position: absolute;
                            top: 0;
                            right: 0;
                            .cmp-carousel__action-link {
                                padding-left: 45px;
                                padding-right: 2.8125rem;
                            }
                        }
                    }
                }
            }
            .slick-initialized{
                margin:0;
                .slick-arrow{
                    opacity: 0;
                }
                .slick-list{
                    margin-left:0;
                    padding: 0 2.6%; 
                    @include breakpoint(tab){
                        padding:0 2rem;
                        padding-right: 78px;
                    }
                    @include breakpoint(mobile){
                        padding:0 1.125rem;
                    }
                }
                .slick-track{
                    .slick-slide{
                        margin-right:1.875rem;
                        @include breakpoint(tab){
                            margin-right:.625rem;
                        }
                        @include breakpoint(mobile){
                            margin-right:.625rem;
                        }
                    }
                    .productcard{
                        text-align:left;
                    }
                    .product-card{
                        max-width:100%;
                        min-width:100%;
                        @include breakpoint(tab-max){
                            max-width:100%;
                            min-width:100%;
                        }
                        &__img-container {
                            display: flex;
                            align-items: center;
                            background: $color-white;
                            margin-bottom: 15px;
                            @include breakpoint (tab-max) {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
                .slick-dots{
                    margin-top:3.75rem;
                    bottom:0;
                    position: relative;
                    @include breakpoint(tab){
                        margin-top:1.875rem;
                        top: 312px !important;
                    }
                    @include breakpoint(mobile){
                        margin-top:2.25rem;
                        top: 411px !important;
                    }
                }
            }
        }
    }
}