.container.three-up-container{
    .three-up-content-header {
        padding: 0 0 3.125rem;
        @include breakpoint (tab){
            padding: 0 0 2.5rem;
        }
        @include breakpoint (mobile){
            padding: 50px 0 0 0 ;
        } 
       .cmp-teaser{
            &__content {
                 position: relative;
                 @include breakpoint (mobile) {
                    position: static;
                    z-index: 1;
                    height: 100%;
                    width: 100%;
                 }
            }
            &__pretitle {
                 color: $text-dark-grey;
                 @include ann-describtion(.75rem, $lato-regular, 1rem);
                 letter-spacing: .125rem;
                 h1,h2,h3,h4,h5,h6,p{
                    color: $text-dark-grey;
                    @include ann-describtion(.75rem, $lato-regular, 1rem);
                    letter-spacing: .125rem;
                    @include breakpoint (tab-max){
                        letter-spacing: 2px;
                    }
                 }
            }
            &__title {
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                    letter-spacing: -1.17px;
                    color: $slate !important;
                    padding-top: .625rem;
                    @include breakpoint (tab-max) {
                        @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                    }
                }                 
            }
            &__description {
                width: 47.75%;
                @include breakpoint (tab){
                    width:63.50%;
                }
                @include breakpoint (mobile) {
                    width: 100%;
                }
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                    color:$text-medium-dark-grey;
                    letter-spacing: normal;
                    padding-top: 1.25rem;
                    @include breakpoint (tab-max){
                        @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    }                    
                }      
            }
            &__action-container {
                border-color: $color-medium-blue;
                border-radius: .0625rem;
                position: absolute;
                right: 0;
                top: 1.75rem;
               @include breakpoint (tab){
                   top: 1.3rem;
               }
               @include breakpoint (mobile) {
                  right: unset;
                  top: unset;
                  text-align: center;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  padding: 0;
               }
            }
            .cmp-teaser__action-container{
                .cmp-teaser__action-link {
                    color: $color-medium-blue;
                    font-family:$lato-bold;
                    font-size: 12px;
                    letter-spacing: 2px;
                    line-height: 16px;
                    text-align: center;
                    padding: 0 27px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                   @include breakpoint(mobile){
                    height: 50px;
                   }
                 }
            }
           
       }
    }
}

.teaser.three-up-content {
        width: 30.43%;
        padding: 0;
        margin: 0;
        @include breakpoint (tab){
            width: 32.35% !important;
        }
        @include breakpoint (mobile) {
            padding-top: 2.5rem;
            width: 100% !important;
        }         
        .cmp-teaser  {
            position: relative;
            &__content{
                padding: 1.875rem 0 0;
                text-align: left;
                @include breakpoint (tab-max){
                    padding: 1.25rem 0 0;
                }
            }
            &__title {
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(1.375rem, $lato-regular, 2rem);
                    color: $text-dark-grey;
                    letter-spacing: normal;
                    @include breakpoint (tab-max){
                        font-size: 18px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }                    
                }       
            }
            &__description {
                 h1,h2,h3,h4,h5,h6, p{
                    color: $text-medium-dark-grey;
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    letter-spacing: normal;
                    padding-top: .625rem;
                    padding-bottom: 0;
                    @include breakpoint (tab-max){
                        @include ann-describtion(.875rem, $lato-regular, 1.25rem); 
                    }
                    @include breakpoint (tab){
                        padding-right: 2.375rem;
                    }
                  }
            }
            &__action-container {
                  padding-top: 1.25rem;             
            }
            .cmp-teaser__action-container{
                .cmp-teaser__action-link{
                    color: $color-light-blue;
                    line-height: 16px;
                    padding: 0 0 .4375rem 0;
                    border-color: $color-light-blue;
                    letter-spacing: 1.8px;
                }
            }

            &__icons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                align-content: center;
                column-gap: .3125rem;
                position: absolute;
                top: 1.25rem;
                right:1.25rem;
                @include breakpoint(tab){
                    top:.625rem;
                    right:.625rem;
                }
                @include breakpoint (desktop-small){
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s,opacity .5s linear;
                }
                .favorite {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    height: 2.25rem;
                    width: 2.25rem;
                    background: $white;
                    border-radius: .3125rem;
                    span {
                        display: none;
                    }
                    &:before {
                        font-family: $icon-moon;
                        color: $text-medium-dark-grey;
                        margin: 0;
                    }
                }
                .share {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    height: 2.25rem;
                    width: 2.25rem;
                    background: $white;
                    border-radius: .3125rem;
                    z-index: 0;
                    &:before {
                        font-family: $icon-moon;
                        color: $text-medium-dark-grey;
                        margin: 0;
                    }
                    span {
                        display: none;
                    }
                }
                .share-cmp{
                    right: 0;
                }
            }
        }
        @include breakpoint (desktop-small){
            &:hover{
                .cmp-teaser__icons{
                    visibility: visible;
                    opacity: 1;
                    }
            }
        } 
    }
