.cart-items {
  .order-summary {
    display: none;
  }
  .cart-td-unit-price,
  .discount-container-padding {
    .cart-item__total-price {
      font-family: $helvetica-regular;
    }
    @media screen and (min-width: 1600px) {
      padding-left: 75px;
    }
    @media screen and (min-width: 1920px) {
      padding-left: 130px;
    }
    padding-left: 70px;
    @media screen and (max-width: 1080px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 800px) {
      padding-left: 10px;
    }
    @media only screen and (min-width: 1080px) and (max-width: 1170px) {
      padding-left: 20px;
    }
  }
  .discount-container-padding {
    @media screen and (max-width: 767px) {
      padding: 0;
      width: auto !important;
      float: right;
    }
  }
  .cart-table-header {
    border-bottom: 1px solid $color-dark-gray;
  }
  &__title {
    font-family: $font-light;
    font-size: 45px;
    line-height: 56px;
    letter-spacing: -3.19px;
    color: $color-slate;
    @media screen and (max-width: 1023px) {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -1.98px;
    }
  }
  .mobile-share-cart-id {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      margin: 25px 0 15px 0;
      width: 100%;
    }
  }
  &__alert {
    width: 100%;
    background-color: $color-error-red;
    margin: 30px 0 -5px 0;
    @media screen and (max-width: 767px) {
      margin: 5px 0 0 0;
      line-height: 24px;
      padding: 20px 18px 25px 18px;
      display: flex;
    }
    padding: 20px 25px;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.55px;
    color: $color-white;
    &-title {
      margin-right: 10px;
      font-family: $font-medium;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.35px;
      @media screen and (max-width: 767px) {
        line-height: 24px;
      }
    }
  }
  &__th--mobile {
    display: none;
  }
  &__td {
    font-family: $font-bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.45px;
    color: $color-slate;
    padding-bottom: 10px;
    margin-top: 35px;
    text-transform: uppercase;
    @media screen and (max-width: 1023px) {
      margin-top: 30px;
      font-size: 16px;
    }
  }
  &__empty {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    span {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-slate;
      text-decoration: underline;
      padding-bottom: 5px;
    }
    &.--loading {
      span {
        pointer-events: none;
      }
      div {
        cursor: not-allowed;
      }
    }
  }
  .share-cart-button {
    .kf-react-button button {
      &.large {
        &.ghost {
          margin-left: 200px;
          height: 40px;
          width: 230px;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.25px;
          margin-top: 25px;
          @media screen and (max-width: 1023px) {
            margin-left: 150px;
          }
        }
      }
    }
    &.copied button.share-button {
      &::after {
        content: " ";
        position: relative;
        display: inline-block;
        top: 0;
        transform: rotate(45deg);
        height: 1em;
        width: 0.5em;
        border-bottom: 0.15em solid;
        border-right: 0.15em solid;
        left: 10px;
        top: -2px;
      }
    }
  }
  .cart-id {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    margin-top: 40px;
  }
  &__download-print {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: 30px;
    span {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      img {
        padding-right: 10px;
      }
    }
  }
  &__no-items {
    padding: 65px 0 120px 0;
    @media screen and (max-width: 1023px) {
      padding: 10px 0 40px 0;
    }
    border-top: 1px solid $color-light-gray;
    &-message {
      font-family: $font-light;
      font-size: 45px;
      line-height: 56px;
      letter-spacing: -3.19px;
      @media screen and (max-width: 1023px) {
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -1.98px;
      }
      color: $color-slate;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    &-links {
      margin-top: 40px;
      font-family: $font-regular;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1023px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 27px;
        flex-wrap: wrap;
      }
      a,
      button {
        text-decoration: underline;
        text-underline-position: under;
        font-family: $font-regular;
        color: $color-dark-gray;
        width: 236px;
        height: 50px !important;
        @media screen and (max-width: 1023px) {
          width: 182px;
          height: 40px !important;
          font-size: 14px !important;
          letter-spacing: -0.25px !important;
        }
        &.sing-in-cart {
          margin-right: 20px;
          @media screen and (max-width: 767px) {
            margin: 0 0 15px 0;
          }
        }
        &:hover {
          text-decoration: underline;
          color: $color-black;
        }
      }
    }
  }
  .your-cart {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 1023px) {
    .order-summary {
      display: block;
      &__item {
        margin-bottom: 0;
      }
    }
    &__checkout-btn {
      margin-top: 30px;
    }
    &__th--mobile {
      display: flex;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    &__td--mobile {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
    }
  }
}

@media screen and (max-width: 767px) {
  .cart-item__price {
    font-size: 14px;
    text-align: right;
  }
  .cart-item__total {
    float: right;
    max-width: 100% !important;
  }

  .order-summary__item--value-large {
    font-size: 21px;
  }
  .cart-items__th .d-none {
    display: none !important;
  }
  .cart-items__no-items-links {
    &.d-flex {
      display: block !important;
    }
  }
}
.pairs-well-with.pairs-well-with--recommended-product.--recently-viewed {
  padding-top: 40px;
  .product-card__price {
    font-weight: unset;
  }
  .product-card__link {
    .product-card__details {
      .product-card__left {
        .product-card__name {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.73px;
        }
      }
    }
  }
  .product-card__img-container {
    margin-bottom: 20px;
    @media screen and (max-width: 1023px) {
      margin-bottom: 10px;
    }
  }
  .product-card__details {
    margin-top: 0;
  }
  .product-card-desc {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.48px;
  }
  .product-card__color-text {
    span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.48px;
    }
  }
  @media screen and (max-width: 1023px) {
    padding: 0;
    .pairs-well-with__title {
      h4 {
        padding-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 18px;
  }
  .pairs-well-with__carousel {
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      column-gap: 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 0 20px 0;
      column-gap: 25px;
    }
    @media screen and (max-width: 376px) {
      column-gap: 20px;
    }
    @media screen and (max-width: 1023px) {
      margin-bottom: 20px;
      .product-card {
        margin-right: 0px;
      }
    }
  }
}
.download-specs--mobile {
  &__cta {
    display: flex;
  }
  p,
  span {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-slate;
    img {
      margin-left: 10px;
    }
  }
  p {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  span {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:first-child {
      margin-right: 85px;
    }
  }
}
.no-border {
  border: unset;
}
.sing-in-cart-message {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.55px;
  color: $color-dark-gray;
  margin: 15px 0 0 0;
}
.cart-landing {
  .download-specs {
    padding: unset;
    border-radius: unset;
    border: unset;
    @media screen and (max-width: 1023px) {
      display: block !important;
    }
    p {
      display: none;
    }
    &__cta {
      justify-content: center;
      margin-top: 0;
      span {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: $color-slate;
        font-family: $helvetica-medium;
        border: 1px solid $color-slate;
        width: 129px;
        align-items: center;
        height: 40px;
        justify-content: center;
      }
      img {
        display: none;
      }
    }
  }
  .cart-id-share {
    display: flex;
    align-items: center;
  }
  .share-cart-id {
    font-size: 14px;
    margin: 0 20px 0 0;
    line-height: 22px;
    letter-spacing: -0.45px;
    font-family: $font-regular;
    color: $slate;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .social-share {
    &__sites {
      display: none;
    }
    &__box {
      right: 0;
    }
    &__actions {
      margin-top: 0;
      padding-top: 0;
      border-top: unset;
    }
  }
}
.cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100% !important;
    flex: 100% !important;
  }
}
.cart-qty-price-mobile {
  @media screen and (max-width: 767px) {
    display: block !important;
    margin: 20px 0 0 0;
  }
}
