@import "./ann_typography";
.carousel-featured-content {
    &.carousel-slick {
        padding: 4.375rem 0;
        height: auto;
        @include breakpoint(tab) {
            padding: 50px 0;
        }
        @include breakpoint(mobile) {
            padding: 50px 0 100px;
        }
        .cmp-carousel-slick {
            &.cmp-carousel {
                text-align: left;
                .carousel-header-info {                    
                    max-width: inherit;
                    @include breakpoint(desktop-xlg){
                        padding: 0 14.0625% 3.125rem;
                    }
                    @include breakpoint(desktop-lg){
                        padding: 0 6.875rem 3.125rem;
                        margin: 0;
                    }
                    @include breakpoint(tab) {
                        padding: 0 2rem 40px 2rem;
                    }
                    @include breakpoint(mobile) {
                        padding: 0 1.125rem 40px 1.125rem;
                        position:static;
                    }
                    h4 {
                        font-size: 0.75rem;
                        padding-bottom: 0.3125rem;
                        color: $text-dark-grey;
                        margin: 0;
                        padding-bottom: 0.625rem;
                    }
                    .cmp-carousel__pretitle{
                        font-size: 12px;
                        line-height: 16px;
                        font-family: $font-lato;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        color: $text-dark-grey;
                    }
                    .cmp-carousel__title {
                        width: auto;
                        h1, h2, h3, h4, h5, h6, p {
                            color: $text-dark-grey;
                            @include headingtitle_h5;
                            padding: 0;
                            margin: 0;    
                            color: $text-dark-grey;                
                        }
                    }
                    .cmp-carousel__action-container {
                        position: relative;
                        top: -3.375rem;
                        @include breakpoint(tab) {
                            top: -0.3125rem;
                        }
                        @include breakpoint(mobile) {
                            top: unset;
                            bottom:0;
                            width: 100%;
                            position: absolute;
                            left:0;
                            text-align: center;
                            padding:0 1.125rem;
                            margin-bottom: 50px;
                        }
                        .cmp-carousel__action-link {
                            color: $color-medium-blue;
                            border-color: $color-medium-blue;
                            position: absolute;
                            right: 0;
                            @include breakpoint(tab) {
                                width: auto;
                            }
                            @include breakpoint(mobile) {
                                position: unset;
                                right: unset;
                                bottom:0;
                                width:100%;                        
                            }
                        }
                    }
                }            
                .slick-initialized {
                    position: relative;
                    .slick-list {
                        margin-left: 0;
                        max-width: inherit;
                        @include breakpoint(desktop-xlg){
                            padding: 0 13.02091% 0 14.0625%;
                        }
                        @include breakpoint(desktop-lg){
                            padding: 0 5.625rem 0 6.875rem;
                        }
                        @include breakpoint(tab-max){
                            padding: 0;
                        }
                        
                        .slick-slide{
                            @media (min-width: 1920px){
                                width: 1380px !important;
                            }
                            @include breakpoint(desktop-xxxlg){
                                max-width: unset!important;
                            }
                            @include breakpoint(desktop-xlg){
                                max-width: unset!important;
                            }
                            @include breakpoint(mobile){
                                max-width: 339px;
                            }
                            @include breakpoint(tab){
                                max-width: 786px;

                            }
                        }
                        .slick-active {
                            .cmp-teaser {
                                &__description {
                                    p {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                    .slick-dotted.slick-slider {
                        margin-bottom: 0;
                    }
                    .slick-dots {                        
                        text-align: right;
                        line-height: 0;
                        margin-top: 2.875rem;
                        position: absolute;
                        height: 10px;
                        @include breakpoint(desktop-xlg){
                            padding: 0 14.0625%;
                        }
                        @include breakpoint(desktop-lg){
                            padding: 0 8.125rem;
                        }
                        @include breakpoint(desktop-small){
                            bottom: .9375rem;
                        }
                        @include breakpoint(tab-max){
                            text-align:center;
                            margin: 1.875rem 0;
                            padding:0;
                        }
                        @include breakpoint(tab) {
                            top: 429px !important;
                        }
                        @include breakpoint(mobile) {
                            top: 189px !important;
                        }
                        li {
                            margin: 0 0 0 .9375rem;
                            button {
                                height: .625rem;
                                width: .625rem;
                                padding: 0;
                            }
                        }
                    }
                    .teaser {
                        padding: 0;
                    }
                    .slick-slide {
                        margin: 0;
                        margin-right: 20px;
                        max-width:100%;
                        @include breakpoint(desktop-lg) {
                            padding: 0 1.25rem 0 0;
                            margin-right: 0;
                        }
                        @include breakpoint(tab) {
                            padding: 0 10px 0 0;
                            margin: 0;
                        }
                        @include breakpoint(mobile){
                            margin-right: 10px;
                        }
                    }
                    .slick-arrow {
                        color: $white;
                        z-index: 9;
                        border: 1px solid $white;
                        padding: 0.3125rem;
                        width: 3.125rem;
                        height: 3.125rem;
                        opacity: 0.3;
                        display: none !important;
                        @include breakpoint(tab-max) {
                            display:none !important;
                        }                   
                        &.slick-disabled {
                            opacity: 0.3;
                            cursor: not-allowed;
                            &:hover{
                                opacity:0.3;
                            }
                        }
                        &:hover {
                            opacity: 1;
                        }
                        &.slick-prev {
                            left: 10.9375rem;
                            @include breakpoint(desktop-xlg){
                                left: 17.5%;
                            }
                            @include breakpoint(tab) {
                                left: 2rem;
                            }
                            @include breakpoint(mobile) {
                                left: 1.1875rem;
                            }
                        }
                        &.slick-next{
                            right: 10.9375rem;
                            @include breakpoint(desktop-xlg){
                                right: 17.5%;
                            }
                            @include breakpoint(tab) {
                                right: 4.875rem;
                            }
                            @include breakpoint(mobile) {
                                right: 3.1875rem;
                            }
                        }
                    }
                }                
            }
        }                        
    }        
}
.featured-content-teaser {
    &.teaser {
        .cmp-teaser {
            &__image {
                @include breakpoint(desktop-small){
                    max-height:776px;
                }
                @include breakpoint(tab){
                    min-height: 429px;
                }
                @include breakpoint(mobile){
                    min-height:189px;
                }
                .cmp-image {     
                    @include breakpoint(tab){
                        min-height:inherit;
                    }
                    @include breakpoint(mobile){
                       min-height:inherit;
                    }
                    img{
                        min-height:inherit;
                    }
                }                
            }            
            &__description {
                p {
                    color: $text-medium-dark-grey;
                    width: 41.25rem;                    
                    @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                    letter-spacing: normal;
                    padding-top: 1.875rem;
                    display: none;
                    @include breakpoint(tab) {
                        width: 46.75rem;
                        padding: 70px 0 0 0;
                        margin-left: 2rem;
                    }
                    @include breakpoint(mobile) {
                        width: 21.1875rem;
                        padding: 70px 0 40px;
                        margin: 0 0 0 1.125rem;
                    }
                }
            }
        }
    }
}
//

