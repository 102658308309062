.lead-gen-main {
  max-width: 780px;
  margin: 90pt auto;
  color: $slate;

  .contact-title {
    &__fields {
      margin-bottom: 40px;
    }
  }

  .required-fields {
    font-family: $font-lato;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: normal;
    color: $text-medium-dark-grey;
  }

  .lead-gen-footer {
    &__submit-button {
      height: 50px;
      padding: 0px !important;
      &.disabled {
        background-color: $light-gray !important;
      }
    }
  }

  .input-field {
    margin-bottom: 20px;
  }

  .Button_buttonWrapper__TBDg1 {
    .kf-react-button {
      &.disabled {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        height: inherit;
        font-family: $font-lato;
        text-transform: uppercase;
        color: $white-cap;
        font-weight: 700;
        background-color: $cool-dark-grey-cap !important;
      }
      &.large {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        font-family: $font-lato;
        text-transform: uppercase;
        background-color: $color-medium-blue-cap;
        color: $white-cap;
        font-weight: 700;
        height: inherit;
      }
    }
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }
}

.lead-gen-confirmation-message {
  &-wrapper {
    display: flex;
    padding: 130px 80px 150px 80px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    align-self: stretch;
    background: $color-light-grey;
    @include breakpoint(tab-max) {
      padding: 40px 32px;
      align-items: flex-start;
      gap: 40px;
    }
    @include breakpoint(mobile-portrait) {
      padding: 40px 18px;
    }
  }
  &__container {
    display: flex;
    padding: 0px 32px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    @include breakpoint(tab-max) {
      flex-direction: column;
      gap: 40px;
    }
    @include breakpoint(mobile) {
      padding: 0;
    }
    .message-content {
      display: flex;
      padding-right: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;
      align-self: stretch;
      @include breakpoint(mobile-portrait) {
        padding: 0;
      }
      .success-message-title p {
        color: $text-dark-grey;
        font-family: $playfair-display-regular;
        font-size: 54px;
        font-style: normal;
        font-weight: 400;
        line-height: 62px;
        letter-spacing: -1px;
        @include breakpoint(tab-max) {
          font-size: 28px;
          font-weight: 400;
          line-height: 34px;
          letter-spacing: -1.17px;
        }
      }
      .success-message-description {
        p {
          color: $text-medium-dark-grey-small;
          font-family: $font-lato;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: normal;
        }
        @include breakpoint(tab-max) {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }
    }
    .lead-gen-response-message {
      &__cta {
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 1px;
        @include breakpoint(mobile-portrait) {
          width: 100%;
        }
        .start-shopping-btn {
          text-align: center;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-transform: uppercase;
          @include breakpoint(mobile-portrait) {
            width: 100%;
          }
        }
        .Button_buttonWrapper__TBDg1 {
          .button {
            padding: 0px 27px;
            height: 50px;
            background-color: $color-medium-blue;
            letter-spacing: 2px;
            font-size: 12px;
            font-family: $font-lato;
            color: $color-white;
          }
        }
      }
    }
  }
}
.ann-sacks-plp-hero-banner {
  .cmp-hero {
    &__container {
      justify-content: center;
      text-align: center;
      &__article_details {
        align-items: center;
        &__headline {
          font-size: 70px;
          font-weight: 400;
          line-height: 78px;
          letter-spacing: -1.5px;
          @include breakpoint(tab-max) {
            font-size: 36px;
            font-weight: 400;
            line-height: 44px;
            letter-spacing: -2px;
          }
        }
        &__subHeadline p{
          font-family: $font-lato;
          justify-content: center;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          @include breakpoint(tab-max) {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}

.ann-sacks-plp-hero-banner{
  text-align: center;
  padding:6.25rem 2.6% 4.125rem;
  @include breakpoint(desktop-xlg){
      padding:93px 50px 60px;
  }
  @include breakpoint(desktop-lg){
      padding: 5.8125rem 0 3.75rem;
  }
  @include breakpoint(desktop-mid){
      padding: 93px 36px 4.125rem;
  }
  @include breakpoint(tab){
      padding: 4.5625rem 2rem 2.5rem;
  }
  @include breakpoint(mobile){
      padding: 4.5625rem 1.125rem 2.5rem;
  }
  .cmp-teaser{ 
      display: block;
      max-width:1820px;
      margin:0 auto;
      @include breakpoint(desktop-lg){
          max-width: 1380px;
      }     
      &__content{
          text-align: center;
          margin:0 auto;
          margin-bottom: 3.75rem;
          @include breakpoint(tab){
              width: 45.46%;
          }
          @include breakpoint(mobile){
              width: 62.065%;
          }              
      }
      &__title {
        text-align: center;
        margin: 0 auto;
          h1,h2,h3,h4,h5,h6,p{
              @include ann-describtion(4.375rem, $playfair-regular, 4.875rem);
              letter-spacing: -1.5px;
              @include breakpoint (mobile) {
                  letter-spacing:  -0.175rem;
              }
              @include breakpoint(tab-max) {
                  @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                  letter-spacing: -0.175rem;
              }
          }
      }
      &__description{
          h1,h2,h3,h4,h5,h6,p {
              @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
              letter-spacing: 0.5px;
              margin: 1.25rem auto 0;
              @include breakpoint(tab-max) {
                  @include ann-describtion(.8125rem, $lato-regular, 1.25rem);
                  letter-spacing: .0125rem;
              }
              @include breakpoint(mobile) {
                  text-align: center;
                  width:100%;
              }
          }
      }
      &__action-link{
          margin: 1.5625rem 0 .875rem;
          padding:0;
          color:$color-light-blue;
          line-height: .75rem;
          letter-spacing: 1.83px !important;
          padding-bottom:.4375rem;
          border-color:$color-light-blue;
      }
  }
  &.line-separator{
      .cmp-teaser{
          border-bottom:1px solid $cool-dark-grey;
      }
  }
}