.large-featured-image {
    padding: 5.625rem 0;
    margin: 0 auto;
    width: 48.75rem;
    color: $text-dark-grey;
    margin-top: 0px !important;
    @include breakpoint(tab) {
        width: 24.75rem;
        padding:2.875rem 0 1.875rem;
    }
    @include breakpoint(mobile) {
        width: 21.1875rem;
        padding:3.125rem 0 1.875rem;
    }
    .cmp-teaser {
        display: unset;
        &__image {
            .cmp-image{
                max-height:925px;
                @include breakpoint(desktop-small){
                    max-width: 780px;
                    max-height: 925px;
                }
                @include breakpoint(tab){
                    min-width: 396px;
                    min-height: 469px;
                }
                @include breakpoint(mobile){
                    min-width: 339px;
                    min-height: 402px;
                }
            }
        }
    }
    .cmp-img-caption {
        padding: 1.25rem 0 0;
        h1, h2, h3, h4, h5, h6, p {
            @include ann-describtion(0.75rem, $lato-regular, 1rem);
            color: $text-dark-grey;
            letter-spacing: 0.01375rem;
            @include breakpoint(mobile) {
                width: 100%;
            }
            a{
                @include ann-describtion(0.75rem, $lato-regular, 1rem);
                color: $text-dark-grey;
                letter-spacing: 0.01375rem;
                text-decoration: underline !important;
                &:focus{
                    text-decoration: none;
                    outline: none !important;
                }
            }
        }
    }
}
.large-featured-full-bleed-image {
    max-width: 1600px;
    margin: 0 auto;
    padding:0;
    float:none !important;
    @include breakpoint (desktop-small) {
        margin-top: 70px;
        margin-bottom: 35px;
    }
    @include breakpoint (tab) {
        margin-top: 50px;
        margin-bottom: 20px;
    }
    @include breakpoint (mobile) {
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .cmp-teaser {
        &__image {
            .cmp-image{
                max-height:700px;
                @include breakpoint(desktop-small){
                    max-width: 1600px;
                    max-height: 700px;
                }
                @include breakpoint(tab){
                    min-width: 812px;
                    max-height:355px;
                }
                @include breakpoint(mobile){
                    min-width: 375px;
                    min-height: 450px;
                    max-height:450px;
                }
                &__image{
                        max-height:inherit;
                    @include breakpoint(tab-max){
                        min-height:inherit;
                    }
                }
            }
        }
    }
    .cmp-img-caption {
        @include breakpoint(desktop-xlg){
            padding: 1.25rem 0 2.1875rem 6.875rem;
        }
        @include breakpoint(desktop-lg){
            padding: 1.25rem 2.25rem 2.1875rem;
        }        
        @include breakpoint(tab) {
            padding: 1.25rem 2rem 1.875rem;
        }
        @include breakpoint(mobile) {
            padding: 1.25rem 1.125rem 1.875rem;
        }
        h1,h2,h3,h4,h5,h6,p {
            @include ann-describtion(0.75rem, $lato-regular, 1rem);
            color: $text-dark-grey;
            a {
                color: $text-dark-grey !important;
                text-decoration: underline !important;
            }
            letter-spacing: 0.01375rem;
            @include breakpoint(desktop-lg){
                max-width: 1380px;
                margin: 0 auto;
            }
            @include breakpoint(mobile) {
                width: 100%;
            }
        }
    }
}
.large-featured-landscape-img {
    @include breakpoint (desktop-small) {
        margin-top: 70px;
    }
    @include breakpoint(desktop-mid){
        padding:0 2.25rem;
    }
    @include breakpoint(tab) {
        padding: 0 2rem;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    @include breakpoint(mobile) {
        padding: 0 1.125rem;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .cmp-teaser {
        max-width:1380px;
        margin:0 auto;
        @include breakpoint(tab-max){
            width:100%;
        }
        &__image {
            .cmp-image{
                max-height: 800px;
                @include breakpoint(desktop-small){
                    max-width: 1380px;
                    max-height: 800px;
                }
                @include breakpoint(tab){
                    min-width: 748px;
                    max-height: 433px;
                }
                @include breakpoint(mobile){
                    min-width: 339px;
                    min-height: 450px;
                    max-height: 450px;
                }
                &__image{
                    max-height:inherit;
                    @include breakpoint(tab-max){
                        min-height:inherit;
                    }
                }
            }
        }
    }
    .cmp-img-caption{
        padding: 1.25rem 0 2.1875rem;
        @include breakpoint(tab-max) {
            padding: 1.25rem 0 1.875rem;
        }
        @include ann-describtion(0.75rem, $lato-regular, 1rem);
        color: $text-dark-grey;
        letter-spacing: 0.01375rem;
        max-width: 1380px;
        margin: 0 auto;
        @include breakpoint(tab-max){
            max-width: 100%;
            margin: 0;
        }
        p{
            @include ann-describtion(0.75rem, $lato-regular, 1rem);
            color: $text-dark-grey;
            letter-spacing: 0.01375rem;
            a {
                color: $text-dark-grey !important;
                text-decoration: underline !important;
            }
        }
    }
} 
.bg-white .large-featured-landscape-img, .bg-white .large-featured-full-bleed-image, .bg-white .large-featured-image {
    .cmp-img-caption {
        color: $text-dark-grey;
    }
}