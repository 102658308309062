.teaser.store-summary {
    width: 100%;
    box-sizing: content-box;
    @include breakpoint(desktop-xlg){
        margin-right: 120px;
        max-width: 420px;
        padding: 0 !important;
        margin: 0;
    }
    @include breakpoint(desktop-lg){
        max-width: 22.5rem;
        padding: 0 3.75rem 0 7.5rem;
    }
    @include breakpoint(desktop-mid){
        padding:0 2.25rem;
    }
    @include breakpoint(tab){
        width: 21.25rem;
        padding: 3.125rem 2rem 5rem;
    }
    @include breakpoint(mobile){
        width:100%;
        padding:2.5rem 1.125rem 3.125rem !important;
        box-sizing: border-box;
    }
    .cmp-teaser{
        &__content{  
            @include breakpoint (desktop-xlg) {
                padding: 80px 0;
            }
            @include breakpoint (tab) {
                width: 21.1875rem;
                min-height: unset !important;
            }
            @include breakpoint(mobile){
                width:100%;
            }  
        }
        &__title{
            h1,h2,h3,h4,h5,h6{
                max-width: 250px;
                @include ann-describtion(2.625rem, $playfair-regular, 3rem);
                letter-spacing: -0.0731rem;
                color:$text-dark-grey;
                padding: 0 0 20px 0 !important;
                @include breakpoint(tab-max){
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                }
            }
            p{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0.44px;
                color:$text-dark-grey;
            }
        }
        &__description{
            p{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: normal;
                color:$text-medium-dark-grey;
                &:first-child{
                    padding-top: 1.875rem;
                    padding-bottom: .9375rem;
                    @include breakpoint(tab-max){
                        padding-top: 1.875rem;
                    }
                }
                &:nth-child(2){
                    padding-top: .9375rem;
                    padding-bottom: 20px;
                    @include ann-describtion (1rem, $lato-regular, 24px);
                    letter-spacing: normal;
                    @include breakpoint(tab-max){
                        padding-top: .9375rem;
                    }
                }
                &:nth-child(3){
                    padding-bottom: 0;
                }
                &:nth-child(4){
                    padding-top: 0;
                     @include breakpoint (desktop-small) {
                       padding-bottom: 20px;
                     }
                     @include breakpoint (tab-max) {
                        padding-bottom: 20px;
                     }
                }
                &:last-child{
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    padding-top: 0;
                    @include breakpoint (desktop-small) {
                    padding-bottom: 46px;  
                    }
                    @include breakpoint (mobile) {
                        padding-bottom: 0;  
                        }
                }
            }
        }
        &__action-container {
            @include breakpoint (tab) {
                bottom: 50px !important;
            }
        }
        &__action-link{
            &.gbh-primary-button{
                height: 3.75rem;
                @include ann-describtion(.75rem !important, $lato-regular, 1rem !important);
                letter-spacing: 2px !important;
                padding: 21px 1.6875rem !important ;
                @include breakpoint(tab){
                    width: 340px;
                }
                @include breakpoint(mobile){
                    margin-top: 2.5rem;
                    width: 100%;
                }
            }
            &.gbh-secondary-button{
                height: 60px;
                margin-top: 1.25rem;
                @include ann-describtion(.75rem !important, $lato-regular, 1rem !important);
                letter-spacing: 2px !important;
                padding: 21px 1.6875rem !important ;
                @include breakpoint(tab){
                    width: 340px;
                }
                @include breakpoint(mobile){
                    width: 100%;
                }
            }
        }
    }
}
// CTA Styles
.bg-dark-blue-three {
    .store-summary{
        .cmp-teaser__action-container{
            .cmp-teaser__action-link{
                &.gbh-primary-button{
                    color: $color-medium-blue;
                    background: $white;
                    border: 1px solid $white;
                    border-radius: 1px;
                 }
                &.gbh-secondary-button{
                    color: $white;
                    background: transparent;
                    border: 1px solid $white;
                    border-radius: 1px;
                }
            }
        }
    }
}
.bg-white { 
    .store-summary{
        .cmp-teaser__action-container{
            .cmp-teaser__action-link{
                &.gbh-primary-button{
                    margin-right: unset;
                    color:  $white;
                    background: $color-medium-blue;
                    border: 1px solid $color-medium-blue;
                    border-radius: 1px;
                }
                &.gbh-secondary-button{
                    color: $color-medium-blue;
                    background: transparent;
                    border: 1px solid $color-medium-blue;
                    border-radius: 1px;
                }
            }
        }
    }
}
// Image Component CTA styles for ML
.bg-dark-blue-three {
    &.store-virtual-image-right .image{
        .cmp-teaser__action-container{
            .cmp-teaser__action-link{
                &.gbh-primary-button{
                    @include breakpoint(tab){
                        color: $color-medium-blue;
                        background: $white;
                        border: 1px solid $white;
                        border-radius: 1px;
                    }
                 }
                &.gbh-secondary-button{
                    @include breakpoint(tab){
                        color: $white;
                        background: transparent;
                        border: 1px solid $white;
                        border-radius: 1px;
                    }
                }
            }
        }
    }
}
.bg-white { 
    &.store-virtual-image-right .image{
        .cmp-teaser__action-container{
            .cmp-teaser__action-link{
                &.gbh-primary-button{
                    @include breakpoint(tab){
                        color:  $white;
                        background: $color-medium-blue;
                        border: 1px solid $color-medium-blue;
                        border-radius: 1px;
                        width: 100%;
                    }
                }
                &.gbh-secondary-button{
                    @include breakpoint(tab){
                        color: $color-medium-blue;
                        background: transparent;
                        border: 1px solid $color-medium-blue;
                        border-radius: 1px;
                        width: 100%;
                    }
                }
            }
        }
    }
}