
/* Primary Colors */

$color-white: #FFFFFF;
$color-black: #000000;
$color-charcoal: #212121;
$color-slate: #494949;
$color-dark-gray: #757575;
$color-light-gray: #CDCDCD;
$color-extra-light-gray: #E6E6E6;
$color-off-white: #F9F9F9;
$color-off-white-dark:#f0f0f0;

/* Secondary Colors */

$color-kohler-blue: #022E49;
$color-kohler-blue-loader: #345366;
$color-accent-blue-a: rgba(24, 104, 165, 0.1);
$color-accent-blue-b: rgba(24, 104, 165, 0.05);
$color-medium-gray: #989898;
$color-tile-bg: #F4F4F4;
$color-bright-gray:#efefef;

/* Error States */

$color-error-red: #D10000;
$text-dark-grey:#424242;
$text-medium-dark-grey:#5D5D5D;
$text-light-grey-cap:#6F6F6F;
$color-slate-light: #404040