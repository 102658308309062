@font-face{font-family:MaterialIcons;font-display:swap;src:url(fonts/material-icons.woff2) format("woff2");font-weight:400;font-style:normal}
*{padding:0;margin:0;-ms-box-sizing:border-box;-moz-box-sizing:border-box;-o-box-sizing:border-box}
a{color:#1473e6;text-decoration:none}
a:hover{text-decoration:underline}
.cmp-accordion__item{position:relative}
.cmp-accordion__header{border-bottom:1px solid #e1e1e1;margin:0 0 10px}
.cmp-accordion__button{border:0;background:0;width:100%;text-align:left;padding:.5rem 0}
.cmp-accordion__button:active,.cmp-accordion__button:focus{outline:0}
.cmp-accordion__button--expanded .cmp-accordion__icon:before{content:"expand_more"}
.cmp-accordion__icon:before{content:"chevron_right";position:absolute;top:.625rem;right:.5rem;font-family:MaterialIcons;font-weight:400;font-style:normal;font-size:1.125rem;line-height:1;text-transform:none;letter-spacing:normal;word-wrap:normal;white-space:nowrap;direction:ltr;-webkit-font-smoothing:antialiased;text-rendering:optimizeLegibility;-moz-osx-font-smoothing:grayscale}
.cmp-breadcrumb__list{font-size:9pt;list-style:none;padding:0;margin:0}
.cmp-breadcrumb__item{display:inline-block;padding:10px 10px 10px 0}
.cmp-breadcrumb__item-link{color:#737373;text-decoration:none}
.cmp-breadcrumb__item-link:hover{color:#000}
.cmp-breadcrumb__item-link:after{content:">";margin:0 0 0 .5rem}
.button .cmp-button{font-size:9pt;font-weight:700;text-align:center;text-transform:uppercase;text-decoration:none;color:#737373;background:0;background-image:none;border:2px solid #737373;border-radius:3px;display:inline-block;padding:8px 18px;margin:0;cursor:pointer;vertical-align:middle;white-space:nowrap;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.button .cmp-button:hover{color:#232323;text-decoration:none}
.button .cmp-button:active,.button .cmp-button:focus{outline:0;text-decoration:none}
.button .cmp-button:disabled{cursor:not-allowed;filter:alpha(opacity=65);-webkit-opacity:.65;-moz-opacity:.65;opacity:.65;-webkit-box-shadow:none;box-shadow:none;pointer-events:none}
.cmp-carousel__content .cmp-image__image{width:100%}
.cmp-carousel__item{display:none}
.cmp-carousel__item--active{display:block}
.cmp-carousel__indicators{margin:.5em 0 0;cursor:pointer;list-style:none;text-align:center}
.cmp-carousel__indicator{display:inline-block;width:8px;height:8px;margin:0 .375rem;border-radius:50%;text-indent:-3000px;cursor:pointer;background-color:#000;outline:0}
.cmp-carousel__indicator:first-child{vertical-align:-6px}
.cmp-carousel__indicator--active{vertical-align:-4px;width:9pt;height:9pt}
.cmp-carousel__actions{float:right;margin-top:0}
.cmp-carousel__action{border:0;background-color:#f5f5f5;outline:0;margin-right:10px;padding:7px 15px}
.cmp-carousel__action-text{display:none}
.cmp-carousel__action-icon{cursor:pointer}
.cmp-carousel__action--previous .cmp-carousel__action-icon:before{content:"<"}
.cmp-carousel__action--next{margin-right:0}
.cmp-carousel__action--next .cmp-carousel__action-icon:before{content:">"}
.cmp-download{width:25pc;border:1px solid #e1e1e1;padding:10px;margin:0}
.cmp-download__title{font-size:1pc;font-weight:600;text-transform:uppercase;margin:0 0 .5rem}
.cmp-download__title-link{color:#1473e6}
.cmp-download__description{font-weight:400;font-size:14px;margin:0 0 1.125rem}
.cmp-download__description p{font-weight:inherit;font-size:inherit;text-transform:uppercase;margin:0;padding:0}
.cmp-download__properties{width:100%;margin:0 0 1.125rem}
.cmp-download__property{display:inline-block;font-size:11px;color:#737373;text-transform:uppercase;background-color:#ebebeb;padding:.5rem;margin-right:.25rem}
.cmp-download__property-label{display:none}
.cmp-download__property-content{margin-right:1em}
.cmp-download__action{display:inline-block;border:1px solid #737373;border-radius:.1875rem;color:#737373;font-weight:700;text-transform:uppercase;text-decoration:none;cursor:pointer;padding:.5rem 1.125rem}
.cmp-download__action-text{font-size:9pt;font-weight:700;text-transform:uppercase;padding:1pc 2.5em 0 2.25em}
.cmp-embed-dialog-edit__type coral-radio{display:inline-block !important}
.cmp-image{position:relative}
.cmp-image__image{display:block}
.cmp-image__title{display:block;margin:.75rem 0;color:#737373}
*{-webkit-box-sizing:border-box;box-sizing:border-box}
.cmp-img-container{max-width:1380px;margin:0 auto}
.cmp-img-container .cmp-img-panel{position:relative;height:355px}
.cmp-img-container .cmp-img-panel .cmp-img__compare{position:absolute;width:auto;height:auto;overflow:hidden}
.cmp-img-container .cmp-img-panel .cmp-img__compare img{display:block;vertical-align:middle;width:1380px;height:350px}
.cmp-img-container .cmp-img__compare--slider{position:absolute;z-index:9;cursor:ew-resize;width:40px;height:40px;background-color:#2196f3;opacity:.7;border-radius:50%}
.cmp-img-container .cmp-img__copyright{width:20px;display:inline;vertical-align:middle;text-align:center}
.cmp-img-container .cmp-img__copyright .cmp-img__caption{width:20px;display:inline}
.cmp-languagenavigation{line-height:1.7rem}
.cmp-list{margin:0 0 0 1.4375rem;padding:0;list-style:none}
.cmp-list__item{margin:.5rem 0 0;padding:0;font-size:0;text-indent:-1.4375rem}
.cmp-list__item:first-child{margin-top:0}
.cmp-list__item:before{content:"-";display:inline-block;padding-left:1.4375rem;font-size:.875rem;text-align:center}
.cmp-list__item article{display:inline}
.cmp-list__item-link{text-decoration:none;color:#1473e6}
.cmp-list__item-link:hover{text-decoration:underline;color:#1267cf}
.cmp-list__item-date,.cmp-list__item-description,.cmp-list__item-title{font-size:.875rem}
.cmp-list__item-date:before,.cmp-list__item-description:before,.cmp-list__item-title:before{color:#232323}
.cmp-list__item-date{margin-left:.5rem}
.cmp-list__item-description{color:#737373;margin-left:.5rem}
.cmp-navigation{line-height:1.7rem}
.cmp-search{position:relative}
.cmp-search__field{position:relative;height:2rem}
.cmp-search__input{padding-left:2rem;padding-right:2rem;height:100%;width:100%}
.cmp-search__icon{display:block;background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTUyIiBoZWlnaHQ9IjExNTIiPjxwYXRoIGQ9Ik02NzIgMEM0MDYuOTAzIDAgMTkyIDIxNC45MDMgMTkyIDQ4MGMwIDk1LjcgMjguMDExIDE4NC44NTUgNzYuMjc1IDI1OS43MjVMMzYgOTcyYy0xOCAxOC0zNiAzNi0zNiA3MnMxOCA1NCAzNiA3MiAzNi4wMTIgMzYgNzIgMzYgNTQtMTggNzItMzZsMjMyLjI3NS0yMzIuMjc1QzQ4Ny4xNDQgOTMxLjk4OCA1NzYuMyA5NjAgNjcyIDk2MGMyNjUuMDk3IDAgNDgwLTIxNC45MDMgNDgwLTQ4MFM5MzcuMDk3IDAgNjcyIDB6bTAgODE2Yy0xODUuNTY4IDAtMzM2LTE1MC40MzMtMzM2LTMzNiAwLTE4NS41NjggMTUwLjQzMi0zMzYgMzM2LTMzNiAxODUuNTY3IDAgMzM2IDE1MC40MzIgMzM2IDMzNiAwIDE4NS41NjctMTUwLjQzMyAzMzYtMzM2IDMzNnoiLz48L3N2Zz4=");background-size:contain}
.cmp-search__icon,.cmp-search__loading-indicator{position:absolute;left:.5rem;top:.5rem;width:1rem;height:1rem}
.cmp-search__loading-indicator{display:none;border:3px solid #ccc;border-top-color:#333;border-radius:50%;-webkit-animation:cmp-search__loading-indicator-spin 2s linear infinite;animation:cmp-search__loading-indicator-spin 2s linear infinite}
.cmp-search__clear{display:none;margin:0;padding:0;border:0;background:transparent}
.cmp-search__clear-icon{position:absolute;top:.5rem;right:.5rem;background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTUyIiBoZWlnaHQ9IjExNTIiPjxwYXRoIGQ9Ik04Mi42IDkzNy4ybDM2Mi0zNjJMODQgMjE0LjhjLTE0LjEtMTQuMS0xNC4xLTM2LjkgMC01MWw4MS4yLTgxLjJjMTQuMS0xNC4xIDM2LjktMTQuMSA1MSAwbDM2MC41IDM2MC41TDkzNy4yIDgyLjZjMTQuMS0xNC4xIDM2LjktMTQuMSA1MSAwbDgxLjIgODEuMmMxNC4xIDE0LjEgMTQuMSAzNi45IDAgNTFMNzA4LjkgNTc1LjNsMzYwLjUgMzYwLjVjMTQuMSAxNC4xIDE0LjEgMzYuOSAwIDUxbC04MS4yIDgxLjJjLTE0LjEgMTQuMS0zNi45IDE0LjEtNTEgMEw1NzYuNyA3MDcuNWwtMzYyIDM2MmMtMTQuMSAxNC4xLTM2LjkgMTQuMS01MSAwbC04MS4yLTgxLjJjLTE0LTE0LjEtMTQtMzcgLjEtNTEuMXoiLz48L3N2Zz4=");background-size:contain;width:1rem;height:1rem}
.cmp-search__results{display:none;overflow-y:auto;min-width:100%;max-height:10rem;position:absolute;top:100%;left:0;z-index:999999;border:1px solid #ccc;background:#fff}
.cmp-search__item{display:block}
.cmp-search__item--is-focused{background-color:#ccc}
@-webkit-keyframes cmp-search__loading-indicator-spin{0%{-webkit-transform:rotate(0);transform:rotate(0)}
to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}
}
@keyframes cmp-search__loading-indicator-spin{0%{-webkit-transform:rotate(0);transform:rotate(0)}
to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}
}
.cmp-separator{padding:9pt 0}
.cmp-separator__horizontal-rule{height:1px;border:0;background-color:#505050}
.cmp-tabs__tablist{list-style:none;margin:0 0 10px;padding:0;text-align:center;display:block}
.cmp-tabs__tab{font-size:14px;font-weight:500;margin:0 6px;padding:8px 20px;display:inline-block;outline:0}
.cmp-tabs__tab:active,.cmp-tabs__tab:focus{outline:0}
.cmp-tabs__tab--active{border-bottom:2px solid #000;color:#000}
.cmp-tabs__tabpanel{width:100%;padding:0 20px;outline:0}
.cmp-tabs__tabpanel:focus{outline:0}
.cmp-teaser{display:inline-block}
.cmp-teaser__content{background-color:#fff}
.cmp-teaser__image{width:100%;min-height:100%;overflow:hidden}
.cmp-teaser__image .cmp-image__image{margin:0;padding:0;width:100%;vertical-align:top}
.cmp-teaser__description,.cmp-teaser__title{display:block;font-weight:400;margin:0}
.cmp-teaser__title{font-size:24px;color:inherit;padding:1rem 0 .83rem}
.cmp-teaser__title a{color:inherit}
.cmp-teaser__description{font-size:1pc;color:inherit;padding:1.14rem 0}
.cmp-teaser__description a{color:inherit}
.cmp-teaser__action-link{display:inline-block;cursor:pointer;line-height:2.5rem;text-align:center;color:#000;background-color:#fbfbfb;box-shadow:0 2px 2px 0 rgba(0,0,0,.2);-webkit-box-shadow:0 2px 2px 0 rgba(0,0,0,.2);border-radius:4px;-webkit-border-radius:4px;padding:0 20px}
.cmp-title{font-family:Roboto,sans-serif}
.cmp-title h1{font-size:2.375rem}
.cmp-title h2{font-size:1.875rem}
.cmp-title h3{font-size:1.5rem}
.cmp-title h4{font-size:1.25rem}
.cmp-title h5{font-size:1rem}
.cmp-title h6{font-size:.875rem}
.cmp-title__link{text-decoration:none;color:#1473e6}
.cmp-title__link:hover{color:#1267cf}
.create-account__account-type-box a.kf-react-button.button.large.black.flexible,.sign-in__sign-in-button a.kf-react-button.button.large.black.flexible,.signup-modal__submit-button a.kf-react-button.button.large.black.flexible{-webkit-transition:all .5s;transition:all .5s}
.create-account__account-type-box a.kf-react-button.button.large.black.flexible:active,.sign-in__sign-in-button a.kf-react-button.button.large.black.flexible:active,.signup-modal__submit-button a.kf-react-button.button.large.black.flexible:active{-webkit-transform:scale(.95);transform:scale(.95)}