.inspiration-module-carousel {
   display: flex;
   justify-content: center;
    .cmp-carousel{
        width: 1380px;
        margin: 0 auto ;
        @include breakpoint(tab-max) {
            width: 100%;
        }
    }
   
    &.carousel-slick {
        padding: 4.375rem 0;
        @include breakpoint(desktop-mid) {
            padding: 4.375rem 2.25rem;
        }
        @include breakpoint(tab) {
            padding: 3.125rem 2rem;
        }
        @include breakpoint(mobile) {
            padding: 3.125rem 0 3.125rem 0;
        }
        .cmp-carousel.cmp-carousel-slick {
            .slick-initialized {
                .slick-list {
                    padding: 0;
                    margin: 0 0 6.2rem 0;
                    @include breakpoint(tab) {
                        margin: 0 0 5.5rem 0;
                    }
                    @include breakpoint(mobile) {
                        margin: 0 0 4.125rem 0;
                        padding: 0 1.125rem;
                    }
                }
                .slick-prev,
                .slick-next {
                    display:none;
                }
                .slick-track{
                    display:flex;
                
                    width: 100% !important;
                    @include breakpoint(mobile){
                        min-width:2220px;
                        display:block;
                    }
                    .slick-slide {
                        margin: 0;
                        max-width:26.25rem;
                        @include breakpoint(tab){
                            max-width:242px;
                        }
                        @include breakpoint(mobile){
                            max-width:100%;
                        }
                        &:nth-child(even){
                            padding:0 3.75rem;
                            box-sizing:content-box;
                            @include breakpoint(tab){
                                padding:0 10px;
                            }
                            @include breakpoint(mobile){
                                padding:0 5px;
                            }
                        }
                    }
                } 
                
                .cmp-carousel-slick__item {
                    &.slick-active {
                        @include breakpoint(mobile) {
                            .cmp-teaser__content {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .slick-dotted.slick-slider {
        margin: 0;
    }
    &.title-top {
        &.carousel-slick{
            .cmp-carousel{
                &.cmp-carousel-slick{
                    .carousel-header-info {
                        position: static;
                        padding: 0 0 3.125rem 0;
                        @include breakpoint(tab) {
                            padding: 0 0 2.5rem 0;
                        }
                        @include breakpoint(mobile) {
                            padding: 0 1.125rem 3.125rem 0;
                        }
                        h4 {
                            text-align: center;
                            @include ann-describtion(.75rem, $lato-regular, 1rem);
                            letter-spacing: .125rem;
                            @include breakpoint(tab-max){
                                margin: 0;
                            }
                        }
                        .cmp-carousel__title {
                            width: 100%;
                            margin: 0;
                            padding: 0;
            
                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6,
                            p {
                                @include headingtitle_h1;
                                color: $text-dark-grey;
                                padding: 0;
                                width: 43.6875rem;
                                margin: 0 auto;
                                text-align: center;
                                @include breakpoint(tab-max) {
                                    letter-spacing: -0.175rem;
                                    padding: 0;
                                    margin: 0 auto;
                                }
                                @include breakpoint(tab-max) {
                                    max-width: 17.9375rem;
                                    width: 100%;
                                }
                            }
                        }
                        .cmp-carousel__action-container {
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            text-align: center;
                            left: 0;
                            margin-top: 50px;
                            @include breakpoint(tab-max) {
                                margin-top: 2.5rem;
                            }
                            @include breakpoint(tab) {
                                bottom: 3.1rem;
                            }
                            @include breakpoint(mobile) {
                                padding: 0 1.125rem;
                                bottom: 3.15rem;
                                z-index:1;
                            }
                            .cmp-carousel__action-link {
                                width: auto;
                                position: unset;
                                padding: 0.75rem 1.6875rem;
                                border-radius: 1px;
                                color: $color-medium-blue;
                                border-color: $color-medium-blue;
                                @include breakpoint(mobile) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.slick-slider-hidden {
        .slick-dots {
            opacity: 0;
            top: unset!important;
            @include breakpoint(mobile) {
                opacity: 1;
                bottom: 5.6rem;
                margin-top: 6.875rem;
            }
        }
    }
   
}
.teaser.inspiration-module{
    @extend .three-up-content;
    width: 420px;
    @include breakpoint (tab-max){
        width: 100%;
    }
    .cmp-teaser{
        &__title {
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                color: $text-dark-grey;
                letter-spacing: normal;
                @include breakpoint (tab-max){
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                }                    
            }       
        }
        &__pretitle {
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(18px, $lato-regular, 24px);
                color: $text-dark-grey !important;
                letter-spacing: 0.5px;
                @include breakpoint (tab-max){
                    font-size: 18px;
                    line-height: 24px;
                }                    
            }       
        }
        &__description {
            h1,h2,h3,h4,h5,h6, p{
               color: $text-medium-dark-grey;
               @include ann-describtion(14px, $lato-regular, 20px);
               letter-spacing: normal;
               padding-top: 10px;
               padding-bottom: 0;
            }
        }
        &__content{
            width: 210px;
            @include breakpoint (tab-max){
                width: 100%;
            }
        }
       }
}