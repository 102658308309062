.product-gallery {
  width: 100%;
  background-color: $cool-light-grey;

  &__badge {
    height: 31px;
    width: auto;
    position: absolute;
    top: 30px;
    left: 30px;
    display: flex;
    border: 1px solid rgba(56, 68, 81, 0.3);
    padding: 7.5px 10.5px;

    @include breakpoint(desktop-extra-sm) {
      top: 20px;
      left: 20px;
    }
    @include breakpoint(tab-max) {
      top: 20px;
      left: 20px;
    }
  }

  &__new,
  &__exclusive {
    color: $color-light-blue;
    font-family: $lato-bold;
    font-size: 9px;
    font-weight: normal;
    letter-spacing: 1.64px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }
}

.image-reel {
  margin: 0;

  &__image-thumb-view {
    padding: 0;
  }
}

.image-reel__image-thumb-view .image-reel__thumbSelected > img {
  height: 100%;
}

.image-reel__image-thumb-view
  .image-reel__image-list
  > div.image-reel__thumbSelected,
.image-reel__image-thumb-view .image-reel__image-thumb {
  height: 61.5px;
  width: 61.5px;
  border-radius: 1px;
  @include breakpoint(tab-max) {
    height: 49px;
    width: 49px;
  }
}

.image-reel__image-thumb-view
  .image-reel__image-list
  > div.image-reel__thumbSelected {
  border: 1.5px solid $text-dark-grey;
}

.image-reel__image-thumb-view .image-reel__image-thumb {
  border: 1px solid $color-extra-light-gray;
}

.image-reel__thumbSelected > img,
.image-reel__image-thumb-view .image-reel__image-thumb > img {
  height: 100%;
  width: 100%;
}

.image-reel__image-thumb {
  height: 61px;
  width: 61px;
  border: 1px solid $color-extra-light-gray;
  border-radius: 1px;
  @include breakpoint(tab-max) {
    width: 49px;
    height: 49px;
  }
}

.image-reel__image-list {
  padding-bottom: 0px;
  @include breakpoint(desktop-large) {
    padding-bottom: 0px;
  }
}

.image-reel__image-thumb-view .image-reel__image-list {
  height: auto;
  width: auto;
}

.image-reel__image-list > img {
  height: 100%;
}

.image-reel__image-view {
  margin: 11px auto 11px;
  width: 61px;
}

.image-reel__link-more,
.image-gallery__link-more.viewmore-mobile {
  color: $text-medium-dark-grey;
  font-family: $lato-regular;
  font-size: 11px;
  letter-spacing: 0.2px;
  line-height: 15px;
  display: inline-block;
  width: 65px;
}

.image-gallery__link-more.viewmore-mobile {
  @include breakpoint(tab) {
    margin: 0 5px;
  }

  @include breakpoint(mobile) {
    margin: 0 5px;
  }
}

.image-reel__image-stack-view {
  padding: 0;
  margin-left: 25px;
}

.image-reel__image-stack-view-block {
  width: 640px;
  height: 700px;
  background-color: $white-cap;

  @include breakpoint(tab) {
    height: 353px;
    width: 339.42px;
    border-radius: 1px;
    background-color: $white-cap;
  }
}

.image-reel__stack-list-image {
  height: 489px;
  width: 489px;
  margin: 105px 76px 105px 75px;
}

.image-reel__image-thumb-view-ul {
  padding: 0;
  margin: 0;

  @include breakpoint(tab) {
    display: flex;
  }
}

.image-reel__photo-credit {
  color: $text-dark-grey;
  font-family: $lato-regular;
  font-size: 11px;
  letter-spacing: 0.22px;
  line-height: 16px;
  margin-top: 20px;
  @include breakpoint(desktop-extra-lite-sm) {
    position: absolute;
    bottom: 0;
  }
  @include breakpoint(tab) {
    min-height: 15px;
    width: auto;
    letter-spacing: 0;
    margin-top: 15px;
  }

  @include breakpoint(mobile) {
    min-height: 16px;
    width: auto;
    letter-spacing: 0.22px;
    padding-left: 17px;
    margin-top: 10px;
  }
}

.image-gallery__image-stack-list {
  @include breakpoint(tab-max) {
    padding: 0;
    margin: auto;
    flex: 0 0 100%;
  }
}

.image-gallery {
  @include breakpoint(tab) {
    margin-bottom: 0;
  }

  @include breakpoint(mobile) {
    margin-bottom: 0;
  }

  &__image-stack-list > img {
    height: 390px;

    @include breakpoint(tab) {
      height: 258.87px;
      width: 258.87px;
      margin: 59.07px 40.27px 47.06px 40.28px;
    }

    @include breakpoint(mobile) {
      height: 286px;
      width: 286px;
      margin: 63px 45px 51px 44px;
    }
  }

  &__stack {
    margin-bottom: 14px;

    @include breakpoint(tab-max) {
      height: 353px;
      border-radius: 1px;
      background-color: $white-cap;
      position: relative;
    }

    @include breakpoint(mobile) {
      max-width: 100%;
      border-radius: 1px;
      background-color: $white-cap;
      height: 390px;
    }
  }

  &__thumb {
    @include breakpoint(tab) {
      height: 75px;
      width: 100%;
      padding-left: 0;
      padding-top: 0;
    }

    @include breakpoint(mobile) {
      width: 100%;
      height: 75px;
      padding-left: 0;
      padding-top: 0;
    }
  }

  &__image-thumbSelected {
    height: 61px;
    width: 61px;
    border: 1.5px solid $text-dark-grey;
    border-radius: 1px;
    padding: 0;

    @include breakpoint(tab) {
      height: 49.7px;
      width: 49.7px;
      border: 1.5px solid $text-dark-grey;
      border-radius: 1px;
      padding: 0;
    }

    @include breakpoint(mobile) {
      height: 49.7px;
      width: 49.7px;
      border: 1.5px solid $text-dark-grey;
      border-radius: 1px;
      padding: 0;
    }
  }

  &__image-thumbSelected > img {
    height: 100%;
    width: 100%;
  }

  &__image-thumb-list {
    @include breakpoint(tab) {
      padding-right: 11px;
    }

    @include breakpoint(mobile) {
      padding-right: 11px;
    }
  }

  &__image-thumb {
    height: 61px;
    width: 61px;
    border: 1px solid $color-extra-light-gray;
    border-radius: 1px;

    @include breakpoint(tab) {
      height: 49.2px;
      width: 49.2px;
      border: 1px solid $color-extra-light-gray;
      border-radius: 1px;
    }

    @include breakpoint(mobile) {
      height: 49.2px;
      width: 49.2px;
      border: 1px solid $color-extra-light-gray;
      border-radius: 1px;
    }
  }

  &__image-thumb-view {
    height: 61px;
    overflow-y: hidden;
    @include breakpoint(tab-max) {
      max-height: 75px;
      height: 75px;
      align-items: unset;
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: $charcoal;
        border-radius: unset;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px $cool-dark-grey;
        background: $cool-dark-grey;
        border-radius: unset;
      }
    }
    @include breakpoint(tab) {
      margin-bottom: 15px;
      &::-webkit-scrollbar-button {
        width: 100%;
      }
    }

    @include breakpoint(mobile) {
      padding-left: 0;
    }
  }

  &__link-more {
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 15px;
    display: inline-block;
    width: 62px;
  }
}

.product-details__overview-block {
  @include breakpoint(tab) {
    padding-bottom: 48px;
  }
}
.asproductoverview {
  .page-container {
    .product-details {
      .modal-popup {
        .modal-popup-content {
          padding: 0;
          .close-button-holder {
            .close-button {
              top: 15px;
              @include breakpoint(desktop-small) {
                right: 51px;
              }
              @include breakpoint(tab) {
                right: 32px;
              }
              @include breakpoint(mobile) {
                right: 18px;
              }
              img {
                width: 21px;
                height: 21px;
              }
            }
          }
          .modal-popup-body {
            background: $cool-light-grey-caps;
            @include breakpoint(desktop-small) {
              padding: 100px 0 210px 50px;
            }
            @include breakpoint(tab) {
              padding: 125px 0 178px;
            }
            @include breakpoint(mobile) {
              padding: 125px 0 188px;
            }
            @include breakpoint(desktop-large) {
              max-height: 100%;
              height: 1029px;
            }
            .image-gallery-container {
              .image-gallery {
                margin: 0;
                .image-gallery__stack {
                  height: 77vh;
                  @include breakpoint(desktop-small) {
                    height: 100%;
                    margin-bottom: 60px;
                  }
                  .image-gallery__image-stack-view {
                    margin: 0;
                    padding-bottom: 143px !important;
                    @include breakpoint(desktop-large) {
                      padding-bottom: 141px !important;
                    }
                    height: auto;
                    @include breakpoint(desktop-small) {
                      &::-webkit-scrollbar-button {
                        width: 25px;
                      }
                      &::-webkit-scrollbar-track {
                        margin-left: -50px;
                      }
                    }
                    .image-gallery__image-stack-list {
                      padding: 0;
                      margin: 0 30px 0 0;
                      @include breakpoint(desktop-small) {
                        width: 960px;
                        height: 719px;
                      }
                      @include breakpoint(tab) {
                        width: 100%;
                        height: 100%;
                        flex: 0 0 100%;
                        padding: 62px 0;
                      }
                      @include breakpoint(mobile) {
                        width: 375px;
                        height: 500px;
                      }
                    }
                    .image-gallery__image-stack-list > img {
                      @include breakpoint(desktop-small) {
                        width: 960px;
                        height: 719px;
                      }
                      @include breakpoint(tab) {
                        width: 380px;
                        height: 380px;
                      }
                      @include breakpoint(mobile) {
                        width: 375px;
                        height: 500px;
                      }
                    }
                    @include breakpoint(desktop-small) {
                      padding-bottom: 210px;
                    }
                  }
                }
                .image-reel__photo-credit {
                  padding: 0;
                  position: absolute;
                  @include breakpoint(desktop-small) {
                    top: 819px;
                    margin-bottom: 20px;
                    font-size: 14px;
                    color: $text-medium-dark-grey;
                    line-height: 20px;
                    font-style: normal;
                  }
                  @include breakpoint(desktop-large) {
                    top: 819px;
                    font-size: 14px;
                    color: $text-medium-dark-grey;
                    line-height: 20px;
                    font-style: normal;
                  }
                }
                .image-gallery__thumb {
                  padding: 0;
                  position: absolute;
                  @include breakpoint(desktop-small) {
                    top: 878px;
                  }
                  @include breakpoint(desktop-large) {
                    top: 878px;
                  }
                  @include breakpoint(tab) {
                    top: 627px;
                    padding-left: 32px;
                    width: 100%;
                  }
                  @include breakpoint(mobile) {
                    top: 625px;
                    padding-left: 18px;
                  }
                  .image-gallery__image-thumb-view {
                    padding: 0;
                    margin: 0 0 30px 0;
                    height: 66px;
                    @include breakpoint(desktop-large) {
                      margin: 0 0 25px 0;
                    }
                    .image-gallery__image-thumb-list {
                      margin-right: 14px;
                      width: 66px;
                      height: 66px;
                      padding: 0;
                      .image-gallery__image-thumbSelected,
                      .image-gallery__image-thumb {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &__image-gallery {
        .modal-popup {
          .modal-popup-content {
            padding-top: 0 !important;
          }
        }
      }
      .product-info .product-info-block {
        padding-bottom: 0;
      }
      .add-to-cart {
        @include breakpoint(mobile) {
          padding: 0;
        }
        .out-of-stock-container {
          @include breakpoint(mobile) {
            margin: 0 0 55px;
          }
          .out-of-stock-success-popup {
            .modal-popup {
              .modal-popup-content {
                max-width: 540px;
                padding: 0;
                min-height: 400px;
                width: 100% !important;
                background: $color-light-grey;
                @include breakpoint(tab-max) {
                  max-width: 338px;
                  min-height: 276px !important;
                  max-height: 276px;
                }
                .modal-popup-body {
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: flex-start;
                  height: 100%;
                  width: 100%;
                  background: $cool-light-grey;
                  @include breakpoint(tab-max) {
                    align-items: center;
                  }
                  .success-popup__title {
                    padding: 120px 0 0;
                    @include breakpoint(tab-max) {
                      padding: 50px 0 0;
                      font-size: 18px;
                      line-height: 28px;
                    }
                  }
                  .success-popup__message {
                    max-width: 332px;
                    @include breakpoint(tab-max) {
                      padding: 25px 0 0;
                      margin: 0 40px;
                      text-align: left;
                    }
                  }
                  .success-popup__done-cta {
                    padding: 70px 0 0;
                    @include breakpoint(tab-max) {
                      max-width: 338px;
                      min-height: 276px;
                      max-height: inherit;
                    }
                  }
                  .close-button-holder {
                    position: absolute;
                    right: 30px;
                    max-width: 40px;
                    min-height: 40px;
                    top: 30px;
                    width: 100%;
                    @include breakpoint(tab-max) {
                      right: 10px;
                      top: 10px;
                    }
                    .close-button {
                      right: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      img {
                        width: 40px;
                        height: 40px;
                        right: 0;
                        left: 0;
                      }
                    }
                  }
                }
              }
            }
            .out-of-stock-description {
              color: $text-light-grey;
            }
          }
        }
      }
    }
  }
}

.manualPdpSummary {
  .product-img-block .image-gallery__image-stack-list > img {
    @include breakpoint(mobile) {
      margin-left: 45px;
    }
  }
  .product-summary {
    .kf-react-button {
      font-family: $lato-bold;
      color: $color-medium-blue-cap!important;
      padding: 0;
      @include text-style(11px!important, 10px!important, 2.5px!important);
      @include breakpoint(mobile) {
        width: 339px;
      }
      .kf-react-button.ghost{
        border: none;
      }
    }
    &__desc{
      .product-summary__desc{
        h1,h2,h3,h4,h5,h6,p {
          max-width: 100%;
          color: $text-dark-grey;
          font-family: $playfair-regular;
          @include text-style(36px, 44px, -2px);
          margin-bottom: 30px;
          @include breakpoint(mobile) {
            max-width: 269px;
            margin-bottom: 35px;
            @include text-style(28px, 34px, -1.17px);
            font-size: 28px;
            letter-spacing: -1.17px;
            line-height: 34px;
          }
      }
    }
    }
    &__details,
    &__details h1,h2,h3,h4,h5,h6,p {
      font-family: $lato-regular;
      max-width: 480px;
      width: 100%;
      color: $text-medium-dark-grey;
      @include text-style(16px, 24px, 0);
      margin-bottom: 29px;
      margin-top: 30px;
      @include breakpoint(mobile) {
        width: 339px;
        margin-bottom: 25px;
        @include text-style(16px, 24px, 0px);
      }
    }
  }
  .page-container {
    width: 100% !important;
  }
  @include breakpoint(mobile) {
    .product-info {
      .product-info-block {
        max-width: 100%;
        padding: 0 18px;
      }

      .image-reel__image-stack-view-block {
        .image-reel__stack-list-image {
          max-width: 286px;
          min-height: 286px;
          height: 100%;
          width: 100%;
          margin: 0 auto;
        }

        .image-reel__image-stack-list {
          max-width: calc(14.26256vw + 321.5154px);
          height: calc(14.91086vw + 334.08428px);
          text-align: center;
        }
      }
    }
  }

  @include breakpoint(mobile) {
    .product-details {
      .kf-react-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .image-gallery {
          &__thumb {
            padding: 0 1.125rem;
          }
          &__image-stack-view {
            width: 375px;
            height: 390px;
          }
          &__image-stack-list > img {
            width: 286px;
            height: 286px;
          }
        }
      }

      .image-reel__image-thumb-view {
        width: 100%;
        max-width: 100%;
        padding: 0 18px;
      }
      &__content-section {
        padding: 0 1.125rem 2.5rem !important;
      }
    }
  }
}
.manualPdpTabs {
  .tabContent {
    p {
      margin-bottom: 20px;
      color: $text-dark-grey;
      font-family: $lato-regular;
      @include text-style(22px, 32px, 0);
      &:nth-child(2) {
        padding-top: 20px;
        @include text-style(14px, 20px, 0);
        color: $text-medium-dark-grey;
      }
      &:last-child {
        margin-bottom: 0;
        @include text-style(14px, 20px, 0);
        color: $text-medium-dark-grey;
      }
    }
    a {
      color: $text-medium-dark-grey-small;
      font-family: $lato-regular;
      @include text-style(16px, 24px, 0);
      text-decoration: underline;
      display: flex;
      align-items: center;
      column-gap: 10px;
      &::before {
        content: "";
        width: 15px;
        height: 15px;
        background: url("../images/External.svg") 0 1px no-repeat;
        background-size: 100%;
      }
    }
    ul {
      padding-left: 18px;
      @include breakpoint(mobile) {
        margin-left: 0;
      }
    }
  }
  .asproducttabdetail {
    .page-container {
      width: 100% !important;
    }
  }
}
.scroll-lock.modal-open .cmp-container {
  @include breakpoint(tab-max) {
    z-index: auto !important;
  }
}
.manualPdpSummary {
  .product-info .product-img-block {
    @include breakpoint(tab) {
      max-width: 340px;
    }
  }
}
