.blog-listing {
  display: block;
  padding-bottom: 3.125rem;

  &.is-blog-loading {
    opacity: 0.4;
    pointer-events: none;
  }
  .blog-listing-inner-content {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    @include breakpoint(xl){
      margin: 0;
    }
    @include breakpoint(lg){
      padding: 50px 32px 0;
    }
    .article-card-wrapper {
      flex: 0 0 25%;
      max-width: 25%;
      margin-bottom: 50px;
      padding-right: 2.8125rem;
        @include breakpoint(tab){
          padding-bottom: 50px;
        }
    }
  }
  &-load-more {
    display: grid;
    width: 100%;
    padding: 15px;
    text-transform: uppercase;
    align-content: center;
    font-family: $lato-bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 2.5px;
    text-align: center;
    justify-content: center;
    color: $color-medium-blue;
    border: 1px solid $color-medium-blue;
    border-radius: 1px;
    background-color: transparent;
    &:hover{
      color: $color-light-blue; 
      transform: scale(.95);
    }
    .blog-listing-load-more{
      &.kf-react-button.secondary{
        color: $color-medium-blue;
        height: 100%;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        font-family: $lato-regular;
        margin: 0;
        padding: 0;
      }
    }
  }
  &-pagination-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    p {
      margin: 0;
    }
  }
  &-pagination {
    color: $text-dark-grey;
    font-family: $lato-regular;
    font-size: 10px;
    letter-spacing: 1.7px;
    line-height: 1pc;
    div {
      align-items: start;
    }

  }
  &-bottom-content{
    @include breakpoint(lg){
      padding: 0 2rem;
    }
    @include breakpoint(mobile){
      padding: 0 1.125rem;
    }
    @media (min-width: 1025px) and (max-width: 1308px) {
      padding: 50px 32px 0;
    }
  }
}

@include breakpoint(xl) {
  .blog-listing {
    .blog-listing-inner-content {
      padding: 4.375rem 3.125rem 0;
      justify-content: space-between;
      .article-card-wrapper {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding-right: 2.7813rem;
      }
    }
    &-pagination-container {
      margin-top: 2rem;
    }
    &-bottom-content{
      padding: 0 50px;
    }
    &-load-more {
      height: 100%;
      margin: 0;
      .blog-listing-load-more{
        color: $black;
      }
    }
  }
}

@include breakpoint(desktop-large) {
  .blog-listing {
    .blog-listing-inner-content {
        margin: 0 auto;
        max-width: 1380px;
        padding-top: 4.375rem;
        gap: 0 30px;
      .article-card-wrapper {
        flex: 0 0 440px;
        max-width: 440px;
        padding: 0;
        margin-bottom: 140px;
        &:nth-last-child(-n + 3){
          margin-bottom: 0;
          padding-bottom: 70px;
        }
      }
    }
    &-pagination-container {
      margin-top: 1.875rem;
    }
    &-bottom-content{
      margin: 0 auto;
      max-width: 1380px;
      @include breakpoint(desktop-large){
        padding-top: 50px;
      }
    }
    &-load-more {
      height: 100%;
      margin: 0;
      padding: 0;
      min-height: 40px;
    }
  }
}

@include breakpoint(xl) {
  .blog-listing {
    padding-bottom: 50px;
    .blog-listing-inner-content {
      justify-content: center;
      gap: 0 30px;
      .article-card-wrapper {
        flex: 0 0 426px;
        max-width: 426px;
        padding: 0;
        margin-bottom: 140px;
        &:nth-last-child(-n + 3){
          margin-bottom: 120px;
        }
      }
    }
    &-pagination-container {
      margin-top: 1.875rem;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .blog-listing {
    padding-bottom: 50px;
    .blog-listing-inner-content {
      justify-content: space-between;
      gap: 0 20px;
      .article-card-wrapper {
        flex: 0 0 306px;
        max-width: 306px;
        padding: 0;
        margin-bottom: 100px;
        &:nth-last-child(-n + 3){
          margin-bottom: 100px;
        }
      }
    }
    &-pagination-container {
      margin-top: 30px;
    }
  }
}

@include breakpoint(tab-max) {
  .blog-listing {
    padding-bottom: 50px;
    justify-content: space-between;
    .blog-listing-inner-content {
      gap: 0 20px;
      .article-card-wrapper {
        flex: 0 0 364px;
        max-width: 364px;
        padding: 0;
        margin-bottom: 100px;
      }
    }
    &-load-more {
      font-size: 0.875rem;
      margin: 0;
    }
    &-pagination-container {
      justify-content: center;
      margin-top: 30px;
      @include breakpoint(tab-max){
        justify-content: flex-end;
      }
    }
  }
}

@include breakpoint(xs) {
  .blog-listing {
    .blog-listing-inner-content {
      padding: 50px 1.125rem 0;
      .article-card-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 50px;
        padding: 0;
      }
    }
    &-load-more {
      margin-bottom: 1.5rem;
    }
    &-pagination-container {
      margin-top: 1.875rem;
    }
  }
}

.breadcrumb + .teaser.teaser-card-style{
  margin-top: 0;
}