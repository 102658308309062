.cart-details {
  &__wrapper {
    margin-bottom: 20px;
  }

  &__thead {
    display: none;
    clear: both;

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    &__data {
      width: 16.66667%;
      float: left;
      margin-bottom: 20px;

      &:first-child {
        width: 41.66667%;
      }

      &:last-child {
        width: 8.33333%;
      }
    }
  }

  &__tbody {
    width: 100%;

    &__data {
      &--qnty {
        margin-top: 10px;
      }

      &--cart-summary {
        display: none;
      }

      &--cta-wrapper {
        display: none;
      }
    }
  }

  &__img-container {
    width: 100%;
    margin-bottom: 20px;
  }

  &__item-img {
    width: 100%;
    max-width: 100%;
  }

  &__product-name {
    margin-bottom: 10px;
  }

  &__product-actions, &__confirm-total {
    display: none;
  }

  &__qnty-wrapper {
    position: relative;
    width: 100%;
    margin-top: 7px;
  }

  &__mobile-counter {
    display: inline-block;
    width: 32%;
    height: 40px;
    background-color: #ccc;
    border-radius: 4px;
    border: 0;
    position: absolute;

    &--plus {
      right: 0;
      top: 0;
    }
  }

  &__qnty {
    width: 40px;
    display: block;
    margin: 0 auto 10px;
    text-align: center;
    border-radius: 4px;
    background: #fff;
    font-size: 12px;
    height: 40px;
    border: solid 1px #ddd;
    -webkit-appearance: none;
  }

  &__qnty-update {
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 5px;
    border: none;
    background: none;
    outline: none;
  }

  &__bth-remove {
    background-color: #364575;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border: none;
    box-shadow: none;
    width: 100%;
    border-radius: 4px;
    margin-top: 30px;
  }

  &__cta-view-cart {
    background-color: #364575;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    width: 100%;
    display: block;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__cta-shopping {
    background-color: #dbdbdb;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    width: 100%;
    display: block;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 20px;
    &--empty-cart{
      &:hover{
        background-color: #bebebe;
      }
    }
  }

  &__check-out-view {
    display: none;
  }

  &__show-for-check-out {
    display: none;
  }

  &__data {
    padding: 20px 0;
  }
}

.check-out__view {
  .cart-details {
    &__data {
      border-bottom: 1.5px solid #e5e5e5;
    }
  }
}

@media (min-width: 768px), print {
  .shopping-cart__view {
    .cart-details {
      &__thead {
        display: block;
        border-bottom: 1px solid #e5e5e5;

        &__data {
          &--qnty {
            padding-left: 50px;
          }
        }
      }

      &__tbody {
        &__data {
          width: 16.66667%;
          float: left;

          &:first-child {
            width: 41.66667%;
          }

          &--total {
            width: 8.33333%;
          }

          &:not(:first-child) {
            margin-top: 15px;
          }

          &--qnty {
            padding-left: 50px;
          }
        }

        &-label {
          display: none;

          &--color {
            display: inline;
          }
        }
      }

      &__summary-wrapper {
        display: flex;
      }

      &__img-container {
        margin-right: 15px;
        width: 110px;
      }

      &__mobile-counter, &__qnty-label {
        display: none;
      }

      &__qnty {
        margin: 0;
      }

      &__qnty-wrapper {
        margin: 0;
      }

      &__qnty-update {
        text-align: left;
      }

      &__product-actions {
        display: block;
      }

      &__btn-wrapper {
        display: none;
      }

      &__data {
        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
  }
}
