.full-width-image-feature {
    padding: 0;
    min-height: 43.75rem;
    @include breakpoint(tab-max){
        min-height: 100%;
    }
    .cmp-teaser {
        &__content {
            position: absolute;
            max-width: 1920px;
            margin: 0 auto;
            width: 100%;
            z-index: 10;
            background: transparent;
            padding: 0 2.6%;
            @include breakpoint(desktop-large){
                padding: 0 50px;
            }
            @include breakpoint(desktop-lg){
                max-width: 1380px;
                padding: 0;
            }
            @include breakpoint(desktop-mid){
                padding: 0 36px;
            }
            @include breakpoint(tab-max){
                position: relative;
                max-width:100%;
            }
            @include breakpoint(tab) {
                padding: 50px 32px;
            }
            @include breakpoint(mobile) {
                padding: 50px 18px;
            }            
        }
        &__pretitle {
            @include ann-describtion(.75rem, $lato-regular, 1rem);
            letter-spacing: .125rem;
            padding: 0 0 .625rem;
            @include breakpoint(tab-max) {
                color: $text-dark-grey;
            }
            h1, h2, h3, h4, h5, h6, p{
                @include ann-describtion(.75rem, $lato-regular, 1rem);
                letter-spacing: .125rem;
                @include breakpoint(tab-max) {
                    color: $text-dark-grey;
                    padding: 0;
                }  
            }          
        }            
        &__title { 
            h1, h2, h3, h4, h5, h6, p {
                @include ann-describtion(2.625rem, $playfair-regular, 3.375rem);
                letter-spacing: -0.073125rem;
                padding: 0;
                margin: 0;
                max-width: 33.9375rem;
                @include breakpoint(tab-max) {
                    @include ann-describtion(2.25rem, $playfair-regular, 2.75rem);
                    letter-spacing: -.125rem;
                    color: $text-dark-grey!important;
                    max-width: 100%;
                    width: 63.9%;
                } 
                @include breakpoint(mobile) {
                    width: 100%;
                }
            }
        }            
        &__description {
            margin: 0;
            padding: 0;
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                letter-spacing: 0;
                max-width: 21.625rem;
                padding: .9375rem 0 0 0;
                margin: 0;
                @include breakpoint(tab-max) { 
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    max-width: 100%;
                    width: 63.50%;                      
                    color: $text-light-grey !important;
                }
                @include breakpoint(mobile) {
                    width: 100%;
                    color: $text-medium-dark-grey;
                }
            }
        }
        &__action-container{
            .cmp-teaser__action-link {
                padding: 0 0 7px 0;
                margin: 1.5625rem 0 0 0;
                line-height: 1rem;
                letter-spacing: 1.83px;
                @include breakpoint(tab-max) {
                    color: $color-light-blue !important;
                    border-bottom: 1px solid $color-light-blue;
                }
                &.gbh-secondary-button {
                    @include ann-describtion(.75rem, $lato-bold, 1.5rem);
                    margin: 1.875rem 0 0 0;
                    padding: 0.75rem 1.6875rem .75rem;
                    background: none;
                    letter-spacing: .1563rem;
                    text-transform: uppercase;
                    @include breakpoint(tab-max){
                        color: $color-medium-blue-cap;
                        border-color: $color-medium-blue-cap;
                        background: transparent;
                    }
                }
            }
        }
        &__image{
            .cmp-image{
                @include breakpoint(mobile){
                    zoom: 1.0009;
                }
            }
        }
    }
    &.fc-white{
        .cmp-teaser{
            &__action-link{
                @include breakpoint(tab-max){
                    color:$color-light-blue;
                    border-bottom: 1px solid $color-light-blue !important;
                }
                &.gbh-secondary-button{
                    @include breakpoint(desktop-small){
                        color: $white;
                        border:1px solid $white;
                    }
                    @include breakpoint(tab-max){
                        color: $color-medium-blue-cap!important;
                        border-color: $color-medium-blue-cap !important;
                    }
                }
            }
        }
    }
    &.fc-stone{
        .cmp-teaser{
            &__action-link{
                @include breakpoint(tab-max){
                    color:$color-light-blue;
                    border-bottom: 1px solid $color-light-blue;
                }
                &.gbh-secondary-button{
                    @include breakpoint(desktop-small){
                        color: $text-dark-grey;
                        border:1px solid $text-dark-grey;
                    }
                    @include breakpoint(tab-max){
                        color: $color-medium-blue-cap;
                        border-color: $color-medium-blue-cap;
                    }
                }
            }
        }
    }
    &.content-bottom-left{
        .cmp-teaser{
            &__content{
                bottom: 70px;
                left: 0;
                right: 0;               
                @include breakpoint(tab-max) {
                    left: unset;
                    bottom: unset;                    
                }
            }
        }        
    }
    &.content-top-right{
        .cmp-teaser {
            &__content {
                right: 0;
                left: 0;
                top: 70px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-content: flex-end;
                align-items: flex-start;
                justify-content: flex-start;
                @include breakpoint(tab-max) {
                    right: unset;
                    top: unset;
                    left: unset;
                    display: block;
                    flex-wrap: unset;
                    align-content: unset;
                    flex-direction: unset;
                    align-items: unset;
                    justify-content: unset;                    
               }         
            }
        }        
    }
    &.content-bottom-right{
        .cmp-teaser {
            &__content {
                right: 0;
                left: 0;
                bottom: 70px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-content: flex-end;
                align-items: flex-start;
                justify-content: flex-start;             
                @include breakpoint(tab-max) {
                    right: unset;
                    bottom: unset;
                    left: unset;
                    display: block;
                    flex-wrap: unset;
                    align-content: unset;
                    flex-direction: unset;
                    align-items: unset;
                    justify-content: unset;                     
               }         
            }
        }         
    }
} 

