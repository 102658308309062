.find-store-landing {
  padding-left: 20px;
  @include breakpoint(desktop-large) {
    padding-left: 245px;
  }
  .react-bingmaps {
    z-index: 0;
    height: calc(100% - 110px);
  }

  &__breadcrumb {
    font-family: $helvetica-regular;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: $color-dark-gray;
    margin-top: 30px;
    margin-bottom: 40px;
    display: inline-block;
    .cmp-breadcrumb {
      &__list {
        font-size: 14px;
      }
      &__item {
        padding: 0px;
        &:last-child .cmp-breadcrumb__item-link:after {
          content: "";
        }
      }
      .cmp-breadcrumb {
        &__item-link {
          &:after {
            content: "/";
            margin: 0 2px;
          }
        }
      }
    }
    &-link {
      cursor: pointer;
      font-family: $helvetica-regular;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: $color-dark-gray;
      text-decoration: none;
    }
  }
  &__search {
    position: relative;
  }
  &__search-container {
    .kf-react-button {
      &.ghost {
        &:hover {
          color: $slate;
        }
      }
    }
  }
  &__use-location {
    font-family: $lato-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    color: $color-dark-gray;
    text-decoration: underline;
    margin-top: 15px;
    margin-bottom: 65px;
    display: inline-block;
    cursor: pointer;
  }

  &__tabs {
    display: flex;
    justify-content: center;
    border-bottom: 0.5px solid $color-dark-gray;
  }

  &__tab {
    font-family: $helvetica-medium;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.4px;
    text-align: center;
    color: $color-dark-gray;
    padding: 0px 30px 15px;
    cursor: pointer;
    border: none;
    background-color: transparent;

    &--active {
      color: $color-charcoal;
      border-bottom: 4px solid $color-charcoal;
      cursor: default;
      margin-bottom: 0px;
    }
  }
  &__list-mb {
    margin-bottom: 110px !important;
  }
  &__list {
    height: 490px;
    overflow-y: auto;
    border-bottom: 1px solid $color-dark-gray;
    margin-bottom: 50px;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: $color-extra-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-dark-gray;
    }
    &::-webkit-scrollbar-button {
      width: 10px;
    }
  }

  &__result-container {
    transition: all 0.5s ease-in-out;
    margin-left: 290px;
    margin-bottom: 95px;
    .kf-react-button.large {
      min-width: 265px;
    }
  }

  &__result-full {
    margin-left: 0;
  }

  &__message {
    font-family: $helvetica-light;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1.7px;
    color: $slate;
  }
  &__store-message {
    margin-top: 65px;
  }

  .find-store-landing__map {
    position: relative;
    margin-top: -94px;
    padding: 0px 0px 0px 31px;
  }
  &__search-full-container {
    padding-top: 97px;
  }

  &__filter {
    width: 260px;
    height: 100%;
    position: absolute;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    padding-top: 9px;
    .filter__chips {
      border-bottom: 1px solid $color-dark-gray;
    }

    &--hide {
      opacity: 0;
    }
  }

  &__location-note {
    padding-top: 45px;
    display: flex;
    justify-content: center;
    span {
      margin-right: 30px;
      display: inline-block;
      font-family: $helvetica-regular;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.55px;
      color: $text-medium-dark-grey;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 15px;
        height: 21px;
        margin-right: 5px;
      }
    }
  }
  .store-list {
    padding: 29px 20px;
    border-bottom: 1px solid $color-dark-gray;
    @include breakpoint(desktop-extra-lite-lg) {
      padding: 30px 20px;
    }

    &:last-child {
      border-bottom: none;
    }

    &__set-store-time {
      display: none;
      margin-top: 15px;
      text-align: right;
      font-family: $helvetica-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }

    &--active {
      background-color: $half-white;
      border-bottom: none;
    }

    &__name {
      font-family: $helvetica-medium;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $color-dark-gray;
      text-decoration: underline;
    }
    &__genuine-dealers {
      text-decoration: none !important;
    }

    &__name-col {
      margin-right: 30px;
    }

    &__address-col {
      margin-right: 20px;
    }

    &__locality,
    &__address {
      font-family: $lato-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
    }
    &__address {
      a {
        font-family: $lato-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
      }
    }

    &__phone {
      font-family: $lato-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      margin-top: 10px;
      color: $color-dark-gray;
      text-decoration: none;
    }

    &__distance-col,
    &__address-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: $lato-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }

    &__distance-col {
      text-align: left;
    }

    &__address {
      span {
        display: block;
      }
    }

    &__set-store {
      margin-top: 15px;
      display: inline-block;

      .checkbox {
        margin-bottom: 0;
      }
    }
  }

  .find-store-landing__store-card {
    display: block;
    &.--ml {
      display: none;
    }
    @media only screen and (min-width: 540px) and (max-width: 1024px) and (orientation: landscape) {
      display: none;
      position: relative;
      &.--ml {
        display: block;
        .store-card {
          position: static;
          display: block;
          margin-top: 0;
          width: 100%;
          .store-card__details {
            padding: 20px 35px 15px;
          }
          .book-appointment {
            display: none;
            &.--mobile {
              display: block;
              position: static;
              .button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .store-card {
    position: absolute;
    z-index: 99;
    bottom: 110px;
    background: $color-charcoal;
    color: $color-white;
    display: grid;
    grid-template-columns: 2fr 1fr;
    right: 0;
    width: 97%;
    left: 30px;
    padding: 0;

    &.showroom {
      background: $color-dark-gray;
      color: $color-white;

      address > p,
      .store-card-phone > a {
        color: $color-white;
      }

      p,
      .distance {
        color: $color-white;
      }
    }

    &__details {
      display: grid;
      padding: 25px;
      grid-template-columns: 2fr 1fr;
    }

    &__time {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 15px;
    }

    &__name {
      p {
        font-size: 26px;
        line-height: 1.31;
        letter-spacing: -1.55px;
        color: $color-white;
        font-family: $helvetica-light;
        margin-bottom: 0;
      }
      span {
        font-family: $helvetica-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-white;
      }
    }

    address {
      margin-top: 15px;
    }

    address > p,
    .store-card-phone > a {
      font-family: $helvetica-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-light-gray;
      margin-bottom: 0;
    }

    .book-appointment {
      display: none;
      &.--mobile {
        display: block;
        position: static;
        .button {
          width: 100%;
        }
      }
    }

    &__open {
      p,
      .distance {
        font-family: $helvetica-regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.32px;
        color: $color-light-gray;
      }

      .store-card__checkbox {
        .checkbox input ~ span,
        .checkbox input:checked ~ span {
          color: $color-white;
        }
      }

      p {
        margin-bottom: 0;
      }
      .distance {
        margin-bottom: 5px;
      }
      .checkbox {
        margin-top: 10px;
        input ~ span {
          color: $color-light-gray;
          font-family: $helvetica-regular;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.32px;
          border-radius: 5px!important;
        }

        input:checked ~ span {
          color: $color-light-gray;
        }

        input ~ .checkbox__border .checkbox__marker {
          .checkbox__marker-icon {
            display: none;
          }
        }
        input:checked ~ .checkbox__border .checkbox__marker {
          background: $black;
          border: none;
          .checkbox__marker-icon {
            display: block;
          }
        }
      }
    }

    &__image {
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &__view-details {
      font-family: $helvetica-medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-white;
      text-decoration: underline;
      padding-right: 42px;
    }
  }
  &__message,
  &__search-input,
  &__use-location,
  &__tab,
  .button,
  &__location-note {
    font-family: $lato-regular;
  }
  .offset-lg-1 {
    padding-left: 0;
    margin-left: 110px;
  }
  &__message {
    color: $text-light-grey;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }
  &__search-input {
    color: $text-medium-dark-grey;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
    padding: 0 49px 20px 0;
  }
  &__use-location {
    color: $text-medium-dark-grey;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 60px;
  }
  &__tabs {
    padding-left: 10px;
    border-bottom: 1px solid $cool-dark-grey;
    margin-bottom: 0;
  }
  &__tab {
    color: $text-light-grey;
    font-size: 14px;
    letter-spacing: 2.33px;
    line-height: 16px;
    text-align: center;
    padding: 0 20px 22px 20px;
    text-transform: uppercase;
    &--active {
      color: $text-dark-grey;
      border-bottom: 2px solid $color-light-blue;
      line-height: 17px;
    }
  }
  &__list {
    height: 540px;
    border-bottom: 1px solid $cool-dark-grey;
    margin-bottom: 63px;
  }
  &__map {
    margin-top: -76px !important;
    @include breakpoint(desktop-extra-sm){
      margin-top: -45px !important;
    }
  }
  .store-list {
    margin-right: 0px;
    &__name,
    &__address,
    &__phone,
    &__distance,
    &__time,
    &__distance-col,
    &__address-col,
    &__set-store-time,
    &__set-store {
      font-family: $lato-regular;
    }
    &__name {
      color: $text-medium-dark-grey;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
    }
    &__address {
      a span {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      }
    }
    &__phone {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: 15px;
    }
    &__distance,
    &__time {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: right;
    }
    &__time {
      margin-top: 10px;
    }
    &__distance-col,
    &__address-col {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__name-col {
      padding: 0 30px 0 20px;
    }
    &__address-col {
      padding: 3px 20px 0 0;
    }
    &__distance-col {
      padding: 3px 20px 0 0;
      display: block;
    }
    &__set-store-time {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: right;
    }
    &__address {
      a {
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: $text-medium-dark-grey;
      }
    }
    &__set-store {
      margin-top: 11px;
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      .checkbox {
        margin-bottom: 0;
        input:checked ~ .checkbox__marker {
          border: 1px solid $color-light-blue;
          background-color: $color-light-blue;
        }
      }
      .checkbox input ~ span {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px!important;
        border-radius: 5px !important;
        margin-top: 1px;
      }
    }
    .row {
      margin: 0;
    }
  }
  &__result-container {
    .kf-react-button.large {
      min-width: max-content;
      height: 24px;
    }
    .find-store-browseall-div {
      .find-store-browseall-link.button {
        color: $color-light-blue;
        border: none;
        font-size: 11px;
        letter-spacing: 1.83px;
        border-bottom: 1px solid $color-light-blue;
        line-height: 24px;
        padding: 0 0 5px;
        height: 24px;
        font-family: $lato-regular;
        text-transform: uppercase;

        &:hover {
          background: none;
        }
      }
    }
    input:checked ~ span .checkbox__marker {
      left: -1px;
      border-radius: 0;
    }
    .checkbox__border {
      height: 16px;
      width: 16px;
      border-radius: 0;
      margin-top: 0;
    }
  }

  .modal-popup-body .button {
    height: 60px;
    color: $white;
    min-width: auto;
    font-size: 11px !important;
    letter-spacing: 1.83px !important;
    line-height: 24px !important;
    border: none;
    border-bottom: 1px solid $color-light-blue;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 0 5px;
    margin-bottom: 11px;
    font-family: $lato-regular;
  }
  .modal-popup-body .button:hover {
    background-color: $color-medium-blue;
  }
  .find-store-browseall-link a {
    min-width: auto;
    line-height: 24px;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 0 5px;
    margin-bottom: 11px;
  }

  .find-store-browseall-div div.find-store-browseall-link {
    height: 24px;
    color: $color-light-blue;
  }
  .find-store-browseall-div div.find-store-browseall-link:hover {
    background-color: $color-white;
  }

  &__location-note {
    margin: 48px 0 55px;
    padding: 0 0 0 30px;
    color: $text-medium-dark-grey;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    span {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 15px;
        height: 21px;
        margin-right: 10px;
      }
    }
  }
  &__filter-panel {
    @include breakpoint(tab) {
      height: 100%;
      margin-top: 48px;
    }
    @include breakpoint(mobile) {
      height: 100%;
      margin-top: 48px;
    }
    @media (min-width: 275px) and (max-width: 1200px) {
      .product-list__filter-toggle {
        border: 0;
        background: 0;
      }
    }
    .Collapsible {
      border-bottom: 1px solid $cool-dark-grey;
      &__trigger,
      &__contentInner .checkbox {
        font-family: $lato-regular;
      }
      .plus {
        width: 8.49px !important;
        height: 8.49px !important;
      }
      .line-1 {
        padding-right: 10px;
      }
      .line-2 {
        padding-left: 1px !important;
        margin-left: 4.5px !important;
      }
      &__trigger {
        color: $text-dark-grey;
        font-size: 12px !important;
        letter-spacing: 2px !important;
        line-height: 16px !important;
        padding: 19.61px 0 25.39px 0 !important;
        text-transform: uppercase;
        @media (min-width: 275px) and (max-width: 1200px) {
          padding: 20px 0 !important;
        }
      }
      &__contentInner input:checked ~ .checkbox__marker {
        background: $color-light-blue;
      }
      &__contentInner .checkbox__marker,
      &__contentInner .checkbox__border {
        width: 16px !important;
        height: 16px !important;
        margin-right: 10px;
        border-radius: unset !important;
      }
      &__contentInner .checkbox {
        color: $text-medium-dark-grey;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 15px;
        margin-bottom: 15px;
        padding-bottom: 0 !important;
      }
      &__contentInner .checkbox .checkbox_default {
        color: $text-medium-dark-grey;
      }
      &__contentInner .checkbox input:checked ~ span .checkbox__marker {
        border: 1px solid $color-light-blue;
        background-color: $color-light-blue;
      }
    }
    .filter {
      &__chips-tag,
      &__chips-clear-all {
        font-family: $lato-regular;
      }
      &__chips {
        border-bottom: 1px solid $cool-dark-grey;
      }
      &__chips-tag {
        display: inline-block;
        color: $text-dark-grey;
        font-size: 11px;
        letter-spacing: 0.16px;
        line-height: 16px;
        text-align: right;
        padding: 7px 13px 7px 10px;
        margin-bottom: 10px;
        border: 1px solid $cool-dark-grey-cap;
        border-radius: 1px;
      }
      &__chips-clear-all {
        color: $text-medium-dark-grey;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
        margin-bottom: 24px;
        margin-top: 4px;
        text-decoration: underline;
        @include breakpoint(mobile) {
          margin-top: 1px;
          margin-bottom: 30px;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
  }
}
.taxware-down {
  &__title {
    margin-top: 20px;
    color: $slate;
    font-family: $helvetica-light;
    font-size: 36px;
    letter-spacing: -1.7px;
    line-height: 44px;
  }
  &__desc {
    margin-top: 20px;
    color: $dark-gray;
    font-family: $helvetica-regular;
    font-size: 20px;
    letter-spacing: -0.65px;
    line-height: 28px;
  }
  &__cta {
    margin-top: 40px;
    background-color: $primary-blue !important;
  }
}
.find-store-landing .auto-suggestion__input {
  @include ann-describtion(
    1.625rem !important,
    $lato-regular !important,
    2.25rem !important
  );
  @include breakpoint(desktop-extra-lite-lg) {
    @include ann-describtion(
      22px !important,
      $lato-regular !important,
      2.25rem !important
    );
  }
  @include breakpoint(tab){
    font-size: 1.375rem!important;
    line-height: 2rem!important;
  }
  color: $text-medium-dark-grey !important;
  letter-spacing: 0 !important;
  padding: 0 49px 20px 0;
}
.find-store-landing .store-card {
  background-color: $color-medium-plump;
  &__details {
    @include breakpoint(tab) {
      padding: 21px 32px 25px 32px;
      .store-card__time {
        display: flex;
      }
      .book-appointment.\--mobile {
        width: 290px;
        border: 1px solid $white;
        .button.small {
          padding: 15px;
        }
      }
    }
    @include breakpoint(mobile) {
      padding: 25px 18px;
      .store-card__time {
        display: flex;
      }
      .book-appointment.\--mobile {
        border: 1px solid $white;
        .button.small {
          padding: 15px;
        }
      }
    }
  }
}
.find-store-landing .store-card.showroom {
  background: $text-dark-grey;
}
.find-store-landing .store-card__name p {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 32px;
}
.find-store-landing .store-card address {
  margin-top: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.find-store-landing .store-card .store-card-phone > a,
.find-store-landing .store-card address > p {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.find-store-landing .store-card .store-card-phone {
  margin-bottom: 0;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.find-store-landing .store-card__open .distance {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 7px;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.find-store-landing .store-card__view-details {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  @include breakpoint(desktop-small) {
    position: absolute;
    bottom: 30px;
  }
}
.find-store-landing .store-card__time {
  display: inline-block;
  margin: auto;
}
.find-store-landing .store-card__open {
  @include breakpoint(desktop-small) {
    margin-bottom: 32px;
  }
  .checkbox input ~ span {
    color: $white;
  }
}
.find-store-landing .book-appointment.\--mobile {
  margin-bottom: 0;
  display: flex;
  padding: 0;
  border: 1px solid $white;
  @include breakpoint(desktop-small) {
    width: 208px;
  }
  .button {
    height: 100%;
    border-style: none;
    border-bottom: 0;
    &:hover {
      background-color: transparent;
    }
    &.small {
      padding: 0;
      margin: 0;
      display: flex;
      border-bottom: 0;
      letter-spacing: 2.5px;
      color: $white;
      transition: none;
    }
  }
}
@include breakpoint(tab) {
  .find-store-landing {
    &__message,
    &__view-list,
    &__location-note {
      font-family: $lato-regular;
    }
    &__search-container {
      padding: 0;
    }
    .offset-lg-1 {
      padding: 0;
      margin: 0;
    }
    &__message {
      color: $text-light-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__breadcrumb {
      margin-top: 40px;
      margin-bottom: 20px;
      padding-left: 32px;
    }
    &__search-input {
      padding: 0 0 13px 0;
    }
    &__use-location {
      margin-top: 16px;
      margin-bottom: 41px;
    }
    &__view-list {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__tabs {
      padding: 0;
      margin: 0;
    }
    &__tab {
      padding: 0 10px 22px 10px;
      margin-right: 20px;
      font-size: 14px;
      letter-spacing: 2.33px;
      line-height: 17px;
      &:first-child {
        margin-left: 0;
      }
    }
    &__list {
      height: auto;
      border-bottom: none;
      margin-bottom: 0;
    }
    &__show-list {
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &__show-map {
      padding-left: 0;
      padding-right: 0;
    }
    .col-12 {
      padding: 0;
      padding-left: 0;
      padding-right: 0;
    }
    &__breadcrumb {
      margin-top: 19px;
      margin-bottom: 22px;
      padding-left: 33px;
    }
    &__location-note {
      margin: 14px 0 6px 0;
      padding: 0 0 0 62px;
      color: $text-medium-dark-grey;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 20px;
      span {
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 15px;
          height: 21px;
          margin-right: 8px;
        }
      }
    }
    &__map {
      margin-top: -25px !important;
    }
    .store-list {
      padding: 28px 0 30px 0;
      margin: 0;
      &__name-col {
        padding: 0 68px 0 20px;
      }
      &__address-col {
        display: block;
        padding: 0 79px 0 0;
      }
      &__phone {
        margin-top: 15px;
      }
      &__distance-col {
        display: flex;
      }
      &__time {
        display: none;
      }
      &__set-store {
        margin-top: 20px;
      }
      &__set-store-time {
        display: block;
        padding-bottom: 4px;
        margin: 0;
      }
    }
    &__result-container {
      div.button.large {
        height: 24px;
        width: 137.36px;
        min-width: 140px;
        color: $color-light-blue;
        background-color: $color-white;
        padding: 0;
        margin: 30.78px 0 11.66px 314px;
      }
      .kf-react-button.large {
        margin: 0 36%;
        width: max-content;
      }
      .row {
        margin: 0;
      }
    }
    .find-store-browseall-div {
      text-align: center;
      .kf-react-button.large {
        margin: unset;
        width: max-content;
      }
    }
    &__filter {
      padding: 0;
    }
    .filter {
      padding: 0;
    }
    &__filter-panel {
      top: 0;
    }
    &__filter-panel .filter__mobile-header {
      padding-top: 0;
      margin: 0 24px 0 32px;
    }
  }
  .product-list {
    &__close-icon {
      width: 20px;
      height: 20px;
    }
    &__controls {
      margin: 0;
      position: relative;
    }
    &__filter-toggle {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      border: 0;
      background: 0;
    }
    &__filter-toggle-icon {
      height: 20px;
      width: 21px;
      margin-right: 10px;
    }
  }
  .find-store-landing .auto-suggestion__input {
    padding: 0 0 13px 0;
  }
  .find-store-landing__filter-panel .filter {
    &__chips {
      margin-left: 33px;
      margin-right: 31.39px;
    }
    &__chips-tag {
      font-size: 12px;
      letter-spacing: 0.2px;
      line-height: 15px;
      padding: 8.5px 24.16px 7.5px 9.67px;
      margin-bottom: 14.5px;
      margin-right: 9.5px;
    }
    &__chips-clear-all {
      color: $text-light-dark-grey;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 18px;
      margin-bottom: 30px;
      text-decoration: underline;
    }
    .product-list__filter-toggle {
      border: 0;
      background: 0;
    }
  }
  .find-store-landing__filter-panel .Collapsible {
    margin: 0 31.76px 0 32px;
    .plus {
      width: 8.49px;
      height: 8.49px;
    }
    .line-1 {
      padding-right: 10px;
    }
    .line-2 {
      padding-left: 1px;
      margin-left: 4.5px;
    }
    &__trigger {
      text-transform: uppercase;
    }
  }
  .filter__inner-container {
    padding-bottom: 0;
  }
  .find-store-landing__filter-panel .filter__footer {
    padding: 0 32px;
    margin-bottom: 25px;
    button {
      margin-top: 20px;
    }
  }
}

@include breakpoint(mobile) {
  .find-store-landing {
    &__search {
      margin: 0;
    }
    &__use-location {
      padding-left: 0;
    }
    &__show-list {
      padding: 0;
    }
    &__message,
    &__view-list {
      font-family: $lato-regular;
    }
    &__search-container {
      padding: 0;
    }
    .offset-lg-1 {
      padding: 0;
      margin: 0 18px 0 18px;
    }
    &__message {
      color: $text-light-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__result-container {
      padding: 0 18px;
      .row {
        margin: auto;
      }
    }
    &__search-input {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
      padding: 0 26px 15px 0;
    }
    &__use-location {
      margin-bottom: 38px;
    }
    &__view-list {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__tabs {
      padding: 0;
    }
    &__tab {
      padding: 0 10px 22px 10px;
    }
    &__list {
      margin-bottom: 28px;
      border-bottom: none;
      height: auto;
      padding: 0;
      .row {
        margin: 0;
      }
    }
    &__filter {
      padding: 0 18px;
    }
    &__map {
      margin-top: -25px !important;
    }
    .store-list {
      padding: 30px 0 30.5px 0;
      margin: 0;
      &__name-col {
        padding: 0 0 0 18px;
      }
      &__address-col {
        padding: 0 0 0 18px;
        margin-top: 30px;
      }
      &__distance-col {
        padding: 0 18px 0 0;
        display: flex;
      }
      &__set-store {
        margin-top: 32px;
      }
      &__set-store-time {
        margin-top: 30px;
      }
    }
    &__show-map {
      padding-left: 0;
      padding-right: 0;
    }
    &__location-note {
      margin: 10px 0 10px;
      padding: 0 0 0 16px;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 20px;
      span {
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 15px;
          height: 21px;
          margin-right: 8px;
        }
      }
    }
    &__result-container {
      .button.large {
        height: 24px;
        width: 137.36px;
        min-width: 140px;
        color: $color-light-blue;
        background-color: $color-white;
        padding: 0;
        text-align: center;
        margin-bottom: 63px;
        margin-left: 95px;
        font-family: $lato-regular;
      }
      .kf-react-button.large.ghost {
        width: max-content;
        margin: 0px 26%;
        height: 24px;
      }
    }
    .find-store-landing {
      .find-store-browseall-div {
        text-align: center;
        .kf-react-button.large {
          margin: unset;
          width: max-content;
        }
      }
    }
  }
  .filter__inner-container {
    padding-bottom: 50px;
  }
  .product-list {
    &__close-icon {
      width: 21.21px;
      height: 21px;
    }
    &__filter-toggle {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      border: 0;
      background: 0;
    }
    &__filter-toggle-icon {
      height: 20px;
      width: 21px;
      margin-right: 10px;
    }
  }
  .find-store-landing__filter-panel .filter {
    &__chips-tag {
      margin-bottom: 14px;
    }
    &__chips-clear-all {
      margin-bottom: 29px;
      text-decoration: underline;
    }
  }
  .find-store-landing .auto-suggestion__input {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0 26px 15px 0;
  }
  .find-store-landing__filter-panel .Collapsible {
    .plus {
      width: 8.49px;
      height: 8.49px;
    }
    .line-1 {
      padding-right: 10px;
    }
    .line-2 {
      padding-left: 1px;
      margin-left: 4.5px;
    }
    &__trigger {
      text-transform: uppercase;
    }
  }
  .find-store-landing .store-card {
    bottom: 40px;
  }
  .find-store-landing__filter-panel .filter__footer {
    margin-bottom: 25px;
    margin-top: 20px;
    width: 100%;
  }
}
.find-store-landing__filter-panel .filter__apply {
  color: $white;
  font-family: $lato-regular;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.5px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 1px;
  margin-bottom: 25px;
  background-color: $cool-dark-grey-cap;
  margin-top: 20px;
  margin-top: 50px;
  @include breakpoint(mobile) {
    margin-bottom: 90px;
    width: 100%;
    height: 60px;
    border: 0;
    outline: auto;
  }
  @include breakpoint(tab) {
    border: 0;
    outline: auto;
    width: 100%;
    height: 60px;
  }
}
.find-store-landing__filter-panel .filter__apply--active {
  color: $white;
  background-color: $color-medium-blue-cap;
  border-radius: 1px;
}
@media screen and (max-width: 767px) {
  .find-store-landing {
    .find-store-browseall-div {
      text-align: center;
      .kf-react-button.large {
        margin: unset;
        width: max-content;
      }
    }
  }
}
@include breakpoint(desktop-extra-lite-lg) {
  .find-store-browseall-div {
    text-align: center;
    .kf-react-button.large {
      margin: unset;
      width: max-content;
    }
  }
}

@include breakpoint(mobile) {
  .col-12 {
    padding: 0 18px;
  }
}
@media only screen and (max-width: 860px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 1024px) {
  .find-store-landing {
    &__filter {
      width: 100%;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $color-white;
      padding: 0px 19px;
      display: none;
    }
    &__breadcrumb {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 17px;
    }
    &__filter--modal {
      display: block;
    }
    &__filter-panel {
      position: sticky;
      top: 8px;
    }
    .filter__mobile-header {
      justify-content: space-between;
    }
    .find-store-landing__search-container {
      padding: 0px;
      margin-bottom: 0px;
    }
    &__search {
      margin: 0px 32px;
    }
    &__message {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      padding: 0px 32px;
    }
    &__store-message {
      margin-top: 25px;
    }
    &__list {
      border-bottom: none;
      margin-bottom: 40px !important;
    }
    .find-store-landing__map {
      margin-top: -71px;
      @include breakpoint(desktop-extra-sm){
        height: 100vh;
        padding: 0;
        position: unset;
        margin-bottom: 0;
      }
      @include breakpoint(tab){
        display: none;
      }
    }
    .find-store-landing__show-map {
      display: block;
      height: 100vh;
      padding: 0;
      position: unset;
      margin-bottom: 0px;
    }
    &__location-note {
      padding: 13px 32px;
      span {
        margin-right: 15px;
      }
    }
    &__view-list {
      text-decoration: underline;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-bottom: 25px;
      cursor: pointer;
      float: right;
      margin-top: 15px;
      padding-right: 34px;
    }
    .find-store-landing__result-container {
      margin-left: 0px;
      padding: 0px;
      position: relative;
      margin-bottom: 0px;
      .kf-react-button.large {
        width: calc(100% - 64px);
        margin: 0px 32px;
        &:hover {
          background-color: $color-accent-blue-a;
        }
      }
    }
    &__show-list {
      padding: 0px 32px;
      height: auto;
      overflow-y: auto;
      @include breakpoint(desktop-mid){
        height: 540px;
      }
    }
    &__tabs {
      width: 100%;
    }
    &__use-location {
      margin-bottom: 25px;
      text-decoration: underline;
      padding-left: 32px;
    }
    &__tabs {
      overflow-x: auto;
      scroll-behavior: smooth;
      justify-content: center;
      align-items: center;
    }
    &__search-full-container {
      padding-top: 21px;
    }
    &__filter-panel {
      .filter__mobile-header {
        height: auto;
        padding-bottom: 20px;
        padding-top: 22px;
        display: flex;
        margin-bottom: 0;
      }
      .filter__chips-tag {
        border: solid 1px $medium-gray;
      }
    }
    .store-list {
      &:last-child {
        border-bottom: 1px solid $color-light-gray;
      }
      &__address {
        a {
          font-family: $lato-regular;
          span {
          font-family: $lato-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      &__distance-col {
        text-align: right;
      }
    }
    .store-card {
      left: 10px;
      margin-top: 14px;
      &__image,
      &__open .checkbox {
        display: none;
      }

      &__name {
        p {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.65px;
        }
        span {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.65px;
        }
      }
      &__details {
        display: block;
        padding: 15px 35px 0 32px;
      }
      &__time {
        flex-direction: unset;
        padding: 15px 0px 49px;
      }
      &__address {
        .book-appointment {
          .kf-react-button.small {
            min-width: 81%;
            justify-content: center;
            padding: 8px 57px;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
@include breakpoint(ipad-landscape){
  .find-store-landing {
    .find-store-landing{
      &__search-container {
        flex: 0 0 41%;
        max-width: 41%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .find-store-landing {
    &__filter {
      width: 100%;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $color-white;
      padding: 0px 19px;
      display: none;
    }
    &__message {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.65px;
      padding: 0px 18px;
    }
    &__store-message {
      margin-top: 31px;
    }
    &__breadcrumb {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 3px;
    }
    .find-store-landing__search-container {
      padding: 0px;
      margin-bottom: 0px;
    }
    &__location-note {
      padding: 10px 18px;
      justify-content: center;
      span {
        display: flex;
        margin-right: 15px;
      }
    }
    &__list {
      border-bottom: none;
      height: auto;
    }
    &__tabs {
      margin-right: 0;
    }
    .find-store-landing__result-container {
      margin-left: 0px;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0px;
      .kf-react-button.large {
        justify-content: center;
        margin: 0px 18px;
        &:hover {
          background-color: $color-accent-blue-a;
        }
      }
    }
    &__filter--modal {
      display: block;
    }
    &__filter-panel {
      position: sticky;
    }
    &__show-list {
      height: auto;
      overflow-y: auto;
    }
    .find-store-landing__map {
      display: none;
      margin-top: 0px;
      padding: 0px;
    }
    .find-store-landing__show-map {
      display: block;
      height: 100%;
      padding: 0px;
      margin: 0;
      .react-bingmaps {
        height: 70vh;
      }
    }
    &__view-list {
      text-decoration: underline;
      font-family: $helvetica-regular;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-bottom: 31px;
      cursor: pointer;
      float: right;
      margin-top: 15px;
    }
    &__use-location {
      margin-bottom: 31px;
    }
    &__tabs {
      overflow-x: auto;
      scroll-behavior: smooth;
      justify-content: flex-start;
      align-items: center;
    }
    &__search-full-container {
      padding-top: 21px;
    }
    &__filter-panel {
      .filter__mobile-header {
        height: auto;
        padding-top: 9px;
        display: flex;
        margin-bottom: 20px;
      }
      .filter__chips-tag {
        border: solid 1px $medium-gray;
      }
      .filter__apply {
        margin-bottom: 50px;
      }
    }
    .store-list {
      padding: 30px 0 30.5px;
      &:last-child {
        border-bottom: 1px solid $color-light-gray;
      }
      &__time {
        display: none;
      }
      &__set-store-time {
        display: block;
      }
      &__address {
        a {
          font-family: $lato-regular;
          span {
          font-family: $lato-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          &:hover {
            text-decoration: none;
          }
        }
        }
      }
      &__distance-col {
        text-align: right;
      }

      &__name-col {
        margin-right: 26px !important;
      }

      &__address-col {
        margin-right: 0;
      }
    }

    .store-card {
      display: block;
      width: 100%;
      left: 0;
      bottom: 0;
      &__open .checkbox {
        display: none;
      }
      &__address {
        .book-appointment {
          margin-top: 0;
          position: absolute;
          bottom: 16px;
          width: 100%;
          .kf-react-button.small {
            min-width: 86%;
            justify-content: center;
          }
        }
      }
      &__details {
        display: block;
      }
      &__image {
        display: none;
      }
      &__time {
        flex-direction: unset;
        padding: 9px 0px 45px;
      }
      &__name {
        p {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.65px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .find-store-landing {
    &__tab {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      padding: 0px 20px 15px;
    }
    &__result-container {
      .kf-react-button.large.ghost {
        margin: 0px 18px;
      }
    }
  }
  .store-list {
    &__name-col {
      display: flex;
      order: 1;
    }
    &__address-col {
      order: 3;
      margin-top: 23px;
    }
    &__distance-col {
      order: 2;
    }
  }
}
