.input-field {
    position: relative;
    margin-bottom: 20px;

    @media not all and (min-resolution:.001dpcm) {
        input:focus,
        select:focus,
        textarea:focus {
            outline: none !important;
        }
    }

    &__border {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 5px 15px;
        border-radius: 4px;
        border: solid 1px $color-dark-gray;
        background-color: $color-white;
        &.--focused {
            border: solid 1px $color-slate;
        }
        &.--with-error {
            border: solid 1px $color-error-red;
        }
        &.--disabled {
            border: none;
            background-color: $color-extra-light-gray;
            label {
                color: $color-slate;
            }
        }
    }

    &__prefix {
        margin-right: 15px;
    }

    &__suffix {
        margin-left: 15px;
    }

    &__toggle {
        cursor: pointer;
        font-family: $font-regular;
        font-size: 14px;
        letter-spacing: -0.65px;
        line-height: 22px;
        color: $color-slate;
    }

    &__value {
        width: 100%;
        display: flex;
        flex-flow: column-reverse;
        overflow: hidden;
        transition: all 0.2s;
        touch-action: manipulation;

        label, input {
            transition: all 0.2s;
            touch-action: manipulation;
        }

        input {
            background: none;
            padding: 0;
            border: none;
            font-family: $font-regular;
            font-size: 18px;
            line-height: 1.44;
            letter-spacing: -0.7px;
            color: $color-slate;
            cursor: text;
            z-index: 1;

            &:focus {
                outline: 0;
                border: none;
            }
        }

        label {
            font-family: $font-regular;
            font-size: 12px;
            line-height: 1.67;
            letter-spacing: -0.35px;
            color: $color-dark-gray;
            margin-bottom: 0;
        }

        input:placeholder-shown + label {
            cursor: text;
            max-width: 66.66%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform-origin: left bottom;
            transform: translate(0, 88%) scale(1.5);
        }

        input::-webkit-input-placeholder,
        input::-moz-placeholder,
        input::placeholder {
            opacity: 0;
        }

        input:not(:placeholder-shown) + label,
        input:focus + label {
            transform: translate(0, 0) scale(1);
        }
    }

    &__error-message {
        display: block;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.3px;
        color: $color-error-red;
        margin-top: 10px;
    }
    &__suggestions {
        width: 100%;
        max-height: 250px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 15px 0;
        margin: 0;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        list-style: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.15);
        transition: height 0.5s ease-in-out;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__list-item {
        padding: 10px 20px;
        color: $color-slate;
        cursor: pointer;
        &.--hover {
            background-color: $color-kohler-blue;
            color: $color-white;
        }
        &:hover, &:focus {
            background-color: $color-off-white;
            color: $color-slate;
        }
    }
    &__list-value {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}
