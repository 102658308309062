.link-tool-box{
    padding:0 3.75rem;
    margin:30px 0 40px;
    border-right:1px solid;
    @include breakpoint(tab-max){
        padding:20px 0 30px;
        border-right:none;
        border-bottom:1px solid;
        margin:0;
    }
    &:first-child{
        padding-left:0;
        @include breakpoint(tab-max){
            padding-top:25px;
        }
    }
    &:last-child{
        padding-right:0;
        border-right:none;
        @include breakpoint(tab-max){
            border-bottom:none;
            padding-bottom:35px;
        }
    }
    .cmp-teaser{
        max-width:1380px;
        margin:0 auto;
        &:first-child{
            @include breakpoint(tab-max){
                border: 0;
            }
        }
        .cmp-teaser__content{
            .cmp-teaser__title{
                padding: 0;
                font-size: unset;
                @include breakpoint(mobile){
                    max-width:123px;
                   margin-right:31px;
                   padding-right:23px;
                }
                *{
                    font-size:12px;
                    line-height:16px;
                    letter-spacing:2px;
                    font-family:$lato-regular;
                }
            }
            .cmp-teaser__description{
                @include breakpoint(tab-min){
                    margin-top:10px !important;
                }
                @include breakpoint(mobile){
                    max-width:154px;
                    padding-left: 0;
                }
                *{
                    font-size:14px;
                    line-height:20px;
                    letter-spacing:normal;
                    font-family:$lato-regular;
                }
            }
        }
    }
}
.tool-box-container {
    @include breakpoint(desktop-small) {
        padding: 0 2.25rem;
    }

    &.bg-warm-gray-medium {
        .link-tool-box {
            border-color: $text-light-grey;
            .cmp-teaser {
                &__content {
                    .cmp-teaser__title {
                        h1, h2, h3, h4, h5, h6 {
                            a {
                                border-bottom: 1px solid $text-dark-grey;
                            }
                        }
                    }
                    .cmp-teaser__description {
                        p {
                            a {
                                border-bottom: 1px solid $text-medium-dark-grey;
                            }
                        }
                    }
                }
            }
        }
    }

    &.bg-dark-blue-three {
        .link-tool-box {
            border-color: $text-medium-dark-grey;
        }
    }

    .cmp-container {
        max-width: 1380px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        padding: 0;
        
        @include breakpoint(tab-max) {
            max-width: 100%;
            flex-direction: column;
        }

        @include breakpoint(tab) {
            padding: 0 2rem;
        }

        @include breakpoint(mobile) {
            padding: 0 1.125rem;
        }
    }
}