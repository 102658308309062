.full-bleed-carousel{
    &.carousel-slick{
        padding:0;
        .cmp-carousel{
            &.cmp-carousel-slick{
                .carousel-header-info{
                    padding:0;
                }
                .slick-initialized{
                    &.slick-slider{
                        margin-bottom:0;
                        position: relative;
                    }
                    .slick-list{
                        padding: 0;
                        margin: 0;                        
                    }
                    .slick-slide{
                        margin:0;
                    }
                    .slick-dots{
                        position:absolute;
                        bottom:unset;
                        margin: 0;
                        margin-bottom:2.8125rem;
                        @include breakpoint(tab-max){
                            margin-bottom:1.875rem;
                        }
                        li{
                            &.slick-active{
                                button{
                                    background:$white;
                                }
                            } 
                            button{
                                border:1px solid $white;
                            }
                        }
                    }
                    .slick-arrow {
                        width:50px;
                        height:50px;
                        opacity:1;
                        margin: 0 auto;
                        z-index:2;
                        @include breakpoint(tab-max){
                            display:none !important;
                        }                    
                        &.slick-disabled{
                            opacity:1;
                            z-index:1;
                            &:before{
                                opacity:0.4;
                                cursor:not-allowed;
                            }                                                    
                        }
                        &.slick-prev{
                            left:50px;
                            @include breakpoint(desktop-large){
                                max-width:1800px;
                                left:50%;
                                margin-left:-910px;
                            }
                            @include breakpoint(desktop-lg){
                                left:6.875rem;
                            }
                            @include breakpoint(desktop-mid){
                                left:2.6%;
                            }
                        }
                        &.slick-next{
                            right:50px;
                            @include breakpoint(desktop-large){
                                max-width: 1800px;
                                right: 50%;
                                margin-right: -910px;
                            }
                            @include breakpoint(desktop-lg){
                                right:6.875rem;
                            }
                            @include breakpoint(desktop-mid){
                                right:2.6%;
                            }
                            &::before{
                                margin-left:auto;
                            }
                        }
                        &:before{
                            color:$white;
                            z-index: 9;
                            border: 1px solid $white;
                            padding: 5px;
                            width:50px;
                            height:50px;
                            opacity:1;
                            display:block;
                            align-items: center;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}