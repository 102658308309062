.promotion-tiles{
    padding: 4.375rem 0;
    @include breakpoint(tab-max){
        padding: 50px 0;
    }
    .cmp-teaser{
        display:flex;
        flex-direction:row-reverse;
        align-items: center;
        justify-content: space-between;
        @include breakpoint(mobile){
            flex-direction:column;
        }
        &__image{
            @include breakpoint(desktop-small){
                width:660px;
            }
            .cmp-image{
                @include breakpoint(desktop-small){
                    max-width:660px;
                    max-height:495px;
                }
                @include breakpoint(tab){
                    min-width:340px;
                    min-height:256px;
                }
                @include breakpoint(mobile){
                    min-width:339px;
                    min-height:255px;
                }
            }
        }
        &__content{
            width:33.75rem;
            margin-right:auto;
            @include breakpoint(tab){
                width:19.25rem;
            }
            @include breakpoint(mobile){
                width:100%;
                margin-top: 1.25rem;
            }
        }
        &__title{
            h1,h2,h3,h4,h5,h6,p{
                @include headingtitle_h3;
                margin-bottom:1.25rem;
                color:$text-dark-grey;
                @include breakpoint(tab-max){
                    letter-spacing:-0.073125rem;
                }
                @include breakpoint(tab){
                    margin-bottom: .625rem;
                }
                @include breakpoint(mobile){
                    margin-bottom:.625rem;
                }
                p{
                    margin:0;
                }
            }
        }
        &__description{
            width:18.75rem;
            margin-bottom: 1.25rem;
            p{
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                margin-bottom:.9375rem;
                letter-spacing: normal;
                color: $text-medium-dark-grey;
                &:last-child{
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    margin-bottom: 0;
                }
                @include breakpoint(tab-max){
                    margin-bottom:0.625rem;
                }
            }
        }
        &__action-link{
            color: $color-light-blue;
            border-color: $color-light-blue;
            padding-bottom: 7px;
            line-height: .75rem;
        }
    }
    &-two-up{
        padding: 4.375rem 0;
        margin-right:3.75rem;
        max-width:660px;
        @include breakpoint (desktop-mid ) {
            max-width: 48%;
            margin-right: 0;
        }
        &:last-child{
            margin-right:0;
            @include breakpoint(mobile){
                padding-bottom: 3.125rem;
            }
        }
        @include breakpoint(tab){
            margin-right: 4.25rem;
            padding: 3.125rem 0;
            width:45.456%;
        }
        @include breakpoint(mobile){
            padding: 75px 0 0;
            margin:0;
            width:100%;
        }
        .cmp-teaser{
            display:flex;
            flex-direction:column;
            &__image{
                margin-bottom: 1.875rem;
                @include breakpoint(tab-max){
                    margin-bottom: 1.25rem;
                }
                .cmp-image{
                    @include breakpoint(desktop-small){
                        max-width:660px;
                        max-height:495px;
                    }
                    @include breakpoint (desktop-mid) {
                        max-width:640px;
                    }
                    @include breakpoint(tab){
                        min-width:340px;
                        min-height:256px;
                    }
                    @include breakpoint(mobile){
                        min-width:339px;
                        min-height:255px;
                    }
                    &__image{
                        margin:0;
                    }
                }
                @include breakpoint(mobile){
                    width:100%;
                }
            }
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(1.375rem, $lato-regular, 2rem);
                    letter-spacing: normal;
                    color:$text-dark-grey;
                    margin-bottom:.625rem;
                    p{
                        margin:0;
                    }
                }
            }
            &__description{
                margin-bottom: 1.25rem;
                @include breakpoint(mobile){
                    width:17.3125rem
                }
                p:nth-child(odd){
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    color:$text-medium-dark-grey;
                    margin-bottom: .625rem;
                    letter-spacing: normal;                    
                }
                p:nth-child(even){
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                    margin-bottom: 0;
                    letter-spacing: normal;
                    color:$text-medium-dark-grey;
                }
            }
            &__action-link{
                color: $color-light-blue;
                border-color:$color-light-blue;
                padding-bottom: 7px;
                line-height: .75rem;
            }
        }
    }
    &-four-up{  
        padding:0;
        max-width: 330px;
        margin-right:1.25rem;
        @include breakpoint (desktop-mid) {
            max-width: 24%;
            margin-right: 0;
        }
        &:last-child{
            @include breakpoint (mobile) {
            padding-bottom:0;
            }
            margin-right:0;            
        }
        @include breakpoint(tab){
            max-width: 340px;
            margin-bottom: 2.5rem;
            margin-right:0;
        }
        @include breakpoint(mobile){
            padding:0 0 2.5rem;
            width:100%;
            max-width:unset;
            &:first-child {
                padding: 0;
            }
        }
        .cmp-teaser{
            display:flex;
            flex-direction: column;
            &__image{
                margin-bottom: 1.875rem;
                @include breakpoint(tab-max){
                    margin-bottom: 1.25rem;
                }
                .cmp-image{
                    @include breakpoint(desktop-small){
                        max-width:330px;
                        max-height:248px;
                    }
                    @include breakpoint(tab){
                        min-width:340px;
                        min-height:256px;
                    }
                    @include breakpoint(mobile){
                        min-width:339px;
                        min-height:255px;
                    }
                    .cmp-teaser__image{
                        margin-bottom:0;
                    }
                }
            }
            &__content{
                padding-right: 1.875rem;
            }
            &__title{
                h1,h2,h3,h4,h5,h6,p{
                    @include ann-describtion(12px, $lato-regular, 16px);
                    text-transform:uppercase;
                    letter-spacing:2px;
                    margin-bottom: .625rem;
                    color:$text-dark-grey;
                }
            }
            &__description{
                p {
                @include ann-describtion(16px, $lato-regular, 24px);
                color:$text-medium-dark-grey;
                margin-bottom: 1.25rem;
                padding-right: 3.75rem;
                letter-spacing: normal;
                @include breakpoint(mobile){
                    padding-right: 5rem;
                }
            }
            }
            &__action-container {
            .cmp-teaser__action-link{
                @include ann-describtion(11px, $lato-regular, 16px);
                color:$color-light-blue;
                border-color:$color-light-blue;
                padding-bottom:.4375rem;
                letter-spacing: 1.8px;
            }
        }
        }
    }
    &-indent {
           .cmp-container {
            @include breakpoint (desktop-mid) {
                padding: 0 20px;
            }
            @include breakpoint (desktop-extra-sm) {
                padding: 0 2.6%;
            }
           }
    }
    
}
.promotion-tile-fourup-wrapper{
    display:flex;
    justify-content:space-between;
    @include breakpoint(tab-max){
        flex-wrap:wrap;
    }
    @include breakpoint(tab){
        max-width:748px;
        margin:0 auto;
    }
}
.promotion-headline-left{
    padding:0 0 3.125rem;
    display:flex;
    max-width:100%;
    flex:0 0 100%;
    .cmp-teaser__image {
       display: none;
    }
    @include breakpoint(tab-max){
        padding:0 0 25px;
    }
    @include breakpoint(tab){
        max-width:748px;
        margin:0 auto;
    }
    .cmp-teaser{
        &__title{
            h1,h2,h3,h4,h5,h6,p{
                @include headingtitle_h3;
                color:$text-dark-grey;
                letter-spacing:-0.0625rem;
                @include breakpoint(tab-max){
                    font-size:2.25rem;
                    line-height:2.75rem;
                    letter-spacing:-0.125rem;
                }
                p{
                    margin:0;
                }
            }
        }
    }
}