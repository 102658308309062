.news-letter-sign-up {
    .modal-popup{
        &.show-modal-popup{
         z-index: 11111111111;
        }
    }
    .modal-popup .modal-popup-content {
        width: 500px;
        overflow-y: unset;
        outline: unset;
         @media (max-width: 1023px) and (min-width: 768px) {
            overflow-y: auto;
         }
         @media (max-width: 767px) {
                width: 100%;
                margin: 0 18px;
                height: unset!important;
                min-height: unset!important;
                margin: auto 18px;
         }
        .modal-popup-body{
            padding: 70px 40px 50px 40px ;
             @media (max-width: 1023px) and (min-width: 768px) {
                padding:50px 38px 35px 38px;
             }
             @media (max-width: 767px) {
                padding: 50px 22px 35px;
            }
        }
        @media (max-width: 1023px) and (min-width: 768px) {
            width: 476px ;
        }
    }
    .close-button {
        @media (max-width: 1024px) and (min-width: 415px) {
            top: 16px ;
            right: 30px;
        }
        @media (max-width: 415px) {
            top: 20px;
            right: 20px;
        }
    }
    
    .success-modal-button {
        background-color: $color-charcoal;
        color: $color-white;
        font-family: $font-medium;
        border-radius: 1px;
        font-size: 18px;
        letter-spacing: -0.35px;
        line-height: 24px;
        text-align: center;
        text-transform: inherit;
        margin-top: 40px;
        width: 100%;
        padding: 18px 0;
        height: unset;
        @media (max-width: 1023px) {
            margin-top: 30px;
        }
    }
    
    &__newsletter-overlay {
        .overlay-mail-errormsg {
            margin: 5px 0 0px 0;
            color: $color-error-red;
            font-size: 14px;
        }
    }
    &__header {
        margin: 0 0 15px 0;
        p{
            color: $color-slate;
            font-family: $font-light;
            font-size: 2.25rem;
            line-height: 2.75rem; 
            letter-spacing: -0.1062rem;
             @media (max-width: 1023px) and (min-width: 768px) {
                font-size: 2rem;
                line-height: 2.5rem;
                letter-spacing: -2.2px;
             }
              @media (max-width: 767px) {
                    font-size: 2rem;
                    line-height: 2.5rem;
                    letter-spacing: -0.1375rem;
              }
        }
    }
    &__subcopy {
          width: 100%;
          margin: 0 0 30px 0;
            @media (max-width: 767px) {
                margin: 0 0 20px 0;
            }
        p{
            color: $color-dark-gray;
            font-family: $font-regular;
            font-size: 1rem;
            letter-spacing: -0.5px;
            line-height: 1.5rem;
        }
         @media (max-width: 1023px) and (min-width: 768px) {
            margin-bottom: 20px;
        }
    }
    &__email-input {
            border: 1px solid $color-dark-gray;
            border-radius: 4px;
            width: 100%;
            padding: 16px 0px 16px 15px;
            font-size: 1.125rem;
            color: $color-dark-gray;
            letter-spacing: -0.0437rem;
            line-height: 26px;
            font-family: $font-regular;
        &::placeholder {
            color: $color-dark-gray;
            font-family: $font-regular;
            font-size: 18px;
            letter-spacing: -0.0437rem;
            line-height: 26px;
        }
        &:focus {
            box-shadow: none;
        }
    }
    &__button {
        background-color: $color-charcoal;
        color: $color-white;
        font-family: $font-medium;
        font-size: 18px;
        letter-spacing: -0.35px;
        line-height: 24px;
        text-align: center;
        width: 100%;
        border-radius: 1px;
        text-transform: capitalize;
        padding: 16px 0;
        margin: 25px 0 0 0;
        @media (max-width: 1023px) {
            margin-top: 25px;
        }
    }
    &__thanks {
        color: $color-slate;
        font-family: $font-light;
        font-size: 2.25rem;
        letter-spacing: -0.1062rem;
        line-height: 2.75rem;
        text-transform: inherit;
        margin-bottom: 15px;
         @media (max-width: 1023px) {
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: -0.1375rem;
         }
    }
    &__success {
        color: $color-dark-gray;
        font-family: $font-regular;
        font-size: 1rem;
        letter-spacing: -0.55px;
        line-height: 24px;    
    }
    &__policies{
        display: flex;
        font-family: $font-regular;
        justify-content: space-between;
        color: $color-dark-gray;
        text-decoration: underline;
        @media (max-width: 767px) {
            display: block;
        }
        a {
            margin-right: 18px;
            text-decoration: underline;
            font-family: $font-regular;
            font-size: .875rem;
            line-height: 1.375rem;
            letter-spacing: -0.0281rem;
            color: $color-dark-gray;
            @media (max-width: 1023px) and (min-width: 768px) {
                margin-right: .75rem;
            }
            @media (max-width: 470px) {
                &:nth-of-type(3n) {
                    width: 100%;
                    display: block;
                }
                &:nth-of-type(2n) {
                    margin-right: 0px;
                }
        }            
            &:hover {
                text-decoration: underline;
                color: $color-black;
            }
        }
    }
}