.product-details__install-items-modal {
    .modal-popup {
        .modal-popup-content {
            height: auto !important;
            padding: 0 !important;
            .close-button {
                right: 60px !important;;
                top: 47px !important;;
            }
        }
    }
}

.required-item {
    background-color: $color-white;
    max-width: 1020px;
    margin: 0;

    &__container {
        display: grid;
        grid-template-columns: 360px 660px;
    }

    &__left-section {
        background-color: $color-off-white;
        padding: 94px 60px 40px 60px;
        width: 360px;
        height: 100%;

        .product-title {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
            color: $color-charcoal;
            margin-bottom: 40px;
        }

        .item-details {
            display: grid;
            row-gap: 20px;
            column-gap: 20px;
            grid-template-columns: 60px auto;
            grid-template-rows: 80px auto;
            &__image {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                .product-image {
                    width: 60px;
                    height: 80px;
                    background-color: $color-off-white;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 50% 50%;
                        mix-blend-mode: multiply;
                    }
                }        
            }

            &__sku {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                .brand-name {
                    font-family: $font-medium;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.25px;
                    color: $color-slate;
                    margin-bottom: 5px;
                }
                .sku-id {
                    font-family: $font-regular;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.65px;
                    color: $color-slate;
                }
            }

            &__info {
                grid-column: 1 / 3;
                grid-row: 2 / 3;
                .product-family-name {
                    font-family: $font-light;
                    font-size: 22px;
                    line-height: 32px;
                    letter-spacing: -1.2px;
                    color: $color-black;
                    margin-bottom: 10px;
                }
        
                .product-description, .color-finish {
                    font-family: $font-regular;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.55px;
                    color: $color-slate;
                    margin-bottom: 20px;
                }
        
                .price {
                    font-family: $font-bold;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.8px;
                    color: $color-charcoal;
                    text-align: right;
                }
            }
        }
        
    }

    &__right-section {
        padding: 94px 58px 40px 60px;

        .required-product-title {
            font-family: $font-light;
            font-size: 32px;
            line-height: 44px;
            letter-spacing: -0.7px;
            color: $color-slate;
            margin-bottom: 10px;
        }

        .required-product-description {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $color-slate;
            padding-bottom: 15px;
        }

        .required-product-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            div {
                font-family: $font-regular;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.65px;
                color: $color-dark-gray;
            }
        }

        .product-line-items {
            .line-item {
                display: grid;
                grid-template-columns: 120px auto;
                grid-template-rows: 1fr auto;
                column-gap: 40px;
                row-gap: 0;
                padding: 30px 0px 25px 0px;
                border-top: 1px solid $color-light-gray;
                &:last-child {
                    padding-bottom: 0;
                }
                &__image {
                    grid-column: 1/2;
                    grid-row: 1/3;
                }
                &__header {
                    grid-column: 2/3;
                    grid-row: 1/2;
                    display: flex;
                    justify-content: space-between;
                }
                &__info {
                    grid-column: 2/3;
                    grid-row: 2/3;
                }
                &__include--mobile, &__price--mobile {
                    display: none;
                }
                &__price--mobile{
                .price{
                    .price_item{
                        color: $color-charcoal;
                        font-size: 16px;
                        font-family:$font-bold;
                        letter-spacing: -0.8px;
                        line-height: 22px;
                    }
                    .discount-price {
                        color: $color-dark-gray;
                        font-family: $font-regular;
                        font-size: 16px;
                        letter-spacing: -0.8px;
                        line-height: 22px;
                        span:first-child {
                            text-decoration: line-through;
                        }
                    }
                    .discount-percent-off {
                        color: $color-slate;
                        font-family: $font-medium;
                        margin-left: 5px;
                    }
                }
            }
                &__sku {
                    display: flex;
                    column-gap: 30px;
                }
                &__price--desktop {
                    display: flex;
                }
                .product-image {
                    width: 120px;
                    height: 160px;

                    .image-container {
                        width: 100%;
                        height: 100%;
                    }
                }
                .list-item__price {
                    color: $color-charcoal;
                    font-family: $font-bold;
                    font-size: 16px;
                    letter-spacing: -0.8px;
                    line-height: 22px;
                    text-align: right;
                }
                .list-item__discounted {
                    margin-top: 5px;
                    color: $color-dark-gray;
                    font-family: $font-regular;
                    font-size: 16px;
                    letter-spacing: -0.8px;
                    line-height: 22px;
                    span:last-child {
                        text-decoration: line-through;
                    }
                }
                .list-item__percent-off {
                    color: $color-slate;
                    font-family: $font-medium;
                    margin-right: 10px;
                }
                .checkbox {
                    margin: 0;
                    &__marker {
                        margin: 0;
                    }
                }
                .brand-name {
                    font-family: $font-medium;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.25px;
                    color: $color-dark-gray;
                }
                .sku-id {
                    font-family: $font-regular;
                    font-size: 14px;
                    color: $color-dark-gray;
                    line-height: 22px;
                    letter-spacing: -0.65px;
                }
                .item-family-name {
                    font-family: $font-light;
                    font-size: 22px;
                    line-height: 32px;
                    letter-spacing: -1.2px;
                    color: $color-black;
                    margin-bottom: 5px;
                }
                .item-description {
                    font-family: $font-regular;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.55px;
                    color: $color-dark-gray;
                    margin-bottom: 20px;
                }
                .item-color {
                    .color-section {
                        display: flex;
                        margin-bottom: 20px;
                        align-items: baseline;
                    }
                    .color-finish, .color-value {
                        font-family: $font-medium;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.35px;
                        color: $color-charcoal;
                    }
                    .color-value {
                        color: $color-dark-gray;
                    }
                    .item-color-swatches {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(4, 64px);
                        grid-column-gap: 5px;
                        .color-swatch input[type="radio"] + label {
                            height: 40px;
                            width: 64px;
                        }
                    }
                    
                }
                .out-of-stock-section {
                    margin-top: 25px;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                    span {
                        color: $color-error-red;
                        font-family: $font-regular;
                        font-size: 16px;
                        letter-spacing: -0.55px;
                        line-height: 22px;
                    }
                    &.--desktop {
                        display: block;
                    }
                    &.--mobile {
                        display: none;
                    }
                }
                .back-in-stock {
                    margin-left: 30px;
                    a{
                        font-family: $font-regular;
                        font-size: 16px;
                        letter-spacing: -0.55px;
                        line-height: 22px;
                        color: $color-error-red;
                        text-decoration: underline;
                    }
                    &.--desktop {
                        display: block;
                    }
                    &.--mobile {
                        display: none;
                    }
                }
            }
        }
        .include-button {
            text-align: right;
               margin-top: 45px;
               @media only screen and (min-width: 1300px){
                margin-top: 20px;
            }

        }
    }

    &__display-discount {
        text-align: right;
    }

    &__percent-off {
        margin-right: 10px;
        color: $color-slate;
        font-family: $font-medium;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 30px;
    }
    
    &__discount-price {
        text-decoration: line-through;
        color: $color-dark-gray;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.4px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 990px) {
    .product-details__install-items-modal {
        .modal-popup {
            .modal-popup-content {
                height: auto !important;
                overflow-y: auto !important;
                padding: 0 !important;
                margin: inherit;
                width: 100%;
                max-height: unset;
                .modal-popup-body {
                    margin: 0;
                }
                .close-button {
                    right: 18px !important;;
                    top: 20px !important;;
                }
            }
        }
    }

    .required-item {
       max-width: 100%;
        &__container {
            display: block;
        }

        &__left-section {
            padding: 105px 150px 50px 150px;
            width: 100%;

            .item-details {
                row-gap: 15px;
                column-gap: 55px;
                grid-template-columns: 80px auto;
                grid-template-rows: auto auto;
                &__image {
                    grid-column: 1 / 2;
                    grid-row: 1 / 3;
                    .product-image {
                        width: 80px;
                        height: 105px;
                    }
                }
                &__sku {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                    .brand-name {
                        margin-bottom: 0;
                    }
                }
                &__info {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                    .price {
                        text-align: left;
                    }
                }
            }
        }

        &__right-section {
            padding: 30px 150px 25px 150px;
            width: 100%;

            .required-product-title {
                font-family: $font-medium;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.35px;
                color: $color-charcoal;
                margin-bottom: 0;
            }
            .required-product-description {
                font-family: $font-medium;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.35px;
                color: $color-charcoal;
                padding-bottom: 30px;
                border-bottom: 1px solid $color-light-gray;
            }

            .required-product-header {
                display: none;
            }

            .product-line-items {
                .line-item {
                    grid-template-columns: 80px auto;
                    grid-template-rows: 25px auto auto;
                    column-gap: 55px;
                    &__image {
                        grid-column: 1/2;
                        grid-row: 1/3;
                    }
                    &__header {
                        grid-column: 2/3;
                        grid-row: 1/2;
                        display: block;
                    }
                    &__info {
                        grid-column: 2/3;
                        grid-row: 2/3;
                    }
                    &__include--mobile {
                        display: block;
                        padding-top: 0;
                        grid-column: 2/2;
                        grid-row: 1/4;
                        .checkbox {
                            align-items: center;
                        }
                    }
                    &__price--mobile {
                        display: block;
                        padding-top: 15px;
                        grid-column: 2/3;
                        grid-row: 3/4;
                    }
                    &__sku {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        column-gap: unset;
                    }
                    &__price--desktop {
                        display: none;
                    }
                    .product-image {
                        width: 80px;
                        height: 107px;
                    }
                    .item-color {
                        .color-value {
                            font-family: $font-regular;
                            font-size: 14px;
                            line-height: 22px;
                            letter-spacing: -0.45px;
                        }
                        .item-color-swatches {
                            grid-template-columns: repeat(5, 47px);
                            .color-swatch input[type="radio"] + label {
                                height: 25px;
                                width: 47px;
                            }
                        }
                        
                    }
                    .out-of-stock-section {
                        margin-top: 10px;
                        &.--desktop {
                            display: none;
                        }
                        &.--mobile {
                            display: block;
                            grid-column: 2/3;
                        }
                    }
                }
            }
            
            .include-button {
                .button {
                    width: 100%;
                }
            }
        }

            &__display-discount {
              display: flex;
              gap: 3px;
              margin-top: 3px;
            }
            &__percent-off {
                order: 2;
                text-align-last: right;
                margin-left: 5px;
            }
    }
}

@media only screen and (max-width: 767px) {
    .required-item {
        &__left-section {
            padding: 60px 18px 50px 18px;
            .item-details {
                column-gap: 20px;
            }
        }
        &__right-section {
            padding: 30px 18px 25px 18px;
            .product-line-items {
                .line-item {
                    column-gap: 40px;
                    .item-color {
                        .item-color-swatches {
                            grid-template-columns: repeat(5, 47px);
                        }
                    }
                    .out-of-stock-section {
                        &.--mobile {
                            display: block;
                            text-align: right;
                            grid-column: 1/3;
                        }
                    }
                    .back-in-stock {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 480px) {
    .required-item{  
        &__right-section{
            .include-button{
                padding-bottom: 50px;
            }
        } 
    }
}

