.features-technologies {
  background-color: $color-charcoal;
  padding: 140px 0;
  .container {
    max-width: 90%;
  }
  .collapsible-accordion {
    max-width: 1260px;
    margin: 0 auto;
    &:first-child {
      border-top: 1px solid $color-dark-gray;
    }
    .Collapsible {
      border-bottom: 1px solid $color-dark-gray;
      .Collapsible__trigger {
        padding-top: 25px;
        padding-bottom: 40px;
        .collapsible__header {
          align-items: center;
          span {
            font-family: $font-light;
            font-size: 60px;
            line-height: 1.13;
            letter-spacing: -3.1px;
            color: $color-white;
          }
          .plus {
            width: 30px;
            height: 30px;
            .line {
              background: $color-white;
              &.line-2 {
                transform: translateX(-15px);
              }
            }
          }
        }
      }
    }
  }
  &__content {
    padding-top: 20px;
    padding-bottom: 70px;
    .product-detail-title {
      font-family: $font-medium;
      color: $color-white;
      line-height: 1.4;
      font-size: 20px;
      padding-bottom: 20px;
      letter-spacing: -0.4px;
    }
  }
  &__description {
    font-family: $font-regular;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-light-gray;
    padding-right: 10%;
  }
  &__icons {
    margin-top: 30px;
    img {
      margin-right: 20px;
      height: 65px;
      width: 65px;
    }
  }
  &__feature-list {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 1.6rem;
  }
  &__list-item {
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-light-gray;
  }

  .Collapsible__contentInner {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .features-technologies {
    &__content {
      padding-bottom: 50px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .features-technologies {
    padding: 0;
    .kf-react-container {
      padding: 0 !important;
    }
    .collapsible-accordion {
      padding: 0 2pc !important;
      border-bottom: 1px solid $color-slate;
      .Collapsible {
        .Collapsible__trigger {
          &.is-closed {
            padding-bottom: 20px;
          }
          .collapsible__header {
            span {
              font-size: 40px;
              line-height: 1.15;
              letter-spacing: -2.2px;
            }
          }
        }
      }
    }
    .collapsible-accordion {
      &:last-child {
        border: none;
      }
    }
    .collapsible-accordion .Collapsible {
      border-bottom: none;
    }
  }
}
@media only screen and (max-width: 768px) {
  .features-technologies {
    padding: 0;
    border-bottom: 1px solid $color-slate;
    .collapsible-accordion {
      .Collapsible {
        .Collapsible__trigger {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      &:last-child {
        .Collapsible {
          border-bottom: none;
        }
      }
    }
    &__content {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    &__feature-list {
      padding-left: 24px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .features-technologies {
    .collapsible-accordion {
      padding: 0 1pc !important;
    }
    &__icons {
      margin-bottom: 50px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .features-technologies {
    .container {
      padding: 0 90px;
    }
  }
}
