.address-form {
    &__required-fields {
        display: inline-block;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin-bottom: 50px;
    }
    &__select-dropdown {
        margin-bottom: 20px;
    }
    &__title{
        color: #494949;
        font-size: 36px;
        font-weight: 300;
        letter-spacing: -1.7px;
        line-height: 44px;
    }
    &__addressTitle{
        display: flex;
        justify-content: space-between;
        color: #212121;
        font-family: "Helvetica Now Text W05 Medium";
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.4px;
        line-height: 28px;
        padding-top: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        border-top:1px solid #CDCDCD;
    }
    &__removeaddress{
        color: #757575; 
        cursor: pointer;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        text-align: right;
        text-decoration: underline;
        &:hover{
            text-decoration: dashed;
        }
    }
    &__addaddress{
        display: flex;
        align-items: center;
        padding-top: 32px;
        cursor: pointer;
        padding-bottom: 32px;
        margin-top:28px;
        margin-bottom: 50px;
        border-top: 1px solid $color-light-gray;
        border-bottom: 1px solid $color-light-gray;
        img {
            height: 14px;
            width: 14px;
            margin-right: 10px;
        }
        span {
            font-family: "Helvetica Now Text W05 Medium";
            color: $color-charcoal; 
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.4px;
            line-height: 28px;
        }
    }
    &__set-default {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        .checkbox {
            margin-bottom: 0;
            input:checked ~ span {
                color: $color-charcoal;
            }
        }
        .input-field__wrapper {
            margin-bottom: 0;
        }
        &-check {
            display: inline-flex;
            height: 60px;
        }
        &.--hide-label {
            margin-top: 10px;
            .address-form__set-default-check {
                height: auto;
            }
        }
    }
    &__label-input {
        margin-top: 20px;
    }
    &__submit-button {
        margin-top: 50px;
        float: right;
    }

    &__auth {
        padding: 30px;
        border-radius: 5px;
        background-color: $color-off-white;
        margin-bottom: 30px;

        &-title {
            font-family: $font-medium;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.4px;
            color: $color-charcoal;
            margin-bottom: 5px;
        }
        &-desc {
            font-family: $font-regular;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.65px;
            color: $color-slate;
            margin-bottom: 30px;
        }
        &-cta {
            margin-top: 10px;
            display: flex;
            gap: 20px;
        }
    }

    .left-half-field {
        padding-right: 10px;
    }
    .right-half-field {
        padding-left: 10px;
    }
}

.account-address{
    &__modal {
        width: 420px;
        .checkbox {
            margin-bottom: 27px;
            margin-top: 5px;
            align-items: center;
            input:checked ~ span {
                color: $color-dark-gray;
            }
        }
        &__close-icon {
            img {
                color: $color-charcoal;
            }
        }
    }
}



@media screen and (max-width: 768px) { 
    .account-address{
        &__modal {
            width: 100%;
            .checkbox {
                margin-bottom: 28px;
                margin-top: 13px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .account-address__modal{ padding-bottom: 50px; }
}