.tooltip__container {
    background-color: $white;
    box-shadow: none;
    padding: 16.17px 14.33px 15px 16.67px;
    border: 1px solid rgba(216,213,211,0.31);
    border-radius: 1px;
    max-width: 240px;
    span {
        font-stretch: normal;
        font-style: normal;
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 12px;
        letter-spacing: 0.17px;
        line-height: 16px;
    }
    .--label {
        color: $text-medium-grey;
    }
}
