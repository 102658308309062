// teaser-sizes start here
.teaser {
    padding: 0;
    width: 100%;
    .cmp-teaser {
        width: 100%;
        display: block;
        &__image{
            width:initial;
            .cmp-image{
                &__image{
                    img{
                        width:100%;
                        height:100%;
                        object-fit: cover;
                    }
                }
            }
        }
        &__action-link{
            width: auto;
        }
    }
}

.teaser-small-1 {
    width: 12.813rem;
    img {
        width: 100%;
    }
}
.teaser-small-2 {
    width: 18.75rem;
    @include breakpoint(tab-max) {
        width: 15.375rem;
    }
    img {
        width: 100% ;
    }
}
.teaser-small-3 {
    width: 18.75rem;
    @include breakpoint(tab-max) {
        width: 13.5rem ;
    }
    img {
        width: 100% ;
    }
}
.teaser-medium-1 {
    width: 26.063rem;
    @include breakpoint(tab-max) {
        width: 19.25rem ;
    }
    img {
        width: 100%;
    }
}
.teaser-medium-2 {
    width: 26.25rem;
    @include breakpoint(mobile) {
        width: 100%;
    }
    img {
        width: 100%;
    }
}
.teaser-medium-3 {
    width: 27.5rem ;
    @include breakpoint(tab) {
        width:14.188rem ;
    }
    @include breakpoint(mobile) {
        width:100% ;
    }
    img {
        width: 100% ;
    }
}
.teaser-medium-4 {
    width: 30rem;
    @include breakpoint(tab-max) {
        width: 13.5rem;
    }
    img {
        width: 100% ;
    }
}
.teaser-large-1 {
    width: 37.5rem;
    @include breakpoint(tab-max) {
        width: 17.438rem;
    }
    img {
        width: 100% ;
    }
}
.teaser-large-2 {
    width: 45rem;
    @include breakpoint(tab-max) {
        width: 20.375rem;
    }
    img {
        width: 100%;
    }
}
.content-left{
    text-align:left;
    .cmp-teaser{
        text-align:left;
        &__content, &__title, &__pretitle, &__description{
            text-align:left;
        }
    }
}
// teaser-sizes End here
// Ann  Sacks Category Landing Template Banner
.teaser {
    .cmp-teaser{
        padding:0;
        margin: 0;
        &__content{
            background-color:transparent;
            padding:0;
            margin: 0;
        }
        &__title{
            padding:0;
            margin: 0;
            h1, h2, h3, h4, h5, h6, p {
                margin: 0;
                font-weight: normal;
            }
        }
        &__description{
            padding:0;
            margin: 0;
            h1, h2, h3, h4, h5, h6, p {
                margin: 0;
                font-weight: normal;
            }
        }
        &__image{
            padding:0;
            margin: 0;
        }
        &__icons{
            .share{
                z-index: 0;
            }
        }
        a{
            visibility: visible;
        }
    }
    .cmp-img-caption {
        p {
            padding:0;
            margin: 0;
            a {
                color: $text-dark-grey !important;
                text-decoration: underline !important;
            }
        }
    }
}

.pre-title-only {
    &.teaser {
        padding: 0;

        .cmp-teaser {
            &__pretitle {
                font-family: $lato-regular;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 2px;
                color: $text-dark-grey;
                text-transform: uppercase;
                padding: 31px 0 59px;
                @include breakpoint(tab-max) {
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    padding: 26px 0 27px;
                }
                @include breakpoint(mobile-portrait) {
                    padding: 25px 0 35px;
                }
            }
        }
    }
}
// Ann  Sacks Category Landing Turn this space Teaser Style
.description-only {
    &.teaser {
        padding: 0;

        .cmp-teaser {
            &__description {
                padding: 0;

                p {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0;
                    color:$text-light-grey;
                    padding: 40px 0 98px;
                    width: 420px;
                    @include breakpoint(tab-max) {
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        padding: 22px 0 38px;
                        width: 320px;
                    }
                    @include breakpoint(mobile-portrait) {
                        width: 320px;
                        padding: 20px 0 35px;
                    }
                }
            }
        }
    }
}
// Ann  Sacks Category Landing Transform your space Teaser Style
.img-top-desc-bottom-small-font {
    &.teaser {
        padding: 0;

        .cmp-teaser {
            &__title {
                font-family: $lato-regular;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0;
                color:$text-dark-grey;
                padding: 27px 0 12px;
                @include breakpoint(tab-max) {
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    padding: 31px 0 14px;
                }
                @include breakpoint(mobile-portrait) {
                    padding: 30px 0 14px;
                }
            }

            &__description {
                padding: 0;

                p {
                    font-family: $lato-regular;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    letter-spacing: normal;
                    color: $text-medium-dark-grey;
                    padding: 0 0 2.375rem;
                    width: 20.5rem;
                    margin: 0;
                }
            }
            &__action-link {
                border-radius: 1px;
                padding: 0;
                font-family: $lato-regular;
                font-size: 0.688rem;
                text-transform: uppercase;
                line-height: 1.5rem;
                letter-spacing: 0.114rem;
                color: $color-light-blue;
                border: 0;
                border-bottom: 1px solid $color-light-blue;
                display: initial;
                background: none;
                box-shadow: none;

                &:hover {
                    color: $color-light-blue;
                    text-decoration: none;
                }
            }
        }
}
}
// Article card style
.flex-container {
    padding: 100px 0;
    @include breakpoint(tab-max) {
        padding: 50px 0;
    }
}
// Article content style
.teaser {
    &.article-right-image {
        .cmp-teaser {
            flex-direction: row-reverse;
            @include breakpoint(tab) {
                flex-direction: row-reverse;
            }
            @include breakpoint(mobile) {
                flex-direction: column;
            }

            &__content {
                @include breakpoint(tab) {
                    padding: 0 5% 0 0;
                }
            }
        }
    }

    &.article-left-image {
        // padding: 6.75rem 13.625rem 6.75rem 6.875rem;
        @include breakpoint(tab) {
            padding: 3.125rem 2rem;
        }
        @include breakpoint(mobile) {
            padding: 1.25rem 1.125rem 3.75rem;
        }

        .cmp-teaser {
            .cmp-teaser__image {
                @include breakpoint(mobile) {
                    width: 100%;
                }
            }

            &__content {
                padding: 0 0 0 10.25rem;
                @include breakpoint(tab) {
                    padding: 0 0 0 5%;
                }
                @include breakpoint(mobile) {
                    padding: 3.125rem 0 0;
                }
            }
        }
    }
}
.content-center{
    .cmp-teaser{
        text-align: center;
    }
}

//teaser focus
.teaser .cmp-teaser__title a:focus:not(:focus-visible), .cmp-teaser__description a:focus:not(:focus-visible), .cmp-img-caption a:focus:not(:focus-visible), .teaser:focus:not(:focus-visible), .cmp-teaser__image a:focus:not(:focus-visible), .cmp-teaser__pretitle a:focus:not(:focus-visible),
.teaser .cmp-teaser__title a:focus-visible, .cmp-teaser__description a:focus-visible, .cmp-img-caption a:focus-visible, .teaser:focus-visible, .cmp-teaser__image a:focus-visible, .cmp-teaser__pretitle a:focus-visible,
.teaser .cmp-teaser__title a:focus, .cmp-teaser__description a:focus, .cmp-img-caption a:focus, .teaser:focus, .cmp-teaser__image a:focus, .cmp-teaser__pretitle a:focus {
    opacity: 1;
    z-index: 999;
    outline: 1px dashed currentcolor!important; //Overwrite default style for ADA focu 
    outline-offset: -2px;
    text-decoration: underline;
    position:initial;
}

.teaser .cmp-teaser .cmp-teaser__image  a{
    display: block;
}
.teaser .share-cmp {
    position: absolute;
    right: 255px;
    top: 35px;
}
.teaser.hero-banner-article-bottom .cmp-teaser__icons .share-cmp{
    display:none;
    right: 0;
}
.teaser .favorite, .share{
    cursor:pointer;
}
.teaser-content-hide {
    .cmp-teaser {
        &__content {
            display: none;
        }
    }
}
.container-events {
  .carousel-header {
    .cmp-teaser {
      &__content {
        width: 41.25rem;
        margin: 0 auto;
        padding: 4.375rem 0 3.125rem;
        text-align: center;
        background-color: transparent;
        @include breakpoint(mobile) {
            width: 100%;
          }
      }
      &__pretitle {
        p {
          font-size: 0.75rem;
          font-family: Lato-Regular;
          line-height: 1rem;
          letter-spacing: 0.125rem;
          padding-bottom: 0.625rem;
          text-transform: uppercase;
          color: $text-dark-grey;
        }
      }
      &__title {
        font-size: 24px;
        color: inherit;
        p {
          font-size: 2.625rem;
          line-height: 3.375rem;
          letter-spacing: -0.073125rem;
          font-family: PlayfairDisplay-Regular;
          font-weight: 400;
          padding-bottom: 1.25rem;
          padding-top: 0px;
          color: $text-dark-grey;
          @include breakpoint(mobile) {
            font-size: 1.75rem;
            line-height: 2.125rem;
            letter-spacing: -.073125rem;
          }
        }
      }
      &__description {
        color: inherit;
        margin: 0;
        padding: 0;
        p {
          font-size: 1.125rem;
          font-family: Lato-Regular;
          line-height: 1.75rem;
          letter-spacing: normal;
          color: $text-medium-dark-grey;
          padding: 0 40px;
        }
      }
    }
  }
  .teaser-event-card-v1 {
    .cmp-teaser {
      .cmp-teaser__image {
        padding: 0 !important;
        margin: 0;
        width: 100%;
        position: unset !important;
        .cmp-image {
          @include breakpoint(desktop-small) {
            max-width: 330px;
            max-height: 248px;
          }
        }
      }
      .cmp-teaser__content {
        padding: 0px !important;
        margin: 1.875rem 0 0 1.875rem;
        text-align: left;
        width: 14.75rem;
        @include breakpoint(tab-max) {
            margin:1.25rem 0 0 1.25rem !important;
          }
        .cmp-teaser__pretitle {
          p {
            color: $text-dark-grey;
            background-color: transparent;
            font-size: 1pc;
            font-family: Lato-Regular;
            background-color: $white;
            letter-spacing: normal;
          }
        }
        .cmp-teaser__title {
          margin: 0;
          padding: 0;
          background-color: $white;
          max-width: 100%;
          min-height: 90px;
          @include breakpoint(mobile) {
           min-height: 47px;
          }
          p {
            font-size: 1.125rem;
            font-family: Lato-Regular;
            line-height: 1.75rem;
            letter-spacing: normal;
            padding-bottom: 0.625rem;
            color: $text-dark-grey !important;
            padding-top: 0px;
          }
        }
        .cmp-teaser__action-container {
            @include breakpoint(mobile) {
                position: unset !important;
                padding-bottom: 3.125rem;
                background-color: $white;
                
              }
          .cmp-teaser__action-link {
            font-size: 0.6875rem;
            font-family: $lato-regular;
            line-height: 0.75rem;
            letter-spacing: 0.114375rem;
            color: #384451 !important;
            border-bottom: 1px solid #384451;
            padding-bottom: 0.4375rem;
            background: none;
            visibility: visible;
          }
        }
      }
    }
  }
}
