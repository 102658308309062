.article-headline{
    padding: 4.375rem 0 3.125rem;
    width: 100%;
    flex: 0 0 100%;
    @include breakpoint(tab-max){
        padding: 3.125rem 0 2.5rem 0;
    }
    .cmp-teaser{
        &__content{
            text-align: center;
            width: 42.188rem;
            margin: 0 auto;
            background-color:transparent;
            @include breakpoint(tab-max){
                width:14.813rem;
            }
            .cmp-teaser__pretitle{
                color: $text-dark-grey;
                text-transform: uppercase;
            }
        }
        &__pretitle{
            @include ann-describtion(0.875rem, $lato-regular, 1rem);
            letter-spacing:0.125rem;
            color:$text-dark-grey;
            @include breakpoint(tab-max){
                @include ann-describtion(0.75rem, $lato-regular, 1rem);
            }
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(0.875rem, $lato-regular, 1rem);
                letter-spacing:0.125rem;
                color:$text-dark-grey !important;
                @include breakpoint(tab-max){
                    @include ann-describtion(0.75rem, $lato-regular, 1rem);
                }  
            }
        }
        &__title{
            h1,h2,h3,h4,h5,h6,p{
                font-family: $playfair-regular;
                font-size: 3.375rem;
                letter-spacing: -1px;
                line-height: 3.875rem;
                color:$text-dark-grey !important;
                padding-top: 0;
                @include breakpoint(tab-max){
                    font-size: 1.75rem;
                    letter-spacing: -0.073125rem;
                    line-height: 2.125rem;
                    padding-top: .625rem;
                }
            } 
        }
    }
}
.three-up-article-module{
    &.teaser {
    margin-right:auto;
    padding: 0 0 4.375rem;
    width: 31.88%;
    @include breakpoint(tab){
        width: 32.48%;
        padding: 0 0 3.125rem;
    }
    @include breakpoint(mobile){
        margin-bottom:2.5rem;
        width:100%;
        padding:0;
    }
    &:last-child{
        margin-right:0;
        @include breakpoint(mobile){
            margin-bottom: 3.125rem;
        }
    }
    .cmp-teaser{
        &__content{
            width:20.5rem;
            text-align:left;
            margin-top: 1.875rem;
            background-color: transparent;
            @include breakpoint(tab){
                width:100%;
                margin-top: 1.25rem;
            }
            @include breakpoint(mobile){
                width: 96.8%;
                margin-top: 1.25rem;
            }
        }
        &__title{
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(1.375rem, $lato-regular,2rem);
                color:$text-dark-grey !important;
                padding:0;
                font-weight:normal;
                letter-spacing: 0;
                @include breakpoint(tab-max){
                    @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                }
            }
        }
        &__description{
            margin:.625rem 0 0;
            p{
                @include ann-describtion(0.875rem, $lato-regular,1.25rem);
                color:$text-medium-dark-grey !important;
                letter-spacing: 0;
                padding:0;
           }
        }
        &__action-container{
            @include breakpoint(mobile){
                padding-bottom: 0;
            } 
            .cmp-teaser__action-link{
                padding:0 0 .4375rem 0;
                margin-top: 1.25rem;
                font-size: .6875rem;
                line-height: 1rem;
                color:$color-light-blue;
                border-color:$color-light-blue;
            }          
        }
    }
    }
}
