
//gbh code below
.collapsed-widget {
    border-radius: 5px;
    background-color: $color-medium-blue;

    &__compare-details {
      .collapsed-widget__number {
        height: 24px;
        width: 10px;
        color: $color-white;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
  
      .collapsed-widget__products-compare-text {
        color: $color-white;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
  
      .collapsed-widget__compare-text {

      }
    }
    &__collapse-icon {
      img {

      }
    }
  }
  
  @media screen and (orientation:landscape) and (max-width: 900px){
    .collapsed-widget {

  
      &__compare-details {
        .collapsed-widget__number {

        }
  
        img {

        }
  
        .collapsed-widget__compare-text {

        }
  
        .collapsed-widget__products-compare-text {

        }
      }
    }
  }
  @media only screen and (orientation: portrait) and (max-width: 767px){
    .collapsed-widget-wrapper{

    }
  }
  @media screen and (max-width: 480px) {
    .collapsed-widget {

  
      &__content-left {
        .collapsed-widget__number {

        }
  
        img {

        }
  
        .collapsed-widget__compare-text {

        }
  
        .collapsed-widget__products-compare-text {

        }
      }
    }
  }
  