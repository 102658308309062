.payment-info {
  &__title {
    margin: 8px -4px 0 10px;
    padding: 5px 0;
  }

  &__body {
    margin: 10px;
  }

  &__sub-header {
    margin-bottom: 5px;
  }

  &__address,
  &__method {
    list-style: none;
    margin-bottom: 20px;
    margin-left: 0;
  }

  &__value {
    margin-bottom: 20px;
  }

  &__list {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .payment-info {
    &__title {
      margin: 8px -4px 0 -4px;
      padding: 5px 0px 15px 10px;
      border: 0px solid #999;
      border-bottom-width: 1px;
    }

    &__body {
      margin-top: 15px;
    }
  }
}
