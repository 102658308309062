.moodboard-teaser {
    width: 30.43%;
    margin: 0;
    padding: 0 0 3.75rem;
    margin-bottom: unset !important;
    @include breakpoint (desktop-xlg) {
        max-width: 34.5625rem;
    }
    @include breakpoint(tab){
        width: 45.32%;
        padding: 0 0 2.5rem;
    }
    @include breakpoint(mobile){
        width:100%;
        padding: 0 0 2.5rem;
    }
    .cmp-teaser{
        position: relative;
        max-width: 34.5625rem;
        @include breakpoint (tab-max) {
            max-height: 26.4375rem ;
        }
        &__content {
            padding-bottom: 0;
        }
        &__image{
            .cmp-image{
                @include breakpoint(desktop-small){
                    min-height: 32.8125rem;
                }
                @include breakpoint (desktop-xlg) {
                    min-height: 43.1875rem;
                }
                @include breakpoint (tab-max){
                    min-height: 26.4375rem;
                }
                .cmp-image__image {
                    min-height: inherit;
                img {
                    min-height: inherit;
                }
            }
            }
        }
        &__icons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            align-content: center;
            column-gap: .3125rem;
            position: absolute;
            top: .625rem;
            right: .625rem;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s,opacity .5s linear;
            .favorite {
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                height: 2.25rem;
                width: 2.25rem;
                background: $white;
                border-radius: .3125rem;
                span {
                    display: none;
                }
                &:before {
                    font-family: icomoon;
                    color: $text-medium-dark-grey;
                    margin: 0;
                }
            }
            .share {
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                height: 2.25rem;
                width: 2.25rem;
                background: $white;
                border-radius: .3125rem;
                &:before {
                    font-family: icomoon;
                    color: $text-medium-dark-grey;
                    margin: 0;
                }
                span {
                    display: none;
                }
            }
        }
        .share-cmp{
            right: 0;
        }
    }
    &:hover{
        .cmp-teaser__icons{
            visibility: visible;
            opacity: 1;
        }
    }
}