//== Container main content style, used on page template
main.container {
    padding: .5em 1em;
}
:root, html {
    font-size: 16px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
@media (min-width: 1024px) and (max-width: 1379px){
    :root, html {
        font-size: 14px;
    }
}
@media (min-width: 1121px) and (max-width: 1340px){
    :root, html {
        font-size: 13px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1120px){
    :root, html {
        font-size: 12px !important;
    }
}

// Chat Overwrite CSS

.dockableContainer{
    .embeddedServiceHelpButton {
        header{
            h2[embeddedService-chatHeader_chatHeader]{
                color:$white !important;
            }
            button[embeddedService-chatHeader_chatHeader]{
                height: 16px;
                min-height: 16px;
                width: 16px;
                min-width: 16px;
                margin:0 0 15px 15px;
            }
        } 
    }
    .sidebarBody{
        .activeFeature{
            .formContent{
                .uiInputSelect{
                    select.slds-style-select select{
                        padding-right: 32px;
                    }
                }
                .embeddedServiceSidebarFormField .uiInputSelect:after {
                    top: calc(50% + 10px);
                }
            }
        }
    }
}