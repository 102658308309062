.kf-react-datepicker {

    .react-datepicker__triangle {
        display: none;
    }
    
    .react-datepicker-popper {
        padding-top: 0;
        margin-top: -15px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        z-index: 999;
    }
    
    .react-datepicker__header {
        background: #fff;
        border-bottom: none;
        padding: 25px 0 0 0;
    }

    .react-datepicker__navigation {
        top: 25px;
    }

    .react-datepicker__navigation--previous {
        right: 60px;
        left: inherit;
    }

    .react-datepicker__navigation--next {
        right: 25px;
    }

    .react-datepicker__month {
        margin: 20px 25px 25px 25px;
    }
   
    .react-datepicker-time__header, 
    .react-datepicker-year-header, 
    .react-datepicker__current-month {
        margin-top: 0;
        color: $color-dark-gray;
        font-weight: normal;
        font-size: 20px;
        font-family: 'Helvetica Now Text W05 Regular';
        text-align: left;
        margin-left: 25px;
    }

    .react-datepicker__current-month {
        margin-bottom: 26px;
    }

    .react-datepicker__month-read-view--down-arrow, 
    .react-datepicker__month-year-read-view--down-arrow, 
    .react-datepicker__navigation-icon:before, 
    .react-datepicker__year-read-view--down-arrow {
        border-color: $color-dark-gray;
        border-style: solid;
        border-width: 1px 1px 0 0;
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        top: 11px;
        width: 10px;
    }
    
    .react-datepicker {
        background-color: #fff;
        color: $color-dark-gray;
        border: 1px solid #fff;
        border-radius: 5px;
        display: inline-block;
        position: relative;
        font-family: 'Helvetica Now Text W05 Regular';
    }

    .react-datepicker__day, 
    .react-datepicker__day-name, 
    .react-datepicker__time-name {
        color: $color-dark-gray;
        display: inline-block;
        width: 32px;
        height: 32px;
        text-align: center;
        margin: 3px;
        font-family: 'Helvetica Now Text W05 Regular';
        font-size: 14px;
        line-height: 32px;
        letter-spacing: -.65px;
    }

    .react-datepicker__day--selected, 
    .react-datepicker__day:hover {
        border-radius: 0;
        background-color: $color-slate;
        color: #fff;
    }

    .react-datepicker__day--keyboard-selected, 
    .react-datepicker__day--outside-month {
        background: none;
        color: #757575;
    }

    button.react-datepicker__close-icon {
        height: 60px;
        width: 48px;
    }

    .react-datepicker__close-icon::after {
        background: none;
        color: $color-dark-gray;
        font-size: 24px;
        margin: auto;
        text-align: center;
        width: 32px;
        left: 0;
        right: 0;
        border-radius: 0;
        display: block;
        height: 32px;
        font-family: 'Helvetica Now Text W05 Light';
    }
 
    .react-datepicker__month-text--today, 
    .react-datepicker__quarter-text--today, 
    .react-datepicker__year-text--today {
        border-radius: 0;
        background-color: $color-slate;
        color: #fff;
    }

    .react-datepicker__day--disabled, 
    .react-datepicker__month-text--disabled, 
    .react-datepicker__quarter-text--disabled, 
    .react-datepicker__year-text--disabled,
    .react-datepicker__day--disabled:hover, 
    .react-datepicker__month-text--disabled:hover, 
    .react-datepicker__quarter-text--disabled:hover, 
    .react-datepicker__year-text--disabled:hover {
        cursor: default;
        background-color: transparent;
        color: #ccc;
    }
}

@media only screen and (max-width: 991px){
    .kf-react-datepicker {
        .input-field__prefix{
            display: flex;
        }
    }
}