@mixin common-width {
  z-index: 9999999999;
  width: 288px;
  height: 70px;
}
@mixin display-flex-cross-browser {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
body.pdp-image-gallery-modal-opened {
  overflow-y: hidden;
  .pdp-pdp-image-reel {
    display: none;
  }
}

/* responsive carousel style */
.pdp-image-gallery {
  position: relative;
  display: flex;

  ul,
  li {
    list-style: none;
    padding: 0;
  }
}

.pdp-image-reel {
  &__modal-image {
    height: 100%;
  }
  &__view {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    display: block;
  }

  &__image-thumb-view {
    display: flex;
    margin-left: 0;
    align-items: center;
    margin-bottom: 0;
    padding: 0 6.72%;
    margin-left: -2.6%;
    @media only screen and (min-width: 1920px) {
      padding: 0 130px;
      margin-left: -50px;
    }
    .pdp-image-reel__image-thumb {
      img {
        height: 80px;
        object-fit: cover;
        object-position: 50% 50%;
        mix-blend-mode: multiply;
      }
    }
    ul {
      li {
        list-style: none;
      }
    }
  }

  &__image-list {
    display: block;
    padding-bottom: 16px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
      cursor: default;
      @media print {
        mix-blend-mode: normal;
      }
      &.tap-image-zoom {
        display: none;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__image-stack-view {
    overflow: auto;
    transition: all 0.35s ease-in-out;
    .pdp-image-reel__link-more {
      font-family: $font-medium;
      color: $color-slate;
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 22px;
      border: 1px solid $color-slate;
      width: 140px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-transition: all 0.5s ease, background-color 1.5s ease;
      -moz-transition: all 0.5s ease, background-color 1.5s ease;
      -o-transition: all 0.5s ease, background-color 1.5s ease;
      transition: all 0.5s ease, background-color 1.5s ease;
      &:hover {
        transform: scale(0.95);
      }
    }
    @media print {
      transform: translateY(0px) !important;
      overflow: visible !important;
    }
  }

  &__thumb {
    display: flex;
    margin-left: 40px;
    padding-top: 45px;
  }

  &__image-thumb-list {
    display: inline-block;
    padding-right: 16px;
    &:last-child {
      padding-right: 0;
    }
  }

  &__image-thumb {
    width: 80px;
    img {
      width: 80px;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__image-thumbSelected {
    border: 1px solid $color-dark-gray;
    width: 80px;
    padding: 2px;
    border-radius: 5px;
    height: 80px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__image-view {
    display: block;
    margin-top: 9px;
    text-align: right;
    cursor: pointer;
    float: right;
  }
  &__image-stack-list {
    margin-left: 0px;
  }

  &__link-more {
    font-size: 14px;
    font-weight: 500;
    color: #757577;
    display: block;
    background: none;
    width: auto;
    border: none;
    .view-more-icon {
      display: block;
      margin: 11px auto;
    }
  }
  &__horizontal-gallery {
    .pdp-image-reel {
      &__image-stack-list,
      &__thumb {
        display: flex;
        margin-bottom: 0;
        @include breakpoint(tab-max) {
          display: block;
          padding-bottom: 40px;
        }
      }
      &__image-stack-view,
      &__thumb {
        &::-webkit-scrollbar {
          display: none;
        }
        &.os-theme-dark {
          &.os-host-overflow {
            padding-bottom: 35px;
            margin-left: 2.6%;
            @media only screen and (max-width: 1280px) {
              padding-bottom: 25px;
              margin-left: 2.54%;
            }
            @media only screen and (min-width: 1920px) {
              margin-left: 50px;
            }
          }
          .os-scrollbar {
            padding: 0px;
            &-handle {
              background-color: $color-medium-gray;
            }
            &-horizontal {
              right: 0;
              height: 5px;
              padding: 0 6.72%;
              margin-left: -2.6%;
              margin-right: 0;
              @media only screen and (min-width: 1920px) {
                padding: 0 130px;
                margin-left: -50px;
              }
            }
          }
          .os-content {
            padding-left: 0px !important;
          }
        }
      }
      &__image-list {
        display: inline-flex;
        padding-right: 16px;
        padding-bottom: 0;
        &:last-child {
          padding-right: 0;
        }
        @include breakpoint(tab-landscape) {
          position: relative;
        }

        img,
        iframe {
          width: 100vw;
          max-width: 960px;
          height: 100%;
        }
        img {
          &.img-play-button {
            position: absolute;
            width: 64px;
            cursor: pointer;
            mix-blend-mode: initial;
            top: 45%;
            left: 45%;
            height: 64px;
          }

          @include breakpoint(tab-landscape) {
            &.tap-image-zoom {
              display: block;
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              text-align: center;
              z-index: 1;
              top: 44%;
              width: 223px;
              height: 58px;
              mix-blend-mode: unset;
              -moz-animation: cssAnimation 0s ease-in 3s forwards;
              -webkit-animation: cssAnimation 0s ease-in 3s forwards;
              -o-animation: cssAnimation 0s ease-in 3s forwards;
              animation: cssAnimation 0s ease-in 3s forwards;
              -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
            }
          }
        }
        &.pdp-image-reel__modal-image {
          img {
            height: auto;
            width: 700px;
            object-fit: cover;
          }
          iframe {
            height: auto;
            width: 700px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    &__horizontal-gallery {
      .pdp-image-reel {
        &__image-list {
          padding-right: 8px;
          &.pdp-image-reel__modal-image {
            img {
              width: 350px;
            }
            iframe {
              width: 350px;
            }
          }
        }
        &__thumb {
          padding-top: 25px;
        }
        &__image-thumb-list {
          padding-right: 8px;
        }
        &__image-thumb {
          width: 45px;
          img {
            height: 45px;
            width: 45px;
          }
        }
        &__image-thumbSelected {
          height: 45px;
          width: 45px;
        }
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    &__horizontal-gallery {
      .pdp-image-reel__slider-container {
        .pdp-video-wrapper {
          img {
            &.img-fluid {
              margin: -40px 0;
            }
          }
        }
        .slick-arrow {
          bottom: -40px !important;
          z-index: 9;
          top: unset;
          &.slick-disabled::before {
            color: $light-gray !important;
          }
        }
        .slick-prev {
          left: 20px;
        }
        .slick-next {
          right: 20px;
        }
        .slick-prev:before,
        .slick-next:before {
          color: $color-charcoal;
          content: "\e92c";
          font-size: 21px;
          justify-content: center;
          align-items: center;
          opacity: 1;
          display: flex;
          font-family: icomoon;
          transform: rotate(-180deg);
        }
        .slick-prev:before {
          transform: unset;
        }

        overflow: hidden;
        position: relative;
      }
      .pdp-image-reel__slider-list {
        overflow: initial;
        white-space: nowrap;
        transition: transform 0.3s ease-in;
        .pdp-image-reel__image-list {
          align-items: center;
          justify-content: center;
          display: inline-flex;
          padding-right: 0;
          .img-play-button {
            position: absolute;
            height: 55px;
            width: 64px;
            cursor: pointer;
            mix-blend-mode: initial;
          }
        }
      }
      .pdp-image-reel__slider-count {
        position: absolute;
        font-family: $font-regular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: $color-slate;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        bottom: 10px;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    &__image-stack-view {
      margin-left: 0px;
    }
    &__image-thumb-view {
      padding-left: 5px;
    }
  }
  @include breakpoint(mobile-potrait) {
    &__horizontal-gallery {
      .pdp-image-reel__slider-container {
        .pdp-video-wrapper {
          img {
            &.img-fluid {
              margin: 0;
              width: 100%;
              max-width: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
  }
}

.zoom-image-scroll {
  overflow: hidden;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
}
// zoom image css start
.image-overlay {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  overflow: auto;
  top: 0;
  padding: 0;
  height: 100vh;
  opacity: 1;
  visibility: visible;
  @media only screen and (max-width: 500px) {
    height: 100% !important;
    overflow: hidden !important;
  }
  @include breakpoint(desktop-small) {
    :focus-visible {
      outline: -webkit-focus-ring-color auto 4px;
      outline-color: #0071e3;
    }
  }
}
.hide-video-present {
  display: none;
}
[data-namespace="s7classic"].s7zoomview[cursortype="drag"] {
  cursor: grabbing !important;
}
[data-namespace="s7classic"].s7zoomview[cursortype="zoomin"],
[data-namespace="s7classic"].s7zoomview[cursortype="zoomin"] [role="button"],
.pdp-image-reel__image-display img {
  cursor: url("../images/zoom-image-icon.svg"), auto;
}

.pdp-image-reel__image-display img {
  @include breakpoint(tab-max) {
    cursor: pointer;
  }
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
.popup-content {
  width: 100%;
  height: 100%;
  display: flex;
  @include display-flex-cross-browser;
  justify-content: center;
  align-items: center;
}

.close-zoom-image {
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  z-index: 99999;
  @media only screen and (max-width: 1024px) {
    right: 20px;
  }
}

.iframe-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  @include display-flex-cross-browser;
  flex-direction: column;
  -webkit-box-direction: column;
  -moz-box-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  @include breakpoint(mobile-potrait) {
    background-color: $color-white;
  }
}

.iframe-container {
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    position: relative;
    top: 20px;
  }
}

.gallery {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 15px 0px 15px 20px;
  background-color: $color-white;
  z-index: 9;

  .slick-slide.cmp-carousel-slick__item:has(div:empty) {
    display: none !important;
  }

  @include breakpoint(mobile-potrait) {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding-right: 0;
    margin-left: 0;
    padding-left: 0;
    ::after {
      content: "";
      background-color: $color-white;
      width: 100%;
      height: 100%;
      left: -10px;
      right: 0;
      z-index: -1;
      position: absolute;
      bottom: 10px;
    }
  }
}
.zoom-image-product-section {
  .zoom-video {
    height: 100%;
    width: 100%;
    z-index: 9;
    background-color: $color-black;
    top: 15px;
    margin-bottom: 30px;
    @include breakpoint(mobile-potrait) {
      top: 8px;
      position: relative;
      background: linear-gradient($color-black, $color-white);
      margin-bottom: 0;
      iframe {
        height: 81vh;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    height: 50px;
    div#s7viewer_zoomOutButton:after {
      content: "";
      height: 50px;
      width: 50px;
      background: $color-white;
      position: relative;
      display: block;
      z-index: -1;
      right: 15px;
      border-radius: 100px;
      top: -15px;
    }

    .slick-arrow {
      display: none !important;
    }
  }

  .slick-track {
    margin: 0 auto;
    @media only screen and (min-width: 767px) {
      display: flex;
      @include display-flex-cross-browser;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (max-width: 970px) {
      @include display-flex-cross-browser;
    }
    @include breakpoint(mobile-potrait) {
      gap: 15px;
    }
  }
  .slick-list {
    max-width: 12in;
    margin: 0 auto;
  }
  @include breakpoint(desktop-xxxlg) {
    .slick-slider {
      max-width: 12in;
      margin: 0 auto;
    }
  }

  .slick-next {
    right: -40px;
  }
  .slick-prev {
    left: -40px;
  }
  .slick-disabled {
    cursor: default !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: $color-charcoal;
    content: "\e92c";
    font-size: 21px;
    justify-content: center;
    align-items: center;
    opacity: 1;
    display: flex;
    font-family: icomoon;
    transform: rotate(-180deg);
  }
  .slick-prev:before {
    transform: rotate(1deg);
  }
  @media only screen and (max-width: 1300px) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }

  .product-detail-page__left-sticky {
    position: relative !important;
    top: unset !important;
  }
  .s7zoomviewer {
    background-color: $color-off-white;
    height: 100% !important;
  }
  #s7viewer {
    #s7viewer_container {
      height: 100% !important;
    }
  }
  .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:last-child {
      margin-right: 0;
    }
    width: 61px !important;
    height: 61px !important;
    margin-right: 15px;
    @include breakpoint(mobile-potrait) {
      margin-right: 0;
    }
  }
  .image-wrapper {
    width: 61px !important;
    height: 61px !important;
    cursor: pointer;
    overflow: hidden;
    @include breakpoint(mobile-potrait) {
      outline: unset !important;
    }
    img {
      width: 60px;
      height: 60px;

      border: 1px solid $white;
      object-fit: cover;
      padding: 2px;
      @include breakpoint(mobile-potrait) {
        border: unset;
      }
      &:hover {
        border: 0.2px solid $slate;
      }
      @media (hover: none) {
        &:hover {
          border: unset;
        }
      }
    }
    &.imageActive {
      img {
        border: 1px solid $slate;
      }
    }
    &.zoom-video-section {
      position: relative;
    }
  }
  .zoom-video-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
  }

  .s7zoomviewer.s7touchinput .s7zoomview .s7iconeffect[media-type="standard"] {
    background: url("../images/pinch-image-zoom.svg"), auto !important;
    @include common-width;
  }

  .zoom-activated
    .s7zoomviewer.s7mouseinput
    .s7zoomview
    .s7iconeffect[media-type="standard"],
  .zoom-activated
    .s7zoomviewer.s7touchinput
    .s7zoomview
    .s7iconeffect[media-type="multitouch"],
  .zoom-activated
    .s7zoomviewer.s7touchinput
    .s7zoomview
    .s7iconeffect[media-type="standard"] {
    background: unset !important;
  }
  .s7zoomviewer.s7mouseinput .s7zoomview .s7iconeffect[media-type="standard"] {
    background: url("../images/click-image-zoom.svg") !important;
    @include common-width;
  }

  .s7zoomviewer.s7touchinput .s7zoomview .s7iconeffect[media-type="multitouch"],
  .s7zoomviewer .s7zoomview .s7iconeffect[media-type] {
    @include common-width;
    background: url("../images/pinch-image-zoom.svg") !important;
  }
  .s7button {
    &.s7fullscreenbutton {
      display: none;
    }
  }

  div#s7viewer_zoomInButton,
  div#s7viewer_zoomOutButton,
  div#s7viewer_zoomResetButton {
    width: 100%;
    display: block;
    position: relative;
    top: unset !important;
    left: 0 !important;
    bottom: unset !important;
  }
  div#s7viewer_zoomInButton {
    margin-bottom: 20px;
    z-index: unset;
    display: block !important;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0;
      opacity: 1 !important;
    }
  }
  div#s7viewer_zoomResetButton {
    top: 50px !important;
    z-index: unset;
  }
  div#s7viewer_zoomInButton:after {
    content: "";
    height: 95px;
    width: 50px;
    background: $color-white;
    position: relative;
    display: block;
    z-index: -1;
    right: 15px;
    border-radius: 100px;
    top: -15px;
    @media only screen and (max-width: 1024px) {
      height: 50px;
    }
  }
  @media only screen and (min-width: 810px) and (max-width: 815px) {
    .gallery {
      display: none;
    }
  }

  div#s7viewer_divcontainer {
    width: 50px !important;
    right: 8px !important;
    left: unset !important;
    float: right !important;
    position: absolute;
    z-index: 9999999999999999;
    top: 43% !important;
    @media only screen and (max-width: 1024px) {
      top: 25px !important;
      right: 69px !important;
    }
  }
  div#s7viewer_zoomResetButton:after {
    content: "";
    width: 50px;
    height: 51px;
    background: $color-white;
    position: relative;
    display: block;
    z-index: -1;
    right: 15px;
    border-radius: 100px;
    top: -16px;
  }
  .s7zoomviewer .s7zoominbutton[state],
  .s7zoomviewer .s7zoomoutbutton[state],
  .s7zoomviewer .s7zoomresetbutton[state] {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 20px !important;
    height: 20px !important;
  }
  .s7zoomviewer .s7zoominbutton[state] {
    background-image: url("../images/zoom-in.svg") !important;
    background-repeat: no-repeat;
    background-color: $color-off-white;
  }
  .s7zoomviewer .s7zoomoutbutton[state] {
    background-image: url("../images/zoom-out-action.svg") !important;
    background-repeat: no-repeat;
    @media only screen and (max-width: 1024px) {
      visibility: visible !important;
      z-index: unset;
    }
  }
  [data-namespace="s7classic"].s7zoomview[cursortype="reset"] {
    cursor: url("../images/zoom-out-action.svg"), auto;
  }
  .s7zoomviewer .s7zoomresetbutton[state] {
    background-image: url("../images/zoom-reset.svg") !important;
    background-repeat: no-repeat;
  }
  div#s7viewer_zoomResetButton[state="disabled"]:after,
  #s7viewer_zoomResetButton[state="disabled"] {
    background-image: url("../images/reset-inactive.png") !important;
    background-size: cover;
    background: unset;
    z-index: unset;
    background-repeat: no-repeat;
    cursor: default;
  }
  #s7viewer_zoomOutButton[state="disabled"] {
    background-image: url("../images/zoom-out-disable.svg") !important;
    background-repeat: no-repeat;
    cursor: default;
  }

  #s7viewer_zoomInButton[state="disabled"] {
    background-image: url("../images/zoom-disable.svg") !important;
    background-repeat: no-repeat;
    cursor: default;
  }
  #s7viewer_zoomView {
    background-color: $color-off-white;
  }

  @media only screen and (max-width: 1024px) {
    .s7zoomoutbutton,
    .s7zoomresetbutton {
      display: none !important;
    }
    .s7zoomviewer.s7size_small.s7touchinput .s7zoominbutton {
      visibility: visible !important;
    }
  }
}
@include breakpoint(mobile-potrait) {
  .video-open {
    .iframe-container {
      background: linear-gradient($color-black, $color-white);
    }
    .close-zoom-image {
      top: 0;
    }
  }
}

// zoom image css end
