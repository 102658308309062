#svg-viewer-iframe {
  width: 80vw;
  height: 80vh;
  border: none;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    width: 90vw;
  }
}

.fade.bg-color.show.tooltip.bs-tooltip-start {
  opacity: 1;
  div.tooltip-inner {
    background-color: $color-slate;
    margin-right: 0px;
    color: $white;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    border-color: $color-slate;
    opacity: 1;
  }
  .bottom {
    margin-top: 8px;
  }
}
