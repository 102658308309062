.teaser.image-right-text-center {
    padding: 70px 0;
    max-width: 1600px;
    margin: 0 auto;
    @include breakpoint(desktop-mid){
        padding: 4.375rem 36px;
    }
    @include breakpoint(tab-max) {
        padding: 3.125rem 0;
    }
    .cmp-teaser {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        @include breakpoint(desktop-lg){
            max-width:100%;
        }
        @include breakpoint(tab){
            align-items:flex-end;
        }
        @include breakpoint(mobile){
            flex-direction:column;
        }
        &__content {
            width: 35rem;
            background: none;
            padding: 0;
            box-sizing: content-box;
            max-width:1380px;
            margin:0 9.3125rem 0 6.875rem;
            @include breakpoint(desktop-mid){
                margin:0;
                width: 37rem;
            }
            @include breakpoint(tab-max){
                margin-left:0;
            }
            @include breakpoint(tab) {
                padding: 0 0 0 2rem;
                margin-right: 70px;
                width: 42%;
            }
            @include breakpoint(mobile) {
                width: 100%;
                padding:3.125rem 1.125rem 0;
                box-sizing:border-box;
                max-width: 100%;
                margin: 0;
            }
        }
        &__pretitle{
            *{
                @include ann-describtion(0.75rem,$lato-regular, 1rem);
                letter-spacing: 2px;
                color: $text-dark-grey;
                text-transform: uppercase;
                padding-bottom: 20px;
                @include breakpoint(mobile){
                    margin-bottom: 1.25rem;
                    padding-bottom: 0;
                }
            }
        }
        &__title {
            h1,h2,h3,h4,h5,h6,p{
                @include headingtitle_h3;
                margin-bottom:4.375rem;
                color: $text-dark-grey;
                @include breakpoint(tab-max){
                    @include headingtitle_h2;
                    margin-bottom:2.5rem;
                }
                @include breakpoint(tab){
                    letter-spacing:-0.125rem;
                }
            }
        }
        &__description {
            width: 16.375rem;
            margin-bottom: 30px;
            @include breakpoint(tab) {
                width: 21.1875rem;
            }
            @include breakpoint(mobile){
                width:100%;
            }
            p {
                @include ann-describtion(1rem,$lato-regular, 1.5rem);
                letter-spacing: 0;
                color: $text-medium-dark-grey;
                margin-bottom:0;
                @include breakpoint(tab) {
                    line-height: 1.5rem;
                }
            }
        }
        &__image {
            @include breakpoint(desktop-small){
                min-height: 57.1875rem;
                width: 100%;
                max-width: 55.625rem;
            }
            @include breakpoint(tab){
                min-width: 44%;
                .cmp-teaser__image{
                    width: unset;
                }
            }
            @include breakpoint(mobile){
                margin-left: 1.25rem;
                min-width: -webkit-fill-available;
            }
            .cmp-image{
                min-height: inherit;
                @include breakpoint(mobile){
                    padding:0;
                }
                .cmp-teaser__image{
                    margin: 0;
                }
            }
        }
        .cmp-teaser__action-link{
            font-size:12px;
            line-height:16px;
            letter-spacing:2px;
            padding: 0 27px;
            height: 60px;
            align-items: center;
            font-family: $lato-regular;
            @include breakpoint(tab-max){
                color:$color-light-blue;
                border-color:$color-light-blue;
            } 
        }
        &__action-link{
            color:$color-medium-blue;
        }
    }
}