.order-history {
  padding: 0 10px;

  &__header {
    margin-bottom: 40px;
  }

  &__filter {
    padding-top: 30px;

    &--name {
      width: 58.33333%;
      display: inline-block;
    }
  }

  &__select {
    width: 41.66667%;
    display: inline-block;
    padding: 0 10px;
    position: relative;

    &__value {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 10px;
      line-height: 30px;
    }

    &__list {
      display: none;

      &--show {
        display: block;
        position: absolute;
        left: 10px;
        width: calc(100% - 20px);
        background-color: #fff;
      }
    }

    &__options {
      border: 1px solid #ccc;
      border-top-width: 0px;
      width: 100%;
      padding: 10px;
      line-height: 30px;

      &:hover {
        background-color: #f2f2f2;
      }

      &.selected {
        background-color: #e5e5e5;

        &:hover {
          background-color: #e5e5e5;
        }
      }
    }
  }

  &__table {
    width: 100%;
  }

  &__thead, &__tbody {
    &__data {
      padding: 20px 10px;
      width: 50%;

      &--total, &--status, &--details {
        display: none;
      }
    }
  }

  &__thead {
    border-bottom: 1px solid #ccc;

  }
}

@media only screen and (min-width: 980px), print {
  .order-history {
    &__header {
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    &__title {
      width: 66.6667%;
      float: left;
    }

    &__filter {
      float: right;
      width: 33.3333%;
      padding-top: 0;
    }

    &__thead, &__tbody {
      &__data {
        padding: 20px 10px;
        width: 16.66667%;

        &--total, &--status, &--details {
          display: table-cell;
        }
      }
    }
  }
}
