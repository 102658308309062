.artist-edition-vertical-img {
    .cmp-img-caption {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: $text-dark-grey;
        }
    }
}

.artist-edition-square-img {
    .cmp-img-caption {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: $text-dark-grey;
        }
    }

    .cmp-teaser {
        &__image {
            .cmp-image {
                @include breakpoint(mobile) {
                    zoom: 1.0019;
                }
            }
        }
    }
}

.content-right-with-number {
    padding: 70px 36px;

    @include breakpoint(tab) {
        padding: 50px 32px;
    }

    @include breakpoint(mobile) {
        padding: 50px 18px;
    }

    .cmp-teaser {
        max-width: 1380px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        @include breakpoint(tab) {
            align-items: center;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            column-gap: 0;
        }

        &__content {
            width: 39.13%;

            @include breakpoint(tab) {
                width: 45.45%;
                padding-left: 0;
            }

            @include breakpoint(mobile) {
                width: 100%;
            }
        }

        &__pretitle {
            @include headingtitle_h4;
            display: flex;
            align-items: center;

            &::after {
                content: '';
                width: 100%;
                border-bottom: 1px solid $cool-dark-grey;
                margin-top: 1rem;
                margin-left: 2.5rem;

                @include breakpoint(tab-max) {
                    margin-left: 1.25rem;
                }
            }

            @include breakpoint(tab-max) {
                @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                letter-spacing: -.073125rem;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include headingtitle_h4;
                display: flex;
                align-items: center;

                @include breakpoint(tab-max) {
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                    letter-spacing: -.073125rem;
                }
            }
        }

        &__title {
            @include breakpoint(tab) {
                padding-right: 1.875rem;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include headingtitle_h3;
                padding-top: 3.125rem;

                @include breakpoint(tab-max) {
                    letter-spacing: -0.0731rem;
                    padding-top: 1.5625rem;
                }
            }
        }

        &__description {
            padding-top: 3.125rem;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0;

                @include breakpoint(tab-max) {
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                }

                &:last-child {
                    padding-top: 1.875rem;
                }
            }
        }

        .cq-dd-image {
            width: 43.47%;

            @include breakpoint(tab) {
                width: 45.45%;
            }

            @include breakpoint(mobile) {
                margin-bottom: 4.5rem;
                width: 100%;
                padding: 0 1.9375rem;
            }
        }
    }

    .cmp-img-caption {
        max-width: 1380px;
        margin: 0 auto;
        padding-top: 1.25rem;

        @include breakpoint(mobile) {
            position: absolute;
            width: 100%;
            top: 26.313rem;
            left: 0;
            padding-left: 3.0625rem;
            padding-right: 3.0625rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            @include ann-describtion(.75rem, $lato-regular, 1rem);
            letter-spacing: .01375rem;
            width: 43.47%;
            margin: 0;

            @include breakpoint(tab) {
                width: 100%;
            }

            @include breakpoint(mobile) {
                width: 95%;
            }
        }
    }
}

.content-left-with-number {
    padding: 70px 36px;

    @include breakpoint(tab) {
        padding: 50px 32px;
    }

    @include breakpoint(mobile) {
        padding: 50px 18px;
    }

    .cmp-teaser {
        max-width: 1380px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;

        @include breakpoint(tab) {
            column-gap: 0;
            align-items: center;
        }

        @include breakpoint(mobile) {
            flex-direction: column-reverse;
            column-gap: 0;
        }

        &__pretitle {
            @include headingtitle_h4;
            display: flex;
            align-items: center;

            @include breakpoint(tab-max) {
                @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                letter-spacing: -.073125rem;
            }

            &::after {
                content: '';
                width: 100%;
                border-bottom: 1px solid $cool-dark-grey;
                margin-top: 1rem;
                margin-left: 2.5rem;

                @include breakpoint(tab-max) {
                    margin-left: 1.25rem;
                }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include headingtitle_h4;
                display: flex;
                align-items: center;
                line-height: 48px;
                letter-spacing: -1.17px;

                @include breakpoint(tab-max) {
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                    letter-spacing: -.073125rem;
                }
            }
        }

        &__title {
            padding-right: 3.5rem;

            @include breakpoint(tab) {
                padding-right: 1.8125rem;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include headingtitle_h3;
                padding-top: 3.125rem;

                @include breakpoint(tab-max) {
                    letter-spacing: -0.073125rem;
                    padding-top: 1.5625rem;
                }
            }
        }

        &__description {
            padding-top: 3.125rem;

            @include breakpoint(mobile) {
                padding-right: 1.9375rem;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                letter-spacing: 0;

                @include breakpoint(tab-max) {
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                }

                &:last-child {
                    padding-top: 1.875rem;
                }
            }
        }

        &__content {
            width: 30.43%;

            @include breakpoint(tab) {
                width: 45.45%;
            }

            @include breakpoint(mobile) {
                width: 100%;
                margin-bottom: 35px;
            }
        }

        &__image {
            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }

        .cq-dd-image {
            width: 55.86%;

            @include breakpoint(tab) {
                max-width: 340px;
                margin-bottom: 0;
            }

            @include breakpoint(mobile) {
                width: 100%;
            }
        }

        .image-and-text-block-mobile {
            @include breakpoint(tab) {
                width: 49.73%;
                margin: 0;
            }

            @include breakpoint(mobile) {
                width: 100%;
                margin-top: 3.125rem;
            }
        }
    }

    .cmp-img-caption {
        max-width: 1380px;
        margin: 0 auto;
        padding: 0;

        @include breakpoint(mobile) {
            position: relative;
            top: 0;
            width: 100%;
            text-align: left;
            margin-top: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            @include ann-describtion(.75rem, $lato-regular, 1rem);
            letter-spacing: .01375rem;
            width: 55.86%;
            text-align: left;
            margin-right: 0;
            margin-top: 1.1875rem;

            @include breakpoint(tab) {
                width: 100%;
                margin: 0;
                padding: 20px 0 0 0;
            }

            @include breakpoint(mobile) {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}

.bg-white {

    .content-right-with-number,
    .content-left-with-number {
        .cmp-teaser {
            &__pretitle {
                color: $text-medium-dark-grey;
            }
        }
    }
}