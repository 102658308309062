.carousel-header-info{
    &.carousel-swipe-scroll{
        padding: 4.375rem 0;
        @include breakpoint(tab-max){
            padding: 3.125rem 0 8.75rem;
        }
        .cmp-carousel-slick{
            .carousel-header-info{
                display:flex;
                justify-content: space-between;
                flex-direction: row;
                padding:0 0 2.5rem 0;
                flex-wrap: wrap;
                @include breakpoint(desktop-xxxlg){
                    margin:0 50px;
                }
                @include breakpoint(desktop-mid){
                    margin:0 2.6045%;
                }
                @include breakpoint(tab-max){
                    flex-direction:column;
                    position:static;
                    padding:0 0 2.5rem 0;
                }
                @include breakpoint(tab){
                    padding:0 2rem 2.5rem;
                }
                @include breakpoint(mobile){
                    padding:0 1.125rem;
                }
                h1,h2,h3,h4,h5,h6{
                    width: 100%;
                    margin: 0;
                }
                .cmp-carousel{
                    &__title{
                        padding-bottom: 20px;
                        @include breakpoint(desktop-small) {
                            width: 20.57%;
                        }
                        @include breakpoint(tab-max){
                            width: 100%;
                        }
                        h1,h2,h3,h4,h5,h6{
                            @include headingtitle_h3;
                            text-align: left;
                            color:$text-dark-grey;
                            margin:0;
                            @include breakpoint(tab-max){
                                @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                                letter-spacing: -0.073125rem;
                            }
                        }
                    }
                    &__description{
                        @include breakpoint(desktop-small) {
                            width: 39.135%;
                        }
                        position: relative;
                        @include breakpoint (desktop-xlg) {
                            right: 10.54rem;
                        }
                        @include breakpoint(tab){
                            width: auto;
                            flex:auto;
                        }
                        @include breakpoint(mobile){
                            width:100%;
                        }
                        p{
                            letter-spacing: 0;
                            @include ann-describtion(1.375rem, $lato-regular, 2rem);
                            text-align:left;
                            color:$text-medium-dark-grey;
                            @include breakpoint(tab-max){
                                @include ann-describtion(16px , $lato-regular, 24px);
                                letter-spacing: normal;
                                width:100%;
                            }
                            @include breakpoint(mobile){
                                margin-bottom: 2.5rem;
                            }
                            &:empty{
                                display: none;
                            }
                        }
                    }
                    &__action-container{
                        position: relative;
                        @include breakpoint(tab-max){
                            position: absolute;
                            bottom: 50px;
                        }
                        @include breakpoint(tab){
                            width: 93%;
                        }
                        @include breakpoint(mobile){
                            left: 18px;
                            right: 18px
                           
                        }
                    }
                    &__action-link{
                        color:$color-medium-blue;
                        margin-top: unset;
                        padding: .75rem 45px .875rem;
                        @include breakpoint(tab-max){
                            position: relative;
                            width:100%;
                            left:0;
                            margin:0;
                        }
                        @include breakpoint(mobile){
                            bottom:0;
                        }
                    }
                }
            }
            .slick-initialized{
                margin-bottom:0;
                .slick-arrow{
                    display:none;
                }
                .slick-list{
                    margin-left:0;
                    padding:0 2.6%;
                    @include breakpoint(desktop-xlg) {
                        padding-right: 408px;
                    } 
                    @include breakpoint (desktop-lg) {
                        padding-left: 2.6%;
                        padding-right: 95px;
                    }
                    @include breakpoint(tab){
                        margin-bottom:2.5rem;
                        padding-left: 2rem;
                        padding-right: 89px;
                    }
                    @include breakpoint(mobile){
                        padding:0 1.125rem; 
                        padding-right: 11px;

                    }
                    .product-card{
                        &__link {
                            .product-card__details{
                                .product-card__left{
                                    text-align: left!important;
                                    @include breakpoint(desktop-small){
                                        flex: 0 0 70%;
                                        max-width: 70%;    
                                    }
                                    @include breakpoint(tab-max){
                                        flex: 0 0 100%;
                                        max-width: 100%; 
                                    }
                                }
                                .product-card__right{
                                    @include breakpoint(desktop-small){
                                        flex: 0 0 30%;
                                        max-width: 30%;    
                                    }
                                    @include breakpoint(tab-max){
                                        flex: 0 0 100%;
                                        max-width: 100%;
                                    }
                                    .product-card__discount-price{
                                        display: flex;
                                        align-content: center;
                                        justify-content: flex-end;
                                        @include breakpoint(tab-max){
                                            justify-content:flex-start;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .slick-slide{
                    margin-right: 1.875rem;
                    @include breakpoint(tab){
                        margin-right: .4375rem;
                    }
                    @include breakpoint(mobile){
                        margin-right: .4375rem;
                    }
                }
                .productcard{
                    text-align:left;
                }
                .product-card{
                    @include breakpoint(tab-max){
                        max-width:100%;
                        min-width:100%;
                    }
                    &__img-container{
                        display: flex;
                        align-items: center;
                        background: $color-white;
                        @include breakpoint(tab-max){
                            min-height: 216px;
                            max-height:216px;
                        }
                    }
                }
                .slick-dots{
                    position: relative;
                    bottom:0;
                    margin-top:3.75rem;
                    line-height:0;
                    top: unset!important;
                    @include breakpoint(tab-max){
                        margin: 2.1875rem 0 auto;
                    }
                }
            }
        }
    }
}
.product-card{
    &__link {
        .product-card__details{
            margin-bottom: 2px;
            @include breakpoint (tab-max) {
                flex-direction: column;
            }
            @include breakpoint(mobile){
                margin-bottom: 5px;
            }
            &:last-child{
                margin-top: 23.5px;
                @include breakpoint(tab-max){
                    margin-top: 15px;
                }
            }
            .product-card__left{
                .product-card__name{
                    .highlight-text{
                        font-family:$font-regular-base;
                    }
                    span{
                        font-family:$font-regular-base;
                        color:$text-dark-grey;
                        font-size:18px;
                        line-height:28px;
                        letter-spacing: normal;
                        @include breakpoint (tab-max) {
                            font-size:16px;
                            line-height: 24px;
                        }
                    
                    }  
                }
                .product-card-desc{
                    font-size:16px;  
                    line-height:24px; 
                    letter-spacing:normal;
                    font-family:$font-regular-base;
                    color:$text-medium-dark-grey;
                }
                .product-card__color-text{
                    span {
                        font-size:14px;  
                        line-height:20px; 
                        letter-spacing:normal;
                            font-family:$font-regular-base;
                            color:$text-medium-dark-grey;
                        }
                    }
            }
            .product-card__right{
                span{
                    font-size:16px;
                    line-height:24px;
                    color:$text-dark-grey;
                    font-family:$font-regular-base;
                    letter-spacing: normal;
                }
                .product-card__price{
                    padding-right: 20px;
                    text-align: right;
                    @include breakpoint(tab-max){
                        padding-right: 0px;
                        margin-top: 4px;
                    }
                }
                .product-card__discount-price{
                    padding-right: 20px;
                    text-align: right;
                    @include breakpoint(tab-max){
                        padding-right: 0px;
                    }
                }
            }
        } 
        .product-card__img{
            @include breakpoint(tab-max){
                max-height:inherit;
            }
            &-container{
                @include breakpoint(tab){
                    width: 100%;
                    min-width: unset;
                }
                @include breakpoint(mobile){
                    margin-bottom: 15.52px;
                    width: 100%;
                    min-width: unset;
                }
            }
            &-container+.product-card__details{
                margin-top: .9375rem;
            }
            @include breakpoint(tab-max){
                height: unset;
            }
        }
    }
    
}
@include breakpoint(tab-max) {
    .product-card__link .product-card__details {
        display: flex;
        align-items:baseline;
    }
    .product-card__link .product-card__img-container+.product-card__details{
        display: flex;
        margin-bottom: 5px;
        margin-top: 0px;
        .product-card__left{
            max-width: 73%;
        }
    }  
}