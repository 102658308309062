@import "./ann_typography";

.teaser.design-services {
  padding: 70px 0;
  max-width: 1600px;
  margin: 0 auto;

  @include breakpoint(tab) {
    padding: 50px 0 50px;
  }

  @include breakpoint(mobile) {
    padding: 50px 0;
  }

  .cmp-teaser {
    &__image {
      @include breakpoint(mobile) {
        width: 100%;
      }

      @include breakpoint(mobile) {
        position: relative;
        margin-bottom: 1.875rem;
        margin-top: 6.15rem;
        padding-left: 3rem;
      }

      .cmp-image {
        .cmp-teaser__image.cmp-image__image {
          @include breakpoint(desktop-small) {
            min-width: 887px;
          }

          @include breakpoint(tab) {
            width: 440px;
          }

          @include breakpoint(mobile) {
            min-width: 326px;
          }

          position: relative;
          top: 0;
          bottom: 0;
          margin: 0;
          padding: 0;

        }
      }
    }

    &__content {
      width: 22.5rem;
      display: flex;
      flex-direction: column;
      max-width: 1380px;
      margin: 0 auto;
      margin-left: 6.875rem;
      z-index: 0;
      position: static;

      @include breakpoint(desktop-lg) {
        padding-right: 2.25rem;
        box-sizing: content-box;
      }

      @include breakpoint(desktop-mid) {
        padding: 0 2.25rem;
        margin-left: 0;
      }

      @include breakpoint(desktop-mid) {
        padding-left: 2.25rem;
      }

      @include breakpoint(tab) {
        width: 17.0625rem;
        padding: 0 2rem;
        box-sizing: content-box;
        margin-left: 0;
        margin-right: auto;
      }

      @include breakpoint(mobile) {
        width: 100%;
        padding: 0 1.125rem;
        box-sizing: border-box;
        margin: 0;
      }
    }

    &__pretitle {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        @include ann-describtion(0.75rem, $lato-regular, 1rem);
        letter-spacing: 0.125rem;
        color: $text-dark-grey;
        text-transform: uppercase;
        margin-bottom: 0.625rem;

        @include breakpoint(mobile) {
          position: absolute;
          top: 0;
        }
      }
    }

    &__title {
      @include breakpoint(mobile) {
        position: absolute;
        top: 19px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        @include headingtitle_h3;
        color: $text-dark-grey;
        margin-bottom: 4.375rem;
        padding-right: 1.8125rem;
        padding-bottom: 0;

        @include breakpoint(tab-max) {
          @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
          letter-spacing: -0.073125rem;
          margin-bottom: 15px;
        }

        @include breakpoint(tab) {
          margin-bottom: 2.5rem;
          padding-right: 0;
        }

        @include breakpoint(mobile) {
          padding-right: 0;
          margin-bottom: 40px;
          position: static;
        }
      }
    }

    &__description {
      width: 100%;
      margin-bottom: 2.5rem;

      @include breakpoint (tab-max) {
        margin-bottom: 30px;
      }

      @include breakpoint(mobile) {
        max-width: 19.25rem;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,p:first-child {
        font-size: 16px;
        font-family: $lato-regular;
        line-height: 24px;
        letter-spacing: 0.44px;
        color: $text-dark-grey;
        margin-bottom: 0.625rem;
        padding-bottom: 0;
      }

      p {
        @include ann-describtion(1rem, $lato-regular, 1.5rem);
        color: $text-medium-dark-grey;
        letter-spacing: normal;

        @include breakpoint(tab-max) {
          @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
          letter-spacing: normal;
          padding-bottom: 0;
        }

        @include breakpoint(mobile) {
          @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
          letter-spacing: normal;
        }
      }
    }

    &__action-link.gbh-secondary-button {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 2px;
      padding: 0 27px;
      height: 60px;
      align-items: center;
      font-family: $lato-regular;

      @include breakpoint(tab) {
        margin-top: 0;
        height: 50px;
      }
    }
  }

  &.content-left {
    .cmp-teaser {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      @include breakpoint(desktop-small) {
        height: 592px;
      }

      @include breakpoint(tab) {
        align-items: center;
      }

      @include breakpoint(mobile) {
        flex-direction: column;
        position: relative;
      }

      &__content {
        padding: 0;
        justify-content: center;
        @include breakpoint(tab-max) {
          padding: 0 18px;
          position: static;
          justify-content: center;
        }
      }

      .cmp-teaser__action-container {
        margin: 0;
      }
    }
  }
}