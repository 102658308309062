.gbh-find-a-store {
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    top: 40px;
    z-index: 2;
    display:none;
    @include breakpoint(desktop-extra-lite-sm){
        position: fixed;
        top: 0;
        z-index: 1;
    }
    
    @include breakpoint(desktop-extra-lite){
        .findstore-bg{
            width:100%;
            height:100%;
            top:0;
            display:block;
            position: absolute;
            z-index:auto;
            &:after {
                position: absolute;
                content: "";
                background-color: rgba(0, 0, 0, .7);
                z-index: 1;
                left: 0;
                top:0;
                opacity: 0.7;
                top: 0;
                width: 100%;
                height: 100vh;
                visibility: hidden;
                animation: slide-up 1s cubic-bezier(0.4, 0, 0.2, 1) 1500ms;
                transition: visibility 2s linear 1s, opacity 1s;
            }
        }
    }
    &.active{
        width: 100%;
        height: 100vh;
        display:block;
        @include breakpoint(desktop-extra-lite){
            .findstore-bg{
                &:after {
                    opacity:0.8;
                    z-index: -1;
                    -webkit-animation: slideInDown 1s;
                    -moz-animation: slideInDown 1s;
                    -o-animation: slideInDown 1s;
                    animation: slideInDown 1s;
                    visibility: visible;
                } 
            }
        }
        
    }
}