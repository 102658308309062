.two-up-fc-image-large{
    padding: 70px 0;
    margin-right: 1.875rem;
    @include breakpoint(tab) {
        margin-right: .625rem;
        padding: 3.125rem 0;
    }
    @include breakpoint(mobile) {
        padding: 3.125rem 0 0;
        margin-right: 0;
    }
    &:last-child{
        margin-right: 0;
        @include breakpoint(mobile) {
            padding: 2.5rem 0 3.125rem;
        }
    }
    .cmp-teaser{
        &__title{
            h1,h2,h3,h4,h5,h6,p {
                @include ann-describtion(.875rem,$lato-regular,1rem);
                padding-top: 1.875rem;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-bottom: 30PX;
                @include breakpoint(tab-max){
                    padding-top: 1.5625rem;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }
        }
        &__description{
            h1,h2,h3,h4,h5,h6,p{
                font-size: 18px;
                line-height: 28px;
                font-family: $lato-regular;
                letter-spacing: normal;
                @include breakpoint(tab-max){
                    font-size: 14px;
                    line-height: 20px;
                }
                &:first-child{
                    font-size: 22px;
                    line-height: 32px;
                    color: $text-dark-grey !important;
                    margin-bottom: 10px;
                    @include breakpoint(tab-max){
                        font-size: 18px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
        &__action-container{
            margin-top: 20px;
        }
    }
}
.two-up-fc-image-medium{
    @extend .two-up-fc-image-large;
    @include breakpoint(tab) {
        padding: 3.125rem 0;
    }
    &:last-child{
        @include breakpoint(mobile) {
            padding: 2.5rem 0 3.125rem 0;  
        }
    }
}
.two-up-content{
    .two-up-fc-image-large, .two-up-fc-image-medium {
        .cmp-teaser{
            &__content{
                h1,h2,h3,h4,h5,h6,p {
                    color: $text-medium-dark-grey;
                }
            }
            .cmp-img-caption{
                h1,h2,h3,h4,h5,h6,p {
                    color: $text-medium-dark-grey;
                }
            }
        }
    }
    &.organize-container{
        .cmp-container{
            max-width: 1380px;
            padding:0;
            @include breakpoint(desktop-mid){
                padding: 0 2.25rem;
            }
            @include breakpoint(tab){
                padding:0 2rem;
            }
            @include breakpoint(mobile){
                padding:0 1.125rem;
            }
            .teaser{
                &:first-child{
                    margin-bottom: 30px;
                    @include breakpoint(tab-max){
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}