#main-content {
    opacity: 1 !important;
}

.cart-landing {
    margin-top: 40px;
    &__content {
        padding-right: 100px;
    }
    &__header {
        margin-bottom: 40px;
    }
    &__back {
        cursor: pointer;
        font-family: $font-regular;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        display: inline-flex;
        align-items: center;
        img {
            margin-right: 20px;
        }
    }
    &__support {
        text-align: left;
        margin-left: 40px;
            div {
                margin-bottom: 10px;
                font-family: $font-light;
                font-size: 26px;
                line-height: 34px;
                letter-spacing: -1.55px;
                color: $color-slate;
            }
            a {
                cursor: pointer;
                font-family: $font-regular;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.55px;
                color: $color-dark-gray;
            }
    }

    @media only screen and (min-width: 1024px){
        margin-top: 25px;
        &__content {
            padding-right: 60px;
        }
    }

    @media only screen and (max-width: 990px){
        margin-top: 25px;
        &__content {
            padding-right: 0;
        }
    }

    @media only screen 
    and (min-width: 1024px) 
    and (max-height: 1366px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
        margin-top: 25px;
        &__content {
            padding-right: 30px;
        }
    }
}