.teaser.four-up-content-header {
    padding: 0 0 3.125rem;
    position: relative;
    @include breakpoint(tab) {
        padding: 0 0 2.5rem;
    }
    @include breakpoint(mobile) {
        padding: 0 1.125rem;
        position: static;
    }
    .cmp-teaser {
        &__pretitle {
            @include ann-describtion(.75rem, $lato-regular, 1rem);
            letter-spacing: .125rem;
            color: $text-dark-grey;
            text-transform: uppercase;
        }
        &__title {
            margin: .625rem 0 1.25rem;
            h1,h2,h3,h4,h5,h6 {
                @include headingtitle_h4;
                letter-spacing: -0.0731rem;
                color: $text-dark-grey;
                @include breakpoint(tab-max) {
                    @include ann-describtion(1.75rem, $playfair-regular, 2.125rem);
                }
            }
        }
        &__description {
            max-width: 33.25rem;
            @include breakpoint(tab) {
                width: 25.5rem;
            }
            @include breakpoint(mobile) {
                width: 100%;
            }
            p {
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                color: $text-light-grey;
                @include breakpoint(tab-max) {
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                }
            }
        }
        &__action-container {
            position: absolute;
            top: 1.625rem;
            right: 0;
            @include breakpoint(mobile) {
                bottom: 0;
                top: unset;
                width: 100%;
                padding: 0 1.125rem;
                margin: 2.5rem 0 3.125rem;
            }
        }
        &__action-link.gbh-secondary-button {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            padding: 0 27px;
            height: 60px;
            align-items: center;
            font-family: $lato-regular;

            @include breakpoint(tab-max) {
                height: 50px;
                margin: 0;
            }
        }
    }
}

.four-up-content {
    margin-right: 3.75rem;
    flex: 1 1 0;
    padding: 0;
    width: 25%;
    &:last-child {
        margin-right: 0;
    }
    @include breakpoint (tab) {
        margin-right: 1.25rem;
        flex: 1 44.82%;
        width: 44.82%;
        &:nth-last-of-type(odd) {
            margin-right: 0;
        }
        &:nth-last-child(-n+2) {
            padding-top: 2.5rem;
        }
    }
    @include breakpoint (mobile) {
        margin-right: 0;
        padding: 2.5rem 3.0625rem 0;
        width: 100%;
        &:nth-child(1) {
            padding-top: 0;
            margin-right: 0;
        }
    }
    .cmp-teaser {
        position: relative;
        h1,h2,h3,h4,h5,p {
            margin-bottom: 0;
        }
        &__image {
            .cmp-image {
                @include breakpoint(desktop-small) {
                    max-width: 300px;
                    max-height: 400px;
                }
                @include breakpoint (tab) {
                    min-width: 364px;
                    min-height: 484px;
                }
                @include breakpoint (mobile) {
                    min-width: 277px;
                    min-height: 370px;
                }
            }
        }
        &__content {
            @include breakpoint (tab) {
                padding-right: 3.6375rem;
            }
        }
        &__title {
            h1,h2,h3,h4,h5,h6,p {
                @include ann-describtion(1.375rem, $lato-regular, 2rem);
                color: $text-dark-grey ;
                letter-spacing: 0;
                padding-top: 1.875rem;
                @include breakpoint (tab-max) {
                    @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                    padding-top: 1.25rem;
                }
            }
        }
        &__description {
            h1,h2,h3,h4,h5,h6,p {
                @include ann-describtion(1rem, $lato-regular, 1.5rem);
                color: $text-medium-dark-grey;
                letter-spacing: 0;
                padding-top: .625rem;
                @include breakpoint (tab-max) {
                    @include ann-describtion(.875rem, $lato-regular, 1.25rem);
                }
            }
        }
        &__action-container {
            padding: 1.25rem 0 0;
        }
        &__action-link {
            text-transform: uppercase;
            color: $color-light-blue;
            @include ann-describtion(.6875rem, $lato-regular, .75rem);
            letter-spacing: .114375rem;
            padding-bottom: .4375rem;
            border-color: $color-light-blue;
        }
        &__icons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            column-gap: .3125rem;
            position: absolute;
            top: .625rem;
            right: .625rem;
            @include breakpoint (desktop-small) {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity .5s linear;
            }
            .favorite {
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                height: 2.25rem;
                width: 2.25rem;
                background: $white;
                border-radius: .3125rem;
                span {
                    display: none;
                }
                &:before {
                    font-family: $icon-moon;
                    color: $text-medium-dark-grey;
                    margin: 0;
                }
            }
            .share {
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                height: 2.25rem;
                width: 2.25rem;
                background: $white;
                border-radius: .3125rem;
                &:before {
                    font-family: $icon-moon;
                    color: $text-medium-dark-grey;
                    margin: 0;
                }
                span {
                    display: none;
                }
            }
            .share-cmp {
                position: absolute;
                top: 31px;
                right: 0;
            }
            .social-share__cta{
                white-space: nowrap;
            }
        }
    }
    @include breakpoint (desktop-small) {
        &:hover {
            .cmp-teaser__icons {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}