.find-store-landing {
  &__message,
  &__search-input,
  &__use-location,
  &__tab,
  .button,
  &__location-note {
    font-family: $lato-regular;
  }
  .offset-lg-1 {
    padding-left: 0;
    margin-left: 110px;
  }
  &__message {
    color: $text-light-grey;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }
  &__search-input {
    color: $text-medium-dark-grey;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 36px;
    padding: 0 49px 20px 0;
  }
  &__use-location {
    color: $text-medium-dark-grey;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 60px;
  }
  &__tabs {
    padding-left: 10px;
    border-bottom: 1px solid $cool-dark-grey;
    margin-bottom: 30px;
  }
  &__tab {
    color: $text-light-grey;
    font-size: 14px;
    letter-spacing: 2.33px;
    line-height: 16px;
    text-align: center;
    padding: 0 20px 22px 20px;
    text-transform: uppercase;
    &--active {
      color: $text-dark-grey;
      border-bottom: 2px solid $color-light-blue;
      line-height: 17px;
    }
  }
  &__list {
    height: 540px;
    border-bottom: 1px solid $cool-dark-grey;
    margin-bottom: 63px;
    .store-list__set-store{
      .checkbox__border, .checkbox__marker{
        width: 20px!important;
        height: 20px!important;
      }
      input:checked~span .checkbox__marker{
        border-radius: 5px!important;
      }
    }
  }
  &__map {
    margin-top: -76px !important;
  }
  .store-list {
    padding: 40px 20px 34.5px 20px;
    margin-right: 20px;
    &__name,
    &__address,
    &__phone,
    &__distance,
    &__time,
    &__distance-col,
    &__address-col,
    &__set-store-time,
    &__set-store {
      font-family: $lato-regular;
    }
    &__name {
      color: $text-medium-dark-grey;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
    }
    &__address {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__phone {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: 15px;
    }
    &__distance,
    &__time {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: right;
    }
    &__time {
      margin-top: 10px;
    }
    &__distance-col,
    &__address-col {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__name-col {
      padding: 0 30px 0 20px;
    }
    &__address-col {
      padding: 3px 20px 0 0;
    }
    &__distance-col {
      padding: 3px 20px 0 0;
      display: block;
    }
    &__set-store-time {
      color: $text-medium-dark-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: right;
    }
    &__address {
      a {
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: $text-medium-dark-grey;
      }
    }
    &__set-store {
      margin-top: 11px;
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      .checkbox {
        margin-bottom: 0;
        input:checked ~ .checkbox__marker {
          border: 1px solid $color-light-blue;
          background-color: $color-light-blue;
        }
      }
      .checkbox input ~ span {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  &__result-container {
    .kf-react-button.large {
      min-width: max-content;
      height: 24px;
    }
    .find-store-browseall-div {
      .find-store-browseall-link.button {
        color: $color-light-blue;
        display: unset;
        &:hover {
          background: none;
        }
      }
    }
    input:checked ~ span .checkbox__marker {
      left: -1px;
    }
    .checkbox__border {
      height: 20px;
      width: 20px;
      border-radius: 5px;
      margin-top: 0;
    }
  }

  .modal-popup-body .button {
    height: 60px;
    color: $white;
  }
  .modal-popup-body .button:hover {
    background-color: $color-medium-blue;
  }
  .find-store-browseall-link,
  .button {
    min-width: auto;
    font-size: 11px;
    letter-spacing: 1.83px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid $color-light-blue;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 0 5px;
    margin-bottom: 11px;
  }

  .find-store-browseall-div div.find-store-browseall-link {
    height: 24px;
    color: $color-light-blue;
  }
  .find-store-browseall-div div.find-store-browseall-link:hover {
    background-color: $color-white;
  }

  &__location-note {
    margin: 48px 0 55px;
    padding: 0 0 0 30px;
    color: $text-medium-dark-grey;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    span {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 15px;
        height: 21px;
        margin-right: 10px;
      }
    }
  }
  &__filter-panel {
    @include breakpoint(tab) {
      height: 100%;
      margin-top: 48px;
    }
    @include breakpoint(mobile) {
      height: 100%;
      margin-top: 48px;
    }
    .Collapsible {
      border-bottom: 1px solid $cool-dark-grey;
      &__trigger,
      &__contentInner .checkbox {
        font-family: $lato-regular;
      }
      .plus {
        width: 8.49px !important;
        height: 8.49px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .line {
        background: $text-medium-black;
        transition: all 0.5s ease;
      }
      .line-1 {
        padding-right: 10px;
        height: 1px;
        width: 100%;
      }
      .line-2 {
        padding-left: 1px !important;
        margin-left: 4.5px !important;
        height: 100%;
        width: 1px;
        transform: translateX(-10px);
      }
      &__trigger {
        color: $text-dark-grey;
        font-size: 12px !important;
        letter-spacing: 2px !important;
        line-height: 16px !important;
        padding: 19.61px 0 25.39px 0 !important;
        text-transform: uppercase;
        @include breakpoint(tab) {
          padding: 24.65px 0 28.66px 0 !important;
        }
        @include breakpoint(mobile) {
          padding: 24.5px 0 24.5px 0 !important;
        }
      }
      &__contentInner input:checked ~ .checkbox__marker {
        background: $color-light-blue;
      }
      &__contentInner .checkbox__marker,
      &__contentInner .checkbox__border {
        width: 16px !important;
        height: 16px !important;
        margin-right: 10px;
        border: 1px solid $cool-dark-grey-cap;
        border-radius: unset;
      }
      &__contentInner .checkbox {
        color: $text-medium-dark-grey;
        font-size: 12px;
        letter-spacing: 0.2px;
        line-height: 15px;
        margin-bottom: 15px;
        padding-bottom: 0 !important;
      }
      &__contentInner .checkbox .checkbox_default {
        color: $text-medium-dark-grey;
      }
      &__contentInner .checkbox input:checked ~ span {
        color: $text-dark-grey;
      }
      &__contentInner .checkbox input:checked ~ span .checkbox__marker {
        border: 1px solid $color-light-blue;
        background-color: $color-light-blue;
      }
    }
    .filter {
      &__chips-tag,
      &__chips-clear-all {
        font-family: $lato-regular;
      }
      &__chips {
        border-bottom: 1px solid $cool-dark-grey;
      }
      &__chips-tag {
        color: $text-dark-grey;
        font-size: 11px;
        letter-spacing: 0.16px;
        line-height: 16px;
        text-align: right;
        padding: 7px 13px 7px 10px;
        margin-bottom: 10px;
        border: 1px solid $cool-dark-grey-cap;
        border-radius: 1px;
      }
      &__chips-clear-all {
        color: $text-medium-dark-grey;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
        margin-bottom: 24px;
        margin-top: 4px;
        @include breakpoint(mobile) {
          margin-top: 1px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
.find-store-landing .auto-suggestion__input {
  @include ann-describtion(1.625rem, $lato-regular, 2.25rem);
  color: $text-medium-dark-grey;
  letter-spacing: 0;
  padding: 0 49px 20px 0;
}
.find-store-landing .store-card {
  background-color: $color-medium-plump;
  &__details {
    @include breakpoint(tab) {
      padding: 21px 32px 25px 32px;
      .store-card__time {
        display: flex;
        width: 320px;
      }
      .book-appointment.\--mobile {
        width: 290px;
        border: 1px solid $white;
        .button.small {
          padding: 15px;
        }
      }
    }
    @include breakpoint(mobile) {
      padding: 25px 18px;
      .store-card__time {
        display: flex;
      }
      .book-appointment.\--mobile {
        border: 1px solid $white;
        .button.small {
          padding: 15px;
        }
      }
    }
  }
}
.find-store-landing .store-card.showroom {
  background: $text-dark-grey;
}
.find-store-landing .store-card__name p {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 32px;
}
.find-store-landing .store-card address {
  margin-top: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.find-store-landing .store-card .store-card-phone > a,
.find-store-landing .store-card address > p {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.find-store-landing .store-card .store-card-phone {
  margin-bottom: 0;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.find-store-landing .store-card__open .distance {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 7px;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}
.find-store-landing .store-card__view-details {
  color: $color-white;
  font-family: $lato-regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  @include breakpoint(desktop-small) {
    position: absolute;
    bottom: 30px;
  }
}
.find-store-landing .store-card__time {
  display: inline-block;
  margin: auto;
}
.find-store-landing .store-card__open {
  @include breakpoint(desktop-small) {
    margin-bottom: 32px;
  }
  .checkbox input ~ span {
    color: $white;
  }
}
.find-store-landing .book-appointment.\--mobile {
  margin-bottom: 0;
  display: flex;
  padding: 0;
  border: 1px solid $white;
  @include breakpoint(desktop-small) {
    width: 208px;
  }
  .button {
    height: 100%;
    border-style: none;
    border-bottom: 0;
    &:hover {
      background-color: transparent;
    }
    &.small {
      padding: 0;
      margin: 0;
      display: flex;
      border-bottom: 0;
      letter-spacing: 2.5px;
      color: $white;
      transition: none;
    }
  }
}
@include breakpoint(desktop-extra-sm) {
  .find-store-landing {
    &__tabs {
      padding-left: 0;
    }
    &__tab {
      padding: 0 10px 22px;
    }
  }
}
@include breakpoint(tab) {
  .find-store-landing {
    &__message,
    &__view-list,
    &__location-note {
      font-family: $lato-regular;
    }
    &__search-container {
      padding: 0;
    }
    .offset-lg-1 {
      padding: 0;
      margin: 0;
    }
    &__message {
      color: $text-light-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__breadcrumb {
      margin-top: 40px;
      margin-bottom: 20px;
      padding-left: 32px;
    }
    &__search-input {
      padding: 0 0 13px 0;
    }
    &__use-location {
      margin-top: 16px;
      margin-bottom: 41px;
    }
    &__view-list {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__tabs {
      padding: 0;
      margin: 0 31px 0 32px;
    }
    &__tab {
      padding: 0 10px 22px 10px;
      margin-right: 20px;
      font-size: 14px;
      letter-spacing: 2.33px;
      line-height: 17px;
      &:first-child {
        margin-left: 154px;
      }
    }
    &__list {
      height: auto;
      border-bottom: none;
      margin-bottom: 0;
    }
    &__show-list {
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &__show-map {
      padding-left: 0;
      padding-right: 0;
    }
    .col-12 {
      padding: 0;
      padding-left: 0;
      padding-right: 0;
    }
    &__breadcrumb {
      margin-top: 19px;
      margin-bottom: 22px;
      padding-left: 33px;
    }
    &__location-note {
      margin: 14px 0 6px 0;
      padding: 0 0 0 62px;
      color: $text-medium-dark-grey;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 20px;
      span {
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 15px;
          height: 21px;
          margin-right: 8px;
        }
      }
    }
    &__map {
      margin-top: -25px !important;
    }
    .store-list {
      padding: 28px 0 30px 0;
      margin: 0;
      &__name-col {
        padding: 0 68px 0 20px;
      }
      &__address-col {
        display: block;
        padding: 0 79px 0 0;
      }
      &__phone {
        margin-top: 15px;
      }
      &__distance-col {
        display: flex;
      }
      &__time {
        display: none;
      }
      &__set-store {
        margin-top: 20px;
      }
      &__set-store-time {
        display: block;
        padding-bottom: 4px;
        margin: 0;
      }
    }
    &__result-container {
      div.button.large {
        height: 24px;
        width: 137.36px;
        min-width: 140px;
        color: $color-light-blue;
        background-color: $color-white;
        padding: 0;
        margin: 30.78px 0 11.66px 314px;
      }
      .kf-react-button.large {
        margin: 0 36%;
        width: max-content;
      }
    }
    .find-store-browseall-div {
      text-align: center;
      .kf-react-button.large {
        margin: unset;
        width: max-content;
      }
    }
    &__filter {
      padding: 0;
    }
    .filter {
      padding: 0;
    }
    &__filter-panel {
      top: 0;
    }
    &__filter-panel .filter__mobile-header {
      padding-top: 0;
      margin: 0 24px 0 32px;
    }
  }
  .product-list {
    &__close-icon {
      width: 20px;
      height: 20px;
    }
    &__controls {
      margin: 0;
      position: relative;
    }
    &__filter-toggle {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__filter-toggle-icon {
      height: 20px;
    }
  }
  .find-store-landing .auto-suggestion__input {
    padding: 0 0 13px 0;
  }
  .find-store-landing__filter-panel .filter {
    &__chips {
      margin-left: 33px;
      margin-right: 31.39px;
    }
    &__chips-tag {
      font-size: 12px;
      letter-spacing: 0.2px;
      line-height: 15px;
      padding: 8.5px 24.16px 7.5px 9.67px;
      margin-bottom: 14.5px;
      margin-right: 9.5px;
    }
    &__chips-clear-all {
      color: $text-light-dark-grey;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 18px;
      margin-bottom: 30px;
    }
  }
  .find-store-landing__filter-panel .Collapsible {
    margin: 0 31.76px 0 32px;
    .plus {
      width: 8.49px;
      height: 8.49px;
    }
    .line-1 {
      padding-right: 10px;
      height: 1px;
      width: 100%;
    }
    .line-2 {
      padding-left: 1px;
      margin-left: 4.5px;
    }
    &__trigger {
      text-transform: uppercase;
    }
  }
  .filter__inner-container {
    padding-bottom: 0;
  }
  .find-store-landing__filter-panel .filter__footer {
    padding: 0 32px;
    margin-bottom: 25px;
  }
}

@include breakpoint(mobile) {
  .find-store-landing {
    &__search {
      margin: 0;
    }
    &__use-location {
      padding-left: 0;
    }
    &__show-list {
      padding: 0;
    }
    &__message,
    &__view-list {
      font-family: $lato-regular;
    }
    &__search-container {
      padding: 0;
    }
    .offset-lg-1 {
      padding: 0;
      margin: 0 18px 0 18px;
    }
    &__message {
      color: $text-light-grey;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &__result-container {
      padding: 0 18px;
      .row {
        margin: auto;
      }
    }
    &__search-input {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
      padding: 0 26px 15px 0;
    }
    &__use-location {
      margin-bottom: 38px;
    }
    &__view-list {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__tabs {
      padding: 0;
    }
    &__tab {
      padding: 0 10px 22px 10px;
    }
    &__list {
      margin-bottom: 28px;
      border-bottom: none;
      height: auto;
      padding: 0;
      .row {
        margin: 0;
      }
    }
    &__filter {
      padding: 0 18px;
    }
    &__map {
      margin-top: -25px !important;
    }
    .store-list {
      padding: 30px 0 30.5px 0;
      margin: 0;
      &__name-col {
        padding: 0 0 0 18px;
      }
      &__address-col {
        padding: 0 0 0 18px;
        margin-top: 30px;
      }
      &__distance-col {
        padding: 0 18px 0 0;
        display: flex;
      }
      &__set-store {
        margin-top: 32px;
      }
      &__set-store-time {
        margin-top: 30px;
      }
    }
    &__show-map {
      padding-left: 0;
      padding-right: 0;
    }
    &__location-note {
      margin: 10px 0 10px;
      padding: 0 0 0 16px;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 20px;
      span {
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 15px;
          height: 21px;
          margin-right: 8px;
        }
      }
    }
    &__result-container {
      .button.large {
        height: 24px;
        width: 137.36px;
        min-width: 140px;
        color: $color-light-blue;
        background-color: $color-white;
        padding: 0;
        text-align: center;
        margin-bottom: 63px;
        margin-left: 95px;
      }
      .kf-react-button.large.ghost {
        width: max-content;
        margin: 0px 26%;
        height: 24px;
      }
    }
    .find-store-landing {
      .find-store-browseall-div {
        text-align: center;
        .kf-react-button.large {
          margin: unset;
          width: max-content;
        }
      }
    }
  }
  .product-list {
    &__close-icon {
      width: 21.21px;
      height: 21px;
    }
    &__filter-toggle {
      color: $text-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__filter-toggle-icon {
      height: 20px;
    }
  }
  .find-store-landing__filter-panel .filter {
    &__chips-tag {
      margin-bottom: 14px;
    }
    &__chips-clear-all {
      margin-bottom: 29px;
    }
  }
  .find-store-landing .auto-suggestion__input {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0 26px 15px 0;
  }
  .find-store-landing__filter-panel .Collapsible {
    .plus {
      width: 8.49px;
      height: 8.49px;
    }
    .line-1 {
      padding-right: 10px;
    }
    .line-2 {
      padding-left: 1px;
      margin-left: 4.5px;
    }
    &__trigger {
      text-transform: uppercase;
    }
  }
  .find-store-landing .store-card {
    bottom: 40px;
  }
}
.find-store-landing__filter-panel .filter__apply {
  display: none;
  color: $white;
  font-family: $lato-regular;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.5px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 1px;
  margin-bottom: 25px;
  background-color: $cool-dark-grey-cap;
  @media (min-width: 275px) and (max-width: 1024px) {
    margin-bottom: 50px;
    display: block;
    border: 0;
    outline: auto;
    width: 100%;
    height: 60px;
  }
}
.find-store-landing__filter-panel .filter__apply--active {
  color: $white;
  background-color: $color-medium-blue-cap;
  border-radius: 1px;
}
@media screen and (max-width: 767px) {
  .find-store-landing {
    .find-store-browseall-div {
      text-align: center;
      .kf-react-button.large {
        margin: unset;
        width: max-content;
      }
    }
  }
}

@include breakpoint(mobile) {
  .col-12 {
    padding: 0 18px;
  }
}
//Filter popup view results button sticky
.find-store-landing__filter {
  .find-store-landing__filter-panel {
    .filter {
      .filter__footer {
        @include breakpoint(tab-max) {
          position: sticky;
        }
      }
    }
  }
}
.product-listing,
.search-result {
  .filter {
    padding: 0 21px 0 2px;
  }
  .filter__inner-container {
    .filter__footer {
      @include breakpoint(tab-max) {
        position: sticky;
      }
    }
  }
}
