.hero-banner-collection{
    padding: 0;
    @include breakpoint(desktop-large){
        min-height: 1000px;
    }
    .cmp-teaser {
        position: relative;
        display: block;        
        &__image {
            max-height:1000px;
            @include breakpoint(tab-max){
                max-height: unset;
            }
            .cmp-image {
                @include breakpoint(tab){
                    min-width:812px;
                    min-height:393px;
                }
                @include breakpoint(mobile){
                    min-width:375px;
                    min-height:775px;
                }
                &::before {
                    background-image: linear-gradient(203.3deg, $black 0%, transparent 15%);
                    background-color: transparent;
                    @include breakpoint(tab-max) {
                        background-image: linear-gradient(180deg, $black 0%, transparent 15%);
                    }
                }
            }            
        }
        &__content {
            position: absolute;
            z-index: 10;
            background: none;                    
            width: 100%;
            text-align: center;
            padding:0 2.6045%;
            bottom: 11.25rem;
            max-width: 1920px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            box-sizing: border-box;
            justify-content: center;    
            z-index: 0;        
            @include breakpoint(desktop-large){
                margin-left:-960px;
                left:50%;
            }
            @include breakpoint(desktop-mid){
                max-width:100%;
                margin-left:0;
                left:0;
                padding:0 2.25rem;
            }
            @include breakpoint(tab-max){
                bottom: 6.25rem;
                margin-left:0;
                left:0;
                box-sizing: border-box;
                width: 100%;
            }
            @include breakpoint(tab) {
                padding:0 2rem;                
            }
            @include breakpoint(mobile) {
                padding:0 1.125rem;
            }
        }
        &__title {
            @include breakpoint(desktop-small){
                width: 26.25rem;
            } 
            h1, h2, h3, h4, h5, h6, p {
                @include headingtitle_h2;
                padding: 0 0 2.5rem;
                margin: 0;
                @include breakpoint(tab) {
                    color: inherit;
                    letter-spacing: -0.125rem;
                    padding: 0 0 1.5625rem;
                    margin: 0;
                }
                @include breakpoint(mobile) {
                    color: inherit;
                    letter-spacing: -0.125rem;
                    padding: 0 0 1.5625rem;
                    margin: 0;
                }
            }
        }
        &__description {
            @include breakpoint(desktop-small){
                width: 26.25rem;
            } 
            p {
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                letter-spacing: normal;
                padding: 0;
                margin: 0;
                @include breakpoint(tab-max) {
                    @include ann-describtion(1rem, $lato-regular, 1.5rem);
                    letter-spacing: normal;
                }
            }
        }
        &__action-link {
            padding: 0 0 0.4375rem 0;
            line-height: .75rem;                   
            @include breakpoint(tab-max) {
                color: inherit;
                border-color: inherit;
            }
            &:last-child{
                position:absolute;
                bottom:-6.25rem;
                border:none;
                padding:0;
                margin:0 auto;
                right:0;
                left:0;
                width:24px;
                height:14px;
                background:transparent;
                box-shadow:none;
                color:inherit;
                @include breakpoint(tab-max){
                    bottom:-2.5rem;
                }
                &:before{
                    content:"\e92a";
                    font-family:$icon-moon;
                    width:25px;
                    height:15px;
                    font-size:12px;
                    font-weight:normal;
                    transform: translatey(0px);
                    animation: float 6s ease-in-out infinite;
                    position: relative;
                    @include breakpoint(tab-max){
                        font-size:10px;
                    }
                }
            }
        }
    }
    &.content-bottom-left{
        .cmp-teaser{
            &__content{
                width: 100%;
                text-align: left;
                @include breakpoint(tab-max) {
                    left: 0;
                    margin:0;
                }
                @include breakpoint(mobile) {
                    padding-left: 1.125rem;
                }
            }
        }        
    }
    &.content-bottom-right {
        .cmp-teaser {
            &__content {
                text-align: right;
                margin-right: -960px;
                left: unset;
                margin-left: 0;
                align-items: flex-end;
                @include breakpoint(desktop-large){
                    margin-right:-960px;
                    right: 50%;
                }
                @include breakpoint(desktop-mid){
                    right: 2.25rem;
                    margin: 0;
                    padding: 0;
                }
                @include breakpoint(tab-max){
                    margin:0;
                    right:0;
                }
            }
        }        
    }
    &.content-bottom-center {
        .cmp-teaser {
            &__content {
                width: 100%;
                align-items: center;
                @include breakpoint(tab-max){
                    left:0;
                    margin:0;
                }
                @include breakpoint(tab) {
                    width: 100%;
                }
            }
            &__title {
                width: 26.25rem;
                margin: 0 auto; 
                @include breakpoint(tab) {
                    width: 29.75rem;
                }
                @include breakpoint(mobile) {
                    width: 100%;
                }
            }
        }        
    }
}