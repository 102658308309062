#press-room-as {
  .press-release {
    &__date,
    &__read-more,
    &__download-link {
      font-family: $lato-regular;
    }
    &__info {
      min-height: auto;
      @include breakpoint(mobile) {
        max-width: 59%;
      }
    }
    &__image-block {
      @include breakpoint(mobile) {
        max-width: 36%;
      }
    }
    &__read-more-block {
      width: 430px;
      @include breakpoint(mobile) {
        clear: none;
        width: 205px;
      }
    }
    &__row {
      border-bottom: 1px solid $cool-dark-grey;
      padding-bottom: 50px;
      margin: 0 0 50px 0;
      @include breakpoint(mobile) {
        padding-bottom: 42px;
        margin: 0 0 30px 0;
      }
      @include breakpoint(tab) {
        padding-bottom: 50px;
        margin: 0 0 37px 0;
      }
    }

    &__date {
      width: 360px;
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 20px;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        width: 185px;
        margin-bottom: 10px;
      }
      @include breakpoint(tab) {
        width: 261px;
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    &__title,
    &__title:hover {
      color: $text-dark-grey;
      font-family: $playfair-regular;
      font-size: 36px;
      letter-spacing: -2px;
      line-height: 45px;
      margin-bottom: 20px;
      text-decoration: none;

      @include breakpoint(mobile) {
        font-size: 18px;
        letter-spacing: -0.67px;
        line-height: 30px;
        margin-bottom: 21px;
      }
      @include breakpoint(tab) {
        font-size: 36px;
        letter-spacing: -2px;
        line-height: 41px;
        margin-bottom: 20px;
      }
    }
    &__read-more,
    &__read-more:hover {
      color: $color-light-blue;
      font-size: 11px;
      letter-spacing: 1.83px;
      line-height: 12px;
      text-transform: uppercase;
      text-underline-offset: 5px;
      margin-top: 4.5px;
      text-decoration: none;
    }
    &__download-block {
      margin-left: 18.49px;
    }
    &__download-link,
    &__download-link:hover {
      color: $text-medium-dark-grey;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      text-decoration: none;
      padding-top: 3.24px;
    }
    &__download-icon {
      height: 16.76px;
      width: 16px;
    }
    &__download-link {
      > span {
        margin-left: 9px;
      }
      .download-article.download-spinner {
        @include breakpoint(mobile) {
          margin-left: 0px !important;
        }
      }
    }
    &__read-more-row {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
    &__image {
      height: 320px;
      width: 240px;
      @include breakpoint(mobile) {
        height: 164px;
        width: 123px;
      }
      @include breakpoint(tab) {
        height: 270px;
        width: 202px;
      }
    }
    .read-more-section {
      margin-top: 20px;
      gap: 18.96px;
      @include breakpoint(tab) {
        gap: 19.96px;
      }
      @include breakpoint(mobile) {
        gap: 18.94px;
      }
    }
  }
}
