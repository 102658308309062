.product-select-style__main-container {
    display: flex;
    padding: 0;
    clear: both;
    max-width: 100%;
    margin: 0;
    @include breakpoint(mobile) {
        padding: 0 0 10px 0;
    }
    .product-select-style{
        &__shape-tooltip, &__color-tooltip, &__finish-tooltip, &__shape-select-tooltip, &__color-select-tooltip{
            display: none;
        }
    }
}
.product-select-style__devider--block{
    border-top: 1px solid $text-light-grey;
    padding-top: 30px;
}

.product-select-style {
    background-color: $cool-light-grey;
    padding: 0;
    max-width: 535px;
    width: 100%;
    @include breakpoint(tab-max){
        max-width: 100%;
    }
    @include breakpoint(desktop-extra-lite-lg){
        margin-right:47px;
    }
    &__product-devider {
        display: none;
        @include breakpoint(tab) {
            display: block;
            height: 1px;
            border-top: 1px solid $text-light-grey-cap;
        }
        @include breakpoint(mobile) {
            display: block;
            height: 1px;
            border-top: 1px solid $text-light-grey-cap;
        }
        @include breakpoint(ipad-landscape) {
            display: block;
            height: 1px;
        }
    }
    &__product-devider--none {
        display: none;
        @include breakpoint(tab) {
            display: block;
            height: 1px;
            border-top: none;
        }
        @include breakpoint(mobile) {
            display: block;
            height: 1px;
            border-top: none;
        }
        @include breakpoint(ipad-landscape) {
            display: block;
            height: 1px;
            border-top: none;
        }
    }
    &__title, &__shape-label, &__shape-text, &__finish-select-option, &__shape-select-option, &__shape-sub-heading, &__color-sub-heading, &__finish-select, &__modal-shape-title, &__modal-shape-total-options, &__modal-shape-sub-heading, &__modal-shape-apply-btn, &__modal-color-title, &__modal-color-total, &__modal-color-total-label, &__modal-color-filter-label-block, &__modal-color-sub-heading, &__clearall-button, &__modal-color-apply-btn, &__color, &__finish {
        font-family: $lato-regular;
    }
    &__modal-color-filter-block, &__clearall-button {
        cursor: pointer;
    }
    &__overlay {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1001;
        height: 100vh;
        @include breakpoint(mobile) {
            height: 100%;
        }
    }
    &__modal {
        background: rgba(0,0,0,0.5);
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        @include breakpoint(tab-max) {
            background-color: $white;
        }
    }
    &__modal-close {
        display: flex;
        justify-content: flex-end;
        img {
            cursor: pointer;
            height: 31px;
            width: 31.21px;
            margin: 54px 0 0 0;
            @include breakpoint(tab){
                height: 21px;
                width: 21.21px;
                margin: 34.5px 32.79px 0 0;
            }
            @include breakpoint(mobile) {
                height: 21px;
                width: 21.21px;
                margin: 0;
                position: fixed;
                top: 54.5px;
                right: 27.29px;
            }
        }
    }
    @include breakpoint(mobile){

        @media (min-height: 180px) and (max-height: 300px) {
            &__modal-color-image-block, &__modal-image-block{
                height: 115px;
                &>div{
                    height: 115px;
                    min-height: 100px;
                    overflow-y: scroll;
                }
            }       
            &__modal-content-block{
                height: 239px !important;
                overflow-y: scroll;
            }
            &__modal-color-footer-block, &__modal-shape-footer-block{
                height: 40px !important;
                >div{
                    padding: 5px 8px 5px 9px; 
                }
            }

        }
    }
    &__modal-image-block {
        height: 610px;
        width: 505px;
        border-radius: 3px;
        background-color: $color-white;
        padding: 0;
        float: left;
        max-width: 50%;
        margin: 95px 0 0 242px;
        @media (min-width: 1024px) and (max-width: 1535px) {
            width: 100%;
            margin: 0;
            border-radius: 0;
            max-width: 50%;
       }
        @include breakpoint(tab) {
            width: 100%;
            margin: 0;
            border-radius: 0;
            max-width: 50%;
        }
        @include breakpoint(mobile) {
            width: 100%;
            max-width: 100%;
            height: auto;
            margin: 0;
            border-radius: 0;
            float: none;
        }
    }
    &__modal-image-box, &__modal-color-image-box {
        height: 100%;
        width: 100%;
        text-align: center;
        padding: 81px 49px;
        margin: 0;
        background-color: $color-white;
        box-sizing: border-box;
    }
    &__modal-image-box {
        @include breakpoint(tab) {
            margin: 60.73px 56.72px 60.37px 0;
            padding: 0;
        }
        @include breakpoint(mobile) {
            margin: 0;
            padding: 58.62px 58.95px 58.62px 59.95px;
        }
    }
    &__modal-color-image-box {
       @media (min-width: 1024px) and (max-width: 1535px) {
            padding: 24px 0 0;
            margin: 0;
       }
        @include breakpoint(tab) {
            padding: 24px 0 0;
            margin: 0;
        }
        @include breakpoint(mobile) {
            padding: 60px !important;
            margin: 0;
            min-height: 375px;
            max-height:375px;

        }
    }
    &__modal-content-block {
        height: 100%;
        max-width: 776px;
        width: 100%;
        padding-left: 80px;
        padding-right: 50px;
        padding-top: 116px;
        position: relative;
        background-color: $cool-light-grey;
        @include breakpoint(desktop-lg){
            max-width: 650px;
            padding-left: 60px;
            padding-right:90px;
        }
        @include breakpoint(desktop-extra-sm){
            max-width: 50%;
            padding-left: 50px;
            padding-right: 35px;
        }
        @include breakpoint(tab-max){
            max-width: 442px;
            padding-left: 50px;
            padding-right: 35px;
            padding-top:35px;
        }
        @include breakpoint(mobile){
            max-width: 100%;
            padding-top: 0;
            position: static;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__modal-image-preview, &__modal-color-image-preview {
        height: auto;
        width: 100%;
        max-height: 100%;
    }
    &__modal-image-preview {
        @include breakpoint(tab) {
            width: 253.86px;
            height: 253.9px;
        }
        @include breakpoint(mobile) {
            height: 256.14px;
            width: 256.1px;
        }
    }
    &__modal-color-image-preview {
        @include breakpoint(tab) {
            width: 322.54px;
        }
        @include breakpoint(mobile) {
            width: 100%;
        }
    }
    &__modal-color-title {
        min-height: 32px;
    }
    &__modal-shape-title, &__modal-color-title {
        color: $text-dark-grey;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        @include breakpoint(tab) {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 28px;
        }
        @include breakpoint(mobile) {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 28px;
            padding-top: 30px;
        }
    }
    &__modal-shape-total-options, &__modal-color-total-options {
        color: $text-medium-dark-grey;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 4px;
        @include breakpoint(tab) {
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;
            margin-top: 3px;
        }
        @include breakpoint(mobile) {
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin-top: 3px;
        }
    }
    &__modal-shape-total-options {
        margin-bottom: 39px;
        @include breakpoint(tab) {
            margin-bottom: 0;
        }
        @include breakpoint(mobile) {
            margin-bottom: 29.5px;
        }
    }
    &__modal-color-total-options {
        @include breakpoint(tab) {
            width: 320px;
            margin-bottom: 0;
        }
        @include breakpoint(mobile) {
            width: auto;
            margin-bottom: 0;
        }
    }
    &__modal-color-total-label, &__modal-color-filter-count {
        padding-left: 3px;
    }
    &__modal-color-total-block {
        float: left;
    }
    &__modal-color-filter-block {
        float: right;
        min-width: 132px;
    }
    &__modal-shape-title-block {
        margin: 0 0 0 61px;
        @include breakpoint(tab) {
            margin: -44.5px 0 0 50px;
            padding-bottom: 24.5px;
        }
        @include breakpoint(mobile) {
            margin: 0 0 0 20px;
        }
    }
    &__modal-color-title-block {
        padding-bottom: 30px;
        @include breakpoint(tab-max) {
            padding-bottom: 13px;
        }
        @include breakpoint(mobile) {
            margin:0;
            padding: 0 18px 30px;
            box-shadow: 0 8px 10px rgba(102,102,102,0.05);
        }
    }
    &__modal-shape-total-label {
        padding-left: 5px;
    }
    &__modal-select-box-block {
        height: 600px;
        width: 479px;
        margin: 0 0 6px 61px;
        border-top: 1px solid $cool-dark-grey-cap;
        @include breakpoint(tab) {
            margin: 0 0 4.5px 50px;
            width: 320px;
        }
        @include breakpoint(mobile) {
            margin: 0 18px 0 19px;
            width: auto;
            height: 500px;
        }
    }
    &__modal-color-select-block {
        height: 600px;
        clear: both;
        border-top: 1px solid $cool-dark-grey-cap;
        @include breakpoint(mobile) {
            margin: 0 16px 0 20px;
            width: auto;
            height: 500px;
            border-top:0;
        }
    }
    &__modal-shape-custom-select {
        height: 395px;
        width: 479px;
        margin-top: 9.5px;
        overflow: auto;
        @include breakpoint(tab) {
            margin-top: 4.5px;
            width: 320px;
            height: 180px;
        }
        @include breakpoint(mobile) {
            width: auto;
            height: 230px;
            margin-top: 29.5px;
        }
    }
    &__modal-color-custom-select {
        margin-top: 30px;
        height: calc(100vh - 370px);
        min-height: 260px;
        overflow: auto;
        @include breakpoint(tab) {
            min-height: 170px;
            padding-bottom: 30px;
        }
        @media only screen and (max-width: 812px) and (max-height: 400px) {
            min-height: 120px;
        }
        @include breakpoint(tab){
          min-height: 170px;
        }
        @include breakpoint(mobile) {
            margin-top: 0;
            width: auto;
        }
    }
    &__modal-shape-select-option-img {
        height: 80px;
        width: 70px;
        border: 1px solid $cool-dark-grey-cap;
        border-radius: 2px;
        @include breakpoint(tab-max) {
            height: 64px;
            width: 56px;
            border: 1px solid $color-extra-light-gray;
            border-radius: 1.2px;
        }
    }
    &__modal-color-custom-img-block {
        height: 80px;
        width: 70px;
        border: 1.5px solid $cool-dark-grey-cap;
        border-radius: 2px;
        padding: 8px 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(tab) {
            height: 64px;
            width: 56px;
            border: 1px solid $color-extra-light-gray;
            border-radius: 1.2px;
            padding: 7px;
        }
        @include breakpoint(mobile) {
            height: 64px;
            width: 56px;
            border: 1px solid $color-extra-light-gray;
            border-radius: 1.2px;
            padding: 7px;
        }
    }
    &__modal-color-select-option-img {
        min-height: 64px;
        max-width: 56px;
        max-height: 64px;
        object-fit: none;
        @include breakpoint(tab) {
            min-height: 50px;
            height: 51px;
            width: 41px;
          }
          @include breakpoint(mobile) {
            min-height: 50px;
            height: 51px;
            width: 41px;
        }
    }
    &__modal-color-custom-img-block, &__modal-color-sub-heading {
        float: left;
    }
    &__modal-color-li {
        clear: both;
    }
    &__modal-shape-link{
        display: flex;
        align-items: center;
    }
    &__modal-color-sub-heading {
        margin-top: 28px;
    }
    &__modal-shape-select-option-img-active, &__modal-color-custom-img-block-active {
        height: 80px;
        width: 70px;
        border: 1.5px solid $text-dark-grey;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(tab-max) {
            height: 64px;
            width: 56px;
            border: 1px solid $text-dark-grey;
            border-radius: 2px;
        }
    }
    &__modal-shape-sub-heading, &__modal-color-sub-heading {
        color: $text-medium-dark-grey;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        margin-left: 30px;
        @include breakpoint(tab-max) {
            font-size: 14px;
            line-height: 20px;
            margin-left: 15px;
        }
    }
    &__modal-color-sub-heading {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 30px;
        margin-top: 0;
    }
    &__modal-color-filter-icon {
        height: 13.19px;
        width: 21px;
    }
    &__modal-color-filter-icon {
        margin-right: 10px;
    }
    &__modal-color-filter-label-block {
        height: 20px;
        width: 104px;
        color: $text-medium-dark-grey;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
    }
    &__modal-shape-link:hover, &__modal-color-link:hover {
        text-decoration: none;
    }
    &__modal-shape-ul, &__modal-color-ul {
        padding: 0;
        list-style-type:none;
    }
    &__modal-color-ul.m-0{
        @include breakpoint(mobile){
            margin-bottom: 240px!important;
            padding-top:30px;
        }
    }
    &__modal-shape-li, &__modal-color-li {
        padding: 10px 0 20px 0;
        &:first-child{
            padding-top: 0;
        }
        @include breakpoint(tab-max) {
            padding: 10px 0 8px 0;
        }
        @include breakpoint(mobile) {
            padding: 0 0 15px;
        }
    }
    &__modal-shape-footer-block, &__modal-color-footer-block {
        height: 116px;
        width: 100%;
        background-color: $cool-light-grey;
        box-shadow: 0 -10px 20px 0 rgba(0,0,0,0.07);
        position: fixed;
        bottom: 0;
        @include breakpoint(tab) {
            position: fixed;
            bottom: 0;
            height: 70px;
        }
        @include breakpoint(mobile) {
            position: fixed;
            bottom: 0;
            height: 70px;
        }
    }
    &__modal-shape-button-block, &__modal-color-button-block {
        padding: 33px 0px 33px 61px;
        @include breakpoint(tab) {
            padding: 15px 22px 15px 18px;
        }
        @include breakpoint(mobile) {
            padding: 15px 18px;
            text-align: center;
        }
    }
    &__modal-shape-apply-btn {
        width: 119.82px;
    }
    &__modal-color-apply-btn {
        width: 120px; 
    }
    &__modal-shape-apply-btn, &__modal-color-apply-btn  {
        box-sizing: border-box;
        height: 50px;
        border: 1px solid $color-medium-blue-cap;
        border-radius: 1px;
        color: $color-medium-blue-cap;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 2.5px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        margin:0 auto;
        @include breakpoint(tab-max) {
            max-height: 40px;
            width:100%;
            font-size: 11px;
            font-weight: normal;
            letter-spacing: 2.5px;
            line-height: 10px;
        }
    }
    &__modal-color-checkbox-block {
        clear: both;
        height: auto;
        width: auto;
        margin: 10px 0 0 0;
        border: none;
        min-height: 75px;
        display: none;
   
        @media only screen and (max-width: 812px) and (max-height: 400px) {
            min-height: 136px;
            height: 136px;
            overflow-y: scroll;
        }
        @include breakpoint(mobile) {
            margin: 20px 0 0 20px;
            min-height: 85px;
            overflow-y: scroll;
            border-bottom: 1px solid $cool-dark-grey;
        }
        
    }
    &__modal-color-checkbox {
        width: 136px;
        float: left;
        @include breakpoint(tab) {
            width: 146px;
        }
        @include breakpoint(mobile) {
            width: 151px;
        }
    }
    &__modal-clearall-block {
        clear: both;
        padding-top: 5px;
        padding-bottom: 20px;
        @include breakpoint(tab) {
            min-height: 20px;
            padding-bottom: 10px;
        }
        @include breakpoint(mobile) {
            min-height: 20px;
            padding-bottom: 10px;
        }
    }
    &__clearall-button {
        height: 20px;
        width: 51px;
        color: $text-medium-dark-grey;
        @include text-style(13px, 20px, 0.2px);
        text-decoration: underline;
    }
    
    &__title {
        color: $text-dark-grey;
        padding-top: 0;
        margin-bottom: 15px;
        @include text-style(18px, 28px, 0);
        @include breakpoint(tab-max) {
            margin-bottom: 15.53px;
        }
    }
    &__color, &__finish {
        clear: both;
    }
    &__finish{
        margin-top:20px;
    }
    &__shape-img-block {
        margin-top: 15px;
        @include breakpoint(mobile) {
            margin-top: 15.5px;
        }
    }
    &__shape-image{
            &:hover, &:focus, &:focus-within{
                border: 1.5px solid $text-dark-grey;
                border-radius: 2px;
            }
    }
    &__shape-img-block, &__color-img-block {
        @include breakpoint(mobile) {
            display: flex;
            height: 70px;
            width: 357px;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
    &__color-img-block, &__finish-form {
        margin-top: 15px;
    }
    &__finish-form {
        padding-bottom: 19.5px;
        @include breakpoint(tab) {
            padding-bottom: 30.4px;
        }
        @include breakpoint(mobile) {
            padding-bottom: 26.49px;
        }
    }
    &__shape-label, &__color-label, &__finish-label {
        color: $text-medium-dark-grey;
    }
    &__shape-text, &__color-text, &__finish-text {
        color: $text-dark-grey;
        margin-left: 5px;
    }
    &__shape-text, &__shape-label, &__color-label, &__color-text, &__finish-label, &__finish-text {
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 20px;
    }
    &__shape-img-block, &__color-img-block {
        margin-bottom: 20px;
        display: flex;
        @include breakpoint(tab) {
            margin-bottom: 0px;
        }
    }
    &__shape-image:nth-child(6), &__color-img-outer-block:nth-child(6) {
        margin-right: 0;
        @include breakpoint(tab) {
            margin-right: 10px;
        }
        @include breakpoint(mobile) {
            margin-right: 10px;
        }
    }
    &__shape-image {
        height: 80px;
        width: 70px;
        border: 1px solid $color-extra-light-gray;
        border-radius: 2px;
        margin-right: 15px;
        cursor: pointer;
        @include breakpoint(desktop-mid){
            width: 55px;
            height: 65px;
        }
        @include breakpoint(tab-max) {
            height: 66px;
            width: 56px;
            margin-right: 10px;
        }
    }
    &__shape-image-active {
        border: 1.5px solid $text-dark-grey;
        border-radius: 2px;
        @include breakpoint(tab-max) {
            height: 65px;
            width: 55px;
        }
    }
    &__shape-tooltip, &__color-tooltip, &__finish-tooltip {
        height: 16px;
        width: 16px;
        cursor: pointer;
    }
    &__shape-tooltip, &__color-tooltip {
        margin: 32px 0 32px 7px;
        @include breakpoint(tab) {
            margin: 25px 0 24px 10px;
        }
        @include breakpoint(mobile) {
            margin: 26px 0 24px 7px;
        }
    }
    &__color-img-outer-block {
        height: 80px;
        width: 70px;
        border: 1px solid $color-extra-light-gray;
        border-radius: 2px;
        margin-right: 15px;
        float: left;
        padding: 8px 7px;
        @include breakpoint(desktop-extra-lite-sm) {
            height: 65px;
            width: 55px;
            padding: 7px;
            margin-right: 10px;
            margin-bottom: 20px;
        }
        &:hover, &:focus, &:focus-within{
            border: 1.5px solid $text-dark-grey;
            border-radius: 2px;
        }
    }
    &__color-img-outer-block-active {
        border: 1.5px solid $text-dark-grey;
        border: 1.5px solid $text-dark-grey;
        border-radius: 2px;
        @include breakpoint(tab) {
            height: 66.5px;
            width: 56.5px;
        }
        @include breakpoint(mobile) {
            height: 66.5px;
            width: 56.5px;
        }  
        border: 1.5px solid $text-dark-grey; 
        border-radius: 2px;
        @include breakpoint(tab) {
            height: 66.5px;
            width: 56.5px;
        }
        @include breakpoint(mobile) {
            height: 66.5px;
            width: 56.5px;
        }  
    }
    &__color-image {
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
    &__finish-select {
        height: 60px;
        width: 93%;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26px;
        color: $text-dark-grey;
        border: 1px solid $cool-dark-grey-cap;
        border-radius: 3px;
        padding: 17px 0 17px 15px;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        // background: url('../resources/resources/images/arrow-down.svg') 95.8%/12.5px no-repeat $cool-light-grey;
        cursor: pointer;
        @include breakpoint(desktop-extra-lite-sm){
            max-height: 50px;
            padding: 12px 0 12px 15px;
        }
        @include breakpoint(tab) {
            width: 381px;
            background-position: 95.5%;
        }
        @include breakpoint(mobile) {
            width: 304px;
            background-position: 95.2%;
        }
    }
    &__finish-select:focus-visible {
        outline: none !important;
    }
    &__finish-select-option {
        color: $text-dark-grey;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 26px;
    }
    &__finish-tooltip {
        margin-left: 22px;
        @include breakpoint(mobile) {
            margin-left: 16px;
        }
    }
    &__shape-custom-select-block, &__color-custom-select-block {
        width: 100%;
        border: 1px solid $cool-dark-grey-cap;
        padding: 0;
        margin-top: 14.5px;
        margin-bottom: 20px;
        float: left;
        @include breakpoint(tab) {
            height: 98px;
            width: 381px;
            margin-top: 15px;
            margin-bottom: 30px;
            padding: 0;
        }
        @include breakpoint(mobile) {
            height: 98px;
            width: 308px;
            margin-top: 15px;
            margin-bottom: 20px;
            padding: 0;
        }
    }
    &__color-custom-select-block {
        @include breakpoint(tab) {
            margin-top: 14.53px;
        }
        @include breakpoint(mobile) {
            margin-top: 14.53px;
        }
    }
    &__shape-select-tooltip-block, &__color-select-tooltip-block {
        height: 16px;
        width: 16px;
        margin-left: 20px;
        @include breakpoint(mobile){
            margin-left: 15px;
        }
    }
    &__shape-select-tooltip {
        height: 16px;
        width: 16px;
        cursor: pointer;
    }
    &__color-select-option-img-block {
        height: 81px;
        width: 71px;
        border: 1.5px solid $text-dark-grey;
        border-radius: 2px;
        padding: 8px 7px 8px 7px;
        float: left;
        @include breakpoint(tab) {
            height: 66.5px;
            width: 56.5px;
            padding: 7px;
        }
        @include breakpoint(mobile) {
            height: 65px;
            width: 55px;
            padding: 7px;
        }
    }
    &__color-select-option-img {
        height: 64px;
        width: 56px;
        @include breakpoint(tab) {
            height: 51px;
            width: 41px;
        }
        @include breakpoint(mobile) {
            height: 51px;
            width: 41px;
        }
    }
    &__shape-select-option-img {
        height: 65px;
        width: 55px;
        border: 1.5px solid $text-dark-grey;
        border-radius: 2px;
        @include breakpoint(desktop-mid){
            width: 55px;
            height: 65px;
        }
        @include breakpoint(tab-max){
            border-radius:1.2px;
        }
        @include breakpoint(tab) {
            height: 66.5px;
            width: 56.5px;
        }
        @include breakpoint(mobile) {
            height: 66.5px;
            width: 56.5px;
        }
    }
    &__shape-sub-heading {
        color: $text-dark-grey;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 29px;
        @include breakpoint(desktop-mid){
            margin-left: 20px;
        }
        @include breakpoint(tab) {
            margin-left: 20px;
        }
        @include breakpoint(mobile) {
            margin-left: 20px;
        }
    }
    &__color-sub-heading {
        color: $text-dark-grey;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 29px;
        float: left;
        margin-top: 33.97px;
        @include breakpoint(tab) {
            margin-left: 20px;
            margin-top: 23px;
        }
        @include breakpoint(mobile) {
            margin-left: 20px;
            margin-top: 23px;
        }
    }
    &__shape-link:hover, &__color-link:hover {
        text-decoration: none;
    }
    &__shape-ul, &__color-ul {
        list-style-type:none;
    }
    &__shape-li, &__color-li {
        padding: 16px 0 16px 17px;
        @include breakpoint(tab){
            padding: 16px 0 16px 17px;
        }
        @include breakpoint(mobile){
            padding: 16.5px 0 16.5px 17.5px;
        }
    }
    &__shape-arrow-down-img, &__color-arrow-down-img {
        height: 9.07px;
        width: 15.73px;
        float: right;
        margin: 32px 27px 0 0;
        @include breakpoint(tab) {
            margin: 28px 14.62px 0 0;
        }
        @include breakpoint(mobile) {
            margin: 29.5px 21px 0 0;
        }
    }
    &__discoutinued-main-block {
        @include breakpoint(tab) {
            margin-top: 30px;
        }
        @include breakpoint(mobile) {
            margin-top: 30px;
        }
        @include breakpoint(ipad-landscape) {
            margin-top: 30px;
        }
    }
    &__discoutinued-block {
        clear: both;
        padding-top: 30.5px;
        margin-bottom: 29.5px;
        @include breakpoint(mobile) {
            margin-left: 0;
            margin-bottom: 30px;
            padding-top: 10.5px;
        }
    }
    &__discoutinued-msg, &__discoutinued-link-lable  {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }
    &__discoutinued-link-block {
        margin-left: 10px;
        @include breakpoint(mobile) {
            margin-left: 0;
        }
    }
    &__discoutinued-link-lable {
        text-decoration: underline;
        @include breakpoint(mobile) {
            display: block;
            padding-top: 10px;
        }
    }
    &__discoutinued-link-lable:hover {
        color: $text-dark-grey;
        text-decoration: underline;
    }
    &__list-tooltip-wrapper {
        position: absolute;
        z-index: 3;
        bottom: 50px;
        right: auto;
        @include breakpoint(mobile){
            bottom: 20px;
            right: auto;
            left: 30px;
        }
    }
    &__list-tooltip-color-wrapper {
        position: absolute;
        z-index: 3;
        bottom: 59px;
        right: auto;
        @include breakpoint(mobile){
            left: -43px !important
        }
    }
    &__list-tooltip--show {
        display: block;
        position: relative;
    }

    &__list-tooltip--hide {
        display: none;
    }
    @include breakpoint(mobile){
        &__shape-link{
            display: flex;
            align-items: center;
        }
        &__shape-li{
            display: flex;
            justify-content: space-between;
        }
    }
    &__finish-form{
        .form{
            position: relative;
            display: flex;
            align-items: center;
        }
        .product-select-style{
            &__list-tooltip--show{
                position: static;
            }
            &__list-tooltip-color-wrapper{
                left: 0;
            }
        }
        .select-dropdown {
            width: 93%;
            @include breakpoint(tab) {
                width: 381px;
            }
            @include breakpoint(mobile) {
                width: 304px;
            }
            .select-dropdown__placeholder{
                display: none;
            }
            .select-dropdown__value{
                bottom: 0;
            }
        }
    }
}

.product-select-style__modal-color-checkbox-block {
    .checkbox {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 12px;
        letter-spacing: 0.2px;
        line-height: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-bottom: 15px;
        width: max-content;
        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }
        &__color-count {
            padding-left: 3px
        }
        input {
            visibility: hidden;
            position: absolute;
        }
        &__marker {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 16px;
            width: 16px;
            border: 1px solid $cool-dark-grey-cap;
            margin-right: 9.5px;
            flex-shrink: 0;
            &-icon {
                height: 7.42px;
                width: 9.13px;
            }
        }
        input:checked &__marker {
            border: 1px solid $color-light-blue;
        }
        &__black {
            input:checked ~ .checkbox__marker {
                background: $color-light-blue;;
            }
            input:checked ~ span {
                color: $color-light-blue;;
            }
        }
        .checkbox__marker {
            position: unset;
        }
    }
}
.product-select-style__modal-color-li{
    .product-select-style__modal-color-link{
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover{
            .product-select-style__modal-color-custom-img-block{
                border: 1.5px solid $text-dark-grey;
            }
        }
    }
    .product-select-style__modal-shape-link{
        &:hover{
            .product-select-style__modal-shape-select-option-img{
                border: 1.5px solid $text-dark-grey;
            }
        }
    }
}

.select-style-popup{
    display: flex;
    width: 100%;
    height: 100vh;
    @include breakpoint(mobile){
        display: block;
    }
   
    .select-style-view{
        width: 67%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(mobile){
            width: 100%;
        }
    }
    .select-style-view-img{
        max-width: 615px;
        width: 100%;
        height: 750px;
        padding: 40px;
        display: flex;
        align-items: center;
        @include breakpoint(desktop-extra-sm){
            max-width: 90%;
            height: 480px;
        }
        @include breakpoint(tab-max){
            max-width: 323px;
            height: 326px;
            padding: 0;
        }
        @include breakpoint(mobile){
            max-width: 100%;
            height: 375px;
        }
        .product-select-style__modal-color-image-preview{
            @include breakpoint(mobile){
                max-width: 375px;
            }
        }
    }
    .select-style-info{
        animation: slideStyleleft 1s linear forwards;
        @include breakpoint(mobile){
            animation:unset;
        }
        .product-select-style__modal-color-footer-block{
            position: absolute;
            left: 0;
            bottom: 0;
            @include breakpoint(tab){
                position: fixed;
                bottom: 2rem!important;
            }
            @include breakpoint(tab-max){
                height: auto;
            }
        }
    }
    .product-select-style__modal-close{
        position: absolute;
        right: 50px;
        top: 50px;
        @include breakpoint(tab-max){
            top: 34px;
        }
        img{
            margin: 0;
            @include breakpoint(mobile){
                width:40px;
                height:40px;
                top: 25px;
                right: 18px;
            }
        }
    }
    .product-select-style__modal-color-apply-btn{
        @include breakpoint(tab-max){
            width: 100%;
        }
    }
    &.shape-block{
        .select-style-view-img{
            @include breakpoint(desktop-large){
                max-width: 750px;
            }
        }
        .product-select-style__modal-content-block{
            @include breakpoint(desktop-large){
                padding-left: 0;
                padding-right: 0;
            }
        }
        .product-select-style__modal-color-title-block{
            @include breakpoint(desktop-large){
                padding: 0 50px 30px 80px;
            }
        }
        .product-select-style__modal-color-custom-select{
            @include breakpoint(desktop-large){
                padding: 30px 50px 0 5pc;
            }
            overflow: auto;
            padding-top: 30px;
            margin: 0;
            background: linear-gradient($cool-light-grey 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), transparent 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) 0 100%;
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: 100% 40px, 100% 40px, 100% 20px, 100% 20px;
            background-attachment: local, local, scroll, scroll;
        }
    }
}
.select-style-view{
    animation: fadein 0.5s linear forwards;
    @include breakpoint(mobile){
        animation:unset;
    }
}
.select-style-view-img{
    animation: fadein 1.3s linear forwards;
    @include breakpoint(mobile){
        animation:unset;
    }
}
.product-select-style__overlay-close{
    .select-style-view{
        animation: fadeout 0.5s linear forwards;
        @include breakpoint(mobile){
            animation:unset;
        }
    }
    .select-style-info{
        animation: slideStyleright 1s linear forwards;
        @include breakpoint(mobile){
            animation:unset;
        }
    }
    .select-style-view-img{
        animation: fadeout 0.5s linear forwards;
        @include breakpoint(mobile){
            animation:unset;
        }
    }
}
@keyframes fadein {
    0% { 
        opacity: 0;
     }
    70% { 
        opacity: 1;
     }
}
@keyframes fadeout {
    0% { 
        opacity: 1;
     }
    100% { 
        opacity: 0;
     }
}

@keyframes slideStyleleft {
    0% { 
        opacity: .3;
        transform: translateX(50%);
     }
    50% { 
        opacity: 1;
        transform: translateX(0);
     }
}
@keyframes slideStyleright {
    0% { 
        opacity: 1;
        transform: translateX(0);
     }
    100% { 
        opacity: 0;
        transform: translateX(100%);
     }
}
.product-select-style__modal-color-select-block{
    &.filter-open{
        .product-select-style__modal-color-custom-select{
            height: calc(100vh - 475px);
            @include breakpoint(desktop-extra-sm){
                min-height: 145px;
            }
            @include breakpoint(mobile){
                height: 75px;
            }
        }
    }
}
.product-select-style{
    .product-select-style__overlay{
        .product-select-style__modal-row{
            @media only screen and (min-device-width: 480px) 
            and (max-device-width: 740px) 
            and (orientation: landscape) {
                height: calc(100vh - 70px);
                width: 100%;
                position: fixed;
                overflow: scroll;
                background:$cool-light-grey;
            }
           .product-select-style__modal-color-custom-select{
            @media only screen and (min-device-width: 480px) 
            and (max-device-width: 740px) 
            and (orientation: landscape) {
                height: 100vh;
                overflow: unset;
            }
           }
           .product-select-style__modal-color-footer-block{
            @media only screen and (min-device-width: 480px) 
            and (max-device-width: 740px) 
            and (orientation: landscape) {
                position: fixed;
            }
           }
        }
    }
}
.color-title-block{
    .product-select-style__modal-color-total-options{
        display: flex;
        justify-content: space-between;
    }
}
.product-color-dropdown{
    .product-select-style__color-li{
        display: flex;
        justify-content: space-between;
    }
}