// Import break points Scss
@import "@/styles/mixins/_breakpoint";
#main-content {
  opacity: 1 !important;
}

.cart-landing {
  @media only screen and (min-width: 1024px) and (max-width: 1100px) {
    .cart-item-list-section {
      max-width: 68%;
    }
  }
  @media only screen and (min-width: 1100px) and (max-width: 1100px) {
    .cart-item-list-section {
      max-width: 69%;
    }
  }
  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .cart-item-list-section {
      max-width: 71%;
    }
  }
  @media screen and (max-width: 1023px) {
    margin-top: 30px;
  }
  &__content {
    &.no-padding {
      padding: 0;
    }
    margin: 0 0 50px 0;
    @media screen and (max-width: 1060px) {
      margin: 0 0 30px 0;
    }
  }
  &__header {
    margin-bottom: 40px;
  }
  &__back {
    cursor: pointer;
    font-family: $font-regular;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
  &__support {
    text-align: left;
    margin-left: 40px;
    div {
      margin-bottom: 10px;
      font-family: $font-light;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.55px;
      color: $color-slate;
    }
    a {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }
  }

  @media only screen and (max-width: 1023px) {
    &__content {
      padding-right: 0;
    }
  }
}
// Cart Landing Page UI Enhancement
.cart-landing {
  margin-top: 3.4375rem;
  @include breakpoint(tab-landscape) {
    margin-top: 1.875rem;
  }
  .kf-react-container.cart-landing {
    max-width: 1920px !important;
    padding: 0 2.6% !important;
    @include breakpoint(desktop-large) {
      padding: 0 50px !important;
    }
    @include breakpoint(mobile-landscape) {
      padding: 0 32px !important;
    }
    @include breakpoint(mobile-potrait) {
      padding: 0 18px !important;
    }
    @include breakpoint(tab-landscape) {
      max-width: 100% !important;
    }
    .row-space-between {
      width: 100%;
      @include breakpoint(desktop-small) {
        justify-content: space-between;
      }
    }
    //Product list and order summery
    .cart-item {
      &-list-section {
        max-width: 73.91%;
        flex: none;
        @include breakpoint(tab-landscape) {
          max-width: 100%;
        }
        .cart-landing__content {
          .cart-items {
            .cart-header {
              .cart-items {
                &__title {
                  @include text-styles($font-light, 45px, 56px, -3.187px);
                  font-weight: 300;
                  @include breakpoint(tab-landscape) {
                    @include text-styles($font-light, 28px, 36px, -1.983px);
                  }
                }
              }
              .cart-id-share {
                .share-cart-id {
                  @include text-styles($font-regular, 14px, 22px, -0.3px);
                  color: $color-slate;
                }
                .download-specs__cta {
                  span {
                    @include text-styles($font-medium, 14px, 22px, -0.3px);
                    color: $color-slate;
                  }
                }
              }
              .mobile-share-cart-id {
                @include breakpoint(mobile-potrait) {
                  margin-bottom: 0;
                }
              }
              @include breakpoint(mobile-potrait) {
                padding-bottom: 20px;
              }
            }
            &__td {
              @include text-styles($font-bold, 14px, normal, 1.27px);
              font-weight: 700;
            }
            .cart-table-header {
              div:last-child {
                .row {
                  @include breakpoint(tab-landscape) {
                    display: flex;
                    justify-content: space-between;
                  }
                  div {
                    &:first-child {
                      order: 1;
                      text-align: left !important;
                    }
                    &:nth-child(2) {
                      order: 0;
                      @include breakpoint(tab-landscape) {
                        display: none !important;
                      }
                    }
                    &:last-child {
                      order: 2;
                    }
                  }
                }
              }
              @include breakpoint(mobile-landscape) {
                justify-content: space-between;
              }
              div:first-child {
                @include breakpoint(mobile-landscape) {
                  max-width: 54%;
                }
              }
              div:last-child {
                @include breakpoint(mobile-landscape) {
                  max-width: 42.91%;
                }
                .row {
                  @include breakpoint(mobile-landscape) {
                    max-width: 100%;
                  }
                }
              }
            }
            .cart-item {
              @include breakpoint(mobile-potrait) {
                padding-bottom: 20px;
              }
              @include breakpoint(mobile-landscape) {
                padding-top: 30px;
              }
              &__info {
                .cart-item__qty-price {
                  .cart-item {
                    &__qty {
                      order: 1;
                      text-align: left;
                      justify-content: left;
                      .quantity-selector.small {
                        min-width: 111px;
                      }
                    }
                    &__discount-container {
                      order: 0;
                      @include breakpoint(tab-landscape) {
                        display: block !important;
                        order: 2;
                      }
                    }
                    &__total {
                      order: 2;
                      @include breakpoint(mobile-landscape) {
                        display: block;
                      }
                      &.hide-price-popup-mobile {
                        @include breakpoint(mobile-landscape) {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
              &__image {
                margin-right: 1.25rem;
                width: 14.8125rem;
                height: 14.8125rem;
                object-fit: cover;
                @include breakpoint(desktop-extra-sm) {
                  width: 12rem;
                  height: 12rem;
                }
                @include breakpoint(tab-landscape) {
                  margin-right: 0.9375rem;
                  width: 9.375rem;
                  height: 9.375rem;
                }
                @include breakpoint(mobile-potrait) {
                  width: 6.25rem;
                  height: 6.25rem;
                }
              }
              &__brand {
                margin-bottom: 2px;
              }
              &__brand-name {
                @include text-styles($font-medium, 14px, 22px, -0.3px);
                text-transform: uppercase;
                margin: 0;
                @include breakpoint(mobile-potrait) {
                  width: 100%;
                  display: block;
                }
              }
              &__name {
                @include text-styles($font-regular, 16px, 24px, -0.55px);
                color: $slate;
              }
              &__desc {
                @include text-styles($font-regular, 16px, 24px, -0.55px);
                color: $slate;
              }
              &__color {
                @include text-styles($font-regular, 14px, 22px, -0.45px);
                margin: 0 0 5px 0;
                color: $slate;
              }
              &__specs {
                width: 18.5625rem;
                @include breakpoint(desktop-extra-sm) {
                  margin-right: 0.9375rem;
                }
                @include breakpoint(mobile-landscape) {
                  width: 16.25rem;
                }
                @include breakpoint(mobile-potrait) {
                  width: 100%;
                  margin-bottom: 0;
                }
                .cart-item-desc__name {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }
              &__qty-mobile {
                @include breakpoint(desktop-small) {
                  display: none;
                }
                @include breakpoint(tab-max) {
                  display: none;
                }
                @include breakpoint(mobile-potrait) {
                  margin-right: 15px;
                }
              }
              &__sku {
                @include text-styles($font-regular, 14px, 22px, -0.45px);
                margin: 0 0 5px 0;
                color: $slate;
                @include breakpoint(mobile-potrait) {
                  float: unset;
                  text-align: left;
                  display: flex;
                }
              }
              &__total {
                @include breakpoint(mobile-landscape) {
                  max-width: 100%;
                }
              }
              &__price {
                @include text-styles($font-regular, 16px, 24px, -0.4px);
                font-weight: 300;
                @include breakpoint(mobile-landscape) {
                  display: flex;
                  flex-direction: column-reverse;
                  align-items: end;
                  line-height: 0;
                }
                @include breakpoint(mobile-potrait) {
                  line-height: 0;
                }
                .cart-item__total-price {
                  @include breakpoint(desktop-small) {
                    font-family: $font-regular;
                  }
                }
              }
              &__total-price {
                @include text-styles($font-medium, 16px, 24px, -0.35px);
                font-weight: 500;
              }
              &__cta-wrapper {
                @include breakpoint(desktop-small) {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                }
                @include breakpoint(mobile-landscape) {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                }
                @include breakpoint(mobile-potrait) {
                  margin: 20px 0 0 0;
                }
                .remove-sec-cart {
                  .cart-item__cta {
                    span {
                      @include text-styles($font-regular, 14px, 22px, -0.45px);
                      color: $color-charcoal;
                      @include breakpoint(mobile-potrait) {
                        @include text-styles($font-regular, 14px, 22px, -0.3px);
                      }
                    }
                  }
                }
              }
              &__stk-massage {
                .cart-stock-msg {
                  padding-top: 10px;
                }
                .cart-stock-message-mobile {
                  @include breakpoint(tab-landscape) {
                    padding-top: 10px;
                  }
                }
              }
              &__name-desc {
                margin-bottom: 15px;
                display: inline-block;
              }
              &__qty-price {
                &.cart-qty-price-mobile {
                  .cart-item__qty-mobile {
                    @include breakpoint(mobile-potrait) {
                      display: block;
                      width: 6.25rem;
                    }
                  }
                }
              }
              &__span {
                &.car-total-qty-mobile {
                  @include breakpoint(mobile-landscape) {
                    display: block;
                    margin: 0;
                    padding-top: 3px;
                  }
                  @include breakpoint(mobile-potrait) {
                    margin: 0;
                    display: inline-block;
                    padding-top: 3px;
                  }
                }
                &.car-total-qty-desktop {
                  @include breakpoint(mobile-landscape) {
                    display: none;
                  }
                }
              }
              .cart-item__stock-msg.--error {
                @include breakpoint(mobile-potrait) {
                  justify-content: flex-start;
                }
              }
              .quantity-selector__count {
                @include text-styles($font-regular, 18px, 26px, -0.5px);
                font-weight: 400;
              }
              .cart-blank-section {
                margin-right: 20px;
                width: 237px;
                @include breakpoint(tab-landscape) {
                  margin-right: 15px;
                  width: 150px;
                }
              }
              .cart-qty-price-mobile {
                @include breakpoint(tab-landscape) {
                  justify-content: space-between;
                }
                .hide-mobile-price {
                  @include breakpoint(tab-landscape) {
                    display: none;
                  }
                }
              }
              .clear {
                @include breakpoint(tab-landscape) {
                  display: none;
                }
              }
              @include breakpoint(mobile-landscape) {
                justify-content: space-between;
              }
              .mobile-full-width {
                @include breakpoint(mobile-landscape) {
                  max-width: 54%;
                }
              }
              .cart-item__info {
                @include breakpoint(mobile-landscape) {
                  max-width: 42.91%;
                }
              }
            }
            .cart-td-unit-price,
            .discount-container-padding {
              padding: 0;
            }
          }
        }
      }
    }
    .order-summary-cart {
      width: 21.704%;
      flex: none;
      @include breakpoint(tab-landscape) {
        width: 100%;
      }
      .order-summary {
        &__title {
          @include text-styles($font-bold, 20px, normal, 1.818px);
          font-weight: 300;
        }
        &__item--label,
        &__item--value {
          font-weight: 400;
          letter-spacing: -0.4px;
        }
        &__input-group {
          div:first-child {
            @include breakpoint(tab-landscape) {
              width: 100%;
            }
          }
        }
        @include breakpoint(tab-landscape) {
          padding-top: 0;
          padding-bottom: 0;
        }
        .order-summary-mobile-sticky {
          &.isSticky {
            @include breakpoint(mobile-potrait) {
              padding: 12px 18px;
            }
          }
          .order-summary {
            &__item--label,
            &__item--value {
              font-weight: 500;
              letter-spacing: -0.35px;
            }
          }
        }
        .checkout-btn-group {
          a {
            background-color: $primary-blue;
            @include text-styles($font-medium, 18px, 26px, -0.35px);
            font-weight: 500;
          }
          &.disabled {
            a {
              background: $color-light-gray;
            }
          }
        }
        .estimated-total {
          .order-summary__item--value-large {
            @include text-styles($font-medium, 18px, 26px, -0.35px);
            color: $charcoal;
          }
        }
      }
      .cart-accordian {
        .header {
          font-weight: 400;
          letter-spacing: -0.4px;
          &::after {
            margin-right: 3px;
          }
        }
      }
    }
  }
  .cart-info {
    &__desc {
      padding-bottom: 30px;
      @include text-styles($font-regular, 16px, 24px, -0.4px);
      color: $color-slate;
      span {
        @include text-styles($font-medium, 16px, 24px, -0.35px);
        color: $color-charcoal;
      }
    }
    &__title {
      display: flex;
      flex-direction: column;
      margin: 0;
      span {
        @include text-styles($font-regular, 16px, 24px, -0.55px);
        color: $slate;
        margin-bottom: 5px;
      }
    }
    &__link {
      @include text-styles($font-medium, 14px, 22px, -0.45px);
      color: $charcoal;
      text-decoration: underline;
    }
    &__brand-title {
      margin-top: 10px;
      @include text-styles($font-regular, 14px, 22px, -0.45px);
      color: $slate;
      @include breakpoint(mobile-potrait) {
        display: flex;
      }
    }
  }
}
.cart-accordion-section {
  .order-summary__input-group {
    .Input_inputFieldWrapper__nt6o0 {
      .order-summary__input {
        .input-field__border {
          .input-field__value {
            label {
              font-size: 10px;
              line-height: 22px;
              letter-spacing: -0.3px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.cart-accordion-section {
  .order-summary__input-group {
    .Input_inputFieldWrapper__nt6o0 {
      .order-summary__input.--error {
        .input-field__border {
          border: 1px solid $color-error-red;
        }
      }
    }
  }
}
