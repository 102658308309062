.press_releases_list {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
  @include breakpoint(desktop-mid) {
    padding: 0 36px;
  }
  @include breakpoint(tab) {
    padding: 0 2rem;
  }
  @include breakpoint(mobile) {
    padding: 0 1.125rem;
    width: 100%;
  }
  &:last-child {
    padding-bottom: 110px;
    @include breakpoint(tab-max) {
      padding-bottom: 50px;
    }
    .cmp-teaser {
      border-bottom: 1px solid $cool-dark-grey;
    }
  }
  .cmp-teaser {
    padding: 2.5rem 0;
    border-top: 1px solid $cool-dark-grey;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
      padding-top: 1.875rem;
    }
    &__image {
      width: 100%;
      @include breakpoint(desktop-small) {
        max-width: 360px;
      }
      @include breakpoint(tab) {
        max-width: 340px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      width: 600px;
      position: relative;
      @include breakpoint(tab) {
        width: 50%;
      }
      @include breakpoint(mobile) {
        width: 100%;
        margin-top: 1.25rem;
      }
      .cmp-teaser {
        &__pretitle {
          @include ann-describtion(0.875rem, $lato-regular, 1rem);
          letter-spacing: 0.125rem;
          color: $text-medium-dark-grey !important;
          padding-bottom: 1.25rem;
          text-transform: uppercase;
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            margin: 0;
            @include ann-describtion(0.875rem, $lato-regular, 1rem);
            letter-spacing: 0.125rem;
            color: $text-medium-dark-grey !important;
          }
          @include breakpoint(tab) {
            padding-bottom: 0.625rem;
          }
          @include breakpoint(mobile) {
            padding-bottom: 1rem;
          }
        }
        &__title {
          margin: 0;
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            @include ann-describtion(2.25rem, $playfair-regular, normal);
            letter-spacing: -0.125rem;
            color: $text-dark-grey;
            padding-bottom: 0.9375rem;
            margin: 0;
            @include breakpoint(tab-max) {
              @include ann-describtion(1.75rem, $playfair-regular, 2.25rem);
              letter-spacing: -0.0469rem;
            }
            @include breakpoint(mobile) {
              padding-bottom: 1.5625rem;
            }
          }
        }
        &__action-container {
          display: flex;
          align-items: center;
          .cmp-teaser__action-link {
            @include ann-describtion(0.6875rem, $lato-regular, 1.5rem);
            letter-spacing: 1.83px;
            color: $color-light-blue;
            border-bottom: 1px solid $color-light-blue;
            margin-right: 1.5625rem!important;
            text-decoration: none!important;
          }
        }
        &__icons {
          width: 100px;
          position: absolute;
          bottom: 0;
          left: 104px;
          visibility: visible;
          opacity: 1;
          top: unset!important;
          .download {
            &::before{
              display: none;
            }
            a {
              @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
              color: $text-medium-dark-grey;
              display: flex;
              &:before {
                content: "\e934";
                margin-right: 8px;
                font-family: $icon-moon;
                font-size: 18.28px;
                height: 16.76px;
              }
            }
          }
        }
      }
    }
  }
}

.teaser{
&.external-link {
  .cmp-teaser {
    max-width: 1380px;
    margin: 0 auto;
    padding: 40px 0;
    text-align: center;
    &__content{
      .cmp-teaser{
        &__action-container{
          a.cmp-teaser__action-link{
              @include ann-describtion(1rem, $lato-regular, 1.5rem);
              color: $text-medium-dark-grey;
              text-transform: capitalize;
              letter-spacing: normal;
              &::before {
                content: "";
                background-image: url(../images/External.svg);
                font-size: 0.875rem;
                font-family: $icon-moon;
                display: inline-block;
                color: $text-medium-dark-grey;
                margin-right: 0.5rem;
                margin-top: 0.2rem;
                vertical-align: middle;
                width: 15px;
                height: 15px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
              }
            }
          }
      }
    }
  }
}
}