.kf-promo-banner {
    width: 100%;
    height: 100%;
    margin-top: 5px;
    background-color: $color-slate;
    &.--fixed-height {
        @media only screen and (min-width: 1440px) {
            height: 579px;
        }
    }
    &__layout {
        display: grid;
        height: 100%;
        &.--col-1 {
            grid-template-columns: 1fr;
            grid-template-rows: 3fr auto;
            .kf-promo-banner__info {
                padding: 30px 25px;
                align-self: center;
            }
            .kf-promo-banner__desc {
                margin-bottom: 20px;
                p, h1, h2, h3, h4, h5, h6 {
                    color: $color-white;
                }
            }
        }
        &.--col-2 {
            grid-template-columns: 7fr 5fr;
            grid-template-rows: 1fr 80px;
            .kf-promo-banner__image {
                grid-row: 1 / 2;
            }
        }
        &.--col-3 {
            grid-template-columns: 8fr 4fr;
            grid-template-rows: 1fr;
        }
        &.--col-2, &.--col-3 {
            .kf-promo-banner__info {
                padding: 0 30px;
                align-self: center;
                justify-self: center;
                grid-column: 2 / 3;
            }
            .kf-promo-banner__image {
                grid-column: 1 / 2;
            }
            .kf-promo-banner__desc {
                margin-bottom: 40px;
                p, h1, h2, h3, h4, h5, h6 {
                    color: $color-white;
                }
            }
        }
        @media only screen and (min-width: 1440px) {
            &.--col-1 {
                grid-template-rows: 384px auto;
            }
            &.--col-3 {
                grid-template-columns: 753px auto;
            }
            &.--col-2 {
                grid-template-columns: 378px auto;
            }
        }
    }
    &__image {
        width: 100%;
        position: relative;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__info {
        display: block;
    }
    &__desc {
        color: $color-white;
        font-family: $font-light;
        font-size: 36px;
        letter-spacing: -1.7px;
        line-height: 44px;
        p, h1, h2, h3, h4, h5, h6 {
            color: $color-white;
            font-family: $font-light;
            font-size: 36px;
            letter-spacing: -1.7px;
            line-height: 44px;
        }
    }
    &__cta {
        &.ghost {
            color: $color-white;
            border-color: $color-white;
            background-color: transparent;
            line-height: 24px;
            padding: 8px 30px;
            &:hover {
                color: $color-charcoal;
                background-color: $color-white;
            }
        }
    }

    @media screen and (max-width: 991px) {
        height: 407px;
        overflow: hidden;
        &.--fixed-height {
            height: 407px;
            width: 100%;
        }
        &__layout {
            &.--col-1, &.--col-2, &.--col-3 {
                grid-template-columns: 476px auto;
                grid-template-rows: 1fr;
                .kf-promo-banner__image {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                    width: 100%;
                }
                .kf-promo-banner__info {
                    width: 100%;
                    height: 100%;
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                    padding: 0 25px;
                    display: flex;
                    row-gap: 20px;
                    align-items: start;
                    flex-direction: column;
                    justify-content: center;
                }
                .kf-promo-banner__desc {
                    margin-bottom: 0;
                    p, h1, h2, h3, h4, h5, h6 {
                        color: $color-white;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        @media (orientation: portrait) {
            height: 220px;
            &.--fixed-height {
                height: 220px;
            }
            &__layout {
                &.--col-1, &.--col-2, &.--col-3 {
                    grid-template-columns: 142px auto !important;
                    grid-template-rows: 187px auto !important;
                    .kf-promo-banner__info {
                        justify-content: end;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px), screen and (max-width: 991px) and (orientation: landscape) {
        &.--fixed-height {
            width: 100%;
        }
        &__layout {
            &.--col-1, &.--col-2, &.--col-3 {
                .kf-promo-banner {
                    &__image {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                    width: 100%;
                    height: 100%;
                    }
                    &__info {
                        width: 100%;
                        height: 100%;
                        grid-column: 2 / 3;
                        grid-row: 1 / 2;
                        padding: 0 18px;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        align-self: unset;
                        justify-self: left;
                    }
                    &__desc {
                        padding-top: 25px;
                        margin-bottom: 0;
                    }
                    &__desc {
                        color: $color-white;
                        font-size: 26px;
                        letter-spacing: -1.5px;
                        line-height: 34px;
                        p, h1, h2, h3, h4, h5, h6 {
                            color: $color-white;
                            font-size: 26px;
                            letter-spacing: -1.5px;
                            line-height: 34px;
                        }
                    }
                }
            }
        }
    }
}

.product-list {
    @media only screen and (min-width: 992px) and (max-width: 1439px) {
        .col-12.product-list__tile {
            .kf-promo-banner {
                &.--fixed-height {
                    height:579px;
                }
            }
        }
    }
}

.product-list {
    &__tile-col--full-width {
        @media only screen and (min-width: 992px) and (max-width: 1439px) {
            .col-12.product-list__tile {
                .kf-promo-banner {
                    &.--fixed-height {
                        height:738px;
                    }
                }
            }
        }
        .kf-promo-banner {
            &.--fixed-height {
                @media only screen and (min-width: 1440px) {
                    height: 738px;
                }
                @media screen and (max-width: 767px) {
                    @media (orientation: portrait) {
                        height: 220px;
                    }
                }
                @media screen and (max-width: 990px) {
                    @media (orientation: landscape) {
                        height: 407px;
                    }
                }
            }
            &__layout {
                &.--col-2 {
                    grid-template-rows: 1fr 83px;
                }
                &.--col-2, &.--col-3 {
                    .kf-promo-banner__info {
                        padding: 0 60px;
                    }
                    @media screen and (max-width: 991px) {
                        @media (orientation: landscape) {
                            grid-template-rows: 1fr;
                        }
                    }
                }
                @media screen and (max-width: 768px) {
                    &.--col-2, &.--col-3 {
                        grid-template-rows: 1fr;
                        .kf-promo-banner__info {
                            padding: 0 25px;
                            @media (orientation: portrait) {
                                padding: 0 15px;
                            }
                        }
                    }
                }
                @media only screen and (min-width: 1440px) {
                    &.--col-1 {
                        grid-template-rows: 545px auto;
                    }
                    &.--col-2 {
                        grid-template-columns: 496px auto;
                    }
                    &.--col-3 {
                        grid-template-columns: 960px auto;
                    }
                }
            }
        }
    }
}