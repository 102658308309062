.dropdown {
    position: relative;
    top: 0;
    margin: 0 auto;
    border: 1px solid $color-medium-gray;
    border-radius: 4px;
    background: $color-white;
    z-index: 9999;

    &__text {
        color: $color-dark-gray;
        padding: 15px;
        cursor: pointer;
        height: auto;
        font-family: $font-regular;
        font-size: 18px;
        line-height: 1;
        letter-spacing: -0.5px;

        &:after {
            content: "";
            transition: all .3s;
            border: solid $color-slate;
            border-width: 0 1px 1px 0;
            float: right;
            margin-top: 8px;
            margin-right: 6px;
            padding: 5px;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }
    }

    &__items {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        max-height: 0px;
        padding: 16px 0 15px;
    }

    &__item {
        cursor: pointer;
        padding: 10px 18px;

        &:hover {
            color: $color-white;
            background: $color-kohler-blue;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $color-white;
        }
    }

    &.active {
        .dropdown__text {
            &:after {
                margin-top:1px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
        }

        .dropdown__items {
            visibility: visible;
            height: auto;
            max-height: 290px;
            opacity: 1;
            transition: max-height 0.3s ease;
            width: 100%;
            border: 1px solid $color-white;
            margin-top: 10px;
            background: $color-white;
            overflow-y: scroll;
            border-radius: 5px;
            box-shadow: 0 0 20px 0 rgba(0,0,0, 0.5);
        }
    }
}