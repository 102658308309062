.sticky-header {
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.65px;
  color: #757575;
  width: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
  padding: 0;
  overflow-x: auto;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0;
  background-color: #fff;
  // ---
  padding: 0;
  .sticky-header__details-wrap {
    padding: 0;
    border-bottom: 1px solid $cool-dark-grey-cap;
  }
  .sticky-header__container {
    padding: 20px 2.6045%;
    max-width: 1920px;
    @media screen and (max-width: 990px) {
      display: none;
    }
    @include breakpoint(desktop-large) {
      padding: 20px 50px;
    }
    img {
      height: 60px;
      width: 60px;
      border: 1px solid $color-extra-light-gray;
      border-radius: 2px;
    }
    .sticky-header__details {
      margin-left: 20px;
      width: 200px;
      .sticky-header__sku,
      .sticky-header__brand-name {
        color: $text-dark-grey;
        font-family: $lato-regular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        padding-top: 8px;
      }
    }
    .sticky-header__rating-desc {
      width: 280px;
      align-items: start;
      margin-left: 80px;
      .sticky-header__description {
        color: $text-medium-dark-grey;
        font-family: $lato-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        padding-top: 8px;
        margin: 0;
      }
    }
    .sticky-header__shape-desc {
      width: 167px;
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 80px;
      margin-right: 95px;
      padding-top: 5px;
    }
    .sticky-header__color-block {
      height: 40px;
      width: 167px;
      color: $text-medium-dark-grey;
      font-family: $lato-regular;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
    .sticky-header__right {
      align-items: center;
      margin-left: auto;
      display: flex;
      s .sticky-header__status {
        margin-left: 0;
        span {
          font-family: $lato-regular;
        }
      }
      .sticky-header__price-block {
        padding-top: 5px;
        .sticky-header__price-text {
          width: 100%;
          color: $text-medium-dark-grey;
          font-family: $lato-regular;
          font-size: 11px;
          letter-spacing: 1.67px;
          line-height: 14px;
          text-align: right;
        }
        .sticky-header__price {
          width: auto;
          color: $text-dark-grey;
          font-family: $lato-regular;
          font-size: 24px;
          letter-spacing: 0;
          line-height: 36px;
          text-align: right;
        }
        .sticky-header__price-type {
          color: $text-medium-dark-grey;
          font-family: $lato-regular;
          font-size: 10px;
          letter-spacing: 1.67px;
          line-height: 16px;
        }
      }
      .button {
        color: $color-white;
        font-family: $lato-bold;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 2px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        background-color: $maroon;
        margin-left: 0;
        height: 50px;
        min-width: 234px;
        border-radius: 1px;
        padding: 0 !important; // 12px 30.5px 14px;
      }
      .calc__notify-cart-btn {
        background-color: $color-medium-blue-cap;
      }
      .sticky-header__cart-block {
        margin-left: 60px;
        padding-top: 5px;
      }
    }
    padding: 20px 2.6045%;
    max-width: 20in;
    display: flex;
  }
}
.sticky-header .sticky-header__nav-container {
  height: 60px;
  background-color: $white-cap;
  padding: 22px 2.6045%;
  align-items: center;
  max-width: 1920px;
  @include breakpoint(desktop-large) {
    padding: 22px 50px;
  }
  @include breakpoint(tab) {
    height: 46px;
    padding: 15px 0 15px 0;
  }
  @include breakpoint(mobile) {
    height: 46px;
    padding: 15px 0;
  }
}
.sticky-header .sticky-header__nav-container .sticky-header__nav-items a {
  color: $text-light-grey-cap;
  font-family: $lato-regular;
  font-size: 14px;
  letter-spacing: 2.33px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  margin-right: 60px;
  cursor: pointer;
  text-transform: uppercase;
  @include breakpoint(mobile) {
    margin-right: 60px;
    padding: 0;
  }
  @include breakpoint(tab) {
    margin-right: 60px;
    &:last-child {
      margin-right: 46px;
    }
  }
}
.sticky-header
  .sticky-header__nav-container
  .sticky-header__nav-items
  a.sticky-nav-active {
  color: $text-dark-grey;
  text-transform: uppercase;
  font-family: $lato-regular;
  font-size: 14px;
  letter-spacing: 2.33px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.sticky-header .sticky-header__nav-container .sticky-header__nav-items {
  @include breakpoint(desktop-small) {
    margin-left: 0;
  }
  @include breakpoint(tab) {
    margin-left: 32px;
  }
  @include breakpoint(mobile) {
    margin-left: 18px;
  }
}
.product-list__sticky-nav-wrap {
  position: relative;
  z-index: 10;
}
.product-details__sticky-wrapper .sticky {
  z-index: 1;
}
.sticky-header__nav-items {
  a {
    &:first-child {
      @include breakpoint(tab-max) {
        padding-left: 0px;
      }
    }
  }
}
