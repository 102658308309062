@import "@/styles/base/variables";
@import "@/styles/base/fonts";
@import "@/styles/base/_colors";

html[page="rooms inspiration"] {
  overflow-x: inherit;
  scroll-behavior: smooth;
}

.body-scroll-disable {
  overflow: hidden !important;
}
.inspiration-list {
  .checkbox input:checked ~ span {
    color: $text-dark-grey !important;
  }
  .filter {
    .filter__inner-container {
      .filter__mobile-header {
        @include breakpoint(tab-max) {
          margin: 20px 0 25px;
          height: auto;
          border: none;
        }
      }
    }
    .Collapsible {
      &__trigger {
        @include breakpoint(mobile) {
          padding: 15px 0;
        }
        @include breakpoint(tab) {
          padding: 15px 0;
        }
      }
    }
    &__footer {
      position: relative;
      @include breakpoint(tab-max) {
        position: absolute;
      }
    }
    &__apply {
      display: none;
    }
  }
  &__container {
    max-width: 86.25rem;
  }

  &__header {
    margin-bottom: 43px;
  }

  &__curated {
    padding-top: 3.75rem !important;
  }

  &__title-info {
    margin-top: 0;
    height: 9.563rem;
  }

  &__title {
    font-family: $font-regular-base;
  }
  &__print-and-share {
    @include breakpoint(mobile) {
      display: none;
    }
    @include breakpoint(tab) {
      display: none;
    }
  }
  &__total {
    font-family: $font-regular-base;
    font-size: 18px;
    line-height: 28px;
    color: $text-dark-grey;
    @include breakpoint(tab-max) {
      flex-direction: column;
      .inputWithIcon {
        display: none;
        .search {
          max-width: 100%;
          margin-left: 0;
        }
        .search-icon {
          left: 15px;
        }
      }
    }
    .textItem {
      margin-top: 5px;
      letter-spacing: normal;
      @include breakpoint(tab-max) {
        padding-bottom: 15px;
      }
      @media screen and (max-width: 768px) {
        padding-bottom: 15px;
      }
    }
    @media screen and (max-width: 768px) {
      .inputWithIcon {
        display: none;
        .search {
          max-width: 100%;
          margin-left: 0px;
          padding-left: 50px;
          border-radius: 3px;
          border: 1px solid $cool-dark-grey-cap;
          font-family: $lato-regular;
        }
        .search-icon {
          bottom: 32px;
          left: 20px;
        }
      }
    }
  }

  &__filter-toggle {
    font-family: $font-regular-base;
    font-size: 16px;
    background: none;
    border: none;
    outline: none;
    &-text {
      color: $text-dark-grey;
      list-style: none;
      padding: 0;
      margin: 0;
      text-align-last: left;
      height: 24px;
      overflow: hidden;

      li {
        height: 24px;
        transition: all 0.3s ease-in-out;

        &.hide {
          opacity: 0;
          transform: scale(0.9);
        }

        &.show {
          opacity: 1;
        }
      }

      li:last-child {
        transform: translateY(-30%);
      }

      &--show {
        li:last-child {
          transform: translateY(-100%);
        }
      }
    }

    &-icon {
      width: 21px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &__close-icon {
    width: 16px;
    height: 16px;
  }

  &__sticky-nav-wrap {
    position: relative;
    z-index: auto;
  }

  &__sticky-nav {
    transition: all 0.3s ease;
    padding-right: 15px;
    padding-left: 15px;

    &--shadow {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-start;
    height: 65px;
    align-items: center;
    @include breakpoint(mobile) {
      border-top: 1px solid $cool-dark-grey;
    }

    .control-left-section {
      width: 300px;
      position: absolute;
    }

    .control-right-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-left: 350px;
      position: relative;
    }

    section {
      display: flex;
      position: absolute;
      right: 15px;
      min-height: 65px;
      align-items: center;
    }
  }

  &__filter {
    width: 300px;
    height: 99%;
    position: absolute;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    @include breakpoint(mobile) {
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $white;
      padding: 0 18px;
      overflow: hidden;
    }
    @include breakpoint(tab) {
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $white;
      padding: 0 18px;
      overflow: hidden;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    &-curated {
      .filter__print-and-share {
        margin-top: 25px;
      }
    }
    &--hide {
      opacity: 0;
    }

    &--sticky {
      position: sticky;
      top: 80px;
      @include breakpoint(mobile) {
        top: 8px;
      }
      @include breakpoint(tab) {
        top: 8px;
      }
    }
    &--modal {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__tiles {
    margin-top: 10px;
    margin-bottom: 45px;
    @media only screen and (min-width: 1024px) and (max-width: 1100px) {
      margin-bottom: 50px;
    }
  }

  &__tiles-knowledge-articles {
    margin-bottom: 63px;
  }

  &__tile-col {
    transition: all 0.5s ease-in-out;
    margin-left: 360px;
    min-height: calc(100vh - 100px);

    &--full-width {
      margin-left: 15px;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }

  &__tile {
    margin-bottom: 30px;
    padding: 0 2.5px;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0px 2px 0px 20px;
    }
  }

  &__load-more {
    // font-family: $font-bold-base;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2.5px;
    margin: 20px auto 34px;
    text-transform: uppercase;
    width: 100%;
    background: #fff;
    text-align: center;
    &:hover {
      transform: scale(0.95);
      color: #384451;
    }
  }

  &__back-to-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      padding-top: 30px;
    }
  }

  &__print-and-share {
    font-family: $font-regular-base;
    @media screen and (max-width: 768px) {
      padding-left: 10px;
    }
    .social-share__box {
      top: 30px;
      transform: translateX(-50%);
    }

    span {
      margin-right: 30px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        margin-right: 15px;
      }
      img {
        height: 16px;
        margin-left: 10px;
      }
    }
  }

  &__go-back {
    font-family: $font-regular-base;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    color: $text-medium-dark-grey;
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    margin: 0;
    background: 0;
    border: 0;
    outline: 0;
    img {
      width: 9.9px;
      height: 18.95px;
      margin-right: 15px;
    }
  }
  &__tile-col {
    .inspiration-landing-tile__wrapper {
      .inspiration-landing-tile__varient-list {
        display: flex;
        position: absolute;
        z-index: 1;
        bottom: 0;
        transition: all 0.5s ease;
        opacity: 0;
        height: 124px;
        background-color: $cool-light-grey;
        width: 100%;
        gap: 14px;
        padding-left: 13px;
        @media screen and (max-width: 992px) {
          opacity: 1;
        }
        .inspiration-landing-tile__image__varient {
          height: 90px;
          width: 87px;
          padding: 12px 10px 11px 10px;
          background: $white;
          cursor: pointer;
          margin: auto 0px;
        }
        .inspiration-landing-tile__image__varient-image {
          height: 67px;
          width: 67px;
          padding: 0px;
        }
        .inspiration-landing-tile__image__varient-loadmore {
          width: 20%;
          margin-left: 10px;
          margin-top: 7%;
          height: 20px;
          color: $text-medium-dark-grey;
          font-family: $lato-regular;
          font-size: 13px;
          letter-spacing: 0.2px;
          line-height: 20px;
          @include breakpoint(tab-max) {
            height: auto;
            margin-top: 0;
            margin-left: 21px;
          }
        }
      }
      .inspiration-landing-tile__image img {
        width: 31.0625rem !important;
        height: 38rem !important;

        @include breakpoint(desktop-small) {
          height: 415px;
          width: 350px;
        }
        @include breakpoint(desktop-lg) {
          width: 31.0625rem !important;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    &--full-width {
      .inspiration-landing-tile__wrapper {
        .inspiration-landing-tile__varient-list {
          height: 124px;
          @media screen and (max-width: 768px) {
            height: 90px;
          }
          @media screen and (max-width: 991px) {
            height: 90px;
            align-items: center;
          }
          .inspiration-landing-tile__image__varient {
            height: 90px;
            width: 87px;
            padding: 12px 10px 11px 10px;
            margin: auto 0px;
            @media screen and (max-width: 768px) {
              height: 70px;
              width: 67px;
              padding: 0px;
            }
            @media screen and (max-width: 991px) {
              height: 70px;
              width: 72.07px;
              padding: 3px;
              margin-top: 10px;
              margin-left: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .inspiration-landing-tile__image img {
          @include breakpoint(desktop-small) {
            height: 521px;
            width: 440px;
          }
        }
      }
    }
  }
  .d-block {
    @include breakpoint(mobile) {
      display: none !important;
    }
  }
  .inspiration-modal-display-block {
    z-index: 1003;
  }
}

.sorting-section {
  margin-right: -30px;
}
//products per page
.inspiration-list {
  .products-per-page {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ul {
      li {
        font-family: $lato-regular;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        &.disabled {
          color: $text-dark-grey;
        }
        &:first-child {
          color: $text-medium-dark-grey;
          font-size: 10px;
          letter-spacing: 1.7px;
          // margin-right: 18px;
          font-family: $lato-regular;
          text-decoration: none;
        }
        font-family: $lato-regular;
        font-size: 12px;
        letter-spacing: 2px;
        color: $text-medium-dark-grey;
        line-height: 16px;
        text-transform: uppercase;
      }
      li.disabled {
        line-height: 1pc;
        text-transform: uppercase;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4.5px;
        text-decoration-color: #a9a9a9;
      }
      li {
        &:first-child {
          text-decoration: none;
        }
      }
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

//sort
.sort {
  margin-left: 5rem;

  &__toggle {
    font-family: $font-regular-base;

    &-icon {
      width: 16px;
      height: 9px;
      margin-left: 9.5px;
      transform: rotateX(180deg);
      transition: transform 0.5s ease-in-out;
    }
  }
}

//sort
.sort {
  margin-left: 5rem;

  &__toggle {
    font-family: $font-regular-base;

    &-icon {
      width: 16px;
      height: 9px;
      margin-left: 15px;
      transform: rotateX(180deg);
      transition: transform 0.5s ease-in-out;
    }
  }

  &__item {
    font-family: $font-regular-base;
  }

  @media screen and (max-width: 576px) {
    margin-left: 0;

    &__drop-down {
      right: 15px;
    }
  }
}

//filter
.filter {
  height: calc(100vh - 100px);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: thin;
  padding: 0 2px; //required for ADA focus border to be visible
  &--show-less {
    .Collapsible {
      display: none;

      &:nth-child(-n + 7) {
        display: block;
      }
    }
  }

  &__mobile-header {
    display: none;
    @include breakpoint(mobile) {
      justify-content: space-between;
      display: flex;
    }
    @include breakpoint(tab) {
      justify-content: space-between;
      display: flex;
    }
  }

  &__show-more {
    font-family: $font-regular-base;
  }

  &__print-and-share {
    font-family: $font-regular-base;
    @include breakpoint(tab-max) {
      display: none !important;
    }
  }

  .social-share__box {
    margin: 5px auto 15px;
    position: relative;
  }

  .Collapsible {
    @include breakpoint(desktop-large) {
      border-bottom: $cool-dark-grey;
    }
    &.is-open {
      .Collapsible__contentInner {
        opacity: 1;
        .checkbox {
          padding-bottom: 10px;
          font-family: $font-regular-base;
        }
        .inputWithIcon {
          .search-collections {
            border-radius: 3px;
            background: $white;
            padding-left: 37px;
            padding-right: 15px;
            height: 40px;
            border: 1px solid $text-dark-grey;
            // -webkit-appearance: none;
            display: block;
            width: 100%;
            clear: both;
            outline: none;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 26px;
          }
          .search-icon {
            position: relative;
            margin-top: -60px;
            margin-left: 15px;
            height: 17.96px;
            width: 17.96px;
          }
          .search-collections__close-icon {
            position: relative;
            top: -25px;
            float: right;
            left: -10px;
            height: 12px;
            width: 12px;
            cursor: pointer;
          }
        }
      }
    }

    &__trigger {
      width: 100%;
      display: block;
      font-family: $font-regular-base;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.35px;
      color: $text-dark-grey;
      padding: 15px 0px;
      cursor: pointer;

      div {
        display: flex;
        justify-content: space-between;
      }

      &.is-open {
        .plus {
          .line {
            &.line-2 {
              height: 0 !important;
            }
          }
        }
      }
    }

    &__contentInner {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      padding-bottom: 15px;
      .checkbox {
        font-family: $font-regular-base;
      }
    }
  }
}

@media print {
  .filter .Collapsible,
  .filter__show-more {
    display: block !important;
  }
  .inspiration-list__filter--modal {
    width: 100%;
  }
  .inspiration-list__filter {
    width: 300px;
    height: 99%;
    position: absolute;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    display: none;
  }
  .filter__mobile-header {
    display: none !important;
  }
  .inspiration-list__total {
    padding-top: 0px;
  }
  .inspiration-landing-tile {
    display: block;
  }
  .inspiration-detail .inspiration-detail__cart .order-sample__image {
    width: 240px;
    height: 240px;
    margin-bottom: 20px;
  }
  .inspiration-detail .inspiration-detail__cart .order-sample__body {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
  }
  .inspiration-modal-display-block
    .container-fluid
    .row
    .product-details__content-section
    .inspiration-detail__cart
    .order-sample__body {
    flex-wrap: wrap !important;
  }
  body,
  html {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100% !important;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  body {
    overflow: visible !important;
  }
  .inspiration-detail {
    position: relative !important;
    overflow: visible !important;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .page-title .cmp-title__text {
    text-align: center;
  }
  .inspiration-list__sticky-nav-wrap,
  .ann-sacks-logobar,
  .ann-sacks-main-menu,
  .inspiration-list__load-more,
  .inspiration-list__back-to-top {
    display: none !important;
  }
  .inspiration-landing
    .inspiration-list
    .inspiration-list__tiles
    .inspiration-list__tile-col {
    margin: 0 !important;
  }
  .inspiration-modal-display-block {
    overflow: auto;
    .inspiration-detail .container-fluid .row {
      overflow-y: auto;
    }
  }
  .inspiration-landing-main {
    position: unset;
    width: 100%;
    height: 100%;
  }
  .inspiration-list .inspiration-list__tile-col .row.row-cols-3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30.33%, 1fr));
    -webkit-column-gap: 1.45%;
    -moz-column-gap: 1.45%;
    column-gap: 1.45%;
    row-gap: 0;
    margin-right: -0.1pc;
  }
}
.inspiration-landing {
  .inspiration-landing-tile__wrapper {
    .social-share {
      position: absolute;
      right: 0;
      &__box {
        right: 15px;
        top: 50px;
        height: 126px;
        width: 257px;
        position: relative;
        padding: 20px 25px;
        border: 1px solid hsla(0, 0%, 59.2%, 0.1);
        border-radius: 1px;
        background-color: $white;
        z-index: 8;
        margin: 5px auto 15px;
        .social-share__cta {
          white-space: nowrap;
        }
      }
      &__sites {
        column-gap: 25px;
        display: flex;
        justify-content: start;
        ul {
          list-style-type: none;
          display: inline-flex;
          margin: 0;
          overflow-x: auto;
          li {
            padding-bottom: 0;
            margin-right: 1.4375rem;
          }
        }
      }
      &__actions {
        margin-top: 15px;
        padding-top: 20px;
        display: flex;
        justify-content: flex-start;
        border-top: 1px solid #cdcdcd;
      }
    }
    .inspiration-landing-tile {
      .inspiration-landing-tile__share {
        opacity: 0;
        display: flex;
        position: absolute;
        z-index: 1;
        right: 25px;
        top: 15px;
        transition: all 0.5s ease;

        @media screen and (min-width: 330px) and (max-width: 992px) {
          opacity: 0;
        }
      }
    }

    &:hover,
    &.--hover {
      .inspiration-landing-tile {
        .inspiration-landing-tile__image {
          &--hover {
            opacity: 1;
          }
          img:nth-child(2) {
            opacity: 1;
            transform: scale(1);
          }
        }
        .inspiration-landing-tile__colors--count {
          opacity: 0;
        }
        .inspiration-landing-tile__colors--swatch,
        .inspiration-landing-tile__compare {
          opacity: 1;
        }
        .inspiration-landing-tile__share {
          opacity: 1;
          display: flex;
          position: absolute;
          z-index: 1;
          right: 25px;
          top: 15px;
          transition: all 0.5s ease;
          &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            background: #fff;
            border-radius: 5px;
            margin-left: 5px;
            cursor: pointer;
          }
        }

        .inspiration-landing-tile__varient-list {
          opacity: 1;
        }
      }
    }
  }
}

//product tile
.inspiration-landing {
  .inspiration-landing-tile {
    // width: 97%;
    width: 100%;
    position: relative;

    &__image {
      width: 100%;
      padding-bottom: 130%; //for maintaining aspect ratio
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      cursor: pointer;

      div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        mix-blend-mode: multiply; //temp style for demonstration
        transition: all 0.5s ease-in-out;
      }

      img:first-child {
        position: absolute;
        opacity: 1;
      }
      img:nth-child(2) {
        opacity: 1;
      }
    }

    &__badge {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 10px;
      display: flex;
    }
    &__new {
      font-family: $font-regular-base;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.65px;
      margin-bottom: 0px;
      padding: 9px 12px;
      text-align: center;
    }

    &__details {
      display: flex;
      justify-content: space-between;
      padding-right: 25px;
      word-break: break-word;
      div {
        &:first-child {
          flex: 1;
        }
      }
    }

    &__title {
      font-family: $font-regular-base;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.4px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
    }

    &__startingat {
      font-family: $font-regular-base;
      font-size: 14px;
      letter-spacing: -0.7px;
      text-align: right;
    }

    &--lite {
      .inspiration-landing-tile__colors,
      .inspiration-landing-tile__share {
        display: none;
        @media screen and (min-width: 768px) and (max-width: 992px) {
          display: flex;
        }
      }

      .inspiration-landing-tile__details {
        display: block;
        padding-right: 0px;
      }

      .inspiration-landing-tile__title {
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: -0.25px;
      }

      .inspiration-landing-tile__price {
        font-size: 14px;
        padding-left: 0;
      }
    }

    &--loading {
      .loading-image {
        width: 100%;
        padding-bottom: 130%;
        margin-bottom: 20px;
      }
      .loading-title {
        width: 75%;
        height: 10px;
        margin-bottom: 10px;
      }
      .loading-description {
        width: 70%;
        height: 10px;
        margin-bottom: 10px;
      }
      .loading-swatch {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}

.promo-banner {
  height: 100% !important;
  margin-left: -15px;
  margin-right: -15px;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.hero-banner-article-bottom .cmp-teaser__icons {
  .social-share__box {
    display: flex;
    top: 0px;
    right: 5px;
    flex-direction: column;
    align-items: flex-start;
    @include breakpoint(mobile) {
      right: 25%;
      top: 30px;
    }
  }
}

.inspiration-detail {
  .inspiration-detail__gallery {
    margin-left: -15px;
    max-width: 800px;
    @include breakpoint(tab) {
      width: 100%;
    }
    .print-share {
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .social-share {
      left: 0;
      top: 0;
    }
    .social-share__box {
      right: 0;
      top: 0;
      left: 0;
      height: 126px;
      width: 257px;
      position: relative;
    }
    .inspiration-detail-tile {
      &__image {
        min-height: 948px;
        max-height: 948px;
        padding: 0 !important;
        @include breakpoint(tab-max) {
          max-height: 554px;
          min-height: 554px;
        }
        img {
          object-fit: cover;
          max-height: inherit;
        }
      }
    }
    .print-share {
      .print-and-share {
        display: flex;
        .print-button,
        .share-button {
          font-family: $lato-regular;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0;
          img {
            &.print-image,
            &.share-image {
              margin-right: 8px;
            }
          }
        }
        .share-button {
          margin-left: 30px;
        }
      }
    }
    .print-share {
      @include breakpoint(tab-max) {
        display: none !important;
      }
    }
  }
  .inspiration-detail {
    &__cart {
      @include breakpoint(tab) {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 812px) and (max-width: 992px) {
  .inspiration-list {
    &__header {
      margin-bottom: 0;
    }
    &__grid-control {
      padding-bottom: 0;
      .inspiration-list__total {
        display: none;
      }
    }
    &__sticky-nav {
      .inspiration-list__controls {
        padding-top: 32px;
      }
    }
  }
}
.search-result .search-result__tab-content .inspiration-tile {
  @media screen and (min-width: 1024px) and (max-width: 1600px) {
    max-width: 100%;
  }
}
.search-result
  .search-result__tab-content
  .product-list__sticky-nav
  .kf-react-container {
  @media screen and (min-width: 1024px) and (max-width: 1600px) {
    background-color: $cool-light-grey-caps;
  }
}
//Search page Inspiration category
.inspiration-tile__category-name {
  text-transform: uppercase;
  font-family: $lato-regular;
  color: $text-dark-grey;
  // @include text-style(0.75rem, 1rem, 0.125rem);
}
.inspiration-tile__article-title {
  font-family: $playfair-regular;
  color: $text-dark-grey;
  // @include text-style(1.375rem, 1.75rem, -0.0469rem);
  p {
    font-family: $lato-regular;
    color: $text-dark-grey;
    // @include text-style(1rem, 1.5rem, normal);
  }
  .highlight-text {
    font-family: $playfair-regular;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.75px;
    text-align: left;
    font-weight: normal;
  }
}
.inspiration-tile {
  &__img {
    @include breakpoint(desktop-small) {
      height: 127px;
      width: 127px;
    }
    @include breakpoint(desktop-large) {
      height: 145px;
      width: 145px;
    }
    @include breakpoint(tab-max) {
      height: 136px;
      width: 136px;
    }
    @include breakpoint(mobile-portrait) {
      height: 80px;
      width: 80px;
    }
  }
  &__description {
    font-family: $lato-regular;
    color: $text-medium-dark-grey-small;
    // @include text-style(0.875rem, 1.25rem, normal);
  }
}

.inspiration-list__filter--sticky .filter .filter__apply {
  font-family: $lato-bold !important;
}

.inspiration-modal-display-block {
  .inspiration-modal {
    &__closeImg {
      @include breakpoint(mobile) {
        height: 100px;
      }
    }
  }

  .inspiration-landing {
    .inspiration-list {
      &__total {
        @include breakpoint(mobile) {
          height: 42px;
        }
        .inputWithIcon {
          display: none;
          border-bottom: none;
        }
      }
      &__controls {
        @include breakpoint(mobile) {
          top: 0px;
        }
        @include breakpoint(tab-max) {
          top: 0px;
          position: absolute !important;
        }
      }

      @include breakpoint(tab-max) {
        .inspiration-landing-tile {
          &__image {
            padding-bottom: 138%;
            &__varient {
              margin: 0;
            }
          }
          &__varient-list {
            padding: 10px;
          }
        }
      }
      @include breakpoint(tab-max) {
        &__tile {
          margin-bottom: 30px;
        }
      }
      &__sticky-nav-wrap {
        @include breakpoint(mobile) {
          padding-bottom: 15px;
          border-bottom: 1px solid $cool-dark-grey;
        }
      }
    }
  }
  .inspiration-detail {
    .inspiration-detail__gallery {
      margin-top: -90px;
      @include breakpoint(tab) {
        margin-top: 0px;
      }
      @include breakpoint(mobile) {
        margin-top: 0px;
      }
    }
  }
}
.inspiration-detail {
  .inspiration-detail__gallery {
    margin-top: 0px;
    @include breakpoint(tab) {
      width: 100%;
    }
  }
  .order-sample {
    &__body {
      position: relative;
    }
  }
  .inspiration-landing {
    .inspiration-list {
      &__total {
        @include breakpoint(mobile) {
          height: 42px;
        }
        .inputWithIcon {
          display: none;
          border-bottom: none;
        }
      }
      &__controls {
        @include breakpoint(mobile) {
          top: 0px;
        }
        @include breakpoint(tab-max) {
          top: 0px;
        }
      }

      @include breakpoint(tab-max) {
        .inspiration-landing-tile {
          &__image {
            padding-bottom: 138%;
          }
          &__varient-list {
            margin-top: 0;
            display: flex;
            gap: 0;
          }
        }
      }
      @include breakpoint(tab-max) {
        &__tile {
          margin-bottom: 30px;
        }
      }
      &__sticky-nav-wrap {
        @include breakpoint(mobile) {
          padding-bottom: 15px;
          border-bottom: 1px solid $cool-dark-grey;
        }
      }
    }
    .inspiration-list__filter-toggle-icon {
      @include breakpoint(tab-max) {
        margin-top: 3px;
      }
    }
    .inspiration-list__filter-toggle {
      span {
        @include breakpoint(tab-max) {
          max-width: 160px !important;
        }
      }
    }
    .checkbox .checkbox_default {
      text-transform: capitalize;
      letter-spacing: 0.23px;
      color: $text-dark-grey;
      font-size: 14px;
    }
  }
  .inspiration-detail {
    &__cart {
      .order-sample {
        &__name,
        &__description {
          height: auto;
        }
        &__price {
          margin-top: 10px;
        }
      }
    }
  }
  .inspiration-detail {
    &__cart {
      margin-left: 0px;
      .order-sample__card {
        margin-right: 17px;
        margin-left: 17px;
      }
      @include breakpoint(tab) {
        width: 100%;
        padding-left: 0px;
        .order-sample__card {
          width: 40%;
        }
      }
      @include breakpoint(tab-max) {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1600px) {
  .inspiration-list__filter {
    width: 239px;
  }
  .inspiration-list__tile-col {
    margin-left: 299px;
  }
  .inspiration-list {
    .filter__print-and-share {
      padding-bottom: 0;
    }
  }
  .inspiration-list {
    .kf-react-container {
      .row {
        .inspiration-list {
          &__tile-col--full-width {
            margin-left: 0;
            .inspiration-list__tile {
              padding: 0 15px 0 15px;
            }
            .inspiration-landing-tile {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .inspiration-detail {
    flex-wrap: wrap;
    .inspiration-detail__gallery {
      width: 100% !important;
      min-width: 0;
      margin-top: -90px;
      .inspiration-detail-tile__image img {
        height: 600px;
      }
    }
    .inspiration-detail__cart {
      width: 100%;
      padding-left: 25px;
      .order-sample {
        &__card {
          width: 40%;
        }
        &__card:last-child {
          margin-bottom: 60px;
        }
        &__name,
        &__description,
        &__price {
          height: auto;
        }
      }
    }
  }
}

.inspiration-detail .inspiration-detail__cart .order-sample__heading {
  margin-bottom: 50px;
  @include breakpoint(tab) {
    margin-bottom: 50px;
  }
  @include breakpoint(mobile) {
    margin-bottom: 40px;
  }
}
.inspiration-detail .inspiration-detail__cart {
  margin: 80px 0 0 0;
  @include breakpoint(tab) {
    margin: 80px 0 0 0;
  }
  @include breakpoint(mobile) {
    padding: 50px 0 0 0;
    margin: 0;
  }
}
.inspiration-detail .inspiration-detail__cart .order-sample__heading {
  padding-left: 17px;
  @include breakpoint(tab) {
    padding-left: 17px;
  }
  @include breakpoint(mobile) {
    padding-left: 19px;
  }
}
.inspiration-detail .inspiration-detail__cart .order-sample__card {
  @include breakpoint(tab) {
    margin-top: 0;
  }
}
.inspiration_back-to-top {
  justify-content: flex-end;
}

// Inspiration Detail Page
.inspiration-modal-display-block {
  .container-fluid {
    padding: 0;
    .row {
      margin: 0;
      padding: 0;
      display: flex;
      align-content: center;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      @include breakpoint(mobile) {
        flex-direction: column;
      }
      > div {
        width: 49.999999% !important;
        display: unset;
        height: 100%;
        flex: unset;
        padding: 0;
        max-width: 50%;
        margin-top: -6.125rem;
        @include breakpoint(desktop-mid) {
          margin-top: -6.7rem;
        }
        @include breakpoint(tab) {
          width: 46.183% !important;
          margin-top: -4.5625rem;
        }
        @include breakpoint(mobile) {
          width: 100% !important;
          max-width: 100%;
          margin: 0;
        }
        .inspiration-detail__gallery {
          margin: 0;
          padding: 0;
          height: 100%;
          max-width: 100%;
          .photo-credits {
            padding: 1.875rem 0 0 3.125rem;
            // @include ann-describtion(0.6875rem, $lato-regular, 1rem);
            letter-spacing: 0.0125rem;
            color: $text-medium-grey;
            @include breakpoint(tab-max) {
              padding: 2.875rem 0 0 1.125rem;
            }
          }
          .print-share {
            padding-left: 3.125rem;
            @include breakpoint(tab-max) {
              display: none;
            }
            .print-and-share .print-button,
            .share-button {
              // @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
              letter-spacing: 0;
              color: $text-medium-dark-grey;
              padding-top: 1.875rem;
              img {
                margin-right: 0.5rem;
              }
            }
            .share-button {
              margin-left: 1.875rem;
              position: relative;
              .social-share {
                position: absolute;
                top: 0;
                left: 0;
                &__box {
                  right: 15px;
                  top: 50px;
                  height: 126px;
                  width: 257px;
                  position: relative;
                  padding: 20px 25px;
                  border: 1px solid hsla(0, 0%, 59.2%, 0.1);
                  border-radius: 1px;
                  background-color: $white;
                  z-index: 8;
                  margin: 5px auto 15px;
                  .social-share__cta {
                    white-space: nowrap;
                  }
                }
                &__sites {
                  column-gap: 25px;
                  display: flex;
                  justify-content: start;
                  ul {
                    list-style-type: none;
                    display: inline-flex;
                    margin: 0;
                    overflow-x: auto;
                    li {
                      padding-bottom: 0;
                      margin-right: 1.4375rem;
                    }
                  }
                }
                &__actions {
                  margin-top: 15px;
                  padding-top: 20px;
                  display: flex;
                  justify-content: flex-start;
                  border-top: 1px solid #cdcdcd;
                }
              }
            }
          }
          .inspiration-detail-tile__image {
            margin: 0;
            @include breakpoint(tab) {
              min-height: 34.5688rem;
              height: 100%;
            }
            img {
              object-fit: cover;
              height: 100%;
            }
          }
        }
      }
      .product-details__content-section {
        margin: 0;
        padding: 0;
        width: 39.7% !important;
        display: unset;
        flex: unset;
        padding-right: 3.1875rem;
        @include breakpoint(tab) {
          width: 45.813% !important;
          padding-right: 2rem;
        }
        @include breakpoint(mobile) {
          width: 100% !important;
          max-width: 100%;
          padding: 0;
        }
        .inspiration-detail__cart {
          width: 100%;
          padding: 0;
          margin: 0;
          @include breakpoint(mobile) {
            padding: 0 1.125rem;
          }
          .order-sample__body {
            justify-content: space-between;
            width: 100%;
            @include breakpoint(mobile) {
              flex-direction: column;
            }
            .order-sample__card {
              width: 47.664%;
              margin: 0 0 4.375rem 0;
              padding: 0;
              @include breakpoint(tab) {
                width: 47.06%;
              }
              @include breakpoint(mobile) {
                width: 100%;
              }
            }
          }
          .order-sample__heading {
            padding-left: 0;
            margin: 6.25rem 0 4.375rem;
            @include ann-describtion(1.625rem, $lato-regular, 2.25rem);
            letter-spacing: 0;
            color: $text-dark-grey;
            @include breakpoint(tab) {
              margin: 5rem 0 3.125rem;
            }
            @include breakpoint(mobile) {
              margin: 3.125rem 0 2.5rem;
            }
            &::nth-child(2) {
              margin: 1.875rem 0 4.375rem;
            }
          }
          .order-sample__image {
            width: 100%;
            margin: 0 0 0.9375rem;
            @include breakpoint(desktop-small) {
              max-width: 21.1875rem;
              height: 21.8125rem;
              object-fit: cover;
            }
            @include breakpoint(desktop-large) {
              min-width: 339px;
              height: 349px;
            }
          }
          .order-sample__button,
          .order-sample__description,
          .order-sample__name,
          .order-sample__price {
            width: 100%;
            margin-top: 0.625rem;
            @include ann-describtion(1rem, $lato-regular, 1.5rem);
            letter-spacing: 0;
            color: $text-dark-grey;
            @include breakpoint(tab) {
              @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
            }
          }
          .order-sample__button {
            @include ann-describtion(0.75rem, $lato-bold, 1.5rem);
            letter-spacing: 0.125rem;
            color: $white;
            text-transform: uppercase;
            height: 50px;
            @include breakpoint(tab) {
              width: 160px;
            }
          }
        }
      }
    }
  }
  .inspiration-modal__closeImg {
    width: 54% !important;
    height: auto;
    margin: 0;
    padding: 2.125rem 3.1875rem;
    background-color: $cool-light-grey;
    box-shadow: 0 10px 20px 0 $header-shadow;
    display: flex;
    justify-content: flex-end;
    @include breakpoint(tab) {
      padding: 1.375rem 2rem;
    }
    @include breakpoint(mobile) {
      width: 100% !important;
      padding: 2.3256rem 1.1381rem 2.0131rem;
    }
    img {
      width: 30px;
      height: 30px;
      color: $text-medium-dark-grey;
      padding-left: 0.625rem;
    }
    &::before {
      content: "Close";
      @include ann-describtion(1rem, $lato-bold, 1.5rem);
      letter-spacing: 0;
      color: $text-dark-grey;
    }
  }
}

// Inspiration Landing Styles Start
.inspiration-landing {
  .inspiration-list {
    padding: 0 2.6%;
    position: relative;
    @include breakpoint(desktop-large) {
      padding: 0 50px;
    }
    @include breakpoint(tab) {
      padding: 0 32px;
    }
    @include breakpoint(mobile) {
      padding: 0 18px;
    }
    .inspiration-list__header {
      max-width: 1820px !important;
      margin: 0 auto !important;
      padding: 0 !important;
      @include breakpoint(tab-max) {
        max-width: 100% !important;
        margin: 0;
        padding: 0 !important;
      }
      .row {
        margin: 0;
        padding: 0;
      }
      .inspiration-list__title-info {
        padding: 40px 0 44px;
        margin: 0;
        height: auto;
        display: none;
        @include breakpoint(tab-max) {
          margin: 0;
          padding: 40px 0 0 0;
        }
        .inspiration-list__title {
          display: none;
        }
      }
    }
    .inspiration-list__sticky-nav-wrap {
      padding: 40px 0;
      @include breakpoint(tab) {
        padding: 40px 0 0;
      }
      @include breakpoint(mobile) {
        padding: 40px 0 0;
      }
      .inspiration-list__sticky-nav {
        max-width: 1820px;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        .row {
          margin: 0;
          padding: 0;
          .kf-react-container {
            max-width: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
            .row {
              margin: 0;
              > div {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
            .inspiration-list__total {
              padding: 0;
              margin: 0;
              max-height: 100%;
              bottom: unset;
              position: relative;
              letter-spacing: 0;
              display: flex;
              align-items: center;
              @include breakpoint(tab-max) {
                align-items: flex-start;
                width: 100%;
              }
              @include breakpoint(mobile) {
                min-width: unset;
                height: 38px;
              }
              .textItem {
                margin-top: 0;
                font-family: $lato-regular;
                line-height: 32px;
                font-size: 22px;
                letter-spacing: 0;
                display: flex;
                align-items: center;
                width: 14.8125rem;
                color: $text-dark-grey;
                height: 32px;
                @include breakpoint(tab-max) {
                  order: 2;
                  width: 100%;
                  padding: 0;
                  font-size: 18px;
                  line-height: 28px;
                }
              }
              .inputWithIcon {
                display: none;
                height: 40px;
                @include breakpoint(tab-max) {
                  order: 1;
                  flex: 1 1 100%;
                  margin-bottom: 30px;
                  width: 100%;
                }
                @include breakpoint(tab-max) {
                  max-height: 55px;
                }
                .search {
                  height: 100%;
                  margin-left: 31px;
                  font-family: $lato-regular;
                  font-size: 18px;
                  line-height: 26px;
                  letter-spacing: 0;
                  color: $text-dark-grey;
                  border: 1px solid $cool-dark-grey;
                  @include breakpoint(tab-max) {
                    height: 40px;
                    margin-left: 0;
                  }
                  &:focus {
                    border: 1px solid $cool-dark-grey;
                    box-shadow: none;
                  }
                }
                img {
                  bottom: 37px;
                  @include breakpoint(tab-max) {
                    bottom: 35px;
                  }
                }
              }
            }
            .inspiration-list__controls {
              display: flex;
              height: 28px;
              justify-content: flex-end;
              align-items: center;
              @include breakpoint(tab-max) {
                order: 3;
                position: absolute;
                right: 0;
                top: 0px;
                padding: 0;
                border: none;
              }
              @include breakpoint(mobile) {
                top: 0px;
              }
              .inspiration-list__filter-toggle {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                line-height: 1.33px;
                letter-spacing: -0.45px;
                padding: 0;
                margin: 0;
                background: 0;
                border: 0;
                outline: 0;
                .inspiration-list__filter-toggle-icon {
                  margin: 0;
                  @include breakpoint(tab) {
                    width: 21px;
                    height: 14px;
                    color: $slate;
                  }
                  @include breakpoint(mobile) {
                    width: 21px;
                    height: 14px;
                    color: $slate;
                  }
                  @include breakpoint(tab-max) {
                    margin: 0 0 0 10px;
                  }
                }
                .inspiration-list__filter-toggle-text {
                  list-style: none;
                  padding: 0;
                  margin: 0;
                  height: 24px;
                  overflow: hidden;
                  margin-right: 0.5625rem;
                  font-size: 18px;
                  line-height: 28px;
                  letter-spacing: 0;
                  color: $text-dark-grey;
                }
                span {
                  font-family: $lato-regular;
                  font-size: 18px;
                  line-height: 28px;
                  letter-spacing: 0;
                  color: $text-dark-grey;
                  @include breakpoint(tab) {
                    width: 98px;
                  }
                  @include breakpoint(mobile) {
                    max-width: 125px;
                  }
                }
              }
            }
          }
        }
        &.inspiration-list__sticky-nav--shadow {
          padding: 11px 0;
          box-shadow: none;
          max-height: 50px;
          @include breakpoint(tab) {
          }
          @include breakpoint(mobile) {
            height: 81px;
          }
          .inputWithIcon {
            display: none;
          }
          .row {
            .kf-react-container {
              .inspiration-list__controls {
                @include breakpoint(tab-max) {
                  top: 0;
                  border: none;
                }
              }
              .inspiration-list__filter-toggle {
                .inspiration-list__filter-toggle-text {
                  @include breakpoint(tab) {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0;
                    font-weight: normal;
                    text-wrap: nowrap;
                  }
                }
              }
              .filter__inner-container
                .Collapsible
                .Collapsible__trigger
                .plus {
                @include breakpoint(tab-max) {
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
    .inspiration-list__tiles {
      max-width: 1820px !important;
      margin: 0 auto !important;
      padding: 0 0 44px 0 !important;
      @include breakpoint(tab-max) {
        padding: 0 0 60px 0 !important;
        top: 50px;
      }
      .row {
        margin: 0;
        padding: 0;
        .col {
          margin: 0;
          padding: 0;
        }
      }
      .inspiration-list__tile-col {
        padding: 0 0 43px;
        min-height: 100%;
        margin-left: 268px;
        height: auto;
        @include breakpoint(tab-max) {
          padding: 0;
          margin: 0;
        }
        .row {
          margin: 0;
          padding: 0;
          &.row-cols-3 {
            justify-content: space-between;
            @include breakpoint(desktop-extra-lite) {
              justify-content: flex-start;
              gap: 10px;
            }
          }
          &.row-cols-2 {
            @include breakpoint(tab) {
              justify-content: space-between;
              gap: 20px;
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
          }
        }
        .kf-react-button.button.small {
          margin: 30px auto 31px;
          height: 48px;
          font-family: $lato-bold;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 2.5px;
          color: $color-medium-blue;
          border: 1px solid $color-medium-blue;
          &:hover {
            color: $color-light-blue;
          }
          @include breakpoint(mobile) {
            font-family: $lato-bold;
            font-size: 11px;
            line-height: 10px;
            letter-spacing: 2.5px;
            text-align: center;
            color: $color-medium-blue;
            border: 1px solid $color-medium-blue;
            border-radius: 1px;
            margin: 40px 0px 30px 0px;
          }
          @include breakpoint(tab) {
            font-family: $lato-bold;
            font-size: 11px;
            line-height: 10px;
            letter-spacing: 2.5px;
            text-align: center;
            color: $color-medium-blue;
            border: 1px solid $color-medium-blue;
            border-radius: 1px;
            margin: 40px 0px 30px 0px;
          }
        }
        &.inspiration-list__tile-col--full-width {
          margin: 0;
          .inspiration-list__tile {
            .lazyload-wrapper {
              .inspiration-landing-tile__wrapper {
                .inspiration-landing-tile {
                  .inspiration-landing-tile__image {
                    .inspiration-landing-tile__image {
                      img {
                        width: 36.625rem;
                        height: 44.8125rem;
                        @include breakpoint(tab) {
                          width: 100%;
                          height: 100%;
                        }
                        @include breakpoint(mobile) {
                          width: 100%;
                          height: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .inspiration-list__tile {
          padding: 0;
          margin: 0 0 1.875rem 0;
          flex: 0 0 32.2%;
          max-width: 100%;
          @include breakpoint(desktop-extra-lite-lg) {
            flex: 0 0 47.85%;
          }
          @include breakpoint(tab) {
            flex: 0 0 48.67%;
            margin: 0 0 1.25rem 0;
          }
          @include breakpoint(mobile) {
            flex: 0 0 100%;
          }
          .lazyload-wrapper {
            .inspiration-landing-tile__wrapper {
              .inspiration-landing-tile {
                width: 100%;
                position: relative;
                .inspiration-landing-tile__share {
                  opacity: 0;
                  display: flex;
                  position: absolute;
                  z-index: 1;
                  right: 25px;
                  top: 15px;
                  transition: all 0.5s ease;
                }
                .inspiration-landing-tile__image {
                  width: 100%;
                  padding-bottom: 121%;
                  margin: 0;
                  position: relative;
                  @include breakpoint(tab) {
                    padding-bottom: 146%;
                  }
                  @include breakpoint(mobile) {
                    padding-bottom: 148.6%;
                  }
                  .inspiration-landing-tile__image {
                    padding: 0;
                    position: unset;
                    img {
                      width: 31.0625rem;
                      height: 38rem;
                      @include breakpoint(desktop-lg) {
                        width: 31.0625rem !important;
                        width: 100%;
                        object-fit: cover;
                      }
                    }
                  }
                }
                .inspiration-landing-tile__varient-list {
                  @include breakpoint(tab) {
                    height: 90px;
                  }
                }
              }
            }
          }
          .inspiration-list__go-back {
            letter-spacing: 0.2px;
            color: $text-medium-dark-grey;
          }
        }
        .inspiration-list__back-to-top {
          font-family: $lato-regular;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: $text-medium-dark-grey;
          @include breakpoint(tab-max) {
            justify-content: flex-end;
            margin: 0;
            padding: 5px 0 63.5px;
            border-bottom: 1px solid $cool-dark-grey;
          }
          .products-per-page {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            ul {
              margin: 0;
              padding: 0;
              list-style: none;
              li {
                display: inline-block;
                font-family: $lato-regular;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 2px;
                &.disabled {
                  color: $text-dark-grey;
                }
                &:first-child {
                  color: $text-medium-dark-grey;
                  font-size: 10px;
                  letter-spacing: 1.7px;
                  // margin-right: 18px;
                  font-family: $lato-regular;
                  text-decoration: none;
                }
                font-family: $lato-regular;
                font-size: 12px;
                letter-spacing: 2px;
                color: $text-medium-dark-grey;
                line-height: 16px;
                text-transform: uppercase;
              }
              li.disabled {
                line-height: 1pc;
                text-transform: uppercase;
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4.5px;
                text-decoration-color: #a9a9a9;
              }
              li {
                &:first-child {
                  text-decoration: none;
                }
              }
            }

            @media screen and (max-width: 768px) {
              display: none;
            }
          }
        }
      }
      .inspiration-list__filter {
        width: 234px;
        &.inspiration-list__filter--modal {
          @include breakpoint(mobile) {
            padding: 0 18px !important;
          }
          @include breakpoint(tab-max) {
            width: 100%;
            padding: 0 2rem;
          }
          .filter--show-less {
            @include breakpoint(tab-max) {
              padding: 0;
              height: calc(100vh - 50px);
            }
            .filter__footer {
              .filter__apply {
                display: none;
                @include breakpoint(tab-max) {
                  display: block;
                  height: 50px;
                  line-height: 24px;
                }
                @include breakpoint(mobile) {
                  display: block;
                  height: 50px;
                  line-height: 24px;
                }
              }
              button {
                @include breakpoint(tab-max) {
                  margin: 25px 0;
                  height: 50px;
                }
              }
            }
          }
          .inspiration-list__controls.filter__mobile-header {
            .inspiration-list__filter-toggle {
              span {
                @include breakpoint(tab-max) {
                  font-family: $lato-regular;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0;
                  color: $text-dark-grey;
                }
              }
              img {
                @include breakpoint(tab-max) {
                  display: none;
                }
              }
            }
            .inspiration-list__close-icon {
              @include breakpoint(tab-max) {
                width: 21px;
                height: 21px;
              }
            }
          }
        }
      }
      .inspiration-list__filter {
        .filter__inner-container {
          .social-share {
            position: absolute;
            right: 0;
            &__box {
              right: 15px;
              top: 10px;
              height: 126px;
              width: 257px;
              position: relative;
              padding: 20px 25px;
              border: 1px solid hsla(0, 0%, 59.2%, 0.1);
              border-radius: 1px;
              background-color: $white;
              z-index: 8;
              margin: 5px auto 15px;
              .social-share__cta {
                white-space: nowrap;
              }
            }
            &__sites {
              column-gap: 25px;
              display: flex;
              justify-content: start;
              ul {
                list-style-type: none;
                display: inline-flex;
                margin: 0;
                overflow-x: auto;
                li {
                  padding-bottom: 0;
                  margin-right: 1.4375rem;
                }
              }
            }
            &__actions {
              margin-top: 15px;
              padding-top: 20px;
              display: flex;
              justify-content: flex-start;
              border-top: 1px solid #cdcdcd;
            }
          }
          .Collapsible {
            div > span {
              font-family: $lato-regular;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 2px;
              color: $text-dark-grey;
            }
            .Collapsible__contentInner {
              span.checkbox_default {
                font-family: $lato-regular;
                font-size: 14px;
                line-height: 15px;
                letter-spacing: 0.23px;
                color: $text-medium-dark-grey;
                @include breakpoint(tab-max) {
                  color: $text-medium-dark-grey;
                  text-transform: capitalize;
                }
                @include breakpoint(desktop-large) {
                  text-transform: capitalize;
                }
              }
              .checkbox {
                padding: 0;
                align-items: center;

                &:last-child {
                  padding-bottom: 0px;
                }
              }
              .filter__show-more {
                padding: 0;
                margin: 0;
                font-family: $lato-regular;
                font-size: 11px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: $text-medium-dark-grey;
                text-decoration: underline;
                background: 0;
                border: 0;
                outline: 0;
              }
              .checkbox__border {
                width: 15.59px;
                height: 15.59px;
                border-radius: 0;
                .checkbox__marker {
                  width: 15px;
                  height: 15px;
                  border-radius: 0;
                }
              }
            }
            .Collapsible__trigger {
              .plus {
                width: 15.16px;
                height: 15.16px;
                display: flex;
                justify-content: center;
                align-items: center;
                .line {
                  background: #212121;
                  transition: all 0.5s ease;
                }
                .line-1 {
                  color: $text-medium-dark-grey;
                  width: 100%;
                  height: 1px;
                }
                .line-2 {
                  color: $text-medium-dark-grey;
                  transform: translateX(-8px);
                  width: 1px;
                  height: 100%;
                  @include breakpoint(desktop-large) {
                    transform: translateX(-7.7px);
                  }
                }
              }
            }
            .search-icon {
              margin-top: -66px;
            }
            .filter__print-and-share span {
              margin-right: 20px;
              &:last-child {
                margin: 0;
              }
            }
            @include breakpoint(desktop-large) {
              border-bottom: 1px solid $cool-dark-grey-cap;
            }
          }
          .Collapsible:first-child {
            border-top: 1px solid #cdcdcd;
          }
          .filter__print-and-share {
            display: flex;
            @include breakpoint(desktop-small) {
              padding-bottom: 1rem;
              color: $text-medium-dark-grey-small;
              padding-top: 45px;
              line-height: 25px !important;
              letter-spacing: normal !important;
              font-size: 14px;
            }
            @include breakpoint(ipad-landscape) {
              padding-bottom: 1rem;
            }
            @include breakpoint(tab) {
              padding-top: 1rem;
            }
            @include breakpoint(mobile) {
              padding-top: 1rem;
            }
            span {
              margin-right: 20px;
              font-family: $lato-regular;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0;
              display: flex;
              align-items: center;
              &:last-child {
                margin: 0;
              }
              img {
                height: 1pc;
                margin-right: 10px;
              }
            }
          }
          .social-share {
            @include breakpoint(desktop-small) {
              position: relative;
            }
            .social-share__box {
              @include breakpoint(desktop-small) {
                margin: 0;
              }
            }
          }
        }
        .filter__chips {
          .filter__chips-tag {
            span {
              font-family: $lato-regular;
              font-size: 14px;
              letter-spacing: 0.23px;
              line-height: 15px;
              color: $text-dark-grey;
              @include breakpoint(tab) {
                text-transform: capitalize;
              }
            }
          }
          .filter__chips-clear-all {
            font-family: $lato-regular;
            font-size: 14px !important;
            line-height: 20px;
            letter-spacing: 0;
            padding-top: 0;
          }
        }
        .filter__chips-clear-all {
          font-family: $lato-regular;
          font-size: 14px !important;
          line-height: 20px;
          letter-spacing: 0;
        }
      }
      .inspiration-list__not-found {
        padding-top: 26px;
        font-family: $lato-regular;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: 0;
        color: $text-dark-grey;
        text-align: center;
      }
      .inspiration-list__try-other {
        padding-bottom: 56px;
        padding-top: 5px;
        font-family: $lato-regular;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0;
        color: $text-medium-dark-grey;
        text-align: center;
      }
    }
  }
}
.inspiration-list__filter {
  .filter__apply[disabled] {
    background: $cool-dark-grey-cap;
  }
}

.container-fluid.inspiration-list__sticky-nav.inspiration-list__sticky-nav--shadow:after {
  @include breakpoint(mobile) {
    position: absolute;
    left: -18px;
    right: -18px;
    content: "";
    height: 50px;
    top: 1px;
    z-index: -7;
  }
  @include breakpoint(tab) {
    position: absolute;
    left: -32px;
    right: -32px;
    content: "";
    height: 50px;
    top: 1px;
    z-index: -7;
  }
}
.Collapsible {
  border-bottom: 1px solid #cdcdcd;
  &.is-open {
    .Collapsible__contentOuter {
      .Collapsible__contentInner {
        .inputWithIcon {
          @include breakpoint(desktop-small) {
            margin-top: 10px;
            width: 218px;
          }
        }
      }
    }
    .plus {
      .line {
        &.line-2 {
          height: 0;
        }
      }
    }
  }
}

.inspiration-list {
  &__filter--sticky {
    .filter {
      padding: 0;
      &__inner-container {
        @include breakpoint(desktop-small) {
          width: 218px !important;
        }
        @include breakpoint(tab-max) {
          width: 100%;
          padding-bottom: 100px;
        }
      }
      .checkbox .checkbox_default {
        text-transform: capitalize;
        letter-spacing: 0.23px;
        color: $text-dark-grey;
        font-size: 14px;
      }

      .filter__apply {
        font-size: 12px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        font-family: $lato-bold;
        background-color: $color-medium-blue;
      }
    }
  }
}

.filter .filter__chips-tag {
  text-transform: capitalize;
  display: inline-block;
}

.inspiration-landing {
  .inspiration-list {
    &__total {
      @include breakpoint(mobile) {
        height: 42px;
      }
      .inputWithIcon {
        display: none;
        border-bottom: none;
      }
    }
    &__controls {
      @include breakpoint(mobile) {
        top: 0px;
      }
      @include breakpoint(tab-max) {
        top: 0px;
      }
    }

    @include breakpoint(tab-max) {
      .inspiration-landing-tile {
        &__image {
          padding-bottom: 138%;
        }
        &__varient-list {
          margin-top: 0px;
        }
      }
    }
    @include breakpoint(tab-max) {
      &__tile {
        margin-bottom: 30px;
      }
    }
    &__sticky-nav-wrap {
      .sticky {
        background-color: $white;
      }
      @include breakpoint(mobile) {
        padding-bottom: 15px;
      }
    }
  }
  .checkbox .checkbox_default {
    text-transform: capitalize;
  }
}
// MP Alignment Issue:
.inspiration-detail {
  .container-fluid {
    .row > div:first-child {
      @include breakpoint(mobile) {
        padding: 0;
      }
    }
    .row {
      @include breakpoint(mobile) {
        margin: 0;
        padding: 0;
      }
      @include breakpoint(tab) {
        margin: 0;
        padding: 0;
      }
    }
    .product-details__content-section {
      @include breakpoint(mobile) {
        padding: 0;
      }
    }
    .inspiration-detail__gallery {
      @include breakpoint(mobile) {
        margin: 0;
        padding: 0;
      }
      .photo-credits {
        @include breakpoint(mobile) {
          padding-left: 18px;
        }
      }
    }
  }
}

//inspiration details starts
.inspiration-detail-before-load {
  padding: 30px;
  font-size: 32px;
  text-align: center;
}

.inspiration-detail {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .inspiration-detail__gallery {
    min-width: 50%;
    // margin-top: -85px;
    .inspiration-product {
      height: 700px;
    }
    .inspiration-image {
      height: 100%;
      width: 100%;
    }
    .photo-credits {
      align-items: flex-start;
      display: flex;
      font-size: 11px;
      line-height: 16px;
      padding-left: 26px;
    }
    .print-share {
      align-items: flex-start;
      display: flex;
      padding-top: 30.9px;
      padding-left: 26px;
      .share-button {
        margin-left: 20px;
        position: relative;
        cursor: pointer;
        .share-image {
          margin-right: 5px;
        }
        .social-share {
          position: absolute;
          top: 0;
          left: 0;
          &__box {
            right: 15px;
            top: 20px;
            height: 126px;
            width: 257px;
            position: relative;
            padding: 20px 25px;
            border: 1px solid hsla(0, 0%, 59.2%, 0.1);
            border-radius: 1px;
            background-color: $white;
            z-index: 8;
            margin: 5px auto 15px;
            .social-share__cta {
              white-space: nowrap;
            }
          }
          &__sites {
            column-gap: 25px;
            display: flex;
            justify-content: start;
            ul {
              list-style-type: none;
              display: inline-flex;
              margin: 0;
              overflow-x: auto;
              li {
                padding-bottom: 0;
                margin-right: 1.4375rem;
              }
            }
          }
          &__actions {
            margin-top: 15px;
            padding-top: 20px;
            display: flex;
            justify-content: flex-start;
            border-top: 1px solid #cdcdcd;
          }
        }
      }
      .print-button {
        cursor: pointer;
        .print-image {
          margin-right: 5px;
        }
      }
    }
    .favorite-icon {
      top: 40px;
      right: 10px;
      background-color: #ffffff;
      border: 1px solid #000;
      border-color: black;
      padding-left: 5px;
      padding-right: 5px;
      float: right;
      position: relative;
      height: 30px;
    }
    .social-share {
      left: 200px;
      top: -20px;
      &__box {
        right: 5px;
        top: 50px;
      }
    }
    .inspiration-detail-tile {
      .inspiration-detail-tile__share {
        opacity: 0;
        @media screen and (min-width: 320px) and (max-width: 767px) {
          opacity: 1;
        }
        .share-icon {
          cursor: pointer;
        }
      }
    }

    &:hover {
      .inspiration-detail-tile {
        .inspiration-detail-tile__image {
          img:nth-child(2) {
            opacity: 1;
            transform: scale(1);
          }
        }
        .inspiration-detail-tile__colors--swatch,
        .inspiration-detail-tile__compare {
          opacity: 1;
        }
        .inspiration-detail-tile__share {
          opacity: 1;
        }
      }
    }
    .inspiration-detail-tile {
      width: 100%;
      position: relative;

      &__image {
        width: 100%;
        padding-bottom: 130%; //for maintaining aspect ratio
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        background: $color-tile-bg;
        div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          mix-blend-mode: multiply; //temp style for demonstration
          transition: all 0.5s ease-in-out;
        }

        img:first-child {
          position: absolute;
          opacity: 1;
        }
        img:nth-child(2) {
          opacity: 1;
          transform: scale(1.03);
        }
      }

      &__share {
        display: flex;
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 10px;
        transition: all 0.5s ease;
        opacity: 1;

        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          background: $color-white;
          border-radius: 5px;
          margin-left: 5px;
        }
      }

      &--loading {
        .loading-image {
          width: 100%;
          padding-bottom: 130%;
          margin-bottom: 20px;
        }
        .loading-title {
          width: 75%;
          height: 10px;
          margin-bottom: 10px;
        }
        .loading-description {
          width: 70%;
          height: 10px;
          margin-bottom: 10px;
        }
        .loading-swatch {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 5px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .inspiration-detail__cart {
    // margin-left: 150px;
    // margin-top: 100px;
    @media only screen and (max-width: 991px) {
      margin-left: 0px;
    }
    .order-sample {
      &__heading {
        // margin-bottom: 20px;
        font-size: 26px;
        line-height: 36px;
        color: #202020;
        font-family: $lato-regular;
      }
      &__card {
        width: 253px;
        text-align: left;
        // margin-right: 50px;
        margin-bottom: 70px;
      }
      &__body {
        display: flex;
        flex-wrap: wrap;
      }
      &__image {
        width: 252px;
        height: 252px;
        margin-bottom: 20px;
      }
      &__name {
        height: auto;
        width: 246px;
        color: $text-dark-grey;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        font-family: $lato-regular;
      }
      &__description {
        height: auto;
        width: 246px;
        color: $text-dark-grey;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        font-family: $lato-regular;
      }
      &__price {
        height: 29px;
        width: 246px;
        color: $text-dark-grey;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        font-family: $lato-regular;
      }
      &__button {
        height: 60px;
        width: 250px;
        color: #ffffff;
        background-color: #570000;
        margin-top: 10px;
        font-size: 100%;
        font-family: $lato-regular;
      }
      &__button:disabled {
        background-color: #8a8282;
      }
    }
  }
}

@media only screen and (max-width: 860px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .inspiration-detail {
    flex-wrap: wrap;
    .inspiration-detail__gallery {
      width: 45%;
      min-width: 0;
      margin-top: -90px;
      @include breakpoint(tab) {
        margin-top: -10px;
      }
      .inspiration-detail-tile__image img {
        height: 600px;
      }
    }
    .inspiration-detail__cart {
      width: 55%;
      margin-top: 20px;
      padding-left: 65px;
      @include breakpoint(tab) {
        width: 100%;
      }
      .order-sample {
        &__card {
          width: 44%;
          margin-right: 20px;
          margin-top: 60px;
          margin-bottom: 0px;
        }
        &__image {
          width: 100%;
          height: 160px;
          margin-bottom: 15px;
        }
        &__name,
        &__description,
        &__price {
          font-size: 14px;
          line-height: 20px;
          width: 100%;
        }
        &__button {
          height: 50px;
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .inspiration-detail {
    flex-wrap: wrap;
    .inspiration-detail__gallery {
      min-width: 100%;
      margin-top: 20px;
      .photo-credits,
      .print-share {
        padding-left: 18px;
      }
    }
    .inspiration-detail__cart {
      margin-left: 0px;
      padding: 18px;
      margin-top: 0px;
      width: 100%;
      @include breakpoint(mobile) {
        margin-top: 30px;
        padding: 0;
      }
      .order-sample {
        &__card {
          width: 100%;
          margin-right: 0px;
          @include breakpoint(mobile) {
            margin-right: 17px;
            padding: 0;
          }
        }
        &__image {
          width: 100%;
          height: 349px;
        }
        &__name,
        &__description,
        &__price {
          width: 100%;
        }
        &__button {
          height: 50px;
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}
.inspiration-modal-display-block {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f7f7f7;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  .inspiration-detail {
    width: 100%;
  }
  .inspiration-modal {
    &__closeImg {
      float: right;
      // margin: 30px;
      cursor: pointer;
    }
    &__closeImg img {
      height: 31px;
      width: 31.21px;
    }
  }
}
.inspiration-modal-display-none {
  display: none;
}
