* {
  margin: 0;
  padding: 0;
}

.gbh-countryselector {
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
  min-height: 0;
  position: absolute;
  top: 40px;
  left: 0;
  @include breakpoint(desktop-extra-lite-sm) {
    left: -100%;
    top: 0;
  }

  .gbh-close {
    display: none;

    @include breakpoint(desktop-extra-lite-sm) {
      display: block;
    }
  }

  &.show {
    height: auto;
    min-height: 484px;
    overflow: hidden;
    z-index: 12;
    
    @include breakpoint(desktop-extra-lite-sm) {
      left: 0;
    }

    @include breakpoint(desktop-extra-lite-sm) {
      display: block;
      top: 0;
      position: absolute;
      z-index: 999;
    }
  }
}

#gbh_utheader {
  background: $color-dark-blue;
  color: $white;
  font-family: sans-serif; //update actual ff
  width: 100%;
  z-index:2;

  .gbh-tab {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 62px;
    border-bottom: 1px solid $slate;
    border-top: 1px solid $slate;

    @include breakpoint(desktop-extra-lite-sm) {
      border-bottom:none;
      border-top: none;
    }
    ul {
      margin: 20px 0 0 0;
      display: flex;
      flex-direction: row;
      @include breakpoint(desktop-extra-lite-sm){
        flex-direction: column;
      }

      li {
        //display: inline-block;
        font-size: 1.25rem;
        padding: 23px 30px 6px;
        letter-spacing: -0.025rem;
        color: $white;
        text-align: center;
        text-transform: inherit;
        font-family: $lato-bold;
        list-style: none;

        &.gbh-tabs__tab--active {
          color: $white;
          border: inherit;
          &:after {
            width: 140%;
            position: relative;
            left: -20%;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 0;
          height: 4px;
          background: $white;
          transition: width .3s;
          position: relative;
          bottom: -10px;

          @include breakpoint(desktop-extra-lite-sm) {
            background: transparent;
          }
        }

        &:hover:after {
          width: 140%;
          position: relative;
          left: -20%;
        }
      }
    }
  }

  .gbh-tab__panels {
    padding: 70px 0px;
    display: flex;
    justify-content: space-around;
    max-width: 1380px;
    margin: 0 auto;
    .gbh-close {
      display: none;

      @include breakpoint(desktop-extra-lite-sm) {
        display: block;
      }
    }

    .gbh-tab__content {
      display: none;

      .left {
        border-right: 1px solid $slate;

        h1, h2, h3, h4, h5, h6 {
          font-size: 3.125rem;
          line-height: 1.08;
          letter-spacing: -0.1875rem;
          font-family: $lato-regular;
          margin-bottom: 70px;
          color: $white;
        }

        p {
          color: $light-white;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          font-family: $lato-regular;
          padding-right: 60px;
        }
      }

      .right {
        padding-left: 121px;

        .gbh-tab__list {
          height: 278px;
          width: 100%;
          flex-wrap: wrap;
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;

          @include breakpoint(desktop-extra-lite-sm) {
            display: block;
          }

          >li {
            list-style: none;
            margin-bottom: 0;
            white-space: nowrap;
            width: 25%;
            margin-right: 60px;
            padding: 4px 0;
            @include breakpoint(desktop-extra-lite-sm) {
              width: 100%;
              border: 0;
            }

            a {
              text-decoration: none;
              color: $light-gray;
              font-size: 1.125rem;
              font-family: $lato-regular;
              line-height: 1.95rem;
              letter-spacing: -0.033125rem;
              

              @include breakpoint(desktop-extra-lite-sm) {
                font-size: 2rem;
                color: $text-dark-grey;
                padding: 0;
                font-family: $lato-regular;
                letter-spacing: -0.11875rem;
                line-height: 2.375rem;
                margin:0px 0px 20px;
              }

              .gbh-arrow-right {
                display: none;

                @include breakpoint(desktop-extra-lite-sm) {
                  display: inline-block;
                  margin-left: 10px;
                }
              }

              &.selected{
                color: $dark-gray;                
                + ul {
                  display: block;
                  margin-left: 10px;
                  li a {
                    color: $dark-gray;
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
            
            

            ul {

              @include breakpoint(desktop-extra-lite-sm) {
                display: none;
              }

              li {
                display: inline-block;
                padding: 0;

                &:first-child {
                  padding-left: 0px;
                }

                &:last-child {
                  border-right: none;
                }

                a {
                  font-weight: normal;
                  font-family: $lato-regular;
                  //border-right: 1px solid $light-gray;
                  padding: 0;
                  margin: 0;
                  color: $white;

                  &.mobile {
                    display: none;
                  }

                  &:hover {
                    color: $white;
                    text-decoration: underline;
                  }
                  &::after {
                    border-right: 1px solid $light-gray;
                    content: '';
                    height: 14px;
                    padding: 0 4px 0 0;
                    margin: 0 7px;  
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    @include breakpoint(desktop-extra-lite-sm) {
                      border-right: 0;
                    }
                  }
                }
                &:last-child {
                  a {
                    border-right: 0;
                    &::after {
                      border-right: 0;
                    }
                  }
                }
              }

              &.gbh-tab__content--active {
                display: inline-block;

                @include breakpoint(desktop-extra-lite-sm) {
                  display: block;
                }
              }
            }
          }
        }
      }

      &.gbh-tab__content--active {
        display: block;

        @media only screen and (min-width: 1201px) {
          width: 100%;
          max-width: 1380px;
          margin: 0 auto;
          padding: 0 60px;
        }
      }
    }
  }
  .gbh-tab__panels  {
    .gbh-tab__content {
      .gbh-tab__list {
        li {
          display: flex;
          flex-direction: row;
          margin-right: 0;
          cursor: pointer;
          @include breakpoint(desktop){
              ul {
                display: flex;
                margin-left: 10px;
              }
          }
        }
      }
    }
  }
}

.countrybg{
  width:100%;
  height:100%;
  top:40px;
  display:block;
  position: absolute;
  z-index:-1;
  left:0;
  &:after {
      position: absolute;
      content: "";
      background-color: $color-dark-blue;
      z-index: 1;
      left: 0;
      top:0;
      opacity: 0.7;
      top: 0;
      width: 100%;
      height: 100vh;
      visibility: hidden;
      animation: slide-up 1s cubic-bezier(0.4, 0, 0.2, 1) 1500ms;
      transition: visibility 2s linear 1s, opacity 1s;
  }
  &.active{
    z-index:1;
    display:block !important;
    &:after {
        opacity:0.8;
        z-index: -1;
        -webkit-animation: slideInDown 1s;
        -moz-animation: slideInDown 1s;
        -o-animation: slideInDown 1s;
        animation: slideInDown 1s;
        visibility: visible;
    } 
  }
}
@include breakpoint(desktop-extra-lite-sm) {
  .active-country + #gbh_utheader {
    left: 0;
  }

  #gbh_utheader {
    width: 100%;
    position: fixed;
    background:$white;
    color:$text-medium-dark-grey;
    z-index: 999;
    .gbh-tab {
      display: block;
      height: 100vh;
      padding: 15px 32px;
      width: 100%;
      overflow: scroll;
      @include breakpoint(mobile) {
        padding: 17px 18px;
      }

      .gbh-close-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        color: $text-dark-grey;
        font-size:0.875rem;
        line-height: 1.25rem;
        margin: 55px 0px 34px !important;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        font-family: $lato-regular;
        letter-spacing: -0.0375rem;
        border-bottom:1px solid $text-dark-grey;
        padding-bottom:1.25rem;
        padding-left:0 !important;

        &:before {
          content: "\e92c";
          margin-right: 12.75px;
          font-size: 13px;
          font-family: icomoon !important;
          color: $text-dark-grey;
        }
      }

      ul {
        li {
          display: block;
          font-size:2rem;
          color: $text-medium-dark-grey;
          text-align: left;
          padding: 0;
          margin: 0;
          font-family: $lato-regular;
          font-weight: 300;
          line-height: 2.375rem;
          letter-spacing: -0.11875rem;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }

          &.active {
            border-bottom: none;
            font-weight: lighter;
            color: $medium-gray;
            font-size: 2rem;
            text-align: left;
            padding: 10px 15px;
            font-family: $lato-regular;
            font-weight: 300;
            line-height: 2.375rem;
            letter-spacing: -0.11875rem;
          }
        }
      }
    }

    .gbh-tab__panels {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100vw;
      height: 100vh;
      background:$white;
      padding: 15px 32px 0;
      overflow: scroll;
      flex-direction:column;
      justify-content: flex-start;
      @include breakpoint(mobile) {
        padding: 15px 18px 0;
      }

      .gbh-back-btn {
        font-size: 0.875rem;
        color: $text-dark-grey;
        padding: 0;
        line-height:1.25rem;
        margin: 30px 0 34px !important;
        border-bottom: 1px solid $text-dark-grey;
        padding-bottom: 20px !important;
        padding-left: 0 !important;
        &:before {
          @media only screen and (max-width: 1023px) {  
            content: "\e92c";
            margin-right: 3.625rem;
            font-size: 13px;
            font-family: icomoon !important;
            color:$text-dark-grey;
          }
        }
      }

      .gbh-close {
        display: none;

        @include breakpoint(desktop-extra-lite-sm) {
          display: block;
          font-size: 1.125rem;
          margin: 0;
          top: 35px;
          left: 18px;
        }
      }

      .gbh-tab__content {
        background-color:$white;

        .right {
          padding-left: 30px;

          .gbh-tab__list {
            margin-top: 25px;
            li {
              font-size: 2rem;
              font-weight: lighter;
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;
              line-height: 2.375rem;
              letter-spacing: -0.11875rem;
              padding: 0;
              &.arrowdropdown{
                a{
                  &::after{
                    content: "\e92a";
                    font-family: icomoon!important;
                    font-size: 9pt;
                    padding-left: 15px;
                    margin-top: 5px;
                  }
                }
              }
              &:last-child {
                margin-bottom: 0;
                padding-bottom: 80px;
              }


              ul {
                width: 100%;
                margin-left:0;
                flex-direction: column;
                padding: 0;
                @include breakpoint(mobile) {
                  width: 109%;
                }
                
                li {
                  padding: 0 32px 10px 52px;
                  width: 100%;
                  display: block;
                  background: $half-white;
                  border-right: none;
                  margin-bottom: 0;
                  &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 25px;
                  }
                  @include breakpoint(mobile) {
                    padding: 0 18px 10px 49px;
                  }

                  &:first-child {
                    padding-top: 20px;
                    padding-left: 40px;
                    @include breakpoint(mobile) {
                      padding-left: 49px;
                    }
                  }

                  a {
                    display: none;
                    font-family: $lato-regular;
                    font-size: 2rem;
                    line-height: 1.375rem;
                    letter-spacing: -0.11875rem;
                    color: $text-medium-dark-grey;
                    font-weight:normal;
                    &.mobile {
                      display: block;
                      border:none;
                      line-height: 2.2rem;
                    }
                    &.selected {
                      color: inherit;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.gbh-article-brand {
  padding: 30px 0;

  .gbh-image__image {
    width: 325px;
    height: 73px;
  }
}

.gbh-mobilebottom-menu {
  display: none;

  @include breakpoint(desktop-extra-lite-sm) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  ul {

    @include breakpoint(desktop-extra-lite-sm) {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      margin: 0;
      list-style: none;
    }

    li {
      @include breakpoint(desktop-extra-lite-sm) {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
      }

      @include breakpoint(mobile) {
        padding: 17px 18px;
        &:first-child {
          border-top: unset;
        }
      }
      
      @include breakpoint(tab) {
        padding: 17px 32px;
      }

      &.gbh-find-place {
        @include breakpoint(desktop-extra-lite-sm) {
          position: absolute;
          top: -5px;
          right: 5px;
        }

        &:before {  
          visibility: visible;
          content: "\e903";
          font-size: 20px;
          color: $white;
          font-family: 'icomoon';
          margin-right: 10px;
          top: 4px;
          position: relative;

          @include breakpoint(desktop-extra-lite-sm) {
            visibility: hidden;
          }
        }


        a {
          &:after {
            visibility: hidden;

            @include breakpoint(desktop-extra-lite-sm) {
              content: "\e903";
              font-size: 20px;
              color: $white;
              font-family: 'icomoon';
              margin-left: 10px;
              top: 0;
              position: relative;
              visibility: visible;
            }
          }
        }
      }


      @include breakpoint(desktop-extra-lite-sm) {

        &.gbh-region-mob {
          &:after {
            content: "\e931";
            font-size: 20px;
            color: $white;
            font-family: 'icomoon';
            margin-right: 0;
            top: 4px;
            position: relative;
          }
        }
      }
      @include breakpoint(mobile) {
        &.gbh-region-mob {
          padding: 17px 17px 17px 18px;
        }
      }

      a {

        @include breakpoint(desktop-extra-lite-sm) {

          color: $light-white;
          font-weight: 300;
          display: flex;
          align-items: center;
          padding: 4px 0;
          font-size: 1.25rem;
          letter-spacing: -0.040625rem;
          font-family: $lato-regular;
          margin: 0;
        }

        img {
          @include breakpoint(desktop-extra-lite-sm) {
            margin-right: 10px;
          }

        }
      }
    }

    .gbh-find-img {
      transform: rotateZ(270deg);

      @include breakpoint(desktop-extra-lite-sm) {
        transform: rotateZ(180deg);
      }
    }
  }
}
.gbh-close-btn {
  &.d-lg-none {
    @include breakpoint (desktop-extra-lite-sm) {
      display: block !important;
    }
  }
}