@mixin text-style($fs, $lh, $ls) {
  font-size: $fs;
  line-height: $lh;
  letter-spacing: $ls;
}

.cmp-hero {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: $color-light-grey;
  max-width: 1920px;
  margin: 0 auto;
  @include breakpoint(desktop-xxxlg) {
    padding: 0 50px;
  }
  @include breakpoint(desktop-mid) {
    padding: 0 36px;
  }
  @include breakpoint(xl) {
    padding: 0 50px;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    column-gap: 90px;
    @include breakpoint(xl) {
      max-width: 100%;
      width: 100%;
    }
    @include breakpoint(desktop-extra-sm) {
      column-gap: 50px;
    }
    @include breakpoint(tab) {
      column-gap: 50px;
      padding: 0 2rem;
    }
    @include breakpoint(mobile) {
      flex-direction: column-reverse;
      padding: 20px 18px 25px;
      gap: 30px;
    }
    &__article_details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      flex: 1 0 0;
      align-self: stretch;
      max-width: 608px;
      width: 100%;
      margin: 40px 0;
      @include breakpoint(desktop-extra-sm) {
        margin: 0;
        width: calc(100% - 328px);
        padding: 16px 0;
      }
      @include breakpoint(xl) {
        margin: 70px 0;
        max-width: 568px;
      }
      @include breakpoint(tab-max) {
        padding: 16px 0;
        margin: 0;
      }
      &__headline {
        font-family: $playfair-regular;
        @include text-style(2.625rem, 3rem, -0.0731rem);
        font-style: normal;
        font-weight: 400;
        color: $text-dark-grey;
        @media (min-width: 1024px) and (max-width: 1439px) {
          @include text-style(28px, 34px, -1.17px);
        }
        @include breakpoint(tab-max) {
          @include text-style(1.75rem, 2.125rem, -0.0731rem);
        }
      }
      &__subHeadline {
        font-family: $lato-regular;
        @include text-style(1rem, 1.5rem, 0);
        font-style: normal;
        font-weight: 400;
        color: $text-medium-dark-grey;
        margin-bottom: 30px;
        display: flex;
        @media (min-width: 1024px) and (max-width: 1439px) {
          @include text-style(14px, 20px, 0);
        }
        @include breakpoint(tab-max) {
          @include text-style(0.875rem, 1.25rem, 0);
        }
      }
      &__read-article {
        font-family: $lato-regular;
        @include text-style(0.6875rem, 1rem, 0.1125rem);
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-light-blue;
        position: relative;
        padding-bottom: 7px;
        border-bottom: 1px $color-light-blue solid;
      }
    }
    &__image_details {
      &__image {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        height: 512px;
        @include breakpoint(desktop-large) {
          min-width: 682px;
          max-height: 512px;
          height: 100%;
        }
        @include breakpoint(xl) {
          min-width: 682px;
          max-height: 512px;
          height: 100%;
        }
        @include breakpoint(desktop-extra-sm) {
          height: 246px;
          width: 328px;
          object-fit: cover;
        }
        @include breakpoint(tab) {
          height: 246px;
          width: 328px;
          object-fit: cover;
        }
        @include breakpoint(mobile) {
          width: 339px;
          height: 254px;
        }
      }
    }
  }
}

.blog-header-tabs {
  max-width: 1920px;
  margin: 0 auto;
  padding: 40px 2.6045%;
  @include breakpoint(desktop-lg) {
    max-width: 1380px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) and (max-width: 1439px) {
    padding: 30px 26px;
  }
  @include breakpoint(tab) {
    padding: 1.875rem 2rem;
  }
  @include breakpoint(mobile) {
    padding: 30px 18px;
  }
  @include breakpoint(xl) {
    max-width: 100%;
    margin: 0;
    padding: 40px 50px;
  }
  .cmp-teaser {
    &__content {
      background-color: transparent;
    }
    &__title {
      font-family: $playfair-regular;
      @include text-style(3.375rem, 3.875rem, -0.0625rem);
      font-style: normal;
      font-weight: 400;
      color: $black;
      padding: 0;
      @include breakpoint(tab-max) {
        @include text-style(2.25rem, 2.75rem, -0.125rem);
      }
    }
    &__description {
      padding: 15px 0 0;
      display: block;
      margin: 0;
      color: $text-dark-grey;
      font-family: $lato-regular;
      @include text-style(1.375rem, 2rem, 0);
      @include breakpoint(tab-max) {
        @include text-style(1.125rem, 1.5rem, 0.0313rem);
        padding: 10px 0 0;
      }
      > p {
        font-family: $lato-regular;
        color: $text-dark-grey;
        @include text-style(1.375rem, 2rem, 0);
        font-style: normal;
        font-weight: 400;
        @include breakpoint(tab-max) {
          @include text-style(1.125rem, 1.5rem, 0.0313rem);
        }
      }
    }
    &__action-container {
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      padding-top: 3.75rem;
      @include breakpoint(tab-max) {
        padding-top: 3.125rem;
        gap: 15px;
      }
    }
    &__action-link {
      border-radius: 50px;
      padding: 8px 30px;
      font-family: $lato-regular;
      box-shadow: none;
      color: $charcoal;
      width: auto;
      @include text-style(1rem, 1.5rem, 0);
      @include breakpoint(tab-max) {
        @include text-style(0.875rem, 1.25rem, 0);
      }
      @include breakpoint(mobile) {
        padding: 8px 23px;
      }
      &.gbh-primary-button {
        background-color: $charcoal;
        border: 1px solid $charcoal;
        color: $white;
      }
    }
  }
}
.blog-carousel {
  display: block;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 4.375rem;
  @media (min-width: 1024px) and (max-width: 1439px) {
    padding-top: 50px;
  }
  @include breakpoint(tab-max) {
    padding-top: 50px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-family: $font-regular;
    font-style: normal;
    color: $black;
    @include breakpoint(desktop-large) {
      max-width: 1380px;
      margin: 0 auto;
      margin-top: 70px;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      padding: 0 32px;
    }
    @include breakpoint(xl) {
      padding: 0 50px;
      margin-top: 70px;
    }
    @include breakpoint(tab) {
      margin: 50px 32px 30px;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 2.625rem;
    line-height: 3rem;
    letter-spacing: -0.0731rem;
    font-family: $playfair-regular;
    color: $text-dark-grey;
    @include breakpoint(xl) {
      padding: 0;
      margin: 0;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      @include text-style(28px, 34px, -1.17px);
    }
    @include breakpoint(tab) {
      padding: 0;
      margin: 0;
      @include text-style(28px, 34px, -1.17px);
    }
    @include breakpoint(mobile) {
      padding: 0;
      margin: 0 18px;
      @include text-style(28px, 34px, -1.17px);
    }
  }
  &__view-all {
    @include text-style(0.6875rem, 1rem, 0.1125rem);
    font-family: $lato-regular;
    color: $color-light-blue;
    border-bottom: 0.0625rem solid $color-light-blue;
    text-decoration: none;
    padding-bottom: 7px;
    text-transform: uppercase;
  }
  &__view-all-center {
    text-align: center;
    margin: 30px 0 50px;

    a {
      @include text-style(11px, 16px, 1.8px);
      color: $black;
      border-bottom: 0.0625rem solid $color-light-blue;
      text-decoration: none;
      text-transform: uppercase;
      font-family: $lato-regular;
      color: $color-light-blue;
      @include breakpoint(mobile) {
        @include text-style(11px, 16px, 1.8px);
      }
    }
  }

  &__content {
    margin: 3.5rem 0 4.375rem;
    overflow-x: auto;
    scroll-behavior: smooth;
    @include breakpoint(xl) {
      margin-top: 3.125rem;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      margin: 30px 0 0 32px;
    }
    @include breakpoint(tab) {
      margin: 0 0 0 32px;
      padding-right: 32px;
    }
    @include breakpoint(mobile) {
      margin: 30px 0 0 18px;
      padding-right: 18px;
    }
    &.os-host-overflow-x {
      padding-bottom: 3.125rem;
      @include breakpoint(desktop-large) {
        margin-left: 270px;
      }
      @include breakpoint(xl) {
        margin-left: 50px;
        margin-bottom: 0;
      }
      @include breakpoint(desktop-extra-sm) {
        margin-left: 32px;
      }
      @include breakpoint(tab-max) {
        padding-bottom: 30px;
      }
    }

    .os-scrollbar-horizontal {
      height: 5px;
      @include xxl {
        max-width: 1700px;
        margin: 0 auto;
      }
      @include xl {
        margin: 0 110px;
      }
      @include lg {
        margin: 0 7.63%;
      }
      @include md {
        margin: 0 80px;
      }
      @include sm {
        margin: 0 44px;
      }
      @include xs {
        margin: 0 30px;
      }
      .os-scrollbar-track {
        border-radius: 0;
        background-color: $color-medium-grey !important;

        .os-scrollbar-handle {
          border-radius: 0;
          background-color: $color-dark-gray !important;
        }
      }
    }

    .os-viewport {
      @include xxl {
        padding: 0 110px !important;
      }

      @include xl {
        padding: 0 110px !important;
      }

      @include lg {
        padding: 0 7.63% !important;
      }

      @include md {
        padding: 0 80px !important;
      }

      @include sm {
        padding: 0 44px !important;
      }

      @include xs {
        padding: 0 30px !important;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: $cool-dark-grey;
    }

    &::-webkit-scrollbar-thumb {
      background: $charcoal;
    }
  }

  &__slider {
    display: inline-flex;
    padding-bottom: 6px;

    .article-card-wrapper {
      min-width: 426px;
      margin-right: 1.875rem;
      @media (min-width: 1024px) and (max-width: 1439px) {
        margin-right: 20px;
        width: 305px;
      }
      @include breakpoint(desktop-extra-sm) {
        min-width: 305px;
      }
      @include breakpoint(tab-max) {
        width: 364px;
        min-width: 364px;
        max-width: 100%;
        margin-right: 20px;
      }
      @media (max-width: 810px){
        width: 339px;
        max-width: 100%;
        min-width: 339px;
      }
      &:last-child {
        margin-right: 0;
      }
      &.remove-mr {
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .hide-last-child {
      display: none;
    }
  }

  .most-popular-section {
    display: inline-flex;
    flex-direction: column;
    @include breakpoint(desktop-large) {
      min-width: 440px;
      margin-left: 30px;
    }
    @include breakpoint(xl) {
      margin-left: 30px;
    }
    @include breakpoint(desktop-extra-sm) {
      margin-left: 30px;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      margin-left: 20px;
    }
    &.top {
      padding: 0;
      margin: 0;
      width: 100%;
      @include breakpoint(desktop-small) {
        margin: 0 0 0 1.875rem;
      }
      @include breakpoint(tab) {
        width: auto;
        margin: 0 32px;
      }
      @include breakpoint(mobile) {
        width: auto;
        margin: 0 18px 30px;
      }
    }
    .most-popular-title {
      font-family: $lato-regular;
      @include text-style(1.625rem, 2.25rem, 0);
      color: $text-dark-grey;
      margin-bottom: 2.5rem;
      text-transform: capitalize;
      @media (min-width: 1024px) and (max-width: 1439px) {
        @include text-style(22px, 32px, 0);
        margin-bottom: 20px;
      }
      @include breakpoint(tab-max) {
        margin-bottom: 20px;
        @include text-style(22px, 32px, 0);
      }
    }
    .links-section {
      font-family: $lato-regular;
      @include text-style(1rem, 1.5rem, 0);
      color: $black;
      margin-bottom: 1.875rem;
      text-transform: capitalize;
      color: $text-dark-grey;
      @media (min-width: 1024px) and (max-width: 1439px) {
        @include text-style(14px, 20px, 0);
        margin-bottom: 20px;
      }
      @include breakpoint(tab-max) {
        @include text-style(14px, 20px, 0);
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $text-dark-grey;
        text-decoration: underline;
        &:hover {
          color: $primary-blue;
        }
      }
      @include sm {
        font-size: 0.875rem;
        line-height: 1.05rem;
      }
      @include xs {
        font-size: 0.875rem;
        line-height: 1.2862rem;
      }
    }
  }
  &__content.blog-react-container {
    margin: 3.5rem 6.875rem 4.5rem;
    @include breakpoint(desktop-large) {
      margin: 4.375rem 16.875rem;
      padding: 0;
    }
    @include breakpoint(tab) {
      margin: 30px 0 0 2rem;
    }
    @include breakpoint(mobile) {
      margin: 30px 18px 50px;
    }
  }
  &__content.latestPopular {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include breakpoint(desktop-small) {
      max-width: 100%;
      margin: 0;
      padding: 70px 50px 10px;
    }
    @include breakpoint(desktop-large) {
      max-width: 1380px;
      margin: 0 auto;
      padding: 50px 0 70px;
      overflow-y: hidden;
    }
    @include breakpoint(desktop-extra-sm) {
      margin: 2.5rem 32px;
      padding: 0 0 10px;
    }
    @include breakpoint(xl) {
      margin: 0 50px;
      padding: 50px 0;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      margin: 2.5rem 32px;
      padding: 0 0 10px;
    }
    @include breakpoint(tab-max) {
      padding: 0 0 10px;
    }
    @include breakpoint(mobile) {
      overflow: hidden;
      flex-direction: column;
      padding: 0;
    }
    ul.blog-carousel__slider {
      @include breakpoint(mobile) {
        flex-direction: column;
        gap: 20px;
      }
    }
    .blog-carousel__slider .article-card-wrapper {
      @include breakpoint(mobile) {
        min-width: 339px;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.blog-carousel:has(.latestPopular) {
  .blog-carousel__header {
    margin-top: 0;
    @include breakpoint(tab-max) {
      margin-top: 30px;
    }
  }
}
.bg-warm-gray-light {
  background-color: $color-light-grey;
}
.hero-banner-article-top {
  .cmp-hero {
    &__container {
      align-items: center;
      justify-content: center;
      .cmp-hero {
        &__container {
          &__article_details {
            align-items: center;
            justify-content: center;
            .cmp-hero {
              &__container {
                &__article_details {
                  &__headline {
                    font-size: 4.375rem;
                    line-height: 4.875rem;
                    letter-spacing: -1.5px;
                    font-family: $playfair-regular;
                    font-weight: normal;
                    margin: 0;
                    color: $text-dark-grey;
                    @include breakpoint(tab) {
                      font-size: 2.625rem;
                      line-height: 3rem;
                      letter-spacing: -0.175rem;
                      width: 25.5rem;
                      margin: 0 auto;
                      text-align: center;
                    }
                    @include breakpoint(mobile) {
                      font-size: 2.625rem;
                      line-height: 3rem;
                      letter-spacing: -0.175rem;
                    }
                  }
                  &__subHeadline p{
                    margin: 0;
                    font-size: 1.125rem;
                    font-family: $lato-regular;
                    line-height: 1.5rem;
                    letter-spacing: .5px;
                    color: $text-medium-dark-grey;
                    @include breakpoint(mobile) {
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .cmp-teaser{
    .cmp-teaser{
      &__content{
        flex-direction: column;
        align-items: center;
        .cmp-teaser{
          &__title{
            border: none;
            h1{
              padding: unset;
            }
        }}
      }
    }
  }
}
