.complimentary-guide-embedded-form {
  padding: 0;
  @include breakpoint(desktop-small) {
    width: 48.315%;
  }
  .cmp-teaser {
    &__content {
      @include breakpoint(desktop-small) {
        max-width: 28.1875rem;
        margin: 0 auto;
        padding-top: 7.375rem;
      }
      @include breakpoint(tab) {
        max-width: 476px;
        margin: 0;
        padding: 72px 2pc;
        width: 100%;
        box-sizing: content-box;
      }
      @include breakpoint(mobile) {
        width: 100%;
        margin: 50px 0;
        padding: 0 18px;
      }
    }
    &__title {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 3.375rem;
        line-height: 3.875rem;
        font-family: $playfair-regular;
        letter-spacing: -0.0625rem;
        margin-bottom: 1.75rem;
        padding-right: 1.875rem;
        color: $text-dark-grey;
        @include breakpoint(tab-max) {
          margin-bottom: 1.875rem;
        }
        @include breakpoint(tab) {
          font-size: 2.25rem;
          line-height: 2.75rem;
          letter-spacing: -0.125rem;
          padding-right: 0;
        }
        @include breakpoint(mobile) {
          font-size: 1.75rem;
          line-height: 2.125rem;
          letter-spacing: -0.073125rem;
          padding-right: 0;
          width: 63%;
        }
      }
    }
    &__description {
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        @include ann-describtion(1rem, $lato-regular, 1.5rem);
        padding-right: 5.1875rem;
        color: $text-medium-dark-grey;
        letter-spacing: normal;
        @include breakpoint(tab-max) {
          @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
          padding-right: 0;
        }
      }
    }
  }
}
.organize-aem-form-teaser {
  .aemform {
    @include breakpoint(desktop-small) {
      width: 51.82%;
    }
    @include breakpoint(tab) {
      padding: 0 2rem;
    }
    @include breakpoint(mobile) {
      padding: 0 1.125rem;
    }
    .aemformcontainer {
      @include breakpoint(desktop-small) {
        max-width: 33.8125rem;
        margin: 0 auto 0 7.375rem;
      }
    }
    #guideContainerForm {
      padding-top: 0.6875rem;
      padding-bottom: 4.375rem;
      @include breakpoint(tab-max) {
        padding-top: 2.5rem;
        padding-bottom: 3.125rem;
      }
      div#guideContainer-rootPanel-guidetextbox_796469195___guide-item,
      div#guideContainer-rootPanel-guidedropdownlist_227530719___guide-item {
        padding-left: 15px;
        @include breakpoint(tab-max) {
          padding-left: 0;
        }
      }
      .container {
        @include breakpoint(tab-max) {
          width: 100%;
        }
        .guideContainerNode {
          .rootPanel {
            .guidefield {
              &.guidebutton {
                .guideFieldButtonWidget {
                  button {
                    @include ann-describtion(0.75rem, $lato-bold, 1.5rem);
                    color: $white;
                    letter-spacing: 0.15625rem;
                    background: $color-medium-blue;
                    padding: 0.75rem 1.6875rem 0.875rem;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    border-radius: 1px;
                    width: 100%;
                    margin-left: 0;
                    border-color: $color-medium-blue;
                    @include breakpoint(tab-max) {
                      width: 100%;
                      margin-left: 0;
                    }
                  }
                }
              }
            }
            div#guideContainer-rootPanel-guidetextdraw_2041746405___guide-item {
              width: auto;
            }
            .guideRootPanel {
              .gridFluidLayout2 {
                #guideContainer-rootPanel___guide-item-container {
                  .guideGridFluidLayout2Container {
                    #guideContainer-rootPanel-guidetextdraw_1684177656___guide-item {
                      .guide-1620993093598 {
                        .planning-form-text {
                          padding: 0;
                          @include breakpoint(tab-max) {
                            padding: 0;
                          }
                          p {
                            color: $text-dark-grey;
                            font-family: $lato-bold;
                            font-size: 1rem;
                            letter-spacing: normal;
                            line-height: 1.5rem;
                            margin: 0;
                            padding-bottom: 0.9375rem;
                            @include breakpoint(tab-max) {
                              padding-bottom: 8px;
                            }
                            @include breakpoint(mobile) {
                              max-width: 224px;
                            }
                          }
                        }
                      }
                    }
                    .textdraw1620643888660 {
                      padding: 0;
                      @include breakpoint(tab-max) {
                        padding: 0;
                      }
                      p {
                        @include ann-describtion(
                          0.875rem,
                          $lato-regular,
                          1.25rem
                        );
                        color: $text-medium-dark-grey;
                        letter-spacing: normal;
                        margin: 0;
                        padding-bottom: 1.875rem;
                      }
                      a {
                        color: $text-medium-dark-grey;
                      }
                    }
                    .textdraw1623405705349 {
                      margin: 0;
                      padding: 1.25rem 0;
                      @include breakpoint(tab-max) {
                        padding: 1.25rem 0;
                      }
                      p {
                        @include ann-describtion(
                          0.875rem,
                          $lato-regular,
                          1.25rem
                        );
                        color: $text-medium-dark-grey;
                        letter-spacing: normal;
                        margin: 0;
                      }
                      a {
                        text-decoration: underline;
                        color: $text-medium-dark-grey;
                      }
                    }
                    .fd-col-lg-2.fd-col-lg-offset-0 {
                      width: auto;
                      .guidetextdraw {
                        .privacy-policy {
                          padding: 0;
                          margin-left: 0;
                          p {
                            @include ann-describtion(
                              0.875rem,
                              $lato-regular,
                              1.25rem
                            );
                            color: $text-medium-dark-grey;
                            letter-spacing: normal;
                            margin: 0;
                            text-decoration: underline;
                          }
                          a {
                            color: $text-medium-dark-grey;
                          }
                        }
                      }
                    }
                    #guideContainer-rootPanel-guidetextdraw_2041746405___guide-item {
                      .guidetextdraw {
                        .textdraw_20417464051633438147503 {
                          padding: 0;
                          margin-left: 1.125rem;
                          p {
                            @include ann-describtion(
                              0.875rem,
                              $lato-regular,
                              1.25rem
                            );
                            color: $text-medium-dark-grey;
                            letter-spacing: normal;
                            margin: 0;
                            text-decoration: underline;
                          }
                          a {
                            color: $text-medium-dark-grey;
                          }
                        }
                      }
                    }
                    .guide-1620643651524
                      .guideFieldNode:not(.guideButton):not(.guideTextDraw):not(
                        .guideChart
                      ) {
                      height: auto;
                    }
                    .guideFieldWidget input,
                    .guideFieldWidget select,
                    .guideFieldWidget textarea {
                      @include ann-describtion(
                        1.125rem,
                        $lato-regular,
                        1.625rem
                      );
                      letter-spacing: 0;
                      color: $text-light-grey;
                      padding: 1.0625rem 0.9375rem;
                      height: 3.75rem;
                      border: 1px solid $cool-dark-grey;
                      margin-bottom: 20px;
                    }
                    .guideFieldWidget textarea {
                      height: 9.375rem;
                      margin: 0;
                      @include breakpoint(tab-max) {
                        height: 11.25rem;
                      }
                    }
                    .dropDownList select {
                      @include ann-describtion(
                        1.125rem,
                        $lato-regular,
                        1.625rem
                      );
                      letter-spacing: 0;
                      color: $text-light-grey;
                      padding: 0.9375rem;
                    }
                    #guideContainer-rootPanel-guidetextbox_1136293516___guide-item,
                    #guideContainer-rootPanel-guidetextbox_796469195___guide-item,
                    #guideContainer-rootPanel-guidedropdownlist_451492454___guide-item,
                    #guideContainer-rootPanel-guidedropdownlist_227530719___guide-item {
                      @include breakpoint(tab) {
                        width: 100%;
                      }
                    }
                    div#guideContainer-rootPanel-guideemail___guide-item {
                      .guideEmail {
                        input#guideContainer-rootPanel-guideemail___widget {
                          text-indent: 35px;
                        }
                        &:after {
                          content: "\e920";
                          width: 25px;
                          height: 20px;
                          position: absolute;
                          left: 15px;
                          top: 18px;
                          font-family: $icon-moon;
                        }
                      }
                    }
                    div#guideContainer-rootPanel-guidetelephone___guide-item {
                      .guideTelephone {
                        input#guideContainer-rootPanel-guidetelephone___widget {
                          text-indent: 35px;
                        }
                        &:after {
                          content: "";
                          background-image: url(../images/phone.svg);
                          width: 25px;
                          height: 21px;
                          position: absolute;
                          left: 15px;
                          top: 20px;
                          font-family: $icon-moon;
                          background-repeat: no-repeat;
                        }
                      }
                    }
                    #guideContainer-rootPanel-panel1658746519870___guide-item {
                      width: auto;
                    }
                  }
                }
              }
              .guideFieldWidget {
                &.textField {
                  &.multiline {
                    display: flex;
                    padding-bottom: 40px;
                    + .guideFieldError {
                      display: none;
                    }
                  }
                }
              }
              #guideContainer-rootPanel-submit___guide-item {
                .submit {
                  &.guidebutton {
                    .guideButton {
                      .guideFieldButtonWidget {
                        + .guideFieldError {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .step {
            &.mobileLayout {
              @include breakpoint(tab-max) {
                display: none;
              }
            }
          }
          .guideFieldError {
            min-height: 20px;
            padding: 0;
            margin: 0;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
    .guideFieldNode.validation-failure:not(.guideButton):not(
        .guideTextDraw
      ):not(.guideChart),
    .guideFieldNode.validation-success:not(.guideButton):not(
        .guideTextDraw
      ):not(.guideChart) {
      border: 0;
      background: transparent;
    }
    #guideContainerForm
      .container
      .guideContainerNode
      .rootPanel
      .guideRootPanel
      .gridFluidLayout2
      #guideContainer-rootPanel___guide-item-container
      .guideGridFluidLayout2Container
      .guideFieldNode.validation-failure
      .guideFieldWidget
      input,
    #guideContainerForm
      .container
      .guideContainerNode
      .rootPanel
      .guideRootPanel
      .gridFluidLayout2
      #guideContainer-rootPanel___guide-item-container
      .guideGridFluidLayout2Container
      .guideFieldNode.validation-failure
      .guideFieldWidget
      select,
    #guideContainerForm
      .container
      .guideContainerNode
      .rootPanel
      .guideRootPanel
      .gridFluidLayout2
      #guideContainer-rootPanel___guide-item-container
      .guideGridFluidLayout2Container
      .guideFieldNode.validation-failure
      .guideFieldWidget
      textarea {
      border: 1px solid $color-red-alert;
    }
    .guideFieldNode.validation-failure .guideFieldError {
      @include ann-describtion(1rem, $lato-regular, 1.375rem);
      color: $color-red-alert;
    }
  }
  .input-planningguide__comment + span {
    position: absolute;
    bottom: 68px;
    right: 0;
    padding: 0 18.5px 15px 0;
    @include ann-describtion(14px, $helvetica-regular, 0);
    letter-spacing: 0;
    color: $charcoal;
    @include breakpoint(tab) {
      padding: 0 21.5px 15px 0;
    }
    @include breakpoint(mobile) {
      padding: 0 11.5px 15px 0;
    }
  }
}

.guideFieldNode:not(.guideButton):not(.guideTextDraw):not(.guideChart) {
  padding: 0 !important; //default css overwrite
  margin: 0 !important; //default css overwrite
  border: none !important; //default css overwrite
}

.input-planningguide__comment + span {
  position: absolute;
  bottom: 30px;
  right: 15px;
}
