.cart-item {
    padding: 35px 0 15px;
    @media screen and (max-width: 990px) {
      padding: 50px 0px 35px 0;
    }
    border-top: 1px solid $color-dark-gray;
    &:last-child {
      padding-bottom: 0;
    }
    &__item-of {
      padding: 15px 31px 15px 0;
      @media screen and (max-width: 990px) {
        padding: 15px 31px 26px 0;
      }
      border-top: 1px solid $color-dark-gray;
      color: $color-dark-gray;
      font-family: $font-regular;
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
      margin-left: 60px;
    }
   &__combo-item{
     padding-left: 60px !important;
    }
    &__summary {
      display: flex;
    }
    &__image {
      width: 120px;
      height: 160px;
      object-fit: contain;
      margin-right: 40px;
      @media screen and (max-width: 768px) {
        width: 60px;
        height: 80px;
        margin-right: 30px;
      }
    }
    &__brand {
      margin-bottom: 10px;
    }
    &__brand-name {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.35px;
      color: $color-dark-gray;
      margin-right: 30px;
    }
    &__sku {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      margin-bottom: -6px;
      @media only screen and (min-width: 768px) and (max-width: 1280px) {
       width: 100%;
       margin-top: 5px;
       white-space: nowrap;
       text-overflow: unset;
       overflow: unset;
       display: block;
      }
    }
    &__email {
      padding-top: 25px;
      color:$color-dark-gray;
      font-size: 14px;
      line-height: 1.5;
    }
    @media screen and (max-width: 480px) {
      &__sku {
        float: right;
        text-align: right;
      }
      &__span {
        margin-right: 0;
      }
      &__specs{
        width: 100%;
        margin-bottom: 30px;
      }
    }
    &__name {
      font-family: $font-light;
      font-size: 22px;
      line-height: 1.45;
      letter-spacing: -1.2px;
      color: $color-charcoal;
    }
    &__specs {
      width: 100%;
    }
    &__desc {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      margin-top: 5px;
      @media screen and (max-width: 990px) {
        margin-top: 0px;
      }
      margin-bottom: 0;
    }
    &__color {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-slate;
      margin: 15px 0 0;
    }
    &__cta-wrapper {
      margin-top: 35px;
    }
    &__cta {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;
      span {
        cursor: pointer;
        text-decoration: underline;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin-left: 25px;
        &:first-child {
          margin-left: 0;
        }
        @media screen and (max-width: 768px) {
          margin-left: 90px;
        }
      }
      &.--loading {
        div {
          cursor: not-allowed;
        }
        span {
          pointer-events: none;
        }
      }
    }
    &__note {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      margin-left: 160px;
      margin-bottom: 0;
      &.--mobile {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
          margin-top: 20px;
          margin-left: 0;
        }
      }
      &.--e-delivery {
        margin-left: 0;
        margin-top: 20px;
      }
    }
    &__quantity-price {
      display: none;
      @media screen and (max-width: 480px) {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__alert {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: #ffffff;
      background: $color-dark-gray;
      padding: 20px 25px;
      border-radius: 5px;
      margin: 30px 0px 0px 160px;
      @media screen and (max-width: 768px) {
        margin-left: 0px;
      }
    }
    &__qty-price {
      margin-bottom: 30px;
      .cart-item__discount-container{
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__qty {
      text-align: center;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .quantity-selector.small {
          max-width: 90px;
      }
    }
    &__price {
      font-family: $font-bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.8px;
      color: $color-charcoal;
      @media screen and (max-width: 768px) {
        text-align-last: right;
      }
    }
    &__unit-price, 
    &__total-price {
      display: block;
    }

    &__unit-price {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.8px;
      color: $color-charcoal;
    }

    &__total-price {
      font-family: $font-bold;
      font-size: 16px;
      line-height: 1.38;
      font-weight: 600;
      letter-spacing: -0.8px;
      color: $color-charcoal;
      @media screen and (max-width: 768px) {
        text-align-last: right;
      }
    }
    &__display-discount, &__display-discount-cart{
      @media screen and (max-width: 480px) {
        display: grid;
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
      margin-top: 5px;
  
      &.--mobile {
        display: none;
        @media screen and (max-width: 480px) {
          display: flex;
          margin-bottom: 20px;
        }
      }
      .cart-item__span{
        text-decoration: line-through;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.8px;
        color: $color-dark-gray;
      }
    }
    &__discount-price {
      text-decoration: line-through;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.8px;
      color: $color-dark-gray;
  
      &.--offPercent, &.--offPercentCart {
        @media screen and (max-width: 480px) {
          order: 2;
          padding-right: 0px;
        }
        text-decoration: none;
        font-size: 12px;
        color: $color-slate;
        font-family: $font-medium;
        margin-right: 0;
      }

      &.--offPercentage {
          text-decoration: none;
          font-size: 12px;
          color: $color-slate;
          font-family: $font-medium;
          margin-right: 0;
      }
  
      &.--mobile {
        font-size: 14px;
      }
    }
    &__display-discount-order {
      @media screen and (max-width: 480px) {
        display: flex;
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
      margin-top: 5px;
  
      &.--mobile {
        display: none;
        @media screen and (max-width: 480px) {
          display: flex;
          margin-bottom: 20px;
        }
      }
      .cart-item__span{
        text-decoration: line-through;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.8px;
        color: $color-dark-gray;
      }
    }

    &__stock-msg {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      margin-top: 40px;
      &.--error {
        display: flex;
        justify-content: flex-end;
        color: $color-error-red;
      } 
      @media screen and (max-width: 768px) {
        margin-top: 25px;
      }
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 10px;
      }
      .cartItemTooltip {
        z-index: 9;
        position: absolute;
        top: -60px;
        text-align: left;
        @media screen and (max-width: 1023px) {
          top: -80px;
          .tooltip__container {
            width: 160px;
          }
        }
      }
    }
    &__label {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.57;
      letter-spacing: -0.45px;
      color: $color-dark-gray;
      margin-bottom: 20px;
      &.--mobile {
        display: none;
        margin-bottom: 0;
        font-size: 14px;
        @media screen and (max-width: 767px) and (orientation: portrait)  {
          display: inline-flex;
        }
      }
      &.--quantity {
        display: block;
        font-size: 20px;
        margin-left: 25px;
        @media screen and (max-width: 480px) {
          //margin-left: 44px;
          font-size: 16px !important;
          text-align: left;
        }
        .--desktop {
          display: none;
          @media screen and (min-width: 768px) {
            display: inline;
          }
        }
        @media screen and (min-width: 768px) {
          display: inline;
         text-align: center;
        }
      }
      &.--sum {
        display: none;
        margin-bottom: 0;
        @media screen and (max-width: 480px) {
          display: inline;
          font-family: $font-bold;
          color: $color-charcoal;
        }
      }
      &.--count {
        margin-bottom: 0;
      }
    }
    &__info {
      position: relative;
    }
    &__radio-group {
      position: absolute;
      right: 0;
      bottom: 0;
      @media screen and (max-width: 768px) {
        margin-top: 30px;
        position: static;
        display: flex;
        flex-direction: row-reverse;
      }
      @media screen and (max-width: 480px) {
        margin-top: 10px !important;
      }
    }
    &__links {
      text-align: right;
    }
    &__link {
      margin-bottom: 10px;
      @media print {
        display: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      a,
      span {
        cursor: pointer;
        text-decoration: underline;
        color: $color-slate;
        font-size: 14px;
        letter-spacing: -0.45px;
        line-height: 22px;
        font-family: $font-regular;
      }
    }
  }
  
  .combo-cart-items {
    &__note {
        display: inline-block;
        padding-bottom: 25px;
        @media screen and (max-width: 990px) {
        padding-bottom: 20px;
        }
        color: $color-charcoal;
        font-family: $font-medium;
        font-size: 16px;
        letter-spacing: -0.35px;
        line-height: 24px;
        padding-left: 60px;
    }
    .cart-item {
        padding-top: 0;
        border-top: none;
        &__image {
            width: 90px;
            height: 120px;
        }
    }
    @media only screen and (max-width: 767px) {
        &__note {
          padding-bottom: 20px;
        }
        .cart-item {
          &__image {
            width: 60px;
            height: 80px;
          }
          &__qty-price{
            margin-bottom: 0 !important;
          }
          &__desc{
            color: $color-slate;
          }
        }
    }
  }

  .cart-item__shimmer {
    display: flex;
    column-gap: 40px;
    margin-top: 50px;
    .cart-item__image-shimmer {
      width: 120px;
      height: 160px;
      @media only screen and (max-width: 991px) {
        width: 60px;
        height: 90px;
      }
    }
    .cart-item__details-shimmer {
      width: 100%;
      p:last-child {
        width: 50%;
      }
    }
  }
  
  @media only screen and (max-width: 991px){
    .cart-item {
      &__display-discount {
        gap: 3px;
        margin-top: 3px;
        .cart-item__span{
          order: 1;
          font-size: 14px;
        }
      }
      &__display-discount-cart {
        gap: 3px;
        margin-top: 3px;
        display: grid;
        .cart-item__span{
          order: 1;
          font-size: 14px;
        }
      }
      &__discount-price {
        &.--offPercent {
          text-align-last: right;
        }
        &.--offPercentage {
          text-align-last: right;
        }
        &.--offPercentCart {
          text-align-last: right;
          order: 2;
        }
      }
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1399px), only screen and (max-width: 900px) and (orientation: landscape){
    .cart-item {
      &__display-discount, &__display-discount-cart{
        gap: 3px;
        justify-content: end;
        .cart-item__span{
          order: 1;
          font-size: 14px;
        }
      }
      &__discount-price {
        &.--offPercent, &.--offPercentCart {
          text-align-last: right;
          margin-right: 5px;
        }
      }
      
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1120px) {
    .cart-item { 
      &__image {
        width: 90px;
        height: 120px;
        margin-right: 10px;
      }
      &__note {
        margin-left: 100px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .cart-item {
      &__combo-item {
        padding-left: 30px !important;
      }
      &__item-of {
        margin-left : 30px !important;
      }
    }
    .combo-cart-items{
      &__note{
        padding-left: 30px;
      }
      .cart-item {
        &__qty-price{
          padding-left: 30px !important;
        }
      }
    }
  }
  
  @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .cart-item { 
      &__image {
        width: 90px;
        height: 120px;
        margin-right: 10px;
      }
      &__note {
        margin-left: 100px;
      }
    }
  }
  
  @page {
    margin: 2cm;
  }
    
  @media print {
    .cart-item {
      .print-left, .print-right {
        float: left;
        width: 50%;
      }
    }
  }
