.non-tile-pdp-feature{
    padding:0;
    width:100%;
    .cmp-teaser{
        flex-direction:column;
        &__image{
            width:100%;
            .cmp-teaser__image{
                @include breakpoint(desktop-small){
                    min-height: unset;
                }
            }
        }
        &__content{
            width:100%;
            max-width: 25.75rem;
            background: transparent;
            margin-top:1.875rem;
            text-align: left;
            position: static;
            min-height: unset;
            @include breakpoint(tab-max){
                width:100%;
            }
            @include breakpoint(tab){
                max-width:408px;
            }
            @include breakpoint(mobile){
                padding:0 18px;
            }
        }
        &__title{
            h1,h2,h3,h4,h5,h6,p{
                @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                color:$text-dark-grey;
                padding:0;
                letter-spacing:normal;
            }
        }
        &__description{
            width:100%;
            padding:.625rem 0 0;
            p{
                @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                color:$text-medium-dark-grey;
                padding:0;
                letter-spacing:normal;
            }
            @include breakpoint(tab){
                padding-top:.625rem;
            }
        }
        .cmp-image {
            .cmp-image__image{
                @media (min-width: 1920px){
                    width: 900px;
                }
            }
        }
    }
}