.teaser.img-top-align-small-font {
    padding: 0 0 4.375rem;
    margin-right:auto;
    width: 31.89%;
    @include breakpoint(tab) {
        width: 32.22%;
        padding:0 0 3.125rem;
    }
    @include breakpoint(mobile) {
        width:100%;
        padding:0 0 2.5rem;
    }
    &:last-child {
        margin-right:0;
        @include breakpoint(mobile){
            padding-bottom: 3.125rem;
        }
        .cmp-teaser {
            &__content {
                padding-bottom: 0;
            }
        }
    }
    .cmp-teaser {
        display: flex;
        flex-direction: column;
        &__content {
            padding: 1.875rem 0 0;
            background: none;
            text-align:left;
            width:20.5rem;
            @include breakpoint(tab-max){
                padding: 1.25rem 0 0;
                width:100%;
            }
            @include breakpoint(mobile){
                padding-right: .6875rem;
            }
        }
        &__title {
            h1,h2,h3,h4,h5,h6,p{               
                letter-spacing: normal;
                color:$text-dark-grey;
                @include breakpoint(desktop-small) {
                    @include ann-describtion (1.375rem, $lato-regular, 2rem);
                }
                @include breakpoint(tab-max){
                    @include ann-describtion (1.125rem, $lato-regular, 24px); 
                    letter-spacing: 0.5px;
                    color: $slate;
                }
            }
        }
        &__description {
            padding: .625rem 0 0;
            h1,h2,h3,h4,h5,h6,p {
                @include ann-describtion (0.875rem, $lato-regular, 1.25rem);
                letter-spacing: normal;
                margin: 0;
                width:100%;
                color:$text-medium-dark-grey;
                @include breakpoint(tab-max){
                    @include ann-describtion (14px, $lato-regular, 20px); 
                    color: $slate;
                }
            }
        }   
        &__action-container{
            .cmp-teaser__action-link {
                color: $color-light-blue !important;
                line-height:16px;
                padding-bottom:.5rem;
                margin-top: 1.25rem;
                border-color: $color-light-blue !important;
                @include breakpoint(tab-max){
                    line-height:16px;
                    font-size: 11px;
                    letter-spacing: 1.8px;
                }
            } 
        } 
         
        &__image{
            .cmp-image{
                @include breakpoint(tab){
                    min-height: 200px;
                }
                @include breakpoint(mobile){
                    min-height: 282px;
                }
            }
        }
    }
}