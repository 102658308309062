.tab-list {  
  padding:0;
  border-bottom: 1px solid #efefef;
  text-align: center;
    .tab-list-item {
        display: inline-block;
        list-style: none;
        padding: 12px 0;
        font-size: 12px;
        color: #757575;
        margin: 0 10px;
        cursor: pointer;
      }
      
      .tab-list-active {
        color: #000;
        border-bottom: 2px solid #000;
        font-weight: bold;
      }
      
  }