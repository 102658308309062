.pdp-feature-image-top {
    padding: 0;
    .cmp-teaser {
        &__content {
            width: 23.375rem;
            margin: 4.375rem auto ;
            @include breakpoint(tab) {
                width: 21.1875rem;
                margin: 50px auto 50px;
            }
            @include breakpoint(mobile) {
                width: auto;
                margin: 3.125rem 1.125rem;
            }
        }
        &__pretitle {
            h1, h2, h3, h4, h6, p {
            @include ann-describtion(0.75rem, $lato-regular, 1rem);
            letter-spacing: 0.125rem;
            color: $text-dark-grey;
            text-transform: uppercase;
            padding-bottom: 1.25rem;
            @include breakpoint(tab-max){
                padding-bottom: .625rem;
            }
        }
        }
        &__title {
            h1, h2, h3, h4, h5, h6, p {
                @include headingtitle_h3;
                letter-spacing: -0.0625rem;
                padding: 0 0 3.125rem 0;
                color: $text-dark-grey;
                @include breakpoint(tab-max) {
                    letter-spacing: -0.073125rem;
                    padding-bottom:1.875rem;
                }
            }
        }
        &__image{
            .cmp-image {
                min-height:800px;
            @include breakpoint(tab){
                max-height: 404px;
            }
            @include breakpoint(mobile){
                min-height: 487px;
            }
            img{
                @include breakpoint(desktop-small){
                    max-height:inherit;
                }
                @include breakpoint(tab-max){
                    min-height:inherit;
                }
            }
        }
    }
        &__description {
            padding: 0;
            p {
                @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                color: $text-medium-dark-grey;
                letter-spacing: normal;
                margin: 0;
            }
        }
    }
}
.pdp-feature-image-left {
    padding: 0;
    .cmp-teaser {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;
        @include breakpoint(desktop-lg){
            max-width:inherit;
        }
        @include breakpoint(mobile) {
            flex-direction: column;
            align-items: unset;
        }
        &__image{
            width: 56.25rem;
            max-height: 800px;
            @include breakpoint(tab-max){
                max-height:inherit;
            }
            @include breakpoint(tab){
                width:31.75rem;
            }
            @include breakpoint(mobile){
                width:100%;
            }
            .cmp-image {
                @include breakpoint(desktop-small){
                    max-width: 900px;
                    min-height: 800px;
                }
                @include breakpoint(tab){
                    min-width: 508px;
                    max-height: 451px;
                }
                @include breakpoint(mobile){
                    min-width: 375px;
                    min-height: 487px;
                }
                &__image{
                    min-height: inherit;
                    max-height: inherit;
                }
                .cmp-teaser__image {
                    @include breakpoint(mobile){
                        padding:0;
                    }
                }
            }
        }
        &__pretitle {
            h1, h2, h3, h4, h5, h6, p {
            @include ann-describtion(0.75rem, $lato-regular, 1rem);
            letter-spacing: 0.125rem;
            color: $text-dark-grey;
            text-transform: uppercase;
            padding-bottom: 1.25rem;
            @include breakpoint(tab-max){
                padding-bottom: .625rem;
            }
        }
    }
        &__title {
            h1, h2, h3, h4, h5, h6, p {
                @include headingtitle_h3;
                letter-spacing: -0.0625rem;
                padding: 0 0 3.125rem 0;
                color: $text-dark-grey;
                @include breakpoint(tab-max) {
                    letter-spacing: -0.073125rem;
                    padding-bottom:1.875rem;
                }
            }
        }
        &__description {
            padding: 0;
            max-width: 23.375rem;
            p {
                @include ann-describtion(0.875rem, $lato-regular, 1.25rem);
                color: $text-medium-dark-grey;
                letter-spacing: normal;
                margin: 0;
            }
        }
        &__content {
            width: 23.375rem;
            margin: 0 auto 0 6.25rem;
            max-width: 1380px;
            @include breakpoint(desktop-extra-sm){
                margin: 0 2.25rem;
            }
            @include breakpoint(tab) {
                width: 10.625rem;
                margin: 0 auto 0 4.25rem;
                text-align: left;
            }
            @include breakpoint(mobile) {
                width: auto;
                margin: 3.125rem 1.125rem;
            }
        }
    }
}

.dynamicpdp{
    .pdp-feature-image-top{
        padding: 0;
        display: none;
    }
}