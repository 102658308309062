.design-services {
    font-family: $font-regular;
    width: 100%;
    padding-top: 30px;

    &__title {
        h3 {
            font-size: 36px;
            font-family: $font-light;
            line-height: 44px;
            letter-spacing: -1.7px;
            color: $color-slate;
        }
    }

    &__description {
        padding-top: 15px;
        ul {
            padding-left: 15px;
            margin-left: 7px;
            margin-bottom: 0px;
        }
    }
    &__list-item {
        font-size: 16px;
        font-family: $font-regular;
        line-height: 1.5;
        letter-spacing: -0.55px;
        padding-bottom: 10px;
        color: $color-dark-gray;
        list-style-position: outside;
        &:last-child {
            padding-bottom: 0px;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 40px;
        border-bottom: 1px solid $color-dark-gray;
    }

    &__original-price {
        span {
            font-family: $font-light;
            font-size: 26px;
            line-height: 1.31;
            letter-spacing: -1.55px;
            color: $color-slate;
        }
    }
    &__button-tab {
        display: flex;
        .button {
            width: fit-content;
            padding: 8px 30px;
            &.ghost {
                margin-right: 10px;
                color: $color-slate;
            }
        }
    }
    &__discount-price{
        display: flex;
        align-items: center;
        span{
            font-size: 16px;
            font-family: $font-regular;
            line-height: 22px;
            letter-spacing: -0.8px;
            color: $color-dark-gray;
            text-decoration: line-through;
        }
    }
    &__percent-discount{
        font-family: $font-medium;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.65px;
        color: $color-slate;
        margin-left: 15px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
        .design-services{
            &__button-tab{
                .button{
                    &.small {
                        padding: 8px 15px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 900px) and (orientation: landscape){
        .design-services {
            &__title {
                h3 {
                    font-size: 32px;
                    line-height: 40px;
                    letter-spacing: -2.2px;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .design-services{
            &__title {
                h3 {
                    font-size: 32px;
                    line-height: 40px;
                    letter-spacing: -2.2px;
                }
            }
            &__footer {
                display: block;
                padding-top: 20px;
            }
            &__button-tab {
                padding-top: 25px;
                .button {
                    width: 49%;
                    &.ghost {
                        margin-right: 2%;
                    }
                }
            }
        }
    }
}
