@media print{
    body {
        font-family: Arial, Helvetica, sans-serif;
      .cmp-teaser{
        display: block;
        &__content{
          display:block;
          z-index:auto ;
        }        
      }
      .basic-text-promo-two-up{
        .cmp-teaser{
          &__content{
            position: relative;
            &__title, 
            &__description {
              display: block;
            }
          }
          &__action-container{
            position: absolute;
            right: 0;
            top: 0;
            width: 21rem;
          }
        }
       
      }
      .curated-hero-banner{
        .cmp-teaser{
          &__content{
            top:0 !important;
          }
          &__title{
            h1,h2,h3,h4,h5,h6,p{
              color:$black !important;                
            }
          }
          &__description{
              p{
                color:$black !important;
              }
          }
          &__action-link {
            color:$black !important;
          }
        }
      }
    }
  // Inspiration Product Gallery Start
  body.body-scroll-disable .container-bg-halfWhite, body.body-scroll-disable .newsletter-footer-bg-white, body.body-scroll-disable .inspiration-list__filter, body.body-scroll-disable .inspiration-list__tile-col{
    display: none;
  }
  .inspiration-landing-main{
    position: absolute;
    width: 100%;
    .inspiration-modal-display-block{
      overflow: hidden;
      position: static;
      .inspiration-modal__closeImg{
          display: none;
        }
      .inspiration-detail{
        .container-fluid{
          .row{
            width: 100%;
            .product-details__content-section{
              padding: 0 0 100px 0;
              width: 50%;
              .inspiration-detail__cart{
                width: 100%;
                .order-sample__body{
                  display: grid;
                  grid-template-columns: 45% 45%;
                  column-gap: 10%;
                  .order-sample__card{
                    width: 100%;
                  }
                }
              }
            }
            >div{
              width: 100% !important;
              max-width: 100%;
            }
            .inspiration-detail__gallery{
              width: 100%;
            }
          }            
        }
      }        
    }      
  }
}
// Global Text Only Print Icon PDF
@media print{
  .hero-banner-article-top,.hero-banner-article-bottom{
    width: 100%;
    display: block;
    text-align: center;
    .cmp-teaser{
      .cmp-teaser__content{
          position: static !important;
      }
    }
  }
  .large-featured-full-bleed-image{
    .cmp-teaser{
      .cmp-teaser__image{
        display: block;
        .cmp-image__image{
          display: block;
        }
      }
    }
  }
  .hero-banner-article-top.content-center{
      padding: 60px 0 60px;
  }
  .literature .kf-react-container, .product-list .container.kf-react-container.product-list__tiles{
    overflow-y: scroll;
    .row .col{
      margin-top: 100px;
    }
  }
}