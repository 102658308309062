.folder-details {
  &__body {
    padding: 0 20px;
  }

  &__img-container {
    display: none;
    width: 100%;
  }

  &__title {
    h1 {
      display: block;
      margin-top: 40px;
      margin-bottom: 13px;
      max-width: 80%;
      float: left;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &__edit-name {
    background-color: transparent;
    border: none;
    outline: none;
    display: block;
    margin: 50px 0 10px 30px;
    width: 35px;
    height: 35px;
    line-height: 5px;
    float: left;
  }

  &__edit-name-form {
    margin-top: 30px;
    margin-bottom: 25px;
    width: 80%;

    .form {
      &__group {
        margin-bottom: 10px;
      }
    }
  }

  &__btn-wrapper {
    span {
      padding: 0 10px;
    }
  }

  &__save, &__cancel {
    background-color: transparent;
    border: none;
    outline: none;
  }

  &__edit-note {
    padding: 20px 0;

    p {
      margin-bottom: 5px;
    }

    a {
      cursor: pointer;
    }
  }

  &__edit-note-form {
    margin-bottom: 25px;
    width: 100%;

    .form {
      &__group {
        margin-bottom: 10px;

        label {
          width: 100%;

          span {
            float: right;
          }
        }

        textarea {
          border-radius: 4px;
          background: #fff;
          border: solid 1px #ddd;
          display: block;
          width: 100%;
          clear: both;
          outline: none;
          height: 67px;
          resize: none;
          padding: 15px;
        }
      }
    }
  }

  &__action-bar {
    display: flex;
    justify-content: start;
    margin-bottom: 10px;
  }

  &__cta {
    width: 35px;
    height: 35px;
    border: none;
    outline: none;
    background-color: #dbdbdb;
    border-radius: 4px;
    margin: 15px 10px 0 0;
    color: #232323;
    fill: #232323;

    &--print {
      display: none;
    }

    svg {
      width: 15px;
      height: auto;
    }
    &:hover{
      color: #fff;
      fill: #fff;
      background-color: #364573;
    }
  }

  &__go-back {
    width: 100%;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    background-size: 100%;
    background-image: linear-gradient(to bottom, #fff 50%, #f2f2f2 100%);
    height: 50px;
    padding: 15px 20px 0;

    a {
      padding-right: 20px;
      border-left: 1px solid #fbfbfb;
      border-right: 1px solid #efefef;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__lineitems {
    padding: 25px 20px;
    background-color: #e8e8e8;
  }

  .my-folders {
    &__dropdown-panel {
      &--for-details {
        top: 28%;
        right: 2%;
      }
    }
  }
}

@media (min-width: 768px) {
  .folder-details {
    &__body {
      padding: 0 10px;
    }
    &__title {
      h1 {
        margin: 0 0 8px 0;
      }
    }

    &__img-container {
      display: block;
      width: 20%;
      float: left;
      position: relative;
    }

    &__section {
      width: 80%;
      float: left;
      padding-left: 30px;
    }

    &__body {
      padding-bottom: 15px;

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    &__info {
      width: 33.33333%;
      float: left;
      padding-right: 10px;
    }

    &__edit-note {
      width: 25%;
      float: left;
      padding: 0 20px 0 0;
    }

    &__edit-note-form {
      margin-bottom: 0;
    }

    &__action-bar {
      width: 41.66667%;
      float: left;
      padding-left: 10px;
    }

    &__edit-name {
      margin: 31px 0 10px 30px;
    }

    &__edit-name-form {
      width: 60%;
      margin-bottom: 0;
    }

    &__cta {
      &--print {
        display: block;
      }
    }

    &__lineitems {
      padding: 25px 0px;
      background-color: #e8e8e8;
    }
  }
}
