.shipping-method{
    &__delivery-option{
        margin: 10px 0 30px;
    }
    .fa-dolly{
        display: inline-block;
        margin-right: 5px;
    }
    ul{
        margin: 0;
        &:after{
            content: '';
            display: table;
            clear: both;
        }
    }
    &__product-list{
        margin: 0 10px 25px;
    }
    &__product{
        margin: 0 0 20px 20px;
    }
    &__item-list{
        width: 100%;
        margin-bottom: 50px;
    }
    &__item{
        height: 85px;
        width: auto;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        background-color: #fff;
        margin-bottom: 10px;
        &:after{
            content: '';
            display: block;
            clear: both;
        }
        &:hover{
            box-shadow: 0 4px 11px 0 grey;
            cursor: pointer;
        }
        &--highlight{
            box-shadow: 0 4px 11px 0 grey;
        }
    }
    &__item-details{
        margin: 6px 0 0;
        position: relative;
        width: 75%;
        float: left;
        padding-left: 16px;
    }
    &__item-price{
        float: right;
        margin-right: 15px;
        margin-top: 15px;
    }
    &__estimated-time{
        margin-top: 20px;
        margin-bottom: 3px;
    }
    &__checkbox{
        clear: both;
        display: block;
        width: 100%;
        box-shadow: 4px 4px 4px -1px rgba(0,0,0,.02);
        border: 1px solid #dbdbdb;
        height: 0px;
        opacity: 0;
        margin-bottom: 0;
        &:not(:checked){
            &+label{
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 26px;
                    height: 26px;
                    background-color: #e8e8e8;
                    background-image: linear-gradient(rgba(255,255,255,.05),rgba(0,0,0,.05));
                    border-radius: 50%;
                    box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,.15);
                }
            }
        }
        &:checked{
            &+label{
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 26px;
                    height: 26px;
                    background-color: #e8e8e8;
                    background-image: linear-gradient(rgba(255,255,255,.05),rgba(0,0,0,.05));
                    border-radius: 50%;
                    box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,.15);
                }
                &:after{
                    content: '';
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    left: 6.5px;
                    top: 6.5px;
                    border-radius: 50%;
                    background: currentColor;
                    transition: opacity .15s;
                }

            }
        }
    }
    &__checkbox-label{
        position: relative;
        padding-top: 5px;
        padding-left:35px;
        outline: 0; 
        display: block;
    }
}
@media (min-width: 768px){
    .shipping-method{
        margin: 0 20px;
        &__item{
            width: 90%;
            height: 90px;
        }
    }
}
@media (min-width: 980px){
    .shipping-method{
        &__item{
            width: 70%;
        }
    }
}