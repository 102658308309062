.orderOverlay{
    height: 100vh;
    position: fixed;
    overflow: auto;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background-color: $cool-light-grey;
}
.order-sample-container{
    position: relative;
}
.orderSampleWrapper {
    width:100%;
    position: relative;
    padding: 4.375rem 6.875rem 8.125rem;
    height:100%;
    @include breakpoint(desktop-extra-lite-lg){
        padding-right:2.25rem;
        padding-left:2.25rem;
    }
    @include breakpoint(tab){
        padding-right:32px;
        padding-left:32px;
    }
    @include breakpoint(mobile){
        padding-right:18px;
        padding-left:18px;
    }
    .order-sample-container{
        max-width:1380px;
        margin:0 auto;
        position:relative;
        height:100%;
    }
    .closeBtn {
        height: 31px;
        width: 31.21px;
        position: absolute;
        top:0;
        right:0;
        font-size: 20px;
        border: none;
        background: transparent;
        color: $cool-dark-grey;
        @include breakpoint(tab-max){
            top: -50px;
            right: 0;
        }
        @include breakpoint(tab) {
            height: 31.21px;
            width: 31px;
            margin-top: 45px;
        }
        @include breakpoint(mobile) {
            height: 21px;
            width: 21.21px;
            margin-top:9.5px;
        }
        img{
            width:100%;
            height:100%;
            vertical-align: baseline;
        }
        img{
            width:100%;
            height:100%;
        }
    }

    .osHeader{
        h1,h2,h3,h4,h5,h6{
            max-width: 540px;
            color: $text-dark-grey;
            font-family: $playfair-regular;
            font-size: 42px;
            letter-spacing: -1.17px;
            line-height: 54px;
            padding:0;
            margin: 0;
        }
        p{
            max-width: 840px;
            color: $text-medium-dark-grey;
            font-family: $lato-regular;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-top: 30px;
            @include breakpoint(mobile){
                margin-top:10px;
            }
        }

        .optionWrapper {
            display: flex; 
            flex-direction: column;
            margin-top:30px;
            @include breakpoint(mobile){
                margin-top:0;
            }
            h5{
                height: 32px;
                width: 59px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 32px;
                @include breakpoint(mobile) {
                    font-size:18px;
                    line-height:28px;
                }
            }
            span{
                width: 84px;
                color: $text-medium-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 28px;
                margin-top:5px;
                @include breakpoint(tab) {
                    margin-bottom: 0;
                }
                @include breakpoint(mobile) {
                    margin-bottom: 10px;
                    font-size:14px;
                    line-height:20px;
                    margin-top:3px;
                }
            }
        }
    }
    .stickyHeader{
        .filterMobile{
            display: none;
        }
    }
    .order-sample-wrapper{
        .stickyHeader{
            display: flex;
            justify-content: space-between;
            background-color: $cool-light-grey;
            align-items: center;
            height: 30px;
            position: sticky;
            margin:25px 0 20px;
            top:0;
            z-index: 1;
            @include breakpoint(tab){
                margin-top:17px;
            }
            @include breakpoint(mobile){
                margin-top:21px;
            }
            .headerShadow {
                box-shadow: 0 10px 20px 0 $header-shadow;
            }
            
            .filter,.sort{
                background: transparent;
                border:none
            }
            .filter{
                height: auto;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                margin:0;
                padding:0;
                @include breakpoint(mobile){
                    color:$text-medium-dark-grey;
                }
                img{
                    margin-right: 10px;
                }
            }
            .filterMobile{
                @include breakpoint(tab) {
                    padding-top: 0;
                    margin-bottom: 0;
                    font-size:16px;
                    color:$text-medium-dark-grey;
                }
            }
            .sortWrapper{
                border-radius: 3px;
                position: relative;
                cursor: pointer;
            }
            .sort{ 
                border:none; 
                margin: 0;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                color: $text-dark-grey;
                font-family: $lato-regular;

                .downArro{
                    width: 16px;
                    margin-left: 9.5px;
                    margin-top: 8px;
                }
                .upArro{
                    width: 16px;
                    margin-left: 9.5px;
                    transform: rotate(180deg);
                    margin-top: 8px;
                }
            }
            .sortList {
                padding: 0;
                margin: 0;
                position: absolute;
                bottom:0;
                top: 100%;
                right: 0;
                width: 192px;
                min-height: 222px;
                background-color:$cool-light-grey;
                z-index: 9;
                box-shadow: 0 0 20px 0 $sort-drop-shadow;
                li{
                    list-style-type: none;
                    height: 40px;
                    width: 100%;
                    text-align: left;
                    padding: 0 20px 0 38px;
                    text-align: left;
                    color: $text-light-grey;
                    font-family: $lato-regular;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 40px;
                    @include breakpoint(tab-max){
                        text-align:right;
                        right:18px;
                    }
                }
            }
        }
        &.filterStickyHeader{
            width:100%;
            position:fixed;
            top:0;
            left:0;
            background:$cool-light-grey;
            z-index:2;
            box-shadow: 10px 0px 15px rgba(0,0,0, 0.07);
            padding:0 2.6%;
            @include breakpoint(tab-max){
                min-height:80px;
            }
            .stickyHeader{
                max-width:1380px;
                margin:25px auto;
                background:$cool-light-grey;
            }
        }
    }
    .mainWrapper{
        display: flex;
        @include breakpoint(mobile) {
            border-top: 1px solid $cool-dark-grey-cap;
            padding-top: 35px;
            padding-left: 0;
        }
        .filterWrapper, .filterWrapperMobile{
            width: 14.9375rem;
            margin-right: 6.875rem;

            .activeColor{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 2.5px;
                @include breakpoint(tab) {
                    margin-bottom: 0;
                }
                @include breakpoint(mobile) {
                    margin-bottom: 0;
                }
                button{
                    margin-right: 10px;
                    border: 1px solid $cool-dark-grey-cap;
                    border-radius: 1px;
                    color: $text-dark-grey;
                    font-family: $lato-regular;
                    font-size: 11px;
                    letter-spacing: 0.16px;
                    line-height: 16px;
                    text-align: right;
                    padding: 7px 12.51px 7px 10px;
                    background: transparent;
                    img{
                        width: 10px;
                        margin-left: 5px;
                    }
                }
            }
            .clearAll{
                cursor: pointer;
                color: $text-medium-dark-grey;
                font-family: $lato-regular;
                font-size: 13px;
                letter-spacing: 0.2px;
                line-height: 12px;
                background: transparent;
                border: none;
                border-bottom: 1px solid $text-medium-dark-grey;
                padding: 0;
                margin-bottom: 22px;
                @include breakpoint(tab) {
                    margin-bottom: 23px;
                  }
                  @include breakpoint(mobile) { 
                    margin-bottom: 23px;
                  }
            }  
            ul{
                margin:0;
                border-top: 1px solid $cool-dark-grey;
                padding: 25px 0 0 0;
                width: 239px;
               
                .checkboxWrapper {
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    .checkbox{
                        align-items:center;
                    }
                    input{
                        margin-right: 10px;
                    }
                        
                }
            }
            
            .filterFooter{
                position: fixed;
                z-index: 1;
                bottom:0;
                left: 0;
                right: 0;
                height: 70px;
                padding: 0 18px;
                background-color: $cool-light-grey;
                box-shadow: 0 -10px 20px 0 $header-shadow;
                display: flex;
                align-items: center;
                justify-content: center;
                  .applyBtn {
                      color: $color-medium-blue-cap;
                      font-family: $lato-regular;
                      font-size: 11px;
                      font-weight: normal;
                      letter-spacing: 2.5px;
                      line-height: 10px;
                      text-align: center; 
                      width: 100%;
                      padding: 15px;
                      border: 1px solid $color-medium-blue-cap;
                  }
            }
            .mob-colorlist-filter{
                @include breakpoint(tab-max){
                    @media (max-height: 375px){
                        height: 180px;
                        overflow-y: auto;
                        border-top: 1px solid #a9a9a9;
                        padding: 25px 0 0;
                    }
                }
                ul{
                    @include breakpoint(tab-max){
                        @media (max-height: 375px){
                            border-top:0;
                            padding:0;
                        }
                    } 
                }
                .clearcolor-availability{
                    @include breakpoint(tab-max){
                        @media (max-height: 375px){
                            height: 65px;
                            overflow-y: auto;
                        }
                    }
                }
            }
        }
        .listWrapper{
            display: flex;
            gap:65px 29px;
            padding-top: 4px;
            width:100%;
            @include breakpoint(desktop-extra-lite-sm){
                flex-wrap: wrap;
                gap:50px 5px;
            }
            @include breakpoint(tab-max){
                padding-right: 0;
                justify-content: unset;
            }
            .cardWrapper{
                &.disabled{
                    cursor: not-allowed;
                    pointer-events: none;
                    width:100%;
                    max-width:323px;
                    display:flex;
                    flex-direction:column;
                    @include breakpoint(desktop-lg){
                        max-width:31.8%;
                    }
                    @include breakpoint(desktop-mid){
                        max-width:31.6%;
                    }
                    @include breakpoint(desktop-extra-lite-lg){
                        max-width:31.5%;
                    }
                }
                .imgBox{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    background-color: $white;
                    border: 1px solid $cool-medium-grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    min-height:323px;
                    @include breakpoint(tab){
                        min-height:160px;
                    }
                    &.active{border-color:$text-dark-grey;}
    
                    .tag{
                        position: absolute;
                        font-family:$lato-bold;
                        font-size: 9px;
                        display: flex;
                        align-items: center;
                        padding: 10px;
                        top:10px;
                        left: 10px;
                        box-sizing: border-box;
                        height: 31px;
                        width: auto;
                        border: 1px solid $tag-border-color;
                        text-transform: uppercase;
                        color:$color-light-blue;
                        @include breakpoint(tab-max){
                            padding:8px 10px;
                        }
                    }
    
                    img{
                        width: 230px;
                        height: 230px;
                    }
                }
                .cardFooter{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: $lato-regular;
                    color: $text-dark-grey;
                    margin-top: 22px;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 24px;
                    @include breakpoint(tab){
                        margin-top:20px;
                    }
                    @include breakpoint(mobile){
                        margin-top:12px;
                    }
                    .cardTitle{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 200px;
                    }
                }
                .out_of_stock{
                    margin-top: 10px;
                }
            }
            
        }
        .orderSampleWrapper__view_more {
            height: 20px;
            width: 100%;
            color: $text-medium-dark-grey;
            font-family: $lato-regular;
            font-size: 11px;
            letter-spacing: 0.2px;
            line-height: 20px;
            margin-top: 25px;
            cursor: pointer;
            text-decoration: underline;
        }
    }  
}


.orderFooter{
    height: 110px;
    bottom: 1px;
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: $white;
    box-shadow: 0 -10px 20px 0 $header-shadow;
    z-index:2;
    @include breakpoint(mobile){
        max-height:70px;
    }
    .addToCartWrapper{
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 110px 0;
      @include breakpoint(desktop-mid){
          padding: 0 32px 0;
      }
      @include breakpoint(tab-max){
          padding: 0 32px 0;
      }
     
    @include breakpoint(mobile){
        padding: 0px;
    }
        .priceWrapper{
            display: flex;
            flex-direction: column;
            span{
                font-family: $lato-regular;
                color: $text-dark-grey;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
            }
            .cartPrice{
                font-family: $lato-regular;
                color: $text-dark-grey;
                font-size: 24px;
                letter-spacing: 0;
                line-height: 36px;
            }
        }
        .addToCartBtn {
            height: 50px;
            width: 241px;
            border-radius: 1px;
            background-color: $maroon;
            color: $white;
            font-family: $lato-regular;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 2px;
            line-height: 24px;
            text-transform: uppercase;
            border: none;
            margin-left: 40px;
            margin-top: 4px;
            cursor: pointer;
            @include breakpoint(mobile){
                font-size:11px;
            }
          }
        .addToCartBtn.disabled {
            background-color: $annsacks-add-to-cart-btn;
            pointer-events: none;
            cursor: unset;
        }
    }
  }


  @include breakpoint(tab) {
    
    .orderSampleWrapper {
        width: 100%;
        margin-top: 0px;
        padding-top: 50px;
       
        .osHeader{
            padding-left: 0;
            h1{
                font-size: 42px;
                letter-spacing: -1.17px;
                line-height: 54px;
                margin-bottom: 0;
                padding-top:0;
            }
            p{
                width: 100%;
            }
        }
        .stickyHeader{
            margin-bottom: 21px;
            padding-left: 0;
            .filter{
                display: none;
            }
            .filterMobile{
                display: block;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                border: none;
                background: transparent;
                img {
                    margin-right: 10px;
                }
            }
        }
        .mainWrapper{
            padding-left: 0;
            flex-direction: column;
            position: relative;
            .filterWrapper{display: none;}

            .filterWrapperMobile{
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                z-index: 1;
                background: $white;
                padding: 40px 20px;

                .mobileFilterHead {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 54px;
                    .filterMobile {
                        border: none;
                        background: transparent;
                        font-size: 16px;
                        line-height:24px;
                        font-family:$lato-regular;
                        color:$text-medium-dark-grey;
                        font-family: $lato-regular;
                        img{
                            margin-right: 10px;
                        }
                    }
                    .mobleCloseBtn{
                        font-size: 20px;
                        border: none;
                        background: transparent;
                        color: #ccc;
                        img{
                            width: 21px;
                        }
                    }
                }
                ul{
                    width: 100%;
                    height: 140px;
                    overflow: auto;
                }
                .filterFooter{
                    position: fixed;
                    z-index: 1;
                    bottom:0;
                    left: 0;
                    right: 0;
                    height: 70px;
                    padding: 0 18px;
                    background-color: $cool-light-grey;
                    box-shadow: 0 -10px 20px 0 $header-shadow;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                      .applyBtn {
                          color: $color-medium-blue-cap;
                          font-family: $lato-regular;
                          font-size: 11px;
                          font-weight: normal;
                          letter-spacing: 2.5px;
                          line-height: 10px;
                          text-align: center; 
                          width: 100%;
                          padding: 15px;
                          border: 1px solid $color-medium-blue-cap;
                      }
                }
            }
            .listWrapper{
                gap: 38px 27px;
                padding-top: 0;
                .cardWrapper.disabled{
                    max-width:160px;
                    .imgBox{
                        width:100%;
                        height: 100%;

                        img{
                            width: 114px;
                            height: 114px;
                        }
                    }
                    .cardFooter{
                        display: block;
                        width: 160px;
                        .cardTitle{
                            width: 147px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .orderFooter{
        .addToCartWrapper{
          width: 100%;
          align-items: center;
        } 
    }

  }
  @include breakpoint(mobile) {
    .orderOverlay{
        overflow-x: hidden;
    }
    .orderSampleWrapper {
        width: 100%;
        margin-top: 0px;
        padding-top: 50px;
        .osHeader{
            padding-left: 0;
            h1{
                font-size: 42px;
                letter-spacing: -1.17px;
                line-height: 54px;
                margin-bottom: 10px;
                padding-top: 0;
            }
            p{
                width: 100%;
                @include breakpoint(mobile) {
                    margin-bottom: 18px;
                }
            }
        }
        .stickyHeader{
            padding-left: 0;
            .filter{
                display: none;
                @include breakpoint(mobile){
                    display: none !important;
                }
            }
            .filterMobile{
                display: block;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                border: none;
                background: transparent;
                margin-top: 11px;
                @include breakpoint(mobile){
                    display: block !important;
                }
                img {
                    margin-right: 10px;
                }
            }
        }
        .mainWrapper{
            flex-direction: column;
            position: relative;
            .filterWrapper{display: none;}

            .filterWrapperMobile{
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                z-index: 1;
                background: $white;
                padding: 40px 20px;

                .mobileFilterHead {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 40px;
                    .filterMobile {
                        border: none;
                        background: transparent;
                        font-size: 16px;
                        line-height:24px;
                        font-family:$lato-regular;
                        color:$text-medium-dark-grey;
                        img{
                            margin-right: 10px;
                        }
                    }
                    .mobleCloseBtn{
                        font-size: 20px;
                        border: none;
                        background: transparent;
                        color: #ccc;
                    }
                }
                ul{
                    width: 100%;
                }

              .filterFooter{
                  position: fixed;
                  z-index: 1;
                  bottom:0;
                  left: 0;
                  right: 0;
                  height: 70px;
                  padding: 0 18px;
                  background-color: $cool-light-grey;
                  box-shadow: 0 -10px 20px 0 $header-shadow;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                    .applyBtn {
                        color: $color-medium-blue-cap;
                        font-family: $lato-regular;
                        font-size: 11px;
                        font-weight: normal;
                        letter-spacing: 2.5px;
                        line-height: 10px;
                        text-align: center; 
                        width: 100%;
                        padding: 15px;
                    }
              }
            }
            .listWrapper{
                display: flex;
                gap: 30px 19px;
                padding-top: 0;
                justify-content: space-between;
                .cardWrapper.disabled{
                    min-width:160px;
                    max-width:46%;
                    .imgBox{
                        width: 100%;
                        min-height: 160px;
                        height:100%;
                        max-height:160px;
                        img{
                            width: 114px;
                            height: 114px;
                        }
                    }
                    .cardFooter{
                        .cardTitle{
                            width: 115px;
                            overflow: visible;
                            white-space: normal;
                        }
                    }
                }
            }
        }
    }
    .orderFooter{
        padding: 0 18px;
        .addToCartWrapper{
          width: 100%;
        } 
    }
  }
  .out_of_stock{
    display: flex;
    column-gap: 10px;
    align-items: center;
    .text{
        font-family: $lato-regular;
        font-size: 14px;
        color: $color-red-alert;
        line-height: 20px;
    }
    .icon{
        img{
            display:block;
        }
    }
  }

// ML overlap filter issue 
.product-details{
    .product-details__overview-block{
        .product-details__content-section{
            .orderOverlay{
                .filterWrapperMobile{
                    .mob-colorlist-filter{
                        @include breakpoint(tab){
                            height: auto;
                        }
                        .clearcolor-availability{
                            @include breakpoint(tab){
                                position: relative;
                                min-height: 100%;
                                overflow-y: scroll;
                                height: 88px;
                            }
                        }
                    }
                }
            }
            .checkbox{
                @include breakpoint(mobile){
                    margin-bottom:20px;
                }
            }
        }
    }
}
.order-sample-container{
.mainWrapper{
    .checkbox {
        input:checked~span{
            color:$text-dark-grey;
        }
        .checkbox__border{
            border-color:$color-light-blue;
        }
    }.checkbox_default{
        font-size:12px !important; 
        line-height:13px;
        letter-spacing:0.2px;
        font-family:$lato-regular;
        color:$text-medium-dark-grey;
    }
    .checkboxWrapper{
        .checkbox__border{
            margin:0 10px 0 0;
            width:16px;
            height:16px;
            .checkbox__marker{
                width:100%;
                height:100%;
                top:0;
                left:0;
            }
        }
    }
}
}
