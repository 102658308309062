.check-out {
    margin-top: 40px;
    &__grid {
        display: flex;
        &-steps {
            width: 100%;
            max-width: 840px;
            margin-bottom: 150px;
        }
        &-summary {
            width: 100%;
            max-width: 420px !important;
            margin-left: 7.5rem;
        }
        @media screen and (min-width: 1024px) and (max-width: 1366px) {
            &-steps {
                max-width: unset;
            }
            &-summary {
                max-width: 340px;
                margin-left: 60px;
            }
        }
        @media screen and (max-width: 1023px) {
            display: block;
            &-summary {
                display: none;
                margin-left: 0;
            }
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 65px;
    }
    &__back {
        cursor: pointer;
        font-family: $font-regular;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        display: inline-flex;
        align-items: center;
        img {
            margin-right: 20px;
        }
    }
    &__support {
        margin: 0 40px 40px;
        div {
            margin-bottom: 10px;
            font-family: $font-light;
            font-size: 26px;
            line-height: 1.31;
            letter-spacing: -1.55px;
            color: $color-slate;
        }
        a {
            cursor: pointer;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.57;
            letter-spacing: -0.65px;
            color: $color-dark-gray;
        }
        &.--lg-device {
            display: block;
        }
        &.--md-device {
            display: none;
        }
        &.--sm-device {
            display: none;
        }
    }
    &__title {
        display: block;
        font-family: $font-light;
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -1.5px;
        color: $color-slate;
        margin-bottom: 28px;
    }
    &__sticky-summary {
        position: sticky;
        top: 0;
        .order-summary-wrapper{
            margin-left: 0px;
            margin-right: 0px;
        }
        .order-summary {
            padding-top: 45px;
            &__separator {
                border-top: 1px solid $color-dark-gray;
            }
            &__item {
                color: $color-charcoal;
            }
        }
    }
    &__sticky-content {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;
        -ms-overflow-style: none;
    	scrollbar-width: none;
		&::-webkit-scrollbar {
   		 display: none;
		}
    }
}
.sign-in__error-box {
    .error-msg{
        color: $color-error-red;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.55px;
        font-family: 'Helvetica Now Text W05 Regular';
    }
}

.review-order {
    display: grid;
    grid-template-columns: 2fr 1fr;
    &__order-summary {
        display: none;
    }
    &__cta {
        .button {
            min-width: 216px;
            float: right;
        }
    }
    &__title {
        display: block;
        font-family: $font-medium;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.4px;
        color: $color-charcoal;
        margin-bottom: 5px;
    }
    &__desc {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        a {
            color: $color-dark-gray;
            text-decoration: underline;
        }
    }
    .shipping-accordion {
        padding: 50px 0 0;
        border-radius: 0;
        border: none;
        &__separator{
            color: $color-dark-gray;
        }
        .preview-cart-item__name{
            color: $color-charcoal;
        }
    }
}

.taxware-down {
    .modal-popup {
        .modal-popup-content {
            width: 600px;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
    &__title {
        margin-top: 20px;
        color: $color-slate;
        font-family: $font-light;
        font-size: 36px;
        letter-spacing: -1.7px;
        line-height: 44px;
    }
    &__desc, &__desc p {
        margin-top: 20px;
        color: $color-dark-gray;
        font-family: $font-regular;
        font-size: 20px;
        letter-spacing: -0.65px;
        line-height: 28px;
    }
    &__cta {
        margin-top: 40px;
    }
}

.payment-method {
    &__title {
        font-family: $font-medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.4px;
        line-height: 28px;
        color: $color-charcoal;
        margin-bottom: 30px;
    }
    &__paypal-logo {
        width: 100px;
        height: 25px;
    }
    &__tile {
        border-radius: 5px;
        background-color: $color-white;
        border: solid 1px $color-light-gray;
        padding: 30px 25px;
        &-details {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-name {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-charcoal;
        }
        &.--card {
            margin-bottom: 20px;
            &.--active {
                padding-bottom: 25px;
            }
        }
        &.--active {
            background-color: $color-off-white;
            border: solid 1px $color-dark-gray;
            .payment-method__tile-details {
                cursor: auto;
            }
        }
    }
    &__card-details {
        margin-top: 10px;
    }
    &__billing-checkbox {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
    }
    &__norton-logo {
        img {
            width: 92px;
            height: 47px;
        }
    }
    &__use-different {
        span {
            cursor: pointer;
            text-decoration: underline;
            font-family: $font-regular;
            font-size: 16px;
            letter-spacing: -0.55px;
            line-height: 24px;
            color: $color-slate;
        }
    }
    &__paypal {
        margin-top: 30px;
    }
    &__billing-form {
        &-title {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-charcoal;
            margin-bottom: 20px;
        }
    }
    &__card-name {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        margin-left: 10px;
        &--label {
            width: 33.33%;
            margin-top: 10px;
            text-align: right;
            padding-right: 25px;
            font-size: 14px;
            text-align: right;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            font-weight: 500;
        }
        &--field {
            width: 33.33%;
        }
        &--input {
            border-radius: 4px;
            background-color: $color-white;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 12px;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            height: 40px;
            width: 100%;
            border: solid 1px #ddd;
            box-sizing: border-box;
            &.--error {
                border: solid 1px #e50000;
                margin-bottom: 4px;
            }
            &:focus-visible {
                outline: -webkit-focus-ring-color auto 1px
            }
        }
        &--error {
            font-size: 12px;
            font-family: $font-regular;
            color: #e50000;
        }
    }
    &__promo {
        margin-bottom: 40px;
        &-add {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            img {
                width: 14px;
                height: 14px;
                margin-right: 10px;
            }
            span {
                color: $color-charcoal;
                font-family: $font-medium;
                font-size: 20px;
                letter-spacing: -0.4px;
                line-height: 28px;
            }
        }
        &-field {
            display: flex;
            align-items: center;
            .button,  .kf-react-button {
                @media screen and (max-width: 560px){
                    min-width: 97px;
                    height: 40px;
                    padding: 8px 29px;
                }
            }
        }
        &-input {
            width: 100%;
            @media screen and (max-width: 560px){
                max-width: 222px;
                height: 60px;
            }
            max-width: 380px;
            margin-right: 20px;
            padding: 15px;
            border-radius: 4px;
            border: solid 1px $color-dark-gray;
            background-color: #ffffff;
            font-family: $font-regular;
            font-size: 18px;
            line-height: 1.44;
            letter-spacing: -0.7px;
            color: $color-slate;
            &.--error {
                border-color: $color-error-red;
            }
            &:focus-visible {
                border-color:$color-slate;
            }
        }
        &-error {
            margin-top: 10px;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: -0.3px;
            color: $color-error-red;
        }
        &-list {
            margin-bottom: 10px;
        }
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:first-child {
                margin-top: 20px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            img {
                cursor: pointer;
                width: 10px;
                height: 10px;
                margin-right: 15px;
            }
        }
        &-label {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-slate;
        }
    }
    &__payment-process-error {
        margin-top: 20px;
        color: $color-error-red;
        font-family: $font-regular;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 22px;
    }
}

.shipping-accordion {
    padding: 50px 40px;
    border-radius: 0px 0px 5px 5px;
    border: solid 1px $color-light-gray;
    &__separator {
        height: 1px;
        background-color: $color-dark-gray;
    }
    .collapsible-accordion {
        .Collapsible {
            border-bottom: 1px solid $color-dark-gray;
            .Collapsible__trigger {
                padding: 25px 0;
                .collapsible__header {
                    span {
                        font-family: $font-light;
                        font-size: 22px;
                        line-height: 1.45;
                        letter-spacing: -1.2px;
                        color: $color-charcoal;
                    }
                }
            }
            .Collapsible__contentInner {
                padding: 5px 0 10px;
                .preview-cart-item {
                    padding: 30px 0;
                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 990px) {
    .check-out {
        margin-top: 25px;
        &__title {
            font-size: 26px;
            line-height: 1.23;
            letter-spacing: -1.15px;
            margin-bottom: 28px;
        }
        &__support {
            margin-bottom: 0;
            &.--lg-device {
                display: none;
            }
            &.--md-device {
                display: block;
            }
            &.--sm-device {
                display: none;
            }
        }
        &__sticky-summary {
            position: static;
        }
    }

    .payment-method {
        &__title {
            font-family: $font-medium;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.4px;
            line-height: 28px;
            color: $color-charcoal;
            margin-bottom: 30px;
        }
        &__billing-checkbox {
            margin-bottom: 30px;
        }
        &__norton-logo {
            img {
                width: 74px;
                height: 38px;
            }
        }
    }

    .review-order {
        grid-template-columns: 1fr;
        &__order-summary {
            display: block;
            order: 1;
        }
        &__mssg {
            order: 3;
        }
        &__cta {
            order: 2;
            margin-top: 50px;
            margin-bottom: 30px;
            .button {
                float: none;
                width: 100%;
            }
        }
        &__title {
            display: none;
        }
        .order-summary {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .check-out {
        margin-top: 20px;
        margin-bottom: 45px;
    }
}


@media screen and (max-width: 576px) {
    .check-out {
        &__shipping-modal .modal-popup .modal-popup-content {
            padding: 35px 18px 65px !important;
        }
        &__support {
            &.--lg-device {
                display: none;
            }
            &.--md-device {
                display: none;
            }
            &.--sm-device {
                display: block;
                margin-top: 50px;
                text-align: left;
            }
        }
    }
    .payment-method {
        &__card-name {
            display: block;
            &--label {
                width: calc(90% - 10px);
                text-align: left;
                margin-bottom: 10px;
            }
            &--field {
                width: calc(90% - 25px);
            }
        }
    }   
}
