.carousel-swipe-scroll {
    &.carousel-content-center {
        &.content-three-up {
            padding: 0 0 70px;
            @include breakpoint(tab-max){
                padding: 0 0 50px;
            }
            .carousel-header-info{
                display: flex;
                flex-direction: column;
                position: relative;
                @include breakpoint(desktop-small){
                    padding: 4.375rem 0;
                }
                @include breakpoint(tab){
                    padding: 3.125rem 2rem 1.25rem;
                }
                @include breakpoint(mobile){
                    padding: 3.125rem 1.125rem 1.25rem;
                }
                .cmp-carousel__title{
                    width: 100%;
                    h1,h2,h3,h4,h5,h6,p{
                        @include ann-describtion(1.625rem, $lato-regular, 2.25rem);
                        margin: 0;
                        letter-spacing: 0;
                        color: $text-medium-dark-grey;
                        text-align: center;
                        padding: 0;                      
                        @include breakpoint(tab-max){
                            font-size: 1.125rem;
                            line-height: 1.75rem;
                        }
                    }
                }       
            }
            .cmp-carousel.cmp-carousel-slick {
                .slick-initialized {                
                    .slick-list {
                        @include breakpoint(desktop-small){
                            max-width: 1600px;
                            margin: 0 auto;
                            padding-right:5rem;
                        }                        
                        @include breakpoint(tab){
                            padding-right: 6.25rem;
                        }
                        @include breakpoint(mobile){
                            padding-right: 1.75rem;
                        }
                        .slick-track {
                            display: flex;
                            .slick-slide {
                                @include breakpoint(desktop-small){
                                    margin: 0 1.875rem 0 0;
                                }
                                @include breakpoint(tab){
                                    margin: 0 1.25rem 0 0;
                                }
                                @include breakpoint(mobile){
                                    margin: 0 .625rem 0 0;
                                }
                                .teaser {
                                    padding: 0;
                                    .cmp-teaser {
                                        display: block;
                                        &__title{
                                            h1,h2,h3,h4,h5,h6,p{
                                                @include ann-describtion(0.875rem, $lato-regular, 1rem);
                                                color: $text-dark-grey;
                                                letter-spacing: .125rem;
                                                padding-top: 1.875rem;
                                                text-align: left;
                                                @include breakpoint(tab-max){
                                                    font-size: .75rem;
                                                    padding-top: .625rem;
                                                }
                                            }
                                        }
                                        &__image {
                                            .cmp-image {
                                                @include breakpoint(desktop-small){
                                                    max-width: 440px;
                                                    max-height: 602px;
                                                }                                                                   
                                            }                
                                        }
                                    }
                                    .cmp-img-caption{                                        
                                        h1,h2,h3,h4,h5,h6,p {
                                            @include ann-describtion(0.875rem, $lato-regular, 1rem);
                                            color: $text-dark-grey;
                                            letter-spacing: .125rem;
                                            text-transform: uppercase;
                                            padding-top: 1.875rem;
                                            text-align: left;
                                            @include breakpoint(tab-max){
                                                font-size: .75rem;
                                                padding-top: .625rem;
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                    }
                    .slick-dots{
                        margin: 3.125rem 0 0;
                        line-height:0;                    
                        @include breakpoint(tab){
                            margin: 2.5rem 0 0;
                        }
                        @include breakpoint(mobile){                        
                            margin: 2.5rem 0 0;
                        }
                        li{
                            button{
                                border:1px solid $text-medium-dark-grey;
                            }
                            &.slick-active{
                                button{
                                    background:$text-medium-dark-grey;
                                }
                            }
                        }
                    }
                }
            }                        
        }
    }    
}