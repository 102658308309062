
  
  //gbh code below

.social-share {
    &__box {
        padding: 20px 25px;
        border: 1px solid rgba(151,151,151,0.1);
        border-radius: 1px;
        background-color: $color-white;
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.07);
        align-items: flex-start;
        justify-content: center;
        @include breakpoint(tab){
            right: 0px;
          }
    }
    &__cta {
        font-family: $font-regular-base;
        color: $text-medium-dark-grey;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        white-space: nowrap;
        img {
            height: 30px;
            width: 19px;
            margin-right: 8px;
        }
        &:hover {
            text-decoration: none;
            color: $text-medium-dark-grey;
        }
    }
    &__sites {
        column-gap: 25px;
        ul li {
            padding-bottom: 0;
            margin-right: 1.4375rem;
            &:last-child{
                margin-right: 0;
            }
        }
        ul li a img{
            color: $text-medium-dark-grey-small;
            height: auto;
        }
    }
    &__actions {
        margin-top: 15px;
        padding-top: 20px;
        justify-content: flex-start;
        border-top: 1px solid $light-gray;
        [role=listitem]{
            margin-right: 2.6875rem;
            &:last-child{
                margin-right: 0;
            }
        }
    
    }
}