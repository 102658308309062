
//gbh code below

.product-tile {
  &__details {
    width: 100%;

    .product-tile__title {
      height: auto;
      width: auto;
      color: $text-dark-grey;
      font-family: $font-regular-base;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .product-tile__startingat {
      height: 14px;
      width: 69px;
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 11px;
      letter-spacing: 1.67px;
      line-height: 14px;
      text-align: right;
      padding: 0;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 11px;
        letter-spacing: 1.67px;
        line-height: 14px;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 11px;
        letter-spacing: 1.67px;
        line-height: 14px;
      }
    }

    .product-tile__price {
      min-width: 50px;
      color: $text-dark-grey;
      font-family: $font-regular-base;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

    .product-tile__description {
      height: 18px;
      max-width: 171px;
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 18px;
    }

    .product-tile__colors {
      .product-tile__colors--count {
        height: 18px;
        width: 118px;
        color: $text-medium-dark-grey;
        font-family: $font-regular-base;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 18px;
      }
    }
  }

  &__image {
    width: 100%;
    height: 465px;

    @include breakpoint(tab) {
      height: 474px;
      background-color: $white;
    }

    @include breakpoint(mobile) {
      height: 216px;
    }
  }
  &__compare-main {
    @include breakpoint(mobile) {
      min-height: 32px;
      margin-top: 20.89px;
    }
  }
  &__share-icon-plp{
    @include breakpoint(tab-max){
      display: none;
    }
  }
}

.button.small,
.kf-react-button.small {
  padding: 11px 30px 13px 30px;
}

.product-list {
   background-color: $cool-light-grey;
   .product-list__total {
    height: 28px;
    color: $text-dark-grey;
    font-family: $font-regular-base;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    padding: 0;
    margin: 0;

    @media only screen and (max-width: 1024px) {
      height: 24px;
      line-height: 24px;
    }
  }

  .product-list__filter {
    @media only screen and (max-width: 860px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 1023px){
      width: 100%;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: $white;
      padding: 0 2pc;
      display: none;
    }
    &--modal{
      @media only screen and (max-width: 860px) and (orientation: landscape), only screen and (min-width: 768px) and (max-width: 1023px){
        display:block;
      }
    }
    @media only screen and (min-width: 1024px) and (max-width: 1600px) {
      width: 239px;
    }
    @include breakpoint(tab-max){
      z-index: 9999;
    }
    .filter .Collapsible.is-open .Collapsible__contentInner{
      @include breakpoint(tab-max){
        padding-bottom: .625rem;
      }
    }
    .Collapsible__trigger {
      color: $text-dark-grey;
      font-family: $font-regular-base;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-top: 19.61px;
      margin-bottom: 19.61px;
      padding: 0;
      text-transform: uppercase;
      @include breakpoint(tab-max){
        @include ann-describtion(14px, $lato-regular, 16px);
        margin: 15px 0;
      }
      span{
        width: 90%;
        word-wrap: break-word;
        @include breakpoint(tab-max){
          @include ann-describtion(14px, $lato-regular, 16px);
          letter-spacing: 2px;
        }
      }
      div.checkbox, .checkbox_default{
        font-size: 14px;
        @include breakpoint(tab-max){
          @include ann-describtion(14px, $lato-regular, 15px);
          letter-spacing: 0.23px;
          color: $text-medium-dark-grey;
        }
        @include breakpoint(desktop-large) {
          font-size: 14px;
          letter-spacing: 0.23px;
          line-height: 18px;
        }
        @include breakpoint (desktop-small) {
          line-height: 18px;
        }
      }
      .checkbox input:checked~span{
        color: $text-dark-grey;
       @include breakpoint(tab-max){
        letter-spacing: 0.23px;
        font-size: 14px;
       }
      }
      .checkbox__marker{
        @include breakpoint(tab-max){
          width: 15px;
          height: 15px;
          color: $cool-dark-grey;
        }
      }
       div{
        .plus{
          width: 15.16px;
          height: 15.16px;
          @include breakpoint(desktop-large) {
            width: 10px;
            height: 10px;
          }
          .line{
            color: $text-medium-dark-grey;
            background-color: $text-medium-dark-grey;
          }
          .line.line-1{
            width: 1px;
            height: 100%;
          }
          .line.line-2{
            transform: translate(-7.5px,-1px);
            width: 100%;
            height: 2px!important;
            @include breakpoint (desktop-extra-sm){
              transform: translate(-8px,0px);
            }
            @include breakpoint (desktop-large) {
              transform: translateX(-4.8px);
              height: 2px;
            }
          }
        }
      }
      &.is-open{
        div{
          .plus{
            .line{
              &.line-1{
                height: 0px;
              }
            }
          }}
      }
    }
    @media only screen and (min-width: 1024px){
      .Collapsible__trigger {
        font-size: 14px;
        letter-spacing: 2px;
        text-align: left;
        margin-top: 15px;
        margin-bottom: 14px;
        padding: 0;
        text-transform: uppercase;
        
      }  
    }
    @media only screen and (min-width: 1920px){
      .Collapsible__trigger {
        font-size: 14px;
        letter-spacing: 2px;
        text-align: left;
        margin-top: 14.5px;
        margin-bottom: 14.5px;
        padding: 0;
        text-transform: uppercase;
        
      }  
    }

    

    .filter__show-more {
      height: 20px;
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 13px;
      letter-spacing: 0.2px;
      line-height: 20px;
      text-decoration: underline;
    }

    .filter__print-and-share {
      padding-top: 45px;
      span:nth-child(1){
        height: 20px;
        margin-right: 20px;
      }
      span:nth-child(2){
        height: 20px;
      }
      img{
        margin-right: 8px;
      }
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .filter__buying-guide {
      padding: 50px 0 25px 0;

      h3,
      a {
        font-family: $lato-regular;
      }
    }
    .filter {
      @include breakpoint(tab-max){
        padding-right: 0;
        padding-left: 0;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1600px) {
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          display: block;
        }
      }
    }
    .filter__inner-container {
      @include breakpoint(tab) {
        height: auto;
        overflow: auto;
        padding-bottom: 85px;
      }
      @include breakpoint(mobile){
        margin-bottom: 10px;
        padding-bottom: 120px;
      }
      @include breakpoint(mobile){
        .collapsible-outer:first-child{        
          margin-top: 20px;
        }
      }
      @include breakpoint(tab-max){
        .collapsible-outer:first-child{        
          margin-top: 25px;
        }
      }
      @include breakpoint(desktop-small){
        .collapsible-outer:first-child{        
          margin-top: 0px;
        }
      }
    }
  }
  
  .product-list__tile-col {
    .row.row-cols-3{
      @include breakpoint(desktop-small){
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media only screen and (min-width: 1024px) and (max-width: 1600px) {
      margin-left: 253px;
    }
    @media only screen and (min-width: 1920px){
      .row.row-cols-3{
        grid-template-columns: repeat(3, 1fr);
        column-gap: 27.9px!important;
        margin-right: -0.1pc!important;
        .product-list__tile{
          padding-right: 0!important;
          max-width: 100%;
          padding-right: 27.9px;
        }
        
      }
    }
    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      .row.row-cols-3{
      .promo-col{
          height: 465px;
        
    }
  }
  }
    @media only screen and (min-width: 1920px) {
      .row.row-cols-3{
      .promo-col{
          height: 648px;
        
    }
  }
  }



    .products-per-page {
      height: 16px;
      color: $text-dark-grey;
      font-family: $font-regular-base;
      font-size: 10px;
      letter-spacing: 1.7px;
      line-height: 16px;
      ul li{
        font-family: $lato-regular;
        font-size: 10px;
        letter-spacing: 2px;
        line-height: 1pc;
        margin-right: 15px;
      }

      .disabled {
        color: $text-medium-dark-grey;
      }
    }

    .product-list__go-back {
      height: 24px;
      color: $text-medium-dark-grey;
      font-family: $font-regular-base;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      flex-direction: row;
      @include breakpoint (tab-max) {
        font-size: 16px;
        letter-spacing: normal;
        line-height: 24px;
      }

      img {
        height: 18.95px;
        width: 9.9px;
        margin-top: 2px;
        margin-left: 0;
      }

      &-text {
        order: 2;
        margin-left: 15px!important;
      }

      &__go-back-image {
        order: 1;
      }
    }
  }
  .product-list__tile-col.product-list__tile-col--full-width{
    margin-left: 0
  }

  .product-listing__controls {
    height: 30px;
    background-color: $cool-light-grey;
    @include breakpoint(mobile){
      height: 20px;
    }
    .product-list__filter-toggle {
      height: 20px;
      color: $text-dark-grey;
      font-family: $font-regular-base;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      &-icon{
        height: 13.19px;
        color: $text-medium-dark-grey;
      }
    }

    .sorting-section {
      margin-right: 0;
    }

    .sort {
      box-sizing: border-box;
      border-radius: 3px;

      .sort__toggle {
        height: 30px;
        color: $text-dark-grey;
        font-family: $font-regular-base;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin: 15.75px 0px 15.42px 20px;
      }
    }
  }
  .product-list__controls {
    height: 30px;
    &.filter {
      &__mobile-header {
        @include breakpoint(tab-max){
          display: flex!important;
        }
        @include breakpoint(desktop-small){
          display: none !important;
        }
        margin: 18px 0 28px;
        @include breakpoint(tab) {
          margin: 18px 0 28px;
        }
      }
    }
    
    .product-list__filter-toggle {
      height: 20px;
      color: $text-dark-grey;
      font-family: $font-regular-base;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      @include breakpoint(tab) {
        height: 24px;
        gap: 10px;
      }
      &-icon{
        height: 13.19px;
        color: $slate;
        @include breakpoint(tab-max){
          display: none;
        }
      }
    }

    .sorting-section {
      margin-right: 0;
      .sort.sort--open{
        .sort__drop-down{
          li{
            width: 140px;
            height: 24px;
            width: 140px;
            color: $text-light-grey;
            font-size: 16px;
            letter-spacing: normal;
            line-height: 24px;
          }
        }
      }
    }

    .sort {
      box-sizing: border-box;
      border-radius: 3px;

      .sort__toggle {
        height: 30px;
        color: $text-dark-grey;
        font-family: $font-regular-base;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin: 15.75px 0px 15.42px 20px;
      }
    }
  } 
  
  .product-list__tile{
    .product-tile__new{
      color: $color-white;
      background-color: $color-light-blue;
      @include breakpoint(mobile){
        background-color: $color-white;
        color: $color-light-blue;
      }
    }
  }


 
  &__sticky-nav-wrap {
    z-index: 2;
  }

  &__header {
    margin: 25px 0 20.5px;
  }

  &__load-more {
    margin: 0px 0 40px 0;
    background-color: $cool-light-grey;
    font-size: 12px;
    font-family: $lato-bold;
    letter-spacing: 2.5px;
    color: $color-medium-blue;
    border-color: $color-light-blue;
  }

  &__tiles{
    margin-top: 0px;
  }

  .product-list__breadcrumb {
    padding-top: 28px;
    padding-bottom: 25px;

    .product-list {
      &__breadcrumb-link {
        cursor: pointer;
        font-family: $helvetica-regular;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.3px;
        color: $slate;
        text-decoration: none;

        &:hover {
          color: $slate;
        }
      }
    }
  }

  .product-list__sticky-nav-wrap {
    position: inherit;
  }
  .product-listing__sticky-nav-wrap {

    @include breakpoint(tab){
     min-height: 0;
     padding: 40px 32px 30px 26px
    }
    @include breakpoint(mobile){
      min-height: 0;
      padding: 20px 18px 15px;     
     }
    .container-fluid.product-listing__sticky-nav.false{
      @include breakpoint(tab){
      padding: 0px;}
      @include breakpoint(mobile){padding: 0px;}
      .product-listing__row{
        .product-listing__col.product-listing__controls{

          @include breakpoint(mobile){
            justify-content: space-between;
          }
          .product-listing__total{
            @include breakpoint(tab-max){
              padding:0;
              margin:0;
            }
            @include breakpoint(mobile){
              width: 174px !important;
            }
          }
      .product-listing__fillter_sort{
        @include breakpoint(tab){
          margin: 0px;
        }
      }
      .product-listing__filter-toggle-icon{
        @include breakpoint(tab){
          margin: 8px 0px 8.81px 10px;
        }
      }
      .product-listing__grid-control{
        .product-listing__grid-control-icon{
          @include breakpoint(mobile){
            padding: 0px;
            margin-left: 20px;
            height: 17px;
            width: 17px;
            color: $slate;
          }
        }
      }
    }}}
    .container-fluid.product-listing__sticky-nav.product-listing__sticky-nav--shadow{
      @include breakpoint(mobile){
      padding-top: 10px;
      padding-bottom: 12px;}
      @include breakpoint(tab){
        padding-top: 10px;
        padding-bottom: 12px;
      }
      @include breakpoint(desktop-large){
        flex-direction: column;
      }
      .product-listing__row{   
        .container.kf-react-plp-container{
          .product-listing__row{
            @include breakpoint(desktop-large){
              max-width: 1920px;
              margin: 0 auto;
              padding:0 50px;
            }      
          }
        }
        .product-listing__col.product-listing__controls{
          @include breakpoint(mobile){
            height: 28px;
          }
          @include breakpoint(tab){
            height: 28px;
          }
        }
      }
    }
    .sticky.nav-down{
      @include breakpoint(tab){
        left: 0px;
        right: 0px;
      }
      @include breakpoint(mobile){
        left: 0px;
        right: 0px;
      }
    }
    .sticky.nav-up{
      @include breakpoint(tab){
        top:70px !important;
        left: 0px;
        right: 0px;
      }
      @include breakpoint(mobile){
        top:70px !important;
        left: 0px;
        right: 0px;
      }
    }
  }
  .product-list__title{
    margin: 0;
    &-info {
      padding: 0;
      height: unset;
    }
  }
  .product-list-tile{
    &__compare-main{
      @include breakpoint(mobile){
        margin: 0;
      }
    }
    &__compare{
      @include breakpoint(mobile){
        display: none;
      }
    }
  }
  .product-list__tile {
    @include breakpoint(mobile) {
      margin-bottom: 60.98px;
    }
  }
  @media screen and (width:1024px) {
    .product-tile__share{
      opacity: 1;
    }
  }
  input:checked~span .checkbox__marker{
    background-color: $color-medium-blue !important;
  }
}

@include breakpoint(mobile) {
  .product-list {
    .product-list__filter {
      z-index: 1000;
      padding: 0 18px;
      .Collapsible__trigger {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .filter {
    .Collapsible__trigger {
      padding: 0px;
    }

    &__mobile-header {
      margin: 18px 0 28px;
      display: flex;
      @include breakpoint(tab){
        margin:18px 0 28px;
      }
      @include breakpoint(tab-max){
        margin:18px 0 28px;
      }
    }
    .product-list__tile{
      .kf-promo-banner{
        @include breakpoint(desktop-small) {
          max-width: 344px;
          max-height: 448px;
        }
        @include breakpoint(desktop-xlg){
          max-width: 497px;
          max-height: 648px;
        }
        @include breakpoint(tab){
          width: 364px;
          height: 474px;
        }
      }
    }
  }

  .filter__footer button {
    margin-left: 0px;
  }

  .product-list__filter {
    padding: 0 18px 0 18px;
  }
}

@include breakpoint(tab-max) {
  .product-list {
    .product-list__filter {
      .Collapsible__trigger {
        margin-top: 15px;
        margin-bottom: 15px;
        &:focus-visible{
          outline: none;
        }
      }
    }
    &__load-more.gbh-data-layer {
      padding: 0;
      height: 42px;
      margin-left: 14px;
      margin-right: 15px;
      margin-top: 20px;
    }
  }

  .filter {
    .Collapsible__trigger {
      padding: 0px;
    }

    &__mobile-header {
      margin: 16px 0 28px;
      display: flex;
      @include breakpoint(tab) {
        margin: 18px 0 28px;
      }
    }
  }

  .filter__footer button {
    margin-left: 0px;
  }

  .product-list__filter {
    padding: 0 18px 0 18px;
  }
}

.product-list__filter {
  @include breakpoint(tab) {
    height: 100%;
  }

  @include breakpoint(mobile) {
    height: 100%;
  }

  .filter__apply {
    color: $white-cap;
    @include text-style(12px, 24px, 2.5px);
    font-family: $lato-bold;
    font-weight: normal;
    text-align: center;
    border-radius: 1px;
    background-color: $color-medium-blue-cap;
    text-transform: uppercase;
    height: 50px;
    border: none;
    box-shadow: none;
    @include breakpoint(tab-max){
      text-transform: uppercase;
    }
  }

  @-moz-document url-prefix('') {
    .filter__apply {
      outline-style: solid;
    }
  }

  .filter__apply--active {
    background-color: $color-medium-blue-cap;
  }

  input:checked ~ span .checkbox__marker {
    border: 1px solid $color-light-blue;
    background-color: $color-medium-blue;
  }
}

.product-list__filter--sticky {
  @include breakpoint(tab-max) {
    height: 100%;
    top: 0;
  }
}

.product-list__tile:not(.col-12):nth-child(odd) {
  .product-tile__wrapper {
    .social-share {
      @include breakpoint(mobile) {
        right: unset;

        &__box {
          right: unset;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1600px) {
  .search-result__tab-content .products-tab {
    .product-list__sticky-nav-wrap {
      margin: auto;
      padding-bottom: 0;
    }

    .product-list__sticky-nav .col,
    .product-list__tiles .col {
      padding: 0;
    }
  }

}

.product-list .product-list__controls .sorting-section .products-per-page {
  display: none;
}

.product-list .kf-react-container .row .col {
  .product-list__tile-col {
    .product-tile__wrapper__card {
      margin-top: 0;
    }
    &--full-width {
      padding-left: 0px;
      .row.row-cols-3 {
        .product-tile__image {
          width: 100%;
          padding-bottom: 0;
        }
      }
      @include breakpoint(mobile) {
        .product-tile__share-icon-plp {
          display: none;
        }
      }
      @include breakpoint(mobile) {
        .row.row-cols-2 {
          .product-tile {
            &__image {
              width: 100%;
            }
            overflow: hidden;
          }
        }
      }
      .row.row-cols-1 {
        .product-tile {
          &__image {
            height: 441px;
          }
          overflow: hidden;
        }
      }
    }
    .product-list__tile {
      @include breakpoint(tab) {
        padding: 0 68px 0 0;
      }

      @include breakpoint(mobile) {
        padding: 0;
        max-width: 100%;
      }
    }
  }
}

.product-list .product-tile {
  @include breakpoint(mobile) {
    left: 20px !important;
  }
}
.product-list .product-tile__share {
  @include breakpoint(tab) {
    right: 60px;
  }
}

.search-result {
  .product-list__container {
    .product-list__tile-col {
      &--full-width {
        margin: 0 15px;
        @include breakpoint(tab) {
          .product-tile {
            width: 340px;
          }
        }
        .row.row-cols-3 {
          .product-list__tile {
            overflow-y: hidden;
            padding: 0 2.5px;
          }
          .product-tile__image {
            width: 100%;
          }
        }
      }
      .row-cols-3 {
        .product-list__tile {
          overflow: hidden;
          padding-left: 0px;
        }
      }
      .product-tile__compare {
        margin-right: 30px;
      }
      @include breakpoint(mobile) {
        .product-tile {
          width: auto;
          margin-right: 10px;
        }

        .product-tile__share {
          top: 40px;
        }
      }

      @include breakpoint(tab) {
        .product-tile__share {
          top: 30px;
        }
      }
    }
  }
  .product-listing__sticky-nav-wrap {
    z-index: 2!important;
    @media(max-width:812px){
        min-height: 0px !important;
        margin: 30px 0px 16px 0px;
    }
    .container-fluid.product-listing__sticky-nav.false{
    @include breakpoint(mobile){
      min-height: 0px !important;
        margin: 30px 0px 17px 0px;
    }
    @include breakpoint(tab-max){
        background-color: $cool-light-grey !important;
    }
    .product-listing__fillter_sort{
      @include breakpoint(mobile){
        margin: 0px;
      }
    }
    .product-listing__filter-toggle-icon{
      @include breakpoint(mobile){
        margin: 8px 0px 6.81px 0px;
      }
      @include breakpoint(tab){
        margin: 8px 0px 6.81px 0px;
      }
    }
    .product-listing__grid-control{
      .product-listing__grid-control-icon{
        @include breakpoint(mobile){
          padding: 0px;
          margin-left: 20px;
          height: 17px;
          width: 17px;
          color: $slate;
        }
      }
    }

    .search-result__sticky-search-pd.product-listing__col.product-listing__controls{
      .product-listing__total__non-sticky {
        @include breakpoint(mobile){
          min-width: 175px !important;
        }
        
    }
      .sorting-section{
        @include breakpoint(mobile){
          justify-content: unset;
        }
      }
    } 
  }
  .product-listing .sticky .product-listing__sticky-nav {
    display: block;
  } 
  }
}

@media (min-width: 561px) and (max-width: 1023px) {
  a.kf-react-button.button.small.load-more.product-list__load-more {
    height: 42px;
    padding: 0;
  }
}
.product-list {
  .product-tile__share {
    padding-top: 5px;
    padding-right: 20px;
  }
  .product-tile__badge {
    padding-top: 5px;
    left: 45px;
  }
}

.product-list
  .product-list__tile-col
  .product-tile__wrapper
  .product-tile__wrapper__card
  .product-tile
  > a
  > .product-tile__details {
  @include breakpoint(mobile) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    word-break: break-word;
  }

  .product-tile__price,
  .product-tile__discount-price {
    @include breakpoint(mobile) {
      padding-top: 0;
      padding-right: 0;
    }
  }
}
.product-list
  .product-list__tile-col
  .product-tile__wrapper
  .product-tile__wrapper__card
  .product-tile--lite
  > a
  > .product-tile__details {
  @include breakpoint(mobile) {
    display: block;
  }
}
.product-list__filter, .product-listing__filter {
  .social-share {
    position: relative;
    left: -15px;
    transform: scale(0.8);
    width: fit-content;
  }
}

.product-listing .filter .filter__inner-container .Collapsible .Collapsible__trigger,
.search-result .product-listing  .filter .Collapsible__trigger {
  font-family: $lato-regular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  text-align: left;
  color: $text-dark-grey;
  margin-top: 19.61px;
  margin-bottom: 19.61px;
  padding: 0;
  text-transform: uppercase;
}

.product-listing .sort__toggle,
.search-result .sort__toggle {
  font-family: $lato-regular;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: normal;
  color: $text-dark-grey;
}

.product-listing .filter__print-and-share,
.search-result .filter__print-and-share {
  font-family: $lato-regular;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  letter-spacing: normal;
  color: $text-medium-dark-grey;
}

.product-listing .filter__print-and-share span,
.search-result .filter__print-and-share span {
  margin-right: 20px;
  @include ann-describtion(.875rem, $lato-regular, 1rem);
  color: $text-medium-dark-grey;
}

.product-listing .filter__print-and-share span img,
.search-result .filter__print-and-share span img {
  margin-right: 8px;
}
.search-result .product-listing .product-listing__sticky-nav.false {
  @media (max-width:812px) {
    padding: 0px !important;
    
  }
}

.product-listing__sticky-nav {
  @media (max-width: 812px){
    height: auto !important;
    background: $cool-light-grey;
  }
}
.product-listing .sticky .product-listing__sticky-nav{
  @media (max-width: 812px){
    height: auto !important;
    background: $color-white;
  }
}

.search-side-panel {
  &__delete-keyword {
    margin: 0 9.65px;
    img {
      max-width: 8.06px;
      max-height: 7.71px;
    }
  }
}
.product-listing__filter--modal{
  .filter__filter-list{
    .Collapsible__contentInner{
      .checkbox_default{
        font-family: $lato-regular;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: .23px;
      }
      .checkbox__border{
        border-radius: 0;
      }
      .checkbox__marker{
        width: 15px;
        height: 15px;
      }
    }
  }
  .product-list__tile{
    .product-list-tile__right-controls{
      .checkbox__border, input:checked~span .checkbox__marker{
        border-radius: 5px;
      }
    }
  }
}
.checkbox{
  font-size: 14px;
  .checkbox_default{
    line-height: 24px;
    letter-spacing: 0.23px;
    color: $text-dark-grey;
    @include breakpoint(tab-max){
      font-family: $lato-regular;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 0.23px;
          color: $text-dark-grey;
    }
    @include breakpoint (desktop-large) {
      font-size: 14px;
      letter-spacing: 0.23px;
      line-height: 18px !important;
    }
    @include breakpoint (desktop-small) {
      line-height: 15px;
    }
  }
  input:checked~span{
    color: $text-dark-grey;
   @include breakpoint(tab-max){
    letter-spacing: 0.23px;
    font-size: 14px;
   }
  }
}

@media screen and (min-width: 1024px){
  .product-list__filter.product-list__filter--modal{
    width: 214px;
    z-index: auto;
    padding: 0;
  }
}
  @include breakpoint(desktop-mid){
  .product-list__filter.product-list__filter--modal{
    .product-listing__filter{
      width: 100%;
      .product-listing.filter{
        padding-right: 0;
        .filter__inner-container{
          .filter__filter-list{
            .collapsible-outer{
              .Collapsible{
                .Collapsible__trigger {
                  margin-top: 16px;
                  margin-bottom: 14px; 
                
                  span div {
                    span{
                      height: 16px;
                      max-width: 112px;
                      font-size: 14px;
                      line-height: 16px;
                      letter-spacing: 2;
                      text-align: left;
                      color: $text-dark-grey;
                    }
                    .plus{
                      width: 15.16px;
                      height: 15.16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
  }
}

@media only screen and (min-width: 375px){
  .container-fluid.false{
    padding-left: 0;
    padding-right: 1px;
  }
  .filter{
    .filter__inner-container{
      .collapsible-outer{
        &:first-child{
          .Collapsible{
            border-top: 1px solid $cool-dark-grey;
          }
        }
        .Collapsible{
          border-bottom: 1px solid $cool-dark-grey;
        }
      }
      width: 100%;
    }
    .filter__buying-guide:empty{
      display: none!important;
    }
  }
}

.product-listing {
  &__go-back img {
    @include breakpoint(tab){
     height: 18.95px;
     width: 9.9px;
     font-size: 16px;
     line-height: 24px;
    }
    @include breakpoint(mobile){
     height: 18.95px;
     width: 9.9px;
    }
   }
  &__close-icon {
        @include breakpoint(tab) {
            width: 21px;
            height: 21px;
        }
    }
  &__filter-toggle {
    font-family: $lato-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: $text-dark-grey;
  }
  .product-list-tile__colors--swatch{
    z-index: auto;
  }
}
.product-list{
  .container-fluid.product-listing__sticky-nav{
    padding-left: 0;
    padding-right: 0;
    background-color: $cool-light-grey;
    @include breakpoint(tab){
      padding: 2.5rem 0 1.875rem;
    }
    @include breakpoint(mobile){
      padding: .9375rem 0;
    }

    .container-fluid.product-listing__sticky-nav{
      .container.kf-react-plp-container{
        margin: 0;
        .sorting-section{
          margin: 0;
        }
      }
    }
  }
  .container-fluid.product-listing__sticky-nav{
    .container.kf-react-plp-container{
      .product-listing__row{
        @include breakpoint(desktop-small){
          padding:0 2.6015%;
        }
        @include breakpoint(desktop-large){
          margin: 0 auto;
          max-width: 1920px;
          padding:0 50px;
        }
        @include breakpoint(desktop-mid){
          padding:0;
        }
      }
      margin: 0;
      .sorting-section{
        margin: 0;
      }
    }
  }
  @include breakpoint(mobile){
    .container-fluid.product-listing__sticky-nav{
      &.product-listing__sticky-nav--shadow{
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .product-listing__row{
        width: calc(100vw - 9.6%);
        margin: auto;
      }
      .container.kf-react-plp-container{
        margin: 0;
        .product-listing__row{
          margin: 0;
        }
        .product-listing__total{
            height: 28px;
            width: 154px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 28px;
          } 
        .sorting-section{
          margin: 0;
          .product-listing__fillter_sort{
            @include breakpoint(tab-max){
              @include ann-describtion(1.125rem,$lato-regular,1.75rem);
              letter-spacing: 0;
              color: $text-dark-grey;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  @include breakpoint(tab){
    .container-fluid.product-listing__sticky-nav{
      &.product-listing__sticky-nav--shadow{
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .product-listing__row{
        width: calc(100vw - 7.882%);
        margin: auto;
      }
      .container.kf-react-plp-container{
        margin: 0;
        .product-listing__row{
          margin: 0;
        }
        .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
            @include breakpoint (tab-max) {
              width: 154px;
            }
          } 
        .sorting-section{
          margin: 0;
        }
      }
    }
  }


  @include breakpoint(desktop-mid){
    .container-fluid.product-listing__sticky-nav{
      &.product-listing__sticky-nav--shadow{
        padding-top: 17px;
        padding-bottom: 16px;
      }
      .container.kf-react-plp-container{
        .product-listing__row{
          width: calc(100vw - 5.2%);
          margin: auto;
        }
        .product-listing__controls{
          .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            @include breakpoint (tab) {
              margin-left: -20px;
            }
            @include ann-describtion(22px, $lato-regular, 32px);
            letter-spacing: 0;
          } 
          .sorting-section{
            .sort{
              margin-left: 30px;
              .sort__toggle{
                margin: 0;
                height: 28px;
                min-width: 139px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: normal;
                line-height: 28px;
              }
            }
            .sort.sort--open{
              .sort__drop-down{
                .sort__item.active{
                  background-color: $cool-light-grey !important;
                }
              }
            }
          } 
        }
      }
    }
  }
  @include breakpoint(desktop-xxxlg){
    .container-fluid.product-listing__sticky-nav{
      &.product-listing__sticky-nav--shadow{
        padding-top: 17px;
        padding-bottom: 16px;
      }
      .container.kf-react-plp-container{
        margin: 0;

        .product-listing__controls{
          .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
          } 
          .sorting-section{
            .sort{
              margin-left: 30px;
              .sort__toggle{
                margin: 0;
                height: 28px;
                min-width: 139px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: normal;
                line-height: 28px;
              }
            }
            .sort.sort--open{
              .sort__drop-down{
                .sort__item.active{
                  background-color: $cool-light-grey;
                }
              }
            }
          } 
        }
      }
    }
  }
  @media only screen and (min-width: 1920px){
    .container-fluid.product-listing__sticky-nav{
      &.product-listing__sticky-nav--shadow{
        padding-top: 17px;
        padding-bottom: 16px;
      }
      .container.kf-react-plp-container{
        margin: 0;
        .product-listing__controls{
          .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
          } 
          .sorting-section{
            .sort{
              margin-left: 30px;
              .sort__toggle{
                margin: 0;
                height: 28px;
                min-width: 139px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: normal;
                line-height: 28px;
              }
            }
            .sort.sort--open{
              .sort__drop-down{
                padding-top: 0;
                padding-bottom: 0;
                .sort__item{
                  font-size: 16px;
                  color: $text-light-grey-cap;
                  font-family: $lato-regular;
                  line-height: 24px;
                  letter-spacing: normal;
                  min-width: 11.875rem;
                      &.active{
                        background-color: $cool-light-grey;
                      }
                      &:hover{
                        background-color: $cool-light-grey;
                      }
                }
                
              }
            }
          } 
        }
      }
    }
  }
}
.product-list{
  &__filter-toggle-text {
    @media only screen and (min-width: 1024px){
      font-size: 18px;
      line-height: 28px;
      height: 28px;
      font-family: $lato-regular;
      color: $text-dark-grey;
    }
    li{
      @include breakpoint(tab-max){
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .product-list__tile { 
    padding-right: 0;
    max-width: 100%;
    margin-bottom: 40px;
    @include breakpoint (mobile) {
      margin-bottom: 10px;
    }
    .product-tile{
      &__badge{
        position: absolute;
        left: 20px;
        top: 20px;
        padding-top: 0;
        border: 1px solid $tag-border-color;
      }
      &__share{
        position: absolute;
        top: 10px;
        right: 10px;
      }
      &__image{
        margin-bottom: 10px;
        padding-bottom: 0;
      }
      &__title{
        font-size: 18px;
        letter-spacing: normal;
        line-height: 28px;
        @include breakpoint(mobile){
          font-size: 1rem;
          margin-bottom: .625rem;
        }
      }
      &__description{
        color: $text-medium-dark-grey;
        font-size: 16px;
        letter-spacing: normal;
        line-height: 24px;
        max-width: 250px;
        height: auto;
      }
      .product-list-tile {
        &__title, &__description, &__product-sku, &__price, &__discount-price, &__colors {
          font-family: $lato-regular;
        }
        &__title{
          @include breakpoint(tab-max){
            margin-bottom: 0;
            font-size: 16px;
          }
          @media (min-width: 812px) and (max-width: 1023px) {
            font-size: 18px;
          }
        }
        &__description{
          @include breakpoint(mobile){
            @include ann-describtion(16px, $lato-regular, 20px);
            margin-top: 5px;
          }
        }
        &__compare{
          .checkbox__border{
            margin-right: 8px;
          }
        }
      }
      &__new, &__offer{
        color: $color-light-blue;
        font-family: $lato-bold;
        font-size: 9px;
        font-weight: normal;
        letter-spacing: 1.64px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        background-color: unset !important;
      }
    }
    .checkbox_default{
      color: $text-medium-dark-grey;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: normal;
      text-align: left;
      font-family: $lato-regular;
      @include breakpoint (desktop-large) {
        font-size: 14px;
        letter-spacing: 0.23px;
      }
    }
  }

  @include breakpoint(mobile){
    .kf-react-container{
      padding: 0!important;
    }
    .container.kf-react-container.product-list__tiles{
      width: calc(100vw - 9.6%)!important;
      margin: auto;
      .row{
        margin: 0;
        @include breakpoint(mobile){
        .row-cols-2{
          font-size: 16px;
            .product-list .product-list-tile__title{
              margin-top: 0px;
            }
            .product-list-tile__description{
              margin-top: 10px;
            }
          }
        }
        .col{
          padding-left: 0;
          padding-right: 0
        }
      }
    }
    .container-fluid.product-list__sticky-nav.false{
      width: calc(100vw - 9.6%)!important;
      margin: auto;
      @include breakpoint(mobile){
        margin-top: -10px;
      }
      .row {
        margin-left: 0;
        margin-right: 0;
        .container.kf-react-container .row{
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
    .container-fluid.product-list__sticky-nav{
      height: 32px;
      padding: 0;
      
      .container.kf-react-container{
        .row{
          max-width: 339px !important; 
          margin: 0 2.6% !important;
          .col{
            padding-right: 0;
            padding-left: 0;
            .product-list__controls{
              .container.kf-react-container.product-list__header{
                margin: 40px 0 30px 0;
                .row{
                  height: 32px;
                }
              }
              .product-list__filter-toggle{
                flex-direction: row-reverse;
                width: 18%;
                height: 32px;
                .product-list__filter-toggle-icon{
                  height: 13.19px;
                  color: $slate;
                  margin-left: 9px;
                }
                .product-list__filter-toggle-text{
                  width: 92px;
                  height: 28px;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: normal;
                  text-align: left;
                }
              }
              .sorting-section{
                min-width: 126px;
                margin-right: -20px;
                .sort{
                  margin-left: 30px;
                  .sort__toggle{
                    height: 28px;
                    min-width: 139px;
                    color: $text-dark-grey;
                    font-family: $lato-regular;
                    font-size: 18px;
                    letter-spacing: normal;
                    line-height: 28px;
                    margin: -12px;
                  }
                }
              }  
            }
          }
        }
      }
    }
    .product-list__tile-col{
      margin: 0;
      .row{
        display: flex;
        justify-content:space-between;
        &.row-cols-1{
          .product-list__tile, .product-list__tile.col-12{
              flex:0 0 100%;
              max-width:100%;
          }
        }
      }
      .row.row-cols-2{
        margin-right: 0;
        .product-list__tile{
          padding-right: 0;
          max-width: 48%;
          flex:0 0 48%;
          margin-bottom: 40px;
          @include breakpoint (mobile) {
            margin-bottom: 10px; 
          }
          .product-tile{
            &__badge{
              position: absolute;
              left: 20px;
              top: 20px;
            }
            &__image{
              margin-bottom: 10px; 
            }
            &__title{
              font-size: 18px;
              letter-spacing: normal;
              line-height: 28px;
            }
            &__description{
              color: $text-medium-dark-grey;
              font-size: 16px;
              letter-spacing: normal;
              line-height: 24px;
              max-width: 250px;
              height: auto;
            }
          }
          .checkbox_default{
            color: $text-medium-dark-grey;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: normal;
            text-align: left;
            font-family: $lato-regular;
          }
          &.col-12{
            flex: 100%;
            max-width:100%;
            .kf-promo-banner.\--fixed-height{
              min-height:441px;
              @include breakpoint(desktop-mid){
                height:inherit;
              }
              @include breakpoint(desktop-extra-lite-sm){
                height:inherit;
              }
            }
          }
        }
      }
      .row:nth-child(2){
        a{
          height: 42px;
          padding: 15px;
          letter-spacing: 2.5px;
          align-items: center;
          text-transform: uppercase;
        }
      }

      .row{
        .product-list{
          &__load-more{
            @include breakpoint(tab-max){
              margin: 30px 0px 33.5px;
            }
            @include breakpoint(mobile){
              font-family: $lato-bold;
              font-size: 11px;
              line-height: 10px;
              letter-spacing: 2.5px;
              text-align: center;
              color: $color-medium-blue;
              border: 1px solid $color-medium-blue;
              border-radius: 1px;
            }
          }
        }
      }
    }
  }
  @include breakpoint(tab){
    .kf-react-container{
      padding: 0!important;
    }
    .container.kf-react-container.product-list__tiles{
      width: calc(100vw - 7.882%)!important;
      margin: auto;
      .row{
        margin: 0;
        .col{
          padding-left: 0;
          padding-right: 0
        }
      }
    }
    .container-fluid.product-list__sticky-nav.false{
      width: calc(100vw - 7.882%)!important;
      margin: auto; 
      .row {
        margin-left: 0;
        margin-right: 0;
        .container.kf-react-container .row{
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
    .container-fluid.product-list__sticky-nav{
      height: 32px;
      padding: 0;
      
      .container.kf-react-container{
        .row{
          width: calc(100vw - 7.882%)!important;
          margin: 0 2.6% !important; 
          .col{
            padding-right: 0;
            padding-left: 0;
          }
        }
        .product-listing__controls{
          .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
          } 
          .product-listing__filter-toggle{
            flex-direction: row-reverse;
            width: 18%;
            height: 32px;
            .product-listing__filter-toggle-icon{
              height: 13.19px;
              color: $slate;
              margin-left: 9px;
            }
            .product-list__filter-toggle-text{
              width: 92px;
              height: 28px;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: normal;
              text-align: left;
              @include breakpoint (tab) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
          .sorting-section{
            min-width: 126px;
            margin-right: -20px; 
            .sort{
              margin-left: 30px;
              .sort__toggle{
                height: 28px;
                min-width: 139px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: normal;
                line-height: 28px;
                margin: -12px;
              }
            }
          } 
        }
      }
    }
    .product-list__tile-col{
      margin: 0;
      .row{
        display: grid;
      }
      .row.row-cols-2{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
        column-gap: 20px;
        margin-right: 0;
        .product-list__tile{
          padding-right: 0;
          max-width: 100%;
          margin-bottom: 40px; 
          .procut-tile{
            &__image{
              margin-bottom: 10px; 
            }
            &__title{
              font-size: 18px;
              letter-spacing: normal;
              line-height: 28px;
            }
            &__description{
              color: $text-medium-dark-grey;
              font-size: 16px;
              letter-spacing: normal;
              line-height: 24px;
              max-width: 250px;
              height: auto;
            }
          }
          .checkbox_default{
            color: $text-medium-dark-grey;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: normal;
            text-align: left;
            font-family: $lato-regular;
          }

        }
        .product-single{
          @include breakpoint (tab) {
            max-width: 50%;
        }
      }
      }
      .row:nth-child(2){
        a{
          height: 42px;
          padding: 15px;
          letter-spacing: 2.5px;
          align-items: center;
          text-transform: uppercase;
        }
      }
      .row{
        .product-list{
          &__load-more{
            @include breakpoint(tab){
              font-family: $lato-bold;
              font-size: 11px;
              line-height: 10px;
              letter-spacing: 2.5px;
              text-align: center;
              color: $color-medium-blue;
              border: 1px solid $color-medium-blue;
              border-radius: 1px;
              margin: 0px 0px 30px 0px;
            }
          }
        }
        
      }

    }
  }
  @include breakpoint(desktop-mid){
    .kf-react-container{
      padding: 0!important;
    }
    .product-list__tile-col.product-list__tile-col--full-width{
      .row.row-cols-2{
        .product-list__tile{
          .product-tile{
            &__image{
              margin-bottom: 10px;
              height: 614px;
            }
          }
        }
        .promo-col{
          height:614px;
        }
      }
    }
    .container.kf-react-container.product-list__tiles{
      margin-bottom: 0;
      width: calc(100vw - 5.2%) !important;
      .row{
        margin: 0;
        .col{
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 60px;
        }
      }
    }
    .container-fluid{
      padding-left: 0;
      padding-right: 0;
    }
    .container-fluid.product-list__sticky-nav.false{
      width: calc(100vw - 5.2%);
      margin: auto;
      .row {
        margin-left: 0;
        margin-right: 0;
        .container.kf-react-container .row{
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
    .container-fluid.product-list__sticky-nav{
      height: 32px;
      padding: 0; 
      .container.kf-react-container{
        .row{
          max-width: 971px !important;  
          margin: 0 2.6% !important; 
          .col{
            padding-right: 0;
            padding-left: 0;
            .product-list__controls{
              .container.kf-react-container.product-list__header{
                margin: 40px 0 30px 0;
                .row{
                  height: 32px;
                }
              }
            }
          }
        }
        .product-listing__controls{
          .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
          } 
          .product-listing__filter-toggle{
            flex-direction: row-reverse;
            width: 18%;
            height: 32px;
            .product-listing__filter-toggle-icon{
              height: 13.19px;
              color: $slate;
              margin-left: 9px;
            }
            .product-list__filter-toggle-text{
              width: 92px;
              height: 28px;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: normal;
              text-align: left;
            }
          }
          .sorting-section{
            min-width: 126px;
            margin-right: -20px; 
            .sort{
              margin-left: 30px;
              .sort__toggle{
                height: 28px;
                min-width: 139px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: normal;
                line-height: 28px;
                margin: -12px;
              }
            }
          } 
        }
      }
    }
    .product-list__tile-col{
      .row{
        display: grid;
      }
      .row.row-cols-2{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 1.875rem;
        margin-right: 0;
        .promo-col{
          height: 448px;
        }
        .product-list__tile{
          padding-right: 0;
          max-width: 100%;
          margin-bottom: 40px; 
          .product-tile{
            &__image{
              margin-bottom: 10px;
              height: 448px;
            }
            &__title{
              font-size: 18px;
              letter-spacing: normal;
              line-height: 28px;
            }
            &__description{
              color: $text-medium-dark-grey;
              font-size: 16px;
              letter-spacing: normal;
              line-height: 24px;
              max-width: 250px;
              height: auto;
            }
          }
          .checkbox_default{
            color: $text-medium-dark-grey;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: normal;
            text-align: left;
            font-family: $lato-regular;
          }

        }
      }
      .row:nth-child(2){
        a{
          height: 42px;
          padding: 15px;
          letter-spacing: 2.5px;
          align-items: center;
          text-transform: uppercase;
        }
      }

    }
  }
  @include breakpoint(desktop-xxxlg){
    .kf-react-container{
      padding: 0!important;
    }
    .container.kf-react-container.product-list__tiles{
      max-width: calc(100vw - 5.2%) !important;
      margin: auto;
      .row{
        margin: 0;
        .col{
          padding-left: 0;
          padding-right: 0;
          z-index: 0;
        }
      }
    }
    .container-fluid{
      padding-left: 0;
      padding-right: 0;
    }
    .container-fluid.product-list__sticky-nav.false{
      width: 100vw !important;
      margin: 0 2.6% !important;
      .row {
        margin-left: 0;
        margin-right: 0;
        .container.kf-react-container .row{
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
    .container-fluid.product-list__sticky-nav{
      height: 32px;
      padding: 0; 
      .container.kf-react-container{
        .row{
          margin: 0 2.6% !important; 
          .col{
            padding-right: 0;
            padding-left: 0;
          }
        }
        .product-listing__controls{
          .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
          } 
          .product-listing__filter-toggle{
            flex-direction: row-reverse;
            width: 18%;
            height: 32px;
            .product-listing__filter-toggle-icon{
              height: 13.19px;
              color: $slate;
              margin-left: 9px;
            }
            .product-list__filter-toggle-text{
              width: 92px;
              height: 28px;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: normal;
              text-align: left;
            }
          }
          .sorting-section{
            min-width: 126px;
            margin-right: -20px;
            .sort{
              margin-left: 30px;
              .sort__toggle{
                height: 28px;
                min-width: 139px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: normal;
                line-height: 28px;
                margin: -12px;
              }
            }
          } 
        }
      }
    }
    .product-list__tile-col{
      .row{
        display: grid;
      }
      .row.row-cols-3{
        display: grid;
        column-gap: 15px; 
        margin-right: -0.1pc;
        .product-list__tile-col.product-list__tile-col--full-width{
          .row.row-cols-3{
            .product-list__tile{
              padding: 0;
              .product-tile{
                &__image{
                  margin-bottom: 10px; 
                  height: 648px;
                }
              }
            }
          }
        }
        .product-list__tile{
          margin-bottom: 60px;
          padding-right: 0;
          max-width: 100%;
          .product-tile{
            &__image{
              margin-bottom: 10px; 
              height: 465px;
            }
            &__title{
              font-size: 18px;
              letter-spacing: normal;
              line-height: 28px;
            }
            &__description{
              color: $text-medium-dark-grey;
              font-size: 16px;
              letter-spacing: normal;
              line-height: 24px;
              max-width: 250px;
              height: auto;
            }
          }
           &__image{
            margin-bottom: 10px; 
          }
          &__title{
            font-size: 18px;
            letter-spacing: normal;
            line-height: 28px;
          }
          &__description{
            max-width: 340px;
            font-size: 16px;
            letter-spacing: normal;
            line-height: 24px;
          }
          .checkbox_default{
            color: $text-medium-dark-grey;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: normal;
            text-align: left;
            font-family: $lato-regular;
          }

        }
      }
      .row:nth-child(2){
        margin-top: 60px;
        a{
          height: 42px;
          padding: 15px;
          letter-spacing: 2.5px;
          align-items: center;
          text-transform: uppercase;
        }
      }

    }
  }
  @media only screen and (min-width: 1920px){
    .container.kf-react-container.product-list__tiles{
      max-width: 1820px !important;
      .product-list__tile:last-child{
        margin-bottom: 60px;
      }
    }
    .container-fluid.product-list__sticky-nav.false{
      max-width: 1820px !important;
      margin: 0 2.6% !important;
      .row .container .kf-react-container .row{
        margin-right: 0;
        margin-left: 0;
      }
    }
    .product-list__sticky-nav-wrap{
      height: 32px;
      max-width: 1820px !important;
      margin: 0 2.6% !important;
      .container.kf-react-container{
        .product-listing__controls{
          .product-listing__total{
            height: 32px;
            width: 237px;
            color: $text-dark-grey;
            font-family: $lato-regular;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 32px;
          } 
          .product-listing__filter-toggle{
            flex-direction: row-reverse;
            width: 18%;
            height: 32px;
            .product-listing__filter-toggle-icon{
              height: 13.19px;
              color: $slate;
              margin-left: 9px;
            }
            .product-list__filter-toggle-text{
              width: 92px;
              height: 28px;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: normal;
              text-align: left;
            }
          }
          .sorting-section{
            min-width: 126px;
            margin-right: -20px;
            .sort{
              margin-left: 30px;
              .sort__toggle{
                height: 28px;
                min-width: 139px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 18px;
                letter-spacing: normal;
                line-height: 28px;
                margin: -12px;
              }
            }
          } 
        }
      }
    }
    .kf-react-container{
      max-width: 1820px !important;
      padding: 0!important;
    }
    .product-list__tile-col.product-list__tile-col--full-width{
      .row.row-cols-3{
        .product-list__tile{
          padding: 0;
          .product-tile{
            &__image{
              margin-bottom: 10px; 
              height: 764px;
            }
          }
        }
      }
    }
    .product-list__tile-col{
      margin-left: 269px;
      .row{
        display: grid;
      }
      .row.row-cols-3{
        display: grid;
        column-gap: 15px;
        row-gap: 0;
        margin-right: -0.1pc;
        .product-list__tile{
          padding: 0;
          max-width: 100%;
          .product-tile{
            &__image{
              margin-bottom: 10px; 
              height: 648px;
              background-color: $color-white;

            }
            &__title{
              font-size: 18px;
              letter-spacing: normal;
              line-height: 28px;
            }
            &__description{
              max-width: 340px;
              font-size: 16px;
              letter-spacing: normal;
              line-height: 24px;
            }
          }
          .checkbox_default{
            color: $text-medium-dark-grey;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: normal;
            text-align: left;
            height: 100%;
          }
        }
      }

      .row:nth-child(2){
        margin-top: 60px;
        a{
          height: 42px;
          padding: 15px;
          letter-spacing: 2.5px;
          align-items: center;
          text-transform: uppercase;
          margin: 0 0 30px;
        }
      }
      .row:nth-child(3){
        .product-listing__go-back{
          flex-direction: row-reverse;
          gap: 15px;
          .product-list__go-back-text{
            font-family: $lato-regular;
            height: 24px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: normal;
            align-items: left;
            color: $text-medium-dark-grey;
            @include breakpoint(tab) {
              order: 2;
              -webkit-box-ordinal-group: 3;
               margin-left: 15px;
               font-size: 16px;
               font-family: $lato-regular;
            }
            @include breakpoint(mobile) {
              font-size: 16px;
              font-family: $lato-regular;
            }
          }
        }
        img {
          @include breakpoint(tab) {
            height: 18.5px;
            width: 9.9px;
          }
        }
      }
      .row{
        .product-list{
          &__load-more{
            
              font-family: $lato-bold;
              font-size: 11px;
              line-height: 10px;
              letter-spacing: 2.5px;
              text-align: center;
              color: $color-medium-blue;
              border: 1px solid $color-medium-blue;
              border-radius: 1px;
              
              &:hover{
               color: $color-light-blue; 
              }
            
          }
        }
        
      }
    }
    .product-list__tile-col.product-list__tile-col--full-width{
      margin-left: 0;
    }
  }
}



.product-listing .filter__chips-tag, .search-result .filter__chips-tag {
  font-family: $lato-regular;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.16px;
  color: $text-dark-grey;
  text-align: right;
}
@include breakpoint(desktop-large){
  .sort__toggle{
    font-size: 18px;
    line-height: 28px;
  }
  .product-listing .filter__chips-tag, .search-result .filter__chips-tag {
    font-size: 14px !important;
    letter-spacing: .23px !important;
 }
 .filter__chips-clear-all.gbh-data-layer {
  font-size: 13px !important;
  font-family: $lato-regular;
  letter-spacing: 0.23px;
  color: $text-medium-dark-grey;
 }
 .checkbox input:checked~span{
  color: $text-dark-grey !important;
  font-size: 14px !important;
  font-family: $lato-regular;
}
.filter .Collapsible {
  color: $cool-dark-grey !important ;
}
}
.filter{
  .filter__mobile-sort{
    .filter__mobile-sort-title {
      height: 24px;
      min-width: 126px;
      color: $text-dark-grey;
      font-family: $lato-bold;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 13px;
      @include breakpoint(tab-max){
        margin-bottom:15px;
      }
    }
    .radio-wrapper .radio__custom{
      box-sizing: border-box;
      height: 20px;
      width: 20px;
      border: 1px solid $cool-dark-grey;
      border-radius: 10px;
      background-color: $white-cap;
      @include breakpoint(tab){
        margin-right:13px;
      }
      @include breakpoint(tab-max){
        padding:2px;
      }
      &:after{
        background:$text-dark-grey !important;
        @include breakpoint(tab-max){
          background:$color-light-blue !important;
        }
      }
    }
    @include breakpoint(mobile) {
        .radio-wrapper{
          display: grid;
          margin-bottom: 0;
            label {
              margin-bottom: 15px;
              &:last-child{
                margin-bottom: 0;
              }
              span:nth-child(1) {
                height: 24px;
                width: 309px;
                color: $text-dark-grey;
                font-family: $lato-regular;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
              }
            }
            input[type="radio"]:checked + span   {
              color: $color-light-blue;
            }
            input[type="radio"] + span + span   {
                color: $text-dark-grey;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                font-family: $lato-regular;
            }
            input[type="radio"]:checked + span + span  {
                color: $text-dark-grey;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                font-family: $lato-bold;
            }
        }
    }
    @include breakpoint(tab) {  
        .radio-wrapper {
          display: grid;
          margin-bottom: 0;
          max-width:339px;
            label {
              margin-bottom: 15px;
              &:last-child{
                margin-bottom: 0;
              }
            }
            input[type="radio"]:checked + span   {
                color: $color-light-blue;
            }
            input[type="radio"] + span + span   {
                color: $text-dark-grey;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                font-family: $lato-regular;
            }
            input[type="radio"]:checked + span + span   {
                color: $text-dark-grey;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                font-family: $lato-bold;
            }
        }
    }
  }
  .filter__chips-clear-all{
    @include breakpoint(tab-max){
      font-size: 14px !important;
      font-family: $lato-regular !important;
      padding-top: 20px;
      margin-bottom: 20px !important;
    }
  }
  .filter__chips-tag{
    font-family: $lato-regular;
    color: $text-dark-grey;
    @include breakpoint(tab-max){
    font-size: 14px;
    letter-spacing: 0.23px;
    padding: 6.5px 9.5px 10.5px 13px;
  }
  }
  
}
.product-list .product-listing__controls .sort{
  @media screen and (max-width: 991px){
    display: none;
  }
}
//search bar overlap issues
.searchbar-open{
  &.modal-open{
    .product-list, .product-listing{
      z-index: 0;
      position: relative;
    }
  }
} 
.gbh-of-scroll {
  .product-listing__sticky-nav-wrap {
    z-index: auto;
  }
  .product-list__tile {
    z-index: 0;
  }
}

@media print {
  .product-list__tile-col{
    .row.row-cols-3{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30.33%, 1fr));
      column-gap: 1.45%;
      row-gap: 0;
      margin-right: -0.1pc;
      margin-left: unset;
    }
  }
  .noPrint{
    display: none;
  }
  .plppage .breadcrumb, .teaser.curated-hero-banner{
    display: none;
  }
}
.plppage,.search-result {
  .product-list{
    &-tile{
      &__colors--swatch, &__colors--swatch > span{
        @include ann-describtion(14px,$lato-regular,20px);
        letter-spacing: normal;
        color: $text-medium-dark-grey;
      }
      &__color-swatch:hover img{
        border: 1px solid $text-medium-black;
	  }
    &__colors--swatch {
      fieldset {
        height: 1.25rem;
        label {
          margin-bottom: 0;
        }
      }
    }
    &__right-controls{
      .checkbox__border, .checkbox__marker, input:checked~span .checkbox__marker{
        border-radius: 5px!important;
        width: 20px!important;
        height: 20px!important;
        .checkbox__marker-icon {
          width: 9.13px;
          height: 7.42px;
      }
      }
    }
    }
    .checkbox, .checkbox_default{
      @include breakpoint(tab-max){
        @include ann-describtion(14px, $lato-regular, 15px);
        letter-spacing: 0.23px;
        color: $text-medium-dark-grey;
      }
    }
    .checkbox__border{
      width: 20px;
      height: 20px;
      border: 1px solid $cool-dark-grey;
    }
    .checkbox input:checked~span{
      color: $text-dark-grey;
    }
    .checkbox__marker{
      @include breakpoint(tab-max){
        width: 20px;
        height: 20px;
        color: $cool-dark-grey;
      }
    }
    &__filter--modal{
      .Collapsible.is-open{
        .checkbox__border, input:checked~span .checkbox__marker{
          border-radius: 0;
        }
      }
    }
  }
}
.sorting-section {
  .product-listing__filter-toggle {
    column-gap: 10px;
    color: $slate;
  }
}
.product-listing__fillter_sort {
  @include breakpoint (tab-max) {
    font-family: $lato-regular;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: normal;
    color: $text-dark-grey;
    margin: 0 10px 0 0;
  }
}
.product-listing__filter-toggle-icon {
  @include breakpoint (tab-max) {
    width: 21px;
    height: 14px;
    padding: 0;
  }
}
.product-list {
  .container-fluid.product-listing__sticky-nav {
    .container.kf-react-plp-container {
      .product-listing__total {
        @include breakpoint (tab-max) {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: normal;
        width: 237px;
        height: 28px;
        padding-top: 4px;
        }
        @include breakpoint (mobile) {
          width: 154px !important;
        }
      }
    }
  }
  .product-list{
    &__tiles{
      .product-list{
        &__tile-col{
          .row-cols-3>* {
            flex: 1 0 32%;
          }
          .product-list{
            &__tile{
              .product-tile{
               
                &__image{
                  img{
                    mix-blend-mode: unset;
                    object-fit: cover;
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  }
} 
.product-listing__sticky-nav {
  @include breakpoint (tab-max) {
    height: 50px;
  }
}
.product-list__sticky-nav-wrap, 
.product-listing__sticky-nav-wrap,
.product-details__sticky-wrapper {
  .sticky.nav-up {
    top: 44px !important;

    @include breakpoint (desktop-extra-lite-sm) {
      top: 67px !important;
    }
  }
}
.inspiration-list__sticky-nav-wrap {
  .sticky.nav-up {
    top: 44px !important;

    @include breakpoint (desktop-extra-lite-sm) {
      top: 65px !important;
    }
  }
}
.sticky-navigation.nav-up {
  top: 44px !important;
  @include breakpoint (desktop-extra-lite-sm) {
    top: 67px !important;
  }
}
.Collapsible {
  &.is-open {
    .Collapsible__contentOuter {
      .Collapsible__contentInner {
        .filter-list {
          .listitem {
            span {
              font-size: 14px;
              line-height: 15px;
              letter-spacing: .23px;
            }
          }
        }
      }
    }
  }
}
.filter__chips-tag {
  padding: 6px 13px 9px 10px;
  margin-bottom: 0;
}
.filter__chips-clear-all {
  margin-bottom: 20px;
}
.filter__chips-close-icon {
  margin-left: 5px;
  width: 7px;
  height: 7px;
}

.product-list__back-to-top {
  @include breakpoint (tab-max) {
    border-top: none;
    padding-top: unset;
    margin-bottom: 60px;
  }
}

//Productpage Filter changes
.product-list__filter {
  .product-list__filter--sticky {
    .filter {
      .Collapsible {
        .Collapsible__contentInner {
          .filter-list {
            li {
              .checkbox {
                align-items: center;
                @include breakpoint (tab-max) {
                  align-items: center;
                  margin-top: 0;
                }
                @include breakpoint (desktop-small) {
                  margin-top: 15px;
                  margin-bottom: 15px;
                }
                @include breakpoint (desktop-large) {
                  margin-top: 15px;
                  margin-bottom: 15px;
                }

                &__border {
                  @include breakpoint (tab-max) {
                    width: 15px;
                    height: 15px;
                    border: 1px solid $cool-dark-grey;
                    border-radius: unset;
                  }

                  .checkbox__marker {
                    .checkbox__marker-icon {
                      @include breakpoint (tab-max) {
                        width: 9.13px;
                        height: 7px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.collapsible-outer {
  .Collapsible {
    .Collapsible__contentOuter {
      .Collapsible__contentInner {
        ul.filter-list {
          li.listitem {
            .checkbox {
              margin-top: 15px;
            }
            .checkbox__border {
              border-radius: unset;
              border: 1px solid $cool-dark-grey;
              width: 15px;
              height: 15px;
              .checkbox__marker {
                border-radius: unset;
                background-color: $color-light-blue;
                .checkbox__marker-icon {
                  width: 9.13px;
                  height: 7.42px;
                }
              }  
            }
          }
        }
      }
    }
  }
}
.checkbox {
  .checkbox_default {
    font-size: 14px;
    color: $text-medium-dark-grey;
  }
}
.product-listing__sticky-nav {
  &.product-listing__sticky-nav--shadow {
    display: flow;
  }
}
.product-list__filter {
  @include breakpoint(tab){
    padding: 0 32px;
  }
  @include breakpoint(mobile){
    padding: 0 18px;
  }
  .filter__apply {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
}
.filter__chips {
  .filter__chips-tag {
    border: 1px solid $cool-dark-grey;
    border-radius: 1px;
    span {
      font-family: $lato-regular;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: .23px;
      @include breakpoint (desktop-small) {
        height: 16px;
      }
    }
  }
}
.product-listing {
  .filter {
    .Collapsible__trigger {
      div {
        height: 16px;
      }
    }
  }
}

.product-listing {
  &__go-back {
    img {
      @include breakpoint(tab) {
        position: absolute;
        right: 91px;
        margin-top: 3px;
        font-size: 16px;
        line-height: 24px;
        color: $text-medium-dark-grey;
      }
      @include breakpoint(mobile) {
        position: absolute;
        right: 91px;
        margin-top: 3px;
        font-size: 16px;
        line-height: 24px;
        color: $text-medium-dark-grey;
      }
    }
  }
}

.product-listing__go-back-text {
  font-family: $lato-regular;
  @include breakpoint (mobile) {
    font-size: 16px;
    line-height: 24px;
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
  }
  @include breakpoint (tab) {
    font-size: 16px;
    line-height: 24px;
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
  }
}

.product-listing {
  &__row {
    @include breakpoint(mobile) {
      width: 100%;
    }
    @include breakpoint(tab) {
      width: 100%;
    }
  }
}

.product-listing {
  .checkbox input:checked~span { 
    @include breakpoint(tab) {
       color: $text-dark-grey;
    }
  }
}
.product-list  {
  .container.kf-react-container {
    .product-list__tiles  {
      .row {
        @include breakpoint (mobile) {
          margin-top: 40px;
        }
      }
    }
  }
  &__tiles{
    .row.gbh-data-layer{
      div{
        @include breakpoint(mobile){
          width: 100%;
        }
        .product-list__load-more {
          @include breakpoint (mobile) {
            margin-top: 40px;
          }
        }
      }
    }
  }
}

.product-tile--lite {
  .product-tile__image { 
    @include breakpoint(mobile) {
      background-color: $white;
    }
    
   }
}

.filter{
  &__mobile-header{
    @include breakpoint(tab-max){
      margin-top: 18px;
    }
  }
}
div.search-result {
  .product-listing {
    .kf-react-plp-container {
      @include breakpoint (tab) {
        margin-right: 3.94%;
        margin-left: 3.94%;
        margin-bottom: unset;
      }
      @include breakpoint (mobile) {
        margin-right: 2.7%;
      }
    
  }
  
  }
    margin-right: 2.7% !important;
    @include breakpoint(desktop-small){
      margin-right: 2.8% !important;
    }
  }
  


input:checked ~ span .checkbox__marker {
  background-color: $color-medium-blue;
}
.filter__chips-tag {
  span {
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.23px;
    color: $text-dark-grey;
  }
}

.product-listing__controls{
  .sorting-section {
    .sort.sort--open .sort__drop-down .sort__item{
      color:$text-light-grey;
      font-family: $lato-regular;
      font-size: 16px;
      &.active{
        background-color: $cool-light-grey;
      }
    }
  }
}

.search-result {
  .product-listing__controls  {
    .product-listing__total {
      @include breakpoint (tab) {
        margin-left: -20px;
      }
    }
  }
.product-list {
  .sorting-section {
    .sort.sort--open .sort__drop-down .sort__item{
      color:$text-light-grey;
      font-family: $lato-regular;
      font-size: 16px;
      &.active{
        background-color: $cool-light-grey !important;
      }
    }
  }
}

.literature .sort__item{
  color:$text-light-grey !important;
  font-family: $lato-regular;
  font-size: 16px;
  &.active{
    background-color: $cool-light-grey;
  }
}

.product-listing__sticky-nav-wrap {
  .sticky.nav-up {
    @include breakpoint (tab-max) {
    width: 100% !important;
    left: 0;
    }
  }
  div, div.sticky{
    left: 0;
  }
}

.filter .Collapsible.is-open .Collapsible__contentInner .filter-list li {
  .checkbox{
  margin-left: 0;
  padding: 5px 0;
  &:last-child{
    margin:0;
  }
}
}
.filter{
    &__inner-container{
      @include breakpoint(desktop-small){
        width:218px !important;
      }
    }
    .Collapsible__trigger div{
      .plus .line{
        background: $text-medium-dark-grey;
      }
    }
  }
}
.product-list .product-list__filter .filter{
  @include breakpoint (desktop-small) {
    overflow-x: hidden;
  }
}
.product-list .product-list__tile-col .row:has(.col-8){
  grid-template-columns: none;
  display:flex;
  justify-content: space-between;
  .product-list__tile{
    flex:0 0 32%;
    max-width:32%;
    @include breakpoint(desktop-mid){
      flex: 0 0 32%;
      max-width: 32%;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      flex: 0 0 48%;
      max-width: 48%;
    }
    @include breakpoint(desktop-extra-lite-sm){
      flex: 0 0 48%;
      max-width:48%;
    }
    &.col-8.product-list__tile{
      flex:0 0 66%;
      max-width:66%;
      @include breakpoint(desktop-mid){
        flex:0 0 66%;
        max-width: 66%;
        padding:0;
      }
      @media (min-width: 1024px) and (max-width: 1439px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include breakpoint(desktop-extra-lite-sm){
        flex:0 0 100%;
        max-width:100%;
        padding:0;
      }
    }
  }
}
.product-list .product-list__tile-col .row:has(.col-12){
  grid-template-columns: none;
  display:flex;
  justify-content: space-between;
  .product-list__tile{
    flex:0 0 32%;
    max-width:32%;
    @media (min-width: 1024px) and (max-width: 1439px) {
      flex: 0 0 48%;
      max-width: 48%;
    }
    @include breakpoint(desktop-extra-lite-sm){
      flex: 0 0 48%;
      max-width:48%;
    }
    &.col-12.product-list__tile{
      flex:0 0 100%;
      max-width:100%;
      @include breakpoint(desktop-mid){ 
        padding:0;
      }
      @media (min-width: 1024px) and (max-width: 1439px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include breakpoint(desktop-extra-lite-sm){ 
        padding:0;
      }
      .kf-promo-banner.\--fixed-height{
        @include breakpoint(desktop-extra-lite-sm){ 
          height:inherit;
        }
        @include breakpoint(desktop-mid){ 
          height:inherit;
        }
      }
    } 
  }
  &.row-cols-2{
    @include breakpoint(mobile){
      .product-list__tile{
        flex:0 0 48%;
      }
    } 
  }
  &.row-cols-1{
    @include breakpoint(mobile){
      .product-list__tile{
        flex:0 0 100%;
      }
    } 
  }
}

.product-list {
  &__filter {
    .filter {
      @include breakpoint (desktop-xlg) {
      scrollbar-width: auto;
      }
    }
  }
}

.product-listing .filter, .search-result .filter { 
  scrollbar-width: auto;
  overflow-x: hidden;
}
.product-list__tiles{
  div.filter{
    .filter__chips{
      .filter__chips-clear-all{
        padding-top: 10px;
      }
    }
  }
  .filter__chips {
    .filter__chips-tag {
      margin: 0 10px 10px 0;
    }
  }
}
// Promotion tiles UI 
.product-list{
    .product-list__tiles{
      .product-list__tile-col{
        &.product-list__tile-col--full-width{
          .row.row-cols-3{
            .col.promo-col.product-list__tile{
              @include breakpoint (desktop-large) {
                  height: 764px;
              }
            }
            .col-8.promo-col.product-list__tile{
              @include breakpoint (desktop-large) {
                  height: 764px;
              }
            }
          }
        }
      }
    }
}

.product-tile__wrapper__card {
.product-tile {
  &__image {
    img:nth-child(2) {
      @include breakpoint (desktop-large) {
      transform: unset;
      }
    }
  }
} 
}

  .product-list.promoPlacement-4 .product-list__tile-col .row:has(.col-12){
      @media (min-width: 1280px) and (max-width: 1439px) {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1280px) and (max-width: 1199px) {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
      }
      .col{
        @media (min-width: 1200px) and (max-width: 1439px) {
          max-width: 100%;
        }
      }
  }
  .product-list.promoPlacement-4 .product-list__tile-col .row:has(.col-12) .product-list__tile.col-12.product-list__tile {
    @media (min-width: 1280px) and (max-width: 1439px) {
      grid-column: 1 / 4;
      grid-row: 4 / 4;
    }
  }
  //Dynamic search css code

  

  .product-listing__sticky-nav-wrap {
    position: relative;
    z-index: 2;
}
.product-listing__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.product-listing__controls {
  display: flex;
  justify-content: flex-start;
  height: 30px;
  align-items: center;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .product-listing__controls {
      justify-content: space-between;
      position: relative;
  }
}



.product-listing__col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.product-listing__controls section {
  display: flex;
  min-height: 34px;
  align-items: center;
}
.product-listing__fillter_sort {
  display: none;
  font-family: "Helvetica Now Text W05 Regular";
}

.product-listing__filter-toggle {
  display: flex;
  align-items: center;
  font-family: "Helvetica Now Text W05 Regular";
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -.45px;
  color: #212121;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
}
.ProductTile_productTileWrapper__slRQS .product-listing .product-list-tile__image {
  background: $white;
  @include breakpoint (desktop-small) {
    width: 22.75rem;
    height: 28.8125rem;
  }
  @include breakpoint (desktop-xlg) {
    width: 31.125rem;
    height: 41.5rem;
  }
  @include breakpoint (tab) {
    height: 30.3125rem;
  }
  @include breakpoint (mobile) {
    width: 10.375rem;
    height: 13.8125rem;
  }
  img {
    &:first-child {
      @include breakpoint (desktop-small) {
        width: 22.75rem;
        height: 28.8125rem;
      }
      @include breakpoint (desktop-xlg) {
        width: 31.125rem;
        height: 41.5rem;
      }
      @include breakpoint (tab) {
        height: 30.3125rem;
      }
      @include breakpoint (mobile) {
        width: 10.375rem;
        height: 13.8125rem;
      }
    }
  }
}

#productListWrapperId{
  .product-list{
    &__load-more{
      width: 100%;
      background: $white;
      border: 1px solid $slate;
      line-height: 24px;    
      text-align: center;    
      transition: all .3s ease-in-out;    
      margin: 0 0 40px;
      background-color: $cool-light-grey;    
      font-family: $lato-bold;    
      color: $color-medium-blue;
      border-color: $color-light-blue;
      text-transform: uppercase;
      @include breakpoint(mobile){
        margin: 20px 0 33px;
      }
    }
    @include breakpoint(desktop-small){
      &__back-to-top{
        margin: 0px;
      }
      &__go-back-text{
        order: 2;
        margin-left: 15px;
      }
    }
    @include breakpoint(desktop-large){
      &__back-to-top{
      padding-bottom: 7.5rem;
      }
    }
    @include breakpoint(xl){
      &__back-to-top{
      padding-bottom: 60px;
      margin-bottom: 0;
      }
    }
    @include breakpoint(desktop-small){
      &__filter-toggle-text{
        li {
          font-size: 18px
        }
      } 
    }
    
  }
  .checkbox{
    .checkbox_default{
      color: $text-medium-dark-grey;
    }
  } 
  .products-per-page ul li{

    text-transform: uppercase;
    color: $text-medium-dark-grey;
    font-family: $lato-regular;
    font-size: 9pt;
    letter-spacing: 2px;
    line-height: 1pc;
    margin-right: 15px;
    &.disabled{
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 4.5px;
      -webkit-text-decoration-color: $cool-dark-grey;
      text-decoration-color: $cool-dark-grey;
  
      &:first-child {
        text-decoration: none;
        font-size: 10px;
        letter-spacing: 1.7px;
      }
    }
  }
  
 
}

.searchbar-open {
  .product-listing__sticky-nav-wrap{
    z-index: auto !important;
  }
  .search-result{
    .product-listing__sticky-nav-wrap{
      z-index: 0 !important;
    } 
  }
}