.organize-teaser-accordion{
    .accordion{
        &.faq-accordion{
            width:45rem;
            @include breakpoint(tab){
                width:100%;
            }
            @include breakpoint(mobile){
                width:100%;
            }
            .cmp-accordion{
                &__item {
                    &:last-child{
                        border-bottom: 0.063rem solid $cool-dark-grey;
                    }
                    .Collapsible{
                        border: none;
                    }
                }
                &__header{
                    border-bottom: 0rem solid $cool-dark-grey;
                    border-top: 1px solid $cool-dark-grey;
                    margin: 0rem;
                    transition: 0.3s ease;
                    overflow:hidden;
                    // border: none;
                    .cmp-accordion__title {
                        @include ann-describtion(1.375rem, $font-regular-base, 2rem);
                        color:$text-dark-grey;
                        letter-spacing: normal;
                        @include breakpoint(desktop-small){
                            padding-right: 6.6875rem;
                        }
                        @include breakpoint(tab) {
                            @include ann-describtion(1rem, $font-regular-base, 1.5rem);
                            width: 100%;
                            letter-spacing: 0.44px
                        }
                        @include breakpoint(mobile) {
                            @include ann-describtion(1rem, $font-regular-base, 1.5rem);
                            width: 17.25rem;
                        }
                    }
                }
                &__button{
                    padding: 2.4688rem 0!important;
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    @include breakpoint(tab-max){
                        padding:1.8438rem 0!important;
                    }
                    &--expanded{
                        overflow:hidden;
                    }
                }
                &__icon{
                    line-height:0;
                    width:15px;
                    height:15px;
                    top: unset!important;
                }
            }
        }
    }
}
.accordion{
    .cmp-accordion{
        &__item{
            .cmp-accordion__icon{
                &::before{
                    content: "\e929";
                    font-size: 15px;
                    color: $text-medium-dark-grey;
                    font-family: $icon-moon;
                    position: relative;
                    top: 0;
                    right: 0;
                    font-weight: 600;
                }
            }
            &.active, .cmp-accordion__button--expanded{
                .cmp-accordion__icon{
                    &::before{
                        content: "\e928";
                        font-size: 1px;
                        color:$text-medium-dark-grey;
                        font-family: $icon-moon;
                    }
                }
            }
        }
        &__header{
            &:focus:not(:focus-visible), :focus-visible{
                opacity: 1;
                width: 100%;
                z-index: 999;
                outline: 1px dashed currentcolor !important; // KF defalut style overwrite
                outline-offset: -2px;
                position:initial;
            }
        }
        &__panel--expanded{
            .text.faq-text{
                .cmp-text{
                    p, p:first-child{
                        margin-bottom:30px;
                        @include breakpoint(mobile){
                            margin-bottom:15px;
                        }
                    }
                    p:last-child{
                        @include breakpoint(desktop-small){
                            margin-bottom:40px;
                        }
                    }
                }
            }
           
        }
    }
}