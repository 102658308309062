.dot-whack-teaser {
    &.teaser {
    width: auto;
    padding: 0;
    background-color: $white;
    box-shadow: 0 0 20px $sort-drop-shadow;
    z-index: 99;
    animation: expand_promo 1s;
    animation-fill-mode: backwards;
    @include breakpoint(tab-max) {
        width: 100%;
    }
    .cmp-teaser {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @include breakpoint(tab-max) {
            justify-content: start;
        }
        &__content {
            margin-left: 35px;
            margin-right: 35px;
            padding: 0;
            @include breakpoint(tab) {
                padding: 0 0 0 2.1875rem;
                width: 375px;
                margin: 0;
            }
            @include breakpoint(mobile) {
                padding: 1.875rem 0 1.875rem 1.125rem;
                margin: 0;
            }
        }
        &__title {
            display: block;
            h1,h2,h3,h4,h5,h6,p {
                @include ann-describtion(1.125rem, $lato-regular, 1.5rem);
                color: $text-dark-grey;
                margin-bottom: .3125rem;
                letter-spacing: 0.5px;
            }
        }
        &__description {
            p {
                @include ann-describtion(0.75rem, $lato-regular, 1rem);
                color: $text-dark-grey;
                text-transform: uppercase;
                letter-spacing: .125rem;
            }
        }
        &__image {
            @include breakpoint(desktop-small) {
                min-width: 19.5625rem;
            }
            @include breakpoint(tab) {
                width: 23.4375rem;
                max-height: 13.1875rem;
            }
            .cmp-image {
                @include breakpoint(desktop-small) {
                    max-width: 313px;
                    max-height: 235px;
                }
                @include breakpoint(tab) {
                    min-width: 375px;
                    min-height: 211px;
                }
                @include breakpoint(mobile) {
                    min-width: 375px;
                    min-height: 281px;
                }
                &__image {
                    @include breakpoint(tab) {
                        min-width: inherit;
                    }
                }
            }
        }
        &__action-container {
            margin-top: 1.875rem;
            .cmp-teaser__action-link {
                @include ann-describtion(0.6875rem, $lato-regular, 1rem);
                letter-spacing: .1125rem;
                color: $color-light-blue;
                border-color: $color-light-blue;
                padding: 0 0 7px 0;
                @include breakpoint(tab-max){
                    padding: 0 0 8px 0;
                }
            }
        }
    }
    &.position-right-bottom {
        position: absolute;
        right: 6.875rem;
        bottom: 3.125rem;
        display: inline-flex;
        @include breakpoint(desktop-large) {
            right: 16.875rem;
        }
        @include breakpoint(tab-max) {
            right: unset;
            left: 0;
            bottom: 0;
            position: relative;
            padding: 60px 0;
            background-color: $cool-light-grey;
        }
        @include breakpoint(mobile){
            display: block;
        }
    }
    &.image-left {
        .cmp-teaser {
            @include breakpoint(mobile) {
                flex-direction: column;
                align-items: flex-start;
            }
            &__image {
                @include breakpoint(tab-max) {
                    padding: 0;
                }
            }
        }
    }
}
}

.dot-whack-teaser-small {
    margin-right: 0;
    @extend .dot-whack-teaser;
    &.image-right {
    .cmp-teaser {
        flex-direction: row-reverse;
        align-items: flex-start;
        align-items: center;
        justify-content: space-between;
        @include breakpoint (desktop-xlg) {
            max-width: 417px;
        }
        @include breakpoint (tab) {
            max-width: unset;
        }
        &__image {
            @include breakpoint(desktop-small) {
                min-width: 7.3125rem;
                height: -webkit-fill-available;
            }
            @include breakpoint(tab) {
                width: auto;
                max-width: 10.5rem;
            }
            @include breakpoint(mobile) {
                max-width: 7.3125rem;
                width: 100%;
            }
            .cmp-image {
                @include breakpoint(desktop-small) {
                    max-width: 117px;
                    height: 117px;
                    margin-left: auto;
                }
                @include breakpoint(tab) {
                    min-width: 168px;
                    min-height: 168px;
                }
                @include breakpoint(mobile) {
                    min-width: 117px;
                    min-height: 117px;
                }
                &__image {
                    img {
                        @include breakpoint(tab) {
                            max-height: 211px;
                        }
                    }
                }
            }
        }
        &__content {
            display: flex;
            flex-direction: row;
            padding: 0 1.9375rem 0 1.25rem;
            margin: 0;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
            @include breakpoint(desktop-small) {
                width: 100%;
                min-width: 300px;
            }
            @include breakpoint(tab) {
                width: 82.51231%;
            }
            @include breakpoint(tab-max) {
                flex-direction: column;
                align-items: flex-start;
                padding: 2.4375rem 0 3.0625rem 2rem;
            }
            @include breakpoint(mobile) {
                width: 100%;
                white-space: nowrap;
                padding: 1rem 3.25rem 1.0625rem 1.125rem;
            }
        }
        &__title {
            @include breakpoint(desktop-small) {
                width: 80%;
            }
            h1,h2,h3,h4,h5,h6,p {
                @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
                letter-spacing: normal;
                color: $text-dark-grey;
            }
        }
        &__description {
            width: auto;
            padding-right: 15px;
            p {
                margin: 0;
                letter-spacing: .125rem;
            }
        }
        &__action-container {
            margin: 0;
            @include breakpoint(desktop-small) {
                position: absolute;
                right: unset;
                padding: 0;
                top: 35px;
            }
            @include breakpoint (desktop-xlg) {
                position: absolute;
                right: unset;
                padding: 0;
                top: 27px;
            }
            @include breakpoint(desktop-extra-sm) {
                right: unset;
                top: 27.5px;
            }
            @include breakpoint(tab-max) {
                margin-top: .625rem;
                line-height: 0;
            }
        }
        &__action-link {
            @include breakpoint(desktop-lg) {
                display: flex;
            }
        }
    }
    .cmp-teaser__content {
        .cmp-teaser__title {
            h1 {
                @include ann-describtion (1.125rem, $lato-regular, 1.5rem);
                letter-spacing: 0.5px;
                padding-bottom: unset;
                @include breakpoint (tab) {
                padding: 0 0 5px;
                margin-bottom: 0;
                }
            }
        }
        .cmp-teaser__description {
            p {
            @include ann-describtion(.75rem, $lato-regular, 1rem);
            letter-spacing: 2px;
            }
        }
        .cmp-teaser__action-container {
            @include breakpoint (desktop-small) {
            position: absolute;
            right: 85px;
            padding: 0;
            top: 30px;
            }
            @include breakpoint (desktop-xlg) {
                top: 24px;
            }
        }
    }
    &.position-right-top {
        position: absolute;
        right: 6.875rem;
        top: 3.125rem;
        bottom: unset;
        display: inline-flex;
        @include breakpoint(desktop-large) {
            right: 16.875rem;
        }
        @include breakpoint(tab-max) {
            right: 0;
            left: unset;
            top: unset;
            bottom: 0;
            position: relative;
            padding: 60px 0;
            background-color: $cool-light-grey;
        }
        @include breakpoint(mobile){
            display: block;
        }
    }
}
}

@keyframes expand_promo {
    0% {
        clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%);
        -webkit-clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%);
    }
    100% {
        clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
        -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
    }
}