.order-details {
  padding: 0 10px;

  &__btn-wrapper {
    display: none;

    button {
      padding: 0;
      margin: 0;
      line-height: 40px;
      height: 40px;
      background-color: #dbdbdb;
      border-radius: 4px;
      width: 100%;
      border: none;

      &:hover {
        background-color: #bebebe;
      }

      &[disabled] {
        opacity: 0.5;
        cursor: default;

        &:hover {
          background-color: #dbdbdb;
        }
      }
    }
  }

  &__headline {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .order-detail {
    &__cta-container,
    &__headline,
    &__sub-headline,
    &__order-content,
    &__shipping-wrapper--shipped-item {
      display: none;
    }

    &__status {
      display: block;
      margin: 0 0 30px 0px;
    }

    .cart-details {
      &__thead__data--status {
        display: block;
      }

      &__tbody__data--status {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .order-details {
    &__btn-wrapper {
      display: block;
      padding: 0 10px;
      width: 200px;
      float: right;
    }

    &__number {
      float: left;
      width: calc(100% - 210px);
    }

    &__headline {
      padding-top: 30px;
      margin-bottom: 50px;
    }

    .order-detail {
      &__status {
        margin: 0 0 30px 40px;
      }
    }
  }
}

@media only screen and (min-width: 980px), print {
  .order-details {
    &__body {
      padding: 15px 10px;
    }

    &__order {
      padding: 0 10px;
      margin-top: 20px;
    }
  }
}
