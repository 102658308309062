.findstore-util-nav {
    &__location-content, &__zipcode-content, &__input-control, &__submit, &__location-link,  &__store, &__signStore, &__search-que,&__not-your-store {
        font-family: $lato-regular;
    }
    &__not-your-store {
        line-height: 16px;
        letter-spacing: 1.7px;
        font-size: 10px;
        margin-bottom: 15px;
    }
    &__access-utility-container {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
    &__content {
        padding: 72px 120px 79px 110px;
        background-color: $color-dark-blue;
        z-index:2;
        position: relative;
        .findstore-util-nav__loc-search-container {
            padding-right: 149.5px;
            padding-left: 118.5px;
        }
    }
    &__content-text {
        padding: 70px 70px 79px 110px;
    }
    &__search-utility-container {
        border-right: 1px solid $text-medium-dark-grey;
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(desktop-extra-lite-sm) {
            flex: 0 0 100%;
            max-width: 100%;
            border-right: 0;
        }
    }

    &__input-container {
        border-bottom: 1px solid $color-white;
        width: 100%;
        padding-top: 0;
    }
    &__link-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 100px;
        @include breakpoint(desktop-extra-lite-sm){
            padding-top:15px;
        }
    } 
    &__link-container-text {
        padding-bottom: 105px;
    }
    &__input-control {
        font-size: 26px;
        letter-spacing: 0;
        line-height: 36px;
        background-color: $color-dark-blue;
        padding-bottom: 28px;
        @include breakpoint(desktop-extra-lite-sm){
            font-size:18px;
            line-height:28px;
            padding-bottom:15px;
        }
    }
    &__submit {
        height: 50px;
        width: 115px;
        color: $color-white;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 2.5px;
        line-height: 24px;
        text-align: center;
        border: 1px solid $color-white;
        border-radius: 1px;
        background-color: $color-dark-blue;
        margin-left: 133px;
        margin-bottom: 25px;
        margin-top: 0px;
    }
    &__location-link {
        @include ann-describtion(1pc, $helvetica-light, 1.5);
        letter-spacing: -.55px;
        color: $light-gray;
        text-decoration: underline;
        text-transform: uppercase;
        @include breakpoint(desktop-extra-lite-sm){
            @include ann-describtion(13px, $helvetica-light, 20px);
            letter-spacing:0.2px;
        }
    }
    // When location matched start code here
    &__input-container-text {
        padding-top: 20px;
    }
    &__supply-section {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(ipad-landscape){
            flex: 0 0 100%;
            max-width: 100%;
        }
        @include breakpoint(desktop-extra-lite-lg){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &__access-utility-container {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(ipad-landscape){
            border: 0;
            max-width: 100%;
            flex: 0 0 100%;
        }
        @include breakpoint(desktop-extra-lite-lg){
            border: 0;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    &__store {
        color: $color-white;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
        margin-bottom: 25px;
    }
    &__signStore {
        color: $color-white;
        font-size: 26px;
        letter-spacing: 0;
        line-height: 36px;
    }
    &__address-container {
        padding: 0px 0px 14px 0px !important;
        p, p:first-child, .checkbox span:last-child {
            font-family: $lato-regular;
        }
        p {
            color: $color-white;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 0;
        }
        p:first-child {
            color: $color-white;
            font-size: 16px;
            letter-spacing: 1.7px;
            line-height: 24px;
            margin-bottom: 10px;
        }
        .checkbox {
            margin-bottom: 21px;
            margin-top: 30px;
            span:last-child {
                color: $color-white;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }
            input:checked ~ span .checkbox__marker {
                width: 20px;
                height: 20px;
            }
            input:checked ~ span{
                color: $white!important;
            }
            input ~ .checkbox__border{
                border: none;
                width: 20px;
                height: 20px;
            }
            input ~ .checkbox__border .checkbox__marker{
                background: $white!important;
                border: 1px solid $dark-gray;
                border-radius: 5px;
            }
            input:checked ~ .checkbox__border .checkbox__marker{
                background: $color-medium-blue!important;
                color: $white!important;
                border: 1px solid $dark-gray;
                border-radius: 5px;
            }
        }
    }
    &__search-que {
        color: $color-white;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: center;
    }
    &__mobile-container{
        display: none;
        @include breakpoint(desktop-extra-lite-sm){
            background:$color-dark-blue;
            padding:85px 32px 30px;
            display: block;
        }
        @include breakpoint(mobile){
            padding:48px 18px 30px;
        }
        .findstore-util-nav__close{
            @include breakpoint(desktop-extra-lite-sm){
                padding-top:0;
                margin-bottom:44px;
            }
            @include breakpoint(mobile){
                margin-bottom:37px;
            }
        }
    }
    &__menu{
        @include breakpoint(desktop-extra-lite-sm){
            padding-bottom:0;
        }
    }
}
.findstore-util-nav {
    &__content {
        .findstore-util-nav__address-container {
            padding-right: 10px;
            padding-left: 25px;
        }
    }
}
.gbh-utility-navbar {
    .findstore-util-nav {
        &__view-link-container .findstore-util-nav__view-store-link, &__address-container .findstore-util-nav__view-store-detail {
            font-family: $lato-regular;
        }
        &__view-link-container {
            .findstore-util-nav__view-store-link {
                @include ann-describtion(14px, $helvetica-light, 20px);
                letter-spacing: 0;
                color: $color-white;
                text-decoration: underline;
                margin-left: 0;
                @include breakpoint(desktop-extra-lite-sm){
                    @include ann-describtion(13px, $helvetica-light, 20px);
                    letter-spacing:0.2px;
                }
            }
        }
        &__address-container {
            .findstore-util-nav__view-store-detail {
                color: $color-white;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                padding-top: 25px;
            }
        }
    }
}
.gbh-utility-nav {
    .findstore-util-nav {
        &__container {
            height: 100vh;
            @include breakpoint(desktop-extra-lite-sm){
                overflow-y: auto;
                background-color: $color-dark-blue;
            }
        }
        &__location-content {
            padding: 10px 0 0;
            @include breakpoint(desktop-extra-lite-sm){
                padding:0;
            }
        }
        &__search-utility-container{
            .findstore-util-nav__location-content{
            @include breakpoint(mobile){
                font-size: 26px;
                letter-spacing: 0;
                line-height: 36px;
                text-align:left;
                padding:39px 50px 20px 0;
            }
            }
        }
        &__zipcode-content {
            padding: 20px 0 0;
            @include breakpoint(mobile){
                padding-top:0;
            }
        }
        &__loc-search-container {
            padding: 1.5625rem 3.125rem 1.5625rem 7.4375rem;
            @include breakpoint(desktop-extra-lite-lg){
                padding: 1.5625rem 0!important;
            }
            @include breakpoint(ipad-landscape){
                padding: 1.5625rem 0;
            }
            @media screen and (min-width: 1200px) and (max-width: 1400px) {
              padding: 25px 50px;
            }
            @include breakpoint(desktop-extra-lite-sm){
                flex: 0 0 100%;
                max-width: 100%;
                padding:50px 0;
            }
            @include breakpoint(desktop-lg){
                padding: 1.5625rem 3.125rem 1.5625rem 2rem;
            }
        }
        &__input-control {
            width: 100%;
        }
        &__submit {
            width: 115px;
            margin-left: 0;
            height: 50px;
            font-family: $helvetica-neue-medium;
            font-size: 16px;
            color: $color-white;
            border-radius: 1px;
            border: 1px solid $color-white;
        }
        &__content {
            @include breakpoint(desktop-extra-lite-sm){
                height: 100vh;
                padding-top:30px;
            }
            @include breakpoint(desktop-extra-lite-sm){
                padding: 0 0 50px;
            }
            @include breakpoint(mobile) {
                padding: 0 0 25px;
            }
        }
      &__location-content {
        padding: 10px 0 0;
      }
    &__search-utility-container{
        .findstore-util-nav__location-content{
           @include breakpoint(mobile){
               font-size: 26px;
               letter-spacing: 0;
               line-height: 36px;
               text-align:left;
               padding:39px 50px 20px 0;
           }
        }
     }
     &__zipcode-content {
       padding: 20px 0 0;
     }
      &__submit {
        width: 115px;
        margin-left: 0;
        height: 50px;
        font-family: $helvetica-neue-medium;
        font-size: 16px;
        color: $color-white;
        border-radius: 1px;
        border: 1px solid $color-white;
      }
    &__location-link {
      letter-spacing: 0;
      line-height: 20px;
      font-size: 14px;
      color: $color-white;
      text-transform: lowercase;
    }
    }
}
.gbh-utility-nav {
    .gbh-utility-navbar {
        .findstore-util-nav {
                &__location-content {
                    font-family: $lato-regular;
                    font-size: 26px;
                    letter-spacing: 0;
                    line-height: 36px;
                    @include breakpoint(desktop-extra-lite-sm){
                        font-size:22px;
                        line-height:32px;
                        padding:0 0 10px;
                    }
                }
                &__zipcode-content {
                    font-family: $lato-regular;
                    letter-spacing: 0;
                    color: $white;
                }
                &__location-link {
                    font-family: $lato-regular;
                    text-transform: capitalize;
                }
                &__view-store-link {
                    font-family: $lato-regular;
                }
                &__submit {
                    color: $color-white;
                    text-transform: uppercase;
                    font-family: $lato-bold;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 2.5px;
                    opacity: 1;
                    @include breakpoint(desktop-extra-lite-sm){
                        margin-bottom:15px;
                    }
                }
                &__input-control {
                    opacity: 1;
                    &::placeholder{
                        color: $color-white;
                    }
                }
            }
        }
    }

    .findstore-util-nav {
        .kf-react-container{
            padding: 0 !important;
            @include breakpoint(desktop-extra-lite-sm){
                padding: 0 36px!important;
            }
            @include breakpoint(mobile){
                padding: 0 18px!important;
            }
            
            
        } 
      .findstore-util-nav{
          &__search-utility-container{
              @include breakpoint(desktop-extra-lite-sm){
                  border-right: none;
                  padding-right: 0;   
              }
          }
      }
      .findstore-util-nav__input-container{
        @include breakpoint(mobile){
          align-items: end;
          flex-direction: row;
        }
      }

      .auto-suggestion__input{
        background-color: $color-dark-blue;
        &::placeholder{
          color: $white;
        }
      }
    }
    .gbh-utility-nav{
        .gbh-utility-navbar{
            .findstore-util-nav{
                &__location-content, &__zipcode-content{
                    @include breakpoint(desktop-extra-lite-sm){
                        text-align: left;
                    }
                }
                &__zipcode-content{
                    @include breakpoint(desktop-extra-lite-sm){
                        padding: 0;
                    }
                }

            }
        } 
    } 

.findstore-util-nav {
  .auto-suggestion__input-container {
      .auto-suggestion__input {
          @include ann-describtion(1.625rem, $lato-regular, 2.25rem);
          letter-spacing: normal;
          background-color: $color-dark-blue;
          padding-bottom: 28px;
  
          @include breakpoint(desktop-extra-lite-sm) {
            @include ann-describtion(1.125rem, $lato-regular, 1.75rem);
            padding-bottom: 15px;
            margin-top: 21px;
          }
      }
  }
  .findstore-util-nav__show-container{
    .findstore-util-nav__search-utility-container{
        .findstore-util-nav__location-content * {
            color: $color-white;
            font-size: 26px;
            font-family: $lato-regular;
            letter-spacing: 0;
            line-height: 36px;
           
        }
        .findstore-util-nav__zipcode-content * {
            color: $color-white;
            font-size: 16px;
            font-family: $lato-regular;
            letter-spacing: 0;
            line-height: 24px;
        }
    }
  }
  .modal-popup{
    .modal-popup-content{
        @include breakpoint(tab-max){
            max-width: 100%;
            text-align: left;
        }
        .modal-popup-body{
            .taxware-down{
                &__title{
                    @include breakpoint(tab-max){
                        text-align: center;
                    }
                }
                &__desc{
                    @include breakpoint(tab-max){
                        text-align: left;
                    }
                }
            }
            .taxware-down__cta{
                padding: 10px;
            }
        }
    }
  }
}
